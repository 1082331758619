import {
    ROOM_TYPE_PREVIEW,
    ROOM_TYPE_NEW,
    ROOM_TYPE_UPDATE,
    ROOM_TYPE_REMOVE,
    ROOM_TYPE_SEARCH,
    ROOM_TYPE_PROCESS_SUCCESS,
    ROOM_TYPE_DATASOURCE_SUCCESS,
    ROOM_TYPE_PROCESS_RESET
  } from "constants/ActionTypes"

  export const roomTypePreview = (value) => {
    return {
      type: ROOM_TYPE_PREVIEW,
      payload:value
    }
  }

  export const roomTypeCreate =(value) =>{
    return {
      type:ROOM_TYPE_NEW,
      payload:value
    }
  }

  export const roomTypeUpdate = (value) =>{
    return {
      type:ROOM_TYPE_UPDATE,
      payload: value
    }
  }

  export const roomTypeRemove = (value)=>{
    return {
      type:ROOM_TYPE_REMOVE,
      payload:value
    }
  }

  export const roomTypeSearch = (keyword) =>{
    return {
      type: ROOM_TYPE_SEARCH,
      payload:keyword
    }
  }

  export const roomTypeProcessSuccess = (value) =>{
    return{
      type:ROOM_TYPE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const roomTypeDataSourceUpdate = (value) => {
    return {
      type: ROOM_TYPE_DATASOURCE_SUCCESS,
      payload: value
    }
  } 
  export const roomTypeProcessReset = () =>{
    return{
      type:ROOM_TYPE_PROCESS_RESET
    }
  }

