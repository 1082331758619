import {
    RATE_APPROVE_PREVIEW,
    RATE_APPROVE_UPDATE,
    RATE_APPROVE_UPDATE_ALL,
    RATE_APPROVE_PROCESS_SUCCESS,
    RATE_APPROVE_DATASOURCE_SUCCESS,
    RATE_APPROVE_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
    loader: false,
    dataSerialize:{},
    dataSource:[],
    process:{}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case RATE_APPROVE_PREVIEW: {
            return {
                ...state,
                loader: false,
                process:{status:false,action:"",message:""}
            }
        }
        case RATE_APPROVE_UPDATE:{
            return{
            ...state,
            loader:true,
            process:{status:false,action:"",message:""},
            dataSerialize:action.payload
            }
        }
        case RATE_APPROVE_UPDATE_ALL: {
            return {
                ...state,
                loader: false,
                process:{status:false,action:"",message:""},
                dataSerialize:action.payload
            }
        }
        case RATE_APPROVE_PROCESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                process:action.payload
            }
        }
        case RATE_APPROVE_DATASOURCE_SUCCESS:{
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case RATE_APPROVE_PROCESS_RESET:{
            return{
                ...state,
                process:{}
            }
        }
        default:
            return state
    }
}