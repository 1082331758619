import {
    DEPARTMENT_PREVIEW,
    DEPARTMENT_NEW,
    DEPARTMENT_UPDATE,
    DEPARTMENT_REMOVE,
    DEPARTMENT_SEARCH,
    DEPARTMENT_PROCESS_SUCCESS,
    DEPARTMENT_DATASOURCE_SUCCESS,
    DEPARTMENT_PROCESS_RESET
  } from "constants/ActionTypes"

  export const departmentPreview = () => {
    return {
      type: DEPARTMENT_PREVIEW
    }
  }

  export const departmentCreate =(value) =>{
    return {
      type:DEPARTMENT_NEW,
      payload:value
    }
  }

  export const departmentUpdate = (value) =>{
    return {
      type:DEPARTMENT_UPDATE,
      payload: value
    }
  }

  export const departmentRemove = (value)=>{
    return {
      type:DEPARTMENT_REMOVE,
      payload:value
    }
  }

  export const departmentSearch = (keyword) =>{
    return {
      type: DEPARTMENT_SEARCH,
      payload:keyword
    }
  }

  export const departmentProcessSuccess = (value) =>{
    return{
      type:DEPARTMENT_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const departmentDataSourceUpdate = (value) => {
    return {
      type: DEPARTMENT_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const departmentProcessReset = () =>{
    return{
      type:DEPARTMENT_PROCESS_RESET
    }
  }

