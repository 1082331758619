// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU'
export const WINDOW_WIDTH = 'WINDOW-WIDTH'
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE'

//Contact Module const
export const FETCH_START = 'fetch_start'
export const FETCH_SUCCESS = 'fetch_success'
export const FETCH_ERROR = 'fetch_error'
export const SHOW_MESSAGE = 'SHOW_MESSAGE'
export const HIDE_MESSAGE = 'HIDE_MESSAGE'
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER'
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER'

//Reset State to Initial state
export const RESET_STATE = "RESET_STATE"

//Destroy sesion
export const DESTROY_SESSION = "DESTROY_SESSION"

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER'
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS'
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER'
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS'
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER'
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS'
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER'
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS'
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER'
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success'
export const SIGNIN_USER = 'SIGNIN_USER'
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS'
export const SIGNOUT_USER = 'SIGNOUT_USER'
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS'
export const INIT_URL = 'INIT_URL'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const VERIFY_USER = 'VERIRY_USER'
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS"
export const VERIFY_OTP = "VERIFY_OTP"
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS"
export const RESET_PROCESS_STATUS = "RESET_PROCESS_STATUS"
export const VERIFY_TOKEN = "VERIFY_TOKEN"
export const CHANGE_WORK_SHIFT = "CHANGE_WORK_SHIFT"
export const CHANGE_WORK_SHIFT_SUCCESS = "CHANGE_WORK_SHIFT_SUCCESS"
export const AUTH_PROCESS_SUCCESS = "AUTH_PROCESS_SUCCESS"

export const REQUEST_RESET_USER = "REQUEST_RESET_USER"
export const REQUEST_RESET_USER_SUCCESS  = "REQUEST_RESET_USER_SUCCESS"
export const REQUEST_RESET_USER_CLEAR = "REQUEST_RESET_USER_CLEAR"
export const REQUEST_RESET_USER_INVALID = "REQUEST_RESET_USER_INVALID"


//System setup channel type
export const CHANNEL_TYPE_PREVIEW = "CHANNEL_TYPE_PREVIEW"
export const CHANNEL_TYPE_NEW = "CHANNEL_TYPE_NEW"
export const CHANNEL_TYPE_UPDATE = "CHANNEL_TYPE_UPDATE"
export const CHANNEL_TYPE_REMOVE = "CHANNEL_TYPE_REMOVE"
export const CHANNEL_TYPE_PROCESS_SUCCESS = "CHANNEL_TYPE_PROCESS_SUCCESS"
export const CHANNEL_TYPE_SEARCH = "CHANNEL_TYPE_SEARCH"
export const CHANNEL_TYPE_DATASOURCE_SUCCESS = "CHANNEL_TYPE_DATASOURCE_SUCCESS"
export const CHANNEL_TYPE_PROCESS_RESET = "CHANNEL_TYPE_PROCESS_RESET"

//System setup channel group
export const CHANNEL_GROUP_PREVIEW = "CHANNEL_GROUP_PREVIEW"
export const CHANNEL_GROUP_NEW = "CHANNEL_GROUP_NEW"
export const CHANNEL_GROUP_UPDATE = "CHANNEL_GROUP_UPDATE"
export const CHANNEL_GROUP_REMOVE = "CHANNEL_GROUP_REMOVE"
export const CHANNEL_GROUP_PROCESS_SUCCESS = "CHANNEL_GROUP_PROCESS_SUCCESS"
export const CHANNEL_GROUP_SEARCH = "CHANNEL_GROUP_SEARCH"
export const CHANNEL_GROUP_DATASOURCE_SUCCESS = "CHANNEL_GROUP_DATASOURCE_SUCCESS"
export const CHANNEL_GROUP_PROCESS_RESET = "CHANNEL_GROUP_PROCESS_RESET"

//System setup channel list
export const CHANNEL_PREVIEW = "CHANNEL_PREVIEW"
export const CHANNEL_NEW = "CHANNEL_NEW"
export const CHANNEL_UPDATE = "CHANNEL_UPDATE"
export const CHANNEL_REMOVE = "CHANNEL_REMOVE"
export const CHANNEL_PROCESS_SUCCESS = "CHANNEL_PROCESS_SUCCESS"
export const CHANNEL_SEARCH = "CHANNEL_SEARCH"
export const CHANNEL_DATASOURCE_SUCCESS = "CHANNEL_DATASOURCE_SUCCESS"
export const CHANNEL_DETAIL = "CHANNEL_DETAIL"
export const CHANNEL_DETAIL_SUCCESS = "CHANNEL_DETAIL_SUCCESS"
export const CHANNEL_LOADLIST = "CHANNEL_LOADLIST"
export const CHANNEL_LOAD_LIST_SUCCESS = "CHANNEL_LOAD_LIST_SUCCESS"
export const CHANNEL_PROCESS_RESET = "CHANNEL_PROCESS_RESET"

//System setup sales person
export const SALES_PERSON_PREVIEW = "SALES_PERSON_PREVIEW"
export const SALES_PERSON_NEW = "SALES_PERSON_NEW"
export const SALES_PERSON_UPDATE = "SALES_PERSON_UPDATE"
export const SALES_PERSON_REMOVE = "SALES_PERSON_REMOVE"
export const SALES_PERSON_PROCESS_SUCCESS = "SALES_PERSON_PROCESS_SUCCESS"
export const SALES_PERSON_SEARCH = "SALES_PERSON_SEARCH"
export const SALES_PERSON_DATASOURCE_SUCCESS = "SALES_PERSON_ DATASOURCE_SUCCESS"
export const SALES_PROCESS_RESET = "SALES_PROCESS_RESET"

//System setup Origin
export const ORIGIN_PREVIEW = "ORIGIN_PREVIEW"
export const ORIGIN_NEW = "ORIGIN_NEW"
export const ORIGIN_UPDATE = "ORIGIN_UPDATE"
export const ORIGIN_REMOVE = "ORIGIN_REMOVE"
export const ORIGIN_PROCESS_SUCCESS = "ORIGIN_PROCESS_SUCCESS"
export const ORIGIN_SEARCH = "ORIGIN_SEARCH"
export const ORIGIN_DATASOURCE_SUCCESS = "ORIGIN_DATASOURCE_SUCCESS"
export const ORIGIN_PROCESS_RESET = "ORIGIN_PROCESS_RESET"

//System setup Market Zone
export const MARKET_PREVIEW = "MARKET_PREVIEW"
export const MARKET_NEW = "MARKET_NEW"
export const MARKET_UPDATE = "MARKET_UPDATE"
export const MARKET_REMOVE = "MARKET_REMOVE"
export const MARKET_PROCESS_SUCCESS = "MARKET_PROCESS_SUCCESS"
export const MARKET_SEARCH = "MARKET_SEARCH"
export const MARKET_DATASOURCE_SUCCESS = "MARKET_DATASOURCE_SUCCESS"
export const MARKET_PROCESS_RESET = "MARKET_PROCESS_RESET"

//System setup Country
export const COUNTRY_PREVIEW = "COUNTRY_PREVIEW"
export const COUNTRY_NEW = "COUNTRY_NEW"
export const COUNTRY_UPDATE = "COUNTRY_UPDATE"
export const COUNTRY_REMOVE = "COUNTRY_REMOVE"
export const COUNTRY_PROCESS_SUCCESS = "COUNTRY_PROCESS_SUCCESS"
export const COUNTRY_SEARCH = "COUNTRY_SEARCH"
export const COUNTRY_DATASOURCE_SUCCESS = "COUNTRY_DATASOURCE_SUCCESS"
export const COUNTRY_PROCESS_RESET = "COUNTRY_PROCESS_RESET"

//System setup Language
export const LANGUAGE_PREVIEW = "LANGUAGE_PREVIEW"
export const LANGUAGE_NEW = "LANGUAGE_NEW"
export const LANGUAGE_UPDATE = "LANGUAGE_UPDATE"
export const LANGUAGE_REMOVE = "LANGUAGE_REMOVE"
export const LANGUAGE_PROCESS_SUCCESS = "LANGUAGE_PROCESS_SUCCESS"
export const LANGUAGE_SEARCH = "LANGUAGE_SEARCH"
export const LANGUAGE_DATASOURCE_SUCCESS = "LANGUAGE_DATASOURCE_SUCCESS"
export const LANGUAGE_PROCESS_RESET = "LANGUAGE_PROCESS_RESET"

//System setup Nationality
export const NATIONALITY_PREVIEW = "NATIONALITY_PREVIEW"
export const NATIONALITY_NEW = "NATIONALITY_NEW"
export const NATIONALITY_UPDATE = "NATIONALITY_UPDATE"
export const NATIONALITY_REMOVE = "NATIONALITY_REMOVE"
export const NATIONALITY_PROCESS_SUCCESS = "NATIONALITY_PROCESS_SUCCESS"
export const NATIONALITY_SEARCH = "NATIONALITY_SEARCH"
export const NATIONALITY_DATASOURCE_SUCCESS = "NATIONALITY_DATASOURCE_SUCCESS"
export const NATIONALITY_PROCESS_RESET = "NATIONALITY_PROCESS_RESET"

//System setup Folio pattern
export const FOLIO_PATTERN_PREVIEW = "FOLIO_PATTERN_PREVIEW"
export const FOLIO_PATTERN_NEW = "FOLIO_PATTERN_NEW"
export const FOLIO_PATTERN_UPDATE = "FOLIO_PATTERN_UPDATE"
export const FOLIO_PATTERN_REMOVE = "FOLIO_PATTERN_REMOVE"
export const FOLIO_PATTERN_PROCESS_SUCCESS = "FOLIO_PATTERN_PROCESS_SUCCESS"
export const FOLIO_PATTERN_SEARCH = "FOLIO_PATTERN_SEARCH"
export const FOLIO_PATTERN_DATASOURCE_SUCCESS = "FOLIO_PATTERN_DATASOURCE_SUCCESS"
export const FOLIO_PATTERN_PROCESS_RESET = "FOLIO_PATTERN_PROCESS_RESET"

//System setup Folio pattern detail
export const FOLIO_PATTERN_DETAIL_PREVIEW = "FOLIO_PATTERN_DETAIL_PREVIEW"
export const FOLIO_PATTERN_DETAIL_NEW = "FOLIO_PATTERN_DETAIL_NEW"
export const FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS = "FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS"
export const FOLIO_PATTERN_DETAIL_SYNC_CONFIRM = "FOLIO_PATTERN_DETAIL_SYNC_CONFIRM"
export const FOLIO_PATTERN_DETAIL_SEARCH = "FOLIO_PATTERN_DETAIL_SEARCH"
export const FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS = "FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS"
export const FOLIO_PATTERN_DETAIL_PROCESS_RESET = "FOLIO_PATTERN_DETAIL_PROCESS_RESET"

//System setup Room group
export const ROOM_GROUP_PREVIEW = "ROOM_GROUP_PREVIEW"
export const ROOM_GROUP_NEW = "ROOM_GROUP_NEW"
export const ROOM_GROUP_UPDATE = "ROOM_GROUP_UPDATE"
export const ROOM_GROUP_REMOVE = "ROOM_GROUP_REMOVE"
export const ROOM_GROUP_PROCESS_SUCCESS = "ROOM_GROUP_PROCESS_SUCCESS"
export const ROOM_GROUP_SEARCH = "ROOM_GROUP_SEARCH"
export const ROOM_GROUP_DATASOURCE_SUCCESS = "ROOM_GROUP_DATASOURCE_SUCCESS"
export const ROOM_GROUP_PROCESS_RESET = "ROOM_GROUP_PROCESS_RESET"

//System setup Room Type
export const ROOM_TYPE_PREVIEW = "ROOM_TYPE_PREVIEW"
export const ROOM_TYPE_NEW = "ROOM_TYPE_NEW"
export const ROOM_TYPE_UPDATE = "ROOM_TYPE_UPDATE"
export const ROOM_TYPE_REMOVE = "ROOM_TYPE_REMOVE"
export const ROOM_TYPE_PROCESS_SUCCESS = "ROOM_TYPE_PROCESS_SUCCESS"
export const ROOM_TYPE_SEARCH = "ROOM_TYPE_SEARCH"
export const ROOM_TYPE_DATASOURCE_SUCCESS = "ROOM_TYPE_DATASOURCE_SUCCESS"
export const ROOM_TYPE_PROCESS_RESET = "ROOM_TYPE_PROCESS_RESET" 

//System setup Bed Type
export const BED_TYPE_PREVIEW = "BED_TYPE_PREVIEW"
export const BED_TYPE_NEW = "BED_TYPE_NEW"
export const BED_TYPE_UPDATE = "BED_TYPE_UPDATE"
export const BED_TYPE_REMOVE = "BED_TYPE_REMOVE"
export const BED_TYPE_PROCESS_SUCCESS = "BED_TYPE_PROCESS_SUCCESS"
export const BED_TYPE_SEARCH = "BED_TYPE_SEARCH"
export const BED_TYPE_DATASOURCE_SUCCESS = "BED_TYPE_DATASOURCE_SUCCESS"
export const BED_TYPE_PROCESS_RESET ="BED_TYPE_PROCESS_RESET"

//System setup Department group
export const DEPARTMENT_GROUP_PREVIEW = "DEPARTMENT_GROUP_PREVIEW"
export const DEPARTMENT_GROUP_NEW = "DEPARTMENT_GROUP_NEW"
export const DEPARTMENT_GROUP_UPDATE = "DEPARTMENT_GROUP_UPDATE"
export const DEPARTMENT_GROUP_REMOVE = "DEPARTMENT_GROUP_REMOVE"
export const DEPARTMENT_GROUP_PROCESS_SUCCESS = "DEPARTMENT_GROUP_PROCESS_SUCCESS"
export const DEPARTMENT_GROUP_SEARCH = "DEPARTMENT_GROUP_SEARCH"
export const DEPARTMENT_GROUP_DATASOURCE_SUCCESS = "DEPARTMENT_GROUP_DATASOURCE_SUCCESS"
export const DEPARTMENT_GROUP_PROCESS_RESET = "DEPARTMENT_GROUP_PROCESS_RESET"

//System setup Department
export const DEPARTMENT_PREVIEW = "DEPARTMENT_PREVIEW"
export const DEPARTMENT_NEW = "DEPARTMENT_NEW"
export const DEPARTMENT_UPDATE = "DEPARTMENT_UPDATE"
export const DEPARTMENT_REMOVE = "DEPARTMENT_REMOVE"
export const DEPARTMENT_PROCESS_SUCCESS = "DEPARTMENT_PROCESS_SUCCESS"
export const DEPARTMENT_SEARCH = "DEPARTMENT_SEARCH"
export const DEPARTMENT_DATASOURCE_SUCCESS = "DEPARTMENT_DATASOURCE_SUCCESS"
export const DEPARTMENT_PROCESS_RESET = "DEPARTMENT_PROCESS_RESET"

//System setup Media group
export const MEDIA_GROUP_PREVIEW = "MEDIA_GROUP_PREVIEW"
export const MEDIA_GROUP_NEW = "MEDIA_GROUP_NEW"
export const MEDIA_GROUP_UPDATE = "MEDIA_GROUP_UPDATE"
export const MEDIA_GROUP_REMOVE = "MEDIA_GROUP_REMOVE"
export const MEDIA_GROUP_PROCESS_SUCCESS = "MEDIA_GROUP_PROCESS_SUCCESS"
export const MEDIA_GROUP_SEARCH = "MEDIA_GROUP_SEARCH"
export const MEDIA_GROUP_DATASOURCE_SUCCESS = "MEDIA_GROUP_DATASOURCE_SUCCESS"
export const MEDIA_GROUP_PROCESS_RESET = "MEDIA_GROUP_PROCESS_RESET"

//System setup Media
export const MEDIA_PREVIEW = "MEDIA_PREVIEW"
export const MEDIA_NEW = "MEDIA_NEW"
export const MEDIA_UPDATE = "MEDIA_UPDATE"
export const MEDIA_REMOVE = "MEDIA_REMOVE"
export const MEDIA_PROCESS_SUCCESS = "MEDIA_PROCESS_SUCCESS"
export const MEDIA_SEARCH = "MEDIA_SEARCH"
export const MEDIA_DATASOURCE_SUCCESS = "MEDIA_DATASOURCE_SUCCESS"
export const MEDIA_PROCESS_RESET = "MEDIA_PROCESS_RESET"

//System setup Inclusion
export const INCLUSION_PREVIEW = "INCLUSION_PREVIEW"
export const INCLUSION_NEW = "INCLUSION_NEW"
export const INCLUSION_UPDATE = "INCLUSION_UPDATE"
export const INCLUSION_REMOVE = "INCLUSION_REMOVE"
export const INCLUSION_PROCESS_SUCCESS = "INCLUSION_PROCESS_SUCCESS"
export const INCLUSION_SEARCH = "INCLUSION_SEARCH"
export const INCLUSION_DATASOURCE_SUCCESS = "INCLUSION_DATASOURCE_SUCCESS"
export const INCLUSION_PROCESS_RESET = "INCLUSION_PROCESS_RESET"

//Inventory
export const INVENTORY_RATECALENDAR = "INVENTORY_RATECALENDAR"
export const INVENTORY_RATECALENDAR_SUCCESS = "INVENTORY_RATECALENDAR_SUCCESS"
export const INVENTORY_RATECALENDAR_FAIL = "INVENTORY_RATECALENDAR_FAIL"
export const INVENTORY_RATECALENDAR_CHANGING = "INVENTORY_RATECALENDAR_CHANGING" // Store data when user change data in mode change
export const INVENTORY_RATECALENDAR_UPDATE = "INVENTORY_RATECALENDAR_UPDATE"
export const INVENTORY_RATECALENDAR_UPDATE_FAIL = 'INVENTORY_RATECALENDAR_UPDATE_FAIL'
export const INVENTORY_RATECALENDAR_UPDATE_SUCCESS = "INVENTORY_RATECALENDAR_UPDATE_SUCCESS"
export const INVENTORY_BULKUPDATE_REQUEST = 'INVENTORY_BULKUPDATE_REQUEST'
export const INVENTORY_BULKUPDATE_SUCCESS = 'INVENTORY_BULKUPDATE_SUCCESS'
export const INVENTORY_BULKUPDATE_FAIL = 'INVENTORY_BULKUPDATE_FAIL'
export const INVENTORY_BULKUPDATE_RESET = 'INVENTORY_BULKUPDATE_RESET'

//System setup Reservation Type
export const RESERVATION_TYPE_PREVIEW = "RESERVATION_TYPE_PREVIEW"
export const RESERVATION_TYPE_NEW = "RESERVATION_TYPE_NEW"
export const RESERVATION_TYPE_UPDATE = "RESERVATION_TYPE_UPDATE"
export const RESERVATION_TYPE_REMOVE = "RESERVATION_TYPE_REMOVE"
export const RESERVATION_TYPE_PROCESS_SUCCESS = "RESERVATION_TYPE_PROCESS_SUCCESS"
export const RESERVATION_TYPE_SEARCH = "RESERVATION_TYPE_SEARCH"
export const RESERVATION_TYPE_DATASOURCE_SUCCESS = "RESERVATION_TYPE_DATASOURCE_SUCCESS"
export const RESERVATION_TYPE_PROCESS_RESET = "RESERVATION_TYPE_PROCESS_RESET"
export const SET_FILTER_DATA_RESERVATION = "SET_FILTER_DATA_RESERVATION"
export const CLEAR_FILTER_DATA_RESERVATION = "CLEAR_FILTER_DATA_RESERVATION"
export const CLEAR_ACTIVE_SAVE_DRAFT = "CLEAR_ACTIVE_SAVE_DRAFT";

//Rate Plan
export const RATEPLAN_PREVIEW = "RATEPLAN_PREVIEW"
export const RATEPLAN_NEW = "RATEPLAN_NEW"
export const RATEPLAN_UPDATE = "RATEPLAN_UPDATE"
export const RATEPLAN_REMOVE = "RATEPLAN_REMOVE"
export const RATEPLAN_PROCESS_SUCCESS = "RATEPLAN_PROCESS_SUCCESS"
export const RATEPLAN_SEARCH = "RATEPLAN_SEARCH"
export const RATEPLAN_DATASOURCE_SUCCESS = "RATEPLAN_DATASOURCE_SUCCESS"
export const RATEPLAN_DETAIL = "RATEPLAN_DETAIL"
export const RATEPLAN_DETAIL_SUCCESS = "RATEPLAN_DETAIL_SUCCESS"
export const RATEPLAN_CHANNEL_MAPPING ="RATEPLAN_CHANNEL_MAPPING"
export const RATEPLAN_ROOM_NEW = "RATEPLAN_ROOM_NEW"
export const RATEPLAN_ROOM_UPDATE = "RATEPLAN_ROOM_UPDATE"
export const RATEPLAN_ROOM_REMOVE = "RATEPLAN_ROOM_REMOVE"
export const RATEPLAN_ROOM_PROCESS_SUCCESS = "RATEPLAN_ROOM_PROCESS_SUCCESS"
export const RATEPLAN_GET_ROOM_DETAIL = "RATEPLAN_GET_ROOM_DETAIL"
export const RATEPLAN_GET_ROOM_DETAIL_SUCCESS = "RATEPLAN_GET_ROOM_DETAIL_SUCCESS"
export const RATEPLAN_PROCESS_RESET = "RATEPLAN_PROCESS_RESET"
export const RATEPLAN_SWITCH_ENABLED = "RATEPLAN_SWITCH_ENABLED" 

//Reservation (Booking)
export const RESERVATION_PREVIEW = "RESERVATION_PREVIEW"
export const RESERVATION_NEW = "RESERVATION_NEW"
export const RESERVATION_UPDATE = "RESERVATION_UPDATE"
export const RESERVATION_REMOVE = "RESERVATION_REMOVE"
export const RESERVATION_PROCESS_SUCCESS = "RESERVATION_PROCESS_SUCCESS"
export const RESERVATION_SEARCH = "RESERVATION_SEARCH"
export const RESERVATION_DATASOURCE_SUCCESS = "RESERVATION_DATASOURCE_SUCCESS"
export const RESERVATION_GET_BOOKING = "RESERVATION_GET_BOOKING"
export const RESERVATION_GET_BOOKING_SUCCESS = "RESERVATION_GET_BOOKING_SUCCESS"
export const RESERVATION_SAVE_DRAFT = "RESERVATION_SAVE_DRAFT"
export const RESERVATION_SAVE_DRAFT_SUCCESS = "RESERVATION_SAVE_DRAFT_SUCCESS"
export const RESERVATION_IS_ACTIVE_SAVE = "RESERVATION_IS_ACTIVE_SAVE"

//Card message component
export const MESSAGE_TO_GUEST_PREVIEW = "MESSAGE_TO_GUEST_PREVIEW"
export const MESSAGE_TO_GUEST_NEW = "MESSAGE_TO_GUEST_NEW"
export const MESSAGE_TO_GUEST_UPDATE = "MESSAGE_TO_GUEST_UPDATE"
export const MESSAGE_TO_GUEST_REMOVE = "MESSAGE_TO_GUEST_REMOVE" 
export const MESSAGE_TO_GUEST_PROCESS_SUCCESS = "MESSAGE_TO_GUEST_PROCESS_SUCCESS"
export const MESSAGE_TO_GUEST_DATASOURCE_SUCCESS = "MESSAGE_TO_GUEST_DATASOURCE_SUCCESS"

//Card followup message component
export const FOLLOW_UP_PREVIEW = "FOLLOW_UP_PREVIEW"
export const FOLLOW_UP_NEW = "FOLLOW_UP_NEW"
export const FOLLOW_UP_UPDATE = "FOLLOW_UP_UPDATE"
export const FOLLOW_UP_REMOVE = "FOLLOW_UP_REMOVE" 
export const FOLLOW_UP_PROCESS_SUCCESS = "FOLLOW_UP_PROCESS_SUCCESS"
export const FOLLOW_UP_DATASOURCE_SUCCESS = "FOLLOW_UP_DATASOURCE_SUCCESS"
export const FOLLOW_UP_COMMENT_NEW = "FOLLOW_UP_COMMENT_NEW"
export const FOLLOW_UP_COMMENT_UPDATE = "FOLLOW_UP_COMMENT_UPDATE"
export const FOLLOW_UP_COMMENT_REMOVE = "FOLLOW_UP_COMMENT_REMOVE"
export const FOLLOW_UP_UPDATE_SEEN = "FOLLOW_UP_UPDATE_SEEN"

//System setup VIP
export const VIP_PREVIEW = "VIP_PREVIEW"
export const VIP_NEW = "VIP_NEW"
export const VIP_UPDATE = "VIP_UPDATE"
export const VIP_REMOVE = "VIP_REMOVE"
export const VIP_PROCESS_SUCCESS = "VIP_PROCESS_SUCCESS"
export const VIP_SEARCH = "VIP_SEARCH"
export const VIP_DATASOURCE_SUCCESS = "VIP_DATASOURCE_SUCCESS"
export const VIP_PROCESS_RESET = "VIP_PROCESS_RESET" 

//ListBox IndexedDB
export const LISTBOX_ROOMTYPE = "LISTBOX_ROOMTYPE"
export const LISTBOX_ROOMTYPE_SUCCESS = "LISTBOX_ROOMTYPE_SUCCESS"
export const LISTBOX_COUNTRY = "LISTBOX_COUNTRY"
export const LISTBOX_COUNTRY_SUCCESS = "LISTBOX_COUNTRY_SUCCESS"
export const LISTBOX_CHANNEL_TYPE = "LISTBOX_CHANNEL_TYPE"
export const LISTBOX_CHANNEL_TYPE_SUCCESS = "LISTBOX_CHANNEL_TYPE_SUCCESS"
export const LISTBOX_ORIGIN = "LISTBOX_ORIGIN"
export const LISTBOX_ORIGIN_SUCCESS = "LISTBOX_ORIGIN_SUCCESS"
export const LISTBOX_SALES = "LISTBOX_SALES"
export const LISTBOX_SALES_SUCCESS = "LISTBOX_SALES_SUCCESS"
export const LISTBOX_MARKET = "LISTBOX_MARKET"
export const LISTBOX_MARKET_SUCCESS = "LISTBOX_MARKET_SUCCESS"
export const LISTBOX_NATION = "LISTBOX_NATION"
export const LISTBOX_NATION_SUCCESS = "LISTBOX_NATION_SUCCESS"
export const LISTBOX_FOLIO = "LISTBOX_FOLIO"
export const LISTBOX_FOLIO_SUCCESS = "LISTBOX_FOLIO_SUCCESS"
export const LISTBOX_ROOMGROUP = "LISTBOX_ROOMGROUP"
export const LISTBOX_ROOMGROUP_SUCCESS = "LISTBOX_ROOMGROUP_SUCCESS"
export const LISTBOX_INCLUSION = "LISTBOX_INCLUSION"
export const LISTBOX_INCLUSION_SUCCESS = "LISTBOX_INCLUSION_SUCCESS"
export const LISTBOX_RATEPLAN = "LISTBOX_RATEPLAN"
export const LISTBOX_RATEPLAN_SUCCESS = "LISTBOX_RATEPLAN_SUCCESS"
export const LISTBOX_VIP = "LISTBOX_VIP"
export const LISTBOX_VIP_SUCCESS = "LISTBOX_VIP_SUCCESS"
export const LISTBOX_FACILITY = "LISTBOX_FACILITY"
export const LISTBOX_FACILITY_SUCCESS = "LIST_FACILITY_SUCCESS"
export const LISTBOX_BEDTYPE = "LISTBOX_BEDTYPE"
export const LISTBOX_BEDTYPE_SUCCESS = "LISTBOX_BEDTYPE_SUCCESS"
export const LISTBOX_BUILDING = "LISTBOX_BUILDING"
export const LISTBOX_BUILDING_SUCCESS = "LISTBOX_BUILDING_SUCCESS"
export const LISTBOX_LOCATION = "LISTBOX_LOCATION"
export const LISTBOX_LOCATION_SUCCESS = "LISTBOX_LOCATION_SUCCESS"
export const LISTBOX_EXPOSURE = "LISTBOX_EXPOSURE"
export const LISTBOX_EXPOSURE_SUCCESS = "LISTBOX_EXPOSURE_SUCCESS"
export const LISTBOX_ROOMINFO = "LISTBOX_ROOMINFO"
export const LISTBOX_ROOMINFO_SUCCESS = "LISTBOX_ROOMINFO_SUCCESS"
export const LISTBOX_RESERVATION_TYPE = "LISTBOX_RESERVATION_TYPE"
export const LISTBOX_RESERVATION_TYPE_SUCCESS = "LISTBOX_RESERVATION_TYPE_SUCCESS"
export const LISTBOX_TITLENAME = "LISTBOX_TITLENAME"
export const LISTBOX_TITLENAME_SUCCESS = "LISTBOX_TITLENAME_SUCCESS"
export const LISTBOX_OCCUPATION = "LISTBOX_OCCUPATION"
export const LISTBOX_OCCUPATION_SUCCESS = "LISTBOX_OCCUPATION_SUCCESS"
export const LISTBOX_OUT_OF_ORDER = "LISTBOX_OUT_OF_ORDER"
export const LISTBOX_OUT_OF_ORDER_SUCCESS = "LISTBOX_OUT_OF_ORDER_SUCCESS"
export const LISTBOX_CITY = "LISTBOX_CITY"
export const LISTBOX_CITY_SUCCESS = "LISTBOX_CITY_SUCCESS"
export const LISTBOX_SOCIAL = "LISTBOX_SOCIAL"
export const LISTBOX_SOCIAL_SUCCESS = "LISTBOX_SOCIAL_SUCCESS"
export const LISTBOX_LANGUAGE = "LISTBOX_LANGUAGE"
export const LISTBOX_LANGUAGE_SUCCESS = "LISTBOX_LANGUAGE_SUCCESS"
export const LISTBOX_CHANNELS = "LISTBOX_CHANNELS"
export const LISTBOX_CHANNELS_SUCCESS = "LISTBOX_CHANNELS_SUCCESS"
export const LISTBOX_MEDIA = "LISTBOX_MEDIA"
export const LISTBOX_MEDIA_SUCCESS = "LISTBOX_MEDIA_SUCCESS"
export const LISTBOX_MEDIA_GROUP = "LISTBOX_MEDIA_GROUP"
export const LISTBOX_MEDIA_GROUP_SUCCESS = "LISTBOX_MEDIA_GROUP_SUCCESS"
export const LISTBOX_DEPARTMENT = "LISTBOX_DEPARTMENT"
export const LISTBOX_DEPARTMENT_SUCCESS = "LISTBOX_DEPARTMENT_SUCCESS"
export const LISTBOX_DEPARTMENT_GROUP = "LISTBOX_DEPARTMENT_GROUP"
export const LISTBOX_DEPARTMENT_GROUP_SUCCESS = "LISTBOX_DEPARTMENT_GROUP_SUCCESS"
export const LISTBOX_CHANNEL_GROUP = "LISTBOX_CHANNEL_GROUP"
export const LISTBOX_CHANNEL_GROUP_SUCCESS = "LISTBOX_CHANNEL_GROUP_SUCCESS"
export const LISTBOX_ITEMIZER = "LISTBOX_ITEMIZER"
export const LISTBOX_ITEMIZER_SUCCESS = "LISTBOX_ITEMIZER_SUCCESS"

export const LISTBOX_ALERT_PAGE = "LISTBOX_ALERT_PAGE"
export const LISTBOX_ALERT_PAGE_SUCCESS = "LISTBOX_ALERT_PAGE_SUCCESS"
export const LISTBOX_FOLLOW_UP_CATEGORY = "LISTBOX_FOLLOW_UP_CATEGORY"
export const LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS = "LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS"
export const LISTBOX_FOLLOW_UP_STATUS = "LISTBOX_FOLLOW_UP_STATUS"
export const LISTBOX_FOLLOW_UP_STATUS_SUCCESS = "LISTBOX_FOLLOW_UP_STATUS_SUCCESS"
export const LISTBOX_FOLLOW_UP_ASSIGN_USER = "LISTBOX_FOLLOW_UP_ASSIGN_USER"
export const LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS = "LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS"
export const LISTBOX_USER = "LISTBOX_USER"
export const LISTBOX_USER_SUCCESS = "LISTBOX_USER_SUCCESS"
export const LISTBOX_LOADALL = "LISTBOX_LOADALL"

export const LISTBOX_FOLIO_DETAIL = "LISTBOX_FOLIO_DETAIL"
export const LISTBOX_FOLIO_DETAIL_SUCCESS = "LISTBOX_FOLIO_DETAIL_SUCCESS"

export const LISTBOX_FOLIO_PAYMENT_CODE = "LISTBOX_FOLIO_PAYMENT_CODE"
export const LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS = "LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS"

//System setup Facility
export const FACILITY_PREVIEW = "FACILITY_PREVIEW"
export const FACILITY_NEW = "FACILITY_NEW"
export const FACILITY_UPDATE = "FACILITY_UPDATE"
export const FACILITY_REMOVE = "FACILITY_REMOVE"
export const FACILITY_PROCESS_SUCCESS = "FACILITY_PROCESS_SUCCESS"
export const FACILITY_SEARCH = "FACILITY_SEARCH"
export const FACILITY_DATASOURCE_SUCCESS = "FACILITY_DATASOURCE_SUCCESS"
export const FACILITY_PROCESS_RESET = "FACILITY_PROCESS_RESET"

//System setup Building
export const BUILDING_PREVIEW = "BUILDING_PREVIEW"
export const BUILDING_NEW = "BUILDING_NEW"
export const BUILDING_UPDATE = "BUILDING_UPDATE"
export const BUILDING_REMOVE = "BUILDING_REMOVE"
export const BUILDING_PROCESS_SUCCESS = "BUILDING_PROCESS_SUCCESS"
export const BUILDING_SEARCH = "BUILDING_SEARCH"
export const BUILDING_DATASOURCE_SUCCESS = "BUILDING_DATASOURCE_SUCCESS"
export const BUILDING_PROCESS_RESET = "BUILDING_PROCESS_RESET" 

//System setup Location
export const LOCATION_PREVIEW = "LOCATION_PREVIEW"
export const LOCATION_NEW = "LOCATION_NEW"
export const LOCATION_UPDATE = "LOCATION_UPDATE"
export const LOCATION_REMOVE = "LOCATION_REMOVE"
export const LOCATION_PROCESS_SUCCESS = "LOCATION_PROCESS_SUCCESS"
export const LOCATION_SEARCH = "LOCATION_SEARCH"
export const LOCATION_DATASOURCE_SUCCESS = "LOCATION_DATASOURCE_SUCCESS"
export const LOCATION_PROCESS_RESET = "LOCATION_PROCESS_RESET"

//System setup Exposure
export const EXPOSURE_PREVIEW = "EXPOSURE_PREVIEW"
export const EXPOSURE_NEW = "EXPOSURE_NEW"
export const EXPOSURE_UPDATE = "EXPOSURE_UPDATE"
export const EXPOSURE_REMOVE = "EXPOSURE_REMOVE"
export const EXPOSURE_PROCESS_SUCCESS = "EXPOSURE_PROCESS_SUCCESS"
export const EXPOSURE_SEARCH = "EXPOSURE_SEARCH"
export const EXPOSURE_DATASOURCE_SUCCESS = "EXPOSURE_DATASOURCE_SUCCESS"
export const EXPOSURE_PROCESS_RESET = "EXPOSURE_PROCESS_RESET" 

//System setup Room info
export const ROOMINFO_PREVIEW = "ROOMINFO_PREVIEW"
export const ROOMINFO_NEW = "ROOMINFO_NEW"
export const ROOMINFO_UPDATE = "ROOMINFO_UPDATE"
export const ROOMINFO_REMOVE = "ROOMINFO_REMOVE"
export const ROOMINFO_PROCESS_SUCCESS = "ROOMINFO_PROCESS_SUCCESS"
export const ROOMINFO_SEARCH = "ROOMINFO_SEARCH"
export const ROOMINFO_DATASOURCE_SUCCESS = "ROOMINFO_DATASOURCE_SUCCESS"
export const ROOMINFO_DETAIL = "ROOMINFO_DETAIL"
export const ROOMINFO_DETAIL_SUCCESS = "ROOMINFO_DETAIL_SUCCESS"
export const ROOMINFO_PROCESS_RESET = "ROOMINFO_PROCESS_RESET"

//System setup Title name
export const TITLE_NAME_PREVIEW = "TITLE_NAME_PREVIEW"
export const TITLE_NAME_NEW = "TITLE_NAME_NEW"
export const TITLE_NAME_UPDATE = "TITLE_NAME_UPDATE"
export const TITLE_NAME_REMOVE = "TITLE_NAME_REMOVE"
export const TITLE_NAME_PROCESS_SUCCESS = "TITLE_NAME_PROCESS_SUCCESS"
export const TITLE_NAME_SEARCH = "TITLE_NAME_SEARCH"
export const TITLE_NAME_DATASOURCE_SUCCESS = "TITLE_NAME_DATASOURCE_SUCCESS"
export const TITLE_NAME_PROCESS_RESET = "TITLE_NAME_PROCESS_RESET"

//System setup Occupation
export const OCCUPATION_PREVIEW = "OCCUPATION_PREVIEW"
export const OCCUPATION_NEW = "OCCUPATION_NEW"
export const OCCUPATION_UPDATE = "OCCUPATION_UPDATE"
export const OCCUPATION_REMOVE = "OCCUPATION_REMOVE"
export const OCCUPATION_PROCESS_SUCCESS = "OCCUPATION_PROCESS_SUCCESS"
export const OCCUPATION_SEARCH = "OCCUPATION_SEARCH"
export const OCCUPATION_DATASOURCE_SUCCESS = "OCCUPATION_DATASOURCE_SUCCESS"
export const OCCUPATION_PROCESS_RESET = "OCCUPATION_PROCESS_RESET"

//System setup Out of order
export const OUT_OF_ORDER_PREVIEW = "OUT_OF_ORDER_PREVIEW"
export const OUT_OF_ORDER_NEW = "OUT_OF_ORDER_NEW"
export const OUT_OF_ORDER_UPDATE = "OUT_OF_ORDER_UPDATE"
export const OUT_OF_ORDER_REMOVE = "OUT_OF_ORDER_REMOVE"
export const OUT_OF_ORDER_PROCESS_SUCCESS = "OUT_OF_ORDER_PROCESS_SUCCESS"
export const OUT_OF_ORDER_SEARCH = "OUT_OF_ORDER_SEARCH"
export const OUT_OF_ORDER_DATASOURCE_SUCCESS = "OUT_OF_ORDER_DATASOURCE_SUCCESS"
export const OUT_OF_ORDER_PROCESS_RESET = "OUT_OF_ORDER_PROCESS_RESET"

//System setup City
export const CITY_PREVIEW = "CITY_PREVIEW"
export const CITY_NEW = "CITY_NEW"
export const CITY_UPDATE = "CITY_UPDATE"
export const CITY_REMOVE = "CITY_REMOVE"
export const CITY_PROCESS_SUCCESS = "CITY_PROCESS_SUCCESS"
export const CITY_SEARCH = "CITY_SEARCH"
export const CITY_DATASOURCE_SUCCESS = "CITY_DATASOURCE_SUCCESS"
export const CITY_PROCESS_RESET = "CITY_PROCESS_RESET"

//System setup Social Media
export const SOCIAL_PREVIEW = "SOCIAL_PREVIEW"
export const SOCIAL_NEW = "SOCIAL_NEW"
export const SOCIAL_UPDATE = "SOCIAL_UPDATE"
export const SOCIAL_REMOVE = "SOCIAL_REMOVE"
export const SOCIAL_PROCESS_SUCCESS = "SOCIAL_PROCESS_SUCCESS"
export const SOCIAL_SEARCH = "SOCIAL_SEARCH"
export const SOCIAL_DATASOURCE_SUCCESS = "SOCIAL_DATASOURCE_SUCCESS"
export const SOCIAL_PROCESS_RESET = "SOCIAL_PROCESS_RESET" 

//Guest and Search Guest
export const SEARCH_GUEST_PREVIEW = "SEARCH_GUEST_PREVIEW"
export const SEARCH_GUEST_PROCESS_SUCCESS = "SEARCH_GUEST_PROCESS_SUCCESS"
export const SEARCH_GUEST_DATASOURCE_SUCCESS = "SEARCH_GUEST_DATASOURCE_SUCCESS"
export const SEARCH_GUEST_PROCESS_RESET = "SEARCH_GUEST_PROCESS_RESET"
export const SEARCH_GUEST_DETAIL_REQUEST = "SEARCH_GUEST_DETAIL_REQUEST"
export const SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS = "SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS"
export const SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS = "SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS"


export const GET_GUEST = "GET_GUEST"
export const GET_GUEST_PROCESS_SUCCESS = "GET_GUEST_PROCESS_SUCCESS"
export const GET_MAIN_GUEST = "GET_MAIN_GUEST"
export const RESET_STATE_MAIN_GUEST = "RESET_STATE_MAIN_GUEST"
export const RESET_STATE_SEARCH_GUEST = "RESET_STATE_SEARCH_GUEST"
export const GET_RATE_ROOM_TYPE = "GET_RATE_ROOM_TYPE"
export const GET_RATE_ROOM_TYPE_PROCESS_SUCCESS = "GET_RATE_ROOM_TYPE_PROCESS_SUCCESS"
export const RESET_RATE_ROOM_TYPE= "RESET_RATE_ROOM_TYPE"
export const ALERT_RATE_ROOM_TYPE= "ALERT_RATE_ROOM_TYPE"
export const MSG_AlERT_BOOKING= "MSG_AlERT_BOOKING"
export const RESET_MSG_AlERT_BOOKING= "RESET_MSG_AlERT_BOOKING"
export const UPDATE_BOOKING= "UPDATE_BOOKING"
export const UPDATE_BOOKING_PROCESS_SUCCESS= "UPDATE_BOOKING_PROCESS_SUCCESS"
export const UPDATE_DETAIL_BOOKING= "UPDATE_DETAIL_BOOKING"
export const CHECK_HAVE_DRAFT= "CHECK_HAVE_DRAFT"
export const GET_DRAFT_BOOKING= "GET_DRAFT_BOOKING"
export const DELETE_DRFT_BOOKING= "DELETE_DRFT_BOOKING"
export const CHANGE_STATUS_BOOKING= "CHANGE_STATUS_BOOKING"
export const CREATE_BOOKING= "CREATE_BOOKING"
export const CREATE_BOOKING_PROCESS_SUCCESS= "CREATE_BOOKING_PROCESS_SUCCESS"

//User Profile
export const CHANGE_PERSONAL_INFO = "CHANGE_PERSONAL_INFO"
export const CHANGE_CONTACT_INFO = "CHANGE_CONTACT_INFO"
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_IMAGE_PROFILE = "CHANGE_IMAGE_PROFILE"
export const CHANGE_PROCESS_SUCCESS = "CHANGE_PROCESS_SUCCESS"
export const USER_PROFILE_PREVIEW = "USER_PROFILE_PREVIEW"
export const USER_PROFILE_PROCESS_SUCCESS = "USER_PROFILE_PROCESS_SUCCESS"

//Notification Message
export const NOTIFICATION_PREVIEW = "NOTIFICATION_PREVIEW"
export const NOTIFICATION_REMOVE = "NOTIFICATION_REMOVE"
export const NOTIFICATION_DATASOURCE_SUCCESS = "NOTIFICATION_DATASOURCE_SUCCESS"
export const NOTIFICATION_PROCESS_SUCCESS = "NOTIFICATION_PROCESS_SUCCESS"

//SET MASAGE ALERT
export const MASSAGE_ALERT = "MASSAGE_ALERT"
export const RESET_MASSAGE_ALERT = "RESET_MASSAGE_ALERT"

//BLOCROOM
export const DESTROY_SESSION_BLOCK_ROOM = 'DESTROY_SESSION_BLOCK_ROOM'
export const SET_GROUP_ROOM = "SET_GROUP_ROOM"
export const RESET_STATE_BLOCK_ROOM = "RESET_STATE_BLOCK_ROOM"
export const GET_BLOCK_ROOM = "GET_BLOCK_ROOM"
export const GET_BLOCK_ROOM_PROCESS_SUCCESS = "GET_BLOCK_ROOM_PROCESS_SUCCESS"
export const SEARCH_ROOM = "SEARCH_ROOM"
export const SEARCH_ROOM_PROCESS_SUCCESS = "SEARCH_ROOM_PROCESS_SUCCESS"
export const BLOCK_ROOM = "BLOCK_ROOM"
export const BLOCK_ROOM_PROCESS_SUCCESS = "BLOCK_ROOM_PROCESS_SUCCESS"
export const UNBLOCK_ROOM = "UNBLOCK_ROOM"
export const UNBLOCK_ROOM_PROCESS_SUCCESS = "UNBLOCK_ROOM_PROCESS_SUCCESS"
export const SUPEER_BLOCK = "SUPEER_BLOCK"
export const SUPEER_BLOCK_PROCESS_SUCCESS = "SUPEER_BLOCK_PROCESS_SUCCESS"
export const SEARCH_BY_STATUS_ROOM = "SEARCH_BY_STATUS_ROOM"
export const ASSIGN_ROOM_NUMBER = "ASSIGN_ROOM_NUMBER"
export const ASSIGN_ROOM_NUNBER_STATUS_ROOM = "ASSIGN_ROOM_NUNBER_STATUS_ROOM"
export const SEARCH_BY_HK_STATUS = "SEARCH_BY_HK_STATUS"
export const CHECK_BOX_GROUP_ROOM = "CHECK_BOX_GROUP_ROOM"
export const RESET_CHECK_BOX_GROUP_ROOM = "RESET_CHECK_BOX_GROUP_ROOM"
export const FILTER_DATA_ROOMS = "FILTER_DATA_ROOMS"
export const SEARCH_BY_HOT_KEY = "SEARCH_BY_HOT_KEY"
export const UPDATE_HK_STATUS = "UPDATE_HK_STATUS"
export const UPDATE_HK_STATUS_PROCESS = "UPDATE_HK_STATUS_PROCESS"
export const UPDATE_ROOM_STATUS = "UPDATE_ROOM_STATUS"
export const UPDATE_ROOM_STATUS_PROCESS = "UPDATE_ROOM_STATUS_PROCESS"
export const UPDATE_BLOCK_ROOM_REALTIME = "UPDATE_BLOCK_ROOM_REALTIME"
export const RESET_UPDATE_DATA_ACTIVE_ROOM = "RESET_UPDATE_DATA_ACTIVE_ROOM"

// out of order
export const CREATE_OUT_OF_ORDER = "CREATE_OUT_OF_ORDER"
export const CREATE_OUT_OF_ORDER_PROCESS = "CREATE_OUT_OF_ORDER_PROCESS"
export const UPDATE_OUT_OF_ORDER = "UPDATE_OUT_OF_ORDER"
export const UPDATE_OUT_OF_ORDER_PROCESS = "UPDATE_OUT_OF_ORDER_PROCESS"
export const UPDATE_OUT_OF_ORDER_RESET = 'UPDATE_OUT_OF_ORDER_RESET'
export const DELETE_OUT_OF_ORDER = "DELETE_OUT_OF_ORDER"
export const DELETE_OUT_OF_ORDER_PROCESS = "DELETE_OUT_OF_ORDER_PROCESS"

export const UPDATE_REMARK_ROOM = "UPDATE_REMARK_ROOM"
export const UPDATE_REMARK_ROOM_PROCESS = "UPDATE_REMARK_ROOM_PROCESS"

export const UPDATE_BED_TYPE_ROOM = "UPDATE_BED_TYPE_ROOM"
export const UPDATE_BED_TYPE_ROOM_PROCESS = "UPDATE_BED_TYPE_ROOM_PROCESS"

// Guest in house
export const GUEST_INHOUSE_PREVIEW = "GUEST_INHOUSE_PREVIEW"
export const GUEST_INHOUSE_PROCESS_SUCCESS = "GUEST_INHOUSE_PROCESS_SUCCESS"
export const GUEST_INHOUSE_DATASOURCE_SUCCESS = "GUEST_INHOUSE_DATASOURCE_SUCCESS"
export const GUEST_INHOUSE_PROCESS_RESET = "GUEST_INHOUSE_PROCESS_RESET"
export const GUEST_INHOUSE_CHECK_OUT = "GUEST_INHOUSE_CHECK_OUT"
export const GUEST_INHOUSE_CANCEL_CHECK_IN = "GUEST_INHOUSE_CANCEL_CHECK_IN"
export const GUEST_INHOUSE_CANCEL_CHECK_OUT = "GUEST_INHOUSE_CANCEL_CHECK_OUT"

export const RESET_STATE_GUEST_IN_HOUSE="RESET_STATE_GUEST_IN_HOUSE"
export const CLEAR_FILTER_DATA_GUEST_IN_HOUSE="CLEAR_FILTER_DATA_GUEST_IN_HOUSE"
export const GET_FILTER_DATA_GUEST_IN_HOUSE="GET_FILTER_DATA_GUEST_IN_HOUSE"
export const LIST_CHECK_IN = "LIST_CHECK_IN"
export const LIST_CHECK_IN_PROCESS = "LIST_CHECK_IN_PROCESS"
export const GET_DETAIL_CHECK_IN = "GET_DETAIL_CHECK_IN"
export const GET_DETAIL_CHECK_IN_PROCESS = "GET_DETAIL_CHECK_IN_PROCESS"

export const CHECK_IN = "CHECK_IN"
export const CHECK_IN_PROCESS = "CHECK_IN_PROCESS"
export const GET_CHECK_IN_SUMMARY = "GET_CHECK_IN_SUMMARY"
export const GET_CHECK_IN_SUMMARY_PROCESS = "GET_CHECK_IN_SUMMARY_PROCESS"
export const RESET_UPDATE_SUMMARY = "RESET_UPDATE_SUMMARY"
export const GET_DETAIL_GUEST_INHOUSE = "GET_DETAIL_GUEST_INHOUSE"
export const GET_DETAIL_GUEST_INHOUSE_PROCESS = "GET_DETAIL_GUEST_INHOUSE_PROCESS"
export const UPDATE_INHOUSE = "UPDATE_INHOUSE"
export const UPDATE_INHOUSE_PROCESS = "UPDATE_INHOUSE_PROCESS"
export const SAVE_WALKIN = "SAVE_WALKIN"
export const SAVE_WALKIN_PROCESS = "SAVE_WALKIN_PROCESS"
export const VERIFY_RATE_AND_AVIAIL = "VERIFY_RATE_AND_AVIAIL"
export const VERIFY_RATE_AND_AVIAIL_PROCESS = "VERIFY_RATE_AND_AVIAIL_PROCESS"
export const RESET_VERIFY_RATE_AND_AVIAIL = "RESET_VERIFY_RATE_AND_AVIAIL"
export const GET_CHECKIN_DETAIL = "GET_CHECKIN_DETAIL"
export const GET_CHECKIN_DETAIL_SUCCESS = "GET_CHECKIN_DETAIL_SUCCESS"
export const IS_CHANGE_DATA_WALKIN = "IS_CHANGE_DATA_WALKIN"


// System Config
export const SYSTEM_CONFIG_PREVIEW = "SYSTEM_CONFIG_PREVIEW"
export const SYSTEM_CONFIG_UPDATE = "SYSTEM_CONFIG_UPDATE"
export const SYSTEM_CONFIG_PROCESS_SUCCESS = "SYSTEM_CONFIG_PROCESS_SUCCESS"
export const SYSTEM_CONFIG_DATASOURCE_SUCCESS = "SYSTEM_CONFIG_DATASOURCE_SUCCESS"
export const SYSTEM_CONFIG_RESET = "SYSTEM_CONFIG_RESET" 

//Night Audit - Rate Approve
export const RATE_APPROVE_PREVIEW = "RATE_APPROVE_PREVIEW"
export const RATE_APPROVE_UPDATE = "RATE_APPROVE_UPDATE"
export const RATE_APPROVE_UPDATE_ALL = "RATE_APPROVE_UPDATE_ALL"
export const RATE_APPROVE_PROCESS_SUCCESS = "RATE_APPROVE_PROCESS_SUCCESS"
export const RATE_APPROVE_DATASOURCE_SUCCESS = "RATE_APPROVE_DATASOURCE_SUCCESS"
export const RATE_APPROVE_PROCESS_RESET = "RATE_APPROVE_PROCESS_RESET"

//Night Audit - End day
export const END_DAY_PREVIEW = "END_DAY_PREVIEW"
export const END_DAY_RUN = "END_DAY_RUN"
export const END_DAY_REALTIME_UPDATE = "END_DAY_REALTIME_UPDATE"
export const END_DAY_PROCESS_SUCCESS = "END_DAY_PROCESS_SUCCESS"
export const END_DAY_DATASOURCE_SUCCESS = "END_DAY_DATASOURCE_SUCCESS"
export const END_DAY_PROCESS_RESET = "END_DAY_PROCESS_RESET"

// Report
export const REPORT_MENU_LOADING = 'REPORT_MENU_LOADING'
export const REPORT_MENU_SUCCESS = 'REPORT_MENU_SUCCESS'

// Cashier
export const CASHIER_GUEST_FOLIO_GET_REQUEST = 'CASHIER_GUEST_FOLIO_GET_REQUEST'
export const CASHIER_GUEST_FOLIO_GET_SUCCESS = 'CASHIER_GUEST_FOLIO_GET_SUCCESS'
export const CASHIER_GUEST_FOLIO_GET_FAILED = 'CASHIER_GUEST_FOLIO_GET_FAILED'
export const CASHIER_GUEST_FOLIO_POST_CHECKOUT_REQUEST = 'CASHIER_GUEST_FOLIO_POST_CHECKOUT_REQUEST'
export const CASHIER_GUEST_FOLIO_POST_CHECKOUT_SUCCESS = 'CASHIER_GUEST_FOLIO_POST_CHECKOUT_SUCCESS'
export const CASHIER_GUEST_FOLIO_POST_CHECKOUT_FAILED = 'CASHIER_GUEST_FOLIO_POST_CHECKOUT_FAILED'

export const CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_REQUEST = 'CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_REQUEST'
export const CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_SUCCESS = 'CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_SUCCESS'
export const CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_FAILED = 'CASHIER_GUEST_FOLIO_POST_CANCELCHECKOUT_FAILED'

//Room Avail
export const ROOM_AVAIL_PREVIEW = "ROOM_AVAIL_PREVIEW"
export const ROOM_AVAIL_PROCESS_SUCCESS = "ROOM_AVAIL_PROCESS_SUCCESS"
export const ROOM_AVAIL_DATASOURCE_SUCCESS = "ROOM_AVAIL_DATASOURCE_SUCCESS"
export const ROOM_AVAIL_PROCESS_RESET = "ROOM_AVAIL_PROCESS_RESET"
export const ROOM_AVAIL_DETAIL_PREVIEW = "ROOM_AVAIL_DETAIL_PREVIEW"
export const ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS = "ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS" 

// Report
export const REPORT_MENU_GET_REQUEST = 'REPORT_MENU_GET_REQUEST'
export const REPORT_MENU_GET_SUCCESS = 'REPORT_MENU_GET_SUCCESS'
export const REPORT_MENU_GET_FAIL = 'REPORT_MENU_GET_FAIL'