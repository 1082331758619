import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"
import { isUndefined ,isEmpty } from "lodash"
import { IndexedDBAdd } from "util/indexedDBLibrary"
import { getItems, setItems } from "util/localStorage"

import * as actionTypes from "appRedux/actions/setup/rate/RatePlanActionType"
import * as actionCreators from "appRedux/actions/setup/rate/RatePlan"
import { userSignOut} from "appRedux/actions/Auth"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { ApiResponse } from "constants/ActionTypes"
import { rateplanDetailType } from "appRedux/reducers/setup/rate/RatePlanType"

function* ratePlanPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/ratePlan/`
    const url_format3 = `${API_URL_V1}pms/masterfile/ratePlan/?format=3`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    try {

      const response:ApiResponse = yield APIGetRequest(url_format3,API_PMS_HEADERS)
      if(response.status === 200)
      { 
            const ratePlanDataSource = response.data.map((obj:any,key:number) =>{
              const container = {} as any
                    container.key = key
                    container.rate_plan_id = obj.id
                    container.rate_plan_name = obj.name
                    container.rate_plan_code = obj.code||""
                    container.channel_count = obj.channel_count
                    container.order_seq = obj.order_seq
                    container.plan_type_id = obj.plan_type_id
                    container.plan_type_name = obj.plan_type_name
                    container.link_plan_id = obj.link_plan_id
                    container.link_description = obj.link_description||""
                    container.base_plan_id = obj.base_plan_id
                    container.link_rate = obj.link_rate
                    container.children = obj.children.map((o:any)=>{
                      const childrenObj = {rate_plan_id : o.id , 
                                            rate_plan_name : o.name,
                                            rate_plan_code : o.code||"",
                                            channel_count : o.channel_count,
                                            order_seq : o.order_seq,
                                            plan_type_id : o.plan_type_id,
                                            plan_type_name : o.plan_type_name,
                                            link_plan_id : o.link_plan_id,
                                            link_description : o.link_description||"",
                                            base_plan_id : o.base_plan_id,
                                            link_rate : o.link_rate,
                                            link_count : 0,
                                            enabled : o.enabled
                                          }
                      return childrenObj
                    })
                    container.link_count = obj.children.length
                    container.enabled = obj.enabled
                return container
            })
            /* if no error put everything to reducer */
            yield put(actionCreators.ratePlanDataSourceUpdate(ratePlanDataSource))
            setItems({
              sessionRatePlandDataSource: JSON.stringify(ratePlanDataSource)
            })

            /* Call API for keep data to indexdb case create listbox */
            const subResponse:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
            if(subResponse.status === 200)
            { 
                  const ratePlanDataSource = subResponse.data.map((obj:any,key:number) =>{
                    const container = {} as any
                          container.key = key
                          container.rate_plan_id = obj.id
                          container.rate_plan_name = obj.name
                          container.rate_plan_code = obj.code||""
                          container.channel_count = obj.channel_count
                          container.rate_plan_details = obj.details
                          container.plan_type_id = obj.plan_type_id
                          container.plan_type_name = obj.plan_type_name
                          container.link_rate = obj.link_rate
                          container.effected_roomtypes = obj.effected_roomtypes
                          container.enabled = obj.enabled
                      return container
                  })
                  /* if no error put everything to reducer */
                  yield IndexedDBAdd("ListBoxDB", "objStoreRatePlanList", ratePlanDataSource)
            }else{
                  console.log("Error : ",subResponse)
                  /* Show error message and signout */
                  const {message,data} = subResponse.data
                  const processStatus = { status: false, action: "preview", message}
                  yield put(actionCreators.ratePlanProcessSuccess(processStatus))
                  if(subResponse.status === 403){
                    /* if data is not null force signout */
                    if(data)
                      yield put(userSignOut())
                  }
            }
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
            yield put(actionCreators.ratePlanProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
      }
      
    } catch (error) {
        console.log("Error on saga ratePlan : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
    }
    
}

function* ratePlanSearchProcess({payload}:actionTypes.RatePlanSearch){
  const  { sessionRatePlandDataSource }  = yield call(getItems, ['sessionRatePlandDataSource'])
  const ReformalData = JSON.parse(sessionRatePlandDataSource)
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){ 
      /* Search */
      const filtered = ReformalData.map((o:any)=>{
        const {rate_plan_name,rate_plan_code,children,...rest} = o
        const childrenFilter = children.filter((obj:any)=> (obj.rate_plan_name.toLowerCase().indexOf(keyword) >-1 || 
                                                      obj.rate_plan_code.toLowerCase().indexOf(keyword) > -1))

        if(isEmpty(childrenFilter))
        { 
          if(rate_plan_name.toLowerCase().indexOf(keyword) > -1 || rate_plan_code.toLowerCase().indexOf(keyword) > -1)
            return {rate_plan_name,rate_plan_code,...rest,children:[]}
          else
            return undefined
        }
        else
          return {rate_plan_name,rate_plan_code,...rest,children:childrenFilter}
      }).filter((o:any)=>o !== undefined)

      const mergeData = [...new Set([...filtered])]

      yield put(actionCreators.ratePlanDataSourceUpdate(mergeData))
  }else 
      yield put(actionCreators.ratePlanDataSourceUpdate(ReformalData))
}

function* ratePlanCreateProcess({payload}:actionTypes.RatePlanNew){

  let ratePlanInfo = {} as rateplanDetailType
      ratePlanInfo.name = payload.name
      ratePlanInfo.code = payload.code
      ratePlanInfo.plan_type_id = payload.plan_type_id

      ratePlanInfo.rate_type = payload.rate_type
      ratePlanInfo.mealtype_id = payload.mealtype_id
      ratePlanInfo.rategroup_id = payload.rategroup_id

      if(payload.book_period){
        ratePlanInfo.book_start_date = moment(payload.book_period[0]._d).format("YYYY-MM-DD")
        ratePlanInfo.book_end_date = moment(payload.book_period[1]._d).format("YYYY-MM-DD")
      }
      
      if(payload.stay_period){
        ratePlanInfo.stay_start_date = moment(payload.stay_period[0]._d).format("YYYY-MM-DD")
        ratePlanInfo.stay_end_date = moment(payload.stay_period[1]._d).format("YYYY-MM-DD")
      }
      ratePlanInfo.description = payload.description||null
      ratePlanInfo.link_plan_id = payload.link_plan_id||null
      ratePlanInfo.link_adjust_type =  parseInt(payload.link_adjust_type)||null
      ratePlanInfo.link_adjust_amt = parseFloat(payload.link_adjust_amt)||null
      ratePlanInfo.link_adjust_percent = parseFloat(payload.link_adjust_percent)||null
      ratePlanInfo.link_adjust_op = (payload.link_adjust_op)?(payload.link_adjust_op==="plus"?"+":"-"):null
      ratePlanInfo.min_stay = parseInt(payload.min_stay)||null
      ratePlanInfo.max_stay = parseInt(payload.max_stay)||null
      ratePlanInfo.min_adv_book = parseInt(payload.min_adv_book)||null
      ratePlanInfo.max_adv_book = parseFloat(payload.max_adv_book)||null
      ratePlanInfo.rate_extrabed = parseFloat(payload.rate_extrabed)||null
      ratePlanInfo.rate_child = parseFloat(payload.rate_child)||null
      ratePlanInfo.rate_infant = parseFloat(payload.rate_infant)||null
      ratePlanInfo.origin_id = payload.origin_id||null
      ratePlanInfo.market_id = payload.market_id||null
      ratePlanInfo.order_seq = parseInt(payload.order_seq)||null
      ratePlanInfo.room_charge_dept = payload.room_charge_dept||null
      ratePlanInfo.min_rate = parseFloat(payload.min_rate)||null

      ratePlanInfo.details = payload.details
      ratePlanInfo.days = { sun:payload?.days.sun,
                            mon:payload?.days.mon,
                            tue:payload?.days.tue,
                            wed:payload?.days.wed,
                            thu:payload?.days.thu,
                            fri:payload?.days.fri,
                            sat:payload?.days.sat
      }
      
      ratePlanInfo.inclusions = payload.inclusions

      ratePlanInfo.no_amend = payload.no_amend
      ratePlanInfo.no_cancel = payload.no_cancel
      ratePlanInfo.no_refund = payload.no_refund
      ratePlanInfo.comp = payload.comp
      ratePlanInfo.comp_abf = payload.comp_abf
      ratePlanInfo.enabled = payload.enabled

      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/ratePlan/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = ratePlanInfo
            const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const {result_code,result_msg } = response.data
                const processStatus = { status: true, action: "rateplanCreate", message:result_msg,result_code}
                yield put(actionCreators.ratePlanProcessSuccess(processStatus))
            }else{   
                const {message,data} = response.data
                const processStatus = { status: false, action: "rateplanCreate", message}
                yield put(actionCreators.ratePlanProcessSuccess(processStatus))
                yield put(actionCreators.ratePlanProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "rateplanCreate", message:error}
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      }
}

function* ratePlanUpdateProcess({payload}: actionTypes.RatePlanUpdate){
  const { flag_effect_child } = payload

  let ratePlanInfo = {} as any
      ratePlanInfo.id = payload.id
      ratePlanInfo.name = payload.name
      ratePlanInfo.code = payload.code
      ratePlanInfo.plan_type_id = payload.plan_type_id

      ratePlanInfo.rate_type = payload.rate_type
      ratePlanInfo.mealtype_id = payload.mealtype_id
      ratePlanInfo.rategroup_id = payload.rategroup_id

      if(flag_effect_child) 
        ratePlanInfo.flag_effect_child = true

      if(payload.book_period){
        ratePlanInfo.book_start_date = moment(payload.book_period[0]._d).format("YYYY-MM-DD")
        ratePlanInfo.book_end_date = moment(payload.book_period[1]._d).format("YYYY-MM-DD")
      }else{
        ratePlanInfo.book_start_date = null
        ratePlanInfo.book_end_date = null
      }
      
      if(payload.stay_period){
        ratePlanInfo.stay_start_date = moment(payload.stay_period[0]._d).format("YYYY-MM-DD")
        ratePlanInfo.stay_end_date = moment(payload.stay_period[1]._d).format("YYYY-MM-DD")
      }else{
        ratePlanInfo.stay_start_date = null
        ratePlanInfo.stay_end_date = null
      }
      ratePlanInfo.description = payload.description||""
      ratePlanInfo.link_plan_id = payload.link_plan_id||null
      ratePlanInfo.link_adjust_type =  parseInt(payload.link_adjust_type)||null
      ratePlanInfo.link_adjust_amt = parseFloat(payload.link_adjust_amt)||null
      ratePlanInfo.link_adjust_percent = parseFloat(payload.link_adjust_percent)||null
      ratePlanInfo.link_adjust_op = (payload.link_adjust_op)?(payload.link_adjust_op==="plus"?"+":"-"):null
      ratePlanInfo.min_stay = parseInt(payload.min_stay)||null
      ratePlanInfo.max_stay = parseInt(payload.max_stay)||null
      ratePlanInfo.min_adv_book = parseInt(payload.min_adv_book)||null
      ratePlanInfo.max_adv_book = parseFloat(payload.max_adv_book)||null
      ratePlanInfo.rate_extrabed = parseFloat(payload.rate_extrabed)||null
      ratePlanInfo.rate_child = parseFloat(payload.rate_child)||null
      ratePlanInfo.rate_infant = parseFloat(payload.rate_infant)||null
      ratePlanInfo.origin_id = payload.origin_id||null
      ratePlanInfo.market_id = payload.market_id||null
      ratePlanInfo.order_seq = parseInt(payload.order_seq)||null
      ratePlanInfo.room_charge_dept = payload.room_charge_dept||null
      ratePlanInfo.min_rate = parseFloat(payload.min_rate)||null

      ratePlanInfo.details = payload.details
      ratePlanInfo.days = { sun:payload?.days.sun,
                            mon:payload?.days.mon,
                            tue:payload?.days.tue,
                            wed:payload?.days.wed,
                            thu:payload?.days.thu,
                            fri:payload?.days.fri,
                            sat:payload?.days.sat
      }
      
      ratePlanInfo.inclusions = payload.inclusions

      ratePlanInfo.no_amend = payload.no_amend
      ratePlanInfo.no_cancel = payload.no_cancel
      ratePlanInfo.no_refund = payload.no_refund
      ratePlanInfo.comp = payload.comp
      ratePlanInfo.comp_abf = payload.comp_abf
      ratePlanInfo.enabled = payload.enabled

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/ratePlan/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = ratePlanInfo
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const {result_code,result_msg } = response.data
            const processStatus = { status: true, action: "rateplanUpdate", message:result_msg,result_code }
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        }else{     
            const {message,data} = response.data
            const processStatus = { status: false, action: "rateplanUpdate", message}
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
            yield put(actionCreators.ratePlanProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "rateplanUpdate", message:error}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanRemoveProcess({payload}:actionTypes.RatePlanRemove){
    const ratePlanRemove = payload
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/ratePlan/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : ratePlanRemove}
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        }else{      
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(actionCreators.ratePlanProcessSuccess(processStatus))
          yield put(actionCreators.ratePlanProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      yield put(actionCreators.ratePlanProcessReset())
    }
}

function* ratePlanDetailProcess({payload}:actionTypes.RatePlanDetail){
  const rate_plan_id = payload
  try{
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/masterfile/ratePlan/${rate_plan_id}/?format=2`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

      const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
      if(response.status === 200){
        const obj = response.data[0]

        let detailData = {} as any
            detailData.id = obj.id
            detailData.name = obj.name 
            detailData.code = obj.code
            detailData.plan_type_id = obj.plan_type_id
            detailData.book_start_date = obj.book_start_date
            detailData.book_end_date = obj.book_end_date
            detailData.stay_start_date = obj.stay_start_date
            detailData.stay_end_date = obj.stay_end_date
            detailData.children_count = obj.children_count

            detailData.rate_type = obj.rate_type
            detailData.mealtype_id = obj.mealtype_id
            detailData.rategroup_id = obj.rategroup_id
            detailData.min_rate = obj.min_rate

            if((obj.book_start_date)&&(obj.book_end_date))
              detailData.book_period = [moment(obj.book_start_date), moment(obj.book_end_date)]
            else 
              detailData.book_period = null
            if((obj.stay_start_date)&&(obj.stay_end_date))
              detailData.stay_period = [moment(obj.stay_start_date), moment(obj.stay_end_date)]
            else
              detailData.stay_period = null

            detailData.description = obj.description
            detailData.link_plan_id = obj.link_plan_id
            detailData.link_adjust_type = obj.link_adjust_type
            detailData.link_adjust_amt = obj.link_adjust_amt
            detailData.link_adjust_percent = obj.link_adjust_percent
            detailData.link_adjust_op = obj.link_adjust_op==="+" ? "plus" : obj.link_adjust_op==="-" ? "minus" : null

            detailData.origin_id = obj.origin_id
            detailData.market_id = obj.market_id
            
            detailData.min_stay = obj.min_stay
            detailData.max_stay = obj.max_stay
            detailData.min_adv_book = obj.min_adv_book
            detailData.max_adv_book = obj.max_adv_book
            detailData.rate_extrabed = obj.rate_extrabed
            detailData.rate_child = obj.rate_child
            detailData.rate_infant = obj.rate_infant

            detailData.no_amend = obj.no_amend
            detailData.no_cancel = obj.no_cancel
            detailData.no_refund = obj.no_refund
            detailData.enabled = obj.enabled
            detailData.order_seq = obj.order_seq

            detailData.inclusions = obj.inclusions
            detailData.in_use_flag = obj.in_use_flag
            detailData.comp = obj.comp
            detailData.comp_abf = obj.comp_abf
            detailData.days = obj.days
            detailData.room_charge_dept = obj.room_charge_dept
            detailData.details = obj.details

        yield put(actionCreators.ratePlanDetailSuccess(detailData))
        const processStatus = { status: true, action: "getRateplanDetail", message:""}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
      }else{ 
        const {message,data} = response.data
        const processStatus = { status: false, action: "getRateplanDetail", message}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
  }
  catch(error){
    console.log("Error : ",error)
    const processStatus = { status: false, action: "getRateplanDetail", message:error}
    yield put(actionCreators.ratePlanProcessSuccess(processStatus))
    yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanChannelMappingProcess({payload}:actionTypes.RatePlanChannelMapping){
  const {rate_plan_id,channel_id} = payload
  
  let channelMappingInfo = {} as any
      channelMappingInfo.rateplan_id = rate_plan_id
      if(isEmpty(channel_id))
        channelMappingInfo.channel_id = []
      else
        channelMappingInfo.channel_id = channel_id
  
    try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/masterfile/ratePlan/channel/assign/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = channelMappingInfo
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
  
      if(response.status === 200){
          const processStatus = { status: true, action: "mapping", message:"Mapping success." }
          yield put(actionCreators.ratePlanProcessSuccess(processStatus))
          yield put(actionCreators.ratePlanProcessReset())
      }else{   
          const {message,data} = response.data
          const processStatus = { status: false, action: "mapping", message}
          yield put(actionCreators.ratePlanProcessSuccess(processStatus))
          yield put(actionCreators.ratePlanProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
      }
  
  } catch (error) {
      console.log("Error : ",error)
      const processStatus = { status: false, action: "mapping", message:error}
      yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanRoomCreateProcess({payload}:actionTypes.RatePlanRoomNew){
 
  let ratePlanRoomDetailInfo = {} as any
      ratePlanRoomDetailInfo.rateplan_id = payload.rate_plan_id
      ratePlanRoomDetailInfo.roomtype_id = payload.room_type_id
      ratePlanRoomDetailInfo.description = payload.description
      ratePlanRoomDetailInfo.rate_amount = (payload.rate_amount)?parseFloat(payload.rate_amount):null
      ratePlanRoomDetailInfo.rate_extrabed = (payload.rate_extra_bed)?parseFloat(payload.rate_extra_bed):null
      ratePlanRoomDetailInfo.rate_child = (payload.rate_child)?parseFloat(payload.rate_child):null
      ratePlanRoomDetailInfo.rate_infant = (payload.rate_infant)?parseFloat(payload.rate_infant):null
      ratePlanRoomDetailInfo.include_adult = (payload.include_adult)?parseInt(payload.include_adult):null  
      ratePlanRoomDetailInfo.include_child = (payload.include_child)?parseInt(payload.include_child):null
      ratePlanRoomDetailInfo.include_infant = (payload.include_infant)?parseInt(payload.include_infant):null
      ratePlanRoomDetailInfo.extra_adult = (payload.extra_adult)?parseInt(payload.extra_adult):null
      ratePlanRoomDetailInfo.extra_child = (payload.extra_child)?parseInt(payload.extra_child):null
      ratePlanRoomDetailInfo.extra_infant = (payload.extra_infant)?parseInt(payload.extra_infant):null
  
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/ratePlan/detail/create/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = ratePlanRoomDetailInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
        const processStatus = { status: true, action: "create", message:"Create success."}
        yield put(actionCreators.ratePlanRoomProcessSuccess(processStatus))
    }else{   
        const {message,data} = response.data
        const processStatus = { status: false, action: "create", message}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
        
    }
  } catch (error) {
      console.log("Error : ",error)
      const processStatus = { status: false, action: "create", message:error}
      yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanRoomUpdateProcess({payload}:actionTypes.RatePlanRoomUpdate){

  let ratePlanRoomDetailInfo = {} as any
      ratePlanRoomDetailInfo.rate_id = payload.rate_id
      ratePlanRoomDetailInfo.description = payload.description
      ratePlanRoomDetailInfo.rate_amount = (payload.rate_amount)?parseFloat(payload.rate_amount):null
      ratePlanRoomDetailInfo.rate_extrabed = (payload.rate_extra_bed)?parseFloat(payload.rate_extra_bed):null
      ratePlanRoomDetailInfo.rate_child = (payload.rate_child)?parseFloat(payload.rate_child):null
      ratePlanRoomDetailInfo.rate_infant = (payload.rate_infant)?parseFloat(payload.rate_infant):null
      ratePlanRoomDetailInfo.include_adult = (payload.include_adult)?parseInt(payload.include_adult):null  
      ratePlanRoomDetailInfo.include_child = (payload.include_child)?parseInt(payload.include_child):null
      ratePlanRoomDetailInfo.include_infant = (payload.include_infant)?parseInt(payload.include_infant):null

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/ratePlan/detail/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = ratePlanRoomDetailInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
        const processStatus = { status: true, action: "update", message:"Update room success."}
        yield put(actionCreators.ratePlanRoomProcessSuccess(processStatus))
    }else{   
        const {message,data} = response.data
        const processStatus = { status: false, action: "update", message}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
        
    }
  } catch (error) {
      console.log("Error : ",error)
      const processStatus = { status: false, action: "update", message:error}
      yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanGetRoomDetailProcess({payload}:actionTypes.RatePlanGetRoomDetail){
  const rate_id = payload
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/ratePlan/linkDetails/${rate_id}/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
    if(response.status === 200){
        const {data} = response
        const processStatus = { status: true, action: "getRoomDetails", message:""}
        yield put(actionCreators.ratePlanGetRoomDetailSuccess(data))
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
    }else{   
        const {message,data} = response.data
        const processStatus = { status: false, action: "getRoomDetails", message}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
        
    }
  } catch (error) {
      console.log("Error : ",error)
      const processStatus = { status: false, action: "getRoomDetails", message:error}
      yield put(actionCreators.ratePlanProcessSuccess(processStatus))
      yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanRoomRemoveProcess({payload}:actionTypes.RatePlanRoomRemove){
  
  const ratePlanRoomRemove = payload
  try{
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/masterfile/ratePlan/detail/delete/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY = {rate_id : ratePlanRoomRemove}
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
      if(response.status === 200){
        const processStatus = { status: true, action: "delete", message:"Delete room success."}
        yield put(actionCreators.ratePlanRoomProcessSuccess(processStatus))
      }else{      
        const {message,data} = response.data
        const processStatus = { status: false, action: "delete", message}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
  }
  catch(error){
    console.log("Error : ",error)
    const processStatus = { status: false, action: "delete", message:error}
    yield put(actionCreators.ratePlanProcessSuccess(processStatus))
    yield put(actionCreators.ratePlanProcessReset())
  }
}

function* ratePlanSwitchEnableProcess({payload}:actionTypes.RatePlanSwitchEnabled){
  const { rate_plan_id, enabled } = payload

  let ratePlanInfo = {} as any
      ratePlanInfo.id = rate_plan_id
      ratePlanInfo.enabled = enabled

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/ratePlan/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = ratePlanInfo
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "switch", message:"Update success." }
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        }else{     
            const {message,data} = response.data
            const processStatus = { status: false, action: "switch", message}
            yield put(actionCreators.ratePlanProcessSuccess(processStatus))
            yield put(actionCreators.ratePlanProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "switch", message:error}
        yield put(actionCreators.ratePlanProcessSuccess(processStatus))
        yield put(actionCreators.ratePlanProcessReset())
  }
}

/* Watcher Saga */
export function* ratePlanPreview() {
  yield takeLatest(actionTypes.RATEPLAN_PREVIEW, ratePlanPreviewProcess)
}
export function* ratePlanSearch(){
  yield takeLatest(actionTypes.RATEPLAN_SEARCH, ratePlanSearchProcess)
}
export function* ratePlanCreate(){
  yield takeEvery(actionTypes.RATEPLAN_NEW,ratePlanCreateProcess)
}
export function* ratePlanUpdate(){
  yield takeEvery(actionTypes.RATEPLAN_UPDATE, ratePlanUpdateProcess)
}
export function* ratePlanRemove(){
  yield takeEvery(actionTypes.RATEPLAN_REMOVE,ratePlanRemoveProcess)
}
export function* ratePlanDetail(){
  yield takeEvery(actionTypes.RATEPLAN_DETAIL,ratePlanDetailProcess)
}
export function* ratePlanChannelMapping(){
  yield takeEvery(actionTypes.RATEPLAN_CHANNEL_MAPPING,ratePlanChannelMappingProcess)
}
export function* ratePlanGetRoomDetail(){
  yield takeEvery(actionTypes.RATEPLAN_GET_ROOM_DETAIL,ratePlanGetRoomDetailProcess)
}
export function* ratePlanRoomCreate(){
  yield takeEvery(actionTypes.RATEPLAN_ROOM_NEW,ratePlanRoomCreateProcess)
}
export function* ratePlanRoomUpdate(){
  yield takeEvery(actionTypes.RATEPLAN_ROOM_UPDATE,ratePlanRoomUpdateProcess)
}
export function* ratePlanRoomRemove(){
  yield takeEvery(actionTypes.RATEPLAN_ROOM_REMOVE,ratePlanRoomRemoveProcess)
}
export function* ratePlanSwitchEnable(){
  yield takeEvery(actionTypes.RATEPLAN_SWITCH_ENABLED,ratePlanSwitchEnableProcess)
}

export default function* rootSaga() {
  yield all([fork(ratePlanPreview),
             fork(ratePlanSearch),
             fork(ratePlanCreate),
             fork(ratePlanUpdate),
             fork(ratePlanRemove),
             fork(ratePlanDetail),
             fork(ratePlanChannelMapping),
             fork(ratePlanRoomCreate),
             fork(ratePlanRoomUpdate),
             fork(ratePlanGetRoomDetail),
             fork(ratePlanRoomRemove),
             fork(ratePlanSwitchEnable)])


}
