import * as actions from "./roomplanResourceActionType"

export const roomplanResourcePreview = (payload?: any): actions.RoomPlanResourceRequest => {
  return {
    type: actions.ROOMPLAN_RESOURCE_REQUEST,
    payload
  }
}

export const roomplanResourceDatasouceSuccess = (payload: any): actions.RoomPlanResourceDataSourceSuccess => {
  return {
    type: actions.ROOMPLAN_RESOURCE_DATASOURCE_SUCCESS,
    payload
  }
}
export const roomplanResourceRawDataSource = (payload: any): actions.RoomPlanResourceRawDataSourceSuccess => {
  return {
    type: actions.ROOMPLAN_RESOURCE_RAW_DATASOURCE,
    payload
  }
}

export const roomplanResourceFilterRawData = (payload: any): actions.RoomPlanResourceFilterRawData => {
  return {
    type: actions.ROOMPLAN_RESOURCE_FILTER_RAWDATA,
    payload
  }
}


export const roomplanResourceSetFilter = (payload: any): actions.RoomPlanResourceSetFilter => {
  return {
    type: actions.ROOMPLAN_RESOURCE_SET_FILTER,
    payload
  }
}


export const roomplanResourceProcessSuccess = (payload: any): actions.RoomPlanResourceProcessSuccess => {
  return {
    type: actions.ROOMPLAN_RESOURCE_PROCESS_SUCCESS,
    payload
  }
}

export const roomplanResourceProcessReset = (): actions.RoomPlanResourceProcessReset => {
  return {
    type: actions.ROOMPLAN_RESOURCE_PROCESS_RESET
  }
}
