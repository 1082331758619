export const RATEGROUP_PREVIEW = "RATEGROUP_PREVIEW"
export const RATEGROUP_NEW = "RATEGROUP_NEW"
export const RATEGROUP_UPDATE = "RATEGROUP_UPDATE"
export const RATEGROUP_REMOVE = "RATEGROUP_REMOVE"
export const RATEGROUP_PROCESS_SUCCESS = "RATEGROUP_PROCESS_SUCCESS"
export const RATEGROUP_DATASOURCE_SUCCESS = "RATEGROUP_DATASOURCE_SUCCESS"

export const RATEGROUP_PROCESS_RESET = "RATEGROUP_PROCESS_RESET"

export const RATEGROUP_SWITCH_ENABLED = "RATEGROUP_SWITCH_ENABLED"


export interface RateGroupRequest {
    type: typeof RATEGROUP_PREVIEW,
    payload?: any
}
export interface RateGroupNew {
    type: typeof RATEGROUP_NEW,
    payload?: any
}
export interface RateGroupUpdate {
    type: typeof RATEGROUP_UPDATE,
    payload?: any
}
export interface RateGroupRemove {
    type: typeof RATEGROUP_REMOVE,
    payload: any
}
export interface RateGroupDataSouceSuccess {
    type: typeof RATEGROUP_DATASOURCE_SUCCESS,
    payload: any
}
export interface RateGroupProcessSuccess {
    type: typeof RATEGROUP_PROCESS_SUCCESS,
    payload: any
}

export interface RateGroupSwitchEnabled {
    type: typeof RATEGROUP_SWITCH_ENABLED,
    payload: any
}

export interface RateGroupProcessReset {
    type: typeof RATEGROUP_PROCESS_RESET
}

export type RateGroupAction =
    | RateGroupRequest
    | RateGroupNew
    | RateGroupUpdate
    | RateGroupRemove
    | RateGroupDataSouceSuccess
    | RateGroupProcessSuccess
    | RateGroupSwitchEnabled
    | RateGroupProcessReset