export const CALENDAR_REQUEST = "CALENDAR_REQUEST"
export const CALENDAR_DATASOURCE_SUCCESS = "CALENDAR_DATASOURCE_SUCCESS"
export const CALENDAR_PROCESS_SUCCESS = "CALENDAR_PROCESS_SUCCESS"
export const CALENDAR_PROCESS_RESET = "CALENDAR_PROCESS_RESET"

export interface CalendarRequest {
  type: typeof CALENDAR_REQUEST,
  payload?:any
}

export interface CalendarProcessSuccess {
  type: typeof CALENDAR_PROCESS_SUCCESS,
  payload:{}
}
export interface CalendarDataSourceSuccess{
  type: typeof CALENDAR_DATASOURCE_SUCCESS,
  payload:any
}
export interface CalendarProcessReset {
  type: typeof CALENDAR_PROCESS_RESET
}


export type CalendarAction = 
| CalendarRequest
| CalendarProcessSuccess
| CalendarDataSourceSuccess
| CalendarProcessReset