import {
  VIP_PREVIEW,
  VIP_DATASOURCE_SUCCESS,
  VIP_NEW,
  VIP_UPDATE,
  VIP_REMOVE,
  VIP_PROCESS_SUCCESS,
  VIP_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:false,
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
      case VIP_PREVIEW:{
        return{
          ...state,
          loader:false,
          process:{status:false,action:"",message:""}
        }
      }
      case VIP_DATASOURCE_SUCCESS:{
        return {
          ...state,
          loader: false,
          dataSource: action.payload
        }
      }
      case VIP_NEW: {
        return {
            ...state,
            loader: true,
            process:{status:false,action:"",message:""}
        }
      }
      case VIP_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case VIP_REMOVE: {
      return {
          ...state,
          loader: false,
          process: {status:false,action:"",message:""}
      }
    }
    case VIP_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case VIP_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}