import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/folioHistoryActionType"
import * as actionCreators from "appRedux/actions/cashier/folioHistory"

import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

interface folioHistoryType{
  data: [{
        "item_id": number,
        "folio_seq": number,
        "folio_name": string,
        "note"?: string,
        "close_date": string,
        "close_time": string,
        "close_user": string,
        "close_shift": string,
        "link_folio_id"?: any,
        "link_folio_type"?: string,
        "link_folio_name"?: string,
        "transactions": []
  }]
}

function* folioHistoryRequestProcess({payload}: actionTypes.FolioHistoryRequest){

    const folio_id = payload.folio_id
    const keyword = payload?.keyword
    const close_date = payload?.close_date

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/${folio_id}/history/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const folioHistory:folioHistoryType = response.data
                localStorage.setItem("folioHistoryOrigin", JSON.stringify(folioHistory))
                /* if no error put everything to reducer */
                yield put(actionCreators.folioHistorySearch({keyword,close_date}))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "folioHistoryRequest", message}
                yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on tranfer first request : ",error)
        const processStatus = { status: false, action: "folioHistoryRequest", message:error}
        yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
    }
}

function* folioHistorySearchProcess({ payload }: actionTypes.FolioHistorySearch) {
    const keyword = payload.keyword||undefined
    const close_date = payload.close_date ? moment(payload.close_date).format("YYYY-MM-DD"):undefined

    const historyObj:any = localStorage.getItem("folioHistoryOrigin")
    const folioHistoryOrigin = JSON.parse(historyObj)

    try{
      if(!keyword && !close_date)
          yield put(actionCreators.folioHistoryDataSourceSuccess(folioHistoryOrigin))
      else {
        const newDataSource = folioHistoryOrigin.filter((obj:any)=>{
          if(keyword && close_date)
            return (obj.folio_name.toLowerCase().indexOf(keyword) > -1 && obj.close_date===close_date )
          else
            return (obj.folio_name.toLowerCase().indexOf(keyword) > -1 || obj.close_date===close_date )
        })
        const mergeData = [...new Set([...newDataSource])]
        yield put(actionCreators.folioHistoryDataSourceSuccess(mergeData))
      }
    } catch(error){
      console.log("Error ",error)
    }
}

function* folioHistoryReopenProcess({ payload}: actionTypes.FolioHistoryReopen){
  try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/cashier/folio/reopen/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = {folio_item_id:payload}
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

      if(response.status === 200){
        const {result_code,result_msg} = response.data
        const processStatus = { status: true, action: "folioHistoryReopen", message:result_msg,result_code}
        yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
      }else { 
        const {message} = response.data
        const processStatus = { status: false, action: "folioHistoryReopen", message}
        yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
        if(response.status === 403){
          const {data} = response.data
          
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      const processStatus = { status: false, action: "folioHistoryReopen", message:error}
      yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
    }
}

interface UpdateType {
  trn_id:number,
  description?:string,
  reference?:string,
  remark?:string,
  dept_id?:number
}
function* folioHistoryUpdateProcess({payload}: actionTypes.FolioHistoryUpdate){

  const updateData = {} as UpdateType
        updateData.trn_id = payload.trn_id
        updateData.reference = payload.reference||""
        updateData.remark = payload.remark||""
        updateData.description = payload.description||""

  try{
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/cashier/transaction/update/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = updateData
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

      if(response.status === 200){
        const {result_code,result_msg} = response.data
        const processStatus = { status: true, action: "updateTransaction", message:result_msg||"",result_code}
        yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
      }else { 
        const {message} = response.data
        const processStatus = { status: false, action: "updateTransaction", message}
        yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
        if(response.status === 403){
          const {data} = response.data
          
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      const processStatus = { status: false, action: "updateTransaction", message:error}
      yield put(actionCreators.folioHistoryProcessSuccess(processStatus))
    }
}


function* folioHistoryRequest(){
  yield takeEvery(actionTypes.FOLIO_HISTORY_REQUEST,folioHistoryRequestProcess)
}
function* folioHistorySearch() {
  yield takeEvery(actionTypes.FOLIO_HISTORY_SEARCH, folioHistorySearchProcess)
}
function* folioHistoryReopen(){
  yield takeEvery(actionTypes.FOLIO_HISTORY_REOPEN,folioHistoryReopenProcess)
}
function* folioHistoryUpdate(){
  yield takeEvery(actionTypes.FOLIO_HISTORY_TRANS_UPDATE,folioHistoryUpdateProcess)
}

export default function* rootSaga() {
  yield all([fork(folioHistorySearch),
             fork(folioHistoryRequest),
             fork(folioHistoryReopen),
             fork(folioHistoryUpdate)
  ])
}


