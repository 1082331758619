export const EXPECTED_DEPARTURE_REQUEST = "EXPECTED_DEPARTURE_REQUEST"
export const EXPECTED_DEPARTURE_DATASOURCE_SUCCESS = "EXPECTED_DEPARTURE_DATASOURCE_SUCCESS"
export const EXPECTED_DEPARTURE_PROCESS_SUCCESS = "EXPECTED_DEPARTURE_PROCESS_SUCCESS"
export const EXPECTED_DEPARTURE_PROCESS_RESET = "EXPECTED_DEPARTURE_PROCESS_RESET"
export const EXPECTED_DEPARTURE_SET_FILTER = "EXPECTED_DEPARTURE_SET_FILTER"

export interface ExpectedDepartureRequest {
  type: typeof EXPECTED_DEPARTURE_REQUEST,
  payload?:any
}

export interface ExpectedDepartureProcessSuccess {
  type: typeof EXPECTED_DEPARTURE_PROCESS_SUCCESS,
  payload:{}
}
export interface ExpectedDepartureDataSourceSuccess{
  type: typeof EXPECTED_DEPARTURE_DATASOURCE_SUCCESS,
  payload:any
}
export interface ExpectedDepartureProcessReset {
  type: typeof EXPECTED_DEPARTURE_PROCESS_RESET
}
export interface ExpectedDepartureSetFilter{
  type: typeof EXPECTED_DEPARTURE_SET_FILTER,
  payload:any
}

export type ExpectedDepartureAction = 
| ExpectedDepartureRequest
| ExpectedDepartureProcessSuccess
| ExpectedDepartureDataSourceSuccess
| ExpectedDepartureProcessReset
| ExpectedDepartureSetFilter