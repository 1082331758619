//import { DateApi } from 'types'

export const ROOMPLAN_EVENT_REQUEST = "ROOMPLAN_EVENT_REQUEST"
export const ROOMPLAN_EVENT_DATASOURCE_SUCCESS = "ROOMPLAN_EVENT_DATASOURCE_SUCCESS"
export const ROOMPLAN_EVENT_PROCESS_SUCCESS = "ROOMPLAN_EVENT_PROCESS_SUCCESS"
export const ROOMPLAN_EVENT_PROCESS_RESET = "ROOMPLAN_EVENT_PROCESS_RESET"

export const ROOMPLAN_EVENT_DRAG_OOO_CHANGE_DATE = "ROOMPLAN_EVENT_DRAG_OOO_CHANGE_DATE"
export const ROOMPLAN_EVENT_DRAG_BLOCK_CHANGE_ROOM = "ROOMPLAN_EVENT_DRAG_BLOCK_CHANGE_ROOM"
export const ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_ROOM = "ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_ROOM"
export const ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_DATE = "ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_DATE"

export interface RoomPlanEventRequest {
    type: typeof ROOMPLAN_EVENT_REQUEST,
    payload: { start_date: any, end_date: any }
}
export interface RoomPlanEventDataSourceSuccess {
    type: typeof ROOMPLAN_EVENT_DATASOURCE_SUCCESS,
    payload: any
}
export interface RoomPlanEventDragOOOChangeDate {
    type: typeof ROOMPLAN_EVENT_DRAG_OOO_CHANGE_DATE,
    payload: {
        ooo_id: number,
        new_start?: string | null,
        new_end?: string | null
    }
}
export interface RoomPlanEventDragBlockChangeRoom {
    type: typeof ROOMPLAN_EVENT_DRAG_BLOCK_CHANGE_ROOM,
    payload: {
        block_id: number,
        new_room_id: number
    }
}
export interface RoomPlanEventDragInhouseChangeRoom {
    type: typeof ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_ROOM,
    payload: {
        register_id: number,
        new_room_id: number
    }
}
export interface RoomPlanEventDragInhouseChangeDate {
    type: typeof ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_DATE,
    payload: {
        register_id: number,
        new_departure?: string | null
    }
}
export interface RoomPlanEventProcessSuccess {
    type: typeof ROOMPLAN_EVENT_PROCESS_SUCCESS,
    payload: {}
}
export interface RoomPlanEventProcessReset {
    type: typeof ROOMPLAN_EVENT_PROCESS_RESET
}

export type RoomPlanEventAction =
    | RoomPlanEventRequest
    | RoomPlanEventDataSourceSuccess
    | RoomPlanEventDragOOOChangeDate
    | RoomPlanEventDragBlockChangeRoom
    | RoomPlanEventDragInhouseChangeDate
    | RoomPlanEventDragInhouseChangeRoom
    | RoomPlanEventProcessSuccess
    | RoomPlanEventProcessReset