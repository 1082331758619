import { faHandSparkles, faBroom, faExclamationTriangle, faSuitcase, faLuggageCart, faCheckCircle} from '@fortawesome/free-solid-svg-icons'

export const setArrayNumber = (limit) => {
  const array = []
  for (let i = 0; i <= limit; ++i) {
    array.push(i)
  }
  // console.log('array', array )
  return array
}

export const frequencyList = [{key:1,frequency_id:1,frequency_name:"Daily Except Arrival"},
                              {key:2,frequency_id:2,frequency_name:"Daily Except Departure"},
                              {key:3,frequency_id:3,frequency_name:"Once On Arrival"},
                              {key:4,frequency_id:4,frequency_name:"Once On Departure"}]
                              
export const multiplierList = [{key:1,multiplier_id:1,multiplier_name:"Per Booking"},
                                {key:2,multiplier_id:2,multiplier_name:"Per Room"},
                                {key:3,multiplier_id:3,multiplier_name:"Per Person (Adult + Child)"},
                                {key:4,multiplier_id:4,multiplier_name:"Per Adult"},
                                {key:5,multiplier_id:5,multiplier_name:"Per Child"},
                                {key:6,multiplier_id:6,multiplier_name:"Per Infant"},
                                {key:7,multiplier_id:7,multiplier_name:"Per Quality"}]

export const statusRoom = [
  {id:1, status: 'VAC' , name: 'Vacant' , color: 'tx-green' , codeColor: '#52C41A' },
  {id:2, status: 'OCC' , name: 'Occupied', color: 'tx-blue-m' , codeColor: '#038FDE' },
  {id:3, status: 'OOO' , name: 'Out of Order' , color: 'tx-red' , codeColor: '#FF0000'},
  {id:4, status: 'OOS' , name: 'Out of Service', color: 'tx-orange'  , codeColor: '#FA8C16'},
  {id:5, status: 'OOI' , name: 'Out of Inventory', color: 'tx-gray-m' , codeColor: '#535353'},
  // { id:6, status: 'HOLD' , name: 'Hold', color: 'tx-black'  }
]

export const hkStatus = [
  {id:1, status: 'DI' , name: 'Dirty' , color: 'tx-orange' , codeColor: '#FA8C16' ,icon : faBroom},
  {id:2, status: 'CL' , name: 'Clean', color: 'tx-blue-m' , codeColor: '#038FDE' ,icon : faHandSparkles},
  {id:3, status: 'IS' , name: 'Inspected', color: 'tx-green' , codeColor: '#FA8C16' ,icon : faCheckCircle},
  {id:4, status: 'PU' , name: 'pickup', color: 'tx-red' , codeColor: '#FF0000' ,icon : faExclamationTriangle},
  {id:5, status: 'MI' , name: 'Main in', color: 'tx-red' , codeColor: '#FF0000' ,icon : faExclamationTriangle},
]


export const hotKeyStatusRoom = [
  {id:1, status: 'vacant_inspected' , name: 'Vacant and Inspected' , color: 'tx-orange' , codeColor: '#FA8C16' ,icon : faCheckCircle},
  {id:2, status: 'expected_arrival' , name: 'Expected arrival' , color: 'tx-orange' , codeColor: '#FA8C16' ,icon : faSuitcase},
  {id:3, status: 'expected_departure' , name: 'Expected Departure', color: 'tx-blue-m' , codeColor: '#038FDE' ,icon : faLuggageCart},
]
