import * as actions from "./RatePlanActionType"

export const ratePlanPreview = (payload?: any): actions.RatePlanRequest => {
  return {
    type: actions.RATEPLAN_PREVIEW,
    payload
  }
}

export const ratePlanCreate = (payload:any):actions.RatePlanNew =>{
  return{
    type: actions.RATEPLAN_NEW,
    payload
  }
}

export const ratePlanUpdate = (payload:any):actions.RatePlanUpdate =>{
  return {
    type: actions.RATEPLAN_UPDATE,
    payload
  }
}
export const ratePlanRemove = (payload:number):actions.RatePlanRemove=>{
  return{
    type: actions.RATEPLAN_REMOVE,
    payload
  }
}
export const ratePlanProcessSuccess = (payload:any):actions.RatePlanProcessSuccess=>{
  return{
    type: actions.RATEPLAN_PROCESS_SUCCESS,
    payload
  }
}
export const ratePlanSearch = (payload:any): actions.RatePlanSearch =>{
  return {
    type: actions.RATEPLAN_SEARCH,
    payload
  }
}

export const ratePlanDataSourceUpdate = (value:any): actions.RatePlanDataSouceSuccess =>{
  return{
    type:actions.RATEPLAN_DATASOURCE_SUCCESS,
    payload:value
  }
}

export const getRatePlanDatail = (value:any): actions.RatePlanDetail =>{
  return{
    type:actions.RATEPLAN_DETAIL,
    payload:value
  }
}

export const ratePlanDetailSuccess = (value:any): actions.RatePlanDetailSuccess =>{
  return{
    type:actions.RATEPLAN_DETAIL_SUCCESS,
    payload:value
  }
}
export const ratePlanChannelMapping = (value:any):actions.RatePlanChannelMapping => {
  return {
    type: actions.RATEPLAN_CHANNEL_MAPPING,
    payload:value
  }
}

export const ratePlanRoomCreate = (value:any): actions.RatePlanRoomNew =>{
  return{
    type:actions.RATEPLAN_ROOM_NEW,
    payload:value
  }
}

export const ratePlanRoomUpdate = (value:any):actions.RatePlanRoomUpdate =>{
  return{
    type:actions.RATEPLAN_ROOM_UPDATE,
    payload:value
  }
}

export const ratePlanRoomRemove = (value:any):actions.RatePlanRoomRemove =>{
  return{
    type: actions.RATEPLAN_ROOM_REMOVE,
    payload:value
  }
}

export const ratePlanRoomProcessSuccess = (value:any):actions.RatePlanRoomProcessSuccess =>{
  return{
    type: actions.RATEPLAN_ROOM_PROCESS_SUCCESS,
    payload:value
  }
}

export const ratePlanGetRoomDetail = (value:any):actions.RatePlanGetRoomDetail =>{
  return{
    type: actions.RATEPLAN_GET_ROOM_DETAIL,
    payload:value
  }
}
export const ratePlanGetRoomDetailSuccess = (value:any):actions.RateplanGetRoomDetailSuccess =>{
  return{
    type: actions.RATEPLAN_GET_ROOM_DETAIL_SUCCESS,
    payload:value
  }
}
export const ratePlanProcessReset = ()=>{
  return{
    type: actions.RATEPLAN_PROCESS_RESET
  }
}

export const ratePlanSwitchEnable = (value:any):actions.RatePlanSwitchEnabled => {
  return {
    type: actions.RATEPLAN_SWITCH_ENABLED,
    payload:value
  }
}
