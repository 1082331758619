import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  NATIONALITY_PREVIEW,
  NATIONALITY_NEW,
  NATIONALITY_UPDATE,
  NATIONALITY_REMOVE,
  NATIONALITY_SEARCH
} from "constants/ActionTypes"

import {nationalityDataSourceUpdate,nationalityProcessSuccess,nationalityProcessReset} from "appRedux/actions/setup/market/Nationality"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* nationalityPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/nationality/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const nationalityDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.nation_id = obj.id
                    container.nation_name = obj.name
                    container.nation_code = obj.code
                    container.default_lang_id = obj.default_lang_id
                    container.language = (obj.language)?obj.language:""
                    container.default_market_id = obj.default_market_id
                    container.market = obj.market
                    container.enabled = obj.enabled
                return container
            })

            /* if no error put everything to reducer */
            yield put(nationalityDataSourceUpdate(nationalityDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreNationList",nationalityDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(nationalityProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
      }
      
    } catch (error) {
        console.log("Error on nataionality preview : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(nationalityProcessSuccess(processStatus))
    }
    
}

function* nationalitySearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreNationList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_nationality = filter(ReformalData, function(o) {
        return o.nation_name.toLowerCase().indexOf(keyword) > -1 || o.nation_code.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_nationality])]

      yield put(nationalityDataSourceUpdate(mergeData))
  }else 
      yield put(nationalityDataSourceUpdate(ReformalData))
}

function* nationalityCreateProcess({payload}){
  const {nation_code,nation_name,active} = payload

  let nationalityInfo = {code:nation_code,
                    name:nation_name,
                    enabled:active}

      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/origin/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = nationalityInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(nationalityProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(nationalityProcessSuccess(processStatus))
              yield put(nationalityProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(nationalityProcessSuccess(processStatus))
            yield put(nationalityProcessReset())
      }
}

function* nationalityUpdateProcess({payload}){
  const { nation_id,nation_name,market_id,lang_id,active} = payload

  let nationalityInfo = {}
      nationalityInfo.id = nation_id
      nationalityInfo.name = nation_name
      nationalityInfo.default_lang_id = lang_id||undefined
      nationalityInfo.default_market_id = market_id||undefined
      nationalityInfo.enabled = active
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/nationality/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = nationalityInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
          const processStatus = { status: true, action: "update", message:"Update success." }
          yield put(nationalityProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(nationalityProcessSuccess(processStatus))
          yield put(nationalityProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }        

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(nationalityProcessSuccess(processStatus))
        yield put(nationalityProcessReset())
  }
}

function* nationalityRemoveProcess({payload}){
    const originRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/origin/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : originRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(nationalityProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(nationalityProcessSuccess(processStatus))
          yield put(nationalityProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }     
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(nationalityProcessSuccess(processStatus))
      yield put(nationalityProcessReset())
    }
}


/* Watcher Saga */
export function* nationalityPreview() {
  yield takeLatest(NATIONALITY_PREVIEW, nationalityPreviewProcess)
}
export function* nationalitySearch(){
  yield takeLatest(NATIONALITY_SEARCH, nationalitySearchProcess)
}
export function* nationalityCreate(){
  yield takeEvery(NATIONALITY_NEW,nationalityCreateProcess)
}
export function* nationalityUpdate(){
  yield takeEvery(NATIONALITY_UPDATE, nationalityUpdateProcess)
}
export function* nationalityRemove(){
  yield takeEvery(NATIONALITY_REMOVE,nationalityRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(nationalityPreview),
             fork(nationalitySearch),
             fork(nationalityUpdate)])


}
