import * as actions from "appRedux/actions/setup/user/userActionType"
import {dataSerializeType,userDataSourceType,userGroupType,propertyType,permissionDataSourceType} from "./userType"

export interface userState {
  loader: boolean,
  dataSerialize?:dataSerializeType,
  dataSource: Array<userDataSourceType>,
  groupTypeInfo:Array<userGroupType>,
  propertyInfo:Array<propertyType>,
  process:any,
  keyword?:string,
  permissionDataSource:Array<permissionDataSourceType>
}

const initialState:userState = {
  loader: false,
  dataSerialize:undefined,
  dataSource:[],
  groupTypeInfo:[],
  propertyInfo:[],
  process:{status:false,action:"",message:""},
  keyword:"",
  permissionDataSource:[]
}

export default (state: userState = initialState,action: actions.UserAction): userState =>{
  switch (action.type) {
    case actions.USER_PREVIEW: {
      return {
        ...state,
        loader: false,
        dataSerialize:undefined,
        groupTypeInfo:[],
        propertyInfo:[],
        permissionDataSource:[],
        process:{status:false,action:"",message:""}
      }
    }
     case actions.USER_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case actions.USER_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
     case actions.USER_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case actions.USER_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case actions.USER_PROCESS_SUCCESS:{
        return{
            ...state,
            loader:false,
            process:action.payload
        }
    }
    case actions.USER_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case actions.USER_PROPERTY_LIST:{
      return{
        ...state,
        loader:false,
        propertyInfo:action.payload
      }
    }
    case actions.USER_GROUP_LIST:{
      return{
        ...state,
        loader:false,
        groupTypeInfo:action.payload
      }
    }
    case actions.USER_PERMISSION_PREVIEW:{
      return{
        ...state,
        loader:false,
        process:{status:false,action:"",message:""}
      }
    }
    case actions.USER_PERMISSION_DATASOURCE:{
      return{
        ...state,
        permissionDataSource:action.payload
      }
    }
    case actions.USER_PERMISSION_SEARCH:{
      return{
        ...state,
        keyword:action.payload
      }
    }
    case actions.USER_PERMISSION_UPDATE:{
      return{
        ...state,
        loader:true,
        process:{status:false,action:"",message:""}
      }
    }
    case actions.USER_PERMISSION_UPDATE_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case actions.USER_PROCESS_RESET:{
      return{
        ...state,
        process:{status:false,action:"",message:""}
      }
    }
    default:
      return state
  }
}