import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  MEDIA_PREVIEW,
  MEDIA_NEW,
  MEDIA_UPDATE,
  MEDIA_REMOVE,
  MEDIA_SEARCH
} from "constants/ActionTypes"

import {mediaDataSourceUpdate,mediaProcessSuccess,mediaProcessReset} from "appRedux/actions/setup/department/Media"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* mediaPreviewProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/media/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const mediaDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.media_id = obj.id
                    container.media_code = obj.code
                    container.media_name = obj.name
                    container.media_group_id = obj.group_id 
                    container.media_group_name = obj.group 
                    container.dept_type = obj.dept_type
                    container.require_info = obj.require_info
                    container.enabled = obj.enabled

                return container
            })
            
            /* if no error put everything to reducer */
             yield put(mediaDataSourceUpdate(mediaDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreMediaList",mediaDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(mediaProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on media preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(mediaProcessSuccess(processStatus))
        yield put(mediaProcessReset())
    }
    
}

function* mediaSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMediaList')
  const ReformalData = [...storeValue[0]] 
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_media_name = filter(ReformalData, function(o) {
        return o.media_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_media_name])]

      yield put(mediaDataSourceUpdate(mergeData))
  }else 
      yield put(mediaDataSourceUpdate(ReformalData))
}

function* mediaCreateProcess({payload}){
  const media_name = payload.media_name
  const media_code = payload.media_code
  const groupID = payload.media_group_id||null 
  const deptType = payload.dept_type
  const requireInfo = payload.require_info
  const active = payload.active

  let mediaInfo = {name:media_name,
                        code:media_code,
                        group_id:groupID,
                        dept_type:deptType,
                        require_info:requireInfo,
                        enabled:active}

    
      
      try {

            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/media/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = mediaInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(mediaProcessSuccess(processStatus))
                yield put(mediaProcessReset())
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(mediaProcessSuccess(processStatus))
              yield put(mediaProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(mediaProcessSuccess(processStatus))
            yield put(mediaProcessReset())
      }
}

function* mediaUpdateProcess({payload}){
  const media_name = payload.media_name
  const media_id = payload.media_id
  const media_code = payload.media_code
  const groupID = (!payload.media_group_id)?null:payload.media_group_id 
  const deptType = payload.dept_type
  const requireInfo = payload.require_info
  const active = payload.active

  let mediaInfo = {}
      mediaInfo.id = media_id
      mediaInfo.name = media_name
      mediaInfo.code = media_code
      mediaInfo.group_id = groupID
      mediaInfo.dept_type = deptType
      mediaInfo.require_info = requireInfo
      mediaInfo.enabled = active
  

  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/media/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = mediaInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(mediaProcessSuccess(processStatus))
            yield put(mediaProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(mediaProcessSuccess(processStatus))
          yield put(mediaProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(mediaProcessSuccess(processStatus))
        yield put(mediaProcessReset())
  }
}

function* mediaRemoveProcess({payload}){
    const mediaRemove = payload
  

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/media/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : mediaRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(mediaProcessSuccess(processStatus))
          yield put(mediaProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(mediaProcessSuccess(processStatus))
          yield put(mediaProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(mediaProcessSuccess(processStatus))
      yield put(mediaProcessReset())
    }
}

/* Watcher Saga */
export function* mediaPreview() {
  yield takeLatest(MEDIA_PREVIEW, mediaPreviewProcess)
}
export function* mediaSearch(){
  yield takeLatest(MEDIA_SEARCH, mediaSearchProcess)
}
export function* mediaCreate(){
  yield takeEvery(MEDIA_NEW,mediaCreateProcess)
}
export function* mediaUpdate(){
  yield takeEvery(MEDIA_UPDATE, mediaUpdateProcess)
}
export function* mediaRemove(){
  yield takeEvery(MEDIA_REMOVE,mediaRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(mediaPreview),
             fork(mediaSearch),
             fork(mediaCreate),
             fork(mediaUpdate),
             fork(mediaRemove)])
}
