import * as actions from "./roomplanEventActionType"

export const roomplanEventPreview = (payload?: any): actions.RoomPlanEventRequest => {
  return {
    type: actions.ROOMPLAN_EVENT_REQUEST,
    payload
  }
}

export const roomplanEventDatasouceSuccess = (payload: any): actions.RoomPlanEventDataSourceSuccess => {
  return {
    type: actions.ROOMPLAN_EVENT_DATASOURCE_SUCCESS,
    payload
  }
}
export const roomplanEventDragOOOChangeDate = (payload: any): actions.RoomPlanEventDragOOOChangeDate => {
  return {
    type: actions.ROOMPLAN_EVENT_DRAG_OOO_CHANGE_DATE,
    payload
  }
}
export const roomplanEventDragBlockChangeRoom = (payload: any): actions.RoomPlanEventDragBlockChangeRoom => {
  return {
    type: actions.ROOMPLAN_EVENT_DRAG_BLOCK_CHANGE_ROOM,
    payload
  }
}
export const roomplanEventDragInhouseChangeDate = (payload: any): actions.RoomPlanEventDragInhouseChangeDate => {
  return {
    type: actions.ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_DATE,
    payload
  }
}
export const roomplanEventDragInhouseChangeRoom = (payload: any): actions.RoomPlanEventDragInhouseChangeRoom => {
  return {
    type: actions.ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_ROOM,
    payload
  }
}

export const roomplanEventProcessSuccess = (payload: any): actions.RoomPlanEventProcessSuccess => {
  return {
    type: actions.ROOMPLAN_EVENT_PROCESS_SUCCESS,
    payload
  }
}

export const roomplanEventProcessReset = (): actions.RoomPlanEventProcessReset => {
  return {
    type: actions.ROOMPLAN_EVENT_PROCESS_RESET
  }
}
