import {
    ROOM_GROUP_PREVIEW,
    ROOM_GROUP_NEW,
    ROOM_GROUP_UPDATE,
    ROOM_GROUP_REMOVE,
    ROOM_GROUP_SEARCH,
    ROOM_GROUP_PROCESS_SUCCESS,
    ROOM_GROUP_DATASOURCE_SUCCESS,
    ROOM_GROUP_PROCESS_RESET
  } from "constants/ActionTypes"

  export const roomGroupPreview = (value) => {
    return {
      type: ROOM_GROUP_PREVIEW,
      payload:value
    }
  }

  export const roomGroupCreate =(value) =>{
    return {
      type:ROOM_GROUP_NEW,
      payload:value
    }
  }

  export const roomGroupUpdate = (value) =>{
    return {
      type:ROOM_GROUP_UPDATE,
      payload: value
    }
  }

  export const roomGroupRemove = (value)=>{
    return {
      type:ROOM_GROUP_REMOVE,
      payload:value
    }
  }

  export const roomGroupSearch = (keyword) =>{
    return {
      type: ROOM_GROUP_SEARCH,
      payload:keyword
    }
  }

  export const roomGroupProcessSuccess = (value) =>{
    return{
      type:ROOM_GROUP_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const roomGroupDataSourceUpdate = (value) => {
    return {
      type: ROOM_GROUP_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const roomGroupProcessReset = () =>{
    return{
      type:ROOM_GROUP_PROCESS_RESET
    }
  }

