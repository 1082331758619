import { all, fork, put, takeEvery, takeLatest, call } from "redux-saga/effects"
import { IndexedDBAdd } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"
import * as actionTypes from "appRedux/actions/setup/mealType/MealTypeActionType"
import * as actionCreators from "appRedux/actions/setup/mealType"
import { userSignOut } from "appRedux/actions/Auth"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { ApiResponse } from "constants/ActionTypes"

function* mealTypePreviewProcess() {
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/mealtype/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
      const mealTypeDataSource = response.data
      /* if no error put everything to reducer */
      yield put(actionCreators.mealTypeDataSourceUpdate(mealTypeDataSource))
      
      /* keep data to indexdb case create listbox */
      const mealTypeDataListBox = mealTypeDataSource.map((obj: any, key: number) => {
          return { key, ...obj }
      })
      yield IndexedDBAdd("ListBoxDB", "objStoreMealTypeList", mealTypeDataListBox)

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "mealTypePreview", message }
      yield put(actionCreators.mealTypeDataSourceUpdate([]))
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
      yield put(actionCreators.mealTypeProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error on saga mealtype : ", error)
    const processStatus = { status: false, action: "mealTypePreview", message: error }
    yield put(actionCreators.mealTypeDataSourceUpdate([]))
    yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    yield put(actionCreators.mealTypeProcessReset())
  }

}

function* mealTypeCreateProcess({ payload }: actionTypes.MealTypeNew) {

  let mealTypeInfo = {} as any
  mealTypeInfo.name = payload.mealTypeName
  mealTypeInfo.abf = payload.abf
  mealTypeInfo.abf_child = payload.abf_child
  mealTypeInfo.abf_extra_adult = payload.abf_extra_adult
  mealTypeInfo.abf_extra_child = payload.abf_extra_child
  mealTypeInfo.lunch = payload.lunch
  mealTypeInfo.lunch_child = payload.lunch_child
  mealTypeInfo.lunch_extra_adult = payload.lunch_extra_adult
  mealTypeInfo.lunch_extra_child = payload.lunch_extra_child
  mealTypeInfo.dinner = payload.dinner
  mealTypeInfo.dinner_child = payload.dinner_child
  mealTypeInfo.dinner_extra_adult = payload.dinner_extra_adult
  mealTypeInfo.dinner_extra_child = payload.dinner_extra_child
  mealTypeInfo.enabled = payload.enabled

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/mealtype/create/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = mealTypeInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "mealTypeCreate", message: result_msg||"", result_code }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "mealTypeCreate", message }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
      yield put(actionCreators.mealTypeProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "mealTypeCreate", message: error }
    yield put(actionCreators.mealTypeProcessSuccess(processStatus))
  }
}

function* mealTypeUpdateProcess({ payload }: actionTypes.MealTypeUpdate) {

  let mealTypeInfo = {} as any
  mealTypeInfo.id = payload.mealTypeID
  mealTypeInfo.name = payload.mealTypeName
  mealTypeInfo.abf = payload.abf
  mealTypeInfo.abf_child = payload.abf_child
  mealTypeInfo.abf_extra_adult = payload.abf_extra_adult
  mealTypeInfo.abf_extra_child = payload.abf_extra_child
  mealTypeInfo.lunch = payload.lunch
  mealTypeInfo.lunch_child = payload.lunch_child
  mealTypeInfo.lunch_extra_adult = payload.lunch_extra_adult
  mealTypeInfo.lunch_extra_child = payload.lunch_extra_child
  mealTypeInfo.dinner = payload.dinner
  mealTypeInfo.dinner_child = payload.dinner_child
  mealTypeInfo.dinner_extra_adult = payload.dinner_extra_adult
  mealTypeInfo.dinner_extra_child = payload.dinner_extra_child
  mealTypeInfo.enabled = payload.enabled

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/mealtype/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = mealTypeInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "mealTypeUpdate", message: result_msg||"", result_code }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "mealTypeUpdate", message }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
      yield put(actionCreators.mealTypeProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "mealTypeUpdate", message: error }
    yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    yield put(actionCreators.mealTypeProcessReset())
  }
}

function* mealTypeRemoveProcess({ payload }: actionTypes.MealTypeRemove) {
  const mealTypeRemove = payload
  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/mealtype/delete/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { id: mealTypeRemove }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const processStatus = { status: true, action: "mealTypeDelete", message: "Delete success." }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "mealTypeDelete", message }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
      yield put(actionCreators.mealTypeProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  }
  catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "mealTypeDelete", message: error }
    yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    yield put(actionCreators.mealTypeProcessReset())
  }
}

function* mealTypeSwitchEnableProcess({ payload }: actionTypes.MealTypeSwitchEnabled) {
  const { mealID, enabled } = payload

  let mealTypeInfo = {} as any
  mealTypeInfo.id = mealID
  mealTypeInfo.enabled = enabled


  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/mealtype/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = mealTypeInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const processStatus = { status: true, action: "mealTypeSwitch", message: "Update success." }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "mealTypeSwitch", message }
      yield put(actionCreators.mealTypeProcessSuccess(processStatus))
      yield put(actionCreators.mealTypeProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "mealTypeSwitch", message: error }
    yield put(actionCreators.mealTypeProcessSuccess(processStatus))
    yield put(actionCreators.mealTypeProcessReset())
  }
}

/* Watcher Saga */
export function* mealTypePreview() {
  yield takeLatest(actionTypes.MEALTYPE_PREVIEW, mealTypePreviewProcess)
}
export function* mealTypeCreate() {
  yield takeEvery(actionTypes.MEALTYPE_NEW, mealTypeCreateProcess)
}
export function* mealTypeUpdate() {
  yield takeEvery(actionTypes.MEALTYPE_UPDATE, mealTypeUpdateProcess)
}
export function* mealTypeRemove() {
  yield takeEvery(actionTypes.MEALTYPE_REMOVE, mealTypeRemoveProcess)
}
export function* mealTypeSwitchEnable() {
  yield takeEvery(actionTypes.MEALTYPE_SWITCH_ENABLED, mealTypeSwitchEnableProcess)
}

export default function* rootSaga() {
  yield all([fork(mealTypePreview),
  fork(mealTypeCreate),
  fork(mealTypeUpdate),
  fork(mealTypeRemove),
  fork(mealTypeSwitchEnable)])


}
