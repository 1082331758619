export const INVENTORY_RATECALENDAR = "INVENTORY_RATECALENDAR"
export const INVENTORY_RATECALENDAR_SUCCESS = "INVENTORY_RATECALENDAR_SUCCESS"
export const INVENTORY_RATECALENDAR_FAIL = "INVENTORY_RATECALENDAR_FAIL"
export const INVENTORY_RATECALENDAR_CHANGING = "INVENTORY_RATECALENDAR_CHANGING" // Store data when user change data in mode change
export const INVENTORY_RATECALENDAR_UPDATE = "INVENTORY_RATECALENDAR_UPDATE"
export const INVENTORY_RATECALENDAR_UPDATE_FAIL = 'INVENTORY_RATECALENDAR_UPDATE_FAIL'
export const INVENTORY_RATECALENDAR_UPDATE_SUCCESS = "INVENTORY_RATECALENDAR_UPDATE_SUCCESS"
export const INVENTORY_BULKUPDATE_REQUEST = 'INVENTORY_BULKUPDATE_REQUEST'
export const INVENTORY_BULKUPDATE_SUCCESS = 'INVENTORY_BULKUPDATE_SUCCESS'
export const INVENTORY_BULKUPDATE_FAIL = 'INVENTORY_BULKUPDATE_FAIL'
export const INVENTORY_BULKUPDATE_RESET = 'INVENTORY_BULKUPDATE_RESET'