import * as actions from "./UserLogsActionType"

export const userLogsPreview = (payload?:any): actions.UserLogsRequest => {
  return {
    type: actions.USER_LOGS_REQUEST,
    payload
  }
}
export const userLogsProcessSuccess = (payload:any):actions.UserLogsProcessSuccess =>{
  return{
    type: actions.USER_LOGS_PROCESS_SUCCESS,
    payload
  }
}
export const userLogsDataSourceSuccess = (payload:any):actions.UserLogsDataSourceSuccess => {
  return {
    type: actions.USER_LOGS_DATASOURCE_SUCCESS,
    payload
  }
}
export const userLogsDetailDataSourceSuccess = (payload:any):actions.UserLogsDetailDataSourceSuccess => {
  return {
    type: actions.USER_LOGS_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}
export const userLogsSetFilter = (payload:any):actions.UserLogsSetFilter=>{
  return{
    type: actions.USER_LOGS_SET_FILTER,
    payload
  }
}
export const userLogsProcessReset = () =>{
  return{
    type:actions.USER_LOGS_PROCESS_RESET
  } 
}
