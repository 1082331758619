import { combineReducers } from "redux"
import { routerReducer } from "react-router-redux"

import Inventory from "./Inventory"
import SettingsReducer from './settings-reducers'
import CommonReducer from './common-reducers'
import Auth from "./Auth"
import User from "./setup/user"
import ChannelType from "./setup/channel/ChannelType"
import ChannelGroup from "./setup/channel/ChannelGroup"
import ChannelList from "./setup/channel/ChannelList"
import SalesPerson from "./setup/channel/SalesPerson"
import Origin from "./setup/market/Origin"
import MarketZone from "./setup/market/MarketZone"
import Country from "./setup/market/Country"
import Language from "./setup/market/Language"
import Nationality from "./setup/market/Nationality"
import FolioPattern from "./setup/folio/FolioPattern"
import FolioPatternDetail from "./setup/folio/FolioPatternDetail"
import RoomGroup from "./setup/roomInformation/RoomGroup"
import RoomType from "./setup/roomInformation/RoomType"
import BedType from "./setup/roomInformation/BedType"
import DepartmentGroup from "./setup/department/DepartmentGroup"
import Department from "./setup/department/Department"
import MediaGroup from "./setup/department/MediaGroup"
import Media from "./setup/department/Media"
import Inclusion from "./setup/miscellaneous/Inclusion"
import Vip from "./setup/miscellaneous/Vip"
import ReservationType from "./setup/miscellaneous/ReservationType"
import RatePlan from "./setup/rate/RatePlan"
import ListBoxsDB from "./Listboxs"
import Facility from "./setup/roomInformation/Facility"
import Building from "./setup/roomInformation/Building"
import Location from "./setup/roomInformation/Location"
import Exposure from "./setup/roomInformation/Exposure"
import RoomInfo from "./setup/roomInformation/RoomInfo"
import TitleName from "./setup/miscellaneous/TitleName"
import Occupation from "./setup/miscellaneous/Occupation"
import OutOfOrder from "./setup/roomInformation/OutOfOrder"
import Social from "./setup/miscellaneous/Social"
import City from "./setup/market/City"
import Guest from "./Guest"
import MessageToGuest from "./MessageToGuest"
import FollowUp from "./FollowUp"
import UserProfile from "./UserProfile"
import Notifications from "./Notifications"
import SystemConfig from "./setup/systemSetup/systemConfig"
import ReportMenuReducer from './report/reportMenu-reducer'
import ReportOptionsReducer from './report/reportOptions-reducer'
import RateApprove from "./nightAudit/rateApprove"
import EndDay from "./nightAudit/endDay"
import RoomAvail from './roomAvail'
import CashierGuest from './cashier/guest'
import CashierBooking from './cashier/booking'
import CashierCashSale from './cashier/cashSale'
import CashierTransfer from "./cashier/transfer"
import CashierFolioHistory from "./cashier/folioHistory"

import CashierPostingJournal from "./cashier/posting"
import CashierChargeSchedule from "./cashier/chargeSchedule"
import Reservations from "./rsv/Reservation"
import ExpectedArrival from "./expected/ExpectedArrival"
import ExpectedDeparture from "./expected/ExpectedDeparture"
import ChargeReservation from "./chargeSchedule/ChargeReservation"
import ChargeInhouse from "./chargeSchedule/ChargeInhouse"
import RoomStatus from "./houseKeeping/roomStatus"

import CurrentLocation from "./currentLocation"
import GuestInHouse from "./registration/GuestInhouse"
import BlockRoom from "./rsv/BlockRoom"
import OccupancyCalendar from "./calendar/occupancyCalendar"
import Itemizer from "./setup/department/Itemizer"
import BatchReport from "./setup/report/BatchReport"
import UserLogs from "./userLogs"

import RoomPlanResource from "./roomPlan/roomplanResource"
import RoomplanEvent from "./roomPlan/roomplanEvent"

import MealType from "./setup/mealType"
import RateGroup from "./setup/rate/RateGroup"
import AutoPostRoom from "./nightAudit/autoPostRoom"
import IssueCard from "./rsv/IssueCard"
import GuestIssueCard from "./registration/GuestIssueCard"

import IssueLog from "./issueLog"
import CheckinMoreGuest from "./rsv/CheckinMoreGuest"
import CashierFolioEdit from "./cashier/folioEdit"

const reducers = combineReducers({
    routing: routerReducer,
    inventory: Inventory,
    settings: SettingsReducer,
    common: CommonReducer,
    auth: Auth,
    user: User,
    channelList: ChannelList,
    channelType: ChannelType,
    channelGroup: ChannelGroup,
    salesPerson: SalesPerson,
    origin: Origin,
    marketZone: MarketZone,
    country: Country,
    language: Language,
    nationality: Nationality,
    folioPattern: FolioPattern,
    roomGroup: RoomGroup,
    roomType: RoomType,
    departmentGroup: DepartmentGroup,
    department: Department,
    mediaGroup: MediaGroup,
    media: Media,
    inclusion: Inclusion,
    vip: Vip,
    bedType: BedType,
    reservationType: ReservationType,
    ratePlan: RatePlan,
    folioPatternDetail: FolioPatternDetail,
    listBoxs: ListBoxsDB,
    facility: Facility,
    building: Building,
    location: Location,
    exposure: Exposure,
    roomInfo: RoomInfo,
    titleName: TitleName,
    occupation: Occupation,
    outoforder: OutOfOrder,
    city: City,
    social: Social,
    guest: Guest,
    messageToGuest: MessageToGuest,
    followUp: FollowUp,
    userProfile: UserProfile,
    notifications: Notifications,
    guestInHouse: GuestInHouse,
    systemConfig: SystemConfig,
    report: ReportMenuReducer,
    reportOptions: ReportOptionsReducer,
    rateApprove: RateApprove,
    endDay: EndDay,
    roomAvail: RoomAvail,
    cashierGuest: CashierGuest,
    cashierBooking: CashierBooking,
    cashierCashSale: CashierCashSale,
    cashierTransfer: CashierTransfer,
    cashierFolioHistory: CashierFolioHistory,
    cashierPostingJournal: CashierPostingJournal,
    cashierChargeSchedule: CashierChargeSchedule,
    reservations: Reservations,
    expectedArrival: ExpectedArrival,
    expectedDeparture: ExpectedDeparture,
    chargeReservation: ChargeReservation,
    chargeInhouse: ChargeInhouse,
    roomStatus: RoomStatus,
    currentLocation: CurrentLocation,
    blockRoom: BlockRoom,
    occupancyCalendar: OccupancyCalendar,
    itemizer: Itemizer,
    batchReport: BatchReport,
    userLogs: UserLogs,
    roomplanResouce: RoomPlanResource,
    roomplanEvent: RoomplanEvent,
    mealType: MealType,
    rateGroup: RateGroup,
    autoPostRoom:AutoPostRoom,
    issueCard:IssueCard,
    guestIssueCard:GuestIssueCard,
    issueLog:IssueLog,
    checkinMoreGuestState:CheckinMoreGuest,
    cashierFolioEdit:CashierFolioEdit
})

type Reducers = ReturnType<typeof reducers>
const rootReducer = (state: Reducers | undefined, action: any) => {
    return reducers(state, action)
}

export default rootReducer