import * as actions from "./GuestInhouseActionType"

export const guestInhousePreview = (payload?: any,sorter?:any): actions.GuestInhouseRequest => {
  return {
    type: actions.GUEST_INHOUSE_REQUEST,
    payload,
    sorter
  }
}

export const guestInhouseNew = (payload: any): actions.GuestInhouseNew => {
  return {
    type: actions.GUEST_INHOUSE_NEW,
    payload
  }
}

export const guestInhouseUpdate = (payload: any): actions.GuestInhouseUpdate => {
  return {
    type: actions.GUEST_INHOUSE_UPDATE,
    payload
  }
}

export const guestInhouseProcessSuccess = (payload:any):actions.GuestInhouseProcessSuccess =>{
  return{
    type: actions.GUEST_INHOUSE_PROCESS_SUCCESS,
    payload
  }
}

export const guestInhouseDataSourceSuccess = (payload:any):actions.GuestInhouseDataSourceSuccess => {
  return {
    type: actions.GUEST_INHOUSE_DATASOURCE_SUCCESS,
    payload
  }
}

export const guestInhouseDetailPreview = (payload:number):actions.GuestInhouseDetailRequest=>{
  return{
    type: actions.GUEST_INHOUSE_DETAIL_REQUEST,
    payload
  }
}

export const guestInhouseDetailDataSourceSuccess = (payload:any):actions.GuestInhouseDetailDataSourceSuccess=>{
  return{
    type: actions.GUEST_INHOUSE_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}

export const guestInhouseRateplanPreview = (payload:any):actions.GuestInhouseRatePlanRequest=>{
  return{
    type: actions.GUEST_INHOUSE_RATEPLAN_REQUEST,
    payload
  }
}

export const guestInhouseRatePlanDataSouceSuccess = (payload:any):actions.GuestInhouseRatePlanDataSourceSuccess=>{
  return{
    type: actions.GUEST_INHOUSE_RATEPLAN_DATASOUCE_SUCCESS,
    payload
  }
}

export const guestInhouseSetFilter = (payload:any):actions.GuestInhouseSetFilter => {
  return {
    type: actions.GUEST_INHOUSE_SET_FILTER,
    payload
  }
}

export const guestInhouseChangeBookingStatus = (payload:any):actions.GuestInhouseChangeBookingStatus=>{
  return{
    type: actions.GUEST_INHOUSE_CHANGE_BOOKING_STATUS,
    payload
  }
}

export const guestInhouseBookingSummaryPreview = (payload:any):actions.GuestInhouseBookingSummaryRequest=>{
  return{
    type: actions.GUEST_INHOUSE_BOOKING_SUMMARY_REQUEST,
    payload
  }
}

export const guestInhouseBookingSummarySuccess = (payload:any):actions.GuestInhouseBookingSummarySuccess=>{
  return{
    type: actions.GUEST_INHOUSE_BOOKING_SUMMARY_SUCCESS,
    payload
  }
}
export const guestInhouseCheckOut = (payload:any):actions.GuestInhouseCheckOut =>{
  return{
    type:actions.GUEST_INHOUSE_CHECK_OUT,
    payload
  }
}

export const guestInHouseCancelCheckIn = (payload:any):actions.GuestInhouseCancelCheckIn =>{
  return{
    type:actions.GUEST_INHOUSE_CANCEL_CHECK_IN,
    payload
  }
}

export const guestInhouseCancelCheckOut = (payload:any):actions.GuestInhouseCancelCheckOut => {
  return{
    type:actions.GUEST_INHOUSE_CANCEL_CHECK_OUT,
    payload
  }
}

export const guestInhouseVerifyrate = (payload:any):actions.GuestInhouseVerifyRate=>{
  return{
    type:actions.GUEST_INHOUSE_VERIFY_RATE,
    payload
  }
}

export const guestInhouseCheckin = (payload:any):actions.GuestInhouseCheckin=>{
  return{
    type:actions.GUSET_INHOUSE_CHECKIN,
    payload
  }
}

export const guestInhouseCheckinVerfify = (payload:any):actions.GuestInhouseCheckinVerify=>{
  return{
    type:actions.GUEST_INHOUSE_CHECKIN_VERIFY,
    payload
  }
}

export const guestInhouseCheckinRoomOriginal = (payload:any):actions.GuestInhouseCheckinRoomOriginal=>{
  return{
    type:actions.GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL,
    payload
  }
}

export const guestInhouseCheckinRoomOriginalReset = ():actions.GuestInhouseCheckinRoomOriginalReset=>{
  return{
    type:actions.GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL_RESET
  }
}

export const guestInhouseProcessReset = () =>{
  return{
    type:actions.GUEST_INHOUSE_PROCESS_RESET
  } 
}
