import * as actions from "appRedux/actions/expected/ExpectedArrivalActionType"

export interface expectedArrivalState {
    loader: boolean,
    keyword:string,
    dataSource: [],
    paginations: any,
    filterExpectArrival: any,
    process:any
}

const initialState: expectedArrivalState = {
    loader: false,
    keyword:'',
    dataSource: [],
    paginations: [],
    filterExpectArrival: [],
    process:{ status:false,action:"",message:""}
}

export default (state: expectedArrivalState = initialState,action: actions.ExpectedArrivalAction): expectedArrivalState =>{
    switch (action.type) {
    case actions.EXPECTED_ARRIVAL_REQUEST:{
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.EXPECTED_ARRIVAL_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.EXPECTED_ARRIVAL_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.EXPECTED_ARRIVAL_SET_FILTER:{
        return{
            ...state,
            filterExpectArrival:action.payload
        }
    }
    case actions.EXPECTED_ARRIVAL_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}