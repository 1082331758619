import {
    END_DAY_PREVIEW,
    END_DAY_RUN,
    END_DAY_REALTIME_UPDATE,
    END_DAY_PROCESS_SUCCESS,
    END_DAY_DATASOURCE_SUCCESS,
    END_DAY_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
    loader: false,
    dataSource:[],
    process:{}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case END_DAY_PREVIEW: {
            return {
                ...state,
                loader: true,
                process:{status:false,action:"",message:""}
            }
        }
        case END_DAY_RUN:{
            return{
            ...state,
            loader:true,
            process:{status:false,action:"",message:""}
            }
        }
        case END_DAY_REALTIME_UPDATE: {
            return {
                ...state,
                process:{status:false,action:"",message:""},
                dataSerialize:action.payload
            }
        }
        case END_DAY_PROCESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                process:action.payload
            }
        }
        case END_DAY_DATASOURCE_SUCCESS:{
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case END_DAY_PROCESS_RESET:{
            return{
                ...state,
                process:{}
            }
        }
        default:
            return state
    }
}