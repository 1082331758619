import * as actions from "appRedux/actions/setup/mealType/MealTypeActionType"
import {mealTypeState} from "./MealType"

const initialState: mealTypeState = {
	loader: false,
	dataSource:[],
	process:{status:false,action:"",message:""}
}

export default (state: mealTypeState = initialState,action: actions.MealTypeAction): mealTypeState =>{
    switch (action.type) {
    case actions.MEALTYPE_PREVIEW:{
        return {
            ...state,
			loader: false,
			process:{status:false,action:"",message:""}
        }
    }
    case actions.MEALTYPE_NEW: {
		return {
			...state,
			loader: true,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.MEALTYPE_UPDATE:{
		return{
			...state,
			loader:true,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.MEALTYPE_REMOVE: {
		return {
			...state,
			loader: false,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.MEALTYPE_PROCESS_SUCCESS:{
		return{
		...state,
		loader:false,
		process:action.payload
		}
	}
	case actions.MEALTYPE_DATASOURCE_SUCCESS:{
		return {
			...state,
			loader: false,
			dataSource: action.payload
		}
	}
	case actions.MEALTYPE_PROCESS_RESET:{
		return{
		...state,
		process:{status:false,action:"",message:""}
		}
	}
	default:
		return state
    }
}