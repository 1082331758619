import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  CHANNEL_TYPE_PREVIEW,
  CHANNEL_TYPE_NEW,
  CHANNEL_TYPE_UPDATE,
  CHANNEL_TYPE_REMOVE,
  CHANNEL_TYPE_SEARCH
} from "constants/ActionTypes"

import {channelTypeDataSourceUpdate,channelTypeProcessSuccess,channelTypeProcessReset} from "appRedux/actions/setup/channel/ChannelType"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* channelTypePreviewProcess({payload}){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/channelType/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const channelDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((channel,key) =>{
              const container = {}
                    container.key = key
                    container.channel_type_id = channel.id
                    container.channel_type_name = channel.name
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(channelTypeDataSourceUpdate(channelDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreChannelTypeList",channelDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(channelTypeProcessSuccess(processStatus))
            yield put(channelTypeProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on saga channel type : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(channelTypeProcessSuccess(processStatus))
        yield put(channelTypeProcessReset())
    }
    
}

function* channelTypeSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreChannelTypeList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_channel_type_name = filter(ReformalData, function(o) {
        return o.channel_type_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_channel_type_name])]

      yield put(channelTypeDataSourceUpdate(mergeData))
  }else 
      yield put(channelTypeDataSourceUpdate(ReformalData))
}

function* channelTypeCreateProcess({payload}){
  const {channel_type_name} = payload
  let channelTypeInfo = {name:channel_type_name}
  
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/channelType/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = channelTypeInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(channelTypeProcessSuccess(processStatus))
                yield put(channelTypeProcessReset())
            }else{
                const {message,data} = response.data
                const processStatus = { status: false, action: "create", message }
                yield put(channelTypeProcessSuccess(processStatus))
                yield put(channelTypeProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(channelTypeProcessSuccess(processStatus))
            yield put(channelTypeProcessReset())
      }
}

function* channelTypeUpdateProcess({payload}){
  const { channel_type_name , channel_type_id } = payload
  let channelInfo = {}
      channelInfo.id = channel_type_id
      channelInfo.name = channel_type_name
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channelType/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = channelInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(channelTypeProcessSuccess(processStatus))
            yield put(channelTypeProcessReset())
        }else{
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(channelTypeProcessSuccess(processStatus))
            yield put(channelTypeProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }           

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(channelTypeProcessSuccess(processStatus))
        yield put(channelTypeProcessReset())
  }
}

function* channelTypeRemoveProcess({payload}){
    const channelTypeRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channelType/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : channelTypeRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(channelTypeProcessSuccess(processStatus))
          yield put(channelTypeProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(channelTypeProcessSuccess(processStatus))
          yield put(channelTypeProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }            
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(channelTypeProcessSuccess(processStatus))
      yield put(channelTypeProcessReset())
    }
}

/* Watcher Saga */
export function* channelTypePreview() {
  yield takeLatest(CHANNEL_TYPE_PREVIEW, channelTypePreviewProcess)
}
export function* channelTypeSearch(){
  yield takeLatest(CHANNEL_TYPE_SEARCH, channelTypeSearchProcess)
}
export function* channelTypeCreate(){
  yield takeEvery(CHANNEL_TYPE_NEW,channelTypeCreateProcess)
}
export function* channelTypeUpdate(){
  yield takeEvery(CHANNEL_TYPE_UPDATE, channelTypeUpdateProcess)
}
export function* channelTypeRemove(){
  yield takeEvery(CHANNEL_TYPE_REMOVE,channelTypeRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(channelTypePreview),
             fork(channelTypeSearch),
             fork(channelTypeCreate),
             fork(channelTypeUpdate),
             fork(channelTypeRemove)])


}
