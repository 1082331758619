import * as actions from "./ExpectedArrivalActionType"

export const expectedArrivalPreview = (payload?: any): actions.ExpectedArrivalRequest => {
  return {
    type: actions.EXPECTED_ARRIVAL_REQUEST,
    payload
  }
}

export const expectedArrivalProcessSuccess = (payload:any):actions.ExpectedArrivalProcessSuccess =>{
  return{
    type: actions.EXPECTED_ARRIVAL_PROCESS_SUCCESS,
    payload
  }
}

export const expectedArrivalDataSourceSuccess = (payload:any):actions.ExpectedArrivalDataSourceSuccess => {
  return {
    type: actions.EXPECTED_ARRIVAL_DATASOURCE_SUCCESS,
    payload
  }
}
export const expectedArrivalSetFilter = (payload:any):actions.ExpectedArrivalSetFilter => {
  return {
    type: actions.EXPECTED_ARRIVAL_SET_FILTER,
    payload
  }
}

export const expectedArrivalProcessReset = () =>{
  return{
    type:actions.EXPECTED_ARRIVAL_PROCESS_RESET
  } 
}
