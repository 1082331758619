import * as actions from "./itemizerActionType"

export const itemizerPreview = (payload?: any): actions.ItemizerRequest => {
  return {
    type: actions.ITEMIZER_REQUEST,
    payload
  }
}
export const itemizerProcessSuccess = (payload:any):actions.ItemizerProcessSuccess =>{
  return{
    type: actions.ITEMIZER_PROCESS_SUCCESS,
    payload
  }
}
export const itemizerDataSourceSuccess = (payload:any):actions.ItemizerDataSourceSuccess => {
  return {
    type: actions.ITEMIZER_DATASOURCE_SUCCESS,
    payload
  }
}
export const itemizerNew = (value:any):actions.ItemizerNew =>{
  return{
    type: actions.ITEMIZER_NEW,
    payload:value
  }
}
export const itemizerUpdate = (value:any):actions.ItemizerUpdate =>{
  return{
    type: actions.ITEMIZER_UPDATE,
    payload:value
  }
}
export const itemizerRemove = (value:any):actions.ItemizerRemove =>{
  return{
    type: actions.ITEMIZER_REMOVE,
    payload:value
  }
}
export const itemizerProcessReset = () =>{
  return{
    type:actions.ITEMIZER_PROCESS_RESET
  } 
}
