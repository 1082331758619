import * as actions from "appRedux/actions/userLogs/UserLogsActionType"
import {filterType ,userLosgStateType} from "./userLogType"

export const filterDefaultState:filterType = {
    searchText: undefined, 
    startDate: undefined,
    category: "booking",
    endDate: undefined ,
    user_name: undefined,
    log_type: undefined,
    log_date: undefined,
    log_shift: undefined,
    pagination:{current:1,pageSize:20,showSizeChanger:false,total:20},
    sorter:{}
}

export const initialState: userLosgStateType = {
    loader: false,
	onProcessing:false,
    dataSource: [],
    filterUserLogs: filterDefaultState,
    process:{ status:false,action:"",message:""}
}
export default (state: userLosgStateType = initialState,action: actions.UserLogsAction): userLosgStateType =>{
    switch (action.type) {
    case actions.USER_LOGS_REQUEST:{
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.USER_LOGS_PROCESS_SUCCESS:{
        return{
            ...state,
			loader:false,
			onProcessing:false,
            process:action.payload
        }
    }
    case actions.USER_LOGS_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.USER_LOGS_SET_FILTER:{
        return{
            ...state,
            filterUserLogs:action.payload
        }
    }
    case actions.USER_LOGS_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}