import * as actions from "appRedux/actions/setup/roomInformation/BedTypeActionType"


export interface initialStateType {
  loader: boolean,
  dataSerialize:any,
  dataSource: Array<any>,
  process:any,
  keyword?:string
}

const INIT_STATE:initialStateType = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{status:false,action:"",message:""},
  keyword:""
}

export default (state:initialStateType = INIT_STATE, action:actions.BedTypeAction):initialStateType => {
  switch (action.type) {
    case actions.BED_TYPE_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case actions.BED_TYPE_NEW: {
      return {
          ...state,
          loader: true,
      }
    }
    case actions.BED_TYPE_UPDATE:{
        return{
          ...state,
          loader:true,
          dataSerialize:action.payload
        }
    }
    case actions.BED_TYPE_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case actions.BED_TYPE_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case actions.BED_TYPE_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case actions.BED_TYPE_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case actions.BED_TYPE_PROCESS_RESET:{
      return{
        ...state,
        process:{status:false,action:"",message:""}
      }
    }
    default:
      return state
  }
}