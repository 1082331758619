import React from "react"
import { Provider } from "react-redux"
import { Router, Route, Switch } from "react-router-dom"

import "assets/vendors/style"
import "styles/wieldy.less"
import configureStore, { history } from "./appRedux/store"

import App from "./containers/App/index"

export const store = configureStore()

const NextApp = () =>
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/" component={App}/>
      </Switch>
      </Router>
  </Provider>


export default NextApp