import {
  LOCATION_PREVIEW,
  LOCATION_NEW,
  LOCATION_UPDATE,
  LOCATION_REMOVE,
  LOCATION_PROCESS_SUCCESS,
  LOCATION_DATASOURCE_SUCCESS,
  LOCATION_SEARCH,
  LOCATION_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOCATION_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case LOCATION_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case LOCATION_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case LOCATION_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case LOCATION_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case LOCATION_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case LOCATION_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case LOCATION_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}