import {all, fork, put, takeEvery,takeLatest, call,select } from "redux-saga/effects"

import {
    RATE_APPROVE_PREVIEW,
    RATE_APPROVE_UPDATE,
    RATE_APPROVE_UPDATE_ALL
} from "constants/ActionTypes"

import {rateApproveDataSourceUpdate,
        rateApproveProcessSuccess,
        rateApproveProcessReset} from "appRedux/actions/nightAudit/rateApprove"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import moment from "moment"

function* rateApprovePreviewProcess(){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/nightAudit/rateApproval/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const rateApproveInfo = response.data.map((obj) =>{
                const container = {}
                        container.register_id = obj.register_id
                        container.approved = obj.approved
                        container.approve_user = obj.approve_user
                        container.approve_time = obj.approve_time 
                        container.room_number = obj.room_number 
                        container.room_type = obj.room_type
                        container.room_code = obj.room_code||""
                        container.arrival = obj.arrival
                        container.departure = obj.departure
                        container.checkin_time = obj.checkin_time
                        container.checkin_user = obj.checkin_user
                        container.guest_name = obj.guest_name
                        container.group_name = obj.group_name
                        container.channel_name = obj.channel_name||""
                        container.rate_plan = obj.rate_plan
                        container.actual_rate_total = obj.actual_rate_total
                        container.actual_rate_details = obj.actual_rate_details
                        container.booking_rate_total = obj.booking_rate_total
                        container.booking_rate_diff = obj.booking_rate_diff
                        container.booking_rate_details = obj.booking_rate_details
                        container.standard_rate_total = obj.standard_rate_total
                        container.standard_rate_diff = obj.standard_rate_diff
                        container.standard_rate_details = obj.standard_rate_details

                    return container
                })
                
                /* if no error put everything to reducer */
                yield put(rateApproveDataSourceUpdate(rateApproveInfo))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(rateApproveProcessSuccess(processStatus))
                yield put(rateApproveProcessReset())
                if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                    yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on department preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(rateApproveProcessSuccess(processStatus))
        yield put(rateApproveProcessReset())
    }
    
}

function* rateApproveUpdateProcess({payload}){
    const [{register_id,approved}] = payload
    
    const approve_time = moment().format()
    const {rateApprove:{dataSource},auth:{authUser}} = yield select(state => state)
    const {user_name:approve_user} = authUser

    let rateApproveInfo = {}
        rateApproveInfo.approve_data = payload
    try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/nightAudit/approveRates/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = rateApproveInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
          const rateApproveTemp = dataSource.map(o=>{
              if(o.register_id === register_id)
                if(approved)
                  return {...o,approved,approve_time,approve_user}
                else
                  return {...o,approved,approve_time:null,approve_user:null}
              else 
                return o
            })
            console.log("after success ",rateApproveTemp)
          yield put(rateApproveDataSourceUpdate(rateApproveTemp))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(rateApproveProcessSuccess(processStatus))
          yield put(rateApproveProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(rateApproveProcessSuccess(processStatus))
        yield put(rateApproveProcessReset())
  }
}

function* rateApproveUpdateAllProcess({payload}){
    console.log("Update All",payload)
    
    let rateApproveInfo = {}
        rateApproveInfo.approve_data = payload
    try {
          const  { access_token }  = yield call(getItems, ['access_token'])
          const AccessToken = access_token
          const url = `${API_URL_V1}pms/nightAudit/approveRates/`
          API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
          const API_DATABODY  = rateApproveInfo
          const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

          if(response.status === 200){
            yield call(rateApprovePreviewProcess)
            console.log("End call rate approve preview")
          }else { 
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message}
            yield put(rateApproveProcessSuccess(processStatus))
            yield put(rateApproveProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
          } 

    } catch (error) {
          console.log("Error : ",error)
          const processStatus = { status: false, action: "update", message:error}
          yield put(rateApproveProcessSuccess(processStatus))
          yield put(rateApproveProcessReset())
    }
}

/* Watcher Saga */
export function* rateApprovePreview() {
  yield takeLatest(RATE_APPROVE_PREVIEW, rateApprovePreviewProcess)
}
export function* rateApproveUpdate(){
  yield takeEvery(RATE_APPROVE_UPDATE, rateApproveUpdateProcess)
}
export function* rateApproveUpdateAll(){
  yield takeEvery(RATE_APPROVE_UPDATE_ALL,rateApproveUpdateAllProcess)
}

export default function* rootSaga() {
  yield all([fork(rateApprovePreview),
             fork(rateApproveUpdate),
             fork(rateApproveUpdateAll)])


}
