import {applyMiddleware, compose, createStore} from "redux"
import reducers from "../reducers/index.ts"
import {routerMiddleware} from "react-router-redux"
import { createBrowserHistory as createHistory } from "history"
import createSagaMiddleware from "redux-saga"
import rootSaga from "../sagas/index"
import { getItems, setItems } from "util/localStorage"
import { stateSet } from "constants/StateDefault"

const history = createHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware, routeMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

function saveToLocalStorage(state){
  try {
    const {ratePlan,reservations,city,country,channelList,listBoxs,...res} = state
    const newRatePlan = {...ratePlan,dataSource:[]}
    const newReservation = {...reservations,dataSource:[]}
    const newChannelList = {...channelList,dataSource:[]}
    const serializedState = JSON.stringify({...res,
                                            ratePlan:{...newRatePlan},
                                            reservations:{...newReservation},
                                            channelList:{...newChannelList}
                                          })
    setItems({
      [stateSet] : serializedState
    })
  } catch (error) {
    console.log(error)
  }
}

function loadFromLocalStorage(){
  try {
    const state = getItems([stateSet]) 
    const serializedState = state[stateSet]
    if(serializedState===null) return undefined
    else return JSON.parse(serializedState)
  } catch (error) {
    console.log(error)
    return undefined
  }
}

const persistedState = loadFromLocalStorage()

export default function configureStore(initialState=persistedState) {

  const store = createStore(reducers, 
                            initialState,
                            composeEnhancers(applyMiddleware(...middlewares))
                            )
        store.subscribe(()=>saveToLocalStorage(store.getState()))

  sagaMiddleware.run(rootSaga)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index.ts', () => {
      const nextRootReducer = require('../reducers/index.ts')
      store.replaceReducer(nextRootReducer)
    })
  }
  return store
}
export {history}
