
export const POSTING_REQUEST = "POSTING_REQUEST"
export const POSTING_PROCESS_SUCCESS = "POSTING_PROCESS_SUCCESS"
export const POSTING_DATASOURCE_SUCCESS = "POSTING_DATASOURCE_SUCCESS"
export const POSTING_PROCESS_RESET = "POSTING_PROCESS_RESET"

export const POSTING_SET_FILTER = "POSTING_SET_FILTER"

export interface PostingRequest {
  type: typeof POSTING_REQUEST,
  payload?:any,
  sorter?:any
}
export interface PostingProcessSuccess {
  type: typeof POSTING_PROCESS_SUCCESS,
  payload:{}
}
export interface PostingDataSourceSuccess{
  type: typeof POSTING_DATASOURCE_SUCCESS,
  payload:any
}
export interface PostingProcessReset {
  type: typeof POSTING_PROCESS_RESET
}

export interface PostingSetFilter{
  type: typeof POSTING_SET_FILTER,
  payload:any
}

export type CashierAction = 
| PostingRequest
| PostingProcessSuccess
| PostingDataSourceSuccess
| PostingProcessReset
| PostingSetFilter
