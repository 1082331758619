import * as actions from "appRedux/actions/cashier/postingActionType"

export interface postingState {
    loader: boolean,
    keyword:string,
    dataSource: {summary:any,transactions:[]},
    paginations: any,
    filterPosting: any,
    process:any
}

const initialState: postingState = {
    loader: false,
    keyword:'',
    dataSource: {summary:{},transactions:[]},
    paginations: [],
    filterPosting: [],
    process:{ status:false,action:"",message:""}
}

export default (state: postingState = initialState,action: actions.CashierAction): postingState =>{
    switch (action.type) {
    case actions.POSTING_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.POSTING_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.POSTING_DATASOURCE_SUCCESS:{
        const {paging=[],...others} = action.payload
        return{
            ...state,
            loader:false,
            dataSource: others,
            paginations: paging
        }
    }
    case actions.POSTING_SET_FILTER:{
            return{
                ...state,
                filterPosting:action.payload
            }
        }
    case actions.POSTING_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}