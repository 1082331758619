import { all, fork, put, takeEvery, takeLatest, call } from "redux-saga/effects"
import { IndexedDBAdd } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"
import * as actionTypes from "appRedux/actions/setup/rate/RateGroupActionType"
import * as actionCreators from "appRedux/actions/setup/rate/RateGroup"
import { userSignOut } from "appRedux/actions/Auth"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { ApiResponse } from "constants/ActionTypes"

function* rateGroupPreviewProcess() {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/rategroup/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    try {
        const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
            const rateGroupDataSource = response.data
            /* if no error put everything to reducer */
            yield put(actionCreators.rateGroupDataSourceUpdate(rateGroupDataSource))

            /* keep data to indexdb case create listbox */
            const rateGroupListBox = rateGroupDataSource.map((obj: any, key: number) => {
                    return { key, ...obj }
            })
            yield IndexedDBAdd("ListBoxDB", "objStoreRateGroupList", rateGroupListBox)

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "rateGroupPreview", message }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
            yield put(actionCreators.rateGroupProcessReset())
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }

    } catch (error) {
        console.log("Error on saga mealyype : ", error)
        const processStatus = { status: false, action: "rateGroupPreview", message: error }
        yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        yield put(actionCreators.rateGroupProcessReset())
    }

}

function* rateGroupCreateProcess({ payload }: actionTypes.RateGroupNew) {

    let rateGroupInfo = {} as any
    rateGroupInfo.name = payload.rateGroupName

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/rategroup/create/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = rateGroupInfo
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {
            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "rateGroupCreate", message: result_msg || "", result_code }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        } else {
            const { message, data } = response.data
            const processStatus = { status: false, action: "rateGroupCreate", message }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
            yield put(actionCreators.rateGroupProcessReset())
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }

    } catch (error) {
        console.log("Error : ", error)
        const processStatus = { status: false, action: "rateGroupCreate", message: error }
        yield put(actionCreators.rateGroupProcessSuccess(processStatus))
    }
}

function* rateGroupUpdateProcess({ payload }: actionTypes.RateGroupUpdate) {

    let rateGroupInfo = {} as any
    rateGroupInfo.id = payload.rateGroupID
    rateGroupInfo.name = payload.rateGroupName

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/rategroup/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = rateGroupInfo
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {
            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "rateGroupUpdate", message: result_msg || "", result_code }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        } else {
            const { message, data } = response.data
            const processStatus = { status: false, action: "rateGroupUpdate", message }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
            yield put(actionCreators.rateGroupProcessReset())
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }

    } catch (error) {
        console.log("Error : ", error)
        const processStatus = { status: false, action: "rateGroupUpdate", message: error }
        yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        yield put(actionCreators.rateGroupProcessReset())
    }
}

function* rateGroupRemoveProcess({ payload }: actionTypes.RateGroupRemove) {
    const rateGroupRemove = payload
    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/rategroup/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { id: rateGroupRemove }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
        if (response.status === 200) {
            const processStatus = { status: true, action: "rateGroupDelete", message: "Delete success." }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        } else {
            const { message, data } = response.data
            const processStatus = { status: false, action: "rateGroupDelete", message }
            yield put(actionCreators.rateGroupProcessSuccess(processStatus))
            yield put(actionCreators.rateGroupProcessReset())
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }
    }
    catch (error) {
        console.log("Error : ", error)
        const processStatus = { status: false, action: "rateGroupDelete", message: error }
        yield put(actionCreators.rateGroupProcessSuccess(processStatus))
        yield put(actionCreators.rateGroupProcessReset())
    }
}

/* Watcher Saga */
export function* rateGroupPreview() {
    yield takeLatest(actionTypes.RATEGROUP_PREVIEW, rateGroupPreviewProcess)
}
export function* rateGroupCreate() {
    yield takeEvery(actionTypes.RATEGROUP_NEW, rateGroupCreateProcess)
}
export function* rateGroupUpdate() {
    yield takeEvery(actionTypes.RATEGROUP_UPDATE, rateGroupUpdateProcess)
}
export function* rateGroupRemove() {
    yield takeEvery(actionTypes.RATEGROUP_REMOVE, rateGroupRemoveProcess)
}

export default function* rootSaga() {
    yield all([fork(rateGroupPreview),
    fork(rateGroupCreate),
    fork(rateGroupUpdate),
    fork(rateGroupRemove)])


}
