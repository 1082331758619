import * as actions from "appRedux/actions/setup/report/BatchReportActionType"

export interface batchReportDataType{
	id:number,
	batch_name?: string,
	batch_type?: string,
	output_type?: string,
	output_target?: string,
	enabled:boolean,
	report_count:number
}

/* Batch report detail type */
export interface subReportDataType{
	seq:number,
	report_id:number,
	report_name?:string|null,
	item_id?:number|null,
	has_options:boolean,
	enabled:boolean,
	duplicated:boolean
}

export interface batchReportDetailType{
	id:number,
	batch_name?:string|null,
	batch_type?:string|null,
	output_type?:string|null,
	output_target?:string|null,
	enabled:boolean,
	financial_reports:Array<subReportDataType>,
	reservation_reports:Array<subReportDataType>,
	registration_reports:Array<subReportDataType>
	marketing_reports:Array<subReportDataType>
}

export const batchReportDetailDefaultState:batchReportDetailType = {
    id:-1,
    batch_name:undefined,
    batch_type:undefined,
    output_type:undefined,
    output_target:undefined,
    enabled:false,
    financial_reports:[],
    reservation_reports:[],
    registration_reports:[],
    marketing_reports:[]
}
/* End Batch report detail type */

export interface reportOptionType{
    type: string,
	name: string,
	label?: null|string,
	groupArea?: null|string,
	groupLayout?: null|string,
	defaultValue?: null|string|number ,
	mode?: null|string,
	items?: Array<any> 
    passValueLabel?:string|null
}

export interface batchReportStateType {
    loader: boolean,
	onProcessing:boolean,
    dataSource: Array<batchReportDataType>,
    detailDataSource:batchReportDetailType,
    detailViewModeDataSource:batchReportDetailType,
    reportOption?:{parameters:Array<reportOptionType>},
    process:any
}


export const initialState: batchReportStateType = {
    loader: false,
	onProcessing:false,
    dataSource: [],
    detailDataSource:batchReportDetailDefaultState,
    detailViewModeDataSource:batchReportDetailDefaultState,
    reportOption:{parameters:[]},
    process:{ status:false,action:"",message:""}
}
export default (state: batchReportStateType = initialState,action: actions.BatchReportAction): batchReportStateType =>{
    switch (action.type) {
    case actions.BATCH_REPORT_REQUEST:{
        return {
            ...state,
            loader:true,
            detailDataSource:batchReportDetailDefaultState,
            reportOption:{parameters:[]},
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BATCH_REPORT_NEW:{
        return {
            ...state,
            loader:false,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BATCH_REPORT_UPDATE:{
        return {
            ...state,
            loader:false,
            process: {status:false,action:"",message:""}
        }
    }
	case actions.BATCH_REPORT_REMOVE:{
		return{
			...state,
			loader:false,
			process:{status:false,action:"",message:""}
		}
	}
    case actions.BATCH_REPORT_DUPLICATE:{
		return{
			...state,
			loader:false,
			process:{status:false,action:"",message:""}
		}
	}
    case actions.BATCH_REPORT_RENAME:{
		return{
			...state,
			loader:false,
			process:{status:false,action:"",message:""}
		}
	}
    case actions.BATCH_REPORT_PROCESS_SUCCESS:{
        return{
            ...state,
			loader:false,
			onProcessing:false,
            process:action.payload
        }
    }
    case actions.BATCH_REPORT_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.BATCH_REPORT_DETAIL_REQUEST:{
        return{
            ...state,
            loader:true,
            process:{status:false,action:"",message:""}
        }
    }
    
    case actions.BATCH_REPORT_DETAIL_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            detailDataSource: action.payload
        }
    }
    case actions.BATCH_REPORT_DETAIL_VIEWMODE_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            detailViewModeDataSource: action.payload
        }
    }
    case actions.BATCH_REPORT_OPTION_REQUEST_SUCCESS:{
        return{
            ...state,
            loader:false,
            reportOption: action.payload
        }
    }
    case actions.BATCH_REPORT_OPTION_RESET:{
        return{
            ...state,
            reportOption:{parameters:[]}
        }
    }
    case actions.BATCH_REPORT_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}