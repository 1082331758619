// eslint-disable-next-line
import React from 'react'
import { Tag } from 'antd'
import { useSelector } from 'react-redux'
import {AppState} from "appRedux/reducers/ReducerState"
import styled from "styled-components"
import moment from "moment"

const TagCustom = styled(Tag)`
    display:flex;
    align-items:center;
    margin-bottom:0;
    padding:4px;
    & span{
        margin-right:5px;
        font-size:14px; 
    }
`
export const OnlineTag: React.FC = () => {

    const systemConfig = useSelector((state:AppState)=>state.systemConfig)
    const { dataSource:dataSourceConfig } = systemConfig

    const SYSTEM_DATE = dataSourceConfig?.SYSTEM?.SYSTEM_DATE||""
        return (
            <TagCustom>
                <span>{moment(SYSTEM_DATE).format("DD/MM/YYYY")}</span>
            </TagCustom>
            
        )
}
export default OnlineTag


