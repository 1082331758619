export const ITEMIZER_REQUEST = "ITEMIZER_REQUEST"
export const ITEMIZER_PROCESS_SUCCESS = "ITEMIZER_PROCESS_SUCCESS"
export const ITEMIZER_DATASOURCE_SUCCESS = "ITEMIZER_DATASOURCE_SUCCESS"
export const ITEMIZER_PROCESS_RESET = "ITEMIZER_PROCESS_RESET"

export const ITEMIZER_NEW = "ITEMIZER_NEW"
export const ITEMIZER_UPDATE = "ITEMIZER_UPDATE"
export const ITEMIZER_REMOVE = "ITEMIZER_REMOVE"


export interface ItemizerRequest {
  type: typeof ITEMIZER_REQUEST,
  payload?:any
}

export interface ItemizerProcessSuccess {
  type: typeof ITEMIZER_PROCESS_SUCCESS,
  payload:{}
}
export interface ItemizerDataSourceSuccess{
  type: typeof ITEMIZER_DATASOURCE_SUCCESS,
  payload:any
}

export interface ItemizerProcessReset {
  type: typeof ITEMIZER_PROCESS_RESET
}

export interface ItemizerNew{
  type: typeof ITEMIZER_NEW,
  payload:any
}
export interface ItemizerUpdate{
  type: typeof ITEMIZER_UPDATE,
  payload:any
}
export interface ItemizerRemove{
  type: typeof ITEMIZER_REMOVE,
  payload:any
}


export type ItemizerAction = 
| ItemizerRequest
| ItemizerProcessSuccess
| ItemizerDataSourceSuccess
| ItemizerProcessReset
| ItemizerNew
| ItemizerUpdate
| ItemizerRemove
