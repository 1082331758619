// eslint-disable-next-line
import React,{ useEffect, useMemo} from 'react'
import { message,Tag,Avatar,Menu,Dropdown } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {AppState} from "appRedux/reducers/ReducerState"
import {cashierGuestChangeOnlineShift,cashierProcessReset } from "appRedux/actions/cashier/guest"
import styled from "styled-components"

const TagCustom = styled(Tag)`
    display:flex;
    align-items:center;
    margin-bottom:0;
    padding:4px;
    cursor: pointer;
    & span{
        margin-right:5px;
        font-size:14px; 
    }
`
export const OnlineTag: React.FC = () => {

    const cashierGuest = useSelector((state:AppState)=>state.cashierGuest)
    const systemConfig = useSelector((state:AppState)=>state.systemConfig)
    const { dataSource:dataSourceConfig } = systemConfig
    const { process } = cashierGuest

    const dispatch = useDispatch()

    const WORKING_SHIFT = dataSourceConfig?.SYSTEM?.SHIFTS||[]
    const ONLINE_SHIFT = dataSourceConfig?.SYSTEM?.ONLINE_SHIFT||""

    const onlineTitle = useMemo(() => {
        return ONLINE_SHIFT.toUpperCase()!=="X" ? "Online Shift":"Offline"
    }, [ONLINE_SHIFT])

    const onlineStatus = useMemo(()=>{
        return ONLINE_SHIFT.toUpperCase()!=="X" ? ONLINE_SHIFT.toUpperCase() : ""
    },[ONLINE_SHIFT])

    useEffect(()=>{    
        const action_validate = ["guestChangeOnlineShift"]
        const error_code = ["1","2","4","ERROR"]
        if(action_validate.includes(process.action)){
            if((process.status && !error_code.includes(process.result_code)) )
                message.success(process.message.toString())
            else 
                message.error(process.message.toString())
            
            setTimeout(() => {dispatch(cashierProcessReset())},100)

        }
    },[process,dispatch])
    
    const menu = () => {
        return (
                <Menu onClick={onHandleChange} style={{float:"right"}}>
                    {WORKING_SHIFT.map((obj:any)=><Menu.Item key={obj}>Shift {obj}</Menu.Item>)}
                    <Menu.Item key={'X'}>Offline</Menu.Item>
                </Menu>
            )
        }

        const onHandleChange = (value:any) =>{
            dispatch(cashierGuestChangeOnlineShift(value?.key))
        }
        return (
            <Dropdown 
                overlay={menu()}   
                placement="bottomCenter"
                trigger={["click"]}
                >
            <TagCustom>
                <span>{onlineTitle}</span>
                <Avatar size={16} style={{backgroundColor:`${ONLINE_SHIFT.toUpperCase()!=="X"?"#60bd60":"#f92c2c"}`}}>
                    {onlineStatus}
                </Avatar>
            </TagCustom>
            </Dropdown>
        )
}
export default OnlineTag


