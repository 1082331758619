import {
    COUNTRY_PREVIEW,
    COUNTRY_NEW,
    COUNTRY_UPDATE,
    COUNTRY_REMOVE,
    COUNTRY_SEARCH,
    COUNTRY_PROCESS_SUCCESS,
    COUNTRY_DATASOURCE_SUCCESS,
    COUNTRY_PROCESS_RESET
  } from "constants/ActionTypes"

  export const countryPreview = (value) => {
    return {
      type: COUNTRY_PREVIEW,
      payload:value
    }
  }

  export const countryCreate =(value) =>{
    return {
      type:COUNTRY_NEW,
      payload:value
    }
  }

  export const countryUpdate = (value) =>{
    return {
      type:COUNTRY_UPDATE,
      payload: value
    }
  }

  export const countryRemove = (value)=>{
    return {
      type:COUNTRY_REMOVE,
      payload:value
    }
  }

  export const countrySearch = (keyword) =>{
    return {
      type: COUNTRY_SEARCH,
      payload:keyword
    }
  }

  export const countryProcessSuccess = (value) =>{
    return{
      type:COUNTRY_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const countryDataSourceUpdate = (value) => {
    return {
      type: COUNTRY_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const countryProcessReset = () =>{
    return{
      type:COUNTRY_PROCESS_RESET
    }
  }

