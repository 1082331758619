import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  LISTBOX_ROOMTYPE,
  LISTBOX_COUNTRY,
  LISTBOX_CHANNEL_TYPE,
  LISTBOX_ORIGIN,
  LISTBOX_SALES,
  LISTBOX_MARKET,
  LISTBOX_NATION,
  LISTBOX_FOLIO,
  LISTBOX_ROOMGROUP,
  LISTBOX_INCLUSION,
  LISTBOX_RATEPLAN,
  LISTBOX_VIP,
  LISTBOX_FACILITY,
  LISTBOX_BEDTYPE,
  LISTBOX_BUILDING,
  LISTBOX_LOCATION,
  LISTBOX_EXPOSURE,
  LISTBOX_ROOMINFO,
  LISTBOX_RESERVATION_TYPE,
  LISTBOX_TITLENAME,
  LISTBOX_OCCUPATION,
  LISTBOX_OUT_OF_ORDER,
  LISTBOX_CITY,
  LISTBOX_SOCIAL,
  LISTBOX_LANGUAGE,
  LISTBOX_CHANNELS,
  LISTBOX_MEDIA,
  LISTBOX_MEDIA_GROUP,
  LISTBOX_ALERT_PAGE,
  LISTBOX_FOLLOW_UP_CATEGORY,
  LISTBOX_FOLLOW_UP_STATUS,
  LISTBOX_FOLLOW_UP_ASSIGN_USER,
  LISTBOX_DEPARTMENT,
  LISTBOX_DEPARTMENT_GROUP,
  LISTBOX_CHANNEL_GROUP,
  LISTBOX_FOLIO_DETAIL,
  LISTBOX_FOLIO_PAYMENT_CODE,
  LISTBOX_USER,
  LISTBOX_ITEMIZER
} from "constants/ActionTypes"

import { listBoxRoomTypeSuccess,listBoxCountrySuccess,listBoxOriginSuccess,listBoxSalesSuccess,
         listBoxChannelTypeSuccess,listBoxMarketSuccess,listBoxNationSuccess,
         listBoxFolioSuccess,listBoxRoomGroupSuccess,listBoxInclusionSuccess,
         listBoxRatePlanSuccess,listBoxVIPSuccess,listBoxFacilitySuccess,
         listBoxBedTypeSuccess,listBoxBuildingSuccess,listBoxLocationSuccess,
         listBoxExposureSuccess,listBoxRoomInfoSuccess,listBoxTitleSuccess,
         listBoxOccupationSuccess,listBoxOutOfOrderSuccess,listBoxCitySuccess,
         listBoxSocialSuccess, listReservationTypeSuccess, listBoxLanguageSuccess,
         listBoxChannelsSuccess,listBoxMediaSuccess,listBoxAlertPageSuccess,
         listBoxFollowUpCategorySuccess,listBoxFollowUpStatusSuccess,listBoxFollowUpAssignUserSuccess,
         listBoxUserSuccess , listBoxMediaGroupSuccess , listBoxDepartmentSuccess, 
         listBoxDepartmentGroupSuccess,listBoxChannelGroupSuccess,listBoxFolioDetailSuccess,
         listBoxFolioPaymentCodeSuccess,listBoxItemizerSuccess} from "../actions/Listboxs"
import {userSignOut,showAuthMessage} from "../actions/Auth"
import {IndexedDBAdd,IndexDBGetDataAll} from "util/indexedDBLibrary"
import { APIGetRequest } from "util/connection"
import { API_URL_V1,API_URL_AUTH, API_PMS_HEADERS,API_HEADERS } from "constants/ApiSetting"
import { isEmpty,orderBy} from "lodash"
import { getItems } from "util/localStorage"

function* listBoxRoomTypeProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomType/`
           API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

     const storeValue = yield IndexDBGetDataAll("ListBoxDB", "objStoreRoomTypeList")
    if(isEmpty(storeValue))
    {
      try {
            const response = yield APIGetRequest(url,API_PMS_HEADERS)
            if(response.status === 200)
            {
                  const roomTypeDataSource = orderBy(response.data,[o => o.order_seq],['asc']).map((obj,key) =>{
                    const container = {}
                          container.key = key
                          container.room_type_id = obj.id
                          container.room_type_name = obj.name
                          container.room_code = obj.code
                          container.room_description = (obj.description)?obj.description:""
                          container.room_order_seq = (obj.order_seq)?obj.order_seq:0
                          container.room_size = (obj.room_size)?obj.room_size:""
                          container.room_total_rooms = (obj.total_rooms)?obj.total_rooms:""
                          container.room_rate = (obj.room_rate)?obj.room_rate:""
                          container.room_child_rate = (obj.child_rate)?obj.child_rate:""
                          container.room_infant_rate = (obj.infant_rate)?obj.infant_rate:""
                          container.room_extrabed_rate = (obj.extrabed_rate)?obj.extrabed_rate:""
                          container.room_min_rate = (obj.min_rate)?obj.min_rate:""
                          container.room_max_adult = (obj.max_adult)?obj.max_adult:0
                          container.room_max_child = (obj.max_child)?obj.max_child:0
                          container.room_max_infant = (obj.max_infant)?obj.max_infant:0
                          container.room_max_pax = (obj.max_pax)?obj.max_pax:0
                          container.room_allow_overbook = obj.allow_overbook
                          container.room_alias = (obj.alias)?obj.alias:""
                          container.room_include_adult = (obj.include_adult)?obj.include_adult:0
                          container.room_include_child = (obj.include_child)?obj.include_child:0
                          container.room_include_infant = (obj.include_infant)?obj.include_infant:0 
                          container.room_extra_adult = (obj.extra_adult) ? obj.extra_adult : 0
                          container.room_extra_child = (obj.extra_child) ? obj.extra_child : 0
                          container.room_extra_infant = (obj.extra_infant) ? obj.extra_infant : 0
                          container.room_group_id = obj.room_group_id 
                          container.room_group_name = (obj.room_group)?obj.room_group:""
                      return container
                  })
                  
                  /* if no error put everything to reducer */
                  yield put(listBoxRoomTypeSuccess(roomTypeDataSource))
                  yield IndexedDBAdd("ListBoxDB","objStoreRoomTypeList",roomTypeDataSource)
            }else{
                  console.log("Error : ",response)
                  /* Show error message and signout */
                  const {message,data} = response.data
                  yield put(showAuthMessage(message))
                  if(response.status === 403){
                    /* if data is not null force signout */
                    if(data)
                      yield put(userSignOut())
                  }     
            }
            
          } catch (error) {
              console.log("Error on load room type on listbox saga : ",error)
              yield put(showAuthMessage(error))
          }

    }else
       yield put(listBoxRoomTypeSuccess(storeValue[0]))
   
}
function* listBoxCountryProcess(){


  const url = `${API_URL_V1}pms/masterfile/country/`
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  const storeValue = yield IndexDBGetDataAll("ListBoxDB", "objStoreCountryList")
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const countryDataSource = orderBy(response.data, [o => o.name.toLowerCase()], ['asc']).map((obj, key) => {
          const container = {}
          container.key = key
          container.country_id = obj.id
          container.country_name = obj.name
          container.country_code = obj.code
          container.enabled = obj.enabled
          return container
        })

        /* if no error put everything to reducer */
        yield put(listBoxCountrySuccess(countryDataSource))
        yield IndexedDBAdd("ListBoxDB", "objStoreCountryList", countryDataSource)
      } else {
        console.log("Error : ", response)
        /* Show error message and signout */
        const { message, data } = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          /* if data is not null force signout */
          if (data)
            yield put(userSignOut())
        }
      }

    } catch (error) {
      console.log("Error on load country on listbox saga : ", error)
      yield put(showAuthMessage(error))
    }

  } else
      yield put(listBoxCountrySuccess(storeValue[0]))
}
function* listBoxChannelTypeProcess(){

  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/channelType/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

     const storeValue = yield IndexDBGetDataAll("ListBoxDB", "objStoreChannelTypeList")
    if(isEmpty(storeValue))
    {
      try {
            const response = yield APIGetRequest(url,API_PMS_HEADERS)
            if(response.status === 200)
            {
              const channelTypeDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((channel,key) =>{
              const container = {}
                    container.key = key
                    container.channel_type_id = channel.id
                    container.channel_type_name = channel.name
                return container
            })
                  
                  /* if no error put everything to reducer */
                  yield put(listBoxChannelTypeSuccess(channelTypeDataSource))
                  yield IndexedDBAdd("ListBoxDB","objStoreChannelTypeList",channelTypeDataSource)
            }else{
                  console.log("Error : ",response)
                  /* Show error message and signout */
                  const {message,data} = response.data
                  yield put(showAuthMessage(message))
                  if(response.status === 403){
                    /* if data is not null force signout */
                    if(data)
                      yield put(userSignOut())
                  }     
            }
            
          } catch (error) {
              console.log("Error on load channel type on listbox saga : ",error)
              yield put(showAuthMessage(error))
          }

    }else
       yield put(listBoxChannelTypeSuccess(storeValue[0]))
}
function* listBoxOriginProcess(){

  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/origin/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
  const storeValue = yield IndexDBGetDataAll("ListBoxDB", "objStoreOriginList")
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
              const originDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.origin_id = obj.id
                    container.origin_name = obj.name
                    container.origin_code = obj.code
                    container.enabled = obj.enabled
                return container
            })

        /* if no error put everything to reducer */
        yield put(listBoxOriginSuccess(originDataSource))
        yield IndexedDBAdd("ListBoxDB", "objStoreOriginList", originDataSource)
      } else {
        console.log("Error : ", response)
        /* Show error message and signout */
        const { message, data } = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          /* if data is not null force signout */
          if (data)
            yield put(userSignOut())
        }
      }

    } catch (error) {
      console.log("Error on load origin on listbox saga : ", error)
      yield put(showAuthMessage(error))
    }

  } else
      yield put(listBoxOriginSuccess(storeValue[0]))
}
function* listBoxSalesProcess(){

  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/salesStaff/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  const storeValue = yield IndexDBGetDataAll("ListBoxDB", "objStoreSalesList")
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
       const salesPersonDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.sales_person_id = obj.id
                    container.sales_person_name = obj.name
                    container.enabled = obj.enabled
                return container
            })
        /* if no error put everything to reducer */
        yield put(listBoxSalesSuccess(salesPersonDataSource))
        yield IndexedDBAdd("ListBoxDB", "objStoreSalesList", salesPersonDataSource)
      } else {
        console.log("Error : ", response)
        /* Show error message and signout */
        const { message, data } = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          /* if data is not null force signout */
          if (data)
            yield put(userSignOut())
        }
      }

    } catch (error) {
      console.log("Error on load sales on listbox saga : ", error)
      yield put(showAuthMessage(error))
    }

  } else
    yield put(listBoxSalesSuccess(storeValue[0]))
}
function* listBoxMarketProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/market/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMarketList')
  if (isEmpty(storeValue)) {
    try {
          const response = yield APIGetRequest(url, API_PMS_HEADERS)
          if (response.status === 200) {
            const marketDataSource = orderBy(response.data, [o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.market_id = obj.id
                container.market_name = obj.name
                container.enabled = obj.enabled
          return container
          })
        
          yield put(listBoxMarketSuccess(marketDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreMarketList', marketDataSource)
        } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
    } catch (error) {
      console.log('Error on load market on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxMarketSuccess(storeValue[0]))
}
function* listBoxNationProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/nationality/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreNationList')
  if (isEmpty(storeValue)) {
      try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const nationDataSource = orderBy(response.data, [o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.nation_id = obj.id
                container.nation_name = obj.name
                container.nation_code = obj.code
                container.default_lang_id = obj.default_lang_id
                container.language = (obj.language) ? obj.language : ""
                container.default_market_id = obj.default_market_id
                container.market = obj.market
                container.enabled = obj.enabled
         return container
        })
        
          yield put(listBoxNationSuccess(nationDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreNationList', nationDataSource)
        } else {
            console.log('Error : ', response)
            
            const { message, data} = response.data
            yield put(showAuthMessage(message))
            if (response.status === 403) {
              if (data)
              yield put(userSignOut())
            }
        }
      } catch (error) {
        console.log('Error on load nationality on listbox saga : ', error)
        yield put(showAuthMessage(error))
      }
  } else
    yield put(listBoxNationSuccess(storeValue[0]))
}
function* listBoxFolioProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/folioPattern/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFolioList')
  if (isEmpty(storeValue)) {
    try {
    const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const folioDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key
              container.folio_id = obj.id
              container.folio_name = obj.name
              container.split_room_charge = obj.split_room_charge
              container.enabled = obj.enabled
        return container
        })
        
        yield put(listBoxFolioSuccess(folioDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreFolioList', folioDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load folioPattern on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
     yield put(listBoxFolioSuccess(storeValue[0]))
}
function* listBoxRoomGroupProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/roomGroup/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRoomGroupList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const roomGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.room_group_id = obj.id
                container.room_group_name = obj.name
                container.enabled = obj.enabled
          return container
          })
          
          yield put(listBoxRoomGroupSuccess(roomGroupDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreRoomGroupList', roomGroupDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
          yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load roomGroup on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
     yield put(listBoxRoomGroupSuccess(storeValue[0]))
}
function* listBoxInclusionProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/inclusion/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreInclusionList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
        const inclustionDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key
              container.inclusion_id = obj.id
              container.inclusion_name = obj.name
              container.description = obj.description||""
              container.multiplier = obj.multiplier||""
              container.multiplier_name = obj.multiplier_name||""
              container.frequency = obj.frequency||""
              container.frequency_name = obj.frequency_name||""
              container.allow_multiple = obj.allow_multiple
              container.charge_rate = obj.charge_rate
              container.charge_rate_format = Intl.NumberFormat().format(obj.charge_rate)
              container.department_id = obj.charge_dept
              container.department_name = obj.charge_department||""
        return container
        })
        
        yield put(listBoxInclusionSuccess(inclustionDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreInclusionList', inclustionDataSource)
      } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
            yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load inclustion on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
    yield put(listBoxInclusionSuccess(storeValue[0]))
}
function* listBoxRatePlanProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/ratePlan/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRatePlanList')
  if (isEmpty(storeValue)) {
      try {
          const response = yield APIGetRequest(url, API_PMS_HEADERS)
          if (response.status === 200) {
            const ratePlanDataSource = response.data.map((obj, key) => {
            const container = {}
                  container.key = key
                  container.rate_plan_id = obj.id
                  container.rate_plan_name = obj.name
                  container.rate_plan_code = (obj.code)?obj.code:""
                  container.channel_count = obj.channel_count
                  container.plan_type_id = obj.plan_type_id
                  container.plan_type_name = obj.plan_type_name
                  container.link_rate = obj.link_rate
                  container.effected_roomtypes = obj.effected_roomtypes
                  container.enabled = obj.enabled
            return container
          })
          
          yield put(listBoxRatePlanSuccess(ratePlanDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreRatePlanList', ratePlanDataSource)
      } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
            yield put(userSignOut())
          }
      }
      } catch (error) {
          console.log('Error on load ratePlan on listbox saga : ', error)
          yield put(showAuthMessage(error))
      }
  } else
      yield put(listBoxRatePlanSuccess(storeValue[0])) 
}
function* listBoxVIPProcess(){

  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/vipLevel/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
  
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreVipList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
        const vipDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.vip_id = obj.id
                container.vip_code = obj.code
                container.vip_name = obj.name
                container.enabled = obj.enabled
          return container
        })
        
        yield put(listBoxVIPSuccess(vipDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreVipList', vipDataSource)
      } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
            if (response.status === 403) {
              if (data)
                yield put(userSignOut())
            }
      }
    } catch (error) {
      console.log('Error on load vipLevel on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else {
      yield put(listBoxVIPSuccess(storeValue[0]))
  }
}
function* listBoxFacilityProcess(){

  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/roomFacility/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFacilityList')
  
  if (isEmpty(storeValue)) {
    try {
    const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const facilityDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key+1
              container.facility_id = obj.id
              container.facility_name = obj.name
        return container
        })
        yield put(listBoxFacilitySuccess(facilityDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreFacilityList', facilityDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
          yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load roomFacility on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
     yield put(listBoxFacilitySuccess(storeValue[0]))
}
function* listBoxBedTypeProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/bedType/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreBedTypeList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const bedTypeDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
          const container = {}
                container.key = key
                container.bed_type_id = obj.id
                container.bed_type_name = obj.name
                container.bed_type_code = obj.code
                container.image_url = obj.image_url
                container.enabled = obj.enabled
        return container
      })
      
        yield put(listBoxBedTypeSuccess(bedTypeDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreBedTypeList', bedTypeDataSource)
      } else {
          console.log('Error : ', response)
      
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
                yield put(userSignOut())
            }
          }
    } catch (error) {
        console.log('Error on load bedtype on listbox saga : ', error)
        yield put(showAuthMessage(error))
    }
  } else{
      yield put(listBoxBedTypeSuccess(storeValue[0]))
  }
}
function* listBoxBuildingProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/building/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreBuildingList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const buildingDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.building_id = obj.id
                container.building_name = obj.name
          return container
          })
        
        yield put(listBoxBuildingSuccess(buildingDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreBuildingList', buildingDataSource)
      } else {
        console.log('Error : ', response)
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
    console.log('Error on load building on listbox saga : ', error)
    yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxBuildingSuccess(storeValue[0]))
}
function* listBoxLocationProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/roomLocation/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreLocationList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
        const locationDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key+1
              container.location_id = obj.id
              container.location_name = obj.name
        return container
        })
        
        yield put(listBoxLocationSuccess(locationDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreLocationList', locationDataSource)
        } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
    } catch (error) {
      console.log('Error on load roomLocation on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxLocationSuccess(storeValue[0]))  
}
function* listBoxExposureProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/roomExposure/`;
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreExposureList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
        const exposureDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key+1
              container.exposure_id = obj.id
              container.exposure_name = obj.name
        return container
        })
      
        yield put(listBoxExposureSuccess(exposureDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreExposureList', exposureDataSource)
      } else {
        console.log('Error : ', response)
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load roomExposure on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxExposureSuccess(storeValue[0]))
}
function* listBoxRoomInfoProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/roomInfo/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRoomInfoList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const roomInfoDataSource = orderBy(response.data,[o => o.room_number],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.room_info_id = obj.id
                container.room_number = obj.room_number
                container.roomtype_id = obj.roomtype_id
                container.room_type_name = obj.room_type
                container.bedtype_id = obj.bedtype_id
                container.bed_type_name = obj.bed_type||""
                container.building_id = obj.building_id
                container.building_name = obj.building||""
                container.location_id = obj.location_id
                container.location_name = obj.location||""
                container.exposure_id = obj.exposure_id
                container.exposure_name = obj.exposure||""
                container.connecting_room_id = obj.connecting_room_id
                container.connecting_room_name = obj.connecting_room||""
                container.facilities = obj.facilities
         return container
      })
      
        yield put(listBoxRoomInfoSuccess(roomInfoDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreRoomInfoList', roomInfoDataSource)
      } else {
        console.log('Error : ', response)
      
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load roomInfo on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxRoomInfoSuccess(storeValue[0]))
}
function* listBoxTitleNameProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/title/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreTitleNameList')
  if (isEmpty(storeValue)) {
      try {
          const response = yield APIGetRequest(url, API_PMS_HEADERS)
          if (response.status === 200) {
            const titleNameDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key+1
                    container.title_id = obj.id
                    container.title_name = obj.name
                    container.gender = obj.sex||""
                    container.enabled = obj.enabled
                   
                return container
          })
          
            yield put(listBoxTitleSuccess(titleNameDataSource))
            yield IndexedDBAdd('ListBoxDB','objStoreTitleNameList', titleNameDataSource)
          } else {
            console.log('Error : ', response)
          
            const { message, data} = response.data
            yield put(showAuthMessage(message))
            if (response.status === 403) {
              if (data)
                yield put(userSignOut())
            }
          }
      } catch (error) {
          console.log('Error on load title on listbox saga : ', error)
          yield put(showAuthMessage(error))
      }
  } else
      yield put(listBoxTitleSuccess(storeValue[0]))
}
function* listBoxOccupationProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
    const url = `${ API_URL_V1 }pms/masterfile/occupation/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
     
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreOccupationList')
    if (isEmpty(storeValue)) {
        try {
            const response = yield APIGetRequest(url, API_PMS_HEADERS)
            if (response.status === 200) {
            const occupationDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
                      const container = {}
                            container.key = key+1
                            container.occupation_id = obj.id
                            container.occupation_name = obj.name
                            container.enabled = obj.enabled
                        return container
            })
            
              yield put(listBoxOccupationSuccess(occupationDataSource))
              yield IndexedDBAdd('ListBoxDB','objStoreOccupationList', occupationDataSource)
            } else {
              console.log('Error : ', response)
              
              const { message, data} = response.data
              yield put(showAuthMessage(message))
              if (response.status === 403) {
                 if (data)
                  yield put(userSignOut())
              }
            }
        } catch (error) {
            console.log('Error on load occupation on listbox saga : ', error)
            yield put(showAuthMessage(error))
        }
    } else
        yield put(listBoxOccupationSuccess(storeValue[0]))
}
function* listBoxOutOfOrderProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/outoforderReason/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreOutOfOrderList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
         const outOfOrderDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.out_of_order_id = obj.id
                container.out_of_order_name = obj.name
          return container
    })
    
      yield put(listBoxOutOfOrderSuccess(outOfOrderDataSource))
      yield IndexedDBAdd('ListBoxDB','objStoreOutOfOrderList', outOfOrderDataSource)
    } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
            if (data)
              yield put(userSignOut())
        }
      }
    } catch (error) {
        console.log('Error on load outoforderReason on listbox saga : ', error)
        yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxOutOfOrderSuccess(storeValue[0]))
}
function* listBoxCityProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
    const url = `${ API_URL_V1 }pms/masterfile/city/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
     
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreCityList')
    if (isEmpty(storeValue)) {
        try {
            const response = yield APIGetRequest(url, API_PMS_HEADERS)
            if (response.status === 200) {
              const cityDataSource = orderBy(response.data,[o => o.city_name],['asc']).map((obj, key) => {
              const container = {}
                    container.key = key+1
                    container.city_id = obj.id
                    container.city_name = obj.city_name
                    container.country_id = obj.country_id
                    container.country_name = obj.country_name
              return container
            })
        
            yield put(listBoxCitySuccess(cityDataSource))
            yield IndexedDBAdd('ListBoxDB','objStoreCityList', cityDataSource)
        } else {
            console.log('Error : ', response)
            
            const { message, data} = response.data
            yield put(showAuthMessage(message))
            if (response.status === 403) {
                if (data)
                  yield put(userSignOut())
            }
        }
        } catch (error) {
            console.log('Error on load city on listbox saga : ', error)
            yield put(showAuthMessage(error))
        }
    } else
        yield put(listBoxCitySuccess(storeValue[0]))
}
function* listBoxSocialProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
    const url = `${ API_URL_V1 }pms/masterfile/socialMedia/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
     
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreSocialList')
    if (isEmpty(storeValue)) {
      try {
          const response = yield APIGetRequest(url, API_PMS_HEADERS)
          if (response.status === 200) {
              const socialDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key+1
                    container.social_id = obj.id
                    container.social_name = obj.name
                    container.social_url = obj.url||""
                    container.social_icon = obj.icon||""
                    container.enabled = obj.enabled
             return container
          })
            yield put(listBoxSocialSuccess(socialDataSource))
            yield IndexedDBAdd('ListBoxDB','objStoreSocialList', socialDataSource)
          } else {
              console.log('Error : ', response)
              
              const { message, data} = response.data
              yield put(showAuthMessage(message))
              if (response.status === 403) {
                if (data)
                  yield put(userSignOut())
              }
          }
      } catch (error) {
        console.log('Error on load socialMedia on listbox saga : ', error)
        yield put(showAuthMessage(error))
      }
    } else
        yield put(listBoxSocialSuccess(storeValue[0]))  
}

function* listBoxReservationTypeProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/reservationType/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreReservationTypeList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const reservationTypeDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
          const container = {}
                container.key = key
                container.reservation_id = obj.id
                container.reservation_name = obj.name
                container.enabled = obj.enabled
            return container
        })
      
        yield put(listReservationTypeSuccess(reservationTypeDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreReservationTypeList', reservationTypeDataSource)
      } else {
        console.log('Error : ', response)
      
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load roomInfo on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listReservationTypeSuccess(storeValue[0]))
}

function* listBoxLanguageProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/language/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreLanguageList')
  if (isEmpty(storeValue)) {
      try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
            const languageDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
            const container = {}
                  container.key = key
                  container.lang_id = obj.id
                  container.lang_name = obj.name
                  container.lang_code = obj.code
                  container.enabled = obj.enabled
                return container
            })
            
            yield put(listBoxLanguageSuccess(languageDataSource))
            yield IndexedDBAdd('ListBoxDB','objStoreLanguageList', languageDataSource)
        } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
              if (response.status === 403) {
                if (data)
                  yield put(userSignOut())
              }
        }
    } catch (error) {
      console.log('Error on load language on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxLanguageSuccess(storeValue[0]))  
}
function* listBoxChannelsProcess() {
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/channel/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreChannelsList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const channelsDataSource = orderBy(response.data,[o=>o.channel_name.toLowerCase()],['asc']).map((obj,key) =>{
            const container = {}
                  container.key = key
                  container.channel_id = obj.id
                  container.channel_name = obj.channel_name
                  container.company_name = obj.company_name
                  container.channel_type_id = obj.channel_type_id
                  container.channel_type = obj.channel_type
                  container.origin_id = obj.origin_id
                  container.origin = (!obj.origin)?"":obj.origin //Check object is undefined set value is ""
                  container.nationality_id = obj.nationality_id
                  container.nationality = (!obj.nationality)?"":obj.nationality //Check object is undefined set value is ""
                  container.country_id = obj.country_id
                  container.country = (!obj.country)?"":obj.country //Check object is undefined set value is ""
                  container.folio_pattern_id = obj.folio_pattern_id
                  container.folio_pattern = (!obj.folio_pattern)?"":obj.folio_pattern
                  container.sales_staff_id = obj.sales_staff_id
                  container.sales_staff = obj.sales_staff
                  container.channel_group_id = obj.channel_group_id
                  container.channel_group = obj.channel_group
                  container.payment_condition = obj.payment_condition
                  container.enabled = obj.enabled
                  container.channel_market = obj.channel_market
                  container.market_id = []
                  if(!isEmpty(obj.channel_market)){
                    const objTemp = obj.channel_market.map((data)=>{ return data.market_id})
                    container.market_id = objTemp
                  }
                  container.credit_limit = obj.credit_limit
              return container
          })
        yield put(listBoxChannelsSuccess(channelsDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreChannelsList', channelsDataSource)
      } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load channels on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxChannelsSuccess(storeValue[0]))
}
function* listBoxMediaProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/media/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMediaList')
    if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const mediaDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
          const container = {}
                container.key = key
                container.media_id = obj.id
                container.media_code = obj.code
                container.media_name = obj.name
                container.media_group_id = obj.group_id 
                container.media_group_name = obj.group 
                container.dept_type = obj.dept_type
                container.require_info = obj.require_info
                container.enabled = obj.enabled
        return container
        })
        yield put(listBoxMediaSuccess(mediaDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreMediaList', mediaDataSource)
      } else {
          console.log('Error : ', response)
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
        console.log('Error on load media on listbox saga : ', error)
        yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxMediaSuccess(storeValue[0]))
}

function* listBoxMediaGroupProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/mediaGroup/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
  
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMediaGroupList')
  if (isEmpty(storeValue)) {
    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const mediaGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.media_group_id = obj.id
                container.media_group_name = obj.name
            return container
        })
      
        yield put(listBoxMediaGroupSuccess(mediaGroupDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreMediaGroupList', mediaGroupDataSource)
      } else {
        console.log('Error : ', response)
        const { message, data} = response.data
        yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
      }
    } catch (error) {
      console.log('Error on load mediagroup on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxMediaGroupSuccess(storeValue[0]))

}


function* listBoxAlertPageProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/alertPage/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreAlertPageList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
    
        const alertTypeDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key
              container.alert_page_id = obj.id
              container.alert_page_name = obj.name
        return container
        })
        
        yield put(listBoxAlertPageSuccess(alertTypeDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreAlertPageList', alertTypeDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load alertType on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxAlertPageSuccess(storeValue[0]))   
}

function* listBoxFollowUpCategoryProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/followUpCategory/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFollowUpCategoryList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const categoryDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.follow_up_category_id = obj.id
                container.follow_up_category_name = obj.name
          return container
        })
        
        yield put(listBoxFollowUpCategorySuccess(categoryDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreFollowUpCategoryList', categoryDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load followUpCategory on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxFollowUpCategorySuccess(storeValue[0]))
}

function* listBoxFollowUpStatusProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/followUpStatus/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
   
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFollowUpStatusList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
          const statusDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.follow_up_status_id = obj.id
                container.follow_up_status_name = obj.name
          return container
        })
        
        yield put(listBoxFollowUpStatusSuccess(statusDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreFollowUpStatusList', statusDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load followUpCategory on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
      yield put(listBoxFollowUpStatusSuccess(storeValue[0]))
}

function* listBoxFollowUpAssignUserProcess({payload}){

  const  { access_token }  = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/userList/`
  API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
      const response = yield APIGetRequest(url, API_HEADERS)
      if (response.status === 200) {
          const userGroupDataSource = orderBy(response.data,[o => o.group_name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.group_id = obj.group_id
                container.group_name = obj.group_name
                container.user_list = obj.user_list
          return container
        })
        
        yield put(listBoxFollowUpAssignUserSuccess(userGroupDataSource))
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
} catch (error) {
      console.log('Error on load followUpCategory on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  
}

function* listBoxUserProcess({payload}){
    const {prop_id:propID , user_group_type:userGroupType } = payload
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/?user_group_type=${userGroupType}&prop_id=${propID}`
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreUserList')
    if (isEmpty(storeValue)) {
      try {
        const response = yield APIGetRequest(url,API_HEADERS)
        if(response.status === 200)
        { 
              const userDataSource = orderBy(response.data,[o=>o.user_name.toLowerCase()],['asc']).map((user,key) =>{
                const container = {}
                      container.key = key
                      container.log_id = user.log_id
                      container.user_id = user.user_id
                      container.user_name = user.user_name
                      container.full_name = user.full_name||""
                      container.group_id = user.group_id
                      container.group_name = user.group_name
                      container.group_type = user.group_type
                      container.mobile = user.mobile||""
                      container.telephone = user.telephone||""
                      container.profile_pic = user.profile_pic
                      container.properties = []
                      container.email = user.email||""
                      container.job_position = user.job_position||""
                      container.active = (user.active)?true:false
                      if(!isEmpty(user.group_name))
                        container.group_tags = [user.group_name] 
                      else 
                        container.group_tags = []

                      if(!isEmpty(user.job_position))
                        container.job_tags = [user.job_position] 
                      else 
                        container.job_tags = []

                      if(!isEmpty(user.properties)){
                          const propertieID = user.properties.map((value)=>{
                            const valueID = (value.id)                         
                            return valueID
                          })
                          container.properties =[...propertieID]
                      }
                  return container
              })
              /* if no error put everything to reducer */
              yield put(listBoxUserSuccess(userDataSource))
              yield IndexedDBAdd('ListBoxDB','objStoreUserList', userDataSource)

        }else{
            console.log('Error : ', response)
        
            const { message, data} = response.data
            yield put(showAuthMessage(message))
            if (response.status === 403) {
              if (data)
                yield put(userSignOut())
            }
        }
        
      } catch (error) {
          console.log("Error on saga userlistbox : ",error)
      }
    }else
      yield put(listBoxUserSuccess(storeValue[0]))
}

function* listBoxDepartmentProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
    const url = `${ API_URL_V1 }pms/masterfile/department/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
    
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreDepartmentList')
    if (isEmpty(storeValue)) {
      try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const departmentDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.department_id = obj.id
                container.department_code = obj.code
                container.department_name = obj.name
                container.department_group_id = obj.group_id 
                container.department_group_name = obj.group 
                container.dept_type = obj.dept_type
                container.require_info = obj.require_info
                container.enabled = obj.enabled
          return container
          })
          
          yield put(listBoxDepartmentSuccess(departmentDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreDepartmentList', departmentDataSource)
        } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
      } catch (error) {
        console.log('Error on load department on listbox saga : ', error)
        yield put(showAuthMessage(error))
      }
    } else
      yield put(listBoxDepartmentSuccess(storeValue[0]))
  }

function* listBoxDepartmentGroupProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/departmentGroup/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreDepartmentGroupList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const departmentGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key
              container.department_group_id = obj.id
              container.department_group_name = obj.name
        return container
        })
        
        yield put(listBoxDepartmentGroupSuccess(departmentGroupDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreDepartmentGroupList', departmentGroupDataSource)
      } else {
        console.log('Error : ', response)
        
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load departmentGroup on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
    yield put(listBoxDepartmentGroupSuccess(storeValue[0]))
}

function* listBoxChannelGroupProcess(){
  const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/channelGroup/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
 
const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreChannelGroupList')
  if (isEmpty(storeValue)) {
    try {
      const response = yield APIGetRequest(url, API_PMS_HEADERS)
      if (response.status === 200) {
        const channelGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
        const container = {}
              container.key = key
              container.channel_group_id = obj.id
              container.channel_group_name = obj.name
        return container
        })
        
        yield put(listBoxChannelGroupSuccess(channelGroupDataSource))
        yield IndexedDBAdd('ListBoxDB','objStoreChannelGroupList', channelGroupDataSource)
      } else {
        console.log('Error : ', response)
      
        const { message, data} = response.data
        yield put(showAuthMessage(message))
        if (response.status === 403) {
          if (data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      console.log('Error on load channelGroup on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }
  } else
    yield put(listBoxChannelGroupSuccess(storeValue[0]))
}

function* listBoxFolioDetailProcess({payload}){

  const folio_id = payload
  const  { access_token:AccessToken }  = yield call(getItems, ['access_token'])
  const url = `${ API_URL_V1 }pms/cashier/folio/transactionCodes/?folio_id=${folio_id}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const folioDetailList = orderBy(response.data,[o => o.code],['asc']).map((obj, key) => {
          const container = {}
                container.key = key
                container.id = obj.id
                container.code = obj.code
                container.name = obj.name
                container.group_id = obj.group_id
                container.group_name = obj.group_name
                container.post_type = obj.post_type
                container.dept_type = obj.dept_type
                container.require_info = obj.require_info
                container.taxes = obj.taxes
                container.folio_seq = obj.folio_seq
          return container
          })
          
          yield put(listBoxFolioDetailSuccess(folioDetailList))
        } else {
          console.log('Error : ', response)
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
    } catch (error) {
      console.log('Error on load folio detail on listbox saga : ', error)
      yield put(showAuthMessage(error))
    }

}

function* listBoxFolioPaymentCodeProcess(){
  //console.log("Load payment code")
  const  { access_token:AccessToken }  = yield call(getItems, ['access_token'])
  const url = `${ API_URL_V1 }pms/cashier/folio/paymentCodes/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`

    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
          const folioPaymentCode = orderBy(response.data,[o => o.code],['asc']).map((obj, key) => {
          const container = {}
                container.key = obj.id
                container.id = obj.id
                container.code = obj.code
                container.name = obj.name
                container.group_id = obj.group_id
                container.group_name = obj.group_name
                container.post_type = obj.post_type
                container.dept_type = obj.dept_type
                container.require_info = obj.require_info
                container.taxes = obj.taxes
          return container
          })
          
          yield put(listBoxFolioPaymentCodeSuccess(folioPaymentCode))
        } else {
          console.log('Error : ', response)
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
    } catch (error) {
      console.log('Error on load folio detail on listbox saga : ', error)
      //yield put(showAuthMessage(error))
    }
}

function* listBoxItemizerProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/itemizer/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
    
    const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreItemizerList')
    if (isEmpty(storeValue)) {
      try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
              const dataSource = response.data||[]
              const itemizerDataSource = orderBy(dataSource,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {} 
                    container.key = key
                    container.itemizer_id = obj.id
                    container.itemizer_code = obj.code
                    container.itemizer_name = obj.name
                    container.itemizer_rate = obj.rate 
                    container.itemizer_amount = obj.amount 
                    container.itemizer_calc_on_baseprice = obj.calc_on_baseprice
                    container.itemizer_is_service = obj.is_service
                    container.itemizer_is_tax = obj.is_tax
                    container.itemizer_enabled = obj.enabled
                return container
          })
          
          yield put(listBoxItemizerSuccess(itemizerDataSource))
          yield IndexedDBAdd('ListBoxDB','objStoreItemizerList', itemizerDataSource)
        } else {
          console.log('Error : ', response)
          
          const { message, data} = response.data
          yield put(showAuthMessage(message))
          if (response.status === 403) {
            if (data)
              yield put(userSignOut())
          }
        }
      } catch (error) {
        console.log('Error on load itemizer on listbox saga : ', error)
        yield put(showAuthMessage(error))
      }
    } else
      yield put(listBoxItemizerSuccess(storeValue[0]))
}

/* Watcher Saga */
export function* listBoxRoomType() {
  yield takeLatest(LISTBOX_ROOMTYPE, listBoxRoomTypeProcess)
}
export function* listBoxCountry(){
  yield takeLatest(LISTBOX_COUNTRY,listBoxCountryProcess)
}
export function* listBoxChannelType(){
  yield takeLatest(LISTBOX_CHANNEL_TYPE,listBoxChannelTypeProcess)
}
export function* listBoxOrigin(){
  yield takeLatest(LISTBOX_ORIGIN,listBoxOriginProcess)
}
export function* listBoxSales(){
  yield takeLatest(LISTBOX_SALES,listBoxSalesProcess)
}
export function* listBoxMarket(){
  yield takeLatest(LISTBOX_MARKET,listBoxMarketProcess)
}
export function* listBoxNation(){
  yield takeLatest(LISTBOX_NATION,listBoxNationProcess)
}
export function* listBoxFolio(){
  yield takeLatest(LISTBOX_FOLIO,listBoxFolioProcess)
}
export function* listBoxRoomGroup(){
  yield takeLatest(LISTBOX_ROOMGROUP,listBoxRoomGroupProcess)
}
export function* listBoxInclusion(){
  yield takeLatest(LISTBOX_INCLUSION,listBoxInclusionProcess)
}
export function* listBoxRatePlan(){
  yield takeLatest(LISTBOX_RATEPLAN,listBoxRatePlanProcess)
}
export function* listBoxVIP(){
  yield takeLatest(LISTBOX_VIP,listBoxVIPProcess)
}
export function* listBoxFacility(){
  yield takeLatest(LISTBOX_FACILITY,listBoxFacilityProcess)
}
export function* listBoxBedType(){
  yield takeLatest(LISTBOX_BEDTYPE,listBoxBedTypeProcess)
}
export function* listBoxBuilding(){
  yield takeLatest(LISTBOX_BUILDING,listBoxBuildingProcess)
}
export function* listBoxLocation(){
  yield takeLatest(LISTBOX_LOCATION,listBoxLocationProcess)
}
export function* listBoxExposure() {
  yield takeLatest(LISTBOX_EXPOSURE, listBoxExposureProcess)
}
export function* listBoxRoomInfo(){
  yield takeLatest(LISTBOX_ROOMINFO,listBoxRoomInfoProcess)
}
export function* listBoxReservationType(){
  yield takeLatest(LISTBOX_RESERVATION_TYPE,listBoxReservationTypeProcess)
}
export function* listBoxTitleName(){
  yield takeLatest(LISTBOX_TITLENAME,listBoxTitleNameProcess)
}
export function* listBoxOccupation(){
  yield takeLatest(LISTBOX_OCCUPATION,listBoxOccupationProcess)
}
export function* listBoxOutOfOrder(){
  yield takeLatest(LISTBOX_OUT_OF_ORDER,listBoxOutOfOrderProcess)
}
export function* listBoxCity(){
  yield takeLatest(LISTBOX_CITY,listBoxCityProcess)
}
export function* listBoxSocial(){
  yield takeLatest(LISTBOX_SOCIAL,listBoxSocialProcess)
}
export function* listBoxLanguage(){
  yield takeLatest(LISTBOX_LANGUAGE,listBoxLanguageProcess)
}
export function* listBoxChannels(){
  yield takeLatest(LISTBOX_CHANNELS,listBoxChannelsProcess)
}
export function* listBoxMedia(){
  yield takeLatest(LISTBOX_MEDIA,listBoxMediaProcess)
}
export function* listBoxMediaGroup(){
  yield takeLatest(LISTBOX_MEDIA_GROUP,listBoxMediaGroupProcess)
}
export function* listBoxAlertPage(){
  yield takeLatest(LISTBOX_ALERT_PAGE,listBoxAlertPageProcess)
}
export function* listBoxFollowUpCategory(){
  yield takeLatest(LISTBOX_FOLLOW_UP_CATEGORY,listBoxFollowUpCategoryProcess)
}
export function* listBoxFollowUpStatus(){
  yield takeLatest(LISTBOX_FOLLOW_UP_STATUS,listBoxFollowUpStatusProcess)
}
export function* listBoxFollowUpAssignUser(){
  yield takeLatest(LISTBOX_FOLLOW_UP_ASSIGN_USER,listBoxFollowUpAssignUserProcess)
}
export function* listBoxUser(){
  yield takeLatest(LISTBOX_USER,listBoxUserProcess)
}
export function* listBoxDepartment(){
  yield takeLatest(LISTBOX_DEPARTMENT,listBoxDepartmentProcess)
}
export function* listBoxDepartmentGroup(){
  yield takeLatest(LISTBOX_DEPARTMENT_GROUP,listBoxDepartmentGroupProcess)
}
export function* listBoxChannelGroup(){
  yield takeLatest(LISTBOX_CHANNEL_GROUP,listBoxChannelGroupProcess)
}

export function* listBoxFolioDetail(){
  yield takeLatest(LISTBOX_FOLIO_DETAIL,listBoxFolioDetailProcess)
}

export function* listBoxFolioPaymentCode(){
  yield takeLatest(LISTBOX_FOLIO_PAYMENT_CODE,listBoxFolioPaymentCodeProcess)
}
export function* listBoxItemizer(){
  yield takeLatest(LISTBOX_ITEMIZER,listBoxItemizerProcess)
}

export default function* rootSaga() {
  yield all([fork(listBoxRoomType),
             fork(listBoxCountry),
             fork(listBoxChannelType),
             fork(listBoxOrigin),
             fork(listBoxSales),
             fork(listBoxMarket),
             fork(listBoxNation),
             fork(listBoxFolio),
             fork(listBoxRoomGroup),
             fork(listBoxInclusion),
             fork(listBoxRatePlan),
             fork(listBoxVIP),
             fork(listBoxFacility),
             fork(listBoxBedType),
             fork(listBoxBuilding),
             fork(listBoxLocation),
             fork(listBoxExposure),
             fork(listBoxRoomInfo),
             fork(listBoxReservationType),
             fork(listBoxTitleName),
             fork(listBoxOccupation),
             fork(listBoxOutOfOrder),
             fork(listBoxCity),
             fork(listBoxSocial),
             fork(listBoxLanguage),
             fork(listBoxChannels),
             fork(listBoxMedia),
             fork(listBoxMediaGroup),
             fork(listBoxAlertPage),
             fork(listBoxFollowUpCategory),
             fork(listBoxFollowUpStatus),
             fork(listBoxFollowUpAssignUser),
             fork(listBoxUser),
             fork(listBoxDepartment),
             fork(listBoxDepartmentGroup),
             fork(listBoxChannelGroup),
             fork(listBoxFolioDetail),
             fork(listBoxFolioPaymentCode),
             fork(listBoxItemizer)
            ])
}
