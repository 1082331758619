export const GUEST_INHOUSE_REQUEST = "GUEST_INHOUSE_REQUEST"
export const GUEST_INHOUSE_NEW = "GUEST_INHOUSE_NEW"
export const GUEST_INHOUSE_UPDATE = "GUEST_INHOUSE_UPDATE"
export const GUEST_INHOUSE_DATASOURCE_SUCCESS = "GUEST_INHOUSE_DATASOURCE_SUCCESS"
export const GUEST_INHOUSE_PROCESS_SUCCESS = "GUEST_INHOUSE_PROCESS_SUCCESS"
export const GUEST_INHOUSE_PROCESS_RESET = "GUEST_INHOUSE_PROCESS_RESET"
export const GUEST_INHOUSE_SET_FILTER = "GUEST_INHOUSE_SET_FILTER"

export const GUEST_INHOUSE_VERIFY_RATE = "GUEST_INHOUSE_VERIFY_RATE"

export const GUEST_INHOUSE_CHECK_OUT = "GUEST_INHOUSE_CHECK_OUT"
export const GUEST_INHOUSE_CANCEL_CHECK_IN = "GUEST_INHOUSE_CANCEL_CHECK_IN"
export const GUEST_INHOUSE_CANCEL_CHECK_OUT = "GUEST_INHOUSE_CANCEL_CHECK_OUT"

export const GUEST_INHOUSE_DETAIL_REQUEST = "GUEST_INHOUSE_DETAIL_REQUEST"
export const GUEST_INHOUSE_DETAIL_DATASOURCE_SUCCESS = "GUEST_INHOUSE_DETAIL_DATASOURCE_SUCCESS"

export const GUEST_INHOUSE_RATEPLAN_REQUEST = "GUEST_INHOUSE_RATEPLAN_REQUEST"
export const GUEST_INHOUSE_RATEPLAN_DATASOUCE_SUCCESS = "GUEST_INHOUSE_RATEPLAN_DATASOUCE_SUCCESS"

export const GUEST_INHOUSE_CHANGE_BOOKING_STATUS = "GUEST_INHOUSE_CHANGE_BOOKING_STATUS"
export const GUEST_INHOUSE_BOOKING_SUMMARY_REQUEST = "GUEST_INHOUSE_BOOKING_SUMMARY_REQUEST"
export const GUEST_INHOUSE_BOOKING_SUMMARY_SUCCESS = "GUEST_INHOUSE_BOOKING_SUMMARY_SUCCESS"

export const GUSET_INHOUSE_CHECKIN = "GUSET_INHOUSE_CHECKIN"
export const GUEST_INHOUSE_CHECKIN_VERIFY = "GUEST_INHOUSE_CHECKIN_VERIFY"

export const GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL = "GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL"
export const GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL_RESET = "GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL_RESET" 

export interface GuestInhouseRequest {
  type: typeof GUEST_INHOUSE_REQUEST,
  payload?:any,
  sorter?:any
}

export interface GuestInhouseNew {
  type: typeof GUEST_INHOUSE_NEW,
  payload?:any
}

export interface GuestInhouseUpdate {
  type: typeof GUEST_INHOUSE_UPDATE,
  payload?:any
}

export interface GuestInhouseProcessSuccess {
  type: typeof GUEST_INHOUSE_PROCESS_SUCCESS,
  payload:{}
}
export interface GuestInhouseDataSourceSuccess{
  type: typeof GUEST_INHOUSE_DATASOURCE_SUCCESS,
  payload:any
}
export interface GuestInhouseProcessReset {
  type: typeof GUEST_INHOUSE_PROCESS_RESET
}
export interface GuestInhouseSetFilter{
  type: typeof GUEST_INHOUSE_SET_FILTER,
  payload:any
}
export interface GuestInhouseDetailRequest{
  type: typeof GUEST_INHOUSE_DETAIL_REQUEST,
  payload:number
}
export interface GuestInhouseDetailDataSourceSuccess{
  type: typeof GUEST_INHOUSE_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface GuestInhouseRatePlanRequest{
  type: typeof GUEST_INHOUSE_RATEPLAN_REQUEST,
  payload:any
}
export interface GuestInhouseRatePlanDataSourceSuccess{
  type: typeof GUEST_INHOUSE_RATEPLAN_DATASOUCE_SUCCESS,
  payload:any
}

export interface GuestInhouseChangeBookingStatus{
  type: typeof GUEST_INHOUSE_CHANGE_BOOKING_STATUS,
  payload:any
}

export interface GuestInhouseBookingSummaryRequest{
  type: typeof GUEST_INHOUSE_BOOKING_SUMMARY_REQUEST,
  payload:any
}
export interface GuestInhouseBookingSummarySuccess{
  type: typeof GUEST_INHOUSE_BOOKING_SUMMARY_SUCCESS,
  payload:any
}

export interface GuestInhouseCheckOut{
  type: typeof GUEST_INHOUSE_CHECK_OUT,
  payload:any
}
export interface GuestInhouseCancelCheckOut{
  type: typeof GUEST_INHOUSE_CANCEL_CHECK_OUT,
  payload:any
}
export interface GuestInhouseCancelCheckIn{
  type: typeof GUEST_INHOUSE_CANCEL_CHECK_IN,
  payload:any
}

export interface GuestInhouseVerifyRate{
  type: typeof GUEST_INHOUSE_VERIFY_RATE,
  payload:any
}

export interface GuestInhouseCheckin{
  type: typeof GUSET_INHOUSE_CHECKIN,
  payload:any
}
export interface GuestInhouseCheckinVerify{
  type: typeof GUEST_INHOUSE_CHECKIN_VERIFY,
  payload:any
}

export interface GuestInhouseCheckinRoomOriginal{
  type: typeof GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL,
  payload:any
}
export interface GuestInhouseCheckinRoomOriginalReset{
  type: typeof GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL_RESET
}

export type GuestInhouseAction = 
| GuestInhouseRequest
| GuestInhouseNew
| GuestInhouseUpdate
| GuestInhouseProcessSuccess
| GuestInhouseDataSourceSuccess
| GuestInhouseProcessReset
| GuestInhouseSetFilter
| GuestInhouseDetailRequest
| GuestInhouseDetailDataSourceSuccess
| GuestInhouseRatePlanRequest
| GuestInhouseRatePlanDataSourceSuccess
| GuestInhouseChangeBookingStatus
| GuestInhouseBookingSummaryRequest
| GuestInhouseBookingSummarySuccess
| GuestInhouseCheckOut
| GuestInhouseCancelCheckOut
| GuestInhouseCancelCheckIn
| GuestInhouseVerifyRate
| GuestInhouseCheckin
| GuestInhouseCheckinVerify
| GuestInhouseCheckinRoomOriginal
| GuestInhouseCheckinRoomOriginalReset