import {
    CHANGE_PERSONAL_INFO,
    CHANGE_CONTACT_INFO,
    CHANGE_PASSWORD,
    CHANGE_IMAGE_PROFILE,
    CHANGE_PROCESS_SUCCESS,
    USER_PROFILE_PREVIEW,
    USER_PROFILE_PROCESS_SUCCESS
  } from "constants/ActionTypes"

  export const userProfilePreview = (value) =>{
    return{
      type:USER_PROFILE_PREVIEW,
      payload:value
    }
  }
  export const userProfileProcessSuccess = (value) =>{
    return{
      type: USER_PROFILE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const changePersonalInfo = (value) => {
    return {
      type: CHANGE_PERSONAL_INFO,
      payload:value
    }
  }

  export const changeContactInfo =(value) =>{
    return {
      type:CHANGE_CONTACT_INFO,
      payload:value
    }
  }
  export const changeImageProfile = (value)=>{
    return {
      type: CHANGE_IMAGE_PROFILE,
      payload:value
    }
  }

  export const changePassword = (value) =>{
    return {
      type:CHANGE_PASSWORD,
      payload: value
    }
  }
  export const changeProcessSuccess = (value) =>{
    return{
      type:CHANGE_PROCESS_SUCCESS,
      payload: value
    }
  }
