import {
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  FOLLOW_UP_PREVIEW,
  FOLLOW_UP_NEW,
  FOLLOW_UP_UPDATE,
  FOLLOW_UP_REMOVE,
  FOLLOW_UP_PROCESS_SUCCESS,
  FOLLOW_UP_DATASOURCE_SUCCESS,
  FOLLOW_UP_COMMENT_NEW,
  FOLLOW_UP_COMMENT_UPDATE,
  FOLLOW_UP_COMMENT_REMOVE,
  RESET_STATE
  
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:{
      return{
        loader: false,
        dataSerialize:{},
        dataSource:[],
        process:{},
        keyword:''
      }
    }
    case FOLLOW_UP_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""},
        processAction:""
      }
    }
    case FOLLOW_UP_NEW: {
      return {
          ...state,
          loader: true
      }
    }
    case FOLLOW_UP_UPDATE:{
        return{
          ...state,
          loader:true,
          dataSerialize:action.payload
        }
    }
    case FOLLOW_UP_REMOVE: {
      return {
          ...state,
          loader: false
      }
    }
    case FOLLOW_UP_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:{...action.payload}
      }
    }
    case FOLLOW_UP_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case FOLLOW_UP_COMMENT_NEW:{
      return {
        ...state,
        loader:true
      }
    }
    case FOLLOW_UP_COMMENT_UPDATE:{
      return {
        ...state,
        loader:true,
        dataSerialize:action.payload
      }
    }
    case FOLLOW_UP_COMMENT_REMOVE: {
      return {
          ...state,
          loader: false
      }
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state
  }
}