import {
    CommonsAction,
    CommonState,
    FETCH_ERROR, 
    FETCH_START, 
    FETCH_SUCCESS, 
    HIDE_MESSAGE, 
    SHOW_MESSAGE, 
    TOGGLE_COLLAPSED_NAV,
    WINDOW_WIDTH 
} from 'constants/ActionTypes'

const INIT_STATE: CommonState = {
  error: "",
  loading: false,
  message: '',
  navCollapsed: true,
  width: window.innerWidth
}

export default (state: CommonState = INIT_STATE, action: CommonsAction): CommonState => {
  switch (action.type) {
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      }
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }
    }
    case FETCH_START: {
      return {...state, error: '', message: '', loading: true}
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', message: '', loading: false}
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', message: action.payload, loading: false}
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, message: ''}
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', message: ''}
    }
    default:
      return state
  }
}