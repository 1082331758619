import {
  NOTIFICATION_PREVIEW,
  NOTIFICATION_REMOVE,
  NOTIFICATION_DATASOURCE_SUCCESS,
  NOTIFICATION_PROCESS_SUCCESS
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSource:[],
  process:{status:false,action:"",message:""}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NOTIFICATION_PREVIEW:{
      return{
        ...state,
        loader:false,
        process:{status:false,action:"",message:""}
      }
    }
    case NOTIFICATION_REMOVE:{
      return{
        ...state,
        loader:true
      }
    }
    case NOTIFICATION_DATASOURCE_SUCCESS:{
      return{
        ...state,
        loader:false,
        dataSource:action.payload
      }
    }
    case NOTIFICATION_PROCESS_SUCCESS:{
      return{
        ...state,
        process:action.payload
      }
    }
    default:
      return state
  }
}