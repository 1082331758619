import moment from 'moment'
import { isEmpty } from 'lodash'
import { statusRoom } from "util/masterDataDefault"

export const footerText = {
  copyright: `Newsoft Technologies Consultants Co.,Ltd | Copyright© 2019 - ${moment().year()}`,
  version: `Version ${process.env.REACT_APP_VERSION}`
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberWithCommasPoint = (x) => {
  if (x !== null && x !== "") {
    x = x.toFixed(2)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
}

export const checkCreatData = (id) => {
//   let x = id.toString().indexOf('NEW')
  return  id < 0 ? true : false
}

export const checkEditDeposit = (data) => {
  //console.log('data', data)
  if(isEmpty(data)){
    return true
  }else{
    // let x = data.id.toString().indexOf('N')
    // x = x >=0 ? true : false
    const x  = data.id === null ? true : false
    return x
  }
}


export const defaultDateArrivalDepart = (date) => {
  const arrival = date
  const departure = moment(arrival, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY')
  const dateArrDepart = [  moment(arrival, 'DD/MM/YYYY'), moment(departure, 'DD/MM/YYYY')]
  //console.log('master dateArrDepart',dateArrDepart)
  return dateArrDepart
}

export const setArrDepartChangeNight = (date, night) => {
  const arrival = date[0]
  const departure = moment(arrival, 'DD/MM/YYYY').add((night), 'days').format('DD/MM/YYYY')
  const dateArrDepart = [  moment(arrival, 'DD/MM/YYYY'), moment(departure, 'DD/MM/YYYY')]
  //console.log('change date by night',dateArrDepart)
  return dateArrDepart
}

export const setNightForDate = (date) => {
  const end = date[1]
  const start = date[0]
  //console.log('night****' , end.diff(start, 'days'))
  return end.diff(start, 'days')
}

export const findStrData = (str, text) => {
    let x = str.toString().indexOf(text)
    x = x >=0 ? true : false
    //console.log(text ,  x)
    return  x
  }

//==========================================================
//===== [CONFIG] : DATA BLOCK ROOM STATUS , ROOM STATUS ====
//==========================================================

export const getDataDetailRoom = (data, room) => {
  const res = data.find(e => e.room === room)
  return res
}

export const getDetailTypeRoom = (i, dataBlockRoomById) => {
  const blockRoom = dataBlockRoomById.find((val,index) => { return i === index }) 
  return blockRoom
}

export const getStatusRoom  = (type) => {
  const status = statusRoom.find((e) => { return type === e.status} )
  const { id, name, color, codeColor } = status
  return {id, name, color, codeColor}
}

export const setDataGroupRooms = (data, typeGroup) => {
    const temp = []
    const arrayHeader = []
    const arrayRight = []
      if(typeGroup === 'location'){ 
        data.map((e) => !arrayHeader.includes(e.location_id) ? arrayHeader.push(e.location_id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.location_id].push(e))
      }else if(typeGroup === 'roomType') {
        data.map((e) => !arrayHeader.includes(e.roomtype_id) ? arrayHeader.push(e.roomtype_id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.roomtype_id].push(e))
      }else if(typeGroup === 'bedType') {
        data.map((e) => !arrayHeader.includes(e.bedtype_id) ? arrayHeader.push(e.bedtype_id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.bedtype_id].push(e))
      }else if(typeGroup === 'roomGroup') {
        data.map((e) => !arrayHeader.includes(e.roomgroup_id) ? arrayHeader.push(e.roomgroup_id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.roomgroup_id].push(e))
      }else if(typeGroup === 'building') {
        data.map((e) => !arrayHeader.includes(e.building_id) ? arrayHeader.push(e.building_id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.building_id].push(e))
      }else if(typeGroup === 'exposure') {
        data.map((e) => !arrayHeader.includes(e.exposure_id) ? arrayHeader.push(e.exposure_id) : null)
         arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[e.exposure_id].push(e))
      }else if(typeGroup === 'status') {
        data.map((e) => !arrayHeader.includes(getStatusRoom(e.room_status).id) ? arrayHeader.push(getStatusRoom(e.room_status).id) : null)
        arrayHeader.map((e) =>  temp[e] = [] )
        data.map((e) => temp[getStatusRoom(e.room_status).id].push(e))
      }
      
    temp.map((e) =>  arrayRight.push(e) )
    // console.log('header', arrayHeader)
    // console.log('temp', temp)

    return arrayRight
  }

export const getGroupRoom = (data, index, type) => {
  const temp  =  data.filter((e,i)  => i === index ) 
  switch(type) {
    case 'location':
      return `${ temp[0][0].location === 'null' ? 'None' : temp[0][0].location  } (${temp[0].length})`
    case 'roomType':
      return  `${ temp[0][0].room_type === 'null' ? 'None' : temp[0][0].room_type  } (${temp[0].length})`
    case 'bedType':
      return  `${ temp[0][0].bed_type === 'null' ? 'None' : temp[0][0].bed_type  } (${temp[0].length})`
    case 'roomGroup':
      return  `${ temp[0][0].room_group === 'null' ? 'None' : temp[0][0].room_group  } (${temp[0].length})`
    case 'building':
      return  `${ temp[0][0].building === 'null' ? 'None' : temp[0][0].building  } (${temp[0].length})`
    case 'exposure':
      return  `${ temp[0][0].exposure === 'null' ? 'None' : temp[0][0].exposure  } (${temp[0].length})`
    case 'status':
      return  `${ temp[0][0].room_status === 'null' ? 'None' : getStatusRoom(temp[0][0].room_status).name } (${temp[0].length})`
    default:
      return null
  }
}