import * as actions from "./postingActionType"

export const postingPreview = (payload?: any, sorter?: any): actions.PostingRequest => {
  return {
    type: actions.POSTING_REQUEST,
    payload,
    sorter
  }
}

export const postingProcessSuccess = (payload: any): actions.PostingProcessSuccess => {
  return {
    type: actions.POSTING_PROCESS_SUCCESS,
    payload
  }
}

export const postingDataSourceSuccess = (payload: any): actions.PostingDataSourceSuccess => {
  return {
    type: actions.POSTING_DATASOURCE_SUCCESS,
    payload
  }
}
export const postingProcessReset = () => {
  return {
    type: actions.POSTING_PROCESS_RESET
  }
}

export const postingSetFilter = (payload:any):actions.PostingSetFilter => {
  return {
    type: actions.POSTING_SET_FILTER,
    payload
  }
}
