import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  DEPARTMENT_PREVIEW,
  DEPARTMENT_NEW,
  DEPARTMENT_UPDATE,
  DEPARTMENT_REMOVE,
  DEPARTMENT_SEARCH
} from "constants/ActionTypes"

import {departmentDataSourceUpdate,departmentProcessSuccess,departmentProcessReset} from "appRedux/actions/setup/department/Department"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* departmentPreviewProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/department/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const departmentDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.department_id = obj.id
                    container.department_code = obj.code
                    container.department_name = obj.name
                    container.department_group_id = obj.group_id 
                    container.department_group_name = obj.group 
                    container.dept_type = obj.dept_type
                    container.require_info = obj.require_info
                    container.itemizers = obj.itemizers
                    container.enabled = obj.enabled

                return container
            })
            
            /* if no error put everything to reducer */
             yield put(departmentDataSourceUpdate(departmentDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreDepartmentList",departmentDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(departmentProcessSuccess(processStatus))
            yield put(departmentProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on department preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(departmentProcessSuccess(processStatus))
        yield put(departmentProcessReset())
    }
    
}

function* departmentSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreDepartmentList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_department_name = filter(ReformalData, function(o) {
        return o.department_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_department_name])]

      yield put(departmentDataSourceUpdate(mergeData))
  }else 
      yield put(departmentDataSourceUpdate(ReformalData))
}

function* departmentCreateProcess({payload}){

  const department_name = payload.department_name
  const department_code = payload.department_code
  const groupID = payload.department_group_id||null
  const deptType = payload.dept_type
  const requireInfo = payload.require_info||false
  const itemizers = payload.itemizers
  const active = payload.active

  let departmentInfo = {name:department_name,
                        code:department_code,
                        group_id:groupID,
                        dept_type:deptType,
                        require_info:requireInfo,
                        itemizers,
                        enabled:active}
      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/department/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = departmentInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(departmentProcessSuccess(processStatus))
                yield put(departmentProcessReset())
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(departmentProcessSuccess(processStatus))
              yield put(departmentProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(departmentProcessSuccess(processStatus))
            yield put(departmentProcessReset())
      }
}

function* departmentUpdateProcess({payload}){
  const department_name = payload.department_name
  const department_id = payload.department_id
  const department_code = payload.department_code
  const groupID = payload.department_group_id||null
  const deptType = payload.dept_type
  const requireInfo = payload.require_info
  const itemizers = payload.itemizers
  const active = payload.active

  let departmentInfo = {}
      departmentInfo.id = department_id
      departmentInfo.name = department_name
      departmentInfo.code = department_code
      departmentInfo.group_id = groupID
      departmentInfo.dept_type = deptType
      departmentInfo.require_info = requireInfo
      departmentInfo.itemizers = itemizers
      departmentInfo.enabled = active
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/department/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = departmentInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(departmentProcessSuccess(processStatus))
            yield put(departmentProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(departmentProcessSuccess(processStatus))
          yield put(departmentProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(departmentProcessSuccess(processStatus))
        yield put(departmentProcessReset())
  }
}

function* departmentRemoveProcess({payload}){
    const departmentRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/department/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : departmentRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(departmentProcessSuccess(processStatus))
          yield put(departmentProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(departmentProcessSuccess(processStatus))
          yield put(departmentProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(departmentProcessSuccess(processStatus))
      yield put(departmentProcessReset())
    }
}

/* Watcher Saga */
export function* departmentPreview() {
  yield takeLatest(DEPARTMENT_PREVIEW, departmentPreviewProcess)
}
export function* departmentSearch(){
  yield takeLatest(DEPARTMENT_SEARCH, departmentSearchProcess)
}
export function* departmentCreate(){
  yield takeEvery(DEPARTMENT_NEW,departmentCreateProcess)
}
export function* departmentUpdate(){
  yield takeEvery(DEPARTMENT_UPDATE, departmentUpdateProcess)
}
export function* departmentRemove(){
  yield takeEvery(DEPARTMENT_REMOVE,departmentRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(departmentPreview),
             fork(departmentSearch),
             fork(departmentCreate),
             fork(departmentUpdate),
             fork(departmentRemove)])


}
