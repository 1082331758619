import { stateSet } from "constants/StateDefault"

export default function permissionVerify(code?: string) {

    const states: any = localStorage.getItem(stateSet)
    if (states) {
        const JsonParse = JSON.parse(states)
        if (!JsonParse.auth?.authUser) return undefined

        const user_permission = JsonParse.auth.authUser.user_permission
        if (!code || !user_permission) return undefined

        const result = user_permission.find((o: any) => o.code === code)
        return result
    }
}