
export const ISSUE_LOG_REQUEST = "ISSUE_LOG_REQUEST"
export const ISSUE_LOG_DATASOURCE_SUCCESS = "ISSUE_LOG_DATASOURCE_SUCCESS"
export const ISSUE_LOG_PROCESS_SUCCESS = "ISSUE_LOG_PROCESS_SUCCESS"
export const ISSUE_LOG_PROCESS_RESET = "ISSUE_LOG_PROCESS_RESET"
export const ISSUE_LOG_RESET = "ISSUE_LOG_RESET"

export interface IssueLogRequest {
    type: typeof ISSUE_LOG_REQUEST,
    payload: {
        type: "booking" | "registation",
        id: number
    }
}

export interface IssueLogProcessSuccess {
    type: typeof ISSUE_LOG_PROCESS_SUCCESS,
    payload: {}
}

export interface IssueLogDataSourceSuccess {
    type: typeof ISSUE_LOG_DATASOURCE_SUCCESS,
    payload: {
        type: "booking" | "registation",
        value: Array<any>
    }
}
export interface IssueLogProcessReset {
    type: typeof ISSUE_LOG_PROCESS_RESET
}

export interface IssueLogReset {
    type: typeof ISSUE_LOG_RESET,
    payload?: {
        type: "booking" | "registation"
    }
}

export type IssueLogAction =
    | IssueLogRequest
    | IssueLogProcessSuccess
    | IssueLogDataSourceSuccess
    | IssueLogProcessReset
    | IssueLogReset
