import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/rsv/IssueCardActionType"
import * as actionCreators from "appRedux/actions/rsv/IssueCard"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* issueCardRequestProcess({ payload }: actionTypes.IssueCardRequest) {

  const { bookingID } = payload
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/addon/keycard/issueInfo?booking_id=${bookingID}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      const issueCardInfo = response.data
      yield put(actionCreators.issueCardDatasouceSuccess(issueCardInfo))

    } else {
      console.log("Error : ", response)
      const { message } = response
      const processStatus = { status: false, action: "issueCardPreview", message }
      yield put(actionCreators.issueCardProcessSuccess(processStatus))
      yield put(actionCreators.issueCardProcessReset())
      if (response.status === 403) {
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on issue card info : ", error)
    const processStatus = { status: false, action: "issueCardPreview", message: error }
    yield put(actionCreators.issueCardProcessSuccess(processStatus))
    yield put(actionCreators.issueCardProcessReset())
  }
}

function* issueCardNewCardProcess({ payload }: actionTypes.IssueCardNewCard) {
  const url = `${process.env.REACT_APP_ISSUE_CARD_URL}/api/KeyCard/newKeyCard`
  /*
  example data
  const DATA_BODY = {
    "status": "RSV",
    "room_id": 372,
    "room_number": "5714",
    "register_id": null,
    "booking_id": 17982,
    "last_name": "STANFORD",
    "first_name": "ROBIN",
    "full_name": "STANFORD ROBIN",
    "valid_from": "2019-05-29",
    "valid_until": "2019-06-01",
    "number_of_cards": 2,
    "maximum_cards": 2,
    "issued_cards": 0,
    "card_data": {
      "Agent": "ADEL",
      "RoomID": "055714",
      "RoomNo": "5714",
      "cardSN": null,
      "cardIdx": null,
      "DataWrite": "",
      "Data": {
        "SubDoor": "0000",
        "PublicDoor": "00000000"
      }
    }
  }*/
 const DATA_BODY ={...payload}
  try {
    const response: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number }, data?: any, status?: number } = yield APIPostRequest(url, DATA_BODY, {
      'Content-Type': 'application/json'
    })

    if (response?.errorCode === 0) {
      const issueCardInfo: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number } } = response
      yield put(actionCreators.issueCardSetNextCard({ cardSerial: issueCardInfo.cardData.cardSerial, cardIndex: issueCardInfo.cardData.cardIndex }))
      const processStatus = { status: true, action: "issueNewCard", message: issueCardInfo.message }
      yield put(actionCreators.issueCardProcessSuccess(processStatus))
      yield put(actionCreators.issueCardProcessReset())

    } else {
      console.log("Error : ", response)
      //const {message} = response.data
      let temp: string = ""
      if (response.status === 400) {
        temp = "Validate errors"
      } else if (response.status === 500) {
        const { message } = response.data
        temp = message
      }

      const processStatus = { status: false, action: "issueNewCard", message: temp }
      yield put(actionCreators.issueCardProcessSuccess(processStatus))
      yield put(actionCreators.issueCardProcessReset())
    }

  } catch (error) {
    console.log("Error on issue new card info : ", error)
    const processStatus = { status: false, action: "issueNewCard", message: error }
    yield put(actionCreators.issueCardProcessSuccess(processStatus))
    yield put(actionCreators.issueCardProcessReset())
  }
}

function* issueCardCopyCardProcess({ payload }: actionTypes.IssueCardCopyCard) {
  const url = `${process.env.REACT_APP_ISSUE_CARD_URL}/api/KeyCard/copyKeyCard`

  const DATA_BODY = {...payload}
  try {
    const response: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number }, data?: any, status?: number } = yield APIPostRequest(url, DATA_BODY, {
      'Content-Type': 'application/json'
    })

    if (response?.errorCode === 0) {
      const issueCardInfo: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number } } = response
      yield put(actionCreators.issueCardSetNextCard({ cardSerial: issueCardInfo.cardData.cardSerial, cardIndex: issueCardInfo.cardData.cardIndex }))
      const processStatus = { status: true, action: "issueCopyCard", message: issueCardInfo.message }
      yield put(actionCreators.issueCardProcessSuccess(processStatus))
      yield put(actionCreators.issueCardProcessReset())

    } else {
      console.log("Error : ", response)
      //const {message} = response.data
      let temp: string = ""
      if (response.status === 400) {
        temp = "Validate errors"
      } else if (response.status === 500) {
        const { message } = response.data
        temp = message
      }

      const processStatus = { status: false, action: "issueCopyCard", message: temp }
      yield put(actionCreators.issueCardProcessSuccess(processStatus))
      yield put(actionCreators.issueCardProcessReset())
    }

  } catch (error) {
    console.log("Error on issue copy card info : ", error)
    const processStatus = { status: false, action: "issueCopyCard", message: error }
    yield put(actionCreators.issueCardProcessSuccess(processStatus))
    yield put(actionCreators.issueCardProcessReset())
  }
}

function* issueCardRequest() {
  yield takeEvery(actionTypes.ISSUE_CARD_REQUEST, issueCardRequestProcess)
}
function* issueCardNewCard() {
  yield takeEvery(actionTypes.ISSUE_CARD_NEW_CARD, issueCardNewCardProcess)
}
function* issueCardCopyCard() {
  yield takeEvery(actionTypes.ISSUE_CARD_COPY_CARD, issueCardCopyCardProcess)
}
export default function* rootSaga() {
  yield all([fork(issueCardRequest),
  fork(issueCardNewCard),
  fork(issueCardCopyCard)
  ])
}


