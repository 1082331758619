import { all, fork, put,takeEvery,takeLatest, call,select }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/setup/user/userActionType"
import * as actionCreators from "appRedux/actions/setup/user"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_AUTH,API_HEADERS } from "constants/ApiSetting"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { AppState } from 'ReducerState'
import { isUndefined,isEmpty,isNull,orderBy,filter } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"

function* userTablePreviewProcess({ payload }: actionTypes.UserRequest) {
  const userDataInfo = payload
  const propID = userDataInfo.prop_id
  const userGroupType = userDataInfo.user_group_type
  const propGroupID = userDataInfo.prop_group_id
  const  { access_token }  = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_AUTH}auth/user/?user_group_type=${userGroupType}&prop_id=${propID}`
  API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response:ApiResponse = yield APIGetRequest(url,API_HEADERS)

    if(response.status === 200)
    { 
          const userDataSource = orderBy(response.data,[o=>o.user_name.toLowerCase()],['asc']).map((user,key) =>{
            const container = {} as any
                  container.key = key
                  container.log_id = user.log_id
                  container.user_id = user.user_id
                  container.user_name = user.user_name
                  container.full_name = user.full_name||""
                  container.group_id = user.group_id
                  container.group_name = user.group_name
                  container.group_type = user.group_type
                  container.mobile = user.mobile||""
                  container.telephone = user.telephone||""
                  container.profile_pic = user.profile_pic
                  container.properties = []
                  container.email = user.email||""
                  container.job_position = user.job_position||""
                  container.active = (user.active)?true:false
                  if(!isEmpty(user.group_name))
                      container.group_tags = [user.group_name] 
                  else 
                      container.group_tags = []

                  if(!isEmpty(user.job_position))
                      container.job_tags = [user.job_position] 
                  else 
                      container.job_tags = []

                  if(!isEmpty(user.properties)){
                        const propertieID = user.properties.map((value:any)=>{
                          const valueID = (value.id)                         
                          return valueID
                        })
                        container.properties =[...propertieID]
                  }
              return container
          })
          
          /* Get propertyListInfo */
          let propertyInfo = []
          let url = ""
          if(userGroupType === "S")
              url = `${API_URL_AUTH}auth/property/?prop_group_id=all`
          else if( userGroupType === "G" && !isNull(propGroupID))
              url = `${API_URL_AUTH}auth/property/?prop_group_id=${propGroupID}`
          else if( userGroupType === "U" || userGroupType === "D" || userGroupType === "P")
              url = `${API_URL_AUTH}auth/property/?prop_group_id=${propID}`

            const resultData:ApiResponse = yield APIGetRequest(url,API_HEADERS)
            if(resultData.status===200){
              propertyInfo = resultData.data.map((val:any)=>{
                const valueData = {value: val.id, name:val.name }
                return valueData
              })
            }else{
              const {message} = resultData.data
              console.log("Error : ",message)
              const processStatus = { status: false, action: "preview", message}
              yield put(actionCreators.userProcessSuccess(processStatus))
              yield put(actionCreators.userProcessReset())
              return
            }

          /* End GetPropertyListInfo */
          
          /* Get UserGroupList */
          let groupTypeInfo = []
              url = ""
          if(userGroupType === "S")
            url = `${API_URL_AUTH}auth/userGroup/?include_group_default=true&enable=true`
          else if(userGroupType === "G")
            url = `${API_URL_AUTH}auth/userGroup/?prop_group_id=${propGroupID}&enable=true`
          else
            url = `${API_URL_AUTH}auth/userGroup/?prop_id=${propID}&enable=true`
          
          const resultGroupList:ApiResponse = yield APIGetRequest(url,API_HEADERS)
          if(resultGroupList.status===200){
            groupTypeInfo = resultGroupList.data.map((val:any)=>{
              const valueData = {value: val.id,name:val.name}
              return valueData
            })
          }else{
            const {message} = resultGroupList.data
            console.log("Error : ",message)
            const processStatus = { status: false, action: "preview", message}
            yield put(actionCreators.userProcessSuccess(processStatus))
            yield put(actionCreators.userProcessReset())
            return
          }
          /* End UserGroupList */
          
          /* if no error put everything to reducer */
          yield put(actionCreators.userDataSourceUpdate(userDataSource))
          yield put(actionCreators.getPropertyList(propertyInfo))
          yield put(actionCreators.getUserGroupList(groupTypeInfo))

          /* Keep userDataSource on localstore for userTableSearchProcess */
          yield IndexedDBAdd('ListBoxDB','objStoreUserList', userDataSource)
    }else{
          console.log("Error : ",response)
          /* Show error message and signout */
          const {message,data} = response
          const processStatus = { status: false, action: "preview", message:message||""}

          yield put(actionCreators.userDataSourceUpdate([]))
          yield put(actionCreators.userProcessSuccess(processStatus))
          yield put(actionCreators.userProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
    }
    
  } catch (error) {
      console.log("Error on saga userTablePreviewProcess : ",error)
      const processStatus = { status: false, action: "preview", message:error}
      yield put(actionCreators.userProcessSuccess(processStatus))
      yield put(actionCreators.userProcessReset())
  }
  
}

function* userTableSearchProcess({payload}: actionTypes.UserSearch){
  const storeValue:any = IndexDBGetDataAll('ListBoxDB', 'objStoreUserList')
  if(!storeValue) return 

  const ReformalData = [...storeValue[0]]

  if(!isUndefined(payload)){
      /* Search user name / full name / email */
      const keyword = payload.toLowerCase()
      const filtered_user = filter(ReformalData, function(o) {
        return o.user_name.toLowerCase().indexOf(keyword) > -1 ||
               o.full_name.toLowerCase().indexOf(keyword) > -1 ||
               o.email.toLowerCase().indexOf(keyword) > -1 
      })

      const mergeData = [...new Set([...filtered_user])]
      //console.log("New data no duplicate : "+JSON.stringify([...new Set([...filtered_user_name, ...filtered_full_name,...filtered_email])]))
      yield put(actionCreators.userDataSourceUpdate(mergeData))
  }else 
      yield put(actionCreators.userDataSourceUpdate(ReformalData))
}

function* userCreateProcess({payload}: actionTypes.UserNew){
  const {user_name,full_name,group_type,job_position,email,mobile,
    telephone,multiple_property,upload} = payload

  let userInfo = {} as any
      userInfo.user_name = user_name
      userInfo.full_name = full_name||null
      userInfo.user_group_id = group_type
      userInfo.job_position = job_position||null
      userInfo.email = email
      userInfo.mobile = mobile||null
      userInfo.telephone = telephone||null
      userInfo.active = true /* Only create new user force active only */
      userInfo.properties = [] 
      if(!isEmpty(multiple_property)){
        multiple_property.map((val:any)=>{
            const objData = {id:val}
            userInfo.properties.push(objData)
            return true
        })
      }

      if(!isEmpty(upload)){
        const dataSplit = upload.split(",")
        const objData = { type: dataSplit[0], image: dataSplit[1] }
        userInfo.profile_pic = objData
      }

      try {
            const url = `${API_URL_AUTH}auth/user/create/`
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = userInfo
            const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

            if (response.status === 200) {
                const processStatus = { status: true, action: "create", message: "Create success." }
                yield put(actionCreators.userProcessSuccess(processStatus))
            } else if (response.status === 201) {
                const processStatus = { status: true, action: "create", message: response?.data?.message || "User created with a warning." }
                yield put(actionCreators.userProcessSuccess(processStatus))
            } else {
                const message = response?.data?.message || "Unexpected error"
                const data = response?.data?.data
                const processStatus = { status: false, action: "create", message }
                yield put(actionCreators.userProcessSuccess(processStatus))
                yield put(actionCreators.userProcessReset())
                if (response.status === 403 && data) {
                    yield put(userSignOut())
                }
            }

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(actionCreators.userProcessSuccess(processStatus))
            yield put(actionCreators.userProcessReset())
      }

}

function* userUpdateProcess({payload}: actionTypes.UserUpdate){

  const {user_name,full_name,group_type,job_position,email,mobile,
    telephone,multiple_property,upload,user_id,active} = payload
  const state:AppState = yield select()
  const AuthUser = state.auth
 // const { user_id:userLoginID } = AuthUser.authUser;
  const { user_id: userLoginID } = (AuthUser as any).authUser ?? {};

  
  let userInfo = {} as any    
      userInfo.user_id = user_id
      userInfo.user_name = user_name
      userInfo.full_name = full_name||null
      userInfo.user_group_id = group_type
      userInfo.job_position = job_position||null
      userInfo.email = email
      userInfo.mobile = mobile||null
      userInfo.telephone = telephone||null
      userInfo.active = (userLoginID === user_id)?true:active
      userInfo.properties = [] 

      if(!isEmpty(multiple_property)){
        multiple_property.map((val:any)=>{
            const objData = {id:val}
            userInfo.properties.push(objData)
            return true
        })
      }

      if(!isEmpty(upload)){
        const dataSplit = upload.split(",")
        const objData = { type: dataSplit[0], image: dataSplit[1] }
        userInfo.profile_pic = objData
      }

  try {
        const url = `${API_URL_AUTH}auth/user/updateInfo/`
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = userInfo
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(actionCreators.userProcessSuccess(processStatus))
            yield put(actionCreators.userProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(actionCreators.userProcessSuccess(processStatus))
          yield put(actionCreators.userProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }
  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(actionCreators.userProcessSuccess(processStatus))
        yield put(actionCreators.userProcessReset())
  }
}

function* userRemoveProcess({payload}: actionTypes.UserRemove){
  const userRemoveID = payload

  try{
      const url = `${API_URL_AUTH}auth/user/delete/`
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY = {user_id : userRemoveID}
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_HEADERS)
      if(response.status === 200){
        const processStatus = { status: true, action: "delete", message:"Delete success."}
        yield put(actionCreators.userProcessSuccess(processStatus))
      }else{
        const {message,data} = response.data
        const processStatus = { status: false, action: "delete", message}
        yield put(actionCreators.userProcessSuccess(processStatus))
        yield put(actionCreators.userProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
  }
  catch(error){
    console.log("Error : ",error)
    const processStatus = { status: false, action: "delete", message:error}
    yield put(actionCreators.userProcessSuccess(processStatus))
    yield put(actionCreators.userProcessReset())
  }
}

function* userPermissionPreviewProcess({payload}: actionTypes.UserPermissionPreview){

  const userDataInfo = payload
  const UserID = userDataInfo.user_id
  const state:AppState = yield select()
  const AuthUser = state.auth
 // const { prop_id:propID } = AuthUser.authUser
  const { prop_id: propID } = (AuthUser as any).authUser ?? {};


  try {
      const url = `${API_URL_AUTH}auth/user/permission/?prop_id=${propID}&user_id=${UserID}`
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const response:ApiResponse = yield APIGetRequest(url,API_HEADERS)

      let DefaultValue = []
      const ReformData = response.data.map((value:any)=>{
            const temp = {} as any
                temp.category = value.category
                temp.isGrantedCode = value.isGrantedCode
                temp.codeInfo = orderBy(value.codeInfo,['name'],['asc'])

                /* permission code default value */
                if(!isEmpty(value.isGrantedCode)){
                  for(const code of value.isGrantedCode) DefaultValue.push(code)
                }

            return temp
      })

      if(response.status === 200){
        yield put(actionCreators.userPermissionSetDataSource(ReformData))
      }else{
        const {message,data} = response.data
        const processStatus = { status: false, action: "preview-permission", message}
        yield put(actionCreators.userProcessSuccess(processStatus))
        yield put(actionCreators.userProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }

  } catch (error) {
      console.log("Error : ",error)
      const processStatus = { status: false, action: "preview-permission", message:error}
      yield put(actionCreators.userProcessSuccess(processStatus))
  }
}

function* userPermissionUpdateProcess({payload}: actionTypes.UserPermissionUpdate){
  const permisionInfo = [...payload.permissionGroup]
  const userID = payload.userInfo.user_id
  const state:AppState = yield select()
  const AuthUser = state.auth
  //const { prop_id:propID } = AuthUser.authUser
  const { prop_id: propID } = (AuthUser as any).authUser ?? {};

  let permissionCode:Array<any> = []
  permisionInfo.map((value)=>{
    value.forEach((element:any) => {
      permissionCode.push({code:element})
    })  
    return true
  })

  let permissionUpdateInfo = {} as any
      permissionUpdateInfo.prop_id = propID
      permissionUpdateInfo.user_id = [userID]
      permissionUpdateInfo.user_permission = permissionCode

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/updatePermission/`
    const API_DATABODY = permissionUpdateInfo
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_HEADERS)
    if(response.status === 200){
      const processStatus = { status: true, action: "update", message:"Update permission success"}
      yield put(actionCreators.userPermissionUpdateSuccess(processStatus))
    }else{
      const {message,data} = response.data
      const processStatus = { status: false, action: "update", message}
      yield put(actionCreators.userPermissionUpdateSuccess(processStatus))
      yield put(actionCreators.userProcessReset())
      if(response.status === 403){
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
      console.log("error : ",error)
      const processStatus = { status: false, action: "update", message:error}
      yield put(actionCreators.userPermissionUpdateSuccess(processStatus))
      yield put(actionCreators.userProcessReset())
}
}

function* userSwitchEnabledProcess({payload}: actionTypes.UserSwitchEnabled){

  const { user_id, enabled } = payload

  let userInfo = {} as any
      userInfo.user_id = user_id
      userInfo.active = enabled
  try {
        const url = `${API_URL_AUTH}auth/user/updateInfo/`
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = userInfo
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "switchActive", message:"Update success."}
            yield put(actionCreators.userProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "switchActive", message}
          yield put(actionCreators.userProcessSuccess(processStatus))
          yield put(actionCreators.userProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }
  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "switchActive", message:error}
        yield put(actionCreators.userProcessSuccess(processStatus))
        yield put(actionCreators.userProcessReset())
  }
}


function* userRequest() {
  yield takeEvery(actionTypes.USER_PREVIEW, userTablePreviewProcess)
}

function* userSearchPreview(){
  yield takeLatest(actionTypes.USER_SEARCH, userTableSearchProcess)
}

function* userCreate(){
  yield takeEvery(actionTypes.USER_NEW,userCreateProcess)
}
function* userUpdate(){
  yield takeEvery(actionTypes.USER_UPDATE, userUpdateProcess)
}
function* userRemove(){
  yield takeEvery(actionTypes.USER_REMOVE,userRemoveProcess)
}
function* userPermissionPreview(){
  yield takeLatest(actionTypes.USER_PERMISSION_PREVIEW,userPermissionPreviewProcess)
}
function* userPermissionUpdate(){
  yield takeEvery(actionTypes.USER_PERMISSION_UPDATE,userPermissionUpdateProcess)
}
function* userSwitchEnabled(){
  yield takeLatest(actionTypes.USER_SWITCH_ENABLED,userSwitchEnabledProcess)
}

export default function* rootSaga() {
  yield all([fork(userRequest),
             fork(userSearchPreview),
             fork(userCreate),
             fork(userUpdate),
             fork(userRemove),
             fork(userPermissionPreview),
             fork(userPermissionUpdate),
             fork(userSwitchEnabled)
  ])
}


