// eslint-disable-next-line
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, message } from "antd";

import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { footerText } from "util/config";
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  hideAuthLoader,
  clearRequestResetUser,
} from "appRedux/actions/Auth";
import { useDocumentTitle, getItems } from "util/custom";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import { AppState } from "ReducerState";
import { isEmpty } from "lodash";
import * as yup from "yup";
import styled from "styled-components";

export const DivBlockWarning = styled.div`
  font-size: 12px;
  font-style: italic;
  color: red;
  font-weight: lighter;
`;

const SignIn = () => {
  useDocumentTitle("Fromas Cloud");

  const { loader, alertMessage, showMessage } = useSelector(
    (state: AppState) => state.auth
  );
  const [initialData] = useState<any>({
    username: undefined,
    password: undefined,
  });

 
  const dispatch = useDispatch();
  const history = useHistory();

  const { access_token } = getItems<any>(["access_token"]);

  useEffect(() => {
    if (showMessage) {
      if (!isEmpty(alertMessage))
        message.error((alertMessage).toString());
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
  }, [showMessage, alertMessage, dispatch]);

  useEffect(() => {
    if (access_token) history.replace("/main/dashboard");
  }, [access_token, history]);

  const handleSubmit = (values: any) => {
    dispatch(showAuthLoader());
    dispatch(userSignIn(values));
    setTimeout(() => {
      dispatch(hideAuthLoader());
    }, 3000);
  };

  const handleActivateClick = (values: any) => {
  //  dispatch(showAuthLoader());
   
    dispatch(clearRequestResetUser());
    history.push('/request/activate/');
   
    // setTimeout(() => {
      
    //   dispatch(hideAuthLoader());
    // }, 3000);
  };

  const handleResetPasswordClick = (values: any) => {
    //  dispatch(showAuthLoader());
     
      dispatch(clearRequestResetUser());
      history.push('/request/resetpassword/');
     
     
    };

  let schema = yup.object().shape({
    username: yup
      .string()
      .required("Please input username")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9_.-]+$/,
        "Please specify your property name like your_username@your_property"
      ),
    password: yup.string().required("Please input your password!"),
  });

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/signin-bg.jpg")} alt="Neature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p>
                <IntlMessages id="app.userAuth.bySigning" />
              </p>
            </div>
            <div className="gx-app-logo">
              {/* <img alt="example" src={require("assets/images/logo.png")}/> */}
            </div>
          </div>
          <div className="gx-app-login-content">
            <Formik
              key={`main-form`}
              enableReinitialize={true}
              initialValues={initialData}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, setFieldValue, values }: any) => (
                <Form
                  layout="horizontal"
                  className="gx-signin-form gx-form-row0"
                >
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      name="username"
                      autoFocus
                      placeholder="Ex. developer@newsoft"
                    />
                    {errors?.username && touched.username && (
                      <DivBlockWarning>{errors.username}</DivBlockWarning>
                    )}
                  </div>
                  <div style={{ marginBottom: 20 }}>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Password"
                    />
                    {errors?.password && touched.password && (
                      <DivBlockWarning>{errors.password}</DivBlockWarning>
                    )}
                  </div>

                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </Form>
              )}
            </Formik>
            <div style={{ marginTop: 10 }}>
              {/* <Link to="/verify/activate/"><span className="gx-fs-sm" style={{marginRight:20}}>Activate</span></Link> */}
              {/* <Link to="/verify/forgot/"><span className="gx-fs-sm">Forgot Password?</span></Link> */}
              {/* <Link to="/request/activate/">
                <span className="gx-fs-sm" style={{ marginRight: 20 }}>
                  Activate
                </span>
              </Link> */}
              <span
                className="gx-fs-sm"
                style={{ marginRight: 20, cursor: "pointer" }}
                onClick={handleActivateClick}
              >
                Activate
              </span>
              {/* <Link to="/request/forgot/">
                <span className="gx-fs-sm" style={{ marginRight: 20 }}>
                  Forgot Password
                </span>
              </Link> */}
               <span
                className="gx-fs-sm"
                style={{ marginRight: 20, cursor: "pointer" }}
                onClick={handleResetPasswordClick}
              >
                Forgot Password
              </span>
            </div>
            <div>
              <div className="gx-text-light gx-fs-sm">{footerText.version}</div>
              <div className="gx-text-light gx-fs-sm">
                {footerText.copyright}
              </div>
            </div>
          </div>

          {loader ? (
            <div className="gx-loader-view">
              <CircularProgress className={"circular-loading"} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
