import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  ROOM_TYPE_PREVIEW,
  ROOM_TYPE_NEW,
  ROOM_TYPE_UPDATE,
  ROOM_TYPE_REMOVE,
  ROOM_TYPE_SEARCH
} from "constants/ActionTypes"

import {roomTypeDataSourceUpdate,roomTypeProcessSuccess,roomTypeProcessReset} from "appRedux/actions/setup/roomInformation/RoomType"
import {userSignOut} from "appRedux/actions/Auth"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* roomTypePreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomType/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const roomTypeDataSource = orderBy(response.data,[o => o.order_seq],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.room_type_id = obj.id
                    container.room_type_name = obj.name
                    container.room_code = obj.code
                    container.room_description = obj.description||""
                    container.room_order_seq = obj.order_seq||0
                    container.room_size = obj.room_size||""
                    container.room_total_rooms = obj.total_rooms||""
                    container.room_rate = obj.room_rate||""
                    container.room_child_rate = obj.child_rate||""
                    container.room_infant_rate = obj.infant_rate||""
                    container.room_extrabed_rate = obj.extrabed_rate||""
                    container.room_min_rate = obj.min_rate||""
                    container.room_max_adult = obj.max_adult
                    container.room_max_child = obj.max_child
                    container.room_max_infant = obj.max_infant
                    container.room_max_pax = obj.max_pax
                    container.room_allow_overbook = obj.allow_overbook
                    container.room_alias = obj.alias||""
                    container.room_include_adult = obj.include_adult||0
                    container.room_include_child = obj.include_child||0
                    container.room_include_infant = obj.include_infant||0 
                    container.room_extra_adult= obj.extra_adult||0
                    container.room_extra_child= obj.extra_child||0
                    container.room_extra_infant= obj.extra_infant||0
                    container.room_group_id = obj.room_group_id 
                    container.room_group_name = obj.room_group||""
                return container
            })
            /* if no error put everything to reducer */
            yield put(roomTypeDataSourceUpdate(roomTypeDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreRoomTypeList",roomTypeDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(roomTypeProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on room type preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(roomTypeProcessSuccess(processStatus))
    }
    
}

function* roomTypeSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRoomTypeList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.room_type_name.toLowerCase().indexOf(keyword) > -1 ||
                o.room_code.toLowerCase().indexOf(keyword) > -1 ||
                o.room_group_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(roomTypeDataSourceUpdate(mergeData))
  }else 
      yield put(roomTypeDataSourceUpdate(ReformalData))
}

function* roomTypeCreateProcess({payload}){

  const room_type_name = payload.room_type_name
  const room_code = payload.room_code
  const room_description = payload.room_description||null
  const room_order_seq = parseInt(payload.room_order_seq)||null
  const room_size = payload.room_size||null
  const room_total_rooms = parseInt(payload.room_total_rooms)||null
  const room_rate = parseFloat(payload.room_rate)||null
  const room_child_rate = parseFloat(payload.room_child_rate)||null
  const room_infant_rate = parseFloat(payload.room_infant_rate)||null
  const room_extrabed_rate = parseFloat(payload.room_extrabed_rate)||null
  const room_min_rate = parseFloat(payload.room_min_rate)||null
  const room_max_adult = parseInt(payload.room_max_adult)||null
  const room_max_child = parseInt(payload.room_max_child)||null
  const room_max_infant = parseInt(payload.room_max_infant)||null
  const room_max_pax = parseInt(payload.room_max_pax)||null
  const room_allow_overbook = payload.room_allow_overbook
  const room_alias = payload.room_alias||null
  const room_include_adult = parseInt(payload.room_include_adult)||null
  const room_include_child = parseInt(payload.room_include_child)||null
  const room_include_infant = parseInt(payload.room_include_infant)||null
  const room_group_id  = payload.room_group_id||null

  let roomTypeInfo = {}
      roomTypeInfo.name = room_type_name
      roomTypeInfo.code = room_code
      roomTypeInfo.description = room_description
      roomTypeInfo.order_seq = room_order_seq
      roomTypeInfo.room_size = room_size
      roomTypeInfo.total_rooms = room_total_rooms
      roomTypeInfo.room_rate = room_rate
      roomTypeInfo.child_rate = room_child_rate 
      roomTypeInfo.infant_rate = room_infant_rate
      roomTypeInfo.extrabed_rate = room_extrabed_rate
      roomTypeInfo.min_rate = room_min_rate 
      roomTypeInfo.max_adult = room_max_adult 
      roomTypeInfo.max_child = room_max_child 
      roomTypeInfo.max_infant = room_max_infant
      roomTypeInfo.max_pax = room_max_pax
      roomTypeInfo.allow_overbook = room_allow_overbook 
      roomTypeInfo.alias = room_alias 
      roomTypeInfo.include_adult = room_include_adult 
      roomTypeInfo.include_child = room_include_child 
      roomTypeInfo.include_infant = room_include_infant 
      roomTypeInfo.extra_adult = payload.extra_adult ? parseInt(payload.extra_adult) : null
      roomTypeInfo.extra_child = payload.extra_child ? parseInt(payload.extra_child) : null
      roomTypeInfo.extra_infant = payload.extra_infant ? parseInt(payload.extra_infant) : null
      roomTypeInfo.group_id = room_group_id  

      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomType/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = roomTypeInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(roomTypeProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(roomTypeProcessSuccess(processStatus))
              yield put(roomTypeProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(roomTypeProcessSuccess(processStatus))
            yield put(roomTypeProcessReset())
      }
}

function* roomTypeUpdateProcess({payload}){

  const room_type_id = payload.room_type_id
  const room_type_name = payload.room_type_name
  const room_code = payload.room_code
  const room_description = payload.room_description||null
  const room_order_seq = parseInt(payload.room_order_seq)||null
  const room_size = payload.room_size||null
  const room_total_rooms = parseInt(payload.room_total_rooms)||null
  const room_rate = parseFloat(payload.room_rate)||null
  const room_child_rate = parseFloat(payload.room_child_rate)||null
  const room_infant_rate = parseFloat(payload.room_infant_rate)||null
  const room_extrabed_rate = parseFloat(payload.room_extrabed_rate)||null
  const room_min_rate = parseFloat(payload.room_min_rate)||null
  const room_max_adult = parseInt(payload.room_max_adult)||null
  const room_max_child = parseInt(payload.room_max_child)||null
  const room_max_infant = parseInt(payload.room_max_infant)||null
  const room_max_pax = parseInt(payload.room_max_pax)||null
  const room_allow_overbook = payload.room_allow_overbook
  const room_alias = payload.room_alias||null
  const room_include_adult = parseInt(payload.room_include_adult)||null
  const room_include_child = parseInt(payload.room_include_child)||null
  const room_include_infant = parseInt(payload.room_include_infant)||null
const room_extra_adult =  parseInt(payload.room_extra_adult) || null
const room_extra_child = parseInt(payload.room_extra_child) || null
const room_extra_infant = parseInt(payload.room_extra_infant) || null
  const room_group_id  = payload.room_group_id||null
 
  let roomTypeInfo = {}
      roomTypeInfo.id = room_type_id
      roomTypeInfo.name = room_type_name
      roomTypeInfo.code = room_code
      roomTypeInfo.description = room_description
      roomTypeInfo.order_seq = room_order_seq
      roomTypeInfo.room_size = room_size
      roomTypeInfo.total_rooms = room_total_rooms
      roomTypeInfo.room_rate = room_rate
      roomTypeInfo.child_rate = room_child_rate 
      roomTypeInfo.infant_rate = room_infant_rate
      roomTypeInfo.extrabed_rate = room_extrabed_rate
      roomTypeInfo.min_rate = room_min_rate 
      roomTypeInfo.max_adult = room_max_adult 
      roomTypeInfo.max_child = room_max_child 
      roomTypeInfo.max_infant = room_max_infant
      roomTypeInfo.max_pax = room_max_pax
      roomTypeInfo.allow_overbook = room_allow_overbook 
      roomTypeInfo.alias = room_alias 
      roomTypeInfo.include_adult = room_include_adult 
      roomTypeInfo.include_child = room_include_child 
      roomTypeInfo.include_infant = room_include_infant 
      roomTypeInfo.extra_adult = room_extra_adult
      roomTypeInfo.extra_child = room_extra_child
      roomTypeInfo.extra_infant = room_extra_infant
      roomTypeInfo.group_id = room_group_id  

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomType/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = roomTypeInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(roomTypeProcessSuccess(processStatus))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(roomTypeProcessSuccess(processStatus))
          yield put(roomTypeProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(roomTypeProcessSuccess(processStatus))
        yield put(roomTypeProcessReset())
  }
}

function* roomTypeRemoveProcess({payload}){
    const roomTypeRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomType/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : roomTypeRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(roomTypeProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(roomTypeProcessSuccess(processStatus))
          yield put(roomTypeProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(roomTypeProcessSuccess(processStatus))
      yield put(roomTypeProcessReset())
    }
}

/* Watcher Saga */
export function* roomTypePreview() {
  yield takeLatest(ROOM_TYPE_PREVIEW, roomTypePreviewProcess)
}
export function* roomTypeSearch(){
  yield takeLatest(ROOM_TYPE_SEARCH, roomTypeSearchProcess)
}
export function* roomTypeCreate(){
  yield takeEvery(ROOM_TYPE_NEW,roomTypeCreateProcess)
}
export function* roomTypeUpdate(){
  yield takeEvery(ROOM_TYPE_UPDATE, roomTypeUpdateProcess)
}
export function* roomTypeRemove(){
  yield takeEvery(ROOM_TYPE_REMOVE,roomTypeRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(roomTypePreview),
             fork(roomTypeCreate),
             fork(roomTypeSearch),
             fork(roomTypeUpdate),
             fork(roomTypeRemove)])


}
