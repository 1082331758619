import {all} from "redux-saga/effects"
import authSagas from "./Auth"
import userSagas from "./setup/user"
import channelTypeSagas from "./setup/channel/ChannelType"
import channelGroupSagas from "./setup/channel/ChannelGroup"
import channelListSagas from "./setup/channel/ChannelList"
import salesPersonSagas from "./setup/channel/SalesPerson"
import originSagas from "./setup/market/Origin"
import marketZoneSagas from "./setup/market/MarketZone"
import countrySagas from "./setup/market/Country"
import languageSagas from "./setup/market/Language"
import nationalitySagas from "./setup/market/Nationality"
import folioPatternSagas from "./setup/folio/FolioPattern"
import folioPatternDetailSagas from "./setup/folio/FolioPatternDetail"
import roomGroupSagas from "./setup/roomInformation/RoomGroup"
import roomTypeSagas from "./setup/roomInformation/RoomType"
import bedTypeSagas from "./setup/roomInformation/BedType"
import departmentGroupsSagas from "./setup/department/DepartmentGroup"
import departmentSagas from "./setup/department/Department"
import mediaGroupSagas from "./setup/department/MediaGroup"
import mediaSagas from "./setup/department/Media"
import inclusionSagas from "./setup/miscellaneous/Inclusion"
import vip from "./setup/miscellaneous/Vip"
import inventorySaga from "./Inventory"
import reservationTypeSagas from "./setup/miscellaneous/ReservationType"
import ratePlanSagas from "./setup/rate/RatePlan"
import listBoxsSagas from "./Listboxs"
import facilitySagas from "./setup/roomInformation/Facility"
import buildingSagas from "./setup/roomInformation/Building"
import locationSagas from "./setup/roomInformation/Location"
import exposureSagas from "./setup/roomInformation/Exposure"
import roomInfoSagas from "./setup/roomInformation/RoomInfo"
import titleNameSagas from "./setup/miscellaneous/TitleName"
import occupationSagas from "./setup/miscellaneous/Occupation"
import outOfOrderSagas from "./setup/roomInformation/OutOfOrder"
import citySagas from "./setup/market/City"
import socialSagas from "./setup/miscellaneous/Social"
import messageToGuestSagas from "./MessageToGuest"
import followUpSagas from "./FollowUp"
import guestSagas from "./Guest"
import userProfileSagas from "./UserProfile"
import notificationsSagas from "./Notifications"
import systemConfigSagas from "./setup/systemSetup/systemConfig"
import reportSagas from './report'
import rateApproveSagas from "./nightAudit/rateApprove"
import endDaySagas from "./nightAudit/endDay"

import roomAvailSagas from './roomAvail'
import cashierGuestSagas from "./cashier/guest"
import cashierBookingSagas from "./cashier/booking"
import cashierCashSaleSagas from "./cashier/cashSale"
import cashierTransferSagas from "./cashier/transfer"
import cashierFolioHistorySagas from "./cashier/folioHistory"
import cashierPostingJournalSagas from "./cashier/posting"
import cashierChargeScheduleSagas from "./cashier/chargeSchedule"
import reservationsSaga from "./rsv/Reservation"
import expectedArrivaSaga from "./expected/ExpectedArrival"
import expectedDepartureSaga from "./expected/ExpectedDeparture"
import chargeReservationSaga from "./chargeSchedule/ChargeReservation"
import chargeInhouseSaga from "./chargeSchedule/ChargeInhouse"
import roomStatusSaga from "./houseKeeping/roomStatus"

import guestInhouseSagas from "./registration/GuestInhouse"
import currentLocationSaga from "./currentLocation"
import blockRoomSaga from "./rsv/BlockRoom"
import occupancyCalendarSaga from "./calendar/occupancyCalendar"
import itemizerSaga from "./setup/department/Itemizer"
import batchReportSaga from "./setup/report/BatchReport"
import userLogsSaga from "./userLogs"

import roomplanResouceSaga from "./roomPlan/roomplanResource"
import roomplanEventSaga from "./roomPlan/roomplanEvent"

import mealTypeSaga from "./setup/mealType"
import rateGroupSaga from "./setup/rate/RateGroup"
import autoPostRoomSaga from "./nightAudit/autoPostRoom"
import issueCardSaga from "./rsv/IssueCard"
import guestIssueCardSaga from "./registration/GuestIssueCard"
import issueLogSaga from "./issueLog"
import checkinMoreGuestSaga from "./rsv/CheckinMoreGuest"
import cashierFolioEditSaga from "./cashier/folioEdit"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    userSagas(),
    channelTypeSagas(),
    channelGroupSagas(),
    channelListSagas(),
    salesPersonSagas(),
    originSagas(),
    marketZoneSagas(),
    countrySagas(),
    languageSagas(),
    nationalitySagas(),
    folioPatternSagas(),
    roomGroupSagas(),
    roomTypeSagas(),
    bedTypeSagas(),
    departmentGroupsSagas(),
    departmentSagas(),
    mediaGroupSagas(),
    mediaSagas(),
    inclusionSagas(),
    inventorySaga(),
    vip(),
    reservationTypeSagas(),
    ratePlanSagas(),
    folioPatternDetailSagas(),
    listBoxsSagas(),
    reservationsSaga(),
    facilitySagas(),
    buildingSagas(),
    locationSagas(),
    exposureSagas(),
    roomInfoSagas(),
    titleNameSagas(),
    occupationSagas(),
    outOfOrderSagas(),
    citySagas(),
    socialSagas(),
    guestSagas(),
    messageToGuestSagas(),
    followUpSagas(),
    userProfileSagas(),
    notificationsSagas(),
    guestInhouseSagas(),
    systemConfigSagas(),
    reportSagas(),
    rateApproveSagas(),
    endDaySagas(),
    roomAvailSagas(),
    cashierGuestSagas(),
    cashierBookingSagas(),
    cashierCashSaleSagas(),
    cashierTransferSagas(),
    cashierFolioHistorySagas(),
    cashierPostingJournalSagas(),
    cashierChargeScheduleSagas(),
    expectedArrivaSaga(),
    expectedDepartureSaga(),
    chargeReservationSaga(),
    chargeInhouseSaga(),
    roomStatusSaga(),
    currentLocationSaga(),
    blockRoomSaga(),
    occupancyCalendarSaga(),
    itemizerSaga(),
    batchReportSaga(),
    userLogsSaga(),
    roomplanResouceSaga(),
    roomplanEventSaga(),
    mealTypeSaga(),
    rateGroupSaga(),
    autoPostRoomSaga(),
    issueCardSaga(),
    guestIssueCardSaga(),
    issueLogSaga(),
    checkinMoreGuestSaga(),
    cashierFolioEditSaga()
  ])
}
