import {
    CHANNEL_TYPE_PREVIEW,
    CHANNEL_TYPE_NEW,
    CHANNEL_TYPE_UPDATE,
    CHANNEL_TYPE_REMOVE,
    CHANNEL_TYPE_SEARCH,
    CHANNEL_TYPE_PROCESS_SUCCESS,
    CHANNEL_TYPE_DATASOURCE_SUCCESS,
    CHANNEL_TYPE_PROCESS_RESET
  } from "constants/ActionTypes"

  export const channelTypePreview = (value) => {
    return {
      type: CHANNEL_TYPE_PREVIEW,
      payload:value
    }
  }

  export const channelTypeCreate =(value) =>{
    return {
      type:CHANNEL_TYPE_NEW,
      payload:value
    }
  }

  export const channelTypeUpdate = (value) =>{
    return {
      type:CHANNEL_TYPE_UPDATE,
      payload: value
    }
  }

  export const channelTypeRemove = (value)=>{
    return {
      type:CHANNEL_TYPE_REMOVE,
      payload:value
    }
  }

  export const channelTypeSearch = (keyword) =>{
    return {
      type: CHANNEL_TYPE_SEARCH,
      payload:keyword
    }
  }

  export const channelTypeProcessSuccess = (value) =>{
    return{
      type:CHANNEL_TYPE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const channelTypeDataSourceUpdate = (value) => {
    return {
      type: CHANNEL_TYPE_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const channelTypeProcessReset = ()=>{
    return{
      type:CHANNEL_TYPE_PROCESS_RESET
    }
  }

