export const FOLIO_EDIT_REQUEST = "FOLIO_EDIT_REQUEST"
export const FOLIO_EDIT_UPDATE = "FOLIO_EDIT_UPDATE"
export const FOLIO_EDIT_PROCESS_SUCCESS = "FOLIO_EDIT_PROCESS_SUCCESS"
export const FOLIO_EDIT_DATASOURCE_SUCCESS = "FOLIO_EDIT_DATASOURCE_SUCCESS"
export const FOLIO_EDIT_PROCESS_RESET = "FOLIO_EDIT_PROCESS_RESET"

export type FolioEditRequest = {
    type: typeof FOLIO_EDIT_REQUEST,
    payload: number | string
}

export type FolioEditUpdate = {
    type: typeof FOLIO_EDIT_UPDATE,
    payload: any
}

export type FolioEditProcessSuccess = {
    type: typeof FOLIO_EDIT_PROCESS_SUCCESS,
    payload: {}
}
export type FolioEditDataSourceSuccess = {
    type: typeof FOLIO_EDIT_DATASOURCE_SUCCESS,
    payload: any
}
export type FolioEditProcessReset = {
    type: typeof FOLIO_EDIT_PROCESS_RESET
}

export type FolioEditAction =
    | FolioEditRequest
    | FolioEditUpdate
    | FolioEditProcessSuccess
    | FolioEditDataSourceSuccess
    | FolioEditProcessReset
