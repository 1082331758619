import { fork, takeEvery, ForkEffect } from "redux-saga/effects"

import {
    REPORT_MENU_GET_REQUEST
} from 'constants/ActionTypes'
import { REPORT_OPTIONS_GET_REQUEST } from 'appRedux/actions/report/report-actionTypes'
import { reportMenuWorker, reportOptionsWorker } from './report-workers'

/* Watcher Saga */
function* WatchReports() {
    yield takeEvery(REPORT_OPTIONS_GET_REQUEST, reportOptionsWorker)
    yield takeEvery(REPORT_MENU_GET_REQUEST, reportMenuWorker)
}

export const reportWatchers: ForkEffect[] = [
    fork(WatchReports)
]