import * as actions from "./cashSaleActionType"

export const cashierCashSalePreview = (payload?: any,sorter?: any): actions.CashierCashSaleFolioRequest => {
  return {
    type: actions.CASHIER_CASHSALE_FOLIO_REQUEST,
    payload,
    sorter
  }
}

export const cashierCashSaleProcessSuccess = (payload:any):actions.CashierCashSaleFolioProcessSuccess =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_PROCESS_SUCCESS,
    payload
  }
}

export const cashierCashSaleDataSourceSuccess = (payload:any):actions.CashierCashSaleFolioDataSourceSuccess =>{
  return {
    type: actions.CASHIER_CASHSALE_FOLIO_DATASOURCE_SUCCESS,
    payload
  }
}

export const cashierCashSaleDetailPreview = (payload:number):actions.CashierCashSaleFolioDetailRequest=>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_DETAIL_REQUEST,
    payload
  }
}

export const cashierCashSaleDetailDataSourceSuccess = (payload:any):actions.CashierCashSaleFolioDetailDataSourceSuccess=>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}

export const cashierCashSaleSetFilter = (payload:any): actions.CashierCashSaleSetFilter =>{
  return {
    type: actions.CASHIER_CASHSALE_FOLIO_SET_FILTER,
    payload
  }
}

export const cashierCashSaleFolioPost = (value:any):actions.CashierCashSaleFolioPost =>{
  return{
    type:actions.CASHIER_CASHSALE_FOLIO_POST,
    payload:value
  }
}

export const cashierCashSaleFolioVoid = (value:any):actions.CashierCashSaleFolioVoid =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_VOID,
    payload:value
  }
}

export const cashierCashSaleFolioUpdate = (value:any):actions.CashierCashSaleFolioUpdate =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_UPDATE,
    payload:value
  }
}

export const cashierCashSaleFolioPayment = (value:any):actions.CashierCashSaleFolioPayment =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_PAYMENT,
    payload:value
  }
}
export const cashierCashSaleFolioSplit = (value:any):actions.CashierCashSaleFolioSplit =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_SPLIT,
    payload:value
  }
}
export const cashierCashSaleFolioMove = (value:any):actions.CashierCashSaleFolioMove =>{
  return{
    type: actions.CASHIER_CASHSALE_FOLIO_MOVE,
    payload:value
  }
}

export const cashierCashSaleCloseFolioSeq = (value:any):actions.CashierCashSaleCloseFolioSeq => {
  return {
    type: actions.CASHIER_CASHSALE_CLOSE_FOLIO_SEQ,
    payload:value
  }
}

export const cashierCashSaleToggleLock = (value:any):actions.CashierCashSaleToggleLock => {
  return {
    type: actions.CASHIER_CASHSALE_FOLIO_TOGGLE_LOCK,
    payload:value
  }
}

export const cashierCashSaleToggleSuccess = (value:any):actions.CashierCashSaleToggleSuccess => {
  return {
    type: actions.CASHIER_CASHSALE_FOLIO_TOGGLE_SUCCESS,
    payload:value
  }
}

export const cashierCashSaleInfoUpdate = (value:any):actions.CashierCashSaleInfoUpdate => {
  return {
    type: actions.CASHIER_CASHSALE_INFO_UPDATE,
    payload:value
  }
}

export const cashierCashSaleInfoUpdateSuccess = (value:any):actions.CashierCashSaleInfoUpdateSuccess => {
  return {
    type: actions.CASHIER_CASHSALE_INFO_UPDATE_SUCCESS,
    payload:value
  }
}

export const cashierCashSaleChangeOnlineShift = (value:any):actions.CashierCashSaleChangeOnlineShift => {
  return {
    type: actions.CASHIER_CASHSALE_CHANGE_ONLINE_SHIFT,
    payload:value
  }
}
export const cashierCashSaleOpenFolio = (value:any):actions.CashierCashSaleOpenFolio => {
  return {
    type: actions.CASHIER_CASHSALE_OPEN_FOLIO,
    payload:value
  }
}
export const cashierCashSaleCloseFolio = (value:any):actions.CashierCashSaleCloseFolio => {
  return {
    type: actions.CASHIER_CASHSALE_CLOSE_FOLIO,
    payload:value
  }
}
export const cashierCashSaleReopenFolio = (value:number): actions.CashierCashSaleReopenFolio =>{
  return{
    type:actions.CASHIER_CASHSALE_REOPEN_FOLIO,
    payload:value
  }
}
export const cashierCashSaleProcessReset=() =>{
  return{
    type:actions.CASHIER_CASHSALE_FOLIO_PROCESS_RESET
  } 
}
