import * as actions from "./BedTypeActionType"

  export const bedTypePreview = (value?:any):actions.BedTypeRequest => {
    return {
      type: actions.BED_TYPE_PREVIEW,
      payload:value
    }
  }

  export const bedTypeCreate =(value:any):actions.BedTypeNew =>{
    return {
      type:actions.BED_TYPE_NEW,
      payload:value
    }
  }

  export const bedTypeUpdate = (value:any):actions.BedTypeUpdate =>{
    return {
      type:actions.BED_TYPE_UPDATE,
      payload: value
    }
  }

  export const bedTypeRemove = (value:any):actions.BedTypeRemove =>{
    return {
      type:actions.BED_TYPE_REMOVE,
      payload:value
    }
  }

  export const bedTypeSearch = (keyword:any):actions.BedTypeSearch =>{
    return {
      type: actions.BED_TYPE_SEARCH,
      payload:keyword
    }
  }

  export const bedTypeProcessSuccess = (value:any):actions.BedTypeProcessSuccess =>{
    return{
      type: actions.BED_TYPE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const bedTypeDataSourceUpdate = (value:any):actions.BedTypeDataSourceSuccess => {
    return {
      type: actions.BED_TYPE_DATASOURCE_SUCCESS,
      payload: value
    }
  }
    export const bedTypeSwitch = (payload:any):actions.BedTypeSwitch=>{
      return{
        type:actions.BED_TYPE_SWITCH,
        payload
      }
    }
  export const bedTypeProcessReset = () =>{
    return{
      type: actions.BED_TYPE_PROCESS_RESET
    }
  }

