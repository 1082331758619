import * as actions from "./ReservationActionType"

export const reservationPreview = (payload?: any,sorter?:any): actions.ReservationRequest => {
  return {
    type: actions.RESERVATION_REQUEST,
    payload,
    sorter
  }
}

export const reservationNew = (payload: any): actions.ReservationNew => {
  return {
    type: actions.RESERVATION_NEW,
    payload
  }
}

export const reservationUpdate = (payload: any): actions.ReservationUpdate => {
  return {
    type: actions.RESERVATION_UPDATE,
    payload
  }
}

export const reservationRemove = (payload: any): actions.ReservationRemove => {
  return {
    type: actions.RESERVATION_REMOVE,
    payload
  }
}

export const reservationProcessSuccess = (payload:any):actions.ReservationProcessSuccess =>{
  return{
    type: actions.RESERVATION_PROCESS_SUCCESS,
    payload
  }
}

export const reservationDataSourceSuccess = (payload:any):actions.ReservationDataSourceSuccess => {
  return {
    type: actions.RESERVATION_DATASOURCE_SUCCESS,
    payload
  }
}
export const reservationSearch = (payload:number):actions.ReservationSearch=>{
  return{
    type: actions.RESERVATION_SEARCH,
    payload
  }
}

export const reservationDetailPreview = (payload:number):actions.ReservationDetailRequest=>{
  return{
    type: actions.RESERVATION_DETAIL_REQUEST,
    payload
  }
}

export const reservationDetailDataSourceSuccess = (payload:any):actions.ReservationDetailDataSourceSuccess=>{
  return{
    type: actions.RESERVATION_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}

export const reservationRateplanPreview = (payload:any):actions.ReservationRatePlanRequest=>{
  return{
    type: actions.RESERVATION_RATEPLAN_REQUEST,
    payload
  }
}

export const reservationRatePlanDataSouceSuccess = (payload:any):actions.ReservationRatePlanDataSourceSuccess=>{
  return{
    type: actions.RESERVATION_RATEPLAN_DATASOUCE_SUCCESS,
    payload
  }
}

export const reservationSetFilter = (payload:any):actions.ReservationSetFilter => {
  return {
    type: actions.RESERVATION_SET_FILTER,
    payload
  }
}

export const reservationChangeBookingStatus = (payload:any):actions.ReservationChangeBookingStatus=>{
  return{
    type: actions.RESERVATION_CHANGE_BOOKING_STATUS,
    payload
  }
}

export const reservationBookingSummaryPreview = (payload:any):actions.ReservationBookingSummaryRequest=>{
  return{
    type: actions.RESERVATION_BOOKING_SUMMARY_REQUEST,
    payload
  }
}

export const reservationBookingSummarySuccess = (payload:any):actions.ReservationBookingSummarySuccess=>{
  return{
    type: actions.RESERVATION_BOOKING_SUMMARY_SUCCESS,
    payload
  }
}

export const reservationProcessReset = () =>{
  return{
    type:actions.RESERVATION_PROCESS_RESET
  } 
}

export const reservationReset = () =>{
  return{
    type:actions.RESERVATION_RESET
  } 
}
