import {all, fork, put,takeLatest, call,select }  from "redux-saga/effects"

import {
    ROOM_AVAIL_PREVIEW,
    ROOM_AVAIL_DETAIL_PREVIEW
} from "constants/ActionTypes"

import {roomAvailDataSourceUpdate,
        roomAvailDetailDataSourceUpdate,
        roomAvailProcessSuccess} from "appRedux/actions/roomAvail"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import moment from "moment"

function* roomAvailPreviewProcess({payload}){
    const {systemConfig:{dataSource:{SYSTEM:{SYSTEM_DATE}}}} = yield select(state => state)

    /* Default Date */
    let startDate,endDate =""
    startDate = moment(SYSTEM_DATE).format("YYYY-MM-DD")
    endDate = moment(SYSTEM_DATE).add(14,"days").format("YYYY-MM-DD")
    
    if(payload){
      const {dateRange} = payload
      if(dateRange){
        startDate = moment(dateRange[0]._d).format("YYYY-MM-DD")
        endDate = moment(dateRange[1]._d).format("YYYY-MM-DD")
      }
    }
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/inventory/roomAvail/?start_date=${startDate}&end_date=${endDate}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const roomAvailDataSource = response.data
                
                /* if no error put everything to reducer */
                yield put(roomAvailDataSourceUpdate({dataSource:roomAvailDataSource,dateRange:[startDate,endDate]}))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(roomAvailProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on roomavail preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(roomAvailProcessSuccess(processStatus))
    }
    
}

function* roomAvailDetailPreviewProcess({payload}){
    //console.log("View detail ",payload)
    const {section, status, date, room_id} = payload
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/inventory/roomAvail/${section}/${status}?adate=${date}${room_id?`&roomtype_id=${room_id}`:''}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const roomAvailDetail = response.data
                //console.log("roomavail detail ",roomAvailDetail)
                /* if no error put everything to reducer */
                yield put(roomAvailDetailDataSourceUpdate(roomAvailDetail))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "detail", message}
                yield put(roomAvailProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room avail detail view : ",error)
        const processStatus = { status: false, action: "detail", message:error}
        yield put(roomAvailProcessSuccess(processStatus))
    }
}

/* Watcher Saga */
export function* roomAvaliPreview() {
  yield takeLatest(ROOM_AVAIL_PREVIEW, roomAvailPreviewProcess)
}
export function* roomAvailDetailPreview(){
  yield takeLatest(ROOM_AVAIL_DETAIL_PREVIEW,roomAvailDetailPreviewProcess)
}

export default function* rootSaga() {
  yield all([fork(roomAvaliPreview),
             fork(roomAvailDetailPreview)])


}
