import React from "react"
import { Row,Col,Card,Avatar,Button} from 'antd'
import { Formik } from "formik"
import { Form, Input, SubmitButton } from "formik-antd"
import IntlMessages from "util/IntlMessages"
const FollowUpCommentForm = (props) => {

    const { validationSchemaComment,
            initialValueCommment,
            handleCommentSubmit,
            handleCloseForm,
            showCommentCloseButton,loader,userProfileImages} = props  
    const formItemLayout = {
      wrapperCol: { sm:{span:24}, md:{span: 24},lg:{span:24}},
      colon:false
    }

    return (
      <>
        <Formik
          enableReinitialize
          isSubmitting={true}
          initialValues={initialValueCommment}
          onSubmit={handleCommentSubmit}
          validationSchema={validationSchemaComment}
        >
          <Form {...formItemLayout}>
            <Card style={{ padding: 10, margin: 10 }}>
              <Row>
                <Col span={2}>
                  <Avatar
                    shape="square"
                    size={32}
                    src={userProfileImages}
                  />
                </Col>
                <Col span={22}>
                  <Form.Item name="follow_comment">
                    <Input.TextArea
                      name="follow_comment"
                      placeholder="Message"
                      rows={4}
                    />
                  </Form.Item>
                  <SubmitButton
                    style={{ float: "right" }}
                    type="primary"
                    htmlType="submit"
                    loading={loader}
                  >
                    <IntlMessages id="app.global.bt.submit" />
                  </SubmitButton>
                  {showCommentCloseButton ? 
                  <Button
                    style={{ float: "right", marginRight:10 }}
                    type="default"
                    htmlType="button"
                    onClick={() => handleCloseForm()}
                  >
                      <IntlMessages id="app.global.bt.back" />
                  </Button>:""
                  }
                </Col>
              </Row>
            </Card>
          </Form>
        </Formik>
      </>
    );
}

export default FollowUpCommentForm