import { AppAction, InitState } from 'constants/ActionTypes'
import { 
    ReportOptionsState,
    REPORT_OPTIONS_GET_REQUEST,
    REPORT_OPTIONS_GET_SUCCESS,
    REPORT_OPTIONS_GET_FAIL
} from 'appRedux/actions/report/report-actionTypes'

const initDataSource: ReportOptionsState = {
    parameters: []
}

const initValues: InitState = {
    caseType: 'INITIATE',
    alertShow: false,
    alertText: '',
    loading: true,
    dataSource: initDataSource
}

const getSuccess = (state: InitState, action: AppAction<string, ReportOptionsState>): InitState => {
    return {
        ...state, // Merge previous state
        caseType: 'SUCCESS',
        alertShow: false,
        alertText: '',
        loading: false,
        dataSource: action.payload
    }
}

export default (state: InitState = initValues, action: AppAction): InitState<ReportOptionsState> => {
    switch (action.type) {
        case REPORT_OPTIONS_GET_REQUEST: 
            return {
                ...state, // Merge previous state
                loading: true,
                alertShow: false,
                dataSource: initDataSource
            }
        case REPORT_OPTIONS_GET_SUCCESS:
            return getSuccess(state, action)
        case REPORT_OPTIONS_GET_FAIL:
            return {
                ...state,
                caseType: 'FAILED',
                loading: false,
                alertShow: true,
                alertText: action.payload                
            }
        default:
            return {
                ...state,
                caseType: 'DEFAULT'
            }
    }
}