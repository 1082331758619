export interface roomDetailType {
    roomtype_id: number,
    roomtype_name: string,
    description?: string|null,
    rate_id: number,
    rate_amount?: number|null,
    rate_extrabed?: number|null,
    rate_child?: number|null,
    rate_infant?: number|null,
    include_adult?: number|null,
    include_child?: number|null,
    include_infant?: number|null,
    extra_adult?: number|null,
    extra_child?: number|null,
    extra_infant?: number|null,
    max_adult?: number|null,
    max_child?: number|null,
    max_infant?: number|null,
    max_pax?: number|null,
    default_rate_amount?: number|null,
    default_rate_extrabed?: number|null,
    default_rate_child?: number|null,
    default_rate_infant?: number|null,
    default_include_adult?: number|null,
    default_include_child?: null,
    default_include_infant?: null,
    active: boolean
}

export interface rateplanDetailType{
    id?: number,
    code?: string|null,
    name?: string|null,
    rate_type?:string|null,
    plan_type_id?: number,
    plan_type_name?: string,
    book_start_date?: string|null,
    book_end_date?: string|null,
    stay_start_date?: string|null,
    stay_end_date?: string|null,
    description?: string|null,
    link_plan_id?: number|null,
    link_adjust_type?: number|null,
    link_adjust_op?: string|null,
    link_adjust_amt?: number|null,
    link_adjust_percent?: number|null,
    origin_id?: number|null,
    origin_name?: string|null,
    market_id?: number|null,
    market_name?: string|null,
    mealtype_id?:number|null,
    mealtype_name?:string|null,
    room_charge_dept?: number|null,
    min_stay?: number|null,
    max_stay?: number|null,
    min_adv_book?: number|null,
    max_adv_book?: number|null,
    min_rate?:number|null,
    rate_extrabed?: number|null,
    rate_child?: number|null,
    rate_infant?: number|null,
    rategroup_id?:number|null,
    rategroup_name?:string|null,
    comp: boolean,
    comp_abf: boolean,
    days: {
        sun: boolean,
        mon: boolean,
        tue: boolean,
        wed: boolean,
        thu: boolean,
        fri: boolean,
        sat: boolean
    },
    in_use_flag: boolean,
    flag_effect_child:false,
    channel_count: number,
    children_count: number,
    no_amend: boolean,
    no_cancel: boolean,
    no_refund: boolean,
    enabled: boolean,
    order_seq?: number|null,
    inclusions: Array<number>,
    details: Array<roomDetailType>
}

export interface rateplanState {    
    loader: boolean,
    dataSerialize:rateplanDetailType,
    dataSource:[],
    channelTypeFilter:[],
    folioPatternFilter:[],
    process:any,
    ratePlanRoomDataSource:[],
    keyword?: any
}

export const rateplanDetailDefaultState:rateplanDetailType = {
    id: -1,
    code: undefined,
    name: undefined,
    rate_type:"D",
    plan_type_id: 1,
    plan_type_name: undefined,
    book_start_date: undefined,
    book_end_date: undefined,
    stay_start_date: undefined,
    stay_end_date: undefined,
    description: undefined,
    link_plan_id: undefined,
    link_adjust_type: undefined,
    link_adjust_op: undefined,
    link_adjust_amt: undefined,
    link_adjust_percent: undefined,
    origin_id: undefined,
    origin_name: undefined,
    market_id: undefined,
    market_name: undefined,
    mealtype_id:undefined,
    room_charge_dept: undefined,
    min_stay: undefined,
    max_stay: undefined,
    min_adv_book: undefined,
    max_adv_book: undefined,
    min_rate:undefined,
    rate_extrabed: undefined,
    rate_child: undefined,
    rate_infant: undefined,
    rategroup_id:undefined,
    comp: false,
    comp_abf: false,
    days: {
        sun: false,
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false
    },
    in_use_flag: false,
    flag_effect_child:false,
    channel_count: 0,
    children_count: 0,
    no_amend: false,
    no_cancel: false,
    no_refund: false,
    enabled: true,
    order_seq: undefined,
    inclusions: [],
    details: []
}