import * as actions from "appRedux/actions/setup/rate/RatePlanActionType"
import {rateplanDetailDefaultState, rateplanState} from "./RatePlanType"

const initialState: rateplanState = {
	loader: false,
	dataSerialize:rateplanDetailDefaultState,
	dataSource:[],
	channelTypeFilter:[],
	folioPatternFilter:[],
	process:{status:false,action:"",message:""},
	ratePlanRoomDataSource:[],
	keyword:''
}

export default (state: rateplanState = initialState,action: actions.RatePlanAction): rateplanState =>{
    switch (action.type) {
    case actions.RATEPLAN_PREVIEW:{
        return {
            ...state,
			loader: false,
			dataSerialize:rateplanDetailDefaultState,
			ratePlanRoomDataSource:[],
			process:{status:false,action:"",message:""}
        }
    }
    case actions.RATEPLAN_NEW: {
		return {
			...state,
			loader: true,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEPLAN_UPDATE:{
		return{
			...state,
			loader:true,
			process:{status:false,action:"",message:""},
			dataSerialize:action.payload
		}
	}
	case actions.RATEPLAN_REMOVE: {
		return {
			...state,
			loader: false,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEPLAN_SEARCH:{
		return {
			...state,
			loader:false,
			keyword:action.payload
		}
	}
	case actions.RATEPLAN_PROCESS_SUCCESS:{
		return{
		...state,
		loader:false,
		process:action.payload
		}
	}
	case actions.RATEPLAN_DATASOURCE_SUCCESS:{
		return {
			...state,
			loader: false,
			dataSource: action.payload
		}
	}
	case actions.RATEPLAN_DETAIL:{
		return{
		...state,
		dataSerialize:rateplanDetailDefaultState
		}
	}
	case actions.RATEPLAN_DETAIL_SUCCESS:{
		return{
		...state,
		dataSerialize:action.payload
		}
	}
	case actions.RATEPLAN_CHANNEL_MAPPING:{
		return {
		...state,
		loader:true,
		process:{status:false,action:"",message:""}
		}
	}

	case actions.RATEPLAN_ROOM_NEW:{
		return{
		...state,
		loader:true,
		process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEPLAN_ROOM_UPDATE:{
		return{
		...state,
		loader:true,
		process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEPLAN_ROOM_REMOVE:{
		return{
		...state,
		loader:false,
		process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEPLAN_ROOM_PROCESS_SUCCESS:{
		return{
		...state,
		loader:false,
		process:action.payload
		}
	}
	case actions.RATEPLAN_GET_ROOM_DETAIL:{
		return{
		...state,
		ratePlanRoomDataSource:[]
		}
	}
	case actions.RATEPLAN_GET_ROOM_DETAIL_SUCCESS:{
		return {
		...state,
		ratePlanRoomDataSource:action.payload
		}
	}
	case actions.RATEPLAN_PROCESS_RESET:{
		return{
		...state,
		process:{status:false,action:"",message:""}
		}
	}
	default:
		return state
    }
}