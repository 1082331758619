import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/userLogs/UserLogsActionType"
import * as actionCreators from "appRedux/actions/userLogs"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { APIGetRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { userLogsDataSourceType } from "appRedux/reducers/userLogs/userLogType"
import moment from "moment"
 
function* userLogsRequestProcess({payload}:actionTypes.UserLogsRequest) {

    yield put(actionCreators.userLogsSetFilter({...payload}))

    let keywords = payload
    const userLogsInfo = {} as any
    if(keywords.searchText)
        userLogsInfo.search_text = keywords.searchText
    if(keywords.startDate)
        userLogsInfo.start_date = moment(keywords.startDate).format('YYYY-MM-DD')
    if(keywords.endDate)
        userLogsInfo.end_date = moment(keywords.endDate).format('YYYY-MM-DD')
    if(keywords.category)
        userLogsInfo.category= keywords.category
    if(keywords.user_name)
        userLogsInfo.user_name = keywords.user_name
    if(keywords.log_type)
        userLogsInfo.log_type = keywords.log_type
    if(keywords.log_shift)
        userLogsInfo.log_shift= keywords.log_shift

    userLogsInfo.page_no = payload.pagination.current
    userLogsInfo.row_per_page = payload.pagination.pageSize
    if(payload.sorter?.column)
        userLogsInfo.order_by = `${payload.sorter.field} ${payload.sorter.order==="ascend" ? "asc":"desc"}`

    /* Create query string from array object */
    let queryString = Object.keys(userLogsInfo).map(key =>`${key}=${userLogsInfo[key]}`).join('&')
    const url = `${API_URL_V1}pms/utility/userLogs?${queryString}`

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    //const url = `${API_URL_V1}pms/utility/userLogs?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const userLogsInfo:userLogsDataSourceType[] = response.data.result
                /* if no error put everything to reducer */
                yield put(actionCreators.userLogsDataSourceSuccess(userLogsInfo))
                yield put(actionCreators.userLogsSetFilter({...payload,pagination:{...payload.pagination,total:response.data.total_rows}}))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "userLogsPreview", message}
                yield put(actionCreators.userLogsProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                    if(data)
                        yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on batch report preview : ",error)
        const processStatus = { status: false, action: "userLogsPreview", message:error}
        yield put(actionCreators.userLogsProcessSuccess(processStatus))
    }
}

function* batchReportRequest() {
    yield takeEvery(actionTypes.USER_LOGS_REQUEST, userLogsRequestProcess)
}

export default function* rootSaga() {
    yield all([
        fork(batchReportRequest)
    ])
}


