import {
  ORIGIN_PREVIEW,
  ORIGIN_NEW,
  ORIGIN_UPDATE,
  ORIGIN_REMOVE,
  ORIGIN_PROCESS_SUCCESS,
  ORIGIN_DATASOURCE_SUCCESS,
  ORIGIN_SEARCH,
  ORIGIN_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ORIGIN_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case ORIGIN_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case ORIGIN_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case ORIGIN_REMOVE: {
      return {
          ...state,
          loader: false
      }
    }
    case ORIGIN_SEARCH:{
      return {
          ...state,
          loader:false,
          keyword:action.payload
      }
    }
    case ORIGIN_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case ORIGIN_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case ORIGIN_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}