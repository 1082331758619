import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  ROOM_GROUP_PREVIEW,
  ROOM_GROUP_NEW,
  ROOM_GROUP_UPDATE,
  ROOM_GROUP_REMOVE,
  ROOM_GROUP_SEARCH
} from "constants/ActionTypes"

import {roomGroupDataSourceUpdate,roomGroupProcessSuccess,roomGroupProcessReset} from "appRedux/actions/setup/roomInformation/RoomGroup"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* roomGroupPreviewProcess({payload}){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomGroup/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {            
            const roomGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.room_group_id = obj.id
                    container.room_group_name = obj.name
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(roomGroupDataSourceUpdate(roomGroupDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreRoomGroupList",roomGroupDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(roomGroupProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on room group preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(roomGroupProcessSuccess(processStatus))
    }
    
}

function* roomGroupSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRoomGroupList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_room_name = filter(ReformalData, function(o) {
        return o.room_group_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_room_name])]

      yield put(roomGroupDataSourceUpdate(mergeData))
  }else 
      yield put(roomGroupDataSourceUpdate(ReformalData))
}

function* roomGroupCreateProcess({payload}){

  const room_group_name = payload.room_group_name
  const roomActive = payload.active

  let roomGroupInfo = {name:room_group_name,
                       enabled:roomActive}

      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomGroup/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = roomGroupInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(roomGroupProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(roomGroupProcessSuccess(processStatus))
              yield put(roomGroupProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(roomGroupProcessSuccess(processStatus))
            yield put(roomGroupProcessReset())
      }
}

function* roomGroupUpdateProcess({payload}){
  const room_group_name = payload.room_group_name
  const room_group_id = payload.room_group_id
  const roomActive = payload.active
 
  let roomGroupInfo = {}
      roomGroupInfo.id = room_group_id
      roomGroupInfo.name = room_group_name
      roomGroupInfo.enabled = roomActive

  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomGroup/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = roomGroupInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
          const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(roomGroupProcessSuccess(processStatus))
            yield put(roomGroupProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(roomGroupProcessSuccess(processStatus))
          yield put(roomGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(roomGroupProcessSuccess(processStatus))
        yield put(roomGroupProcessReset())
  }
}

function* roomGroupRemoveProcess({payload}){
    const roomGroupRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomGroup/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : roomGroupRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(roomGroupProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(roomGroupProcessSuccess(processStatus))
          yield put(roomGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(roomGroupProcessSuccess(processStatus))
      yield put(roomGroupProcessReset())
    }
}

/* Watcher Saga */
export function* roomGroupPreview() {
  yield takeLatest(ROOM_GROUP_PREVIEW, roomGroupPreviewProcess)
}
export function* roomGroupSearch(){
  yield takeLatest(ROOM_GROUP_SEARCH, roomGroupSearchProcess)
}
export function* roomGroupCreate(){
  yield takeEvery(ROOM_GROUP_NEW,roomGroupCreateProcess)
}
export function* roomGroupUpdate(){
  yield takeEvery(ROOM_GROUP_UPDATE, roomGroupUpdateProcess)
}
export function* roomGroupRemove(){
  yield takeEvery(ROOM_GROUP_REMOVE,roomGroupRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(roomGroupPreview),
             fork(roomGroupSearch),
             fork(roomGroupCreate),
             fork(roomGroupUpdate),
             fork(roomGroupRemove)
            ])


}
