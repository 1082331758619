
export const RouteNavigator = [
  {
    title: "Dashboard",
    method: "dashboard",
    subMenu: "",
    pathName: "/main/dashboard",
    linkTo: "/main/dashboard"
  },
  {
    title: "Inventory",
    method: "rate_alloment",
    subMenu: "",
    pathName: "/main/inventory",
    linkTo: "/main/inventory"
  },
  {
    title: "Blockroom",
    method: "blockroom",
    subMenu: "",
    pathName: "/main/blockroom",
    linkTo: "#"
  },
  {
    title: "Reservations",
    method: "reservations",
    subMenu: "",
    pathName: "/main/reservations",
    linkTo: "/main/reservations"
  },
  {
    title: "Individual Form",
    method: "reservations",
    subMenu: "",
    pathName: "/main/reservations/individual",
    linkTo: "#"
  },
    {
    title: "Expected Arrival",
    method: "expectedArrival",
    subMenu: "",
    pathName: "/main/expected_arrival",
    linkTo: "#"
  },
  {
    title: "Expected Departure",
    method: "expectedDeparture",
    subMenu: "",
    pathName: "/main/expected_departure",
    linkTo: "#"
  },
  {
    title: "Guest Inhouse",
    method: "guestIndividual",
    subMenu: "sub1",
    pathName: "/main/guestinhouse",
    linkTo: "/main/guestinhouse"
    
  },
  {
    title: "CheckIn",
    method: "checkInDetail",
    subMenu: "sub1",
    pathName: "/main/guestinhouse/CheckIn",
    linkTo: "#"
  },
  {
    title: "Guest In House Form",
    method: "guestIndividual",
    subMenu: "sub1",
    pathName: "/main/guestinhouse/detail",
    linkTo: "#"
  },
  {
    title: "Room Status",
    method: "registration",
    subMenu: "",
    pathName: "/main/registration/roomstatus",
    linkTo: "/main/registration/roomstatus"
  },
  {
    title: "System Settings",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup",
    linkTo: "/main/setup"
  },
  {
    title: "System Interface",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup/systemsetup/systeminterface",
    linkTo: "/main/setup/systemsetup/systeminterface"
  },
  {
    title: "System Parameter",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup/systemsetup/systemparameter",
    linkTo: "/main/setup/systemsetup/systemparameter"
  },
  {
    title: "System Profile",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup/systemsetup/systemprofile",
    linkTo: "/main/setup/systemsetup/systemprofile"
  },
  {
    title: "Batch Report",
    method:"systemSetup",
    subMenu:"sub7",
    pathName:"/main/setup/systemsetup/reports",
    linkTo:"/main/setup/systemsetup/reports"
  },
  {
    title: "User groups",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup/systemsetup/usergroups",
    linkTo: "/main/setup/systemsetup/usergroups"
  },
  {
    title: "User",
    method: "systemSetup",
    subMenu: "sub7",
    pathName: "/main/setup/systemsetup/user",
    linkTo: "/main/setup/systemsetup/user"
  },
  {
    title: "Room Info",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/roominfo",
    linkTo: "/main/setup/roominformation/roominfo"
  },
  {
    title: "Bed Type",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/bedtype",
    linkTo: "/main/setup/roominformation/bedtype"
  },
  {
    title: "Building",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/building",
    linkTo: "/main/setup/roominformation/building"
  },
  {
    title: "Exposure",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/exposure",
    linkTo: "/main/setup/roominformation/exposure"
  },
  {
    title: "Facility",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/facility",
    linkTo: "/main/setup/roominformation/facility"
  },
  {
    title: "Floor Plan",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/floorplan",
    linkTo: "/main/setup/roominformation/floorplan"
  },
  {
    title: "Location",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/location",
    linkTo: "/main/setup/roominformation/location"
  },
  {
    title: "Room Group",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/roomgroup",
    linkTo: "/main/setup/roominformation/roomgroup"
  },
  {
    title: "Room Status",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/roomstatus",
    linkTo: "/main/setup/roominformation/roomstatus"
  },
  {
    title: "Room Type",
    method: "roominformation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/roomtype",
    linkTo: "/main/setup/roominformation/roomtype"
  },
  {
    title: "Folio Format",
    method: "folioSetup",
    subMenu: "sub7",
    pathName: "/main/setup/foliosetup/format",
    linkTo: "/main/setup/foliosetup/format"
  },
  {
    title: "Folio Pattern",
    method: "folioSetup",
    subMenu: "sub7",
    pathName: "/main/setup/foliosetup/pattern",
    linkTo: "/main/setup/foliosetup/pattern"
  },
  {
    title: "Folio Printing",
    method: "folioSetup",
    subMenu: "sub7",
    pathName: "/main/setup/foliosetup/printing",
    linkTo: "/main/setup/foliosetup/printing"
  },
  {
    title: "Channel Type",
    method: "channel",
    subMenu: "sub7",
    pathName: "/main/setup/channel/type",
    linkTo: "/main/setup/channel/type"
  },
  {
    title: "Channel Group",
    method: "channel",
    subMenu: "sub7",
    pathName: "/main/setup/channel/group",
    linkTo: "/main/setup/channel/group"
  },
  {
    title: "Channel",
    method: "channel",
    subMenu: "sub7",
    pathName: "/main/setup/channel/list",
    linkTo: "/main/setup/channel/list"
  },
  {
    title: "Sales Person",
    method: "channel",
    subMenu: "sub7",
    pathName: "/main/setup/channel/sales",
    linkTo: "/main/setup/channel/sales"
  },
  {
    title: "Rate Plan",
    method: "rate",
    subMenu: "sub7",
    pathName: "/main/setup/rate/rateplan",
    linkTo: "/main/setup/rate/rateplan"
  },
  {
    title: "Code",
    method: "rate",
    subMenu: "sub7",
    pathName: "/main/setup/rate/code",
    linkTo: "/main/setup/rate/code"
  },
  {
    title: "Condition",
    method: "rate",
    subMenu: "sub7",
    pathName: "/main/setup/rate/condition",
    linkTo: "/main/setup/rate/condition"
  },
  {
    title: "Template",
    method: "rate",
    subMenu: "sub7",
    pathName: "/main/setup/rate/template",
    linkTo: "/main/setup/rate/template"
  },
  {
    title: "Department",
    method: "department",
    subMenu: "sub7",
    pathName: "/main/setup/department/setup",
    linkTo: "/main/setup/department/setup"
  },
  {
    title: "Department Group",
    method: "department",
    subMenu: "sub7",
    pathName: "/main/setup/department/group",
    linkTo:  "/main/setup/department/group"
  },
  {
    title: "Media",
    method: "department",
    subMenu: "sub7",
    pathName: "/main/setup/department/media",
    linkTo: "/main/setup/department/media"
  },
  {
    title: "Media Group",
    method: "department",
    subMenu: "sub7",
    pathName: "/main/setup/department/mediagroup",
    linkTo: "/main/setup/department/mediagroup"
  },
  {
    title: "Itemizer",
    method: "department",
    subMenu: "sub7",
    pathName: "/main/setup/department/itemizer",
    linkTo: "/main/setup/department/itemizer"
  },
  {
    title: "Country",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/country",
    linkTo: "/main/setup/market/country"
  },
  {
    title: "City",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/city",
    linkTo: "/main/setup/market/city"
  },
  {
    title: "Market Zone",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/zone",
    linkTo: "/main/setup/market/zone"
  },
  {
    title: "Nationality",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/nationality",
    linkTo: "/main/setup/market/nationality"
  },
  {
    title: "Origin",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/origin",
    linkTo: "/main/setup/market/origin"
  },
  {
    title: "Language",
    method: "marketSegment",
    subMenu: "sub7",
    pathName: "/main/setup/market/language",
    linkTo: "/main/setup/market/language"
  },
  {
    title: "Letter",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/letter",
    linkTo: "/main/setup/miscellaneous/letter"
  },
  {
    title: "Currency",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/currency",
    linkTo: "/main/setup/miscellaneous/currency"
  },
  {
    title: "Currencytype",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/currencytype",
    linkTo: "/main/setup/miscellaneous/currencytype"
  },
  {
    title: "Entrypoint",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/entrypoint",
    linkTo: "/main/setup/miscellaneous/entrypoint"
  },
  {
    title: "Inclusion",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/inclusion",
    linkTo: "/main/setup/miscellaneous/inclusion"
  },
  {
    title: "Reservation Type",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/reservation",
    linkTo: "/main/setup/miscellaneous/reservation"
  },
  {
    title: "Title",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/title",
    linkTo:  "/main/setup/miscellaneous/title"
  },
  {
    title: "Vip",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/vip",
    linkTo: "/main/setup/miscellaneous/vip"
  },
  {
    title: "Visa",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/visa",
    linkTo:  "/main/setup/miscellaneous/visa"
  },
  {
    title: "Occupation",
    method: "miscellaneous",
    subMenu: "sub7",
    pathName: "/main/setup/miscellaneous/occupation",
    linkTo:"/main/setup/miscellaneous/occupation"
  },
  {
    title: "Out Of Order",
    method: "roominfomation",
    subMenu: "sub7",
    pathName: "/main/setup/roominformation/ooo",
    linkTo: "/main/setup/roominformation/ooo"
  },
  {	
    title: "Social Media",	
    method: "miscellaneous",	
    subMenu: "sub7",	
    pathName: "/main/setup/miscellaneous/social",
    linkTo: "/main/setup/miscellaneous/social"
  },
  { title: "Rate Approve",
    method: "rateApprove",
    subMenu: "nightAudit",
    pathName: "/main/night/approve",
    linkTo: "/main/night/approve"
  },
  {	
    title: "Report",	
    method: "report",	
    subMenu: "",	
    pathName: "/main/report",
    linkTo: "/main/report"
  },
  {	
    title: "Report",	
    method: "reportOptions",	
    subMenu: "",	
    pathName: "/main/report-options",
    linkTo: "/main/report"	
  },
  {	
    title: "Report",	
    method: "pdfViewer",	
    subMenu: "",	
    pathName: "/main/pdf-viewer",	
    linkTo:"/main/report"
  },
  {	
    title: "Printfrom Options",	
    method: "printfromOptions",	
    subMenu: "",	
    path: "/main/printform-options",
    linkTo:"/main/pdf-viewer"
  },  
  {	
    title: "Report",	
    method: "pdfViewer",	
    subMenu: "",	
    pathName:"/main/pdf-viewer",
    path: "/main/pdf-viewer",
    linkTo: "#"

  },
  {	
    title: "Room Plan",	
    method: "roomplan",	
    subMenu: "",	
    pathName: "/main/roomplan",
    linkTo: "/main/roomplan"
  },
  {	
    title: "Identity",	
    method: "identity",	
    subMenu: "",	
    pathName: "/main/identity",
    linkTo: "/main/identity"
  },
  {
    title:"Endday",
    method:"endDay",
    subMenu:"nightAudit",
    pathName:"/main/night/endday",
    linkTo:"/main/night/endday"
  },{
    title:"Room Avail",
    method:"roomAvail",
    subMenu:"",
    pathName:"/main/room/avail",
    linkTo: "/main/room/avail"
  },
  {	
    title: "Guest Folio",	
    method: "cashierguest",	
    subMenu: "sub9",	
    pathName: "/main/cashier/guest"	,
    linkTo:"/main/cashier/guest"
  },
  {	
    title: "Deposit Folio",	
    method: "cashierbooking",	
    subMenu: "sub9",	
    pathName: "/main/cashier/booking"	,
    linkTo: "/main/cashier/booking"
  },
  {	
    title: "Cash Sales",	
    method: "cashiercash",	
    subMenu: "sub9",	
    pathName: "/main/cashier/cash"	,
    linkTo:"/main/cashier/cash"
  },
  {
    title: "Occupancy Calendar",
    method: "calendar",
    subMenu: "",
    pathName: "/main/calendar",
    linkTo: "#"
  },
  {
    title: "Rate Group",
    method: "rate",
    subMenu: "sub7",
    pathName: "/main/setup/rate/rategroup",
    linkTo: "/main/setup/rate/rategroup"
  },
  {
    title: "Meal Type",
    method: "meal",
    subMenu: "",
    pathName: "/main/setup/mealtype",
    linkTo: "/main/setup/mealtype"
  },
]

export const Navi = (locationURL) => {
  let pathSnippets =  locationURL.split('/')
  let navigator = pathSnippets.map(function(e,index){
    return ({ 
        title: e,
        pathName:`${pathSnippets.slice(0, index + 1).join('/')}`
    })
  })
  const finalBreadcrump = navigator.map((obj)=> RouteNavigator.find(v=>v.pathName===obj.pathName)).filter(o=>o!==undefined)
  return [{title:"Home",pathName:'/main/dashboard',linkTo:"#"},...finalBreadcrump]
}


export const findMenuActive = (current) => {
  let pathSnippets =  current.split('/')
  const location = pathSnippets.slice(0,pathSnippets.length).join('/')
  const arrayRoute = RouteNavigator.find(x => x.pathName === location ) 
  return arrayRoute
}