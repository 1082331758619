
export const ISSUE_CARD_REQUEST = "ISSUE_CARD_REQUEST"
export const ISSUE_CARD_DATASOURCE_SUCCESS = "ISSUE_CARD_DATASOURCE_SUCCESS"
export const ISSUE_CARD_PROCESS_SUCCESS = "ISSUE_CARD_PROCESS_SUCCESS"
export const ISSUE_CARD_PROCESS_RESET = "ISSUE_CARD_PROCESS_RESET"
export const ISSUE_CARD_RESET = "ISSUE_CARD_RESET"

export const ISSUE_CARD_NEW_CARD = "ISSUE_CARD_NEW_CARD"
export const ISSUE_CARD_COPY_CARD = "ISSUE_CARD_COPY_CARD"
export const ISSUE_CARD_PREPARE_DATA = "ISSUE_CARD_PREPARE_DATA"
export const ISSUE_CARD_SET_NEXT_CARD = "ISSUE_CARD_SET_NEXT_CARD"
export const ISSUE_CARD_SET_NEXT_CARD_RESET = "ISSUE_CARD_SET_NEXT_CARD_RESET"

export interface IssueCardRequest {
    type: typeof ISSUE_CARD_REQUEST,
    payload?: any
}

export interface IssueCardProcessSuccess {
    type: typeof ISSUE_CARD_PROCESS_SUCCESS,
    payload: {}
}

export interface IssueCardDataSourceSuccess {
    type: typeof ISSUE_CARD_DATASOURCE_SUCCESS,
    payload: any
}
export interface IssueCardProcessReset {
    type: typeof ISSUE_CARD_PROCESS_RESET
}

export interface IssueCardReset {
    type: typeof ISSUE_CARD_RESET
}

export interface IssueCardNewCard {
    type: typeof ISSUE_CARD_NEW_CARD,
    payload: any
}
export interface IssueCardCopyCard {
    type: typeof ISSUE_CARD_COPY_CARD,
    payload: any
}

export interface IssueCardSetNextCard{
    type: typeof ISSUE_CARD_SET_NEXT_CARD,
    payload: any
}

export interface IssueCardSetNextCardReset{    
    type: typeof ISSUE_CARD_SET_NEXT_CARD_RESET
}

export type IssueCardAction =
    | IssueCardRequest
    | IssueCardProcessSuccess
    | IssueCardDataSourceSuccess
    | IssueCardProcessReset
    | IssueCardReset
    | IssueCardNewCard
    | IssueCardCopyCard
    | IssueCardSetNextCard
    | IssueCardSetNextCardReset
