import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/calendar/calendarActionType"
import * as actionCreators from "appRedux/actions/calendar/occupancyCalendar"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { APIGetRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* calendarRequestProcess({ payload }: actionTypes.CalendarRequest) {

    const month = moment(payload).format("MM")
    const year  =moment(payload).format("YYYY")

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/inventory/occupancyCalendar/?year=${year}&month=${month}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const calendarInfo = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.calendarDataSourceSuccess(calendarInfo))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "calendarPreview", message}
                yield put(actionCreators.calendarProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                    if(data)
                        yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on calendar preview : ",error)
        const processStatus = { status: false, action: "calendarPreview", message:error}
        yield put(actionCreators.calendarProcessSuccess(processStatus))
    }
}

function* calendarRequest() {
    yield takeEvery(actionTypes.CALENDAR_REQUEST, calendarRequestProcess)
}


export default function* rootSaga() {
    yield all([fork(calendarRequest)
    ])
}


