import {
    INVENTORY_RATECALENDAR,
    INVENTORY_RATECALENDAR_SUCCESS,
    INVENTORY_RATECALENDAR_FAIL,
    INVENTORY_RATECALENDAR_CHANGING,
    INVENTORY_RATECALENDAR_UPDATE,
    INVENTORY_RATECALENDAR_UPDATE_FAIL,
    INVENTORY_RATECALENDAR_UPDATE_SUCCESS,
    INVENTORY_BULKUPDATE_REQUEST,
    INVENTORY_BULKUPDATE_SUCCESS,
    INVENTORY_BULKUPDATE_FAIL,
    INVENTORY_BULKUPDATE_RESET
} from 'appRedux/actions/inventory/inventory-actionTypes'

interface INITSTATEProps {
    loader: boolean,
    isCallDataSource: boolean,
    dataSource: any,
    alertMessage: string,
    processUpdateMSG: string,
    bulkUpdate: {
        loader: boolean,
        results: ''
    }
}
const INIT_STATE: INITSTATEProps = {
    loader: true,
    isCallDataSource: true,
    dataSource: {},
    alertMessage: '',
    processUpdateMSG: '',
    bulkUpdate: {
        loader: false,
        results: ''
    }
}

// After this will be adjust code
// Type of action, Type of return this function
export default (state = INIT_STATE, action: any): any => {
    switch (action.type) {
        case INVENTORY_BULKUPDATE_REQUEST: {
            return {
                ...state,
                processUpdateMSG: undefined,
                bulkUpdate: {
                    loader: true,
                    results: ''
                }
            }
        }
        case INVENTORY_BULKUPDATE_SUCCESS: 
        case INVENTORY_BULKUPDATE_FAIL: {
            return {
                ...state,
                processUpdateMSG: undefined,
                bulkUpdate: {
                    loader: false,
                    results: action.payload
                }
            }
        }
        case INVENTORY_BULKUPDATE_RESET: {
            return {
                ...state,
                processUpdateMSG: undefined,
                bulkUpdate: {
                    loader: false,
                    results: ''
                }
            }
        }
        case INVENTORY_RATECALENDAR: {
            return {
                ...state,
                loader: true,
                isCallDataSource: false,
                dataSource: {},
                alertMessage: '',
                processUpdateMSG: undefined
            }
        }
        case INVENTORY_RATECALENDAR_SUCCESS: {
            return {
                ...state,
                loader: false,
                isCallDataSource: false,
                dataSource: action.payload,
                alertMessage: '',
                processUpdateMSG: undefined
            }
        }
        case INVENTORY_RATECALENDAR_FAIL: {
            return {
                ...state,
                loader: false,
                isCallDataSource: false,
                dataSource: {},
                alertMessage: action.messages,
                processUpdateMSG: undefined
            }
        }
        case INVENTORY_RATECALENDAR_CHANGING: {
            return {
                ...state,
                processUpdateMSG: action.processUpdateMSG
            }
        }
        case INVENTORY_RATECALENDAR_UPDATE: {
            return {
                ...state,
                loader: true,
                processUpdateMSG: action.processUpdateMSG
            }
        }
        case INVENTORY_RATECALENDAR_UPDATE_SUCCESS: {
            return {
                ...state,
                loader: false,
                isCallDataSource: false,
                dataSource: action.payload,
                alertMessage: '',
                processUpdateMSG: undefined
            }            
        }
        case INVENTORY_RATECALENDAR_UPDATE_FAIL: {
            return {
                ...state,
                loader: false,
                processUpdateMSG: action.processUpdateMSG
            }
        }
        default:
            return state
    }
}