
export const CHARGE_INHOUSE_REQUEST = "CHARGE_INHOUSE_REQUEST"
export const CHARGE_INHOUSE_UPDATE = "CHARGE_INHOUSE_UPDATE"
export const CHARGE_INHOUSE_PROCESS_SUCCESS = "CHARGE_INHOUSE_PROCESS_SUCCESS"
export const CHARGE_INHOUSE_DATASOURCE_SUCCESS = "CHARGE_INHOUSE_DATASOURCE_SUCCESS"
export const CHARGE_INHOUSE_PROCESS_RESET = "CHARGE_INHOUSE_PROCESS_RESET"
export const CHARGE_INHOUSE_SET_FILTER = "CHARGE_INHOUSE_SET_FILTER"

export const CHARGE_INHOUSE_DETAIL_REQUEST = "CHARGE_INHOUSE_DETAIL_REQUEST"
export const CHARGE_INHOUSE_DETAIL_DATASOURCE_SUCCESS = "CHARGE_INHOUSE_DETAIL_DATASOURCE_SUCCESS"

export interface ChargeInhouseRequest {
  type: typeof CHARGE_INHOUSE_REQUEST,
  payload?:any
}
export interface ChargeInhouseUpdate {
  type: typeof CHARGE_INHOUSE_UPDATE,
  payload?:any
}

export interface ChargeInhouseProcessSuccess {
  type: typeof CHARGE_INHOUSE_PROCESS_SUCCESS,
  payload?:any
}
export interface ChargeInhouseDataSourceSuccess{
  type: typeof CHARGE_INHOUSE_DATASOURCE_SUCCESS,
  payload:any
}
export interface ChargeInhouseDetailRequest {
  type: typeof CHARGE_INHOUSE_DETAIL_REQUEST,
  payload?:any
}
export interface ChargeInhouseDetailDataSourceSuccess{
  type: typeof CHARGE_INHOUSE_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface ChargeInhouseProcessReset {
  type: typeof CHARGE_INHOUSE_PROCESS_RESET
}
export interface ChargeInhouseSetFilter{
  type: typeof CHARGE_INHOUSE_SET_FILTER,
  payload:any
}


export type ChargeInhouseAction = 
| ChargeInhouseRequest
| ChargeInhouseDetailRequest
| ChargeInhouseUpdate
| ChargeInhouseProcessSuccess
| ChargeInhouseDataSourceSuccess
| ChargeInhouseDetailDataSourceSuccess
| ChargeInhouseProcessReset
| ChargeInhouseSetFilter

