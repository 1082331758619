import * as actions from "./ExpectedDepartureActionType"

export const expectedDeparturePreview = (payload?: any): actions.ExpectedDepartureRequest => {
  return {
    type: actions.EXPECTED_DEPARTURE_REQUEST,
    payload
  }
}

export const expectedDepartureProcessSuccess = (payload:any):actions.ExpectedDepartureProcessSuccess =>{
  return{
    type: actions.EXPECTED_DEPARTURE_PROCESS_SUCCESS,
    payload
  }
}

export const expectedDepartureDataSourceSuccess = (payload:any):actions.ExpectedDepartureDataSourceSuccess => {
  return {
    type: actions.EXPECTED_DEPARTURE_DATASOURCE_SUCCESS,
    payload
  }
}
export const expectedDepartureSetFilter = (payload:any):actions.ExpectedDepartureSetFilter => {
  return {
    type: actions.EXPECTED_DEPARTURE_SET_FILTER,
    payload
  }
}

export const expectedDepartureProcessReset = () =>{
  return{
    type:actions.EXPECTED_DEPARTURE_PROCESS_RESET
  } 
}
