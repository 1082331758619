import {
    NOTIFICATION_PREVIEW,
    NOTIFICATION_REMOVE,
    NOTIFICATION_DATASOURCE_SUCCESS,
    NOTIFICATION_PROCESS_SUCCESS
  } from "constants/ActionTypes"

  export const notificationPreview = (value = null) =>{
    return{
      type: NOTIFICATION_PREVIEW,
      notify_datas: value
    }
  }
  export const notificationRemove = (value) =>{
    return {
      type: NOTIFICATION_REMOVE,
      payload:value
    };
  }
  export const notificationDataSourceUpdate = (value)=>{
    return {
      type: NOTIFICATION_DATASOURCE_SUCCESS,
      payload:value
    };
  }
  export const notificationProcessSuccess = (value) =>{
    return{
      type: NOTIFICATION_PROCESS_SUCCESS,
      payload:value
    }
  }
