import * as actions from "appRedux/actions/roomPlan/roomplanResourceActionType"

interface roomplanDataSourceType {
    id: string,
    title?: string | null,
    children: Array<{
        id: string,
        title: string,
        parentId: string,
        extendedProps: {
            status?: string | null,
            bedtype?: string | null,
            room_id: number,
            building?: string | null,
            exposure?: string | null,
            location?: string | null,
            roomtype?: string | null,
            hk_status?: string | null,
            order_seq: number,
            roomgroup?: string | null,
            bedtype_id?: number,
            building_id?: number,
            exposure_id?: number,
            location_id?: number,
            room_number?: string | null,
            roomtype_id?: number,
            bedtype_code?: string | null,
            roomgroup_id?: number,
            roomtype_code?: string | null
        }
    }>
    extendedProps: {
        roomtype?: string | null,
        order_seq: number,
        roomgroup?: string | null,
        roomtype_id: number,
        roomgroup_id?: number,
        roomtype_code?: string | null
    }
}

interface roomplanFilterType {
    roomtype_id?: number | null,
    bedtype_id?: number | null,
    roomgroup_id?: number | null,
    building_id?: number | null,
    location_id?: number | null,
    exposure_id?: number | null,
    statusVAC: boolean,
    statusOCC: boolean,
    statusOOO: boolean,
    statusOOS: boolean,
    statusOOI: boolean,
    maidDirty: boolean,
    maidIn: boolean,
    maidClean: boolean,
    maidInspected: boolean,
    maidPickup: boolean,
    roomNumberSearch?: string
}

export const roomplanFilterStateDefault: roomplanFilterType = {
    roomtype_id: undefined,
    bedtype_id: undefined,
    roomgroup_id: undefined,
    building_id: undefined,
    location_id: undefined,
    exposure_id: undefined,
    statusVAC: false,
    statusOCC: true,
    statusOOO: false,
    statusOOS: false,
    statusOOI: false,
    maidDirty: false,
    maidIn: false,
    maidClean: false,
    maidInspected: false,
    maidPickup: false,
    roomNumberSearch: undefined
}

export interface roomplanResourceType {
    loader: boolean,
    dataSource: Array<any>,
    rawDataSource: Array<roomplanDataSourceType>
    filtersRoomplan: roomplanFilterType,
    process: any
}

const initialState: roomplanResourceType = {
    loader: false,
    dataSource: [],
    rawDataSource: [],
    filtersRoomplan: roomplanFilterStateDefault,
    process: { status: false, action: "", message: "" }
}

export default (state: roomplanResourceType = initialState, action: actions.RoomPlanResourceAction): roomplanResourceType => {
    switch (action.type) {
        case actions.ROOMPLAN_RESOURCE_REQUEST: {
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.ROOMPLAN_RESOURCE_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case actions.ROOMPLAN_RESOURCE_RAW_DATASOURCE: {
            return {
                ...state,
                loader: false,
                rawDataSource: action.payload
            }
        }
        case actions.ROOMPLAN_RESOURCE_SET_FILTER: {
            return {
                ...state,
                filtersRoomplan: action.payload
            }
        }
        case actions.ROOMPLAN_RESOURCE_PROCESS_SUCCESS: {
            return {
                ...state,
                process: action.payload
            }
        }
        case actions.ROOMPLAN_RESOURCE_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.ROOMPLAN_RESOURCE_RESET: {
            return {
                loader: false,
                dataSource: [],
                rawDataSource: [],
                filtersRoomplan: roomplanFilterStateDefault,
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state
    }
}