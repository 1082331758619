import { all, fork, put, takeEvery, call, select } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/roomPlan/roomplanResourceActionType"
import * as actionCreators from "appRedux/actions/roomPlan/roomplanResource"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"
import { AppState } from "ReducerState"

function* roomplanResourceRequestProcess({ payload }: actionTypes.RoomPlanResourceRequest) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/inventory/roomPlan/resource/?format=2`

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
      const roomplanDataSource = response.data
      yield put(actionCreators.roomplanResourceRawDataSource(roomplanDataSource))

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "roomplanGetResource", message }
      yield put(actionCreators.roomplanResourceProcessSuccess(processStatus))
      yield put(actionCreators.roomplanResourceProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on get roomplan resource : ", error)
    const processStatus = { status: false, action: "roomplanGetResource", message: error }
    yield put(actionCreators.roomplanResourceProcessSuccess(processStatus))
    yield put(actionCreators.roomplanResourceProcessReset())
  }
}
function* roomplanResouceFilterRawDataProcess({ payload }: actionTypes.RoomPlanResourceFilterRawData) {
  //yield console.log("filterd ", payload)
  const { statusVAC, statusOCC, statusOOO, statusOOS, statusOOI,
    maidDirty, maidIn, maidClean, maidInspected, maidPickup,
    roomNumberSearch, roomtype_id, bedtype_id, roomgroup_id, building_id, location_id, exposure_id
  } = payload
  const { rawDataSource } = yield select((state: AppState) => state.roomplanResouce)
  //yield console.log("Raw data ", rawDataSource)

  let roomFilterCheck = [] as any
  let maidFilterCheck = [] as any
  if (statusVAC) roomFilterCheck.push("VAC")
  if (statusOCC) roomFilterCheck.push("OCC")
  if (statusOOO) roomFilterCheck.push("OOO")
  if (statusOOS) roomFilterCheck.push("OOS")
  if (statusOOI) roomFilterCheck.push("OOI")

  if (maidDirty) maidFilterCheck.push("DI")
  if (maidIn) maidFilterCheck.push("MI")
  if (maidClean) maidFilterCheck.push("CL")
  if (maidInspected) maidFilterCheck.push("IS")
  if (maidPickup) maidFilterCheck.push("PU")

  const dataReform = rawDataSource.map((obj: any) => {
    let childFilterd = []
    childFilterd = obj.children.filter((o: any) => {
      if (roomFilterCheck.length > 0 && maidFilterCheck.length > 0)
        return (roomFilterCheck.includes(o.extendedProps.status) && maidFilterCheck.includes(o.extendedProps.hk_status))
      else if (roomFilterCheck.length > 0) return roomFilterCheck.includes(o.extendedProps.status)
      else if (maidFilterCheck.length > 0) return maidFilterCheck.includes(o.extendedProps.hk_status)
      else return o
    })

    if (roomgroup_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.roomgroup_id === roomgroup_id)
    if (roomtype_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.roomtype_id === roomtype_id)
    if (bedtype_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.bedtype_id === bedtype_id)
    if (building_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.building_id === building_id)
    if (location_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.location_id === location_id)
    if (exposure_id) childFilterd = childFilterd.filter((o: any) => o.extendedProps.exposure_id === exposure_id)
    if (roomNumberSearch) childFilterd = childFilterd.filter((o: any) => o.extendedProps.room_number.toLowerCase().indexOf(roomNumberSearch) > -1)

    //console.log("childFilterd ", childFilterd)
    if (childFilterd.length > 0)
      return { ...obj, children: childFilterd }
    else
      return undefined

  }).filter((n: any) => n !== undefined)

  //yield console.log("Final ", dataReform)
  yield put(actionCreators.roomplanResourceSetFilter(payload))
  yield put(actionCreators.roomplanResourceDatasouceSuccess(dataReform))
}

function* roomplanResourceRequest() {
  yield takeEvery(actionTypes.ROOMPLAN_RESOURCE_REQUEST, roomplanResourceRequestProcess)
}
function* roomplanResourceFilterRawData() {
  yield takeEvery(actionTypes.ROOMPLAN_RESOURCE_FILTER_RAWDATA, roomplanResouceFilterRawDataProcess)
}

export default function* rootSaga() {
  yield all([fork(roomplanResourceRequest),
  fork(roomplanResourceFilterRawData)
  ])
}


