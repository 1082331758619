import { all, fork, put, takeEvery, call, delay } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/issueLog/IssueLogActionType"
import * as actionCreators from "appRedux/actions/issueLog"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* issueLogRequestProcess({ payload }: actionTypes.IssueLogRequest) {

  const { type,id } = payload
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = (type==="booking") ? `${API_URL_V1}pms/addon/keycard/issueLogs?booking_id=${id}` : `${API_URL_V1}pms/addon/keycard/issueLogs?register_id=${id}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      const issueCardInfo = response.data
      yield delay(1000)
      yield put(actionCreators.issueLogDatasouceSuccess({type,value:issueCardInfo}))

    } else {
      console.log("Error : ", response)
      const { message } = response
      const action = (type === "booking") ? "issueLogByBooking" : "issueLogByRegistration"
      const processStatus = { status: false, action, message }
      yield put(actionCreators.issueLogProcessSuccess(processStatus))
      yield put(actionCreators.issueLogProcessReset())
      if (response.status === 403) {
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on issue log info : ", error)
    const action = (type === "booking") ? "issueLogByBooking" : "issueLogByRegistration"
    const processStatus = { status: false, action, message: error }
    yield put(actionCreators.issueLogProcessSuccess(processStatus))
    yield put(actionCreators.issueLogProcessReset())
  }
}

function* issueLogRequest() {
  yield takeEvery(actionTypes.ISSUE_LOG_REQUEST, issueLogRequestProcess)
}
export default function* rootSaga() {
  yield all([fork(issueLogRequest)
  ])
}


