import { AxiosRequestConfig, AxiosResponse } from "axios"

import { Api } from '../Axios'
import { RequestConfig } from 'constants/ApiConfigs'
import { LocalStorage } from 'util/webStorage'
import { ApiResponse } from 'constants/ActionTypes'
import { ReportAllState } from 'interfaces/report'
import { ReportOptionsState } from 'appRedux/actions/report/report-actionTypes'

class ReportApi extends Api {
    public constructor(apiConfig: AxiosRequestConfig) {
        super(apiConfig)

        this.reportMenu = this.reportMenu.bind(this)
        this.reportOptions = this.reportOptions.bind(this)
    }

    public async reportOptions(report_id: number): Promise<ApiResponse<ReportOptionsState>> {
        // Get access token from localStorage 
        const localStorageItem = new LocalStorage()
        // Config request for this API
        const requestConfig = this.getConfig({
            // -Push Authorization in headers
            headers: { Authorization: `Bearer ${localStorageItem.get('access_token')}` },
            // // -Push Query string into path of URL
            // params: {
            //     report_id
            // }
        })

        // Request and Receive response data from API
        return this.get<ReportOptionsState>(`/v1/pms/report/${report_id}/options/`, requestConfig)
        .then((result: AxiosResponse<ReportOptionsState>) => {
            // console.log('reportOptions:result', result.data)
            return result.data
        })
        .catch((error: AxiosResponse) => {
            // console.log('searchGuestFolio:error', error)
            return error.data
        })

    }

    public async reportMenu(): Promise<ApiResponse<ReportAllState[]>> {
        // Get access token from localStorage 
        const localStorageItem = new LocalStorage()
        // Config request for this API
        const requestConfig = this.getConfig({
            // -Push Authorization in headers
            headers: { Authorization: `Bearer ${localStorageItem.get('access_token')}` }
        })

        // Request and Receive response data from API
        return this.get<ReportAllState[]>(`/v1/pms/report/list/`, requestConfig)
        .then((result: AxiosResponse<ReportAllState[]>) => {
            // console.log('reportMenu:result', result.data)
            return result.data
        })
        .catch((error: AxiosResponse) => {
            // console.log('reportMenu:error', error)
            return error.data
        })
    }

}

export const reportApi = new ReportApi(RequestConfig)