import * as actions from "appRedux/actions/cashier/folioEditActionType"

export type DepartmentType = {
    id: number
    code: string | null
    name: string | null
}

export type FolioType =
    {
        note: string | null
        item_id: number | null
        folio_seq: number | null
        folio_name: string | null
        departments: Array<DepartmentType>
        charge_to_folio_id: number | null
        charge_to_guest_name: string | null
        charge_to_room_number: number | null
    } | null

export type FolioUpdateType = {
    folio1: FolioType
    folio2: FolioType
    folio3: FolioType
    folio4: FolioType
    folio_id: number
    register_id: number | null
    room_number: number | null
    folio_pattern_id: number
    split_room_charge: boolean
    folio_pattern_name: string | null
}

export type folioEditState = {
    loader: boolean,
    dataSource?: FolioUpdateType,
    newFolioUpdate?: FolioUpdateType,
    process: any
}

const initialState: folioEditState = {
    loader: false,
    dataSource: undefined,
    newFolioUpdate: undefined,
    process: { status: false, action: "", message: "" }
}

export default (state: folioEditState = initialState, action: actions.FolioEditAction): folioEditState => {
    switch (action.type) {
        case actions.FOLIO_EDIT_REQUEST:
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        case actions.FOLIO_EDIT_UPDATE:
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        case actions.FOLIO_EDIT_PROCESS_SUCCESS: {
            return {
                ...state,
                loader:false,
                process: action.payload
            }
        }
        case actions.FOLIO_EDIT_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case actions.FOLIO_EDIT_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state;
    }
}