import {
    MARKET_PREVIEW,
    MARKET_NEW,
    MARKET_UPDATE,
    MARKET_REMOVE,
    MARKET_SEARCH,
    MARKET_PROCESS_SUCCESS,
    MARKET_DATASOURCE_SUCCESS,
    MARKET_PROCESS_RESET
  } from "constants/ActionTypes"

  export const marketPreview = (value) => {
    return {
      type: MARKET_PREVIEW,
      payload:value
    }
  }

  export const marketCreate =(value) =>{
    return {
      type:MARKET_NEW,
      payload:value
    }
  }

  export const marketUpdate = (value) =>{
    return {
      type:MARKET_UPDATE,
      payload: value
    }
  }

  export const marketRemove = (value)=>{
    return {
      type:MARKET_REMOVE,
      payload:value
    }
  }

  export const marketSearch = (keyword) =>{
    return {
      type: MARKET_SEARCH,
      payload:keyword
    }
  }

  export const marketProcessSuccess = (value) =>{
    return{
      type:MARKET_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const marketDataSourceUpdate = (value) => {
    return {
      type: MARKET_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const marketProcessReset = () =>{
    return{
      type:MARKET_PROCESS_RESET
    }
  }

