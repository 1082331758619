import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/rsv/CheckinMoreGuestActionType"
import * as actionCreators from "appRedux/actions/rsv/CheckinMoreGuest"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIPostRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* checkinMoreGuestDetailRequestProcess({ payload }: actionTypes.CheckinMoreGuestDetailRequest) {

  const { register_id = undefined, guest_id = undefined } = payload

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}/pms/reservation/addmoreguest`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  const API_DATABODY  = {
    data: {
      register_id,
      guest_id
    }
  }

  try {
    const response: ApiResponse = yield APIPostRequest(url,API_DATABODY, API_PMS_HEADERS)
    //const guestInfo: guestType | undefined = guest_id ? yield call(getGuestById, guest_id) : undefined
    
    if (response.status === 200) {
      const checkinResponse = response.data
      const { result_code ,result_msg } = checkinResponse
      //yield put(actionCreators.checkinMoreGuestDataSourceSuccess({ detailDataSource: guestInhouseDetail, guestInfo }))

      const processStatus = { status: result_code === "0", action: "checkinMoreGuest", message: result_msg }
      yield put(actionCreators.checkinMoreGuestProcessSuccess(processStatus))
      yield put(actionCreators.checkinMoreGuestProcessReset())

    } else {
      console.log("Error : ", response)
      const { result_msg=""} = response.data
      const processStatus = { status: false, action: "checkinMoreGuest", message:result_msg }
      yield put(actionCreators.checkinMoreGuestProcessSuccess(processStatus))
      yield put(actionCreators.checkinMoreGuestProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
          yield put(userSignOut())
      }
    }
  } catch (error) {
    console.log("Error on get checkin more guest : ", error)
    const processStatus = { status: false, action: "checkinMoreGuest", message: error }
    yield put(actionCreators.checkinMoreGuestProcessSuccess(processStatus))
    yield put(actionCreators.checkinMoreGuestProcessReset())
  }
}
/*
function* getGuestById(id: number) {

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/guest/${id}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      const guestInfo = response.data
      return guestInfo
    }
    return undefined
  } catch (error) {
    console.log("Error on get guest by id : ", error)
    return undefined
  }

}*/

function* checkinMoreGuestDetailRequest() {
  yield takeEvery(actionTypes.CHECKIN_MORE_GUEST_DETAIL_REQUEST, checkinMoreGuestDetailRequestProcess)
}

export default function* rootSaga() {
  yield all([fork(checkinMoreGuestDetailRequest)
  ])
}


