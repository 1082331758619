import {
    ROOM_AVAIL_PREVIEW,
    ROOM_AVAIL_PROCESS_SUCCESS,
    ROOM_AVAIL_DATASOURCE_SUCCESS,
    ROOM_AVAIL_PROCESS_RESET,
    ROOM_AVAIL_DETAIL_PREVIEW,
    ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS
} from "constants/ActionTypes"

export const roomAvailPreview = (value) => {
    return {
        type: ROOM_AVAIL_PREVIEW,
        payload:value
    }
}

export const roomAvailProcessSuccess = (value) =>{
    return{
    type:ROOM_AVAIL_PROCESS_SUCCESS,
    payload:value
    }
}

export const roomAvailDataSourceUpdate = (value) => {
    return {
    type: ROOM_AVAIL_DATASOURCE_SUCCESS,
    payload: value
    }
}

export const roomAvailDetailPreview = (value) => {
    return {
        type: ROOM_AVAIL_DETAIL_PREVIEW,
        payload:value
    }
}

export const roomAvailDetailDataSourceUpdate = (value) => {
    return {
    type: ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS,
    payload: value
    }
}

export const roomAvailProcessReset = () =>{
    return{
    type:ROOM_AVAIL_PROCESS_RESET
    }
}

