import {
    EXPOSURE_PREVIEW,
    EXPOSURE_NEW,
    EXPOSURE_UPDATE,
    EXPOSURE_REMOVE,
    EXPOSURE_SEARCH,
    EXPOSURE_PROCESS_SUCCESS,
    EXPOSURE_DATASOURCE_SUCCESS,
    EXPOSURE_PROCESS_RESET
  } from "constants/ActionTypes"

  export const exposurePreview = (value) => {
    return {
      type: EXPOSURE_PREVIEW,
      payload:value
    }
  }

  export const exposureCreate = value => {
           return {
             type: EXPOSURE_NEW,
             payload: value
           };
         };

  export const exposureUpdate = (value) =>{
    return {
      type:EXPOSURE_UPDATE,
      payload: value
    }
  }

  export const exposureRemove = (value)=>{
    return {
      type:EXPOSURE_REMOVE,
      payload:value
    }
  }

  export const exposureSearch = (keyword) =>{
    return {
      type: EXPOSURE_SEARCH,
      payload:keyword
    }
  }

  export const exposureProcessSuccess = (value) =>{
    return{
      type:EXPOSURE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const exposureDataSourceUpdate = (value) => {
    return {
      type: EXPOSURE_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const exposureProcessReset = () =>{
    return{
      type:EXPOSURE_PROCESS_RESET
    }
  }


