import {all, fork, put, call, takeEvery, takeLatest} from "redux-saga/effects"
import {
  SEARCH_GUEST_PREVIEW,
  SEARCH_GUEST_DETAIL_REQUEST,
  GET_GUEST
} from "constants/ActionTypes"

import {searchGuestDataSourceSuccess,
        getGuestProcessSuccess, 
        getMainGuest, 
        setMsgAlertBooking,

        searchGuestDetailDataSourceSuccess,
        searchGuestDetailFormDataSourceSuccess,
        searchGuestProcessSuccess
      } from "appRedux/actions/Guest"
import {userSignOut} from "appRedux/actions/Auth" //showAuthMessage

import { APIGetRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import { getItems } from "util/localStorage"

function* guestSearchProcess({payload}){

  let keywords = payload
  const guestInfo = {} 
  if(keywords.name)
        guestInfo.guest_name = keywords.name
  if(keywords.documentNumber)
        guestInfo.doc_number = keywords.documentNumber
  if(keywords.memberCode)
        guestInfo.member_code = keywords.memberCode
  if(keywords.email)
        guestInfo.email = keywords.email
  if(keywords.telephone)
        guestInfo.telephone = keywords.telephone
  if(keywords.nationId)
        guestInfo.nation_id = keywords.nationId
  
  /* Create query string from array object */
  let queryString = Object.keys(guestInfo).map(key =>`${key}=${guestInfo[key]}`).join('&')

  try {
    
    const url = `${API_URL_V1}pms/guest/search/?${queryString}`
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response = yield APIGetRequest(url,API_PMS_HEADERS)
    if(response.status === 200){
      const dataResponse = response.data.map((obj,key) =>{
        const guestInfo = {}
              guestInfo.key = key
              guestInfo.id = obj.id 
              guestInfo.full_name =  obj.full_name||"" 
              guestInfo.sex =  obj.sex||""
              guestInfo.birthdate = obj.birthdate 
              guestInfo.age = obj.age||""
              guestInfo.nationality = obj.nationality||"" 
              guestInfo.vip_level = obj.vip_level
              guestInfo.company = obj.company
              guestInfo.telephones = obj.telephones
              guestInfo.mobiles = obj.mobiles
              guestInfo.fax = obj.fax
              guestInfo.emails = obj.emails
              guestInfo.member_code = obj.member_code||""
              guestInfo.doc_number =  obj.doc_number||""
              guestInfo.visit_count = obj.visit_count||""
              guestInfo.visit_nights = obj.visit_nights
              guestInfo.last_arrival = obj.last_arrival
              guestInfo.last_departure = obj.last_departure
        return guestInfo
      })
      yield put(searchGuestDataSourceSuccess(dataResponse))

    }else{
      //yield put(searchGuestDataSourceSuccess([]))
      const {message,data} = response.data
      const result_code = ''
      const result_msg = isEmpty(message) ? `Error search guest ${response.status}`  : `${message}  ${response.status}` 
      const result_data = []
      yield put(setMsgAlertBooking({result_code, result_msg, result_data}))
      if(response.status === 403){
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    //console.log("Error : ",error)) 
    const result_code = ''
    const result_msg = 'Error Call search guest' 
    const result_data = []
    yield put(setMsgAlertBooking({result_code, result_msg, result_data}))
  }
}

function* getGuestById({payload}){
  
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/guest/${payload.id}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response = yield APIGetRequest(url,API_PMS_HEADERS)
    
    if(response.status === 200){
      const dataResponse = response.data
      if(payload.isMainGuest){
        yield put(getMainGuest(dataResponse))
      } else {
        yield put(getGuestProcessSuccess(dataResponse))
      }
    }else{
      if(payload.isMainGuest){
        yield put(getMainGuest([]))
      } else {
        yield put(getGuestProcessSuccess([]))
      }
      const {message,data} = response.data
      const result_code = ''
      const result_msg = isEmpty(message) ? `Error search guest ${response.status}`  : `${message}  ${response.status}` 
      const result_data = []
      yield put(setMsgAlertBooking({result_code, result_msg, result_data}))
      if(response.status === 403){
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    //console.log("Error : ",error)
    const result_code = ''
    const result_msg = 'Error search guest' 
    const result_data = []
    yield put(setMsgAlertBooking({result_code, result_msg, result_data}))
  }

}

function* guestDetailSearchProcess({payload}){
  //yield console.log("Get guest detail id ",payload)
  const {value,updateMainGuest,notiKey} = payload
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/guest/${value}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response = yield APIGetRequest(url,API_PMS_HEADERS)
    
    if(response.status === 200){
      const dataResponse = response.data
      if(updateMainGuest){
        yield put(searchGuestDetailDataSourceSuccess(dataResponse))
        const processStatus = { status: true, action: "getGuestDetail", message:"Guest data is updated",notiKey}
        yield put(searchGuestProcessSuccess(processStatus))
      }else{
        yield put(searchGuestDetailFormDataSourceSuccess(dataResponse))
        const processStatus = { status: true, action: "getGuestDetailForm", message:"Guest data is load success.",notiKey}
        yield put(searchGuestProcessSuccess(processStatus))
      }
    }else{

      const {message,data} = response.data
      const processStatus = { status: false, action: "getGuestDetail", message}
      yield put(searchGuestProcessSuccess(processStatus))
      if(response.status === 403){
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    console.log("Error on search guest detail : ",error)
    const processStatus = { status: false, action: "getGuestDetail", message:error}
    yield put(searchGuestProcessSuccess(processStatus))
  }
}

export function* guestSearch() {
  yield takeEvery(SEARCH_GUEST_PREVIEW, guestSearchProcess)
}

export function* getGuest() {
  yield takeEvery(GET_GUEST, getGuestById)
}

export function* guestDetailSearch(){
  yield takeLatest(SEARCH_GUEST_DETAIL_REQUEST,guestDetailSearchProcess)
}

export default function* rootSaga() {
  yield all([
    fork(guestSearch),
    fork(getGuest),
    fork(guestDetailSearch)
  ])
}