import * as actions from "appRedux/actions/currentLocation/currentLoacationActionType"
import { currentLocationType } from "./currentLocationType"

const initialState: currentLocationType = {
    markLocations:[],
    finalAddCurrentLocation:undefined,
    finalRemoveCurrentLocation:undefined
}

export default (state: currentLocationType = initialState,action: actions.CurrentLocationAction): currentLocationType =>{
    switch (action.type) {
    case actions.CURRENT_LOCATION_ADD:
        return {
            ...state,
            finalAddCurrentLocation:action.payload
        }
    case actions.CURRENT_LOCATION_REMOVE:{
        return{
            ...state,
            finalRemoveCurrentLocation:action.payload
        }
    }
    case actions.CURRENT_LOCATION_DATASOURCE_SUCCESS:{
        return{
            ...state,
            markLocations:action.payload
        }
    }
    case actions.CURRENT_LOCATION_RESET:
        return{
            ...state,
            markLocations:[],
            finalAddCurrentLocation:undefined,
            finalRemoveCurrentLocation:undefined
        }
    default:
        return state;
    }
}