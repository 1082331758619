import * as actions from "appRedux/actions/setup/rate/RateGroupActionType"
import {rateGroupState} from "./RateGroupType"

const initialState: rateGroupState = {
	loader: false,
	dataSource:[],
	process:{status:false,action:"",message:""}
}

export default (state: rateGroupState = initialState,action: actions.RateGroupAction): rateGroupState =>{
    switch (action.type) {
    case actions.RATEGROUP_PREVIEW:{
        return {
            ...state,
			loader: false,
			process:{status:false,action:"",message:""}
        }
    }
    case actions.RATEGROUP_NEW: {
		return {
			...state,
			loader: true,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEGROUP_UPDATE:{
		return{
			...state,
			loader:true,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEGROUP_REMOVE: {
		return {
			...state,
			loader: false,
			process:{status:false,action:"",message:""}
		}
	}
	case actions.RATEGROUP_PROCESS_SUCCESS:{
		return{
		...state,
		loader:false,
		process:action.payload
		}
	}
	case actions.RATEGROUP_DATASOURCE_SUCCESS:{
		return {
			...state,
			loader: false,
			dataSource: action.payload
		}
	}
	case actions.RATEGROUP_PROCESS_RESET:{
		return{
		...state,
		process:{status:false,action:"",message:""}
		}
	}
	default:
		return state
    }
}