import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/postingActionType"
import * as actionCreators from "appRedux/actions/cashier/posting"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { APIGetRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* postingRequestProcess(value: actionTypes.PostingRequest) {

  const { payload, sorter = undefined } = value

  let keywords = payload
  const postingJournalInfo: any = {}
  if (keywords.searchText)
    postingJournalInfo.search_text = keywords.searchText
  if (keywords.startDate)
    postingJournalInfo.start_date = moment(keywords.startDate).format('YYYY-MM-DD')
  if (keywords.endDate)
    postingJournalInfo.end_date = moment(keywords.endDate).format('YYYY-MM-DD')
  if (keywords.folioID)
    postingJournalInfo.folio_id = keywords.folioID
  if (keywords.folioSeq)
    postingJournalInfo.folio_seq = keywords.folioSeq
  if (keywords.folioType)
    postingJournalInfo.folio_type = keywords.folioType
  if (keywords.amount)
    postingJournalInfo.amount = keywords.amount
  if (keywords.amount_op)
    postingJournalInfo.amount_op = keywords.amount_op
  if (keywords.roomNumber)
    postingJournalInfo.room_number = keywords.roomNumber
  if (keywords.postUser)
    postingJournalInfo.post_users = keywords.postUser
  if (keywords.postShift)
    postingJournalInfo.post_shift = keywords.postShift
  if (keywords.qty)
    postingJournalInfo.qty = keywords.qty
  if (keywords.registerId)
    postingJournalInfo.register_id = keywords.registerId

  if (keywords.trnCode.length > 0 && keywords.mediaTrnCode.length > 0)
    postingJournalInfo.trn_codes = [...keywords.trnCode, ...keywords.mediaTrnCode]
  else if (keywords.trnCode.length > 0)
    postingJournalInfo.trn_codes = [...keywords.trnCode]
  else if (keywords.mediaTrnCode.length > 0)
    postingJournalInfo.trn_codes = [...keywords.mediaTrnCode]

  postingJournalInfo.flag_post = keywords.statusPost
  postingJournalInfo.flag_settle = keywords.statusSettle
  postingJournalInfo.flag_void = keywords.statusVoid
  postingJournalInfo.flag_split = keywords.statusSplit
  postingJournalInfo.flag_transfer_in = keywords.statusTransIn
  postingJournalInfo.flag_transfer_out = keywords.statusTransOut

  postingJournalInfo.page = keywords.current
  postingJournalInfo.limit = keywords.limit

  /* Create query string from array object */
  let queryString = Object.keys(postingJournalInfo).map(key => `${key}=${postingJournalInfo[key]}`).join('&')
  /* Set sorter */
  if (sorter !== undefined) {
    const sortFieldData = sorter.fieldArray.toString()
    const sortOrderData = sorter.orderArray.toString()
    queryString += `&sortField=${sortFieldData}&sortOrder=${sortOrderData}`
  }

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/cashier/transaction/search/?${queryString}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
      const postingJournalInfo = response.data
      const { paging } = postingJournalInfo
      /* if no error put everything to reducer */
      yield put(actionCreators.postingDataSourceSuccess(postingJournalInfo))
      yield put(actionCreators.postingSetFilter({
              ...payload,
              current: paging?.current,
              limit: paging?.limit,
              pageSize: paging?.limit,
              total: paging?.total_rows
            }))

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "preview", message }
      yield put(actionCreators.postingProcessSuccess(processStatus))
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on posting journal : ", error)
    const processStatus = { status: false, action: "preview", message: error }
    yield put(actionCreators.postingProcessSuccess(processStatus))
  }
}

function* PostingRequest() {
  yield takeEvery(actionTypes.POSTING_REQUEST, postingRequestProcess)
}
export default function* rootSaga() {
  yield all([fork(PostingRequest)])
}


