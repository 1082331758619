import * as actions from "appRedux/actions/cashier/bookingActionType"

export interface cashierState {
    loader: boolean,
    keyword:string,
    dataSource: [],
    paginations: any,
    detailDataSource:any,
    checkoutList:any,
    filterBookingFolio: any,
    process:any
}

const initialState: cashierState = {
    loader: false,
    keyword:'',
    dataSource: [],
    paginations: [],
    detailDataSource:undefined,
    checkoutList:[],
    filterBookingFolio: [],
    process:{ status:false,action:"",message:""}
}

export default (state: cashierState = initialState,action: actions.CashierAction): cashierState =>{
    switch (action.type) {
    case actions.CASHIER_BOOKING_FOLIO_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.CASHIER_BOOKING_FOLIO_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_DATASOURCE_SUCCESS:
        const {results,paging} = action.payload
        return{
            ...state,
            loader:false,
            dataSource: results,
            paginations: paging
        }
    case actions.CASHIER_BOOKING_FOLIO_SET_FILTER:{
        return{
            ...state,
            filterBookingFolio:action.payload
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_DETAIL_REQUEST:{
        return{
            ...state,
            loader:true,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_DETAIL_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            detailDataSource: action.payload
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_POST:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_VOID:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_PAYMENT:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_SPLIT:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_MOVE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_TOGGLE_SUCCESS:{
        return{
            ...state,
            detailDataSource:{...state.detailDataSource,locked:action.payload}
        }
    }
    case actions.CASHIER_BOOKING_INFO_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_INFO_UPDATE_SUCCESS:{
        return{
            ...state,
            detailDataSource:{...state.detailDataSource,note:action.payload}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_CHECKOUT_LIST:{
        return{
            ...state,
            loader:true,
            checkoutList:[],
            process:{ status:false,action:"",message:""}
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_CHECKOUT_LIST_SUCCESS:{
        return{
            ...state,
            loader:false,
            checkoutList:action.payload
        }
    }
    case actions.CASHIER_BOOKING_FOLIO_PROCESS_RESET:{
        return{
            ...state,
            process:{ status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}