
export const CHECKIN_MORE_GUEST_DETAIL_REQUEST = "CHECKIN_MORE_GUEST_DETAIL_REQUEST"
export const CHECKIN_MORE_GUEST_DATASOURCE_SUCCESS = "CHECKIN_MORE_GUEST_DATASOURCE_SUCCESS"
export const CHECKIN_MORE_GUEST_SAVE = "CHECKIN_MORE_GUEST_SAVE"
export const CHECKIN_MORE_GUEST_PROCESS_SUCCESS = "CHECKIN_MORE_GUEST_PROCESS_SUCCESS"
export const CHECKIN_MORE_GUEST_PROCESS_RESET = "CHECKIN_MORE_GUEST_PROCESS_RESET"
export const CHECKIN_MORE_GUEST_RESET = "CHECKIN_MORE_GUEST_RESET"


export interface CheckinMoreGuestDetailRequest {
    type: typeof CHECKIN_MORE_GUEST_DETAIL_REQUEST,
    payload?: any,
    sorter?: any
}
export interface CheckinMoreGuestSave {
    type: typeof CHECKIN_MORE_GUEST_SAVE
}

export interface CheckinMoreGusetDataSourceSuccess {
    type: typeof CHECKIN_MORE_GUEST_DATASOURCE_SUCCESS,
    payload: any
}
export interface CheckinMoreGuestProcessSuccess {
    type: typeof CHECKIN_MORE_GUEST_PROCESS_SUCCESS,
    payload: {}
}

export interface CheckinMoreGuestProcessReset {
    type: typeof CHECKIN_MORE_GUEST_PROCESS_RESET
}

export interface CheckinMoreGuestReset {
    type: typeof CHECKIN_MORE_GUEST_RESET
}

export type CheckinMoreGusetAction =
    | CheckinMoreGuestDetailRequest
    | CheckinMoreGuestSave
    | CheckinMoreGuestProcessSuccess
    | CheckinMoreGusetDataSourceSuccess
    | CheckinMoreGuestProcessReset
    | CheckinMoreGuestReset
