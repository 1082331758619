import moment from 'moment'

import { DefaultValueType } from 'appRedux/actions/report/report-actionTypes'

const convertDefaultDate = (defaultDate: DefaultValueType, system_date: string) => {
    switch (defaultDate) {
        case 'TODAY':
            return moment(system_date)
        case 'TOMORROW':
            return moment(system_date).add(1, 'days')
        case 'YESTERDAY':
            return moment(system_date).subtract(1, 'days')
        case 'NEXT_7_DAYS':
            return moment(system_date).add(7, 'days')
        case 'NEXT_30_DAYS':
            return moment(system_date).add(30, 'days')
        case 'LAST_7_DAYS':
            return moment(system_date).subtract(7, 'days')
        case 'LAST_30_DAYS':
            return moment(system_date).subtract(30, 'days')
        case 'NEXT_MONTH':
            return moment(system_date).add(1, 'month')
        case 'LAST_MONTH':
            return moment(system_date).subtract(1, 'month')
        case 'NEXT_WEEK':
            return moment(system_date).add(1, 'week')
        case 'LAST_WEEK':
            return moment(system_date).subtract(1, 'week')
        case 'START_OF_MONTH':
            return moment(system_date).startOf('month')
        case 'END_OF_MONTH':
            return moment(system_date).endOf('month')
        case 'START_OF_YEAR':
            return moment(system_date).startOf('year')
        case 'END_OF_YEAR':
            return moment(system_date).endOf('year')
        case 'START_OF_DAY':
            return moment(system_date).startOf('day')
        case 'END_OF_DAY':
            return moment(system_date).endOf('day')
        default:
            return null
    }
}

export { convertDefaultDate }