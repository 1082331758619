// Error: 'React' was used before it was defined  @typescript-eslint/no-use-before-define
// So use: // eslint-disable-next-line
// This issule just begin (14/09/2020)
// https://github.com/typescript-eslint/typescript-eslint/issues/2540

// Import package from NPM
// eslint-disable-next-line
import React, { useEffect } from "react"
import { Menu } from "antd"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from 'react-router-dom'

// Import custom by Template
import CustomScrollbars from "util/CustomScrollbars"
import SidebarLogo from "./SidebarLogo"
import { THEME_TYPE_LITE } from "../../constants/ThemeSetting"
import IntlMessages from "../../util/IntlMessages"

// Import custom by Project
import { setPathName } from 'appRedux/actions'
import { AppState } from 'ReducerState'

// Import custom of this components
import { findMenuActive } from "../../routes/main/route"
import { isNull, isUndefined, isEmpty } from "lodash"

import { Icon } from '@mdi/react'
import {
  mdiMonitorDashboard, mdiBadgeAccountHorizontalOutline, mdiHomeAccount, mdiAirplaneLanding, mdiAirplaneTakeoff, mdiChartTimeline,
  mdiFileTree, mdiBedKingOutline, mdiCurrencyUsd, mdiCalendarCheck, mdiCashRegister, mdiMoonWaningCrescent, mdiHistory,
  mdiFileDocumentMultipleOutline, mdiCog, mdiAccountSwitchOutline
} from '@mdi/js'

import permissionVerify from "util/permissionVerify"

const SubMenu = Menu.SubMenu


const SidebarContent = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  let { themeType, pathname } = useSelector((state: AppState) => state.settings)
  const { markLocations } = useSelector((state: AppState) => state.currentLocation)
  /* Check pathurl have query parameter ? */
  const urlRedirect = `${location.pathname}${isEmpty(location.search) ? "" : location.search}`

  useEffect(() => {
    dispatch(setPathName(urlRedirect))
  }, [dispatch, urlRedirect])

  // From Template Wieldy
  // const selectedKeys = pathname.substr(1)
  // const defaultOpenKeys = pathnameKeys.split('/')[1]
  // Custom by KenG (2020-12-14)
  const activeMenu = findMenuActive(isUndefined(pathname) ? '/' : pathname)
  const selectedKeys = isUndefined(activeMenu) || isNull(activeMenu) ? '' : activeMenu.method
  const menuItemFontSize = { fontSize: "small" }
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content pb-2">
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={['dashboard']}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'light' : 'dark'}
            mode="inline"
            style={{ fontSize: 13, marginTop: "15px", marginLeft: "-6px" }}
          >
            <Menu.Item key="dashboard" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
              <Link to={"/main/dashboard"}>
                <Icon path={mdiMonitorDashboard} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.dashboard" />
                </span>
              </Link>
            </Menu.Item>
            {permissionVerify("10000") &&
              <Menu.Item key="reservations" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to={"/main/reservations"}>
                  <Icon path={mdiBadgeAccountHorizontalOutline} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.reservations" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("20000") &&
              <Menu.Item key="guestIndividual" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/guestinhouse">
                  <Icon path={mdiHomeAccount} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.guestInhouse" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("80056") &&
              <Menu.Item key="expectedArrival" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/expected_arrival">
                  <Icon path={mdiAirplaneLanding} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.expectedArrival" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("80058") &&
              <Menu.Item key="expectedDeparture" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/expected_departure">
                  <Icon path={mdiAirplaneTakeoff} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.expectedDeparture" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("62000") &&
              <Menu.Item key="roomplan" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/roomplan">
                  <Icon path={mdiChartTimeline} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.roomPlan" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("50000") &&
              <Menu.Item key="roomStatus" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/registration/housekeeping">
                  <Icon path={mdiFileTree} size={1} />
                  {/*<span>Room Status</span>*/}
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.roomStatus" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("63000") &&
              <Menu.Item key="room_avail" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/room/avail">
                  <Icon path={mdiBedKingOutline} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.roomAvail" />
                  </span>
                </Link>
              </Menu.Item>
            }
            {permissionVerify("64000") &&
              <Menu.Item key="rate_alloment" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/inventory">
                  <Icon path={mdiCurrencyUsd} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.rateAndAllotment" />
                  </span>
                </Link>
              </Menu.Item>
            }
            <Menu.Item key="calendar" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
              <Link to={"/main/calendar"}>
                <Icon path={mdiCalendarCheck} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.occCalendar" />
                </span>
              </Link>
            </Menu.Item>
            <SubMenu key="sub3" title={
              <span style={menuItemFontSize}>
                <Icon path={mdiCashRegister} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.frontCashier" />
                </span>
              </span>
            }
              disabled={markLocations.includes(location.pathname) ? true : false}
            >
              <>
                {permissionVerify("30000") &&
                  <>
                    <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="cashierguest"><Link to="/main/cashier/guest"><IntlMessages id="sidebar.menu.guestFolio" /></Link></Menu.Item>
                    <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="cashierbooking"><Link to="/main/cashier/booking"><IntlMessages id="sidebar.menu.bookingFolio" /></Link></Menu.Item>
                    <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="cashiercash"><Link to="/main/cashier/cash"><IntlMessages id="sidebar.menu.cashSales" /></Link></Menu.Item>
                  </>
                }
                {permissionVerify("33000") &&
                  <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="cashierposting"><Link to="/main/cashier/posting"><IntlMessages id="sidebar.menu.postingJournal" /></Link></Menu.Item>
                }
              </>
            </SubMenu>
            <SubMenu key="nightAudit" title={
              <span>
                <Icon path={mdiMoonWaningCrescent} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.nightAudit" />
                </span>
              </span>
            }
              disabled={markLocations.includes(location.pathname) ? true : false}
            >
              <>
                {permissionVerify("41000") &&
                  <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="rateApprove"><Link to="/main/night/approve"><IntlMessages id="sidebar.menu.rateApprove" /></Link></Menu.Item>
                }
                {permissionVerify("42000") &&
                  <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="endDay"><Link to="/main/night/endday"><IntlMessages id="sidebar.menu.endDay" /></Link></Menu.Item>
                }
                {permissionVerify("43000") &&
                  <Menu.Item style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false} key="autopost"><Link to="/main/night/autopost">Auto Post</Link></Menu.Item>
                }
              </>
            </SubMenu>
            {permissionVerify("80000") &&
              <Menu.Item key="report" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/report">
                  <Icon path={mdiFileDocumentMultipleOutline} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.reports" />
                  </span>
                </Link>
              </Menu.Item>
            }
            <Menu.Item key="logs" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
              <Link to="/main/logs">
                <Icon path={mdiHistory} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.logs" />
                </span>
              </Link>
            </Menu.Item>
            <Menu.Item key="identity" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
              <Link to="/main/identity">
                <Icon path={mdiAccountSwitchOutline} size={1} />
                <span style={{ marginLeft: "30px" }}>
                  <IntlMessages id="sidebar.menu.identity" />
                </span>
              </Link>
            </Menu.Item>

            {permissionVerify("90000") &&
              <Menu.Item key="setup" style={menuItemFontSize} disabled={markLocations.includes(location.pathname) ? true : false}>
                <Link to="/main/setup">
                  <Icon path={mdiCog} size={1} />
                  <span style={{ marginLeft: "30px" }}>
                    <IntlMessages id="sidebar.menu.settings" />
                  </span>
                </Link>
              </Menu.Item>
            }
          </Menu>
        </CustomScrollbars>
      </div >
    </>
  )
}

SidebarContent.propTypes = {}
export default SidebarContent