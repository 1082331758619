import {
    AppAction,
    InitState,
    REPORT_MENU_GET_REQUEST,
    REPORT_MENU_GET_SUCCESS,
    REPORT_MENU_GET_FAIL
} from 'constants/ActionTypes'

import { ReportState } from 'interfaces/report'


const initDataSource: ReportState = { 
    category_report: [], 
    all_report: []
}
const initValues: InitState = {
    caseType: 'INITIATE',
    alertShow: false,
    alertText: '',
    loading: true,
    dataSource: initDataSource
}

const getSuccess = (state: InitState, action: AppAction<string, ReportState>): InitState => {
    return {
        ...state, // Merge previous state
        loading: false,
        dataSource: action.payload
    }
}

export default (state: InitState = initValues, action: AppAction): InitState<ReportState> => {
    switch (action.type) {
        case REPORT_MENU_GET_REQUEST:
            return {
                ...state, // Merge previous state
                loading: true,
                alertShow: false,
                dataSource: initDataSource
            }
        case REPORT_MENU_GET_SUCCESS:
            return getSuccess(state, action)
        case REPORT_MENU_GET_FAIL:
            return {
                ...state,
                caseType: 'FAILED',
                loading: false,
                alertShow: true,
                alertText: action.payload                
            }
        default:
            return {
                ...state,
                caseType: 'DEFAULT'
            }
    }
}