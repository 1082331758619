import {
    SYSTEM_CONFIG_PREVIEW,
    SYSTEM_CONFIG_UPDATE,
    SYSTEM_CONFIG_DATASOURCE_SUCCESS,
    SYSTEM_CONFIG_PROCESS_SUCCESS,
    SYSTEM_CONFIG_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
    loader: false,
    dataSource:{},
    process:{status:false,action:"",message:""}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SYSTEM_CONFIG_PREVIEW:{
            return{
                ...state,
                loader:false,
                process:{status:false,action:"",message:""}
            }
        }
        case SYSTEM_CONFIG_UPDATE:{
            return{
                ...state,
                loader:true,
                process:{status:false,action:"",message:""}
            }
        }
        case SYSTEM_CONFIG_DATASOURCE_SUCCESS:{
            return{
                ...state,
                dataSource:action.payload
            }
        }
        case SYSTEM_CONFIG_PROCESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                process:{...action.payload}
            }
        }
        case SYSTEM_CONFIG_RESET:{
            return{
                ...state,
                loader:false,
                process:{status:false,action:"",message:""}
            }
        }
        default:
            return state
    }
}