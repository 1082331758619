import {
  FOLIO_PATTERN_PREVIEW,
  FOLIO_PATTERN_NEW,
  FOLIO_PATTERN_UPDATE,
  FOLIO_PATTERN_REMOVE,
  FOLIO_PATTERN_PROCESS_SUCCESS,
  FOLIO_PATTERN_DATASOURCE_SUCCESS,
  FOLIO_PATTERN_SEARCH,
  FOLIO_PATTERN_PROCESS_RESET
  
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOLIO_PATTERN_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case FOLIO_PATTERN_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case FOLIO_PATTERN_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case FOLIO_PATTERN_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case FOLIO_PATTERN_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case FOLIO_PATTERN_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case FOLIO_PATTERN_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case FOLIO_PATTERN_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}