import {
    SEARCH_GUEST_PREVIEW,
    SEARCH_GUEST_PROCESS_SUCCESS,
    SEARCH_GUEST_DATASOURCE_SUCCESS,
    SEARCH_GUEST_DETAIL_REQUEST,
    SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS,
    SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS,
    SEARCH_GUEST_PROCESS_RESET,
    RESET_STATE_SEARCH_GUEST,
    GET_GUEST,
    GET_GUEST_PROCESS_SUCCESS,
    GET_MAIN_GUEST,
    RESET_STATE_MAIN_GUEST,
    MSG_AlERT_BOOKING
  } from "constants/ActionTypes"

  export const searchGuestDetailRequest = (value)=>{
    return {
      type: SEARCH_GUEST_DETAIL_REQUEST,
      payload:value
    }
  }

  export const searchGuestDetailDataSourceSuccess = (value)=>{
    return {
      type: SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS,
      payload:value
    }
  }

  export const searchGuestDetailFormDataSourceSuccess = (value)=>{
    return {
      type: SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS,
      payload:value
    }
  }

  export const searchGuestProcessSuccess = (value) =>{
    return{
      type:SEARCH_GUEST_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const searchGuestProcessReset = ()=>{
    return{
      type: SEARCH_GUEST_PROCESS_RESET
    }
  }

  export const setMsgAlertBooking = (value) => {
    return {
      type: MSG_AlERT_BOOKING,
      payload: value
    }
  }

  export const resetSearchGuest = () => {
    return {
      type : RESET_STATE_SEARCH_GUEST
    }
  }

  export const resetMainGuest = () => {
    return {
      type : RESET_STATE_MAIN_GUEST
    }
  }
  
  export const searchGuestPreview = (value) => {
    return {
      type : SEARCH_GUEST_PREVIEW,
      payload: value
    }
  }

  export const searchGuestDataSourceSuccess = (value) => {
    return {
      type: SEARCH_GUEST_DATASOURCE_SUCCESS,
      payload: value
    }
  }

  export const getGuest = (value) => {
    return {
      type : GET_GUEST,
      payload: value
    }
  }

  export const getGuestProcessSuccess = (value) => {
    return {
      type : GET_GUEST_PROCESS_SUCCESS,
      payload: value
    }
  }

  export const getMainGuest = (value) => {
    return {
      type : GET_MAIN_GUEST,
      payload: value
    }
  }

  


  // export const guestProcess = (value) => {
  //   return {
  //     type: RESERVATION_GET_BOOKING_SUCCESS,
  //     payload: value
  // }