export const TRANSFER_FOLIO_FIRST_REQUEST = "TRANSFER_FOLIO_FIRST_REQUEST"
export const TRANSFER_FOLIO_FIRST_DATASOURCE_SUCCESS = "TRANSFER_FOLIO_FIRST_DATASOURCE_SUCCESS"
export const TRANSFER_FOLIO_SECOND_REQUEST = "TRANSFER_FOLIO_SECOND_REQUEST"
export const TRANSFER_FOLIO_SECOND_DATASOURCE_SUCCESS = "TRANSFER_FOLIO_SECOND_DATASOURCE_SUCCESS"

export const TRANSFER_FOLIO_ADVANCE_REQUEST = "TRANSFER_FOLIO_ADVANCE_REQUEST"
export const TRANSFER_FOLIO_ADVANCE_DATASOURCE_SUCCESS = "TRANSFER_FOLIO_ADVANCE_DATASOURCE_SUCCESS"
export const TRANSFER_FOLIO_PROCESS_SUCCESS = "TRANSFER_FOLIO_PROCESS_SUCCESS"
export const TRANSFER_FOLIO_PROCESS_RESET = "TRANSFER_FOLIO_PROCESS_RESET"

export const TRANSFER_TRANSACTION = "TRANSFER_TRANSACTION"
export const TRANSFER_SET_TARGET_FIRST_FOLIO = "TRANSFER_SET_TARGET_FIRST_FOLIO"
export const TRANSFER_SET_TARGET_SECOND_FOLIO = "TRANSFER_SET_TARGET_SECOND_FOLIO"
export const TRANSFER_DATA_FIRST_TO_SECOND = "TRANSFER_DATA_FIRST_TO_SECOND"
export const TRANSFER_DATA_SECOND_TO_FIRST = "TRANSFER_DATA_SECOND_TO_FIRST"

export interface TransferFolioFirstRequest {
  type: typeof TRANSFER_FOLIO_FIRST_REQUEST,
  payload:number
}
export interface TransferFolioFirstDataSourceSuccess{
  type: typeof TRANSFER_FOLIO_FIRST_DATASOURCE_SUCCESS,
  payload:any
}
export interface TransferFolioSecondRequest {
  type: typeof TRANSFER_FOLIO_SECOND_REQUEST,
  payload:number
}
export interface TransferFolioSecondDataSourceSuccess{
  type: typeof TRANSFER_FOLIO_SECOND_DATASOURCE_SUCCESS,
  payload:any
}
export interface TransferFolioAdvanceRequest {
  type: typeof TRANSFER_FOLIO_ADVANCE_REQUEST,
  payload:any
}
export interface TransferFolioAdvanceDataSourceSuccess{
  type: typeof TRANSFER_FOLIO_ADVANCE_DATASOURCE_SUCCESS,
  payload:any
}
export interface TransferFolioProcessSuccess {
  type: typeof TRANSFER_FOLIO_PROCESS_SUCCESS,
  payload:{}
}
export interface TransferFolioProcessReset {
  type: typeof TRANSFER_FOLIO_PROCESS_RESET
}
export interface TransferTransaction{
  type: typeof TRANSFER_TRANSACTION,
  payload:any
}
export interface TransferSetTargetFirstFolio{
  type: typeof TRANSFER_SET_TARGET_FIRST_FOLIO,
  payload:number
}
export interface TransferSetTargetSecondFolio{
  type: typeof TRANSFER_SET_TARGET_SECOND_FOLIO,
  payload:number
}
export interface TransferDataFirstToSecond{
  type: typeof TRANSFER_DATA_FIRST_TO_SECOND,
  payload:{target:number,transfer:any}
}
export interface TransferDataSecondToFirst{
  type: typeof TRANSFER_DATA_SECOND_TO_FIRST,
  payload:{target:number,transfer:any}
}

export type TransferAction = 
| TransferFolioFirstRequest
| TransferFolioFirstDataSourceSuccess
| TransferFolioSecondRequest
| TransferFolioSecondDataSourceSuccess
| TransferFolioAdvanceRequest
| TransferFolioAdvanceDataSourceSuccess
| TransferFolioProcessSuccess
| TransferFolioProcessReset
| TransferTransaction
| TransferSetTargetFirstFolio
| TransferSetTargetSecondFolio
| TransferDataFirstToSecond
| TransferDataSecondToFirst
