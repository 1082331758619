import { all, fork, put,takeEvery, call,select }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/transferActionType"
import * as actionCreators from "appRedux/actions/cashier/transfer"

import { userSignOut} from "appRedux/actions/Auth"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import moment from 'moment'
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* transferFolioFirstRequestProcess({payload}: actionTypes.TransferFolioFirstRequest){

  const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/${payload}/2`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const folioDetail = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.transferFolioFirstDataSourceSuccess(folioDetail))
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "transferFirstRequest", message}
                yield put(actionCreators.transferProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on tranfer first request : ",error)
        const processStatus = { status: false, action: "transferFirstRequest", message:error}
        yield put(actionCreators.transferProcessSuccess(processStatus))
    }
}

function* transferFolioSecondRequestProcess({payload}: actionTypes.TransferFolioSecondRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/${payload}/2`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {       
                    const folioDetail = response.data
                    /* if no error put everything to reducer */
                    yield put(actionCreators.TransferFolioSecondDataSourceSuccess(folioDetail))
  
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "transferSecondRequest", message}
                yield put(actionCreators.transferProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on tranfer second request : ",error)
        const processStatus = { status: false, action: "transferSecondRequest", message:error}
        yield put(actionCreators.transferProcessSuccess(processStatus))
    }
}

function* transferFolioAdvanceRequestProcess({ payload }: actionTypes.TransferFolioAdvanceRequest) {

    let keywords = payload
    const {cashierTransfer:{dataSourceFirst:{folio_id:folioSource}}} = yield select(state => state)
      const searchData:any = {}     
        if(keywords.searchText)
          searchData.search_text = keywords.searchText
        if(keywords.arrival)
          searchData.arrival = moment(keywords.arrival).format('YYYY-MM-DD')
        if(keywords.departure)
          searchData.departure = moment(keywords.departure).format('YYYY-MM-DD')
        if(keywords.room_no)
          searchData.room_number = keywords.room_no
        if(keywords.channel)
          searchData.channel_name = keywords.channel
        if(keywords.folio_type)
          searchData.folio_type = keywords.folio_type

        const roomStatus = ["A"]

        if(!isEmpty(roomStatus) && roomStatus.length < 2)
          searchData.status = roomStatus.toString()
        
        searchData.page = keywords.current||1
        searchData.limit = keywords.limit||50

      /* Create query string from array object */
      let queryString = Object.keys(searchData).map(key =>`${key}=${searchData[key]}`).join('&')

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/search/?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const cashierGuestFolio = response.data
                if(keywords.room_no){
                  const {results,paging} = cashierGuestFolio
                  if(results.length > 1){
                    const processStatus = { status: true, action: "showAdvanceRoom",message:""}
                    const removeSourceFolioID = results.filter((obj:any)=> obj.folio_id !== folioSource)
                    const guestFolioReforming:any = {results:[...removeSourceFolioID],paging}
                    yield put(actionCreators.transferProcessSuccess(processStatus))
                    yield put(actionCreators.TransferFolioAdvanceDataSourceSuccess(guestFolioReforming))
                  }else if(results.length === 0){
                    const processStatus = { status: false, action: "transferSecondRequest",message:"Room not found"}
                    yield put(actionCreators.transferProcessSuccess(processStatus))
                  }else if(results.length === 1){
                    const {folio_id} = results[0]
                    if(folioSource !== folio_id)
                      yield put(actionCreators.transferFolioSecondPreview(folio_id))
                    else {
                      const processStatus = { status: false, action: "transferSecondRequest",message:"Room duplicate."}
                      yield put(actionCreators.transferProcessSuccess(processStatus))
                    }
                  }
                }else{
                  /* if no error put everything to reducer */
                  const {results,paging} = cashierGuestFolio
                  const removeSourceFolioID = results.filter((obj:any)=> obj.folio_id !== folioSource)
                  const guestFolioReforming:any = {results:[...removeSourceFolioID],paging}
                  
                  yield put(actionCreators.TransferFolioAdvanceDataSourceSuccess(guestFolioReforming))
                }
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "advanceSearch", message}
                yield put(actionCreators.transferProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on advance search folio : ",error)
        const processStatus = { status: false, action: "advanceSearch", message:error}
        yield put(actionCreators.transferProcessSuccess(processStatus))
    }
}

interface transferType{
  transfer_data: {
    src_folio_id: number,
    des_folio_id: number,
    transactions: { 
      folio1: [],
      folio2: [],
      folio3: [],
      folio4: []
    }
  },
  shift: string
}

function* transferTransactionProcess({payload}: actionTypes.TransferTransaction){

  const transferInfo = {} as transferType
        transferInfo.transfer_data = {src_folio_id:payload.source,
                                      des_folio_id:payload.target,
                                      transactions:payload.transfers
                                      }
        transferInfo.shift = payload.shift

  try{
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/cashier/transaction/transfer/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = transferInfo
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
      if(response.status === 200){
        const {result_code,result_msg} = response.data
        const processStatus = { status: true, action: "transferTransaction", message:result_msg,result_code}
        yield put(actionCreators.transferProcessSuccess(processStatus))
      }else { 
        const {message} = response.data
        const processStatus = { status: false, action: "transferTransaction", message}
        yield put(actionCreators.transferProcessSuccess(processStatus))
        if(response.status === 403){
          const {data} = response.data
          
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
    } catch (error) {
      const processStatus = { status: false, action: "transferTransaction", message:error}
      yield put(actionCreators.transferProcessSuccess(processStatus))
    }
}

function* transferFolioFirstRequest(){
  yield takeEvery(actionTypes.TRANSFER_FOLIO_FIRST_REQUEST,transferFolioFirstRequestProcess)
}
function* transferFolioSecondRequest(){
  yield takeEvery(actionTypes.TRANSFER_FOLIO_SECOND_REQUEST,transferFolioSecondRequestProcess)
}
function* transferFolioAdvanceRequest() {
  yield takeEvery(actionTypes.TRANSFER_FOLIO_ADVANCE_REQUEST, transferFolioAdvanceRequestProcess)
}
function* transferTransaction(){
  yield takeEvery(actionTypes.TRANSFER_TRANSACTION,transferTransactionProcess)
}

export default function* rootSaga() {
  yield all([fork(transferFolioAdvanceRequest),
             fork(transferFolioFirstRequest),
             fork(transferFolioSecondRequest),
             fork(transferTransaction)
  ])
}


