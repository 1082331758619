import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/cashSaleActionType"
import * as actionCreators from "appRedux/actions/cashier/cashSale"
import { systemConfigPreview } from "appRedux/actions/setup/systemSetup/systemConfig"
import { userSignOut } from "appRedux/actions/Auth"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import moment from 'moment'
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* cashierCashSaleFolioRequestProcess(value: actionTypes.CashierCashSaleFolioRequest) {

  const { payload, sorter = undefined } = value

  let keywords = payload
  const guestInhouseInfo: any = {}
  guestInhouseInfo.folio_type = "C"
  if (keywords.searchText)
    guestInhouseInfo.search_text = keywords.searchText
  if (keywords.arrival)
    guestInhouseInfo.arrival = moment(keywords.arrival).format('YYYY-MM-DD')
  if (keywords.departure)
    guestInhouseInfo.departure = moment(keywords.departure).format('YYYY-MM-DD')
  if (keywords.stayDate)
    guestInhouseInfo.stay_date = moment(keywords.stayDate).format('YYYY-MM-DD')
  if (keywords.roomNumber)
    guestInhouseInfo.room_number = keywords.roomNumber
  if (keywords.channelName)
    guestInhouseInfo.channel_name = keywords.channelName
  if (keywords.registerId)
    guestInhouseInfo.register_id = keywords.registerId
  if (keywords.bookingId)
    guestInhouseInfo.booking_id = keywords.bookingId
  if (keywords.folioNumber)
    guestInhouseInfo.folio_id = keywords.folioNumber

  const roomStatus = []
  if (keywords.statusINH) roomStatus.push("A")
  if (keywords.statusCO) roomStatus.push("C")

  if (!isEmpty(roomStatus) && roomStatus.length < 2)
    guestInhouseInfo.status = roomStatus.toString()

  guestInhouseInfo.page = keywords.current
  guestInhouseInfo.limit = keywords.limit

  /* Create query string from array object */
  let queryString = Object.keys(guestInhouseInfo).map(key => `${key}=${guestInhouseInfo[key]}`).join('&')
  /* Set sorter */
  if (sorter !== undefined) {
    const sortFieldData = sorter.fieldArray.toString()
    const sortOrderData = sorter.orderArray.toString()
    queryString += `&sortField=${sortFieldData}&sortOrder=${sortOrderData}`
  }

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/cashier/folio/search/?${queryString}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      const cashierBookingFolio = response.data
      const { paging } = cashierBookingFolio
      /* if no error put everything to reducer */
      yield put(actionCreators.cashierCashSaleDataSourceSuccess(cashierBookingFolio))
      yield put(actionCreators.cashierCashSaleSetFilter({
        ...payload,
        current: paging?.current,
        limit: paging?.limit,
        pageSize: paging?.limit,
        total: paging?.total_rows
      }))

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "preview", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on cashier guest folio : ", error)
    const processStatus = { status: false, action: "preview", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}

function* cashierCashSaleFolioDetailRequestProcess({ payload }: actionTypes.CashierCashSaleFolioDetailRequest) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/cashier/folio/${payload}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    //console.log("Response ",response)
    if (response.status === 200) {
      const cashierFolioDetail = response.data
      /* if no error put everything to reducer */
      yield put(actionCreators.cashierCashSaleDetailDataSourceSuccess(cashierFolioDetail))
    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "detail", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on cashier guest detail folio : ", error)
    const processStatus = { status: false, action: "detail", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}


interface PostType {
  folio_id: number
  dept_code: string
  amount: number
  qty: number
  folio_seq: number
  reference: string
  description: string
  remark: string
  charge_date?: string | null,
  shift?: string | null
  /* เพ่ิ่มพารามิเตอร์ charge_date ให้สามารถเลือกวันที่ post ได้ ดีฟอลด์จาก system_date */
}

function* cashierCashSaleFolioPostProcess({ payload }: actionTypes.CashierCashSaleFolioPost) {

  const postData = {} as PostType
  postData.folio_id = payload.folio_id
  postData.dept_code = payload.department
  postData.amount = parseFloat(payload.amount)
  postData.qty = parseInt(payload.qty)
  postData.folio_seq = parseInt(payload.folioseq)
  postData.reference = isEmpty(payload.reference) ? null : payload.reference
  postData.remark = isEmpty(payload.remark) ? null : payload.remark
  postData.description = isEmpty(payload.description) ? null : payload.description
  postData.charge_date = moment(payload.postDate).format("YYYY-MM-DD")
  postData.shift = payload.shift

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/post/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = postData
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    //console.log("response ",response)
    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "postTransaction", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "postTransaction", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "postTransaction", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}

interface VoidType {
  trn_ids: number[]
  reason: string
  shift: string
}
function* cashierCashSaleFolioVoidProcess({ payload }: actionTypes.CashierCashSaleFolioVoid) {

  const transID = payload.department.map((obj: any) => obj.id)

  const voidData = {} as VoidType
  voidData.trn_ids = transID
  voidData.reason = payload.description
  voidData.shift = payload.shift

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/void/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = voidData
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    //console.log("response ",response)
    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "voidTransaction", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "voidTransaction", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "voidTransaction", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
interface UpdateType {
  trn_id: number,
  description?: string,
  reference?: string,
  remark?: string,
  dept_id?: number
}
function* cashierCashSaleFolioUpdateProcess({ payload }: actionTypes.CashierCashSaleFolioUpdate) {

  const updateData = {} as UpdateType
  updateData.trn_id = payload.trn_id
  updateData.reference = payload.reference || ""
  updateData.remark = payload.remark || ""
  updateData.description = payload.description || ""

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = updateData
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "updateTransaction", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "updateTransaction", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "updateTransaction", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}

interface paymentType {
  folio_id: number,
  folio_seq: number,
  payment_code: string,
  amount: number,
  shift: string,
  description?: string,
  reference?: string,
  remark?: string
}
function* cashierCashSaleFolioPaymentProcess({ payload }: actionTypes.CashierCashSaleFolioPayment) {

  const paymentData = {} as paymentType
  paymentData.folio_id = payload.folioId
  paymentData.folio_seq = payload.folioseq
  paymentData.payment_code = payload.paymentCode
  paymentData.amount = parseFloat(payload.amount)
  paymentData.reference = payload.reference || ""
  paymentData.remark = payload.remark || ""
  paymentData.description = payload.description || ""
  paymentData.shift = payload.shift

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/payment/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = paymentData
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "folioPayment", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "folioPayment", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "folioPayment", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
interface splitType {
  trn_id: number,
  amounts: [number]
}
function* cashierCashSaleFolioSplitProcess({ payload }: actionTypes.CashierCashSaleFolioSplit) {
  const splitData = {} as splitType
  splitData.trn_id = payload.trn_id
  splitData.amounts = payload.splits

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/split/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = splitData
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "splitTransaction", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "splitTransaction", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "splitTransaction", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
interface moveType {
  trans: [number],
  des_folio_id: number,
  des_folio_seq: number,
  shift: string
}
function* cashierCashSaleFolioMoveProcess({ payload }: actionTypes.CashierCashSaleFolioMove) {

  const moveInfo = {} as moveType
  moveInfo.des_folio_id = payload.folioid
  moveInfo.des_folio_seq = payload.folioseq
  moveInfo.shift = payload.shift
  moveInfo.trans = payload.moveData.map((obj: any) => obj.id)

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/transaction/move/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = moveInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "moveTransaction", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "moveTransaction", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "moveTransaction", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }

}

interface closFolioSeqType {
  folio_id: number,
  folio_seq: number,
  shift: string,
  reset_folio: boolean
}

function* cashierCashSaleCloseFolioSeqProcess({ payload }: actionTypes.CashierCashSaleCloseFolioSeq) {
  const closeInfo = {} as closFolioSeqType
  closeInfo.folio_id = payload.folio_id
  closeInfo.folio_seq = payload.folio_seq
  closeInfo.shift = payload.shift
  closeInfo.reset_folio = true
  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/close/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = closeInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "closeFolioSeq", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "closeFolioSeq", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "closeFolioSeq", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}

function* cashierCashSaleToggleLockProcess({ payload }: actionTypes.CashierCashSaleToggleLock) {
  const { folioID, locked } = payload

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = locked ? `${API_URL_V1}pms/cashier/folio/lock/` : `${API_URL_V1}pms/cashier/folio/unlock/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { folio_id: folioID }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "lockFolio", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      yield put(actionCreators.cashierCashSaleToggleSuccess(locked))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "lockFolio", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "lockFolio", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleInfoUpdateProcess({ payload }: actionTypes.CashierCashSaleInfoUpdate) {
  const { note, folio_id, folio_name, address1, address2, close_date, company, channel_id } = payload

  const updateInfo = {} as any
  updateInfo.note = note
  updateInfo.folio_id = folio_id
  updateInfo.folio_name = folio_name
  updateInfo.address1 = address1
  updateInfo.address2 = address2
  updateInfo.close_date = moment(close_date).format("YYYY-MM-DD")
  updateInfo.company = company
  updateInfo.channel_id = channel_id

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/updateCashSale/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = updateInfo
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "guestInfoUpdate", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleInfoUpdateSuccess(note))
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "guestInfoUpdate", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "guestInfoUpdate", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleChangeOnlineShiftProcess({ payload }: actionTypes.CashierCashSaleChangeOnlineShift) {

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/onlineShift/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { "new_shift": payload }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      /* Update system config new value */
      yield put(systemConfigPreview())
    } else {
      const message = response?.message || "Can't change work shift"
      const processStatus = { status: false, action: "guestChangeOnlineShift", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "guestChangeOnlineShift", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleOpenFolioProcess({ payload }: any) {
  yield console.log("payload ", payload)
  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/openCashSale/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { ...payload }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    console.log("response ", response)
    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "openCashSale", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))

    } else {
      const message = response?.message || "Can't open cashsale"
      const processStatus = { status: false, action: "openCashSale", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "openCashSale", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleCloseFolioProcess({ payload }: any) {
  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/closeCashSale/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { folio_id: payload }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "closeCashSale", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))

    } else {
      const message = response?.message || "Can't close cashsale"
      const processStatus = { status: false, action: "closeCashSale", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "closeCashSale", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleReopenFolioProcess({ payload }: actionTypes.CashierCashSaleReopenFolio) {
  const { folio_id, new_close_date } = payload
  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/reopenCashSale/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { folio_id, new_close_date: moment(new_close_date).format("YYYY-MM-DD") }
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "reopenCashSale", message: result_msg, result_code }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
    } else {
      const { message } = response.data
      const processStatus = { status: false, action: "reopenCashSale", message }
      yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
      if (response.status === 403) {
        const { data } = response.data

        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "reopenCashSale", message: error }
    yield put(actionCreators.cashierCashSaleProcessSuccess(processStatus))
  }
}
function* cashierCashSaleFolioRequest() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_REQUEST, cashierCashSaleFolioRequestProcess)
}
function* cashierCashSaleFolioDetailRequest() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_DETAIL_REQUEST, cashierCashSaleFolioDetailRequestProcess)
}
function* cashierCashSaleFolioPost() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_POST, cashierCashSaleFolioPostProcess)
}
function* cashierCashSaleFolioVoid() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_VOID, cashierCashSaleFolioVoidProcess)
}
function* cashierCashSaleFolioUpdate() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_UPDATE, cashierCashSaleFolioUpdateProcess)
}

function* cashierCashSaleFolioPayment() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_PAYMENT, cashierCashSaleFolioPaymentProcess)
}
function* cashierCashSaleFolioSplit() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_SPLIT, cashierCashSaleFolioSplitProcess)
}
function* cashierCashSaleFolioMove() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_MOVE, cashierCashSaleFolioMoveProcess)
}
function* cashierCashSaleCloseFolioSeq() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_CLOSE_FOLIO_SEQ, cashierCashSaleCloseFolioSeqProcess)
}

function* cashierCashSaleToggleLock() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_FOLIO_TOGGLE_LOCK, cashierCashSaleToggleLockProcess)
}
function* cashierCashSaleInfoUpdate() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_INFO_UPDATE, cashierCashSaleInfoUpdateProcess)
}
function* cashierCashSaleChangeOnlineShift() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_CHANGE_ONLINE_SHIFT, cashierCashSaleChangeOnlineShiftProcess)
}
function* cashierCashSaleOpenFolio() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_OPEN_FOLIO, cashierCashSaleOpenFolioProcess)
}
function* cashierCashSaleCloseFolio() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_CLOSE_FOLIO, cashierCashSaleCloseFolioProcess)
}
function* cashierCashSaleReopenFolio() {
  yield takeEvery(actionTypes.CASHIER_CASHSALE_REOPEN_FOLIO, cashierCashSaleReopenFolioProcess)
}
export default function* rootSaga() {
  yield all([fork(cashierCashSaleFolioRequest),
  fork(cashierCashSaleFolioDetailRequest),
  fork(cashierCashSaleFolioPost),
  fork(cashierCashSaleFolioVoid),
  fork(cashierCashSaleFolioUpdate),
  fork(cashierCashSaleFolioPayment),
  fork(cashierCashSaleFolioSplit),
  fork(cashierCashSaleFolioMove),
  fork(cashierCashSaleCloseFolioSeq),
  fork(cashierCashSaleToggleLock),
  fork(cashierCashSaleInfoUpdate),
  fork(cashierCashSaleChangeOnlineShift),
  fork(cashierCashSaleOpenFolio),
  fork(cashierCashSaleCloseFolio),
  fork(cashierCashSaleReopenFolio)
  ])
}


