import React from 'react'
import { Button} from 'antd'
import { Formik } from "formik"
import { Form, Input, SubmitButton,Select,TreeSelect,DatePicker } from "formik-antd"
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { listBoxAlertPage,listBoxFollowUpCategory,listBoxFollowUpStatus,listBoxFollowUpAssignUser } from "appRedux/actions/Listboxs"
import moment from 'moment'
import IntlMessages from 'util/IntlMessages'
const { Option } = Select
const { TreeNode } = TreeSelect
const FollowUpForm = (props) => {
    const {validationSchema,initialValue,handleSubmit,handleCloseForm,loader} = props
  const { auth: { authUser }, listBoxs, systemConfig } = useSelector(props => props)
    const { alertPageList,followUpCategoryList,followUpStatusList,followUpAssignUserList} = listBoxs
  const { dataSource: { SYSTEM: { SYSTEM_DATE } } } = systemConfig  
    const disPatch = useDispatch()

    useEffect(()=>{
            disPatch(listBoxAlertPage())
            disPatch(listBoxFollowUpCategory())
            disPatch(listBoxFollowUpStatus())
            disPatch(listBoxFollowUpAssignUser(authUser))            
    },[disPatch,authUser])

    const formItemLayout = {
      labelCol: { sm:{span:8},md:{span:8},lg:{span:8} },
      wrapperCol: { sm:{span:12}, md:{span: 12},lg:{span:12}},
      colon:false
    }
    return (
      <>
        <Formik
          enableReinitialize
          isSubmitting={true}
          initialValues={initialValue}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          <Form {...formItemLayout}>
            <Form.Item name="follow_topic" label={<IntlMessages id="app.global.label.topic" />}>
              <Input name="follow_topic" placeholder="Topic" autoFocus={true} />
            </Form.Item>
            <Form.Item name="follow_message" label={<IntlMessages id="app.global.label.message" />}>
              <Input.TextArea
                name="follow_message"
                placeholder="Message"
                rows={4}
              />
            </Form.Item>
            <Form.Item name="category" label={<IntlMessages id="app.global.label.category" />}>
              <Select
                name="category"
                allowClear={true}
                placeholder="Select Category"
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {followUpCategoryList.map((e, key) => (
                  <Option
                    key={`category-${e.follow_up_category_id}`}
                    value={e.follow_up_category_id}
                  >
                    {e.follow_up_category_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="status" label={<IntlMessages id="app.global.label.status" />}>
              <Select
                name="status"
                allowClear={true}
                placeholder="Select status"
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {followUpStatusList.map((e) => (
                  <Option key={`statusList-${e.follow_up_status_id}`} value={e.follow_up_status_id}>
                    {e.follow_up_status_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="assign_user" label={<IntlMessages id="app.global.label.assignUser" />}>
              <TreeSelect
                name="assign_user"
                treeCheckable={true}
                treeDefaultExpandAll={false}
                placeholder="Assign User"
                showSearch
                multiple
                filterTreeNode={(input, option) =>
                  option.props.title
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {followUpAssignUserList.map(({ group_name, user_list }, key) => (
                  <TreeNode
                    value={group_name}
                    title={group_name}
                    key={`${group_name}`}
                  >
                    {user_list.map((v, _k) => (
                      <TreeNode
                        value={v.user_name}
                        title={v.user_name}
                        key={`${v.user_name}`}
                      />
                    ))}
                  </TreeNode>
                ))}
              </TreeSelect>
            </Form.Item>
            <Form.Item name="alert_location" label={<IntlMessages id="app.global.label.alert" />}>
              <Select
                mode="multiple"
                name="alert_location"
                allowClear={true}
                placeholder="Select Location"
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {alertPageList.map((e, key) => (
                  <Option
                    key={`locationList-${e.alert_page_id}`}
                    value={e.alert_page_id}
                  >
                    {e.alert_page_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="alert_date" label={<IntlMessages id="app.global.label.date" />}>
              <DatePicker
                name="alert_date"
                format={"DD/MM/YYYY"}
                disabledDate={(current) => current && (current < moment(SYSTEM_DATE))}
                placeholder="Alert date"
              />
            </Form.Item>
            <SubmitButton
              style={{ marginBottom: 0, marginLeft: "35%" }}
              type="primary"
              htmlType="submit"
              loading={loader}
            >
              <IntlMessages id="app.global.bt.submit" />
            </SubmitButton>
            <Button
              style={{ marginBottom: 0 }}
              type="default"
              htmlType="button"
              onClick={() => handleCloseForm()}
            >
              <IntlMessages id="app.global.bt.back" />
            </Button>
          </Form>
        </Formik>
      </>
    )
}

export default FollowUpForm