import {
    LOCATION_PREVIEW,
    LOCATION_NEW,
    LOCATION_UPDATE,
    LOCATION_REMOVE,
    LOCATION_SEARCH,
    LOCATION_PROCESS_SUCCESS,
    LOCATION_DATASOURCE_SUCCESS,
    LOCATION_PROCESS_RESET
  } from "constants/ActionTypes"

  export const locationPreview = (value) => {
    return {
      type: LOCATION_PREVIEW,
      payload:value
    }
  }

  export const locationCreate =(value) =>{
    return {
      type:LOCATION_NEW,
      payload:value
    }
  }

  export const locationUpdate = (value) =>{
    return {
      type:LOCATION_UPDATE,
      payload: value
    }
  }

  export const locationRemove = (value)=>{
    return {
      type:LOCATION_REMOVE,
      payload:value
    }
  }

  export const locationSearch = (keyword) =>{
    return {
      type: LOCATION_SEARCH,
      payload:keyword
    }
  }

  export const locationProcessSuccess = (value) =>{
    return{
      type:LOCATION_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const locationDataSourceUpdate = (value) => {
    return {
      type: LOCATION_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const locationProcessReset = () =>{
    return{
      type:LOCATION_PROCESS_RESET
    }
  }

