import * as actions from "./BatchReportActionType"

export const batchReportPreview = (payload?: boolean): actions.BatchReportRequest => {
  return {
    type: actions.BATCH_REPORT_REQUEST,
    payload
  }
}
export const batchReportDetailPreview = (payload?: any): actions.BatchReportDetailRequest => {
  return {
    type: actions.BATCH_REPORT_DETAIL_REQUEST,
    payload
  }
}
export const batchReportProcessSuccess = (payload: any): actions.BatchReportProcessSuccess => {
  return {
    type: actions.BATCH_REPORT_PROCESS_SUCCESS,
    payload
  }
}
export const batchReportDataSourceSuccess = (payload: any): actions.BatchReportDataSourceSuccess => {
  return {
    type: actions.BATCH_REPORT_DATASOURCE_SUCCESS,
    payload
  }
}
export const batchReportDetailDataSourceSuccess = (payload: any): actions.BatchReportDetailDataSourceSuccess => {
  return {
    type: actions.BATCH_REPORT_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}
export const batchReportDetailViewModeDataSourceSuccess = (payload: any): actions.BatchReportDetailViewModeDataSourceSuccess => {
  return {
    type: actions.BATCH_REPORT_DETAIL_VIEWMODE_DATASOURCE_SUCCESS,
    payload
  }
}
export const batchReportNew = (value: any): actions.BatchReportNew => {
  return {
    type: actions.BATCH_REPORT_NEW,
    payload: value
  }
}
export const batchReportUpdate = (value: any): actions.BatchReportUpdate => {
  return {
    type: actions.BATCH_REPORT_UPDATE,
    payload: value
  }
}
export const batchReportRemove = (value: any): actions.BatchReportRemove => {
  return {
    type: actions.BATCH_REPORT_REMOVE,
    payload: value
  }
}
export const batchReportDuplicate = (value: any): actions.BatchReportDuplicate => {
  return {
    type: actions.BATCH_REPORT_DUPLICATE,
    payload: value
  }
}
export const batchReportRename = (value: any): actions.BatchReportRename => {
  return {
    type: actions.BATCH_REPORT_RENAME,
    payload: value
  }
}
export const batchReportOptionRequest = (value: any): actions.BatchReportOptionRequest => {
  return {
    type: actions.BATCH_REPORT_OPTION_REQUEST,
    payload: value
  }
}
export const batchReportSaveOption = (value: any): actions.BatchReportSaveOption => {
  return {
    type: actions.BATCH_REPORT_SAVE_OPTION,
    payload: value
  }
}
export const batchReportOptionRequestSuccess = (value: any): actions.BatchReportOptionRequestSuccess => {
  return {
    type: actions.BATCH_REPORT_OPTION_REQUEST_SUCCESS,
    payload: value
  }
}
export const batchReportOptionReset = () => {
  return {
    type: actions.BATCH_REPORT_OPTION_RESET
  }
}
export const batchReportRun = (value: { batchID: number[], userName?: string }): actions.BatchReportRun => {
  return {
    type: actions.BATCH_REPORT_RUN,
    payload: value
  }
}

export const batchReportSwitch = (payload: any): actions.BatchReportSwitch => {
  return {
    type: actions.BATCH_REPORT_SWITCH,
    payload
  }
}

export const batchReportProcessReset = () => {
  return {
    type: actions.BATCH_REPORT_PROCESS_RESET
  }
}
