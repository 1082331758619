import { CHANGE_WORK_SHIFT_SUCCESS } from "constants/ActionTypes"
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  VERIFY_USER,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_USER_SUCCESS,
  RESET_PROCESS_STATUS,
  CHANGE_WORK_SHIFT,
  AUTH_PROCESS_SUCCESS,
  REQUEST_RESET_USER,
  REQUEST_RESET_USER_SUCCESS,
  REQUEST_RESET_USER_CLEAR,
  REQUEST_RESET_USER_INVALID
  // VERIFY_TOKEN
} from "constants/ActionTypes"

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  }
}
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  }
}
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  }
}
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  }
}

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
}
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
}

export const userVerify = (value) =>{
  return{
    type: VERIFY_USER,
    payload: value
  }
}
export const userVerifySuccess = (value)=>{
  return{
    type:VERIFY_USER_SUCCESS,
    payload:value
  }
}
export const verifyOTP = (value)=>{
  return{
    type:VERIFY_OTP,
    payload:value
  }
}
export const verifyOTPSuccess = (value)=>{
  return{
    type:VERIFY_OTP_SUCCESS,
    payload:value
  }
}

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  }
}

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  }
}
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  }
}

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  }
}

export const resetProcessStatus = () =>{
  return {
    type:RESET_PROCESS_STATUS
  }
}

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  }
}

export const resetPassword = (value) =>{
  /* Value is {password:'',confirm:'',uid:''} */
  return {
    type: RESET_PASSWORD,
    payload: value
  }
}

export const changeWorkShift = (value)=>{
  return{
    type: CHANGE_WORK_SHIFT,
    payload:value
  }
}
export const changeWorkShiftSuccess = (value)=>{
  return{
    type: CHANGE_WORK_SHIFT_SUCCESS,
    payload:value
  }
}

export const resetPasswordSuccess = ()=>{
  return {
    type:RESET_PASSWORD_SUCCESS
  }
}

export const authProcessSuccess = (value)=>{
  return{
    type:AUTH_PROCESS_SUCCESS,
    payload:value
  }
}

// export const verifyToken = (value)=>{
//   return{
//     type:VERIFY_TOKEN,
//     payload:value
//   }
// }


export const requestResetUser = (value) =>{
  /* Value is { "action_type" : "","user_name" :  "","email" :  "" } */
  return {
    type: REQUEST_RESET_USER,  // Process
    payload: value
  }
}

export const requestResetUserSuccess = (value) => {
  return {
    type: REQUEST_RESET_USER_SUCCESS,
    payload: value
  }
}

export const clearRequestResetUser = () => {
  return {
    type: REQUEST_RESET_USER_CLEAR
  }
}

export const invalidRequestResetUser = (value) =>{
  return {
    type: REQUEST_RESET_USER_INVALID,
    payload : value

  }
}




