import * as actions from "appRedux/actions/expected/ExpectedDepartureActionType"

export interface expectedDepartureState {
    loader: boolean,
    keyword:string,
    dataSource: [],
    paginations: any,
    filterExpectDeparture: any,
    process:any
}

const initialState: expectedDepartureState = {
    loader: false,
    keyword:'',
    dataSource: [],
    paginations: [],
    filterExpectDeparture: [],
    process:{ status:false,action:"",message:""}
}

export default (state: expectedDepartureState = initialState,action: actions.ExpectedDepartureAction): expectedDepartureState =>{
    switch (action.type) {
    case actions.EXPECTED_DEPARTURE_REQUEST:{
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.EXPECTED_DEPARTURE_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.EXPECTED_DEPARTURE_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.EXPECTED_DEPARTURE_SET_FILTER:{
        return{
            ...state,
            filterExpectDeparture:action.payload
        }
    }
    case actions.EXPECTED_DEPARTURE_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}