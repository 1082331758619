import { forOwn, forEach } from 'lodash'

/**
 * setItem of localStorage one or more
 * how to call like setItems({key1: value1, key2: value2})
 * @param {object} KeysandValues 
 */
const setItems = (KeysandValues) => {
    forOwn(KeysandValues, (item, key) => {
        localStorage.setItem(key, item)
    })
}

const setItemsBySession = (value) => {
    forOwn(value , (item,key)=>{
        sessionStorage.setItem(key,item)
    })
}

/**
 * getItem of localStorage one or more
 * how to call like const { key1, key2 } = getItems(['key1', 'key2'])
 * @param {array} Keys 
 */
const getItems = (Keys) => {
    let items = {}
    forEach(Keys, (key) => {
        items[key] = localStorage.getItem(key)
    })
    return items
}

const getItemsBySession = (Keys) =>{
    let items = {}
    forEach(Keys, (key) => {
        items[key] = sessionStorage.getItem(key)
    })
    return items
}

/**
 * removeItem of localStorage one or more
 * how to call like removeItems(['key1', 'key2'])
 * @param {array} Keys 
 */
const removeItems = (Keys) => {
    forEach(Keys, (key) => {
        localStorage.removeItem(key)
    })
}

const removeItemsBySession = (Keys) => {
    forEach(Keys, (key) => {
        sessionStorage.removeItem(key)
    })
}

export {
    setItems,
    setItemsBySession,
    getItems,
    getItemsBySession,
    removeItems,
    removeItemsBySession
}