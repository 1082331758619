// Filter action -> Button show data
export const SHOW_RATE_AVAIL = 'show_rates_avail'
export const SHOW_AVAIL_ONLY = 'show_avail_only'

// Cell in table that roomtype or rate plan -> for check
export const ROWTYPE_ROOM_TYPE = 'roomtype'
export const ROWTYPE_SELLABLE = 'sellalble'
export const ROWTYPE_RATE_PLAN = 'rateplan'
export const ROWTYPE_SUB_RATE_PLAN_STOPSELL = 'resticStopSell'
export const ROWTYPE_SUB_RATE_PLAN_CTA = 'resticCTA'
export const ROWTYPE_SUB_RATE_PLAN_CTD = 'resticCTD'
export const ROWTYPE_SUB_RATE_PLAN_MINSTAY = 'resticMinStay'
export const ROWTYPE_SUB_RATE_PLAN_MAXSTAY = 'resticMaxStay'
// Field type must match in column of parameter for send to API
export const FIELD_TYPE_SELL_STATUS = 'sell_status'
export const FIELD_TYPE_RATE_PLANS_RATES = 'rates'
export const FIELD_TYPE_RESTRICTIONS_STOP_SELL = 'stop_sell'
export const FIELD_TYPE_RESTRICTIONS_CTA = 'cta'
export const FIELD_TYPE_RESTRICTIONS_CTD = 'ctd'
export const FIELD_TYPE_RESTRICTIONS_MIN_STAY = 'min_stay'
export const FIELD_TYPE_RESTRICTIONS_MAX_STAY = 'max_stay'

// Button action header
export const MODE_EDIT = 'mode_edit'
export const MODE_NORMAL = 'mode_normal'

// Exp. of localStorage storeTempValueInventory
// Store temp array value of inventory of overall tab
// oldValue: [
//     {
//         roomtype: 1,
//         sell_status: [
//             {
//                 date: 2020-01-05,
//                 sellable: true
//             }
//         ],
//         rate_plans: [
//             {
//                 rate_id: 1,
//                 rateplan_id: 1,
//                 date: 2020-01-05,
//                 amount: 1200
//             }
//         ],
//         restrictions: [
//             {
//                 rate_id: 1,
//                 rateplan_id: 1,
//                 date: 2020-01-05,
//                 stop_sell: true,
//                 cta: true,
//                 ctd: false,
//                 min_stay: 2,
//                 max_stay: 5
//             }
//         ]
//     }
// ]