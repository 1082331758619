import * as actions from "appRedux/actions/nightAudit/autoPostRoomType"
import { chargeType } from "routes/main/nightAudit/autoPostRoom/autoPost"

type DataType = {
    key: React.ReactNode
    name: string,
    children?: chargeType[]
}


export type autoPostRoomState = {
    loader: boolean,
    dataSource: DataType[],
    process: any
}

const initialState: autoPostRoomState = {
    loader: false,
    dataSource: [],
    process: { status: false, action: "", message: "" }
}

export default (state: autoPostRoomState = initialState, action: actions.AutoPostRoomAction): autoPostRoomState => {
    switch (action.type) {
        case actions.AUTOPOST_ROOM_REQUEST: {
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.AUTOPOST_ROOM_RUN: {
            return {
                ...state,
                loader: false
            }
        }
        case actions.AUTOPOST_ROOM_REALTIME_UPDATE: {
            return {
                ...state,
                loader: false
            }
        }
        case actions.AUTOPOST_ROOM_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case actions.AUTOPOST_ROOM_PROCESS_SUCCESS: {
            return {
                ...state,
                process: action.payload
            }
        }
        case actions.AUTOPOST_ROOM_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state
    }
}