import * as actions from './CheckinMoreGuestActionType'

export const checkinMoreGuestDetailRequest = (payload?: any): actions.CheckinMoreGuestDetailRequest => {
    return {
        type: actions.CHECKIN_MORE_GUEST_DETAIL_REQUEST,
        payload
    }
}
export const checkinMoreGuestDataSourceSuccess = (payload: any): actions.CheckinMoreGusetDataSourceSuccess => {
    return {
        type: actions.CHECKIN_MORE_GUEST_DATASOURCE_SUCCESS,
        payload
    }
}

export const checkinMoreGuestProcessSuccess = (payload: any): actions.CheckinMoreGuestProcessSuccess => {
    return {
        type: actions.CHECKIN_MORE_GUEST_PROCESS_SUCCESS,
        payload
    }
}

export const checkinMoreGuestSave = (): actions.CheckinMoreGuestSave => {
    return {
        type: actions.CHECKIN_MORE_GUEST_SAVE
    }
}

export const checkinMoreGuestProcessReset = (): actions.CheckinMoreGuestProcessReset => {
    return {
        type: actions.CHECKIN_MORE_GUEST_PROCESS_RESET
    }
}

export const checkinMoreGuestReset = (): actions.CheckinMoreGuestReset => {
    return {
        type: actions.CHECKIN_MORE_GUEST_RESET
    }
}