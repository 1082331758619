import * as actions from "./MealTypeActionType"

export const mealTypePreview = (payload?: any): actions.MealTypeRequest => {
  return {
    type: actions.MEALTYPE_PREVIEW,
    payload
  }
}

export const mealTypeCreate = (payload: any): actions.MealTypeNew => {
  return {
    type: actions.MEALTYPE_NEW,
    payload
  }
}

export const mealTypeUpdate = (payload: any): actions.MealTypeUpdate => {
  return {
    type: actions.MEALTYPE_UPDATE,
    payload
  }
}
export const mealTypeRemove = (payload: number): actions.MealTypeRemove => {
  return {
    type: actions.MEALTYPE_REMOVE,
    payload
  }
}
export const mealTypeProcessSuccess = (payload: any): actions.MealTypeProcessSuccess => {
  return {
    type: actions.MEALTYPE_PROCESS_SUCCESS,
    payload
  }
}


export const mealTypeDataSourceUpdate = (value: any): actions.MealTypeDataSouceSuccess => {
  return {
    type: actions.MEALTYPE_DATASOURCE_SUCCESS,
    payload: value
  }
}
export const mealTypeProcessReset = () => {
  return {
    type: actions.MEALTYPE_PROCESS_RESET
  }
}
export const mealTypeSwitchEnable = (value: any): actions.MealTypeSwitchEnabled => {
  return {
    type: actions.MEALTYPE_SWITCH_ENABLED,
    payload: value
  }
}
