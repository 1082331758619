import * as actions from "appRedux/actions/registration/GuestInhouseActionType"
import { guestType, detailType, bookingSummaryType, guestInhouseState } from "./GuestInhouseType"

export const guestDefault: guestType = {
	id: -1,
	first_name: undefined,
	last_name: undefined,
	title_id: undefined,
	title_name: undefined,
	full_name: undefined,
	sex: undefined,
	birthdate: undefined,
	nation_id: undefined,
	nationality: null,
	country_res_id: undefined,
	country_of_residence: undefined,
	lang_id: undefined,
	language: undefined,
	vip_id: undefined,
	vip_name: undefined,
	company: undefined,
	occupation_id: undefined,
	occupation_name: undefined,
	credit_limit: null,
	confidential: false,
	member_code: null,
	carplate_no: null,
	photo: null,
	photo_url: null,
	remark: null,
	likes: [],
	dislikes: [],
	image_count: 0,
	visit_count: 0,
	visit_nights: 0,
	last_arrival: null,
	last_departure: null,
	contact: {
		address1: null,
		address2: null,
		city_id: null,
		city_name: null,
		country_id: null,
		country_name: null,
		emails: [],
		telephones: [],
		mobiles: []
	},
	document: {
		doc_type: null,
		doc_number: null,
		expire_date: null,
		country_id: null,
		country_name: null
	},
	credit_cards: [],
	social_media: []
}

export const detailInitialState: detailType = {
	register_id: null,
	booking_id: undefined,
	booking_name: undefined,
	booking_item: undefined,
	status: undefined,
	book_type: undefined,
	stay_type: undefined,
	confirm_no: undefined,
	folio_id: undefined,
	rsvtype_id: undefined,
	reservation_type: undefined,
	book_by: undefined,
	guest_id: undefined,
	group_id: null,
	group_name: null,
	member_code: null,
	channel_id: null,
	channel_name: null,
	voucher_no: null,
	upgrade_type: undefined,
	upgrade_reason: undefined,
	origin_id: undefined,
	origin_name: undefined,
	arrival: undefined,
	arrival_time: undefined,
	arrive_by: null,
	arrive_from: null,
	departure: undefined,
	departure_time: undefined,
	depart_by: null,
	depart_to: null,
	nights: undefined,
	sales_id: null,
	sales_name: null,
	market_id: null,
	market_name: null,
	create_user: null,
	create_time: null,
	modify_user: null,
	modify_time: null,
	confirm_user: null,
	confirm_time: null,
	confirm_remark: null,
	cancel_user: null,
	cancel_time: null,
	checkin_user: null,
	checkin_time: null,
	checkout_user: null,
	checkout_time: null,
	msg_count: 0,
	flag_count: 0,
	note: null,
	keep_original_rate: false,
	room_id: undefined,
	room_number: undefined,
	roomtype_id: undefined,
	roomtype_code: undefined,
	roomtype_name: undefined,
	bedtype_id: undefined,
	bedtype_name: undefined,
	rateplan_id: undefined,
	rateplan_name: undefined,
	rate_id: undefined,
	adult: 2,
	child: 0,
	infant: 0,
	extra_adult: undefined,
	extra_child: undefined,
	extra_infant: undefined,
	charge_to_booking: false,
	rate_schedule: [],
	payment: {
		payment_method: null,
		folio_pattern_id: null,
		folio_pattern_name: null,
		credit_limit: 0,
		promo_code: null
	},
	guests: [{ ...guestDefault }],
	requirements: [],
	deposits: [],
	logs: [],
	summary: {
		adult: 0,
		child: 0,
		infant: 0,
		nights: 0,
		arrival: undefined,
		balance: 0,
		deposit: 0,
		room_qty: 0,
		room_number: undefined,
		departure: undefined,
		total_charge: 0,
		other_charges: [],
		accommodations: [],
		total_room_charge: 0,
		total_other_charge: 0
	}
}

export const bookingSummaryInitialState: bookingSummaryType = {
	adult: 0,
	child: 0,
	infant: 0,
	nights: 0,
	arrival: undefined,
	balance: 0,
	deposit: 0,
	room_qty: 0,
	room_number: undefined,
	departure: undefined,
	total_charge: 0,
	other_charges: [],
	accommodations: [],
	total_room_charge: 0,
	total_other_charge: 0,
	extra_adult:0,
	extra_child:0,
	extra_infant:0,
	include_adult:0,
	include_child:0,
	include_infant:0
}

export const initialState: guestInhouseState = {
	loader: false,
	onProcessing: false,
	keyword: '',
	dataSource: [],
	paginations: [],
	detailDataSource: detailInitialState,
	filterDataGuestInhouse: [],
	rateplanDataSource: [],
	bookingSummaryDataSouce: bookingSummaryInitialState,
	roomOriginal: {
		room_id: null,
		room_number: null,
		roomtype_code: null,
		roomtype_id: null,
		roomtype_name: null
	},
	process: { status: false, action: "", message: "" }
}

export default (state: guestInhouseState = initialState, action: actions.GuestInhouseAction): guestInhouseState => {
	switch (action.type) {
		case actions.GUEST_INHOUSE_REQUEST: {
			return {
				...state,
				loader: true,
				detailDataSource: {
					...detailInitialState,
					guests: [{ ...guestDefault }]
				},
				roomOriginal: {
					room_id: null,
					room_number: null,
					roomtype_code: null,
					roomtype_id: null,
					roomtype_name: null
				},
				bookingSummaryDataSouce: bookingSummaryInitialState,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_NEW: {
			return {
				...state,
				loader: false,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_UPDATE: {
			return {
				...state,
				loader: false,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_PROCESS_SUCCESS: {
			return {
				...state,
				onProcessing: false,
				process: action.payload
			}
		}
		case actions.GUEST_INHOUSE_DATASOURCE_SUCCESS: {
			const { results, paging } = action.payload
			return {
				...state,
				loader: false,
				dataSource: results,
				paginations: paging
			}
		}
		case actions.GUEST_INHOUSE_DETAIL_REQUEST: {
			return {
				...state,
				loader: true,
				roomOriginal: {
					room_id: null,
					room_number: null,
					roomtype_code: null,
					roomtype_id: null,
					roomtype_name: null
				},
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_DETAIL_DATASOURCE_SUCCESS: {
			return {
				...state,
				loader: false,
				detailDataSource: action.payload
			}
		}
		case actions.GUEST_INHOUSE_RATEPLAN_REQUEST: {
			return {
				...state,
				rateplanDataSource: []
			}
		}
		case actions.GUEST_INHOUSE_RATEPLAN_DATASOUCE_SUCCESS: {
			return {
				...state,
				rateplanDataSource: action.payload
			}
		}
		case actions.GUEST_INHOUSE_SET_FILTER: {
			return {
				...state,
				filterDataGuestInhouse: action.payload
			}
		}

		case actions.GUEST_INHOUSE_CHANGE_BOOKING_STATUS: {
			return {
				...state,
				process: { status: false, action: "", message: "" }
			}
		}

		case actions.GUEST_INHOUSE_BOOKING_SUMMARY_SUCCESS: {
			return {
				...state,
				bookingSummaryDataSouce: action.payload
			}
		}

		case actions.GUEST_INHOUSE_VERIFY_RATE: {
			return {
				...state,
				onProcessing: true,
				process: { state: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_CHECKIN_VERIFY: {
			return {
				...state,
				loader: true,
				detailDataSource: {
					...detailInitialState,
					guests: [{ ...guestDefault }]
				},
				bookingSummaryDataSouce: bookingSummaryInitialState,
				roomOriginal: {
					room_id: null,
					room_number: null,
					roomtype_code: null,
					roomtype_id: null,
					roomtype_name: null
				},
				onProcessing: false,
				process: { state: false, action: "", message: "" }
			}
		}
		case actions.GUSET_INHOUSE_CHECKIN: {
			return {
				...state,
				loader: false,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL: {
			return {
				...state,
				roomOriginal: action.payload
			}
		}
		case actions.GUEST_INHOUSE_CHECKIN_ROOM_ORIGINAL_RESET: {
			return {
				...state,
				roomOriginal: {
					room_id: null,
					room_number: null,
					roomtype_code: null,
					roomtype_id: null,
					roomtype_name: null
				}
			}
		}
		case actions.GUEST_INHOUSE_PROCESS_RESET: {
			return {
				...state,
				process: { status: false, action: "", message: "" }
			}
		}
		default:
			return state
	}
}