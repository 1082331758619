export const CURRENT_LOCATION_ADD = "CURRENT_LOCATION_ADD"
export const CURRENT_LOCATION_REMOVE ="CURRENT_LOCATION_REMOVE"
export const CURRENT_LOCATION_DATASOURCE_SUCCESS = "CURRENT_LOCATION_DATASOURCE_SUCCESS"
export const CURRENT_LOCATION_RESET = "CURRENT_LOCATION_RESET"


export interface CurrentLocationAdd {
  type: typeof CURRENT_LOCATION_ADD,
  payload?:any
}
export interface CurrentLocationRemove {
  type: typeof CURRENT_LOCATION_REMOVE,
  payload?:any
}
export interface CurrentLocationDataSouceSuccess{
  type: typeof CURRENT_LOCATION_DATASOURCE_SUCCESS,
  payload?:any
}
export interface CurrentLocationReset {
  type: typeof CURRENT_LOCATION_RESET
}


export type CurrentLocationAction = 
| CurrentLocationAdd
| CurrentLocationRemove
| CurrentLocationDataSouceSuccess
| CurrentLocationReset

