import {
    FOLIO_PATTERN_PREVIEW,
    FOLIO_PATTERN_NEW,
    FOLIO_PATTERN_UPDATE,
    FOLIO_PATTERN_REMOVE,
    FOLIO_PATTERN_SEARCH,
    FOLIO_PATTERN_PROCESS_SUCCESS,
    FOLIO_PATTERN_DATASOURCE_SUCCESS,
    FOLIO_PATTERN_PROCESS_RESET
  } from "constants/ActionTypes"

  export const folioPatternPreview = () => {
    return {
      type: FOLIO_PATTERN_PREVIEW
    }
  }

  export const folioPatternCreate =(value) =>{
    return {
      type:FOLIO_PATTERN_NEW,
      payload:value
    }
  }

  export const folioPatternUpdate = (value) =>{
    return {
      type:FOLIO_PATTERN_UPDATE,
      payload: value
    }
  }

  export const folioPatternRemove = (value)=>{
    return {
      type:FOLIO_PATTERN_REMOVE,
      payload:value
    }
  }

  export const folioPatternSearch = (keyword) =>{
    return {
      type: FOLIO_PATTERN_SEARCH,
      payload:keyword
    }
  }

  export const folioPatternProcessSuccess = (value) =>{
    return{
      type:FOLIO_PATTERN_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const folioPatternDataSourceUpdate = (value) => {
    return {
      type: FOLIO_PATTERN_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const folioPatternProcessReset = () =>{
    return{
      type:FOLIO_PATTERN_PROCESS_RESET
    }
  }

