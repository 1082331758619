import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  LOCATION_PREVIEW,
  LOCATION_NEW,
  LOCATION_UPDATE,
  LOCATION_REMOVE,
  LOCATION_SEARCH
} from "constants/ActionTypes"

import {locationDataSourceUpdate,locationProcessSuccess,locationProcessReset} from "appRedux/actions/setup/roomInformation/Location"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* locationPreviewProcess(){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomLocation/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const locationDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.location_id = obj.id
                container.location_name = obj.name
          return container
          })
            
            /* if no error put everything to reducer */
             yield put(locationDataSourceUpdate(locationDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreLocationList",locationDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(locationProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on roomLocation preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(locationProcessSuccess(processStatus))
    }
    
}

function* locationSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreLocationList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.location_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(locationDataSourceUpdate(mergeData))
  }else 
      yield put(locationDataSourceUpdate(ReformalData))
}

function* locationCreateProcess({payload}){
  const {location_name} = payload
  
  let locationInfo = {}
      locationInfo.name = location_name
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomLocation/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = locationInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(locationProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(locationProcessSuccess(processStatus))
              yield put(locationProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(locationProcessSuccess(processStatus))
            yield put(locationProcessReset())
      }

}

function* locationUpdateProcess({payload}){
  const {location_id,location_name} = payload
  
  let locationInfo = {}
      locationInfo.id = location_id
      locationInfo.name = location_name
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomLocation/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = locationInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "update", message:"Update success."}
                yield put(locationProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message}
              yield put(locationProcessSuccess(processStatus))
              yield put(locationProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error}
            yield put(locationProcessSuccess(processStatus))
            yield put(locationProcessReset())
      }
}

function* locationRemoveProcess({payload}){
    const locationRemove = payload
    
    console.log("Delete id ",locationRemove)

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomLocation/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : locationRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(locationProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(locationProcessSuccess(processStatus))
          yield put(locationProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(locationProcessSuccess(processStatus))
      yield put(locationProcessReset())
    }
}

/* Watcher Saga */
export function* locationPreview() {
  yield takeLatest(LOCATION_PREVIEW, locationPreviewProcess)
}
export function* locationSearch(){
  yield takeLatest(LOCATION_SEARCH, locationSearchProcess)
}
export function* locationCreate(){
  yield takeLatest(LOCATION_NEW,locationCreateProcess)
}
export function* locationUpdate(){
  yield takeLatest(LOCATION_UPDATE, locationUpdateProcess)
}
export function* locationRemove(){
  yield takeLatest(LOCATION_REMOVE,locationRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(locationPreview),
             fork(locationCreate),
             fork(locationSearch),
             fork(locationUpdate),
             fork(locationRemove)])


}
