import {
  FOLIO_PATTERN_DETAIL_PREVIEW,
  FOLIO_PATTERN_DETAIL_NEW,
  FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS,
  FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS,
  FOLIO_PATTERN_DETAIL_SYNC_CONFIRM,
  FOLIO_PATTERN_DETAIL_PROCESS_RESET
  
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  entities:{},
  dataSource:[],
  process:{},
  sync_confirm:false,
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FOLIO_PATTERN_DETAIL_PREVIEW: {
      return {
        ...state,
        loader: false,
        dataSource:[],
        process:{status:false,action:"",message:""},
        sync_confirm:false
      }
    }
    case FOLIO_PATTERN_DETAIL_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    
    case FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload.process,
        sync_confirm:action.payload.sync_confirm
      }
    }
    case FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS:{
      return {
        ...state,
        loader: false,
        dataSource: action.payload
      }
    }

    case FOLIO_PATTERN_DETAIL_SYNC_CONFIRM:{
      return{
        ...state,
        process:{status:false,action:"",message:""},
        sync_confirm:false
      }
    }
    case FOLIO_PATTERN_DETAIL_PROCESS_RESET:{
      return{
        ...state,
        process:{},
        sync_confirm:false
      }
    }
    default:
      return state
  }
}