import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  ROOMINFO_PREVIEW,
  ROOMINFO_NEW,
  ROOMINFO_UPDATE,
  ROOMINFO_REMOVE,
  ROOMINFO_SEARCH,
  ROOMINFO_DETAIL
} from "constants/ActionTypes"

import {roomInfoDataSourceUpdate,roomInfoProcessSuccess,roomInfoDetailSuccess,roomInfoProcessReset} from "appRedux/actions/setup/roomInformation/RoomInfo"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* roomInfoPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomInfo/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const roomInfoDataSource = orderBy(response.data,[o => o.room_number],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.room_info_id = obj.id
                container.room_number = obj.room_number
                container.roomtype_id = obj.roomtype_id
                container.room_type_name = obj.room_type
                container.bedtype_id = obj.bedtype_id
                container.bed_type_name = obj.bed_type||""
                container.building_id = obj.building_id
                container.building_name = obj.building||""
                container.location_id = obj.location_id
                container.location_name = obj.location||""
                container.exposure_id = obj.exposure_id
                container.exposure_name = obj.exposure||""
                container.connecting = obj.connecting
                container.connecting_room_id = obj.connecting_room_id
                container.connecting_room_name = obj.connecting_room||""
                container.facilities = obj.facilities
          return container
          })

            /* if no error put everything to reducer */
            yield put(roomInfoDataSourceUpdate(roomInfoDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreRoomInfoList",roomInfoDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(roomInfoProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on roomInfo preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(roomInfoProcessSuccess(processStatus))
    }
    
}

function* roomInfoSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreRoomInfoList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.location_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(roomInfoDataSourceUpdate(mergeData))
  }else 
      yield put(roomInfoDataSourceUpdate(ReformalData))
}

function* roomInfoCreateProcess({payload}){
  const {bedtype_id,building_id,connecting_room_id,exposure_id,facilities,
         location_id,room_number,roomtype_id} = payload
 
  let roomInfo = {}
      roomInfo.room_number = room_number
      roomInfo.roomtype_id = roomtype_id
      roomInfo.building_id = building_id||null
      roomInfo.bedtype_id = bedtype_id||null
      roomInfo.location_id = location_id
      roomInfo.exposure_id = exposure_id
      roomInfo.connecting_room = connecting_room_id
      roomInfo.facilities = facilities
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomInfo/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = roomInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success"}
                yield put(roomInfoProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(roomInfoProcessSuccess(processStatus))
              yield put(roomInfoProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(roomInfoProcessSuccess(processStatus))
            yield put(roomInfoProcessReset())
      }

}

function* roomInfoUpdateProcess({payload}){
  
  const {bedtype_id,building_id,connecting_room_id,exposure_id,facilities,
         location_id,room_number,roomtype_id,room_info_id} = payload
 
  let roomInfo = {}
      roomInfo.id = room_info_id
      roomInfo.room_number = room_number
      roomInfo.roomtype_id = roomtype_id
      roomInfo.building_id = building_id||null
      roomInfo.bedtype_id = bedtype_id
      roomInfo.location_id = location_id
      roomInfo.exposure_id = exposure_id||null
      roomInfo.connecting_room = connecting_room_id
      roomInfo.facilities = facilities

      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomInfo/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = roomInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "update", message:"Update success"}
                yield put(roomInfoProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message}
              yield put(roomInfoProcessSuccess(processStatus))
              yield put(roomInfoProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error}
            yield put(roomInfoProcessSuccess(processStatus))
            yield put(roomInfoProcessReset())
      }
}

function* roomInfoRemoveProcess({payload}){
    const roomInfoRemove = payload
    

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomInfo/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : roomInfoRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(roomInfoProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(roomInfoProcessSuccess(processStatus))
          yield put(roomInfoProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(roomInfoProcessSuccess(processStatus))
      yield put(roomInfoProcessReset())
    }
}
function* roomInfoDetailProcess({payload}){
  const  { access_token }  = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${ API_URL_V1 }pms/masterfile/roomInfo/${payload}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${ AccessToken }`
      try {
          const response = yield APIGetRequest(url, API_PMS_HEADERS)
          if (response.status === 200) {
          const {data:ResponseData} = response
          const {roomtype_id,room_type,room_number,bedtype_id,bed_type,
                 building_id,building,location_id,location,
                 exposure_id,exposure,connecting,
                 connecting_room_id,connecting_room,facilities,
                 status,hk_status } = ResponseData[0]
                 
          const roomDetailInfo = {}
                roomDetailInfo.roomtype_id = roomtype_id
                roomDetailInfo.room_type_name = room_type
                roomDetailInfo.room_number = room_number
                roomDetailInfo.bedtype_id = bedtype_id
                roomDetailInfo.bed_type_name = bed_type || ""
                roomDetailInfo.building_id = building_id
                roomDetailInfo.building_name = building || ""
                roomDetailInfo.location_id = location_id
                roomDetailInfo.location_name = location || ""
                roomDetailInfo.exposure_id = exposure_id
                roomDetailInfo.exposure_name = exposure || ""
                roomDetailInfo.connecting = connecting
                roomDetailInfo.connecting_room_id = connecting_room_id
                roomDetailInfo.connecting_room_name = connecting_room || ""
                roomDetailInfo.facilities = facilities
                roomDetailInfo.status = status
                roomDetailInfo.hk_status = hk_status
          yield put(roomInfoDetailSuccess(roomDetailInfo))

          } else {
            console.log('Error : ', response)
            const { message, data} = response.data
            const processStatus = { status: false, action: "get-detail", message}
              yield put(roomInfoProcessSuccess(processStatus))
              yield put(roomInfoProcessReset())
              if (response.status === 403) {
                if (data)
                  yield put(userSignOut())
              }
          }
      } catch (error) {
          console.log('Error on load roomDetail on listbox saga : ', error)
          const processStatus = { status: false, action: "get-detail", message:error}
          yield put(roomInfoProcessSuccess(processStatus))
          yield put(roomInfoProcessReset())
      }

}

/* Watcher Saga */
export function* roomInfoPreview() {
  yield takeLatest(ROOMINFO_PREVIEW, roomInfoPreviewProcess)
}
export function* roomInfoSearch(){
  yield takeLatest(ROOMINFO_SEARCH, roomInfoSearchProcess)
}
export function* roomInfoCreate(){
  yield takeLatest(ROOMINFO_NEW,roomInfoCreateProcess)
}
export function* roomInfoUpdate(){
  yield takeLatest(ROOMINFO_UPDATE, roomInfoUpdateProcess)
}
export function* roomInfoRemove(){
  yield takeLatest(ROOMINFO_REMOVE,roomInfoRemoveProcess)
}
export function* roomInfoDetail(){
  yield takeLatest(ROOMINFO_DETAIL,roomInfoDetailProcess)
}

export default function* rootSaga() {
  yield all([fork(roomInfoPreview),
             fork(roomInfoCreate),
             fork(roomInfoSearch),
             fork(roomInfoUpdate),
             fork(roomInfoRemove),
             fork(roomInfoDetail)])


}
