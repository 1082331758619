import * as actions from "./guestActionType"

export const cashierPreview = (payload?: any,sorter?:any): actions.CashierGuestFolioRequest => {
  return {
    type: actions.CASHIER_GUEST_FOLIO_REQUEST,
    payload,
    sorter
  }
}

export const cashierProcessSuccess = (payload:any):actions.CashierGuestFolioProcessSuccess =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_PROCESS_SUCCESS,
    payload
  }
}

export const cashierDataSourceSuccess = (payload:any):actions.CashierGuestFolioDataSourceSuccess => {
  return {
    type: actions.CASHIER_GUEST_FOLIO_DATASOURCE_SUCCESS,
    payload
  }
}

export const cashierDetailPreview = (payload:number):actions.CashierGuestFolioDetailRequest=>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_DETAIL_REQUEST,
    payload
  }
}

export const cashierDetailDataSourceSuccess = (payload:any):actions.CashierGuestFolioDetailDataSourceSuccess=>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}

export const cashierSetFilter = (payload:any):actions.CashierSetFilter => {
  return {
    type: actions.CASHIER_GUEST_FOLIO_SET_FILTER,
    payload
  }
}

export const cashierGuestCheckOut = (value:number):actions.CashierGuestCheckOut=>{
  return{
    type:actions.CASHIER_GUEST_FOLIO_CHECK_OUT,
    payload:value
  }
}

export const cashierGuestCancelCheckOut = (value:any):actions.CashierGuestCancelCheckOut =>{
  return{
    type:actions.CASHIER_GUEST_FOLIO_CANCEL_CHECK_OUT,
    payload:value
  }
}

export const cashierGuestFolioPost = (value:any):actions.CashierGuestFolioPost =>{
  return{
    type:actions.CASHIER_GUEST_FOLIO_POST,
    payload:value
  }
}

export const cashierGuestFolioVoid = (value:any):actions.CashierGuestFolioVoid =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_VOID,
    payload:value
  }
}

export const cashierGuestFolioUpdate = (value:any):actions.CashierGuestFolioUpdate =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_UPDATE,
    payload:value
  }
}

export const cashierGuestFolioPayment = (value:any):actions.CashierGuestFolioPayment =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_PAYMENT,
    payload:value
  }
}
export const cashierGuestFolioSplit = (value:any):actions.CashierGuestFolioSplit =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_SPLIT,
    payload:value
  }
}
export const cashierGuestFolioMove = (value:any):actions.CashierGuestFolioMove =>{
  return{
    type: actions.CASHIER_GUEST_FOLIO_MOVE,
    payload:value
  }
}

export const cashierGuestCloseFolioSeq = (value:any):actions.CashierGuestCloseFolioSeq => {
  return {
    type: actions.CASHIER_GUEST_CLOSE_FOLIO_SEQ,
    payload:value
  }
}

export const cashierGuestToggleLock = (value:any):actions.CashierGuestToggleLock => {
  return {
    type: actions.CASHIER_GUEST_FOLIO_TOGGLE_LOCK,
    payload:value
  }
}

export const cashierGuestToggleSuccess = (value:any):actions.CashierGuestToggleSuccess => {
  return {
    type: actions.CASHIER_GUEST_FOLIO_TOGGLE_SUCCESS,
    payload:value
  }
}

export const cashierGuestInfoUpdate = (value:any):actions.CashierGuestInfoUpdate => {
  return {
    type: actions.CASHIER_GUEST_INFO_UPDATE,
    payload:value
  }
}

export const cashierGuestInfoUpdateSuccess = (value:any):actions.CashierGuestInfoUpdateSuccess => {
  return {
    type: actions.CASHIER_GUEST_INFO_UPDATE_SUCCESS,
    payload:value
  }
}

export const cashierGuestChangeOnlineShift = (value:any):actions.CashierGuestChangeOnlineShift => {
  return {
    type: actions.CASHIER_GUEST_CHANGE_ONLINE_SHIFT,
    payload:value
  }
}

export const cashierProcessReset = () =>{
  return{
    type:actions.CASHIER_GUEST_FOLIO_PROCESS_RESET
  } 
}
