import { APIGetRequest,APIPostRequest } from "util/connection"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { isUndefined, find, filter } from 'lodash'

import {
    INVENTORY_RATECALENDAR,
    INVENTORY_RATECALENDAR_UPDATE,
    INVENTORY_BULKUPDATE_REQUEST
} from 'constants/ActionTypes'
import {
    FIELD_TYPE_RATE_PLANS_RATES,
    FIELD_TYPE_SELL_STATUS,
    FIELD_TYPE_RESTRICTIONS_STOP_SELL,
    FIELD_TYPE_RESTRICTIONS_CTA,
    FIELD_TYPE_RESTRICTIONS_CTD,
    FIELD_TYPE_RESTRICTIONS_MIN_STAY,
    FIELD_TYPE_RESTRICTIONS_MAX_STAY
} from '../../routes/main/inventory/components/CardContent/CardContentOverall/ActionType'

import {
    getInventoryRateCalendarSuccess,
    getInventoryRateCalendarFail,
    updateInventoryCalendarSuccess,
    bulkUpdateSuccess,
    bulkUpdateFail
} from 'appRedux/actions/Inventory'
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from 'util/custom'

const number_of_days = 20

function* bulkUpdateProcess({ payload: { values, dates, rates } }) {
    const { access_token } = getItems(['access_token'])
    const URL = `${API_URL_V1}pms/inventory/rateCalendar/bulkUpdate/`
    API_PMS_HEADERS.headers.Authorization = `${process.env.REACT_APP_ACCESS_TOKEN_TYPE} ${access_token}`

    const resUpdate = yield call(APIPostRequest, URL, {
        update_data: {
            values,
            dates,
            rates
        }
    }, API_PMS_HEADERS)
    const { status, message, data } = resUpdate
    if(status===200) {
        yield put(bulkUpdateSuccess(data))
    } else {
        yield put(bulkUpdateFail(message))
    }
}


function* getRateCalendarProcess({ start_date, is_edit_data }) {
    try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/inventory/rateCalendar/?start_date=${start_date}&number_of_days=${number_of_days}&is_edit_data=${is_edit_data}`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

        const response = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200) {
            /* if no error put everything to reducer */
            yield put(getInventoryRateCalendarSuccess(response.data))
        } else {
            /* Show error message and signout */
            const {message,data} = response.data
            yield put(getInventoryRateCalendarFail(message))
            if(response.status === 403){
                /* if data is not null force signout */
                if(data) yield put(userSignOut())
            }
        }
    } catch (error) {
        yield put(getInventoryRateCalendarFail(`${error}`))
    }
}

function* updateInventoryCalendarProcess({ start_date, payload }) {
    // if(payload.length<=0) {
    //     yield put(updateInventoryCalendarFail('You are not change anything!'))
    // } else {
        // map format object of array (sequence format before send to API update)
        const objInventory = sequenceInventoryCalendar(payload, start_date)
        // console.log('objInventory--->', objInventory)
        const { access_token } = getItems(['access_token'])
        const URL = `${API_URL_V1}pms/inventory/rateCalendar/update/`
        API_PMS_HEADERS.headers.Authorization = `${process.env.REACT_APP_ACCESS_TOKEN_TYPE} ${access_token}`

        const resUpdate = yield call(APIPostRequest, URL, objInventory, API_PMS_HEADERS)
        console.log('SAGA--->', resUpdate)
        const { status, message, data } = resUpdate
        if(status===200) {
            yield put(updateInventoryCalendarSuccess(data))
        } else {
            yield put(getInventoryRateCalendarFail(message))
        }
    // }
}
/**
 * Sequence format
 * @param {*} newValue format not yet sequence
 */
const sequenceInventoryCalendar = (newValue, start_date) => {
    let inventory = []
    let keyRows = 0
    let countRoomtype = 0

    while (countRoomtype < newValue.length) {
        inventory[keyRows] = addRoomType(newValue[countRoomtype])
        keyRows++
        countRoomtype++
    }

    return {
        start_date,
        number_of_days,
        inventory
    }
}
const addRoomType = (objRoomType) => {
    let objInventory = {
        roomtype_id: objRoomType.roomtype_id
    }

    // add(SELL_STATUS) If exist field sell_status that add to object of API
    if(!isUndefined(objRoomType[FIELD_TYPE_SELL_STATUS])) objInventory[FIELD_TYPE_SELL_STATUS] = objRoomType[FIELD_TYPE_SELL_STATUS]

    // add(RATE_PLANS and RESTRICTION) if exist field rates opr restrictions that to object of API
    if(!isUndefined(objRoomType[FIELD_TYPE_RATE_PLANS_RATES]) || !isUndefined(objRoomType['restrictions'])) objInventory['rate_plans'] = addRatePlans(objRoomType[FIELD_TYPE_RATE_PLANS_RATES], objRoomType['restrictions'])

    return objInventory
}
const mergeArrayRatesRestrictions = (arrRates, arrRestrictions) => {
    // console.log('mergeArrayRatesRestrictions', {arrRates, arrRestrictions})
    let arrJoin = []
    let keyRows = 0
    let iRates = 0
    let iRestriction = 0
    
    // add rates to new array
    while (iRates < arrRates.length) {
        const existKey = find(arrJoin, { 'rate_id': arrRates[iRates].rate_id, 'rateplan_id': arrRates[iRates].rateplan_id, 'date': arrRates[iRates].date })
        if(isUndefined(existKey)) {
            arrJoin[keyRows] = { 
                rate_id: arrRates[iRates].rate_id, 
                rateplan_id: arrRates[iRates].rateplan_id, 
                date: arrRates[iRates].date, 
                amount: arrRates[iRates].amount 
            }
            keyRows++
        }
        iRates++
    }

    // add restriction to new array
    while (iRestriction < arrRestrictions.length) {
        let existKeyRate = find(arrJoin, { 'rate_id': arrRestrictions[iRestriction].rate_id, 'rateplan_id': arrRestrictions[iRestriction].rateplan_id, 'date': arrRestrictions[iRestriction].date })
        if(isUndefined(existKeyRate)) {
            arrJoin[keyRows] = { 
                rate_id: arrRestrictions[iRestriction].rate_id, 
                rateplan_id: arrRestrictions[iRestriction].rateplan_id, 
                date: arrRestrictions[iRestriction].date,
                amount: null,
                [FIELD_TYPE_RESTRICTIONS_STOP_SELL]: arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_STOP_SELL],
                [FIELD_TYPE_RESTRICTIONS_CTA]: arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_CTA],
                [FIELD_TYPE_RESTRICTIONS_CTD]: arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_CTD],
                [FIELD_TYPE_RESTRICTIONS_MIN_STAY]: arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_MIN_STAY],
                [FIELD_TYPE_RESTRICTIONS_MAX_STAY]: arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_MAX_STAY]
            }
            keyRows++
        } else {
            existKeyRate[FIELD_TYPE_RESTRICTIONS_STOP_SELL] = arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_STOP_SELL]
            existKeyRate[FIELD_TYPE_RESTRICTIONS_CTA] = arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_CTA]
            existKeyRate[FIELD_TYPE_RESTRICTIONS_CTD] = arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_CTD]
            existKeyRate[FIELD_TYPE_RESTRICTIONS_MIN_STAY] = arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_MIN_STAY]
            existKeyRate[FIELD_TYPE_RESTRICTIONS_MAX_STAY] = arrRestrictions[iRestriction][FIELD_TYPE_RESTRICTIONS_MAX_STAY]
        }
        
        iRestriction++
    }

    return arrJoin
}
const addObjectRate = (objRate, arrRates) => {
    let objRatePlans = {
        rate_id: objRate.rate_id,
        rateplan_id: objRate.rateplan_id

    }

    // add object of array rate
    objRatePlans[FIELD_TYPE_RATE_PLANS_RATES] = addFieldRates(arrRates)

    return objRatePlans
}
const addFieldRates = (arrRates) => {
    let rates = []
    let keyRowsRates = 0
    let i = 0
    while (i < arrRates.length) {
        rates[keyRowsRates] =  addFieldsRestriction(arrRates[i])
        keyRowsRates++
        i++
    }
    return rates
}
const addFieldsRestriction = (objRates) => {
    let objRate = {
        date: objRates.date,
        amount: objRates.amount
    }

    if(!isUndefined(objRates[FIELD_TYPE_RESTRICTIONS_STOP_SELL])) objRate['restrictions'] = {
        [FIELD_TYPE_RESTRICTIONS_STOP_SELL]: objRates[FIELD_TYPE_RESTRICTIONS_STOP_SELL],
        [FIELD_TYPE_RESTRICTIONS_CTA]: objRates[FIELD_TYPE_RESTRICTIONS_CTA],
        [FIELD_TYPE_RESTRICTIONS_CTD]: objRates[FIELD_TYPE_RESTRICTIONS_CTD],
        [FIELD_TYPE_RESTRICTIONS_MIN_STAY]: objRates[FIELD_TYPE_RESTRICTIONS_MIN_STAY],
        [FIELD_TYPE_RESTRICTIONS_MAX_STAY]: objRates[FIELD_TYPE_RESTRICTIONS_MAX_STAY]
    }


    return objRate
}
const addRatePlans = (arrRates, arrRestrictions) => {
    let fieldRates = (!isUndefined(arrRates))?(arrRates):([])
    let fieldRestrictions = (!isUndefined(arrRestrictions))?(arrRestrictions):([])

    const joinFieldRateRestriction = mergeArrayRatesRestrictions(fieldRates, fieldRestrictions)

    let ratesPlan = []
    let keyRowsRatePlan = 0
    let i = 0

    while (i < joinFieldRateRestriction.length) {
        const existRate_RatePlanID = find(ratesPlan, { 'rate_id': joinFieldRateRestriction[i].rate_id, 'rateplan_id': joinFieldRateRestriction[i].rateplan_id })
        if(isUndefined(existRate_RatePlanID)) {
            // add object to array
            const arrRatesOfExistRateRatePlanID = filter(joinFieldRateRestriction, { 'rate_id': joinFieldRateRestriction[i].rate_id, 'rateplan_id': joinFieldRateRestriction[i].rateplan_id })
            ratesPlan[keyRowsRatePlan] = addObjectRate(joinFieldRateRestriction[i], arrRatesOfExistRateRatePlanID)
            keyRowsRatePlan++
        }
        i++ 
    }
    
    return ratesPlan
}


/* Watcher Saga */
export function* bulkUpdate(){
    yield takeEvery(INVENTORY_BULKUPDATE_REQUEST, bulkUpdateProcess)
}
export function* updateInventoryCalendar(){
    yield takeEvery(INVENTORY_RATECALENDAR_UPDATE, updateInventoryCalendarProcess)
}
export function* getInventoryRateCalendar(){
    yield takeEvery(INVENTORY_RATECALENDAR, getRateCalendarProcess)
}


export default function* rootSaga() {
    yield all([
        fork(getInventoryRateCalendar),
        fork(updateInventoryCalendar),
        fork(bulkUpdate)
    ])
}