import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  RESET_PASSWORD_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  RESET_PROCESS_STATUS,
  VERIFY_OTP_SUCCESS,
  CHANGE_WORK_SHIFT_SUCCESS,
  AUTH_PROCESS_SUCCESS,
  REQUEST_RESET_USER_SUCCESS,
  REQUEST_RESET_USER_CLEAR,
  REQUEST_RESET_USER_INVALID,
  // VERIFY_TOKEN
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: null,
  processStatus: false,
  otpGenerate: "",
  activateUID: "",
  process: { status: false, action: "", message: "" },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "/",
        loader: false,
      };
    }
    case RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loader: false,
        processStatus: true,
      };
    }
    case VERIFY_USER: {
      return {
        ...state,
        loader: true,
        processStatus: false,
        otpGenerate: "",
        activateUID: "",
      };
    }
    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        processStatus: true,
        otpGenerate: action.payload,
      };
    }
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        loader: false,
        processStatus: true,
        activateUID: action.payload,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        showMessage: false,
        loader: false,
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case CHANGE_WORK_SHIFT_SUCCESS: {
      return {
        ...state,
        authUser: { ...state.authUser, current_shift: action.payload },
      };
    }
    case AUTH_PROCESS_SUCCESS: {
      return {
        ...state,
        process: action.payload,
      };
    }
    case RESET_PROCESS_STATUS: {
      return {
        ...state,
        processStatus: false,
        process: { status: false, action: "", message: "" },
      };
    }

    case REQUEST_RESET_USER_SUCCESS: {
      return {
        ...state,
        processStatus: true,
        process: {
          status: true,
          action: "requestResetUser",
          message: action.payload.res_msg,
        },
      };
    }

    // case REQUEST_RESET_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     processStatus: true,
    //     process: {
    //       status: true,
    //       action: "requestResetUser",
    //       message:action.payload
    //     },
    //   };
    // }

    // case REQUEST_RESET_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     processStatus: {
    //       status: true,
    //       action: "requestResetUser",
    //       message:action.payload
    //     },
    //   };
    // }

    // case REQUEST_RESET_USER_SUCCESS: {
    //   return {
    //     ...state,
    //     processStatus: {
    //       status: action.payload.status === 200,
    //       action: "requestResetUser",
    //       res_msg: action.payload.res_msg,
    //       res_code: action.payload.res_code,
    //       message: action.payload.message,
    //       // เก็บ response ทั้งหมดไว้ถ้าต้องการใช้
    //       complete_response: action.payload
    //     },
    //   };
    // }

    // case REQUEST_RESET_USER_CLEAR: {
    //   return {
    //     ...INIT_STATE,
    //     processStatus: {
    //       status: false,
    //       action: "requestResetUserClear",
    //       message: "",
    //     },
    //   };
    // }
    // case REQUEST_RESET_USER_INVALID: {
    //   return {
    //     ...state,
    //     processStatus: {
    //       status: false,
    //       action: "requestResetUserInvalid",
    //       message: "",
    //     },
    //   };
    // }

    case REQUEST_RESET_USER_CLEAR: {
      return {
        ...INIT_STATE,
        processStatus: false,
        process: {
          status: false,
          action: "requestResetUserClear",
          message: '',
        },
      };
    }
    case REQUEST_RESET_USER_INVALID: {
      return {
        ...state,
        processStatus: false,
        process: {
          status: false,
          action: "requestResetUserInvalid",
          message: "",
        },
      };
    }



    default:
      return state;
  }
};
