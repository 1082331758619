import { detailType, guestType } from "../registration/GuestInhouseType"
import { detailInitialState } from "../registration/GuestInhouse"
import * as actions from "appRedux/actions/rsv/CheckinMoreGuestActionType"

export interface checkinMoreGuestState {
    loader: boolean,
    dataSource: detailType,
    guestInfo?: guestType,
    process: any
}

export const initialState: checkinMoreGuestState = {
    loader: false,
    dataSource: detailInitialState,
    guestInfo: undefined,
    process: { status: false, action: "", message: "" }
}

export default (state: checkinMoreGuestState = initialState, action: actions.CheckinMoreGusetAction): checkinMoreGuestState => {
    switch (action.type) {
        case actions.CHECKIN_MORE_GUEST_DETAIL_REQUEST: {
            return {
                ...state,
                loader: true,
                dataSource: { ...detailInitialState },
                guestInfo: undefined,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.CHECKIN_MORE_GUEST_DATASOURCE_SUCCESS: {
            const { guestInfo = undefined, detailDataSource = detailInitialState } = action.payload
            return {
                ...state,
                loader: false,
                dataSource: detailDataSource,
                guestInfo: guestInfo
            }
        }
        case actions.CHECKIN_MORE_GUEST_SAVE: {
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.CHECKIN_MORE_GUEST_PROCESS_SUCCESS: {
            return {
                ...state,
                loader: false,
                process: action.payload
            }
        }

        case actions.CHECKIN_MORE_GUEST_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.CHECKIN_MORE_GUEST_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state;
    }
}