import * as actions from "appRedux/actions/chargeSchedule/ChargeReservationActionType"
import { chargeScheduleType } from "./chargeType"

export interface chargeReservationState {
    loader: boolean,
    keyword:string,
    dataSource: chargeScheduleType[],
    detailDataSource:any,
    filterChargeSchedule: any,
    process:any
}

const initialState: chargeReservationState = {
    loader: false,
    keyword:'',
    dataSource: [],
    detailDataSource:undefined,
    filterChargeSchedule: [],
    process:{ status:false,action:"",message:""}
}

export default (state: chargeReservationState = initialState,action: actions.ChargeAction): chargeReservationState =>{
    switch (action.type) {
    case actions.CHARGE_RESERVATION_REQUEST:{
        return {
            ...state,
            loader:true,
            dataSource:[],
            process: {status:false,action:"",message:""}
        }
    }
    case actions.CHARGE_RESERVATION_DETAIL_REQUEST:{
        return {
            ...state,
            detailDataSource:undefined,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.CHARGE_RESERVATION_UPDATE:{
        return {
            ...state,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.CHARGE_RESERVATION_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.CHARGE_RESERVATION_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.CHARGE_RESERVATION_DETAIL_DATASOURCE_SUCCESS:{
        return{
            ...state,
            detailDataSource: action.payload
        }
    }
    case actions.CHARGE_RESERVATION_SET_FILTER:{
        return{
            ...state,
            filterChargeSchedule:action.payload
        }
    }
    case actions.CHARGE_RESERVATION_PROCESS_RESET:{
        return{
            ...state,
            process:{ status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}