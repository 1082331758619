import { all, fork, put, takeEvery, call, select, delay } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/folioEditActionType"
import * as actionCreators from "appRedux/actions/cashier/folioEdit"
import { userSignOut } from "appRedux/actions/Auth"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { AppState } from "ReducerState"

function* folioEditRequestProcess({ payload }: actionTypes.FolioEditRequest) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/cashier/folio/${payload}/folioPattern/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
      const folioEditResponse = response.data
      /* if no error put everything to reducer */
      yield put(actionCreators.folioEditDataSourceSuccess(folioEditResponse))
      const processStatus = { status: true, action: "folioEditPreview", message: "" }
      yield put(actionCreators.folioEditProcessSuccess(processStatus))

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "folioEditPreview", message }
      yield put(actionCreators.folioEditProcessSuccess(processStatus))
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on folio edit request : ", error)
    const processStatus = { status: false, action: "folioEditPreview", message: error }
    yield put(actionCreators.folioEditProcessSuccess(processStatus))
  }
}

function* folioEditUpdateProcess({ payload }: actionTypes.FolioEditUpdate) {

  const { folioID, folioData, isApplyFolio } = payload

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/cashier/folio/${folioID}/folioPattern/update`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  const { dataSource } = yield select((state: AppState) => state.cashierFolioEdit)

  console.log(" Old data source ", dataSource)
  console.log(" New data source ", folioData)
  const { columns: { folio1, folio2, folio3, folio4 } } = folioData
  console.log("folio 1 ", folio1)
  console.log("folio 2 ", folio2)
  console.log("folio 3 ", folio3)
  console.log("folio 4 ", folio4)

  let modifierData = { ...dataSource,sync_transactions : isApplyFolio }
  const setFolioDepartment = (folioName: string, folioTitle: string, folioSeq: number, newDepartment: Array<{ id: number }>) => {
    modifierData = {
      ...modifierData,
      [folioName]: {
        ...modifierData[folioName],
        folio_name: folioTitle,
        folio_seq: folioSeq,
        departments: [...newDepartment]
      }
    }
  }

  if (folio1.title === "")
    modifierData = { ...modifierData, folio1: null }
  else
    setFolioDepartment('folio1', folio1.title, 1, folio1.taskIds.map((o: any) => ({ id: parseInt(o) })))

  if (folio2.title === "")
    modifierData = { ...modifierData, folio2: null }
  else
    setFolioDepartment('folio2', folio2.title, 2, folio2.taskIds.map((o: any) => ({ id: parseInt(o) })))

  if (folio3.title === "")
    modifierData = { ...modifierData, folio3: null }
  else
    setFolioDepartment('folio3', folio3.title, 3, folio3.taskIds.map((o: any) => ({ id: parseInt(o) })))

  if (folio4.title === "")
    modifierData = { ...modifierData, folio4: null }
  else
    setFolioDepartment('folio4', folio4.title, 4, folio4.taskIds.map((o: any) => ({ id: parseInt(o) })))
  //console.log("modified data source ", modifierData)

  /*
  const processStatus = { status: true, action: "folioEditUpdate", message: "Update folio success." }
  yield put(actionCreators.folioEditProcessSuccess(processStatus))
  yield delay(1000)
  yield put(actionCreators.folioEditProcessReset())
  */

  try {
    const response: ApiResponse = yield APIPostRequest(url, modifierData, API_PMS_HEADERS)
    if (response.status === 200) {

      const { result_code, result_msg } = response.data
      const processStatus = { status: true, action: "folioEditUpdate", message: result_msg, result_code }
      /* if no error put everything to reducer */
      yield put(actionCreators.folioEditProcessSuccess(processStatus))
      yield delay(1000)
      yield put(actionCreators.folioEditProcessReset())

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "folioEditUpdate", message }
      yield put(actionCreators.folioEditProcessSuccess(processStatus))
      yield delay(1000)
      yield put(actionCreators.folioEditProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  }
  catch (error) {
    console.log("Error on folio edit request : ", error)
    const processStatus = { status: false, action: "folioEditPreview", message: error }
    yield put(actionCreators.folioEditProcessSuccess(processStatus))
  }
}

function* FolioEditRequest() {
  yield takeEvery(actionTypes.FOLIO_EDIT_REQUEST, folioEditRequestProcess)
}

function* FolioEditUpdate() {
  yield takeEvery(actionTypes.FOLIO_EDIT_UPDATE, folioEditUpdateProcess)
}

export default function* rootSaga() {
  yield all([fork(FolioEditRequest), fork(FolioEditUpdate)])
}


