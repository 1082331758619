import { all, fork, put, takeLatest, call, delay } from "redux-saga/effects"
import { getItems } from "util/localStorage"
import * as actionTypes from "appRedux/actions/nightAudit/autoPostRoomType"
import * as actionCreators from "appRedux/actions/nightAudit/autoPostRoom"
import { userSignOut } from "appRedux/actions/Auth"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { ApiResponse } from "constants/ActionTypes"

import { chargeType } from "routes/main/nightAudit/autoPostRoom/autoPost"

type DataType = {
    key: React.ReactNode
    name: string,
    children?: chargeType[]
}

const autoPostDataInfo: DataType[] = [
    {
        key: `chargeIndividual`,
        name: 'Individual',
        children: []
    },
    {
        key: `chargeGroup`,
        name: 'Group',
        children: []
    },
]

function* autoPostRoomRequestProcess() {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/nightAudit/autopost/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    try {
        const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
        /*
        const { data } = autoPostData
        const { Individual, ChargeToBooking } = data
        autoPostDataInfo[0].children = Individual.map((o: any, key: number) => {
            return { key: `individual-${key}`, ...o }
        })
        autoPostDataInfo[1].children = ChargeToBooking.map((o: any, key: number) => {
            return { key: `group-${key}`, ...o }
        })
        */
        if (response.status === 200) {
            const { Individual, ChargeToBooking } = response.data
            autoPostDataInfo[0].children = Individual.map((o: any, key: number) => {
                return { key: `individual-${key}`, ...o }
            })
            autoPostDataInfo[1].children = ChargeToBooking.map((o: any, key: number) => {
                return { key: `group-${key}`, ...o }
            })
            /* if no error put everything to reducer */
            yield put(actionCreators.autoPostRoomDataSourceUpdate(autoPostDataInfo))

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "autoPostRequest", message }
            yield put(actionCreators.autoPostRoomProcessSuccess(processStatus))
            yield put(actionCreators.autoPostRoomProcessReset())
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }

    } catch (error) {
        console.log("Error on saga auto post request : ", error)
        const processStatus = { status: false, action: "autoPostRequest", message: error }
        yield put(actionCreators.autoPostRoomProcessSuccess(processStatus))
        yield put(actionCreators.autoPostRoomProcessReset())
    }

}

function* autoPostRoomRunProcess({ payload }: actionTypes.AutoPostRoomRun) {
 
    const { username, postdate } = payload
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/nightAudit/autopost/execute`

    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { username, postdate }

    try {
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        console.log("Auto post execute ", response)
        if (response.status === 200) {
            const {result_code,result_msg} = response.data

            /* if no error put everything to reducer */
            const processStatus = { status: true, action: "autoPostRunExecute", message:result_msg,result_code }
            yield delay(2000)
            yield put(actionCreators.autoPostRoomProcessSuccess(processStatus))
            yield put(actionCreators.autoPostRoomProcessReset())

        } else {
            console.log("Error : ", response)
            if (response.status === 403) {
                /* if status 403 force signout */
                yield put(userSignOut())
                return
            }
            /* Show error message and signout */
            const { message = "" } = response.data || {}
            const processStatus = { status: false, action: "autoPostRunExecute", message }
            yield put(actionCreators.autoPostRoomProcessSuccess(processStatus))
            yield put(actionCreators.autoPostRoomProcessReset())

        }

    } catch (error) {
        console.log("Error on saga auto post request : ", error)
        const processStatus = { status: false, action: "autoPostRunExecute", message: error }
        yield put(actionCreators.autoPostRoomProcessSuccess(processStatus))
        yield put(actionCreators.autoPostRoomProcessReset())
    }

}

/* Watcher Saga */
export function* autoPostRoomRequest() {
    yield takeLatest(actionTypes.AUTOPOST_ROOM_REQUEST, autoPostRoomRequestProcess)
}

export function* autoPostRoomRun() {
    yield takeLatest(actionTypes.AUTOPOST_ROOM_RUN, autoPostRoomRunProcess)
}

export default function* rootSaga() {
    yield all([fork(autoPostRoomRequest),
    fork(autoPostRoomRun)
    ])


}
