import * as actions from "./chargeScheduleActionType"

export const chargeSchedulePreview = (payload?: any): actions.ChargeScheduleRequest => {
  return {
    type: actions.CHARGE_SCHEDULE_REQUEST,
    payload
  }
}

export const chargeScheduleProcessSuccess = (payload:any):actions.ChargeScheduleProcessSuccess =>{
  return{
    type: actions.CHARGE_SCHEDULE_PROCESS_SUCCESS,
    payload
  }
}

export const chargeScheduleDataSourceSuccess = (payload:any):actions.ChargeScheduleDataSourceSuccess => {
  return {
    type: actions.CHARGE_SCHEDULE_DATASOURCE_SUCCESS,
    payload
  }
}
export const chargeScheduleProcessReset = () =>{
  return{
    type:actions.CHARGE_SCHEDULE_PROCESS_RESET
  } 
}
