import {
    END_DAY_PREVIEW,
    END_DAY_RUN,
    END_DAY_REALTIME_UPDATE,
    END_DAY_PROCESS_SUCCESS,
    END_DAY_DATASOURCE_SUCCESS,
    END_DAY_PROCESS_RESET
} from "constants/ActionTypes"

export const endDayPreview = () => {
    return {
    type: END_DAY_PREVIEW
    }
}

export const endDayRun = () =>{
    return {
    type:END_DAY_RUN
    }
}

export const endDayRealTimeUpdate = (value)=>{
    return {
        type:END_DAY_REALTIME_UPDATE,
        payload:value
    }
}

export const endDayProcessSuccess = (value) =>{
    return{
    type:END_DAY_PROCESS_SUCCESS,
    payload:value
    }
}

export const endDayDataSourceUpdate = (value) => {
    return {
    type: END_DAY_DATASOURCE_SUCCESS,
    payload: value
    }
}
export const endDayProcessReset = () =>{
    return{
    type:END_DAY_PROCESS_RESET
    }
}

