import {
  LISTBOX_ROOMTYPE,
  LISTBOX_ROOMTYPE_SUCCESS,
  LISTBOX_COUNTRY,
  LISTBOX_COUNTRY_SUCCESS,
  LISTBOX_CHANNEL_TYPE,
  LISTBOX_CHANNEL_TYPE_SUCCESS,
  LISTBOX_ORIGIN,
  LISTBOX_ORIGIN_SUCCESS,
  LISTBOX_SALES,
  LISTBOX_SALES_SUCCESS,
  LISTBOX_MARKET,
  LISTBOX_MARKET_SUCCESS,
  LISTBOX_NATION,
  LISTBOX_NATION_SUCCESS,
  LISTBOX_FOLIO,
  LISTBOX_FOLIO_SUCCESS,
  LISTBOX_ROOMGROUP,
  LISTBOX_ROOMGROUP_SUCCESS,
  LISTBOX_INCLUSION,
  LISTBOX_INCLUSION_SUCCESS,
  LISTBOX_RATEPLAN,
  LISTBOX_RATEPLAN_SUCCESS,
  LISTBOX_VIP,
  LISTBOX_VIP_SUCCESS,
  LISTBOX_FACILITY,
  LISTBOX_FACILITY_SUCCESS,
  LISTBOX_BEDTYPE,
  LISTBOX_BEDTYPE_SUCCESS,
  LISTBOX_BUILDING,
  LISTBOX_BUILDING_SUCCESS,
  LISTBOX_LOCATION,
  LISTBOX_LOCATION_SUCCESS,
  LISTBOX_EXPOSURE,
  LISTBOX_EXPOSURE_SUCCESS,
  LISTBOX_ROOMINFO,
  LISTBOX_ROOMINFO_SUCCESS,
  LISTBOX_RESERVATION_TYPE,
  LISTBOX_RESERVATION_TYPE_SUCCESS,
  LISTBOX_TITLENAME,
  LISTBOX_TITLENAME_SUCCESS,
  LISTBOX_OCCUPATION,
  LISTBOX_OCCUPATION_SUCCESS,
  LISTBOX_OUT_OF_ORDER,
  LISTBOX_OUT_OF_ORDER_SUCCESS,
  LISTBOX_CITY,
  LISTBOX_CITY_SUCCESS,
  LISTBOX_SOCIAL,
  LISTBOX_SOCIAL_SUCCESS,
  LISTBOX_LANGUAGE,
  LISTBOX_LANGUAGE_SUCCESS,
  LISTBOX_CHANNELS,
  LISTBOX_CHANNELS_SUCCESS,
  LISTBOX_MEDIA,
  LISTBOX_MEDIA_SUCCESS,
  LISTBOX_ALERT_PAGE,
  LISTBOX_ALERT_PAGE_SUCCESS,
  LISTBOX_FOLLOW_UP_CATEGORY,
  LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS,
  LISTBOX_FOLLOW_UP_STATUS,
  LISTBOX_FOLLOW_UP_STATUS_SUCCESS,
  LISTBOX_FOLLOW_UP_ASSIGN_USER,
  LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS,
  LISTBOX_USER,
  LISTBOX_USER_SUCCESS,
  LISTBOX_MEDIA_GROUP,
  LISTBOX_MEDIA_GROUP_SUCCESS,
  LISTBOX_DEPARTMENT,
  LISTBOX_DEPARTMENT_SUCCESS,
  LISTBOX_DEPARTMENT_GROUP,
  LISTBOX_DEPARTMENT_GROUP_SUCCESS,
  LISTBOX_CHANNEL_GROUP,
  LISTBOX_CHANNEL_GROUP_SUCCESS,
  LISTBOX_FOLIO_DETAIL,
  LISTBOX_FOLIO_DETAIL_SUCCESS,
  LISTBOX_FOLIO_PAYMENT_CODE,
  LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS,
  LISTBOX_ITEMIZER,
  LISTBOX_ITEMIZER_SUCCESS,
  RESET_STATE
} from "constants/ActionTypes";

  const INIT_STATE = {
          roomTypeList: [],
          countryList:[],
          channelsList:[],
          channelTypeList:[],
          channelGroupList:[],
          originList:[],
          salesList:[],
          marketList:[],
          nationList:[],
          folioList:[],
          roomGroupList:[],
          inclusionList:[],
          ratePlanList:[],
          vipList:[],
          facilityList:[],
          bedTypeList:[],
          buildingList:[],
          locationList:[],
          exposureList:[],
          roomInfoList:[],
          reservationTypeList:[],
          titleNameList:[],
          occupationList:[],
          outOfOrderList:[],
          cityList:[],
          socialList:[],
          languageList:[],
          mediaList:[],
          mediaGroupList:[],
          alertPageList:[],
          followUpCategoryList:[],
          followUpStatusList:[],
          followUpAssignUserList:[],
          userList:[],
          departmentList:[],
          departmentGroupList:[],
          folioDetailList:[],
          folioPaymentCodeList:[],
          itemizerList:[],
          processStatus:false
        }

  export default (state = INIT_STATE, action) => {
    switch (action.type) {
      case RESET_STATE: {
        return {
          roomTypeList: [],
          countryList: [],
          channelsList:[],
          channelTypeList: [],
          channelGroupList:[],
          originList: [],
          salesList: [],
          marketList: [],
          nationList: [],
          folioList: [],
          roomGroupList: [],
          inclusionList: [],
          ratePlanList: [],
          vipList: [],
          facilityList:[],
          bedTypeList:[],
          buildingList:[],
          locationList:[],
          exposureList:[],
          roomInfoList:[],
          reservationTypeList:[],
          titleNameList:[],
          occupationList:[],
          outOfOrderList:[],
          cityList:[],
          socialList:[],
          languageList:[],
          mediaList:[],
          mediaGroupList:[],
          alertPageList:[],
          followUpCategoryList:[],
          followUpStatusList:[],
          followUpAssignUserList:[],
          userList:[],
          departmentList:[],
          departmentGroupList:[],
          folioDetailList:[],
          folioPaymentCodeList:[],
          itemizerList:[],
          processStatus: false
        }
      }
      case LISTBOX_ROOMTYPE: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_ROOMTYPE_SUCCESS: {
        return {
          ...state,
          roomTypeList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_COUNTRY: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_COUNTRY_SUCCESS: {
        return {
          ...state,
          countryList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_CHANNEL_TYPE: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_CHANNEL_TYPE_SUCCESS: {
        return {
          ...state,
          channelTypeList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_CHANNEL_GROUP: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_CHANNEL_GROUP_SUCCESS: {
        return {
          ...state,
          channelGroupList: action.payload,
          processStatus: true
        }
      }
      
      case LISTBOX_ORIGIN: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_ORIGIN_SUCCESS: {
        return {
          ...state,
          originList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_SALES: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_SALES_SUCCESS: {
        return {
          ...state,
          salesList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_MARKET: {
        return {
          ...state,
          processStatus: false
        }
      }
      case LISTBOX_MARKET_SUCCESS: {
        return {
          ...state,
          marketList: action.payload,
          processStatus: true
        }
      }
      case LISTBOX_NATION:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_NATION_SUCCESS:{
        return{
          ...state,
          nationList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLIO:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLIO_SUCCESS:{
        return{
          ...state,
          folioList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_ROOMGROUP:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_ROOMGROUP_SUCCESS:{
        return{
          ...state,
          roomGroupList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_INCLUSION:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_INCLUSION_SUCCESS:{
        return{
          ...state,
          inclusionList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_RATEPLAN:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_RATEPLAN_SUCCESS:{
        return{
          ...state,
          ratePlanList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_VIP:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_VIP_SUCCESS:{
        return{
          ...state,
          vipList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FACILITY:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FACILITY_SUCCESS:{
        return{
          ...state,
          facilityList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_BEDTYPE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_BEDTYPE_SUCCESS:{
        return{
          ...state,
          bedTypeList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_BUILDING:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_BUILDING_SUCCESS:{
        return{
          ...state,
          buildingList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_LOCATION:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_LOCATION_SUCCESS:{
        return{
          ...state,
          locationList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_EXPOSURE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_EXPOSURE_SUCCESS:{
        return{
          ...state,
          exposureList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_ROOMINFO:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_ROOMINFO_SUCCESS:{
        return{
          ...state,
          roomInfoList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_RESERVATION_TYPE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_TITLENAME:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_RESERVATION_TYPE_SUCCESS:{
        return{
          ...state,
          reservationTypeList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_TITLENAME_SUCCESS:{
        return{
          ...state,
          titleNameList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_OCCUPATION:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_OCCUPATION_SUCCESS:{
        return{
          ...state,
          occupationList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_OUT_OF_ORDER:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_OUT_OF_ORDER_SUCCESS:{
        return{
          ...state,
          outOfOrderList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_CITY:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_CITY_SUCCESS:{
        return{
          ...state,
          cityList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_SOCIAL:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_SOCIAL_SUCCESS:{
        return{
          ...state,
          socialList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_LANGUAGE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_LANGUAGE_SUCCESS:{
        return{
          ...state,
          languageList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_CHANNELS:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_CHANNELS_SUCCESS:{
        return{
          ...state,
          channelsList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_MEDIA:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_MEDIA_SUCCESS:{
        return{
          ...state,
          mediaList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_MEDIA_GROUP:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_MEDIA_GROUP_SUCCESS:{
        return{
          ...state,
          mediaGroupList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_ALERT_PAGE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_ALERT_PAGE_SUCCESS:{
        return{
          ...state,
          alertPageList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLLOW_UP_CATEGORY:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS:{
        return{
          ...state,
          followUpCategoryList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLLOW_UP_STATUS:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLLOW_UP_STATUS_SUCCESS:{
        return{
          ...state,
          followUpStatusList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLLOW_UP_ASSIGN_USER:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS:{
        return{
          ...state,
          followUpAssignUserList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_USER:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_USER_SUCCESS:{
        return{
          ...state,
          userList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_DEPARTMENT:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_DEPARTMENT_SUCCESS:{
        return{
          ...state,
          departmentList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_DEPARTMENT_GROUP:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_DEPARTMENT_GROUP_SUCCESS:{
        return{
          ...state,
          departmentGroupList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLIO_DETAIL:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLIO_DETAIL_SUCCESS:{
        return{
          ...state,
          folioDetailList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_FOLIO_PAYMENT_CODE:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS:{
        return{
          ...state,
          folioPaymentCodeList:action.payload,
          processStatus:true
        }
      }
      case LISTBOX_ITEMIZER:{
        return{
          ...state,
          processStatus:false
        }
      }
      case LISTBOX_ITEMIZER_SUCCESS:{
        return{
          ...state,
          itemizerList:action.payload,
          processStatus:true
        }
      }
      default:
        return state
    }
  }