import { SettingsAction, LocaleSettingsState, SWITCH_LANGUAGE, TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH } from "constants/ActionTypes"
import { LAYOUT_TYPE, NAV_STYLE, THEME_COLOR, THEME_TYPE, SET_PATH_NAME } from "../../constants/ThemeSetting"


export const toggleCollapsedSideNav = (navCollapsed: boolean): SettingsAction => {
  return {
      type: TOGGLE_COLLAPSED_NAV, 
      navCollapsed
    }
}

export const updateWindowWidth = (width: number): SettingsAction => {
  return {
      type: WINDOW_WIDTH, 
      width
    }
}

export const setThemeType = (themeType: string): SettingsAction => {
  return {
      type: THEME_TYPE, 
      themeType
  }
}

export const setThemeColor = (themeColor: string): SettingsAction => {
  console.log("ms",themeColor)
  return {
    type: THEME_COLOR, 
    themeColor
  }
}

export const onNavStyleChange = (navStyle: string): SettingsAction => {
  return {
    type: NAV_STYLE, 
    navStyle
  }
}

export const onLayoutTypeChange = (layoutType: string): SettingsAction => {
  return {
    type: LAYOUT_TYPE, layoutType
  }
}

export function setPathName(pathname: string): SettingsAction {
  return {
    type: SET_PATH_NAME, 
    payload: pathname
  }
}

export function switchLanguage(locale: LocaleSettingsState): SettingsAction<string, LocaleSettingsState> {
  return {
      type: SWITCH_LANGUAGE,
      payload: locale
    
  }
}