export const MEALTYPE_PREVIEW = "MEALTYPE_PREVIEW"
export const MEALTYPE_NEW = "MEALTYPE_NEW"
export const MEALTYPE_UPDATE = "MEALTYPE_UPDATE"
export const MEALTYPE_REMOVE = "MEALTYPE_REMOVE"
export const MEALTYPE_PROCESS_SUCCESS = "MEALTYPE_PROCESS_SUCCESS"
export const MEALTYPE_DATASOURCE_SUCCESS = "MEALTYPE_DATASOURCE_SUCCESS"

export const MEALTYPE_PROCESS_RESET = "MEALTYPE_PROCESS_RESET"

export const MEALTYPE_SWITCH_ENABLED = "MEALTYPE_SWITCH_ENABLED"


export interface MealTypeRequest {
  type: typeof MEALTYPE_PREVIEW,
  payload?:any
}
export interface MealTypeNew {
  type: typeof MEALTYPE_NEW,
  payload?:any
}
export interface MealTypeUpdate {
  type: typeof MEALTYPE_UPDATE,
  payload?: any
}
export interface MealTypeRemove{
  type: typeof MEALTYPE_REMOVE,
  payload:any
}
export interface MealTypeDataSouceSuccess{
  type: typeof MEALTYPE_DATASOURCE_SUCCESS,
  payload:any
}
export interface MealTypeProcessSuccess {
  type: typeof MEALTYPE_PROCESS_SUCCESS,
  payload:any
}

export interface MealTypeSwitchEnabled{
  type: typeof MEALTYPE_SWITCH_ENABLED,
  payload:any
}

export interface MealTypeProcessReset{
  type: typeof MEALTYPE_PROCESS_RESET
}

export type MealTypeAction = 
| MealTypeRequest
| MealTypeNew
| MealTypeUpdate
| MealTypeRemove
| MealTypeDataSouceSuccess
| MealTypeProcessSuccess
| MealTypeSwitchEnabled
| MealTypeProcessReset