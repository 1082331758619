import { all, fork, put, takeEvery, call, select } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/setup/report/BatchReportActionType"
import * as actionCreators from "appRedux/actions/setup/report/BatchReport"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { batchReportDataType, batchReportDetailType, reportOptionType } from "appRedux/reducers/setup/report/BatchReport"
import { AppState } from "ReducerState"

function* batchReportRequestProcess({ payload }: actionTypes.BatchReportRequest) {

    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = payload ? `${API_URL_V1}pms/report/batch/setup?active=true` : `${API_URL_V1}pms/report/batch/setup`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
            const batchReportInfo: batchReportDataType[] = response.data
            /* if no error put everything to reducer */
            yield put(actionCreators.batchReportDataSourceSuccess(batchReportInfo))

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "batchReportPreview", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            if (response.status === 403) {
                //if data is not null force signout 
                if (data)
                    yield put(userSignOut())
            }

        }
    } catch (error) {
        console.log("Error on batch report preview : ", error)
        const processStatus = { status: false, action: "batchReportPreview", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
    }
}

function* batchReportDetailRequestProcess({ payload }: actionTypes.BatchReportDetailRequest) {

    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token

    const url = `${API_URL_V1}pms/report/batch/setup/form?batch_id=${payload?.batchID}&action=${payload?.action}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
            const batchReportInfo = response.data
            /* if no error put everything to reducer */
            if (payload?.action === "edit")
                yield put(actionCreators.batchReportDetailDataSourceSuccess(batchReportInfo))
            else if (payload?.action === "view")
                yield put(actionCreators.batchReportDetailViewModeDataSourceSuccess(batchReportInfo))
        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const action = payload?.action === "edit" ? "batch_report_get_detail" : "batch_report_viewmode"

            const processStatus = { status: false, action, message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                //if data is not null force signout 
                if (data)
                    yield put(userSignOut())
            }

        }
    } catch (error) {
        console.log("Error on get batch report detail folio : ", error)
        const action = payload?.action === "edit" ? "batch_report_get_detail" : "batch_report_viewmode"
        const processStatus = { status: false, action, message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportCreateProcess({ payload }: actionTypes.BatchReportNew) {

    const batchReportInfo: batchReportDetailType = payload

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/create`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batchReportInfo }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "createBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "createBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on create batch report : ", error)
        const processStatus = { status: false, action: "createBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportUpdateProcess({ payload }: actionTypes.BatchReportNew) {

    const batchReportInfo: batchReportDetailType = payload

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/update`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batchReportInfo }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "updateBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "updateBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on update batch report : ", error)
        const processStatus = { status: false, action: "updateBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportDuplicateProcess({ payload }: actionTypes.BatchReportDuplicate) {
    const { duplicateInfo, indexObj } = payload

    let reportReform = { ...duplicateInfo, report_name: duplicateInfo.report_name_suffix }
    delete reportReform.report_name_suffix

    const currentState: AppState = yield select((state: AppState) => state)
    const batchReportTemp: batchReportDetailType = currentState.batchReport.detailViewModeDataSource

    let reportInfo = {} as any
    switch (indexObj) {
        case "financial_reports":
            reportInfo = { ...batchReportTemp, financial_reports: [...batchReportTemp.financial_reports, reportReform] }
            break
        case "reservation_reports":
            reportInfo = { ...batchReportTemp, reservation_reports: [...batchReportTemp.reservation_reports, reportReform] }
            break
        case "registration_reports":
            reportInfo = { ...batchReportTemp, registration_reports: [...batchReportTemp.registration_reports, reportReform] }
            break
        case "marketing_reports":
            reportInfo = { ...batchReportTemp, marketing_reports: [...batchReportTemp.marketing_reports, reportReform] }
            break
        default:
            reportInfo = { ...batchReportTemp }
            break
    }

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/update`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batchReportInfo: reportInfo }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "duplicateBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "duplicateBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on duplicate report : ", error)
        const processStatus = { status: false, action: "duplicateBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportRenameProcess({ payload }: actionTypes.BatchReportDuplicate) {
    const { batchRenameInfo, indexObj } = payload

    let reportReform = { ...batchRenameInfo, report_name: batchRenameInfo.report_name_suffix }
    delete reportReform.report_name_suffix

    const currentState: AppState = yield select((state: AppState) => state)
    const batchReportTemp: batchReportDetailType = currentState.batchReport.detailViewModeDataSource

    let reportInfo = {} as any
    switch (indexObj) {
        case "financial_reports":
            const financialReform = batchReportTemp.financial_reports.filter((o: any) => o.seq !== reportReform.seq)
            reportInfo = { ...batchReportTemp, financial_reports: [...financialReform, reportReform] }
            break
        case "reservation_reports":
            const reservationReform = batchReportTemp.reservation_reports.filter((o: any) => o.seq !== reportReform.seq)
            reportInfo = { ...batchReportTemp, reservation_reports: [...reservationReform, reportReform] }
            break
        case "registration_reports":
            const registerationReform = batchReportTemp.registration_reports.filter((o: any) => o.seq !== reportReform.seq)
            reportInfo = { ...batchReportTemp, registration_reports: [...registerationReform, reportReform] }
            break
        case "marketing_reports":
            const marketingReform = batchReportTemp.marketing_reports.filter((o: any) => o.seq !== reportReform.seq)
            reportInfo = { ...batchReportTemp, marketing_reports: [...marketingReform, reportReform] }
            break
        default:
            reportInfo = { ...batchReportTemp }
            break
    }

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/update`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batchReportInfo: reportInfo }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "renameBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "renameBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on rename duplicate report : ", error)
        const processStatus = { status: false, action: "renameBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportRemoveProcess({ payload }: actionTypes.BatchReportRemove) {

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/delete`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batch_id: payload }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "deleteBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "deleteBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on delete batch report : ", error)
        const processStatus = { status: false, action: "deleteBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportOptionRequestProcess({ payload }: actionTypes.BatchReportOptionRequest) {

    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/report/batch/setup/${payload}/options/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
        if (response.status === 200) {
            const batchReportInfo: reportOptionType[] = response.data
            /* if no error put everything to reducer */
            yield put(actionCreators.batchReportOptionRequestSuccess(batchReportInfo))

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "batchReportOptionPreview", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            if (response.status === 403) {
                //if data is not null force signout 
                if (data)
                    yield put(userSignOut())
            }

        }
    } catch (error) {
        console.log("Error on get batch report option : ", error)
        const processStatus = { status: false, action: "batchReportOptionPreview", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
    }


}
function* batchReportSaveOptionProcess({ payload }: actionTypes.BatchReportSaveOption) {
    const { item_id, optionValue, keyIndex } = payload
    const reportOptionInfo = {} as any
    reportOptionInfo.item_id = item_id
    reportOptionInfo.optionValue = optionValue
    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/options/save`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { ...reportOptionInfo }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "saveBatchReportOption", message: result_msg, result_code, keyIndex }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "saveBatchReportOption", message, keyIndex }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on create batch report : ", error)
        const processStatus = { status: false, action: "saveBatchReportOption", message: error, keyIndex }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }

}
function* batchReportRunProcess({ payload }: actionTypes.BatchReportRun) {
    yield console.log("Saga batch id ", payload)

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/run`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {
            batchID: payload.batchID,
            userName: payload.userName
        }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { message } = response
            const processStatus = { status: true, action: "batchReportRun", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "batchReportRun", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on run batch report : ", error)
        const processStatus = { status: false, action: "batchReportRun", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}

function* batchReportSwitchProcess({ payload }: actionTypes.BatchReportSwitch) {

    const { id, enabled } = payload

    try {
        const { access_token } = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/report/batch/setup/update`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = { batchReportInfo:{ id, enabled} }
        const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

        if (response.status === 200) {

            const { result_code, result_msg } = response.data
            const processStatus = { status: true, action: "switchBatchReport", message: result_msg, result_code }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())

        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "switchBatchReport", message }
            yield put(actionCreators.batchReportProcessSuccess(processStatus))
            yield put(actionCreators.batchReportProcessReset())
            if (response.status === 403) {
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on update batch report : ", error)
        const processStatus = { status: false, action: "updateBatchReport", message: error }
        yield put(actionCreators.batchReportProcessSuccess(processStatus))
        yield put(actionCreators.batchReportProcessReset())
    }
}


function* batchReportRequest() {
    yield takeEvery(actionTypes.BATCH_REPORT_REQUEST, batchReportRequestProcess)
}
function* batchReportDetailRequest() {
    yield takeEvery(actionTypes.BATCH_REPORT_DETAIL_REQUEST, batchReportDetailRequestProcess)
}
function* batchReportCreate() {
    yield takeEvery(actionTypes.BATCH_REPORT_NEW, batchReportCreateProcess)
}
function* batchReportUpdate() {
    yield takeEvery(actionTypes.BATCH_REPORT_UPDATE, batchReportUpdateProcess)
}
function* batchReportRemove() {
    yield takeEvery(actionTypes.BATCH_REPORT_REMOVE, batchReportRemoveProcess)
}
function* batchReportDuplicate() {
    yield takeEvery(actionTypes.BATCH_REPORT_DUPLICATE, batchReportDuplicateProcess)
}
function* batchReportRename() {
    yield takeEvery(actionTypes.BATCH_REPORT_RENAME, batchReportRenameProcess)
}
function* batchReportOptionRequest() {
    yield takeEvery(actionTypes.BATCH_REPORT_OPTION_REQUEST, batchReportOptionRequestProcess)
}
function* batchReportSaveOption() {
    yield takeEvery(actionTypes.BATCH_REPORT_SAVE_OPTION, batchReportSaveOptionProcess)
}
function* batchReportRun() {
    yield takeEvery(actionTypes.BATCH_REPORT_RUN, batchReportRunProcess)
}
function* batchReportSwitch() {
    yield takeEvery(actionTypes.BATCH_REPORT_SWITCH, batchReportSwitchProcess)
}

export default function* rootSaga() {
    yield all([
        fork(batchReportRequest),
        fork(batchReportDetailRequest),
        fork(batchReportCreate),
        fork(batchReportUpdate),
        fork(batchReportRemove),
        fork(batchReportDuplicate),
        fork(batchReportRename),
        fork(batchReportOptionRequest),
        fork(batchReportSaveOption),
        fork(batchReportRun),
        fork(batchReportSwitch)
    ])
}


