// eslint-disable-next-line
import React, { useState,useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, message } from "antd"
import { Link } from 'react-router-dom'
import { Formik } from "formik"
import { Form,Input} from "formik-antd"
import * as yup from 'yup'
import {hideMessage,resetPassword,resetProcessStatus} from "appRedux/actions/Auth"
import { decode } from 'hi-base32'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import { AppState } from 'ReducerState'

const Activateuser = ({history,location}:any)=>{
  
  const params = new URLSearchParams(location.search)
  
  const paramsActivate:any = {uid:params.get('uid'),
                              p:params.get("p"),
                              gt:params.get("gt"),
                              action:params.get("ac")}

  const [initialData,setInitialData] = useState<any>({uid:undefined,gt:undefined,action:undefined})
  const [regInitialData,setRegInitialData] = useState<{message?:string,reg:RegExp,min:number,max:number}>({message:undefined,reg:/(?=(.*\d){0})(?=(.*[a-z]){0})(?=(.*[A-Z]){0})(?=(.*[!@#$%]){0})/,min:0,max:20})
  const [loader,setLoader] = useState(false)

  const { auth } = useSelector((state:AppState)=> state) 
  const { showMessage, alertMessage,processStatus } = auth

  const dispatch = useDispatch()
  const checkRefresh = useRef<any>(true)
  
  useEffect(()=>{
		
    if(processStatus){
      setLoader(false)
      message.success("Success.")
      dispatch(resetProcessStatus())
      setTimeout(() => { dispatch(hideMessage())}, 100)
      setTimeout(() =>{ history.push('/')},500)
    }else if(showMessage){
    //  message.error(alertMessage.toString())
      message.error((alertMessage as any).toString())
      setTimeout(() => { dispatch(hideMessage())}, 100)
      setLoader(false)
    }
    
  },[processStatus,dispatch,showMessage,alertMessage,history])

  useEffect(()=>{
      if(checkRefresh.current){
        const splitArray = paramsActivate.uid.split(" ")
        const fullKey = splitArray.join("+")
        setInitialData({uid:fullKey,
                        gt:paramsActivate.gt,
                        action:paramsActivate.action})
        const splitParameter = decode(paramsActivate.p).split("|")
        /*
        console.log("Message : ",splitParameter[0])
        console.log("Reg : ",splitParameter[1])
        console.log("Min : ",splitParameter[2])
        console.log("Max : ",splitParameter[3])
        */
        const reg = new RegExp(splitParameter[1])
        setRegInitialData({message:splitParameter[0],reg,min:parseInt(splitParameter[2]),max:parseInt(splitParameter[3])})
        checkRefresh.current = false
      }
  },[paramsActivate])

  const handleSubmit = (value:any) => {
    setLoader(true)
    dispatch(resetPassword(value))
  }

  let schema = yup.object({
    password:yup.string().required(regInitialData.message)
    .min(regInitialData.min)
    .max(regInitialData.max).matches(regInitialData.reg),
    confirm:yup.string().required(regInitialData.message)
    .min(regInitialData.min)
    .max(regInitialData.max).matches(regInitialData.reg).oneOf([yup.ref("password")],"Password must match")
})

  return(
    <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
            <Link to="/"><img src={require("assets/images/logo-white.png")} alt="fromas" title="fromas"/></Link>
          </div>
          <div className="gx-mb-4">
            <h2>Setup Your Password</h2>
            <p>Enter a password for your account</p>
          </div>
          <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >  
            <Form className="gx-login-form gx-form-row0" layout='vertical'>
            <Form.Item name="password" label="Password">
                <Input.Password name='password' 
                              type="password" 
                              placeholder="Password" 
                              iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}/>
            </Form.Item>
            <Form.Item name="confirm" label={"Confirm password"}>
                <Input.Password name='confirm' 
                      type="password" 
                      placeholder="Confirm password"
                      iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                      />
            </Form.Item>
              <Input name="uid" type="hidden"/>
              <Input name="action" type="hidden"/>
              <Input name="gt" type="hidden"/>
              <Button type="primary" htmlType="submit" loading={loader} >Submit</Button>
          </Form>
          </Formik>
        </div>
      </div>
  )

}

export default Activateuser
