import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Layout, Popover, Tooltip, Badge, Breadcrumb } from "antd"
import { Link } from "react-router-dom"

import CustomScrollbars from "util/CustomScrollbars"
import languageData from "./languageData"
import { switchLanguage, toggleCollapsedSideNav } from '../../appRedux/actions/setting-actions'
import UserInfo from "components/UserInfo"
import { userSignOut } from "appRedux/actions/Auth"
import MessageNotification from "components/MessageNotification"

//import { notificationPreview } from "appRedux/actions/Notifications"
import { NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE, TAB_SIZE_MINI } from "../../constants/ThemeSetting"

import { Navi } from "routes/main/route"
import OnlineTag from "./Tags/OnlineTag"
import SystemDateTag from "./Tags/SystemDateTag"
import CurrentTag from "./Tags/CurrentTag"

import * as signalR from '@microsoft/signalr'

const { Header } = Layout

const Topbar = ({ history }) => {

  const dispatch = useDispatch()
  const { notifications, auth: { authUser }, currentLocation: { markLocations }, systemConfig } = useSelector(state => state)

  if (!authUser)
    dispatch(userSignOut())

  const hotelName = authUser?.prop_name
  const prop_code = authUser?.prop_code
  const user_name = authUser?.user_name

  const { dataSource } = notifications
  const { dataSource: { SYSTEM } } = systemConfig

  const { locale, navStyle } = useSelector(({ settings }) => settings)
  const { navCollapsed, width } = useSelector(({ common }) => common)
  const [popOverToggle, setPopOverToggle] = useState(false)

  const RouteNavigator = Navi(history.location.pathname)
  /*
  useEffect(() => {
    dispatch(notificationPreview())
  }, [dispatch])*/

  useEffect(() => {
    // Initialize SignalR connection
    const newConnection = new signalR.HubConnectionBuilder().withUrl(process.env.REACT_APP_REALTIME_URL, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    }).build()

    // Start the connection
    newConnection
      .start()
      .then(() => {
        console.log('เชื่อมต่อท่อสำเร็จ')
      })
      .catch((err) => {
        console.error('มีปัญหาในการเชื่อมต่อท่อ: ' + err)
        return
      })


      //Handling incoming messages
      newConnection.on(`test-aonestar-${prop_code}-${user_name}`, (result) => {
        console.log("result :",result)
      })
      
      newConnection.on('endsdayMsg', (result) => {
        console.log('Station 1 รับข้อมูลจาก Server:', result);
      })

      return () => {
        newConnection.stop().then(() => console.log('Disconnected from SignalR hub'));
      }
  },[prop_code,user_name])

  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            dispatch(switchLanguage(language))
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>)

  const handleVisibleChange = (value) => {
    setPopOverToggle(value)
  }

  const handleClose = () => {
    setPopOverToggle(false)
  }

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
        <div className="gx-linebar">
          <i className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/w-logo.png")} /></Link>
      <div style={{ fontSize: "1.6em", fontStyle: "italic", color: "steelblue" }}>{hotelName}
        {RouteNavigator.length > 1 ? (
          <Breadcrumb style={{ fontSize: 12, marginTop: 2 }}>
            {RouteNavigator.map((obj, index) => (
              <Breadcrumb.Item key={obj.pathName}>
                {markLocations.includes(history.location.pathname) ? obj.title : <Link to={obj.linkTo}>{obj.title}</Link>}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>) : null}
      </div>
      <ul className="gx-header-notifications gx-ml-auto">
        {width >= TAB_SIZE ? null : <>
          {/*
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" 
                      content={<MessageNotification onHandleClose={handleClose} />}
                      trigger="click"
                      visible={popOverToggle}
                      onVisibleChange={handleVisibleChange}
                      >
                    <span className="gx-pointer gx-d-block">
                      <Tooltip title={dataSource.length}>
                        <i className="icon icon-notification" />
                        <Badge title={dataSource.length} count={dataSource.length}></Badge>
                      </Tooltip>
                    </span>
              </Popover>
            </li>
          </Auxiliary>
        */}
        </>
        }
        {width <= TAB_SIZE_MINI ? null : <>
          <li className="gx-msg" style={{ marginRight: "2px" }}>
            <CurrentTag />
          </li>
          {SYSTEM?.ONLINE_POST &&
            <li className="gx-msg" style={{ marginRight: "2px" }}>
              <OnlineTag />
            </li>
          }
          <li className="gx-msg" style={{ marginRight: "2px" }}>
            <SystemDateTag />
          </li>
          <li className="gx-msg" style={{ marginRight: "3px" }}>
            |
          </li>
        </>
        }
        <li className="gx-notify" style={{ marginRight: 10 }}>
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight"
            content={<MessageNotification onHandleClose={handleClose} />}
            trigger="click"
            open={popOverToggle}
            onOpenChange={handleVisibleChange}
          >
            <span className="gx-pointer gx-d-block">
              <Tooltip title={dataSource.length}>
                <i className="icon icon-notification" />
                <Badge title={dataSource.length} count={dataSource.length}></Badge>
              </Tooltip>
            </span>
          </Popover>
        </li>
        <li className="gx-language" style={{ marginRight: 10 }}>
          <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={languageMenu()}
            trigger="click">
            <span className="gx-pointer gx-flex-row gx-align-items-center">
              <i className={`flag flag-24 flag-${locale.icon}`} />
            </span>
          </Popover>
        </li>
        <li className="gx-user-nav"><UserInfo /></li>
      </ul>
    </Header>
  )
}

export default Topbar