import {
    FOLIO_PATTERN_DETAIL_PREVIEW,
    FOLIO_PATTERN_DETAIL_NEW,
    FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS,
    FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS,
    FOLIO_PATTERN_DETAIL_SYNC_CONFIRM,
    FOLIO_PATTERN_DETAIL_PROCESS_RESET
  } from "constants/ActionTypes"

  export const folioPatternDetailPreview = (value) => {
    return {
      type: FOLIO_PATTERN_DETAIL_PREVIEW,
      payload:value
    }
  }

  export const folioPatternDetailCreate =(value) =>{
    return {
      type:FOLIO_PATTERN_DETAIL_NEW,
      payload:value
    }
  }

  export const folioPatternDetailProcessSuccess = (value) =>{
    return{
      type:FOLIO_PATTERN_DETAIL_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const folioPatternDetailDataSourceUpdate = (value) => {
    return {
      type: FOLIO_PATTERN_DETAIL_DATASOURCE_SUCCESS,
      payload: value
    }
  }

  export const folioPatternSyncConfirm = (value) =>{
    return {
      type:FOLIO_PATTERN_DETAIL_SYNC_CONFIRM,
      payload:value
    }
  }
  export const folioPatternDetailProcessReset = () =>{
    return{
      type:FOLIO_PATTERN_DETAIL_PROCESS_RESET
    }
  }

