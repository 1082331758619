import {
    LANGUAGE_PREVIEW,
    LANGUAGE_NEW,
    LANGUAGE_UPDATE,
    LANGUAGE_REMOVE,
    LANGUAGE_SEARCH,
    LANGUAGE_PROCESS_SUCCESS,
    LANGUAGE_DATASOURCE_SUCCESS,
    LANGUAGE_PROCESS_RESET
  } from "constants/ActionTypes"

  export const languagePreview = (value) => {
    return {
      type: LANGUAGE_PREVIEW,
      payload:value
    }
  }

  export const languageCreate =(value) =>{
    return {
      type:LANGUAGE_NEW,
      payload:value
    }
  }

  export const languageUpdate = (value) =>{
    return {
      type:LANGUAGE_UPDATE,
      payload: value
    }
  }

  export const languageRemove = (value)=>{
    return {
      type:LANGUAGE_REMOVE,
      payload:value
    }
  }

  export const languageSearch = (keyword) =>{
    return {
      type: LANGUAGE_SEARCH,
      payload:keyword
    }
  }

  export const languageProcessSuccess = (value) =>{
    return{
      type:LANGUAGE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const languageDataSourceUpdate = (value) => {
    return {
      type: LANGUAGE_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const languageProcessReset = () =>{
    return{
      type:LANGUAGE_PROCESS_RESET
    }
  }

