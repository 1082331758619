
export const GUEST_ISSUE_CARD_REQUEST = "GUEST_ISSUE_CARD_REQUEST"
export const GUEST_ISSUE_CARD_DATASOURCE_SUCCESS = "GUEST_ISSUE_CARD_DATASOURCE_SUCCESS"
export const GUEST_ISSUE_CARD_PROCESS_SUCCESS = "GUEST_ISSUE_CARD_PROCESS_SUCCESS"
export const GUEST_ISSUE_CARD_PROCESS_RESET = "GUEST_ISSUE_CARD_PROCESS_RESET"
export const GUEST_ISSUE_CARD_RESET = "GUEST_ISSUE_CARD_RESET"

export const GUEST_ISSUE_CARD_NEW_CARD = "GUEST_ISSUE_CARD_NEW_CARD"
export const GUEST_ISSUE_CARD_COPY_CARD = "GUEST_ISSUE_CARD_COPY_CARD"
export const GUEST_ISSUE_CARD_PREPARE_DATA = "GUEST_ISSUE_CARD_PREPARE_DATA"
export const GUEST_ISSUE_CARD_SET_NEXT_CARD = "GUEST_ISSUE_CARD_SET_NEXT_CARD"
export const GUEST_ISSUE_CARD_SET_NEXT_CARD_RESET = "GUEST_ISSUE_CARD_SET_NEXT_CARD_RESET"

export interface GuestIssueCardRequest {
    type: typeof GUEST_ISSUE_CARD_REQUEST,
    payload?: any
}

export interface GuestIssueCardProcessSuccess {
    type: typeof GUEST_ISSUE_CARD_PROCESS_SUCCESS,
    payload: {}
}

export interface GuestIssueCardDataSourceSuccess {
    type: typeof GUEST_ISSUE_CARD_DATASOURCE_SUCCESS,
    payload: any
}
export interface GuestIssueCardProcessReset {
    type: typeof GUEST_ISSUE_CARD_PROCESS_RESET
}

export interface GuestIssueCardReset {
    type: typeof GUEST_ISSUE_CARD_RESET
}

export interface GuestIssueCardNewCard {
    type: typeof GUEST_ISSUE_CARD_NEW_CARD,
    payload: any
}
export interface GuestIssueCardCopyCard {
    type: typeof GUEST_ISSUE_CARD_COPY_CARD,
    payload: any
}

export interface GuestIssueCardSetNextCard{
    type: typeof GUEST_ISSUE_CARD_SET_NEXT_CARD,
    payload: any
}

export interface GuestIssueCardSetNextCardReset{    
    type: typeof GUEST_ISSUE_CARD_SET_NEXT_CARD_RESET
}

export type GuestIssueCardAction =
    | GuestIssueCardRequest
    | GuestIssueCardProcessSuccess
    | GuestIssueCardDataSourceSuccess
    | GuestIssueCardProcessReset
    | GuestIssueCardReset
    | GuestIssueCardNewCard
    | GuestIssueCardCopyCard
    | GuestIssueCardSetNextCard
    | GuestIssueCardSetNextCardReset
