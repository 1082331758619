import {
    SYSTEM_CONFIG_PREVIEW,
    SYSTEM_CONFIG_UPDATE,
    SYSTEM_CONFIG_DATASOURCE_SUCCESS,
    SYSTEM_CONFIG_PROCESS_SUCCESS,
    SYSTEM_CONFIG_RESET

} from "constants/ActionTypes"

export const systemConfigPreview = () => {
    return {
        type: SYSTEM_CONFIG_PREVIEW
    }
}
export const systemConfigUpdate = (value) =>{
    return {
        type: SYSTEM_CONFIG_UPDATE,
        payload: value
    }
}
export const systemConfigProcessSuccess = (value) => {
    return {
        type: SYSTEM_CONFIG_PROCESS_SUCCESS,
        payload: value
    }
}
export const systemConfigDataSourceUpdate = (value) => {
    return {
        type: SYSTEM_CONFIG_DATASOURCE_SUCCESS,
        payload: value
    }
}
export const systemConfigReset = ()=>{
    return {
        type: SYSTEM_CONFIG_RESET
    }
}
