import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/rsv/BlockRoomActionType"
import * as actionCreators from "appRedux/actions/rsv/BlockRoom"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* blockRoomRequestProcess({ payload }: actionTypes.BlockRoomRequest) {

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/blockRoom/${payload}/`
    
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const blockRoomData = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.blockRoomDatasouceSuccess(blockRoomData)) 

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(actionCreators.blockRoomProcessSuccess(processStatus))
                yield put(actionCreators.blockRoomProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on block room preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.blockRoomProcessSuccess(processStatus))
        yield put(actionCreators.blockRoomProcessReset())
    }
}
function* blockRoomByRoomNumberProcess({payload}:actionTypes.BlockRoomByRoomNumber){
  const {roomNumber,bookingItem,notikey} = payload
  const blockRoom = {
    booking_item:bookingItem,
    super_block:false
  }

    try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/reservation/blockRoom/block/${roomNumber}`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = blockRoom
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200)
        {      
                const {result_code, result_msg } = response.data
                const processStatus = { status: true, action: "blockRoomByRoomNumber", message:result_msg,result_code,notikey}
                /* if no error put everything to reducer */
                yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
                yield put(actionCreators.blockRoomProcessReset())

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "blockRoomByRoomNumber", message,notikey}
                yield put(actionCreators.blockRoomProcessSuccess(processStatus))
                yield put(actionCreators.blockRoomProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on blockRoomByRoomNumber : ",error)
        const processStatus = { status: false, action: "blockRoomByRoomNumber", message:error,notikey}
        yield put(actionCreators.blockRoomProcessSuccess(processStatus))
        yield put(actionCreators.blockRoomProcessReset())
    }

}
function* blockRoomUnBlockProcess({payload}:actionTypes.BlockRoomUnblock){
  const {roomID,bookingItem} = payload
  const blockRoom = {
    booking_item:bookingItem,
    block_ids:roomID
  }

    try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/reservation/blockRoom/unblock/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = blockRoom
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200)
        {      
                const {result_code, result_msg } = response.data
                const processStatus = { status: true, action: "blockRoomUnBlock", message:result_msg,result_code}
                /* if no error put everything to reducer */
                yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
                yield put(actionCreators.blockRoomProcessReset())

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "blockRoomUnBlock", message}
                yield put(actionCreators.blockRoomProcessSuccess(processStatus))
                yield put(actionCreators.blockRoomProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on unblock room : ",error)
        const processStatus = { status: false, action: "blockRoomUnBlock", message:error}
        yield put(actionCreators.blockRoomProcessSuccess(processStatus))
        yield put(actionCreators.blockRoomProcessReset())
    }
}
function* blockRoomSetSuperBlockProcess({payload}:actionTypes.BlockRoomSetSuperBlock){
  const {bookingItem,roomID,superBlock} = payload
  const blockRoom = {
    booking_item: bookingItem,
    room_id: roomID,
    super_block:superBlock
  }
  try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/reservation/blockRoom/updateStatusSuperBlock/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = blockRoom
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

      if(response.status === 200)
      {      
              const {result_code, result_msg } = response.data
              const processStatus = { status: true, action: "blockRoomSetSuperBlock", message:result_msg,result_code}
              /* if no error put everything to reducer */
              yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
              yield put(actionCreators.blockRoomProcessReset())

      }else{
              console.log("Error : ",response)
              /* Show error message and signout */
              const {message,data} = response.data
              const processStatus = { status: false, action: "blockRoomSetSuperBlock", message}
              yield put(actionCreators.blockRoomProcessSuccess(processStatus))
              yield put(actionCreators.blockRoomProcessReset())
              if(response.status === 403){
              //if data is not null force signout 
                if(data)
                    yield put(userSignOut())
              }
              
      }
  } catch (error) {
      console.log("Error on Set superBlock : ",error)
      const processStatus = { status: false, action: "blockRoomSetSuperBlock", message:error}
      yield put(actionCreators.blockRoomProcessSuccess(processStatus))
      yield put(actionCreators.blockRoomProcessReset())
  }
}
function* blockRoomByRoomIDProcess({payload}:actionTypes.BlockRoomByRoomID){
  const {bookingItem,roomTargetID,superBlock} = payload
  const blockRoomInfo = {
      booking_item: bookingItem,
      room_ids: roomTargetID,
      super_block:superBlock
  }

  try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/reservation/blockRoom/block/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = blockRoomInfo
      const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

      if(response.status === 200)
      {      
              const {result_code, result_msg } = response.data
              const processStatus = { status: true, action: "blockRoomByRoomID", message:result_msg,result_code}
              /* if no error put everything to reducer */
              yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
              yield put(actionCreators.blockRoomProcessReset())

      }else{
              console.log("Error : ",response)
              /* Show error message and signout */
              const {message,data} = response.data
              const processStatus = { status: false, action: "blockRoomByRoomID", message}
              yield put(actionCreators.blockRoomProcessSuccess(processStatus))
              if(response.status === 403){
              //if data is not null force signout 
                if(data)
                    yield put(userSignOut())
              }
              
      }
  } catch (error) {
      console.log("Error on block room by room id : ",error)
      const processStatus = { status: false, action: "blockRoomByRoomID", message:error}
      yield put(actionCreators.blockRoomProcessSuccess(processStatus))
      yield put(actionCreators.blockRoomProcessReset())
  }
}
function* blockRoomUnBlockAllProcess({payload}:actionTypes.BlockRoomUnblockAll){

    try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/reservation/blockRoom/unblockAll/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = {booking_id:payload}
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200)
        {      
                const {result_code, result_msg } = response.data
                const processStatus = { status: true, action: "blockRoomUnBlockAll", message:result_msg,result_code}
                /* if no error put everything to reducer */
                yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
                yield put(actionCreators.blockRoomProcessReset())

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "blockRoomUnBlockAll", message}
                yield put(actionCreators.blockRoomProcessSuccess(processStatus))
                yield put(actionCreators.blockRoomProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on unblock all : ",error)
        const processStatus = { status: false, action: "blockRoomUnBlockAll", message:error}
        yield put(actionCreators.blockRoomProcessSuccess(processStatus))
        yield put(actionCreators.blockRoomProcessReset())
    }
}
function* blockRoomAutoBlockProcess({payload}:actionTypes.BlockRoomAutoBlock){

  const {bedtype,location,building,exposure,superBlock,reservation} = payload
  const blockRoomInfo = {} as any
        blockRoomInfo.booking_id = reservation
        blockRoomInfo.super_block = superBlock
  if(bedtype.length > 0) 
        blockRoomInfo.bedtypes = bedtype
  if(location.length > 0)
        blockRoomInfo.locations = location
  if(building.length > 0)
        blockRoomInfo.buildings = building
  if(exposure.length > 0)
        blockRoomInfo.exposures = exposure

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/blockRoom/AutoBlock/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = blockRoomInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200)
    {      
            const {result_code, result_msg } = response.data
            const processStatus = { status: true, action: "blockRoomAutoBlock", message:result_msg,result_code}
            /* if no error put everything to reducer */
            yield put(actionCreators.blockRoomProcessSuccess(processStatus)) 
            yield put(actionCreators.blockRoomProcessReset())

    }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "blockRoomAutoBlock", message}
            yield put(actionCreators.blockRoomProcessSuccess(processStatus))
            yield put(actionCreators.blockRoomProcessReset())
            if(response.status === 403){
            //if data is not null force signout 
              if(data)
                  yield put(userSignOut())
            }
            
    }
} catch (error) {
    console.log("Error on auto block : ",error)
    const processStatus = { status: false, action: "blockRoomAutoBlock", message:error}
    yield put(actionCreators.blockRoomProcessSuccess(processStatus))
    yield put(actionCreators.blockRoomProcessReset())
  }
}

function* blockRoomRequest() {
  yield takeEvery(actionTypes.BLOCKROOM_REQUEST, blockRoomRequestProcess)
}
function* blockRoomByRoomNumber(){
  yield takeEvery(actionTypes.BLOCKROOM_BY_ROOM_NUMBER,blockRoomByRoomNumberProcess)
}
function* blockRoomUnBlock(){
  yield takeEvery(actionTypes.BLOCKROOM_UNBLOCK,blockRoomUnBlockProcess)
}
function* blockRoomSetSuperBlock(){
  yield takeEvery(actionTypes.BLOCKROOM_SET_SUPERBLOCK,blockRoomSetSuperBlockProcess)
}
function* blockRoomByRoomID(){
  yield takeEvery(actionTypes.BLOCKROOM_BY_ROOM_ID,blockRoomByRoomIDProcess)
}
function* blockRoomUnBlockAll(){
  yield takeEvery(actionTypes.BLOCKROOM_UNBLOCK_ALL,blockRoomUnBlockAllProcess)
}
function* blockRoomAutoBlock(){
  yield takeEvery(actionTypes.BLOCKROOM_AUTO_BLOCK,blockRoomAutoBlockProcess)
}

export default function* rootSaga() {
  yield all([fork(blockRoomRequest),
              fork(blockRoomByRoomNumber),
              fork(blockRoomUnBlock),
              fork(blockRoomSetSuperBlock),
              fork(blockRoomByRoomID),
              fork(blockRoomUnBlockAll),
              fork(blockRoomAutoBlock)
              
  ])
}


