
export const ROOM_STATUS_REQUEST = "ROOM_STATUS_REQUEST"
export const ROOM_STATUS_REALTIME = "ROOM_STATUS_REALTIME"
export const ROOM_STATUS_PROCESS_SUCCESS = "ROOM_STATUS_PROCESS_SUCCESS"
export const ROOM_STATUS_DATASOURCE_SUCCESS = "ROOM_STATUS_DATASOURCE_SUCCESS"
export const ROOM_STATUS_RAW_DATASOURCE = "ROOM_STATUS_RAW_DATASOURCE"
export const ROOM_STATUS_PROCESS_RESET = "ROOM_STATUS_PROCESS_RESET"
export const ROOM_STATUS_FILTER_RAWDATA = "ROOM_STATUS_FILTER_RAWDATA"
export const ROOM_STATUS_SET_FILTER = "ROOM_STATUS_SET_FILTER"
export const ROOM_STATUS_CHECK_IN = "ROOM_STATUS_CHECK_IN"
export const ROOM_STATUS_CANCEL_CHECK_IN = "ROOM_STATUS_CANCEL_CHECK_IN"
export const ROOM_STATUS_CHECK_OUT = "ROOM_STATUS_CHECK_OUT"
export const ROOM_STATUS_CANCEL_CHECK_OUT = "ROOM_STATUS_CANCEL_CHECK_OUT"
export const ROOM_STATUS_CHANGE_GROUP_VIEW = "ROOM_STATUS_CHANGE_GROUP_VIEW" 
export const ROOM_STATUS_EXTRA_OPTIONAL = "ROOM_STATUS_EXTRA_OPTIONAL"
export const ROOM_STATUS_SELECTION_MODE = "ROOM_STATUS_SELECTION_MODE"
export const ROOM_STATUS_TARGET_SELECTED = "ROOM_STATUS_TARGET_SELECTED" 

export const ROOM_STATUS_DATALIST_CHECKIN_REQUEST = "ROOM_STATUS_DATALIST_CHECKIN_REQUEST"
export const ROOM_STATUS_DATALIST_CHECKIN_DATASOURCE = "ROOM_STATUS_DATALIST_CHECKIN_DATASOURCE"

export const ROOM_STATUS_UPDATE = "ROOM_STATUS_UPDATE"
export const ROOM_STATUS_MAID_UPDATE = "ROOM_STATUS_MAID_UPDATE"
export const ROOM_STATUS_BED_UPDATE = "ROOM_STATUS_BED_UPDATE"
export const ROOM_STATUS_OUT_OF_ORDER_NEW = "ROOM_STATUS_OUT_OF_ORDER_NEW"
export const ROOM_STATUS_OUT_OF_ORDER_UPDATE = "ROOM_STATUS_OUT_OF_ORDER_UPDATE"
export const ROOM_STATUS_OUT_OF_ORDER_REMOVE = "ROOM_STATUS_OUT_OF_ORDER_REMOVE"
export const ROOM_STATUS_REMARK_UPDATE = "ROOM_STATUS_REMARK_UPDATE"


export interface RoomStatusRequest {
  type: typeof ROOM_STATUS_REQUEST,
  payload?:any
}
export interface RoomStatusRealTime {
  type: typeof ROOM_STATUS_REALTIME,
  payload?:any
}
export interface RoomStatusProcessSuccess {
  type: typeof ROOM_STATUS_PROCESS_SUCCESS,
  payload:{}
}
export interface RoomStatusDataSourceSuccess{
  type: typeof ROOM_STATUS_DATASOURCE_SUCCESS,
  payload:any
}
export interface RoomStatusRawDataSource{
  type: typeof ROOM_STATUS_RAW_DATASOURCE,
  payload:any
}
export interface RoomStatusFilterRawData{
  type: typeof ROOM_STATUS_FILTER_RAWDATA,
  payload:any
}
export interface RoomStatusProcessReset {
  type: typeof ROOM_STATUS_PROCESS_RESET
}
export interface RoomStatusSetFilter{
  type: typeof ROOM_STATUS_SET_FILTER,
  payload:any
}
export interface RoomStatusCheckIn{
  type: typeof ROOM_STATUS_CHECK_IN,
  payload:number
}
export interface RoomStatusCancelCheckIn{
  type: typeof ROOM_STATUS_CANCEL_CHECK_IN,
  payload:any
}
export interface RoomStatusCheckOut{
  type: typeof ROOM_STATUS_CHECK_OUT,
  payload:number
}
export interface RoomStatusCancelCheckOut{
  type: typeof ROOM_STATUS_CANCEL_CHECK_OUT,
  payload:any
}
export interface RoomStatusChangeGroupView{
  type: typeof ROOM_STATUS_CHANGE_GROUP_VIEW,
  payload:any
}
export interface RoomStatusExtraOptional{
  type: typeof ROOM_STATUS_EXTRA_OPTIONAL,
  payload?:any
}
export interface RoomStatusSelectionMode{
  type: typeof ROOM_STATUS_SELECTION_MODE,
  payload:any
}
export interface RoomStatusTargetSelected{
  type: typeof ROOM_STATUS_TARGET_SELECTED,
  payload:any
}

export interface RoomStatusUpdate{
  type: typeof ROOM_STATUS_UPDATE,
  payload:any
}

export interface RoomStatusMaidUpdate{
  type: typeof ROOM_STATUS_MAID_UPDATE,
  payload:any
}

export interface RoomStatusBedUpdate{
  type: typeof ROOM_STATUS_BED_UPDATE,
  payload:any
}
export interface RoomStatusOutOfOrderNew{
  type: typeof ROOM_STATUS_OUT_OF_ORDER_NEW,
  payload:any
}
export interface RoomStatusOutOfOrderUpdate{
  type: typeof ROOM_STATUS_OUT_OF_ORDER_UPDATE,
  payload:any
}
export interface RoomStatusOutOfOrderRemove{
  type: typeof ROOM_STATUS_OUT_OF_ORDER_REMOVE,
  payload:any
}
export interface RoomStatusRemarkUpdate{
  type: typeof ROOM_STATUS_REMARK_UPDATE,
  payload:any
}
export interface RoomStatusDataCheckinListRequest{
  type: typeof ROOM_STATUS_DATALIST_CHECKIN_REQUEST,
  payload:number
}
export interface RoomStatusDataCheckinListDataSource{
  type: typeof ROOM_STATUS_DATALIST_CHECKIN_DATASOURCE,
  payload:any
}

export type RoomStatusAction = 
| RoomStatusRequest
| RoomStatusRealTime
| RoomStatusProcessSuccess
| RoomStatusDataSourceSuccess
| RoomStatusRawDataSource
| RoomStatusFilterRawData
| RoomStatusProcessReset
| RoomStatusSetFilter
| RoomStatusCheckIn
| RoomStatusCancelCheckIn
| RoomStatusCheckOut
| RoomStatusCancelCheckOut
| RoomStatusChangeGroupView
| RoomStatusExtraOptional
| RoomStatusSelectionMode
| RoomStatusTargetSelected
| RoomStatusUpdate
| RoomStatusMaidUpdate
| RoomStatusBedUpdate
| RoomStatusOutOfOrderNew
| RoomStatusOutOfOrderUpdate
| RoomStatusOutOfOrderRemove
| RoomStatusRemarkUpdate
| RoomStatusDataCheckinListRequest
| RoomStatusDataCheckinListDataSource