import moment from 'moment'
import { isUndefined } from 'lodash'
import { isISO8601 } from 'validator'

import { DateApi } from 'constants/types'

/**
 * Check if the Date is a valid ISO 8601 (https://en.wikipedia.org/wiki/ISO_8601) date.
 * @param date Date | null | undefined
 */
const isDateApi = (date: Date | null | undefined): boolean => {
  const d: string | undefined = date?.toString()
  return isISO8601(!isUndefined(d) ? d : '')
}
  
/**
 * Convert Date | moment | string to DateApi
 * @param date 
 */
const toDateApi = (date: Date | moment.Moment | string | null | undefined): DateApi => {
    if (typeof date === 'string') {
      const dateString = moment(date).format('YYYY-MM-DD')
      if (isValidDateStr(dateString)) {
        return dateString
      } else {
        throw new Error(`Invalid date string: ${date}`)
      }
    } else {
      const dateString = moment(date).format('YYYY-MM-DD')
      if (isValidDateStr(dateString)) {
        return dateString;
      }
    }
    throw new Error(`Shouldn't get here (invalid toDateApi provided): ${date}`)
}

const isValidDateStr = (str: string): str is DateApi => {
    return str.match(/^\d{4}-\d{2}-\d{2}$/) !== null
}

export { 
  toDateApi, 
  isDateApi
}