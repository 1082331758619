import {
    OCCUPATION_PREVIEW,
    OCCUPATION_NEW,
    OCCUPATION_UPDATE,
    OCCUPATION_REMOVE,
    OCCUPATION_SEARCH,
    OCCUPATION_PROCESS_SUCCESS,
    OCCUPATION_DATASOURCE_SUCCESS,
    OCCUPATION_PROCESS_RESET
  } from "constants/ActionTypes"

  export const occupationPreview = () => {
    return {
      type: OCCUPATION_PREVIEW
    }
  }

  export const occupationCreate =(value) =>{
    return {
      type:OCCUPATION_NEW,
      payload:value
    }
  }

  export const occupationUpdate = (value) =>{
    return {
      type:OCCUPATION_UPDATE,
      payload: value
    }
  }

  export const occupationRemove = (value)=>{
    return {
      type:OCCUPATION_REMOVE,
      payload:value
    }
  }

  export const occupationSearch = (keyword) =>{
    return {
      type: OCCUPATION_SEARCH,
      payload:keyword
    }
  }

  export const occupationProcessSuccess = (value) =>{
    return{
      type:OCCUPATION_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const occupationDataSourceUpdate = (value) => {
    return {
      type: OCCUPATION_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const occupationProcessReset = () =>{
    return{
      type:OCCUPATION_PROCESS_RESET
    }
  }

