import React from "react"
import {Route, Switch} from "react-router-dom"
import asyncComponent from "../../util/asyncComponent"
import permissionVerify from "util/permissionVerify"

const DashBoardPage = asyncComponent(() => import('./dashboard'))
const SetupPage = asyncComponent(()=>import('./setup'))
const InventoryPage = asyncComponent(()=>import('./inventory'))
const ReservationsPage = asyncComponent(()=>import('./reservations/individual'))
const ReservationsForms = asyncComponent(()=>import('./reservations/individual/forms'))
const UserProfile = asyncComponent(()=>import('./userprofile'))

const Registration = asyncComponent(()=>import('./registration'))
const RateApprove = asyncComponent(()=>import('./nightAudit/rateApprove'))
const Report = asyncComponent(()=>import('./report'))
const ReportOptions = asyncComponent(()=>import('./report/showOptions'))
const PDFViewer = asyncComponent(()=>import('./report/pdfViewer'))
const RoomPlan = asyncComponent(()=>import('./roomplan'))
const EndDay = asyncComponent(()=>import('./nightAudit/endDay'))
const RoomAvail = asyncComponent(()=>import('./roomAvail/'))
const RoomAvailDetailView = asyncComponent(()=>import('./roomAvail/detail'))
const CashierGuest = asyncComponent(()=>import('./cashier/guest'))
const CashierGuestForm = asyncComponent(()=>import('./cashier/guest/form'))
const CashierBooking = asyncComponent(()=>import("./cashier/booking"))
const CashierBookingForm = asyncComponent(()=>import("./cashier/booking/form"))
const CashierCashSale = asyncComponent(()=>import("./cashier/cashsale"))
const CashierCashSaleForm = asyncComponent(()=>import("./cashier/cashsale/form"))
const CashierTransfer = asyncComponent(()=>import("./cashier/transfer"))
const CashierFolioHistory = asyncComponent(()=>import("./cashier/folioHistory"))
const CashierChargeSchedule = asyncComponent(()=>import("./cashier/chargeSchedule"))
const CashierPosting = asyncComponent(()=>import('./cashier/journal'))
const ExpectedArrival = asyncComponent(()=>import("./expected/arrival"))
const ExpectedDeparture = asyncComponent(()=>import("./expected/departure"))
const ChargeScheduleReservation = asyncComponent(()=>import("./chargeSchedule/reservation"))
const ChargeScheduleGuestInhouse = asyncComponent(()=>import("./chargeSchedule/inhouse"))

const GuestinhouseNew = asyncComponent(()=>import('./guestinhouse/individual'))
const GuestinhouseForm = asyncComponent(()=>import('./guestinhouse/individual/form'))
const GuestinhouseWalkin = asyncComponent(()=>import("./guestinhouse/individual/form/walkin"))
const GuestinhouseCheckin = asyncComponent(()=>import("./guestinhouse/individual/form/checkin"))

const BlockRoom = asyncComponent(()=>import("./blockroom"))

const UserRestrict = asyncComponent(()=>import("./404"))

const OccupancyCalendar = asyncComponent(()=>import("./calendar"))
const UserLogs = asyncComponent(()=>import("./userLogs"))
const Identity = asyncComponent(()=>import("./identity"))
const AutoPostRoom = asyncComponent(()=>import("./nightAudit/autoPostRoom"))

const Main = ({ match }) => {
  
  return(
  <Switch>
    <Route path={`${match.url}/logs`} component={UserLogs}/>
    <Route path={`${match.url}/identity`} component={Identity}/>
    <Route path={`${match.url}/calendar`} component={OccupancyCalendar}/>
    <Route path={`${match.url}/dashboard`} component={DashBoardPage} />
    <Route path={`${match.url}/setup`} component={permissionVerify("90000") ? SetupPage : UserRestrict} />
    <Route path={`${match.url}/inventory`} component={InventoryPage} />
    <Route exact path={`${match.url}/reservations`} component={permissionVerify("10000") ? ReservationsPage : UserRestrict} />
    <Route path={`${match.url}/reservations/individual/form/:id`} component={(permissionVerify("10000") || permissionVerify("80056")) ? ReservationsForms : UserRestrict} />
    <Route path={`${match.url}/expected_arrival`} component={permissionVerify("80056") ? ExpectedArrival : UserRestrict }/>
    <Route path={`${match.url}/expected_departure`} component={permissionVerify("80058") ? ExpectedDeparture : UserRestrict }/>
    <Route path={`${match.url}/userprofile`} component={UserProfile} />
    <Route path={`${match.url}/registration`} component={permissionVerify("50000") ? Registration : UserRestrict} />

    <Route exact path={`${match.url}/blockroom/:id`} component={BlockRoom} />

    <Route exact path={`${match.url}/guestinhouse`} component={permissionVerify("20000") ? GuestinhouseNew : UserRestrict} />
    <Route path={`${match.url}/guestinhouse/individual/form/:id`} component={(permissionVerify("20000") || permissionVerify("80058")) ? GuestinhouseForm : UserRestrict } />
    <Route path={`${match.url}/guestinhouse/walkin/:id`} component={permissionVerify("20000") ?GuestinhouseWalkin : UserRestrict } />
    <Route path={`${match.url}/guestinhouse/checkin`} component={permissionVerify("20000") ?GuestinhouseCheckin : UserRestrict }/>

    <Route path={`${match.url}/night/approve`} component={permissionVerify("41000") ? RateApprove : UserRestrict}/>
    <Route path={`${match.url}/night/autopost`} component={permissionVerify("43000") ? AutoPostRoom : UserRestrict}/>
    <Route path={`${match.url}/report`} component={permissionVerify("80000") ? Report : UserRestrict} />
    <Route path={`${match.url}/report-options`} component={permissionVerify("80000") ? ReportOptions : UserRestrict} />
    <Route path={`${match.url}/printform-options`} component={ReportOptions} />
    <Route path={`${match.url}/pdf-viewer`} component={PDFViewer} />
    <Route path={`${match.url}/roomplan`} component={permissionVerify("62000") ? RoomPlan : UserRestrict} />
    <Route path={`${match.url}/night/endday`} component={ permissionVerify("42000") ? EndDay : UserRestrict}/>
    <Route exact path={`${match.url}/room/avail/`} component={permissionVerify("63000") ? RoomAvail : UserRestrict}/>
    <Route path={`${match.url}/room/avail/detail`} component={permissionVerify("63000") ?RoomAvailDetailView : UserRestrict}/>
    <Route exact path={`${match.url}/cashier/guest`} component={permissionVerify("30000") ? CashierGuest : UserRestrict } />
    <Route exact path={`${match.url}/cashier/guest/folio/:id`} component={permissionVerify("30000") ? CashierGuestForm : UserRestrict}/>
    <Route path={`${match.url}/cashier/guest/transfer/:id`} component={permissionVerify("30000") ? CashierTransfer : UserRestrict}/>
    <Route path={`${match.url}/cashier/guest/history/:id`} component={permissionVerify("30000") ? CashierFolioHistory : UserRestrict}/>
    <Route path={`${match.url}/cashier/guest/schedule/:id`} component={permissionVerify("30000") ? CashierChargeSchedule : UserRestrict}/>
    <Route path={`${match.url}/cashier/posting`} component={permissionVerify("33000") ? CashierPosting : UserRestrict}/>

    <Route exact path={`${match.url}/cashier/booking`} component={permissionVerify("30000") ? CashierBooking : UserRestrict} />
    <Route exact path={`${match.url}/cashier/booking/folio/:id`} component={permissionVerify("30000") ? CashierBookingForm : UserRestrict} />
    <Route path={`${match.url}/cashier/booking/transfer/:id`} component={permissionVerify("30000") ? CashierTransfer : UserRestrict}/>
    <Route path={`${match.url}/cashier/booking/history/:id`} component={permissionVerify("30000") ? CashierFolioHistory : UserRestrict}/>
    <Route path={`${match.url}/cashier/booking/schedule/:id`} component={permissionVerify("30000") ? CashierChargeSchedule : UserRestrict}/>

    <Route exact path={`${match.url}/cashier/cash`} component={permissionVerify("30000") ? CashierCashSale : UserRestrict} />
    <Route exact path={`${match.url}/cashier/cash/folio/:id`} component={permissionVerify("30000") ? CashierCashSaleForm : UserRestrict} />
    <Route path={`${match.url}/cashier/cash/transfer/:id`} component={permissionVerify("30000") ? CashierTransfer : UserRestrict}/>
    <Route path={`${match.url}/cashier/cash/history/:id`} component={permissionVerify("30000") ? CashierFolioHistory : UserRestrict}/>
    <Route path={`${match.url}/cashier/cash/schedule/:id`} component={permissionVerify("30000") ? CashierChargeSchedule : UserRestrict}/>
    <Route path={`${match.url}/charge_schedule/rsv/:id`} component={ChargeScheduleReservation}/>
    <Route path={`${match.url}/charge_schedule/inhouse/:id`} component={ChargeScheduleGuestInhouse}/>
    <Route component={UserRestrict}/>
  </Switch>
  )
}

export default Main
