import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/rsv/ReservationActionType"
import * as actionCreators from "appRedux/actions/rsv/Reservation"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import moment from 'moment'
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { APIGetRequest,APIPostRequest } from "util/connection"

function* reservationRequestProcess(value: actionTypes.ReservationRequest) {

    const {payload, sorter = undefined} = value

    let keywords = payload
    const reservationInfo = {} as any
    if(keywords.searchText)
      reservationInfo.search_text = keywords.searchText
    if(keywords.arrival)
      reservationInfo.arrival = moment(keywords.arrival).format('YYYY-MM-DD')
    if(keywords.departure)
      reservationInfo.departure = moment(keywords.departure).format('YYYY-MM-DD')
    if(keywords.stayDate)
      reservationInfo.stay_date = moment(keywords.stayDate).format('YYYY-MM-DD')
    if(keywords.roomNumber)
      reservationInfo.room_number= keywords.roomNumber
    if(keywords.roomTypeId)
      reservationInfo.roomtype_id= keywords.roomTypeId
    if(keywords.channelName)
      reservationInfo.channel_name= keywords.channelName
    if(keywords.vipId)
      reservationInfo.vip_id= keywords.vipId
    if(keywords.memberCode)
      reservationInfo.member_code= keywords.memberCode
    if(keywords.company)
      reservationInfo.company= keywords.company
    if(keywords.nationId)
      reservationInfo.nation_id= keywords.nationId
    if(keywords.ratePlanId)
      reservationInfo.rateplan_id= keywords.ratePlanId

    const roomStatus = []
        if(keywords.statusRSV) roomStatus.push("R")
        if(keywords.statusCFM) roomStatus.push("C")
        if(keywords.statusTTT) roomStatus.push("W")
        if(keywords.statusCXL) roomStatus.push("X")
        if(keywords.statusNOS) roomStatus.push("N")
        if(keywords.statusINH) roomStatus.push("I")
        if(keywords.statusCO) roomStatus.push("O")

    reservationInfo.statuses = isEmpty(roomStatus)?("R,C,W,X,N,I,O"):roomStatus.toString()
    reservationInfo.page = payload.current
    reservationInfo.limit = payload.limit

    /* Create query string from array object */
    let queryString = Object.keys(reservationInfo).map(key =>`${key}=${reservationInfo[key]}`).join('&')

    /* Set sorter */
    if(sorter !== undefined){
    const sortFieldData = sorter.fieldArray.toString()
    const sortOrderData = sorter.orderArray.toString() 
      queryString += `&sortField=${sortFieldData}&sortOrder=${sortOrderData}`
    }
    
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/search/?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const reservationData = response.data
                const {paging} = reservationData
                /* if no error put everything to reducer */
                yield put(actionCreators.reservationDataSourceSuccess(reservationData))
                yield put(actionCreators.reservationSetFilter({...payload,
                                                            current:paging?.current,
                                                            limit:paging?.limit,
                                                            pageSize:paging?.limit,
                                                            total:paging?.total_rows
                                                          })) 

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "reservationPreview", message}
                yield put(actionCreators.reservationProcessSuccess(processStatus))
                yield put(actionCreators.reservationProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on reservation reservationPreview : ",error)
        const processStatus = { status: false, action: "reservationPreview", message:error}
        yield put(actionCreators.reservationProcessSuccess(processStatus))
        yield put(actionCreators.reservationProcessReset())
    }
}

function* reservationDetailRequestProcess({payload}: actionTypes.ReservationDetailRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/${payload}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const reservationDetail = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.reservationDetailDataSourceSuccess(reservationDetail))
                yield put(actionCreators.reservationBookingSummarySuccess(reservationDetail.summary)) 
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "reservation_detail", message}
                yield put(actionCreators.reservationProcessSuccess(processStatus))
                yield put(actionCreators.reservationProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get reservation detail folio : ",error)
        const processStatus = { status: false, action: "reservation_detail", message:error}
        yield put(actionCreators.reservationProcessSuccess(processStatus))
        yield put(actionCreators.reservationProcessReset())
    }
}

function* reservationNewProcess({payload}: actionTypes.ReservationNew){

  const {bookingData,confirmStatus,confirmRemark} = payload
  const arrival = moment(bookingData.arrival).format("YYYY-MM-DD")
  const departure = moment(bookingData.departure).format("YYYY-MM-DD")
  const arrival_time = moment(bookingData.arrival_time).format('HH:mm')
  const departure_time = moment(bookingData.departure_time).format('HH:mm')
  const booking = {
    confirm: confirmStatus,
    confirm_remark : confirmRemark ,
    booking: { ...bookingData,arrival,departure,arrival_time,departure_time }
  }

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/create`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = booking
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){

      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: "createBooking", message:result_msg,result_code}
      yield put(actionCreators.reservationProcessSuccess(processStatus))
      yield put(actionCreators.reservationProcessReset())

    }else { 
      console.log("Error : ",response)
      /* Show error message and signout */
      const {message,data} = response.data
      const processStatus = { status: false, action: "createBooking", message}
      yield put(actionCreators.reservationProcessSuccess(processStatus))
      yield put(actionCreators.reservationProcessReset())
      if(response.status === 403){
          if(data)
            yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error on create booking : ",error)
    const processStatus = { status: false, action: "createBooking", message:error}
    yield put(actionCreators.reservationProcessSuccess(processStatus))
    yield put(actionCreators.reservationProcessReset())
  }

}

function* reservationUpdateProcess({payload}: actionTypes.ReservationUpdate){

  const {bookingData,confirmStatus,confirmRemark} = payload
  const arrival = moment(bookingData.arrival).format("YYYY-MM-DD")
  const departure = moment(bookingData.departure).format("YYYY-MM-DD")
  const arrival_time = moment(bookingData.arrival_time).format('HH:mm')
  const departure_time = moment(bookingData.departure_time).format('HH:mm')
  const booking = {
    confirm: confirmStatus,
    confirm_remark : confirmRemark ,
    booking: { ...bookingData,arrival,departure,arrival_time,departure_time }
  }
  yield console.log("Sent api for update booking ",booking)
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/update`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = booking
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){

      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: "updateBooking", message:result_msg,result_code}
      yield put(actionCreators.reservationProcessSuccess(processStatus)) 
      yield put(actionCreators.reservationProcessReset())

    }else { 
      console.log("Error : ",response)
      /* Show error message and signout */
      const {message,data} = response.data
      const processStatus = { status: false, action: "updateBooking", message}
      yield put(actionCreators.reservationProcessSuccess(processStatus))
      yield put(actionCreators.reservationProcessReset())
      if(response.status === 403){
          if(data)
            yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error on create booking : ",error)
    const processStatus = { status: false, action: "updateBooking", message:error}
    yield put(actionCreators.reservationProcessSuccess(processStatus))
    yield put(actionCreators.reservationProcessReset())
  }
}
function* reservationRemoveProcess({payload}: actionTypes.ReservationRemove){
  yield console.log("remove rsv ",payload)
}

function* reservationRatePlanRequestProcess({payload}: actionTypes.ReservationRatePlanRequest){
    
    let keywords = payload
    console.log("Dispatching reservationRatePlanRequestProcess with payload:", keywords);
    const roomCondition = {} as any
      roomCondition.booking_item = keywords.item_id||(Math.floor(Math.random() * 10000000)*-1)
      roomCondition.roomtype_id = keywords.roomtype_id
      roomCondition.qty= keywords.qty
      roomCondition.adult= keywords.adult
      roomCondition.child= keywords.child
      roomCondition.infant= keywords.infant
      roomCondition.arrival_date = moment(keywords.dateRange[0]).format('YYYY-MM-DD')
      roomCondition.departure_date = moment(keywords.dateRange[1]).format('YYYY-MM-DD')
      roomCondition.keep_original_rate = keywords.keep_original_rate
    /* Create query string from array object */
    let queryString = Object.keys(roomCondition).map(key =>`${key}=${roomCondition[key]}`).join('&')
    console.log("Constructed roomCondition:", roomCondition);
    console.log("Query string:", queryString);

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/inventory/availableRates/?${queryString}`
    console.log("API URL for rate plan lookup:", url);
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
    const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
    console.log("Rate plan API response:", response);
    if(response.status === 200){

      const {result_code, result_msg, result_data} = response.data
      const processStatus = { status: true, action: "rateplanLookup", message:result_msg,result_code}
      yield put(actionCreators.reservationRatePlanDataSouceSuccess(result_data))
      yield put(actionCreators.reservationProcessSuccess(processStatus))
    }else{
        console.log("Error : ",response)
        /* Show error message and signout */
        const {message,data} = response.data
        const processStatus = { status: false, action: "rateplanLookup", message}
        yield put(actionCreators.reservationProcessSuccess(processStatus))
        yield put(actionCreators.reservationProcessReset())
        if(response.status === 403){
          if(data)
            yield put(userSignOut())
        }
    }
  } catch (error) {
      console.log("Error on get rateplan lookup : ",error)
      const processStatus = { status: false, action: "rateplanLookup", message:error}
      yield put(actionCreators.reservationProcessSuccess(processStatus))
      yield put(actionCreators.reservationProcessReset())
  }
}

function* reservationChangeBookingStatusProcess({payload}: actionTypes.ReservationChangeBookingStatus){

  const changeStatusInfo = {
    booking_id: payload.booking_id,
    new_status: payload.new_status,
    remark: payload.remark
  }

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/status/update`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = changeStatusInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: payload.action, message:result_msg,result_code}
      yield put(actionCreators.reservationProcessSuccess(processStatus))   
      yield put(actionCreators.reservationProcessReset())

    }else { 
      console.log("Error : ",response)
      /* Show error message and signout */
      const {message,data} = response.data
      const processStatus = { status: false, action: payload.action, message}
      yield put(actionCreators.reservationProcessSuccess(processStatus))
      yield put(actionCreators.reservationProcessReset())

      if(response.status === 403){
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error update status booking: ",error)
    const processStatus = { status: false, action: payload.action, message:error}
    yield put(actionCreators.reservationProcessSuccess(processStatus))
    yield put(actionCreators.reservationProcessReset())
  }
}

function* reservationBookingSummaryProcess({payload}: actionTypes.ReservationBookingSummaryRequest){
  
  const arrival = moment(payload.arrival).format("YYYY-MM-DD")
  const departure = moment(payload.departure).format("YYYY-MM-DD")
  const arrival_time = moment(payload.arrival_time).format('HH:mm')
  const departure_time = moment(payload.departure_time).format('HH:mm')
  
  const reformData = {...payload,arrival,departure,arrival_time,departure_time}

  const summaryInfo = { booking_data: {...reformData}}

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/bookingsummary/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = summaryInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      yield put(actionCreators.reservationBookingSummarySuccess(response.data))   
    }else { 
      console.log("Error : ",response)
    } 
  } catch (error) {
    console.log("Error call booking summary api : ",error)
  }
}

function* reservationRequest() {
  yield takeEvery(actionTypes.RESERVATION_REQUEST, reservationRequestProcess)
}
function* reservationDetailRequest(){
  yield takeEvery(actionTypes.RESERVATION_DETAIL_REQUEST,reservationDetailRequestProcess)
}
function* reservationNew(){
  yield takeEvery(actionTypes.RESERVATION_NEW,reservationNewProcess)
}
function* reservationUpdate(){
  yield takeEvery(actionTypes.RESERVATION_UPDATE,reservationUpdateProcess)
}
function* reservationRemove(){
  yield takeEvery(actionTypes.RESERVATION_REMOVE,reservationRemoveProcess)
}
function* reservationRatePlanRequest(){
  yield takeEvery(actionTypes.RESERVATION_RATEPLAN_REQUEST,reservationRatePlanRequestProcess)
}
function* reservationChangeBookingStatus(){
  yield takeEvery(actionTypes.RESERVATION_CHANGE_BOOKING_STATUS,reservationChangeBookingStatusProcess)
}
function* reservationBookingSummary(){
  yield takeEvery(actionTypes.RESERVATION_BOOKING_SUMMARY_REQUEST,reservationBookingSummaryProcess)
}

export default function* rootSaga() {
  yield all([fork(reservationRequest),
             fork(reservationDetailRequest),
             fork(reservationNew),
             fork(reservationUpdate),
             fork(reservationRemove),
             fork(reservationRatePlanRequest),
             fork(reservationChangeBookingStatus),
             fork(reservationBookingSummary)
  ])
}


