import {
    MESSAGE_TO_GUEST_PREVIEW,
    MESSAGE_TO_GUEST_NEW,
    MESSAGE_TO_GUEST_UPDATE,
    MESSAGE_TO_GUEST_REMOVE,
    MESSAGE_TO_GUEST_PROCESS_SUCCESS,
    MESSAGE_TO_GUEST_DATASOURCE_SUCCESS,
    RESET_STATE
  } from "constants/ActionTypes"

  export const messageToGuestPreview = (value) => {
    return {
      type: MESSAGE_TO_GUEST_PREVIEW,
      payload:value
    }
  }

  export const messageToGuestCreate =(value) =>{
    return {
      type:MESSAGE_TO_GUEST_NEW,
      payload:value
    }
  }

  export const messageToGuestUpdate = (value) =>{
    return {
      type:MESSAGE_TO_GUEST_UPDATE,
      payload: value
    }
  }

  export const messageToGuestRemove = (value)=>{
    return {
      type:MESSAGE_TO_GUEST_REMOVE,
      payload:value
    }
  }

  export const messageToGuestProcessSuccess = (value) =>{
    return{
      type:MESSAGE_TO_GUEST_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const messageToGuestDataSourceUpdate = (value) => {
    return {
      type: MESSAGE_TO_GUEST_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const messageToGuestResetState = () =>{
    return{
      type: RESET_STATE
    }
  }

