import {
    AppAction,
    REPORT_MENU_GET_REQUEST
} from 'constants/ActionTypes'
import * as actions from './report-actionTypes'

/**
 * GET_REQUEST: Request API 
 * @param report_id 
 * @returns 
 */
export const reportOptionsReguestAction = (report_id: number): AppAction<string, number> => {
    return {
        type: actions.REPORT_OPTIONS_GET_REQUEST,
        payload: report_id
    }
}

/**
 * GET_REQUEST: Request API 
 * Function connect between components and actions
 */
export const reportMenuRequestAction = (): AppAction => {
    return {
        type: REPORT_MENU_GET_REQUEST,
        payload: null
    }
}