import {
  SEARCH_GUEST_PREVIEW,
  SEARCH_GUEST_PROCESS_SUCCESS,
  SEARCH_GUEST_DATASOURCE_SUCCESS,
  SEARCH_GUEST_DETAIL_REQUEST,
  SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS,
  SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS,
  SEARCH_GUEST_PROCESS_RESET,

  RESET_STATE_SEARCH_GUEST,
  GET_GUEST,
  GET_GUEST_PROCESS_SUCCESS,
  GET_MAIN_GUEST,
  RESET_STATE_MAIN_GUEST,
  MSG_AlERT_BOOKING
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSearchGuest:[],
  dataGuestById:[],
  dataMainGuestById: [],
  processStatus:false,
  msgBooking: '',
  loaderMsgBooking:false,
  
  guestDetail:{},
  guestDetailForm:{},
  process:{status:false,action:"",message:"",notiKey:""}
}

export default (state = INIT_STATE, action) => {
switch (action.type) {

  case SEARCH_GUEST_DETAIL_REQUEST:{
    return{
      ...state,
      loader:true,
      guestDetail:{},
      guestDetailForm:{}
    }
  }
  case SEARCH_GUEST_DETAIL_DATASOURCE_SUCCESS:{
    return{
      ...state,
      loader:false,
      guestDetail:action.payload
    }
  }
  case SEARCH_GUEST_DETAIL_FORM_DATASOURCE_SUCCESS:{
    return{
      ...state,
      loader:false,
      guestDetailForm:action.payload
    }
  }

  case SEARCH_GUEST_PROCESS_SUCCESS:{
    return{
      ...state,
      process:action.payload
    }
  }
  case SEARCH_GUEST_PROCESS_RESET:{
    return{
      ...state,
      process:{status:false,action:"",message:"",notiKey:""}
    }
  }

  case RESET_STATE_SEARCH_GUEST:{
    return{
      ...state,
      loader: false,
      dataSearchGuest:[],
      guestDetail:{},
      guestDetailForm:{},
      process:{status:false,action:"",message:"",notiKey:""}
    }
  }
  case MSG_AlERT_BOOKING: {
    return {
      ...state,
      loaderMsgBooking:false,
      msgBooking: action.payload,
    }
  }
  case RESET_STATE_MAIN_GUEST:{
    return{
      ...state,
      loader: false,
      dataSearchGuest:[],
      dataMainGuestById:[],
      processStatus:false,
      process:{status:false,action:"",message:"",notiKey:""}
    }
  }
  case SEARCH_GUEST_PREVIEW:{
    return{
      ...state,
      loader:true
    }
  }
  case GET_GUEST:{
    return{
      ...state,
      loader:true
    }
  }
  case GET_GUEST_PROCESS_SUCCESS:{
    return {
      ...state,
      loader: false,
      dataGuestById: action.payload
    }
  }
  case GET_MAIN_GUEST:{
    return {
      ...state,
      loader: false,
      dataMainGuestById: action.payload
    }
  }
  case SEARCH_GUEST_DATASOURCE_SUCCESS:{
    return {
      ...state,
      loader: false,
      dataSearchGuest: action.payload
    }
  }
  default:
    return state
}
}
