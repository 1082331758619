import * as actions from "./transferActionType"

export const transferFolioFirstPreview = (payload?: any): actions.TransferFolioFirstRequest => {
  return {
    type: actions.TRANSFER_FOLIO_FIRST_REQUEST,
    payload
  }
}
export const transferFolioFirstDataSourceSuccess = (payload:any):actions.TransferFolioFirstDataSourceSuccess => {
  return {
    type: actions.TRANSFER_FOLIO_FIRST_DATASOURCE_SUCCESS,
    payload
  }
}

export const transferFolioSecondPreview = (payload:number):actions.TransferFolioSecondRequest=>{
  return{
    type: actions.TRANSFER_FOLIO_SECOND_REQUEST,
    payload
  }
}
export const TransferFolioSecondDataSourceSuccess = (payload:any):actions.TransferFolioSecondDataSourceSuccess=>{
  return{
    type: actions.TRANSFER_FOLIO_SECOND_DATASOURCE_SUCCESS,
    payload
  }
}

export const transferfolioAdvanceRequest = (payload:any):actions.TransferFolioAdvanceRequest => {
  return {
    type: actions.TRANSFER_FOLIO_ADVANCE_REQUEST,
    payload
  }
}
export const TransferFolioAdvanceDataSourceSuccess = (value:number):actions.TransferFolioAdvanceDataSourceSuccess=>{
  return{
    type:actions.TRANSFER_FOLIO_ADVANCE_DATASOURCE_SUCCESS,
    payload:value
  }
}

export const transferTransaction = (value:any):actions.TransferTransaction =>{
  return{
    type: actions.TRANSFER_TRANSACTION,
    payload:value
  }
}

export const transferSetTargetFirstFolio = (value:number):actions.TransferSetTargetFirstFolio =>{
  return{
    type: actions.TRANSFER_SET_TARGET_FIRST_FOLIO,
    payload:value
  }
}
export const transferSetTargetSecondFolio = (value:number):actions.TransferSetTargetSecondFolio =>{
  return{
    type: actions.TRANSFER_SET_TARGET_SECOND_FOLIO,
    payload:value
  }
}
export const transferDataFirstToSecond = (value:any):actions.TransferDataFirstToSecond =>{
  return{
    type: actions.TRANSFER_DATA_FIRST_TO_SECOND,
    payload:value
  }
}
export const transferDataSecondToFirst = (value:any):actions.TransferDataSecondToFirst =>{
  return{
    type: actions.TRANSFER_DATA_SECOND_TO_FIRST,
    payload:value
  }
}

export const transferProcessSuccess = (payload:any):actions.TransferFolioProcessSuccess =>{
  return{
    type: actions.TRANSFER_FOLIO_PROCESS_SUCCESS,
    payload
  }
}
export const transferFolioProcessReset = () =>{
  return{
    type:actions.TRANSFER_FOLIO_PROCESS_RESET
  } 
}
