import {
    LISTBOX_ROOMTYPE,
    LISTBOX_ROOMTYPE_SUCCESS,
    LISTBOX_COUNTRY,
    LISTBOX_COUNTRY_SUCCESS,
    LISTBOX_CHANNEL_TYPE,
    LISTBOX_CHANNEL_TYPE_SUCCESS,
    LISTBOX_ORIGIN,
    LISTBOX_ORIGIN_SUCCESS,
    LISTBOX_SALES,
    LISTBOX_SALES_SUCCESS,
    LISTBOX_MARKET,
    LISTBOX_MARKET_SUCCESS,
    LISTBOX_NATION,
    LISTBOX_NATION_SUCCESS,
    LISTBOX_FOLIO,
    LISTBOX_FOLIO_SUCCESS,
    LISTBOX_ROOMGROUP,
    LISTBOX_ROOMGROUP_SUCCESS,
    LISTBOX_INCLUSION,
    LISTBOX_INCLUSION_SUCCESS,
    LISTBOX_RATEPLAN,
    LISTBOX_RATEPLAN_SUCCESS,
    LISTBOX_VIP,
    LISTBOX_VIP_SUCCESS,
    LISTBOX_FACILITY,
    LISTBOX_FACILITY_SUCCESS,
    LISTBOX_BEDTYPE,
    LISTBOX_BEDTYPE_SUCCESS,
    LISTBOX_BUILDING,
    LISTBOX_BUILDING_SUCCESS,
    LISTBOX_LOCATION,
    LISTBOX_LOCATION_SUCCESS,
    LISTBOX_EXPOSURE,
    LISTBOX_EXPOSURE_SUCCESS,
    LISTBOX_ROOMINFO,
    LISTBOX_ROOMINFO_SUCCESS,
    LISTBOX_RESERVATION_TYPE,
    LISTBOX_RESERVATION_TYPE_SUCCESS,
    LISTBOX_TITLENAME,
    LISTBOX_TITLENAME_SUCCESS,
    LISTBOX_OCCUPATION,
    LISTBOX_OCCUPATION_SUCCESS,
    LISTBOX_OUT_OF_ORDER,
    LISTBOX_OUT_OF_ORDER_SUCCESS,
    LISTBOX_CITY,
    LISTBOX_CITY_SUCCESS,
    LISTBOX_SOCIAL,
    LISTBOX_SOCIAL_SUCCESS,
    LISTBOX_LANGUAGE,
    LISTBOX_LANGUAGE_SUCCESS,
    LISTBOX_CHANNELS,
    LISTBOX_CHANNELS_SUCCESS,
    LISTBOX_MEDIA,
    LISTBOX_MEDIA_SUCCESS,
    LISTBOX_ALERT_PAGE,
    LISTBOX_ALERT_PAGE_SUCCESS,
    LISTBOX_FOLLOW_UP_CATEGORY,
    LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS,
    LISTBOX_FOLLOW_UP_STATUS,
    LISTBOX_FOLLOW_UP_STATUS_SUCCESS,
    LISTBOX_FOLLOW_UP_ASSIGN_USER,
    LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS,
    LISTBOX_USER,
    LISTBOX_USER_SUCCESS,
    LISTBOX_MEDIA_GROUP,
    LISTBOX_MEDIA_GROUP_SUCCESS,
    LISTBOX_DEPARTMENT,
    LISTBOX_DEPARTMENT_SUCCESS,
    LISTBOX_DEPARTMENT_GROUP,
    LISTBOX_DEPARTMENT_GROUP_SUCCESS,
    LISTBOX_CHANNEL_GROUP,
    LISTBOX_CHANNEL_GROUP_SUCCESS,
    LISTBOX_FOLIO_DETAIL,
    LISTBOX_FOLIO_DETAIL_SUCCESS,
    LISTBOX_FOLIO_PAYMENT_CODE,
    LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS,
    LISTBOX_ITEMIZER,
    LISTBOX_ITEMIZER_SUCCESS,
    LISTBOX_LOADALL
  } from "constants/ActionTypes"

  export const listBoxRoomType = () => {
    return {
      type: LISTBOX_ROOMTYPE
    }
  }
  export const listBoxRoomTypeSuccess = (value) =>{
    return {
      type: LISTBOX_ROOMTYPE_SUCCESS,
      payload: value
    }
  }

  export const listBoxCountry = () => {
    return {
      type: LISTBOX_COUNTRY
    }
  }
  export const listBoxCountrySuccess = (value) =>{
    return {
      type: LISTBOX_COUNTRY_SUCCESS,
      payload: value
    }
  }

  export const listBoxChannelType = () => {
    return {
      type: LISTBOX_CHANNEL_TYPE
    }
  }
  export const listBoxChannelTypeSuccess = (value) =>{
    return {
      type: LISTBOX_CHANNEL_TYPE_SUCCESS,
      payload: value
    }
  }

  export const listBoxOrigin = () => {
    return {
      type: LISTBOX_ORIGIN
    }
  }
  export const listBoxOriginSuccess = (value) => {
    return {
      type: LISTBOX_ORIGIN_SUCCESS,
      payload: value
    }
  }
  export const listBoxSales = () => {
    return {
      type: LISTBOX_SALES
    }
  }
  export const listBoxSalesSuccess = (value) => {
    return {
      type: LISTBOX_SALES_SUCCESS,
      payload: value
    }
  }
export const listBoxMarket = () => {
  return {
    type: LISTBOX_MARKET
  }
}
export const listBoxMarketSuccess = (value) => {
  return {
    type: LISTBOX_MARKET_SUCCESS,
    payload: value
  }
}
export const listBoxNation = () =>{
  return{
    type: LISTBOX_NATION
  }
}
export const listBoxNationSuccess = (value) =>{
  return{
    type: LISTBOX_NATION_SUCCESS,
    payload:value
  }
}
export const listBoxFolio = () =>{
  return{
    type: LISTBOX_FOLIO
  }
}
export const listBoxFolioSuccess = (value) =>{
  return{
    type: LISTBOX_FOLIO_SUCCESS,
    payload:value
  }
}
export const listBoxRoomGroup = () =>{
  return{
    type:LISTBOX_ROOMGROUP
  }
}
export const listBoxRoomGroupSuccess = (value)=>{
  return {
    type: LISTBOX_ROOMGROUP_SUCCESS,
    payload:value
  }
}
export const listBoxInclusion = ()=>{
  return{
    type:LISTBOX_INCLUSION
  }
}
export const listBoxInclusionSuccess = (value)=>{
  return {
    type: LISTBOX_INCLUSION_SUCCESS,
    payload:value
  }
}
export const listBoxRatePlan = ()=>{
  return{
    type:LISTBOX_RATEPLAN
  }
}
export const listBoxRatePlanSuccess = (value)=>{
  return {
    type: LISTBOX_RATEPLAN_SUCCESS,
    payload:value
  }
}
export const listBoxVIP = ()=>{
  return{
    type:LISTBOX_VIP
  }
}
export const listBoxVIPSuccess = (value)=>{
  return {
    type: LISTBOX_VIP_SUCCESS,
    payload:value
  }
}
export const listBoxFacility = () =>{
  return{
    type:LISTBOX_FACILITY
  }
}
export const listBoxFacilitySuccess = (value)=>{
  return {
    type: LISTBOX_FACILITY_SUCCESS,
    payload:value
  }
}

export const listBoxBedType = () =>{
  return{
    type:LISTBOX_BEDTYPE
  }
}
export const listBoxBedTypeSuccess = (value)=>{
  return {
    type: LISTBOX_BEDTYPE_SUCCESS,
    payload:value
  }
}
export const listBoxBuilding = () =>{
  return{
    type:LISTBOX_BUILDING
  }
}
export const listBoxBuildingSuccess = (value)=>{
  return {
    type: LISTBOX_BUILDING_SUCCESS,
    payload:value
  }
}
export const listBoxLocation = () =>{
  return{
    type:LISTBOX_LOCATION
  }
}
export const listBoxLocationSuccess = (value)=>{
  return {
    type: LISTBOX_LOCATION_SUCCESS,
    payload:value
  }
}
export const listBoxExposure = () =>{
  return{
    type:LISTBOX_EXPOSURE
  }
}
export const listBoxExposureSuccess = (value)=>{
  return {
    type: LISTBOX_EXPOSURE_SUCCESS,
    payload:value
  }
}
export const listBoxRoomInfo = () =>{
  return{
    type:LISTBOX_ROOMINFO
  }
}
export const listBoxRoomInfoSuccess = (value)=>{
  return {
    type: LISTBOX_ROOMINFO_SUCCESS,
    payload:value
  }
}

export const listReservationType = () =>{
  return{
    type:LISTBOX_RESERVATION_TYPE
  }
}
export const listReservationTypeSuccess = (value)=>{
  return {
    type: LISTBOX_RESERVATION_TYPE_SUCCESS,
    payload:value
  }
}

export const listBoxTitle = () =>{
  return {
    type: LISTBOX_TITLENAME
  }
}
export const listBoxTitleSuccess = (value)=>{
  return {
    type:LISTBOX_TITLENAME_SUCCESS,
    payload:value
  }
}
export const listBoxOccupation = () =>{
  return{
    type:LISTBOX_OCCUPATION
  }
}
export const listBoxOccupationSuccess = (value)=>{
  return{
    type:LISTBOX_OCCUPATION_SUCCESS,
    payload:value
  }
}
export const listBoxOutOfOrder = ()=>{
  return{
    type:LISTBOX_OUT_OF_ORDER
  }
}
export const listBoxOutOfOrderSuccess = (value) =>{
  return{
    type:LISTBOX_OUT_OF_ORDER_SUCCESS,
    payload:value
  }
}
export const listBoxCity = ()=>{
  return{
    type:LISTBOX_CITY
  }
}

export const listBoxCitySuccess = (value) =>{
  return{
    type:LISTBOX_CITY_SUCCESS,
    payload:value
  }
}
export const listBoxSocial = ()=>{
  return{
    type:LISTBOX_SOCIAL
  }
}
export const listBoxSocialSuccess = (value) =>{
  return{
    type:LISTBOX_SOCIAL_SUCCESS,
    payload:value
  }
}
export const listBoxLanguage = ()=>{
  return{
    type:LISTBOX_LANGUAGE
  }
}
export const listBoxLanguageSuccess = (value) =>{
  return{
    type:LISTBOX_LANGUAGE_SUCCESS,
    payload:value
  }
}
export const listBoxChannels = ()=>{
  return{
    type:LISTBOX_CHANNELS
  }
}
export const listBoxChannelsSuccess = (value) =>{
  return{
    type:LISTBOX_CHANNELS_SUCCESS,
    payload:value
  }
}

export const listBoxChannelGroup = ()=>{
  return{
    type:LISTBOX_CHANNEL_GROUP
  }
}
export const listBoxChannelGroupSuccess = (value) =>{
  return{
    type:LISTBOX_CHANNEL_GROUP_SUCCESS,
    payload:value
  }
}

export const listBoxMedia = ()=>{
  return{
    type:LISTBOX_MEDIA
  }
}
export const listBoxMediaSuccess = (value) =>{
  return{
    type:LISTBOX_MEDIA_SUCCESS,
    payload:value
  }
}
export const listBoxMediaGroup = ()=>{
  return{
    type:LISTBOX_MEDIA_GROUP
  }
}
export const listBoxMediaGroupSuccess = (value) =>{
  return{
    type:LISTBOX_MEDIA_GROUP_SUCCESS,
    payload:value
  }
}
export const listBoxAlertPage = ()=>{
  return{
    type:LISTBOX_ALERT_PAGE
  }
}
export const listBoxAlertPageSuccess = (value) =>{
  return{
    type:LISTBOX_ALERT_PAGE_SUCCESS,
    payload:value
  }
}
export const listBoxFollowUpCategory = ()=>{
  return{
    type:LISTBOX_FOLLOW_UP_CATEGORY
  }
}
export const listBoxFollowUpCategorySuccess = (value) =>{
  return{
    type:LISTBOX_FOLLOW_UP_CATEGORY_SUCCESS,
    payload:value
  }
}
export const listBoxFollowUpStatus = ()=>{
  return{
    type:LISTBOX_FOLLOW_UP_STATUS
  }
}
export const listBoxFollowUpStatusSuccess = (value) =>{
  return{
    type:LISTBOX_FOLLOW_UP_STATUS_SUCCESS,
    payload:value
  }
}
export const listBoxFollowUpAssignUser = (value)=>{
  return {
    type: LISTBOX_FOLLOW_UP_ASSIGN_USER,
    payload:value
  }
}
export const listBoxFollowUpAssignUserSuccess = (value) =>{
  return{
    type:LISTBOX_FOLLOW_UP_ASSIGN_USER_SUCCESS,
    payload:value
  }
}
export const listBoxUser = (value)=>{
  return{
    type:LISTBOX_USER,
    payload:value
  }
}
export const listBoxUserSuccess = (value)=>{
  return{
    type:LISTBOX_USER_SUCCESS,
    payload:value
  }
}
export const listBoxDepartment = ()=>{
  return{
    type:LISTBOX_DEPARTMENT
  }
}
export const listBoxDepartmentSuccess = (value) =>{
  return{
    type:LISTBOX_DEPARTMENT_SUCCESS,
    payload:value
  }
}
export const listBoxDepartmentGroup = ()=>{
  return{
    type:LISTBOX_DEPARTMENT_GROUP
  }
}
export const listBoxDepartmentGroupSuccess = (value) =>{
  return{
    type:LISTBOX_DEPARTMENT_GROUP_SUCCESS,
    payload:value
  }
}

export const listBoxFolioDetail = (value) =>{
  return{
    type:LISTBOX_FOLIO_DETAIL,
    payload:value
  }
}

export const listBoxFolioDetailSuccess = (value) =>{
  return{
    type:LISTBOX_FOLIO_DETAIL_SUCCESS,
    payload:value
  }
}

export const listBoxFolioPaymentCode = () =>{
  return{
    type:LISTBOX_FOLIO_PAYMENT_CODE
  }
}
export const listBoxFolioPaymentCodeSuccess = (value) =>{
  return{
    type:LISTBOX_FOLIO_PAYMENT_CODE_SUCCESS,
    payload:value
  }
}
export const listBoxItemizer = () =>{
  return{
    type:LISTBOX_ITEMIZER
  }
}
export const listBoxItemizerSuccess = (value) =>{
  return{
    type:LISTBOX_ITEMIZER_SUCCESS,
    payload:value
  }
}

export const listBoxLoadAll = () =>{
    return{
      type: LISTBOX_LOADALL
    }
}
