import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  INCLUSION_PREVIEW,
  INCLUSION_NEW,
  INCLUSION_UPDATE,
  INCLUSION_REMOVE,
  INCLUSION_SEARCH
} from "constants/ActionTypes"

import {inclusionDataSourceUpdate,inclusionProcessSuccess,inclusionProcessReset} from "appRedux/actions/setup/miscellaneous/Inclusion"
import {userSignOut} from "appRedux/actions/Auth"
import { getItems } from "util/localStorage"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"

function* inclusionPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/inclusion/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)
      if(response.status === 200)
      {
            const inclusionDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.inclusion_id = obj.id
                    container.inclusion_name = obj.name
                    container.description = obj.description||""
                    container.multiplier = obj.multiplier||""
                    container.multiplier_name = obj.multiplier_name||""
                    container.frequency = obj.frequency||""
                    container.frequency_name = obj.frequency_name||""
                    container.allow_multiple = obj.allow_multiple
                    container.charge_rate = obj.charge_rate
                    container.charge_rate_format = Intl.NumberFormat().format(obj.charge_rate)
                    container.department_id = obj.charge_dept
                    container.department_name = obj.charge_department||""
                return container
            })
            /* if no error put everything to reducer */
            yield put(inclusionDataSourceUpdate(inclusionDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreInclusionList",inclusionDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(inclusionProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on Inclusion preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(inclusionProcessSuccess(processStatus))
    }
    
}

function* inclusionSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreInclusionList')
  const ReformalData = [...storeValue[0]] 
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_inclusion_name = filter(ReformalData, function(o) {
        return o.inclusion_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_inclusion_name])]

      yield put(inclusionDataSourceUpdate(mergeData))
  }else 
      yield put(inclusionDataSourceUpdate(ReformalData))
}

function* inclusionCreateProcess({payload}){
  const inclusion_name = payload.inclusion_name
  const description = payload.description||null
  const multiplier = parseInt(payload.multiplier)||null
  const frequency = parseInt(payload.frequency)||null
  const allow_multiple = payload.allow_multiple
  const charge_dept = payload.department_id||null
  const charge_rate = parseFloat(payload.charge_rate)||null

  let inclusionInfo = {}
      inclusionInfo.name = inclusion_name
      inclusionInfo.description = description
      inclusionInfo.multiplier = multiplier
      inclusionInfo.frequency = frequency
      inclusionInfo.allow_multiple = allow_multiple
      inclusionInfo.charge_dept = charge_dept
      inclusionInfo.charge_rate = charge_rate

      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/inclusion/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = inclusionInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success." }
              yield put(inclusionProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(inclusionProcessSuccess(processStatus))
              yield put(inclusionProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(inclusionProcessSuccess(processStatus))
            yield put(inclusionProcessReset())
      }
}

function* inclusionUpdateProcess({payload}){

  const inclusion_id = payload.inclusion_id
  const inclusion_name = payload.inclusion_name
  const description = payload.description||null
  const multiplier = parseInt(payload.multiplier)||null
  const frequency = parseInt(payload.frequency)||null
  const allow_multiple = payload.allow_multiple
  const charge_dept = payload.department_id||null
  const charge_rate = parseFloat(payload.charge_rate)||null
 
  let inclusionInfo = {}
      inclusionInfo.id = inclusion_id
      inclusionInfo.name = inclusion_name
      inclusionInfo.description = description
      inclusionInfo.multiplier = multiplier
      inclusionInfo.frequency = frequency
      inclusionInfo.allow_multiple = allow_multiple
      inclusionInfo.charge_dept = charge_dept
      inclusionInfo.charge_rate = charge_rate
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/inclusion/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = inclusionInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(inclusionProcessSuccess(processStatus))
            yield put(inclusionProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(inclusionProcessSuccess(processStatus))
          yield put(inclusionProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(inclusionProcessSuccess(processStatus))
        yield put(inclusionProcessReset())
  }
}

function* inclusionRemoveProcess({payload}){
    const inclusionRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/inclusion/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : inclusionRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(inclusionProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(inclusionProcessSuccess(processStatus))
          yield put(inclusionProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(inclusionProcessSuccess(processStatus))
      yield put(inclusionProcessReset())
    }
}

/* Watcher Saga */
export function* inclusionPreview() {
  yield takeLatest(INCLUSION_PREVIEW, inclusionPreviewProcess)
}
export function* inclusionSearch(){
  yield takeLatest(INCLUSION_SEARCH, inclusionSearchProcess)
}
export function* inclusionCreate(){
  yield takeEvery(INCLUSION_NEW,inclusionCreateProcess)
}
export function* inclusionUpdate(){
  yield takeEvery(INCLUSION_UPDATE, inclusionUpdateProcess)
}
export function* inclusiontRemove(){
  yield takeEvery(INCLUSION_REMOVE,inclusionRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(inclusionPreview),
             fork(inclusionCreate),
             fork(inclusionUpdate),
             fork(inclusiontRemove)])


}
