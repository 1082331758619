import {
    RATE_APPROVE_PREVIEW,
    RATE_APPROVE_UPDATE,
    RATE_APPROVE_UPDATE_ALL,
    RATE_APPROVE_PROCESS_SUCCESS,
    RATE_APPROVE_DATASOURCE_SUCCESS,
    RATE_APPROVE_PROCESS_RESET
} from "constants/ActionTypes"

export const rateApprovePreview = () => {
    return {
    type: RATE_APPROVE_PREVIEW
    }
}

export const rateApproveUpdate = (value) =>{
    return {
    type:RATE_APPROVE_UPDATE,
    payload: value
    }
}

export const rateApproveUpdateAll = (value)=>{
    return {
    type:RATE_APPROVE_UPDATE_ALL,
    payload:value
    }
}

export const rateApproveProcessSuccess = (value) =>{
    return{
    type:RATE_APPROVE_PROCESS_SUCCESS,
    payload:value
    }
}

export const rateApproveDataSourceUpdate = (value) => {
    return {
    type: RATE_APPROVE_DATASOURCE_SUCCESS,
    payload: value
    }
}
export const rateApproveProcessReset = () =>{
    return{
    type:RATE_APPROVE_PROCESS_RESET
    }
}

