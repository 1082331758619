import * as actions from "./BlockRoomActionType"

export const blockRoomPreview = (payload?: any): actions.BlockRoomRequest => {
  return {
    type: actions.BLOCKROOM_REQUEST,
    payload
  }
}

export const blockRoomByRoomNumber = (payload: any): actions.BlockRoomByRoomNumber => {
  return {
    type: actions.BLOCKROOM_BY_ROOM_NUMBER,
    payload
  }
}

export const blockRoomByRoomID = (payload: any): actions.BlockRoomByRoomID => {
  return {
    type: actions.BLOCKROOM_BY_ROOM_ID,
    payload
  }
}

export const blockRoomUnblock = (payload: any): actions.BlockRoomUnblock => {
  return {
    type: actions.BLOCKROOM_UNBLOCK,
    payload
  }
}
export const blockRoomUnblockAll = (payload: any): actions.BlockRoomUnblockAll => {
  return {
    type: actions.BLOCKROOM_UNBLOCK_ALL,
    payload
  }
}
export const blockRoomAutoBlock = (payload: any): actions.BlockRoomAutoBlock => {
  return {
    type: actions.BLOCKROOM_AUTO_BLOCK,
    payload
  }
}
export const blockRoomChangeRoom = (payload:any):actions.BlockRoomChangeRoom => {
  return {
    type: actions.BLOCKROOM_CHANGE_ROOM,
    payload
  }
}
export const blockRoomSetSuperBlock = (payload:any):actions.BlockRoomSetSuperBlock=>{
  return{
    type: actions.BLOCKROOM_SET_SUPERBLOCK,
    payload
  }
}
export const blockRoomDatasouceSuccess = (payload:any):actions.BlockRoomDataSourceSuccess=>{
  return{
    type: actions.BLOCKROOM_DATASOURCE_SUCCESS,
    payload
  }
}


export const blockRoomProcessSuccess = (payload:any):actions.BlockRoomProcessSuccess =>{
  return{
    type: actions.BLOCKROOM_PROCESS_SUCCESS,
    payload
  }
}

export const blockRoomProcessReset = ():actions.BlockRoomProcessReset =>{
  return{
    type: actions.BLOCKROOM_PROCESS_RESET
  } 
}
