// This file (ApiConfifs.ts) will be instead file ApiSetting.js in the future

import { AxiosRequestConfig } from "axios"
import qs from 'qs'

export const API_URL = process.env.REACT_APP_API_URL

/**
 * ### Request from API
 * #### Header
 * `Example: Key: Authorization, Value: 'PPP'`
 * 
 * `How to use: RequestConfig.headers = {
 *                  Authorization: 'PPP'
 *              }`
 * #### Method GET 
 * `Example: http://API/path1/?type=R&status=A`
 * 
 * `How to use: RequestConfig.params = { 
 *                  type: 'O',
 *                  status: 'A'
 *              }`
 */
export let RequestConfig: AxiosRequestConfig = {
    headers: { 
        'Content-Type':'application/json',
        'x-api-key': process.env.REACT_APP_API_PMS_KEY
    },
    paramsSerializer: (params) => qs.stringify(params)
}

// export let RequestConfig = (baseURL: string): AxiosRequestConfig {
//     baseURL: API_URL
// }