import {
    SOCIAL_PREVIEW,
    SOCIAL_NEW,
    SOCIAL_UPDATE,
    SOCIAL_REMOVE,
    SOCIAL_SEARCH,
    SOCIAL_PROCESS_SUCCESS,
    SOCIAL_DATASOURCE_SUCCESS,
    SOCIAL_PROCESS_RESET
  } from "constants/ActionTypes"

  export const socialPreview = () => {
    return {
      type: SOCIAL_PREVIEW
    }
  }

  export const socialCreate =(value) =>{
    return {
      type:SOCIAL_NEW,
      payload:value
    }
  }

  export const socialUpdate = (value) =>{
    return {
      type:SOCIAL_UPDATE,
      payload: value
    }
  }

  export const socialRemove = (value)=>{
    return {
      type:SOCIAL_REMOVE,
      payload:value
    }
  }

  export const socialSearch = (keyword) =>{
    return {
      type: SOCIAL_SEARCH,
      payload:keyword
    }
  }

  export const socialProcessSuccess = (value) =>{
    return{
      type:SOCIAL_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const socialDataSourceUpdate = (value) => {
    return {
      type: SOCIAL_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const socialProcessReset = () => {
    return{
      type:SOCIAL_PROCESS_RESET
    }
  }

