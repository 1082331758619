import {
    INCLUSION_PREVIEW,
    INCLUSION_NEW,
    INCLUSION_UPDATE,
    INCLUSION_REMOVE,
    INCLUSION_SEARCH,
    INCLUSION_PROCESS_SUCCESS,
    INCLUSION_DATASOURCE_SUCCESS,
    INCLUSION_PROCESS_RESET
  } from "constants/ActionTypes"

  export const inclusionPreview = () => {
    return {
      type: INCLUSION_PREVIEW
    }
  }

  export const inclusionCreate =(value) =>{
    return {
      type:INCLUSION_NEW,
      payload:value
    }
  }

  export const inclusionUpdate = (value) =>{
    return {
      type:INCLUSION_UPDATE,
      payload: value
    }
  }

  export const inclusionRemove = (value)=>{
    return {
      type:INCLUSION_REMOVE,
      payload:value
    }
  }

  export const inclusionSearch = (keyword) =>{
    return {
      type: INCLUSION_SEARCH,
      payload:keyword
    }
  }

  export const inclusionProcessSuccess = (value) =>{
    return{
      type:INCLUSION_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const inclusionDataSourceUpdate = (value) => {
    return {
      type: INCLUSION_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const inclusionProcessReset = () =>{
    return{
      type:INCLUSION_PROCESS_RESET
    }
  }
