import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  BUILDING_PREVIEW,
  BUILDING_NEW,
  BUILDING_UPDATE,
  BUILDING_REMOVE,
  BUILDING_SEARCH
} from "constants/ActionTypes"

import {buildingDataSourceUpdate,buildingProcessSuccess,buildingProcessReset} from "appRedux/actions/setup/roomInformation/Building"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* buildingPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/building/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const buildingDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.building_id = obj.id
                container.building_name = obj.name
          return container
          })
            
            /* if no error put everything to reducer */
             yield put(buildingDataSourceUpdate(buildingDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreBuildingList",buildingDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(buildingProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on building preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(buildingProcessSuccess(processStatus))
    }
    
}

function* buildingSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreBuildingList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.building_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(buildingDataSourceUpdate(mergeData))
  }else 
      yield put(buildingDataSourceUpdate(ReformalData))
}

function* buildingCreateProcess({payload}){
 
  const {building_name} = payload
  
  let buildingInfo = {}
      buildingInfo.name = building_name
   
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/building/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = buildingInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(buildingProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(buildingProcessSuccess(processStatus))
              yield put(buildingProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(buildingProcessSuccess(processStatus))
            yield put(buildingProcessReset())
      }

}

function* buildingUpdateProcess({payload}){

  const {building_id,building_name} = payload
  
  let buildingInfo = {}
      buildingInfo.id = building_id
      buildingInfo.name = building_name
   
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/building/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = buildingInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "update", message:"Update success."}
                yield put(buildingProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message}
              yield put(buildingProcessSuccess(processStatus))
              yield put(buildingProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error}
            yield put(buildingProcessSuccess(processStatus))
            yield put(buildingProcessReset())
      }
}

function* buildingRemoveProcess({payload}){
    const buildingRemove = payload
    
    console.log("Delete id ",buildingRemove)

    try{
      const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/building/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : buildingRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(buildingProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(buildingProcessSuccess(processStatus))
          yield put(buildingProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(buildingProcessSuccess(processStatus))
      yield put(buildingProcessReset())
    }
}

/* Watcher Saga */
export function* buildingPreview() {
  yield takeLatest(BUILDING_PREVIEW, buildingPreviewProcess)
}
export function* buildingSearch(){
  yield takeLatest(BUILDING_SEARCH, buildingSearchProcess)
}
export function* buildingCreate(){
  yield takeLatest(BUILDING_NEW,buildingCreateProcess)
}
export function* buildingUpdate(){
  yield takeLatest(BUILDING_UPDATE, buildingUpdateProcess)
}
export function* buildingRemove(){
  yield takeLatest(BUILDING_REMOVE,buildingRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(buildingPreview),
             fork(buildingCreate),
             fork(buildingSearch),
             fork(buildingUpdate),
             fork(buildingRemove)])


}
