import React from 'react'
import { Card, Divider, Popconfirm, Tooltip } from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPrint, faTrash,faEdit,faClock,faUsers,faExclamation,faPencilAlt,faEye,faBell } from "@fortawesome/free-solid-svg-icons"
import styled from 'styled-components'
import {isEmpty} from "lodash"

const CardStyle = styled(Card)`
  border-radius:10px;
  margin:10px;
  padding:10px;
  :hover{
    background:aliceblue;
  }
  & .messageBox{
    cursor:pointer;
  }
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
  margin-right:10px;
  cursor:pointer;
`

const IconMessage = (props) =>{
      const {toolTipPlacement,toolTipTitle,toolTipTigger,fontIcon,fontSize,message} = props
      
      return (
      <span style={{ marginRight: 20 }}>
        <Tooltip placement={toolTipPlacement} title={toolTipTitle} trigger={toolTipTigger}>
            <FontAwesomeIconStyle icon={fontIcon} size={fontSize} />{message}
        </Tooltip>
      </span>
      )
}

const FollowUpMessage = (props) => {
  const {
  followTopic,
  followMessage,
  followTime,
  entryBy,
  followID,
  followCategoryID,
  followStatusID,
  assignUserID,
  alertLocationID,
  alertDate,
  alertDateMessage,
  userSeen,
  handleUpdate,
  handleDelete,
  handleSelect,
  hideUpdate = false,
  hideDelete = false,
  hidePrint = true
} = props
  const toolTipAssignUser = assignUserID.map((value,key)=> (<li key={`assignUser-${key}`}>{value}</li>))
  const toolTipAlertLocation = alertLocationID.map((value,key)=> (<li key={`alert-${value.id}`}>{value.name}</li>))
  const toolTipUserSeen = isEmpty(userSeen)?"Seen":userSeen.map((value,key)=>(<li key={`userseen-${value.user}`}>{value.user}</li>))

  return (
    <CardStyle>
      <div className="messageBox" onClick={() => handleSelect({ followID })}>
        <div className="messageTitle">
          <h3>{followTopic}</h3>
        </div>
        <div style={{ textIndent: 25,whiteSpace:"pre-line" }}>{followMessage}</div>
      </div>
      <Divider />
      <div className="messageFooter">
        <IconMessage
          toolTipPlacement="top"
          toolTipTitle="Create Time"
          toolTipTigger="hover|click"
          fontIcon={faClock}
          fontSize="sm"
          message={followTime}
        />
        <IconMessage
          toolTipPlacement="top"
          toolTipTitle="Entry by"
          toolTipTigger="hover|click"
          fontIcon={faPencilAlt}
          fontSize="sm"
          message={entryBy}
        />
        <IconMessage
          toolTipPlacement="top"
          toolTipTitle={toolTipUserSeen}
          toolTipTigger="hover|click"
          fontIcon={faEye}
          fontSize="sm"
          message={userSeen.length}
        />
        {assignUserID.length > 0 ? (
          <IconMessage
            toolTipPlacement="top"
            toolTipTitle={toolTipAssignUser}
            toolTipTigger="hover|click"
            fontIcon={faUsers}
            fontSize="sm"
            message={assignUserID.length}
          />
        ) : ("")
        }
        {alertLocationID.length > 0 ? (
          <IconMessage
            toolTipPlacement="top"
            toolTipTitle={toolTipAlertLocation}
            toolTipTigger="hover|click"
            fontIcon={faExclamation}
            fontSize="sm"
            message={alertLocationID.length}
          />
        ) : ("")}
        {isEmpty(alertDate)?"":(
          <IconMessage
          toolTipPlacement="top"
          toolTipTitle="Alert Time"
          toolTipTigger="hover|click"
          fontIcon={faBell}
          fontSize="sm"
          message={alertDateMessage}
        />
        )}
        <span style={{ float: "right" }}>
          { hideUpdate ? "": 
          <FontAwesomeIconStyle
            icon={faEdit}
            size="lg"
            onClick={() =>
              handleUpdate({
                followMessage,
                followTopic,
                followID,
                followCategoryID,
                followStatusID,
                assignUserID,
                alertLocationID,
                alertDate
              })}
          />}
          {hidePrint ? "" : <FontAwesomeIconStyle icon={faPrint} size="lg" />}
          {hideDelete ? "" :
          <Popconfirm
            title="Are you confirm to delete?"
            onConfirm={() => handleDelete(followID)}
              cancelButtonProps={{ style: { paddingTop: 0 } }}
          >
            <FontAwesomeIconStyle icon={faTrash} size="lg" />
          </Popconfirm>
          }
        </span>
      </div>
    </CardStyle>
  );
}

export default FollowUpMessage