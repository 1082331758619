// This file (webStorage.ts) will be instead localStorage.js in the future

export interface IStorageItem {
    key: string;
    value: any;
}

export class StorageItem {
    private key: string
    private value: any

    constructor(data: IStorageItem) {
        this.key = data.key
        this.value = data.value
    }
}

// class for working with local storage in browser (common that can use other classes for store some data)
export class LocalStorage {
    localStorageSupported: boolean | undefined

    constructor() {
        this.localStorageSupported = typeof window['localStorage'] != "undefined" && window['localStorage'] != null
    }

    // get one item by key from storage
    public get(key: string): string | null {
        if (this.localStorageSupported) {
        const item = localStorage.getItem(key)
            return item
        } else {
            return null
        }
    }

}