import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  COUNTRY_PREVIEW,
  COUNTRY_NEW,
  COUNTRY_UPDATE,
  COUNTRY_REMOVE,
  COUNTRY_SEARCH
} from "constants/ActionTypes"

import {countryDataSourceUpdate,countryProcessSuccess,countryProcessReset} from "appRedux/actions/setup/market/Country"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* counryPreviewProcess({payload}){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/country/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const countryDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.country_id = obj.id
                    container.country_name = obj.name
                    container.country_code = obj.code
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(countryDataSourceUpdate(countryDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreCountryList",countryDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(countryProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on saga channel type : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(countryProcessSuccess(processStatus))
    }
    
}

function* countrySearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreCountryList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_country_name = filter(ReformalData, function(o) {
        return o.country_name.toLowerCase().indexOf(keyword) > -1
      })

       /* Search by code */
        const filtered_country_code = filter(ReformalData, function(o) {
        return o.country_code.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_country_name,...filtered_country_code])]

      yield put(countryDataSourceUpdate(mergeData))
  }else 
      yield put(countryDataSourceUpdate(ReformalData))
}

function* countryCreateProcess({payload}){
  const {country_name,active:countryActive} = payload

  let countryInfo = {name:country_name,
                    enabled:countryActive}

      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/country/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = countryInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(countryProcessSuccess(processStatus))
            }else{
                const {message,data} = response.data
                const processStatus = { status: false, action: "create", message }
                yield put(countryProcessSuccess(processStatus))
                yield put(countryProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }  
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(countryProcessSuccess(processStatus))
            yield put(countryProcessReset())
      }
}

function* countryUpdateProcess({payload}){
  const {country_id,active:countryActive} = payload
  
  let countryInfo = {}
      countryInfo.id = country_id
      countryInfo.enabled = countryActive
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/country/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = countryInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(countryProcessSuccess(processStatus))
        }else { 
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(countryProcessSuccess(processStatus))
            yield put(countryProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(countryProcessSuccess(processStatus))
        yield put(countryProcessReset())
  }
}

function* countryRemoveProcess({payload}){
    const countryRemove = payload

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/country/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : countryRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(countryProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(countryProcessSuccess(processStatus))
          yield put(countryProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(countryProcessSuccess(processStatus))
      yield put(countryProcessReset())
    }
}

/* Watcher Saga */
export function* countryPreview() {
  yield takeLatest(COUNTRY_PREVIEW, counryPreviewProcess)
}
export function* countrySearch(){
  yield takeLatest(COUNTRY_SEARCH, countrySearchProcess)
}
export function* countryCreate(){
  yield takeEvery(COUNTRY_NEW,countryCreateProcess)
}
export function* countryUpdate(){
  yield takeEvery(COUNTRY_UPDATE, countryUpdateProcess)
}
export function* countryRemove(){
  yield takeEvery(COUNTRY_REMOVE,countryRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(countryPreview),
             fork(countrySearch),
             fork(countryUpdate)])

}
