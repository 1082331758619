import { 
  AppAction, 
  CommonsAction, 
  FETCH_ERROR, 
  FETCH_START, 
  FETCH_SUCCESS, 
  HIDE_MESSAGE, 
  SHOW_MESSAGE 
} from "constants/ActionTypes"

/**
 * DYNAMIC_TYPE: function connect between actions and sagas(...-workers.ts)
 * @template T the type of the AppAction's `type` tag.
 * @template P the type of the AppAction's `payload` tag.
 */
export function commonAction<T, P>(type: T, payload: P): AppAction<T, P> {
  return {
      type,
      payload
  }
}

// Below function from Template 
export const fetchStart = (): CommonsAction => {
  return {
    type: FETCH_START,
    width: window.innerWidth
  }
}

export const fetchSuccess = (): CommonsAction => {
  return {
    type: FETCH_SUCCESS,
    width: window.innerWidth
  }
}

export const fetchError = (error: string): CommonsAction => {
  return {
    type: FETCH_ERROR,
    payload: error,
    width: window.innerWidth
  }
}

export const showMessage = (message: string): CommonsAction => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
    width: window.innerWidth
  }
}

export const hideMessage = (): CommonsAction => {
  return {
    type: HIDE_MESSAGE,
    width: window.innerWidth
  }
}