
export const CASHIER_BOOKING_FOLIO_REQUEST = "CASHIER_BOOKING_FOLIO_REQUEST"
export const CASHIER_BOOKING_FOLIO_REALTIME = "CASHIER_BOOKING_FOLIO_REALTIME"
export const CASHIER_BOOKING_FOLIO_PROCESS_SUCCESS = "CASHIER_BOOKING_FOLIO_PROCESS_SUCCESS"
export const CASHIER_BOOKING_FOLIO_DATASOURCE_SUCCESS = "CASHIER_BOOKING_FOLIO_DATASOURCE_SUCCESS"
export const CASHIER_BOOKING_FOLIO_PROCESS_RESET = "CASHIER_BOOKING_FOLIO_PROCESS_RESET"
export const CASHIER_BOOKING_FOLIO_SET_FILTER = "CASHIER_BOOKING_FOLIO_SET_FILTER"
export const CASHIER_BOOKING_FOLIO_CHECK_OUT = "CASHIER_BOOKING_FOLIO_CHECK_OUT"
export const CASHIER_BOOKING_FOLIO_CANCEL_CHECK_OUT = "CASHIER_BOOKING_FOLIO_CANCEL_CHECK_OUT"
export const CASHIER_BOOKING_FOLIO_CHECKOUT_LIST = "CASHIER_BOOKING_FOLIO_CHECKOUT_LIST"
export const CASHIER_BOOKING_FOLIO_CHECKOUT_LIST_SUCCESS = "CASHIER_BOOKING_FOLIO_CHECKOUT_LIST_SUCCESS"

export const CASHIER_BOOKING_FOLIO_TOGGLE_LOCK = "CASHIER_BOOKING_FOLIO_TOGGLE_LOCK"
export const CASHIER_BOOKING_FOLIO_TOGGLE_SUCCESS = "CASHIER_BOOKING_FOLIO_TOGGLE_SUCCESS"
export const CASHIER_BOOKING_CHANGE_ONLINE_SHIFT = "CASHIER_BOOKING_CHANGE_ONLINE_SHIFT"

export const CASHIER_BOOKING_FOLIO_DETAIL_REQUEST = "CASHIER_BOOKING_FOLIO_DETAIL_REQUEST"
export const CASHIER_BOOKING_FOLIO_DETAIL_DATASOURCE_SUCCESS = "CASHIER_BOOKING_FOLIO_DETAIL_DATASOURCE_SUCCESS"
export const CASHIER_BOOKING_INFO_UPDATE = "CASHIER_BOOKING_INFO_UPDATE"
export const CASHIER_BOOKING_INFO_UPDATE_SUCCESS = "CASHIER_BOOKING_INFO_UPDATE_SUCCESS"

export const CASHIER_BOOKING_FOLIO_POST = "CASHIER_BOOKING_FOLIO_POST"
export const CASHIER_BOOKING_FOLIO_VOID = "CASHIER_BOOKING_FOLIO_VOID"
export const CASHIER_BOOKING_FOLIO_UPDATE = "CASHIER_BOOKING_FOLIO_UPDATE"
export const CASHIER_BOOKING_FOLIO_PAYMENT = "CASHIER_BOOKING_FOLIO_PAYMENT"
export const CASHIER_BOOKING_FOLIO_SPLIT = "CASHIER_BOOKING_FOLIO_SPLIT"
export const CASHIER_BOOKING_FOLIO_MOVE = "CASHIER_BOOKING_FOLIO_MOVE"

export const CASHIER_BOOKING_CLOSE_FOLIO_SEQ = "CASHIER_BOOKING_CLOSE_FOLIO_SEQ"

export interface CashierBookingFolioRequest {
  type: typeof CASHIER_BOOKING_FOLIO_REQUEST,
  payload?:any,
  sorter?:any
}
export interface CashierBookingFolioRealTime {
  type: typeof CASHIER_BOOKING_FOLIO_REALTIME,
  payload?:any
}
export interface CashierBookingFolioProcessSuccess {
  type: typeof CASHIER_BOOKING_FOLIO_PROCESS_SUCCESS,
  payload:{}
}
export interface CashierBookingFolioDataSourceSuccess{
  type: typeof CASHIER_BOOKING_FOLIO_DATASOURCE_SUCCESS,
  payload:any
}
export interface CashierBookingFolioProcessReset {
  type: typeof CASHIER_BOOKING_FOLIO_PROCESS_RESET
}
export interface CashierBookingSetFilter{
  type: typeof CASHIER_BOOKING_FOLIO_SET_FILTER,
  payload:any
}
export interface CashierBookingCheckOut{
  type: typeof CASHIER_BOOKING_FOLIO_CHECK_OUT,
  payload:any
}
export interface CashierBookingCancelCheckOut{
  type: typeof CASHIER_BOOKING_FOLIO_CANCEL_CHECK_OUT,
  payload:any
}
export interface CashierBookingCheckOutList{
  type: typeof CASHIER_BOOKING_FOLIO_CHECKOUT_LIST,
  payload:any
}
export interface CashierBookingCheckOutListSuccess{
  type: typeof CASHIER_BOOKING_FOLIO_CHECKOUT_LIST_SUCCESS,
  payload:any
}

export interface CashierBookingFolioDetailRequest {
  type: typeof CASHIER_BOOKING_FOLIO_DETAIL_REQUEST,
  payload:number
}
export interface CashierBookingFolioDetailDataSourceSuccess{
  type: typeof CASHIER_BOOKING_FOLIO_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}

export interface CashierBookingFolioPost{
  type: typeof CASHIER_BOOKING_FOLIO_POST,
  payload:any
}

export interface CashierBookingFolioVoid{
  type: typeof CASHIER_BOOKING_FOLIO_VOID,
  payload:any
}

export interface CashierBookingFolioUpdate{
  type: typeof CASHIER_BOOKING_FOLIO_UPDATE,
  payload:any
}

export interface CashierBookingFolioPayment{
  type: typeof CASHIER_BOOKING_FOLIO_PAYMENT,
  payload:any
}

export interface CashierBookingFolioSplit{
  type: typeof CASHIER_BOOKING_FOLIO_SPLIT,
  payload:any
}
export interface CashierBookingFolioMove{
  type: typeof CASHIER_BOOKING_FOLIO_MOVE,
  payload:any
}

export interface CashierBookingCloseFolioSeq{
  type: typeof CASHIER_BOOKING_CLOSE_FOLIO_SEQ,
  payload:any
}

export interface CashierBookingToggleLock{
  type: typeof CASHIER_BOOKING_FOLIO_TOGGLE_LOCK,
  payload:any
}

export interface CashierBookingToggleSuccess{
  type: typeof CASHIER_BOOKING_FOLIO_TOGGLE_SUCCESS,
  payload:any
}

export interface CashierBookingInfoUpdate{
  type: typeof CASHIER_BOOKING_INFO_UPDATE,
  payload:any
}

export interface CashierBookingInfoUpdateSuccess{
  type: typeof CASHIER_BOOKING_INFO_UPDATE_SUCCESS,
  payload:any
}

export interface CashierBookingChangeOnlineShift{
  type: typeof CASHIER_BOOKING_CHANGE_ONLINE_SHIFT,
  payload:any
}


export type CashierAction = 
| CashierBookingFolioRequest
| CashierBookingFolioRealTime
| CashierBookingFolioProcessSuccess
| CashierBookingFolioDataSourceSuccess
| CashierBookingFolioProcessReset
| CashierBookingSetFilter
| CashierBookingCheckOut
| CashierBookingCancelCheckOut
| CashierBookingCheckOutList
| CashierBookingCheckOutListSuccess
| CashierBookingFolioDetailRequest
| CashierBookingFolioDetailDataSourceSuccess
| CashierBookingFolioPost
| CashierBookingFolioVoid
| CashierBookingFolioUpdate
| CashierBookingFolioPayment
| CashierBookingFolioSplit
| CashierBookingFolioMove
| CashierBookingCloseFolioSeq
| CashierBookingToggleLock
| CashierBookingToggleSuccess
| CashierBookingInfoUpdate
| CashierBookingInfoUpdateSuccess
| CashierBookingChangeOnlineShift
