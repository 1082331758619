import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  CITY_PREVIEW,
  CITY_NEW,
  CITY_UPDATE,
  CITY_REMOVE,
  CITY_SEARCH
} from "constants/ActionTypes"

import {cityDataSourceUpdate,cityProcessSuccess,cityProcessReset} from "appRedux/actions/setup/market/City"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* cityPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/city/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const cityDataSource = orderBy(response.data,[o => o.city_name],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.city_id = obj.id
                container.city_name = obj.city_name
                container.country_id = obj.country_id
                container.country_name = obj.country_name
          return container
          })

            /* if no error put everything to reducer */
            yield put(cityDataSourceUpdate(cityDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreCityList",cityDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(cityProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on cityInfo preview : ",error)
        const processStatus = { status: false, action: "preview", message:error } 
        yield put(cityProcessSuccess(processStatus))
    }
    
}

function* citySearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreCityList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.city_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(cityDataSourceUpdate(mergeData))
  }else 
      yield put(cityDataSourceUpdate(ReformalData))
}

function* cityCreateProcess({payload}){

  const {country_id,city_name} = payload
 
  let cityInfo = {}
      cityInfo.country_id = country_id
      cityInfo.name = city_name

      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/city/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = cityInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success" }
                yield put(cityProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(cityProcessSuccess(processStatus))
              yield put(cityProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(cityProcessSuccess(processStatus))
            yield put(cityProcessReset())
      }

}

function* roomInfoUpdateProcess({payload}){
  
  const {city_id,city_name,country_id} = payload
 
  let cityInfo = {}
      cityInfo.id = city_id
      cityInfo.name = city_name
      cityInfo.country_id = country_id

      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/city/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = cityInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "update", message:"Update success" }
                yield put(cityProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message }
              yield put(cityProcessSuccess(processStatus))
              yield put(cityProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error }
            yield put(cityProcessSuccess(processStatus))
            yield put(cityProcessReset())
      }
}

function* cityRemoveProcess({payload}){
    const cityRemoveID = payload
    

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/city/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : cityRemoveID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(cityProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(cityProcessSuccess(processStatus))
          yield put(cityProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(cityProcessSuccess(processStatus))
      yield put(cityProcessReset())
    }
}


/* Watcher Saga */
export function* cityPreview() {
  yield takeLatest(CITY_PREVIEW, cityPreviewProcess)
}
export function* citySearch(){
  yield takeLatest(CITY_SEARCH, citySearchProcess)
}
export function* cityCreate(){
  yield takeLatest(CITY_NEW,cityCreateProcess)
}
export function* cityUpdate(){
  yield takeLatest(CITY_UPDATE, roomInfoUpdateProcess)
}
export function* cityRemove(){
  yield takeLatest(CITY_REMOVE,cityRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(cityPreview),
             fork(cityCreate),
             fork(citySearch),
             fork(cityUpdate),
             fork(cityRemove)])


}
