import {
    DEPARTMENT_GROUP_PREVIEW,
    DEPARTMENT_GROUP_NEW,
    DEPARTMENT_GROUP_UPDATE,
    DEPARTMENT_GROUP_REMOVE,
    DEPARTMENT_GROUP_SEARCH,
    DEPARTMENT_GROUP_PROCESS_SUCCESS,
    DEPARTMENT_GROUP_DATASOURCE_SUCCESS,
    DEPARTMENT_GROUP_PROCESS_RESET
  } from "constants/ActionTypes"

  export const departmentGroupPreview = () => {
    return {
      type: DEPARTMENT_GROUP_PREVIEW
    }
  }

  export const departmentGroupCreate =(value) =>{
    return {
      type:DEPARTMENT_GROUP_NEW,
      payload:value
    }
  }

  export const departmentGroupUpdate = (value) =>{
    return {
      type:DEPARTMENT_GROUP_UPDATE,
      payload: value
    }
  }

  export const departmentGroupRemove = (value)=>{
    return {
      type:DEPARTMENT_GROUP_REMOVE,
      payload:value
    }
  }

  export const departmentGroupSearch = (keyword) =>{
    return {
      type: DEPARTMENT_GROUP_SEARCH,
      payload:keyword
    }
  }

  export const departmentGroupProcessSuccess = (value) =>{
    return{
      type:DEPARTMENT_GROUP_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const departmentGroupDataSourceUpdate = (value) => {
    return {
      type: DEPARTMENT_GROUP_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const departmentGroupProcessReset = () =>{
    return{
      type:DEPARTMENT_GROUP_PROCESS_RESET
    }
  }

