import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  FOLIO_PATTERN_DETAIL_PREVIEW,
  FOLIO_PATTERN_DETAIL_NEW,
  FOLIO_PATTERN_DETAIL_SYNC_CONFIRM
} from "constants/ActionTypes"

import {folioPatternDetailDataSourceUpdate,folioPatternDetailProcessSuccess} from "appRedux/actions/setup/folio/FolioPatternDetail"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { orderBy,isEmpty } from "lodash"
import { getItems, setItems } from "util/localStorage"


function* folioPatternDetailPreviewProcess({payload}){
    const folio_pattern_id = payload
    
    /* Call Department List */
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    let url = `${API_URL_V1}pms/masterfile/department/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const response = yield APIGetRequest(url,API_PMS_HEADERS)
    const departmentDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.department_id = obj.id
                    container.department_name = obj.name
                    container.enabled = obj.enabled

                return container
            })
    /* End call department API */   
        url = `${API_URL_V1}pms/masterfile/folioPatternDetails/${folio_pattern_id}`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
          
    const tasks = departmentDataSource.map(
      (val) => ({
        id: `task-${val.department_id}`,
        content: `${val.department_name}`
      })
    )
    const taskMap = tasks.reduce(
      (previous, current) => {
        previous[current.id] = current
        return previous
      },{}
    )
    const departmentList = {
      id: 'departmentList',
      title: 'Department',
      taskIds: tasks.map((task) => task.id)
    }
    let objInfo = []
        objInfo = [...objInfo,departmentList]
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
        const {data:{id,name,split_room_charge,enabled,folio1,folio2,folio3,folio4}} = response
        let folioPatternDetailDataSource = {}
            folioPatternDetailDataSource.folio_pattern_id = id
            folioPatternDetailDataSource.folio_pattern_name = name
            folioPatternDetailDataSource.split_room_charge = split_room_charge
            folioPatternDetailDataSource.enable = enabled
            folioPatternDetailDataSource.folio1 = folio1
            folioPatternDetailDataSource.folio2 = folio2
            folioPatternDetailDataSource.folio3 = folio3
            folioPatternDetailDataSource.folio4 = folio4

        let taskFiltered = []
        if(folio1)
        {
          taskFiltered = []
          if(folio1.departments)
          {
            taskFiltered = folio1.departments.map((task)=>`task-${task.id}`)
            const filterItems = departmentList.taskIds.filter(item => !taskFiltered.includes(item))
            departmentList.taskIds = [...filterItems]
          }
            objInfo = [...objInfo,{id:"folio_1",title:folio1.name,taskIds:taskFiltered}]
        }
        else
          objInfo = [...objInfo,{id:"folio_1",title:"",taskIds:taskFiltered,DragDisabled:false}]
                
        if(folio2)
        {
          taskFiltered = []
          if(folio2.departments)
          {
            taskFiltered = folio2.departments.map((task)=>`task-${task.id}`)
            const filterItems = departmentList.taskIds.filter(item => !taskFiltered.includes(item))
            departmentList.taskIds = [...filterItems]
          }
            objInfo = [...objInfo,{id:"folio_2",title:folio2.name,taskIds:taskFiltered}]
        }else
          objInfo = [...objInfo,{id:"folio_2",title:"",taskIds:[],DragDisabled:false}]

        if(folio3)
        {
          taskFiltered = []
          if(folio3.departments)
          {
            taskFiltered = folio3.departments.map((task)=>`task-${task.id}`)
            const filterItems = departmentList.taskIds.filter(item => !taskFiltered.includes(item))
            departmentList.taskIds = [...filterItems]
          }
            objInfo = [...objInfo,{id:"folio_3",title:folio3.name,taskIds:taskFiltered}]
        }else 
          objInfo = [...objInfo,{id:"folio_3",title:"",taskIds:[],DragDisabled:false}]

        if(folio4)
        {
          taskFiltered = []
          if(folio4.departments)
          {
            taskFiltered = folio4.departments.map((task)=>`task-${task.id}`)
            const filterItems = departmentList.taskIds.filter(item => !taskFiltered.includes(item))
            departmentList.taskIds = [...filterItems]
          }
            objInfo = [...objInfo,{id:"folio_4",title:folio4.name,taskIds:taskFiltered}]
        }else
          objInfo = [...objInfo,{id:"folio_4",title:"",taskIds:[],DragDisabled:false}]

          /* ObjInfo = [{id:"",title:"",taskIds:["",...]}] */
            const setColumnOrder = objInfo.map((obj)=>obj.id)
            const setColumns = objInfo.reduce((previous,current)=>{
                  previous[current.id] = current
                  return previous
            },{})
            //console.log("Compare columnOrder : ",setColumnOrder)
            //console.log("Compare column ",setColumns)
            const entities = {
              columnOrder: setColumnOrder,
              columns: setColumns,
              tasks:  taskMap
            }

            /* if no error put everything to reducer */
            yield put(folioPatternDetailDataSourceUpdate(entities))

            /* Keep folioPatternDetailDataSource on localstore */
            setItems({
              SessionFolioPatternDetailDataSource: JSON.stringify(entities)
            })
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const process = { status: false, action: "preview", message}
            const processStatus = { sync_confirm: false, process }
            yield put(folioPatternDetailProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on foliopattern detail : ",error)
        const process = { status: false, action: "preview", message:error}
        const processStatus = { sync_confirm: false, process }
        yield put(folioPatternDetailProcessSuccess(processStatus))
    }
    
}

function* folioPatternDetailCreateProcess({payload}){

      const columnOrder = payload.entities.columnOrder
      let seqNo = 1
      let folioPatternDetailInfo = {}
      folioPatternDetailInfo.pattern_id = parseInt(payload.pattern_id)
      folioPatternDetailInfo.folios = columnOrder.filter((obj)=>(obj!=="departmentList")).map((val)=>{
        const taskIdsInfo = payload.entities.columns[val].taskIds.map((v)=>parseInt(v.replace("task-", "")))

        const title = payload.entities.columns[val].title
        const newColumn = { seq:seqNo++,
                            name:title,
                            departments:taskIdsInfo.map((value)=>{
                              return {id:value}
                            })}

        if(isEmpty(title)) return null
        return newColumn
      })
      folioPatternDetailInfo.folios = folioPatternDetailInfo.folios.filter((e)=>e != null)

      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/folioPatternDetails/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = folioPatternDetailInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
            
            if(response.status === 200){
                const {data} = response
                const sync_confirm = (data.case === 2)?true:false
                /* 2 equal ask to sync */
                const process = { status: true, action: "update", message:"Update success" }
                const processStatus = { sync_confirm , process}
                yield put(folioPatternDetailProcessSuccess(processStatus))
            }else{
                const {message,data} = response.data
                const process = { status: false, action: "update", message }
                const processStatus = { sync_confirm:false , process}
                yield put(folioPatternDetailProcessSuccess(processStatus))
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const process = { status: false, action: "update", message:error }
            const processStatus = { sync_confirm:false , process}
            yield put(folioPatternDetailProcessSuccess(processStatus))
      }
}

function* folioPatternSyncConfirmProcess({payload}){
    const folio_pattern_id = payload

    try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/masterfile/folioPatternDetails/sync/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const API_DATABODY  = {pattern_id:folio_pattern_id}
      const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

      if(response.status === 200){
        const process = { status: true, action: "syncing", message:"Folio Syncing..." }
        const processStatus = { sync_confirm: false, process }
        yield put(folioPatternDetailProcessSuccess(processStatus))
      }else{
          const {message,data} = response.data
          const process = { status: false, action: "syncing", message}
          const processStatus = { sync_confirm: false, process }
          yield put(folioPatternDetailProcessSuccess(processStatus))
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
      }      
    }catch(error){
        console.log("Sync error ",error)
    }
}

/* Watcher Saga */
export function* folioPatternDetailPreview() {
  yield takeLatest(FOLIO_PATTERN_DETAIL_PREVIEW, folioPatternDetailPreviewProcess)
}
export function* folioPatternDetailCreate(){
  yield takeEvery(FOLIO_PATTERN_DETAIL_NEW,folioPatternDetailCreateProcess)
}
export function* folioPatternSyncConfirm(){
  yield takeEvery(FOLIO_PATTERN_DETAIL_SYNC_CONFIRM,folioPatternSyncConfirmProcess)
}

export default function* rootSaga() {
  yield all([fork(folioPatternDetailPreview),
             fork(folioPatternDetailCreate),
             fork(folioPatternSyncConfirm)])


}
