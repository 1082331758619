import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/roomPlan/roomplanEventActionType"
import * as actionCreators from "appRedux/actions/roomPlan/roomplanEvent"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* roomplanEventRequestProcess({ payload }: actionTypes.RoomPlanEventRequest) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/inventory/roomPlan/event/?start_date=${payload.start_date}&end_date=${payload.end_date}`

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)
    if (response.status === 200) {
      const roomplanDataSource = response.data
      yield put(actionCreators.roomplanEventDatasouceSuccess(roomplanDataSource))

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "roomplanGetEvent", message }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on get roomplan event : ", error)
    const processStatus = { status: false, action: "roomplanGetEvent", message: error }
    yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
    yield put(actionCreators.roomplanEventProcessReset())
  }
}

function* roomplanEventInhouseChangeDateProcess({ payload }: actionTypes.RoomPlanEventDragInhouseChangeDate) {
  const { register_id, new_departure } = payload
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/registration/guestInHouse/${register_id}/changeDeparture/`

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  const API_DATABODY = { new_departure }
  try {
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const { result_data, result_code, result_msg } = response.data
      const processStatus = { status: true, action: "eventDragInhouseChangeDate", message: result_msg, result_code, result_data }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "eventDragInhouseChangeDate", message }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on roomplan drag inhouse change date : ", error)
    const processStatus = { status: false, action: "eventDragInhouseChangeDate", message: error }
    yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
    yield put(actionCreators.roomplanEventProcessReset())
  }
}

function* roomplanEventInhouseChangeRoomProcess({ payload }: actionTypes.RoomPlanEventDragInhouseChangeRoom) {
  const { register_id, new_room_id } = payload
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/registration/guestInHouse/${register_id}/changeRoom/`

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  const API_DATABODY = { new_room_id }
  try {
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const { result_data, result_code, result_msg } = response.data
      const processStatus = { status: true, action: "eventDragInhouseChangeRoom", message: result_msg, result_code, result_data }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "eventDragInhouseChangeRoom", message }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on roomplan drag inhouse change room : ", error)
    const processStatus = { status: false, action: "eventDragInhouseChangeRoom", message: error }
    yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
    yield put(actionCreators.roomplanEventProcessReset())
  }
}

function* roomplanEventOOOChangeDateProcess({ payload }: actionTypes.RoomPlanEventDragOOOChangeDate) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/housekeeping/roomStatus/changeOutOfOrderPeriod/`

  const oooChangeInfo = {} as any
  oooChangeInfo.ooo_id = payload.ooo_id
  oooChangeInfo.new_start = payload.new_start
  oooChangeInfo.new_end = payload.new_end

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  const API_DATABODY = oooChangeInfo
  try {
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const { result_data, result_code, result_msg } = response.data
      const processStatus = { status: true, action: "eventDragOOOChangeDate", message: result_msg, result_code, result_data }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "eventDragOOOChangeDate", message }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on roomplan drag inhouse change date : ", error)
    const processStatus = { status: false, action: "eventDragOOOChangeDate", message: error }
    yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
    yield put(actionCreators.roomplanEventProcessReset())
  }
}
function* roomplanEventBlockChangeRoomProcess({ payload }: actionTypes.RoomPlanEventDragBlockChangeRoom) {

  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/reservation/blockRoom/changeRoom/`

  const BlockChangeInfo = {} as any
  BlockChangeInfo.block_id = payload.block_id
  BlockChangeInfo.new_room_id = payload.new_room_id

  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
  const API_DATABODY = BlockChangeInfo
  try {
    const response: ApiResponse = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const { result_data, result_code, result_msg } = response.data
      const processStatus = { status: true, action: "eventDragBlockChangeRoom", message: result_msg, result_code, result_data }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())

    } else {
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "eventDragBlockChangeRoom", message }
      yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
      yield put(actionCreators.roomplanEventProcessReset())
      if (response.status === 403) {
        //if data is not null force signout 
        if (data)
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on roomplan drag Block change room : ", error)
    const processStatus = { status: false, action: "eventDragBlockChangeRoom", message: error }
    yield put(actionCreators.roomplanEventProcessSuccess(processStatus))
    yield put(actionCreators.roomplanEventProcessReset())
  }
}

function* roomplanEventRequest() {
  yield takeEvery(actionTypes.ROOMPLAN_EVENT_REQUEST, roomplanEventRequestProcess)
}
function* roomplanEventInhouseChangeDate() {
  yield takeEvery(actionTypes.ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_DATE, roomplanEventInhouseChangeDateProcess)
}
function* roomplanEventInhouseChangeRoom() {
  yield takeEvery(actionTypes.ROOMPLAN_EVENT_DRAG_INHOUSE_CHANGE_ROOM, roomplanEventInhouseChangeRoomProcess)
}
function* roomplanEventOOOChangeDate() {
  yield takeEvery(actionTypes.ROOMPLAN_EVENT_DRAG_OOO_CHANGE_DATE, roomplanEventOOOChangeDateProcess)
}
function* roomplanEventBlockChangeRoom() {
  yield takeEvery(actionTypes.ROOMPLAN_EVENT_DRAG_BLOCK_CHANGE_ROOM, roomplanEventBlockChangeRoomProcess)
}


export default function* rootSaga() {
  yield all([fork(roomplanEventRequest),
  fork(roomplanEventInhouseChangeDate),
  fork(roomplanEventOOOChangeDate),
  fork(roomplanEventInhouseChangeRoom),
  fork(roomplanEventBlockChangeRoom)
  ])
}


