import * as actions from "appRedux/actions/rsv/ReservationActionType"
import { boolean } from "yup"

interface guestType {
	id: number,
	first_name?: string,
	last_name?: string,
	title_id?: number,
	title_name?: string,
	full_name?: string,
	sex?: string | null,
	birthdate?: string | null,
	nation_id?: number,
	nationality?: string | null,
	country_res_id?: number | null,
	country_of_residence?: string | null,
	lang_id?: number | null,
	language?: string | null,
	vip_id?: number | null,
	vip_name?: string | null,
	company?: string | null,
	occupation_id?: number | null,
	occupation_name?: string | null,
	credit_limit?: string | null,
	confidential: boolean,
	member_code?: string | null,
	carplate_no?: string | null,
	photo?: string | null,
	photo_url?: string | null,
	remark?: string | null,
	likes: [],
	dislikes: [],
	image_count: number,
	visit_count: number,
	visit_nights: number,
	last_arrival?: string | null,
	last_departure?: string | null,
	contact: {
		address1?: string | null,
		address2?: string | null,
		city_id?: number | null,
		city_name?: number | null,
		country_id?: number | null,
		country_name: string | null,
		emails: [],
		telephones: [],
		mobiles: []
	},
	document: {
		doc_type?: string | null,
		doc_number?: string | null,
		expire_date?: string | null,
		country_id?: string | null,
		country_name?: string | null
	},
	credit_cards: [],
	social_media: []
}

export interface detailType {
	booking_id?: number,
	booking_name?: string,
	status?: string,
	book_type?: string,
	stay_type?: string,
	confirm_no?: string | null,
	folio_id?: number | null,
	rsvtype_id?: number,
	reservation_type?: string,
	book_by?: string | null,
	guest_id?: number | null,
	group_id?: number | null
	group_name?: string | null,
	member_code?: string | null,
	channel_id?: number | null,
	channel_name?: string | null,
	voucher_no?: string | null,
	origin_id?: number | null,
	origin_name?: string | null,
	arrival?: string | null,
	arrival_time?: string | null,
	arrive_by?: string | null,
	arrive_from?: string | null,
	departure?: string | null,
	departure_time?: string | null,
	depart_by?: string | null,
	depart_to?: string | null,
	sales_id?: number | null,
	sales_name?: string | null,
	upgrade_type?: any,
	upgrade_reason?: string | null,
	market_id?: number | null,
	market_name?: string | null,
	create_user?: string | null,
	create_time?: string | null,
	modify_user?: string | null,
	modify_time?: string | null,
	keep_original_rate?: boolean | null,
	confirm_user?: string | null,
	confirm_time?: string | null,
	confirm_remark?: string | null,
	cancel_user?: string | null,
	cancel_time?: string | null,
	checkin_user?: string | null,
	checkin_time?: string | null,
	checkout_user?: string | null,
	checkout_time?: string | null,
	msg_count: number,
	flag_count: number,
	note?: string | null,
	payment: {
		payment_method?: string | null,
		folio_pattern_id: number | null,
		folio_pattern_name?: string | null,
		credit_limit: number,
		promo_code?: string | null
	},
	details: Array<{
		item_id?: number,
		roomtype_id?: number,
		roomtype_code?: string,
		roomtype_name?: string,
		bedtype_id?: number,
		bedtype_name?: string,
		rateplan_id?: number,
		rateplan_name?: string,
		rate_id?: number,
		arrival?: string,
		departure?: string,
		nights: number,
		qty: number,
		adult: number,
		child: number,
		infant: number,
		extra_adult: number,
		extra_child: number,
		extra_infant: number,
		allot_qty: number,
		block_qty: number,
		inhouse_qty: number,
		charge_to?: string,
		no_amend: boolean,
		no_cancel: boolean,
		no_refund: boolean,
		room_numbers: [],
		request_list?: string | null,
		requirements: [],
		rate_schedule: Array<{
			date?: string | null,
			amount?: number | null,
			extra_adult_rate?: number | null,
			extra_child_rate?: number | null,
			extra_infant_rate?: number | null,
			comp: boolean,
			comp_abf: boolean
		}>
	}
	>,
	guests: Array<guestType>,
	requirements: Array<{
		id: number,
		name: string,
		qty: number,
		charge_dept: number,
		charge_rate: number,
		charge_type: number,
		start_date: string,
		end_date: string,
		frequency: number,
		inclusion_id: number,
		remark?: string,
		request_time?: string | null
	}>,
	deposits: Array<{
		id: number | null,
		status?: string | null,
		deposit_date?: string | null,
		payment_id?: number | null,
		payment_name?: string | null,
		amount: number,
		remark?: string | null,
		create_user?: string | null,
		create_time?: string | null,
		modify_user?: string | null,
		modify_time?: string | null,
		cancel_user?: string | null,
		cancel_time?: string | null
	}>,
	logs: Array<{
		id: number,
		user_name?: string | null,
		log_type?: string | null,
		log_text?: string | null,
		log_date?: string | null,
		log_time?: string | null
	}>,
	summary: {
		adult: number,
		child: number,
		infant: number,
		nights: number,
		arrival?: string,
		balance: number,
		deposit: number,
		room_qty: number,
		block_qty: number,
		departure?: string,
		inhouse_qty: number,
		total_charge: number,
		other_charges: Array<{
			amount: number,
			description?: string | null
		}>,
		accommodations: Array<{
			amount: number,
			description?: string | null
		}>,
		total_room_charge: number,
		total_other_charge: number
	}
}

export const guestDefault: guestType = {
	id: -1,
	first_name: undefined,
	last_name: undefined,
	title_id: undefined,
	title_name: undefined,
	full_name: undefined,
	sex: undefined,
	birthdate: undefined,
	nation_id: undefined,
	nationality: null,
	country_res_id: undefined,
	country_of_residence: undefined,
	lang_id: undefined,
	language: undefined,
	vip_id: undefined,
	vip_name: undefined,
	company: undefined,
	occupation_id: undefined,
	occupation_name: undefined,
	credit_limit: null,
	confidential: false,
	member_code: null,
	carplate_no: null,
	photo: null,
	photo_url: null,
	remark: null,
	likes: [],
	dislikes: [],
	image_count: 0,
	visit_count: 0,
	visit_nights: 0,
	last_arrival: null,
	last_departure: null,
	contact: {
		address1: null,
		address2: null,
		city_id: null,
		city_name: null,
		country_id: null,
		country_name: null,
		emails: [],
		telephones: [],
		mobiles: []
	},
	document: {
		doc_type: null,
		doc_number: null,
		expire_date: null,
		country_id: null,
		country_name: null
	},
	credit_cards: [],
	social_media: []
}

export const detailInitialState: detailType = {
	booking_id: undefined,
	booking_name: undefined,
	status: undefined,
	book_type: undefined,
	stay_type: undefined,
	confirm_no: undefined,
	folio_id: undefined,
	rsvtype_id: undefined,
	reservation_type: undefined,
	book_by: undefined,
	guest_id: undefined,
	group_id: null,
	group_name: null,
	member_code: null,
	channel_id: null,
	channel_name: null,
	voucher_no: null,
	origin_id: undefined,
	origin_name: undefined,
	arrival: undefined,
	arrival_time: undefined,
	arrive_by: null,
	upgrade_type : null,
	upgrade_reason :null,
	arrive_from: null,
	departure: undefined,
	departure_time: undefined,
	depart_by: null,
	depart_to: null,
	sales_id: null,
	sales_name: null,
	market_id: null,
	market_name: null,
	create_user: null,
	create_time: null,
	modify_user: null,
	modify_time: null,
	keep_original_rate: false,
	confirm_user: null,
	confirm_time: null,
	confirm_remark: null,
	cancel_user: null,
	cancel_time: null,
	checkin_user: null,
	checkin_time: null,
	checkout_user: null,
	checkout_time: null,
	msg_count: 0,
	flag_count: 0,
	note: null,
	payment: {
		payment_method: null,
		folio_pattern_id: null,
		folio_pattern_name: null,
		credit_limit: 0,
		promo_code: null
	},
	details: [],
	guests: [{ ...guestDefault }],
	requirements: [],
	deposits: [],
	logs: [],
	summary: {
		adult: 0,
		child: 0,
		infant: 0,
		nights: 0,
		arrival: undefined,
		balance: 0,
		deposit: 0,
		room_qty: 0,
		block_qty: 0,
		departure: undefined,
		inhouse_qty: 0,
		total_charge: 0,
		other_charges: [],
		accommodations: [],
		total_room_charge: 0,
		total_other_charge: 0
	}
}

interface bookingSummaryType {
	adult: number,
	child: number,
	infant: number,
	nights: number,
	arrival?: string,
	balance: number,
	deposit: number,
	room_qty: number,
	block_qty: number,
	departure?: string,
	inhouse_qty: number,
	total_charge: number,
	extra_child: number,
	extra_adult: number,
	extra_infant: number,
	other_charges: Array<{
		amount: number,
		description?: string | null
	}>,
	accommodations: Array<{
		amount: number,
		description?: string | null
	}>,
	total_room_charge: number,
	total_other_charge: number
}

export const bookingSummaryInitialState: bookingSummaryType = {
	adult: 0,
	child: 0,
	infant: 0,
	nights: 0,
	arrival: undefined,
	balance: 0,
	deposit: 0,
	room_qty: 0,
	block_qty: 0,
	departure: undefined,
	inhouse_qty: 0,
	total_charge: 0,
	other_charges: [],
	accommodations: [],
	total_room_charge: 0,
	total_other_charge: 0,
	extra_child:0,
	extra_adult:0,
	extra_infant:0
}

export interface reservationsState {
	loader: boolean,
	onProcessing: boolean,
	keyword: string,
	dataSource: [],
	paginations: any,
	detailDataSource: detailType,
	filterReservation: any,
	rateplanDataSource: any,
	bookingSummaryDataSouce: bookingSummaryType,
	process: any
}

const initialState: reservationsState = {
	loader: false,
	onProcessing: false,
	keyword: '',
	dataSource: [],
	paginations: [],
	detailDataSource: detailInitialState,
	filterReservation: [],
	rateplanDataSource: [],
	bookingSummaryDataSouce: bookingSummaryInitialState,
	process: { status: false, action: "", message: "" }
}

export default (state: reservationsState = initialState, action: actions.ReservationAction): reservationsState => {
	switch (action.type) {
		case actions.RESERVATION_REQUEST: {
			return {
				...state,
				loader: true,
				detailDataSource: {
					...detailInitialState,
					guests: [{ ...guestDefault }]
				},
				bookingSummaryDataSouce: bookingSummaryInitialState,
				onProcessing: false,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.RESERVATION_NEW: {
			return {
				...state,
				loader: false,
				onProcessing: true,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.RESERVATION_UPDATE: {
			return {
				...state,
				loader: false,
				onProcessing: true,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.RESERVATION_REMOVE: {
			return {
				...state,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.RESERVATION_PROCESS_SUCCESS: {
			return {
				...state,
				onProcessing: false,
				process: action.payload
			}
		}
		case actions.RESERVATION_DATASOURCE_SUCCESS: {
			const { results, paging } = action.payload
			return {
				...state,
				loader: false,
				dataSource: results,
				paginations: paging
			}
		}
		case actions.RESERVATION_DETAIL_REQUEST: {
			return {
				...state,
				loader: true,
				process: { status: false, action: "", message: "" }
			}
		}
		case actions.RESERVATION_DETAIL_DATASOURCE_SUCCESS: {
			return {
				...state,
				loader: false,
				detailDataSource: action.payload
			}
		}
		case actions.RESERVATION_RATEPLAN_REQUEST: {
			return {
				...state,
				rateplanDataSource: []
			}
		}
		case actions.RESERVATION_RATEPLAN_DATASOUCE_SUCCESS: {
			return {
				...state,
				rateplanDataSource: action.payload
			}
		}
		case actions.RESERVATION_SET_FILTER: {
			return {
				...state,
				filterReservation: action.payload
			}
		}
		case actions.RESERVATION_SEARCH: {
			return {
				...state,
				loader: true,
				process: { status: false, action: "", message: "" }
			}
		}

		case actions.RESERVATION_CHANGE_BOOKING_STATUS: {
			return {
				...state,
				process: { status: false, action: "", message: "" }
			}
		}

		case actions.RESERVATION_BOOKING_SUMMARY_SUCCESS: {
			return {
				...state,
				bookingSummaryDataSouce: action.payload
			}
		}

		case actions.RESERVATION_PROCESS_RESET: {
			return {
				...state,
				process: { status: false, action: "", message: "" }
			}
		}
		default:
			return state;
	}
}