import * as actions from "./ChargeReservationActionType"

export const chargeReservationPreview = (payload?: any): actions.ChargeReservationRequest => {
  return {
    type: actions.CHARGE_RESERVATION_REQUEST,
    payload
  }
}
export const chargeReservationDetailRequest = (payload?: any): actions.ChargeReservationDetailRequest => {
  return {
    type: actions.CHARGE_RESERVATION_DETAIL_REQUEST,
    payload
  }
}

export const chargeReservationUpdate= (payload?: any): actions.ChargeReservationUpdate => {
  return {
    type: actions.CHARGE_RESERVATION_UPDATE,
    payload
  }
}

export const chargeReservationProcessSuccess = (payload:any):actions.ChargeReservationProcessSuccess =>{
  return{
    type: actions.CHARGE_RESERVATION_PROCESS_SUCCESS,
    payload
  }
}

export const chargeReservationDataSourceSuccess = (payload:any):actions.ChargeReservationDataSourceSuccess =>{
  return {
    type: actions.CHARGE_RESERVATION_DATASOURCE_SUCCESS,
    payload
  }
}

export const chargeReservationDetailDataSourceSuccess = (payload:any):actions.ChargeReservationDetailDataSourceSuccess =>{
  return {
    type: actions.CHARGE_RESERVATION_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}


export const chargeReservationSetFilter = (payload:any): actions.ChargeReservationSetFilter =>{
  return {
    type: actions.CHARGE_RESERVATION_SET_FILTER,
    payload
  }
}

export const chargeReservationProcessReset=() =>{
  return{
    type:actions.CHARGE_RESERVATION_PROCESS_RESET
  } 
}
