import { all, fork, put, takeEvery, call } from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/registration/GuestIssueCardActionType"
import * as actionCreators from "appRedux/actions/registration/GuestIssueCard"
import { userSignOut } from "appRedux/actions/Auth"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { APIGetRequest, APIPostRequest } from "util/connection"
import { ApiResponse } from "constants/ActionTypes"

function* guestIssueCardRequestProcess({ payload }: actionTypes.GuestIssueCardRequest) {

  const { registerID } = payload
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/addon/keycard/issueInfo?register_id=${registerID}`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response: ApiResponse = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      const issueCardInfo = response.data
      yield put(actionCreators.guestIssueCardDatasouceSuccess(issueCardInfo))

    } else {
      console.log("Error : ", response)
      const { message } = response
      const processStatus = { status: false, action: "guestIssueCardPreview", message }
      yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
      yield put(actionCreators.guestIssueCardProcessReset())
      if (response.status === 403) {
          yield put(userSignOut())
      }

    }
  } catch (error) {
    console.log("Error on guest issue card info : ", error)
    const processStatus = { status: false, action: "guestIssueCardPreview", message: error }
    yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
    yield put(actionCreators.guestIssueCardProcessReset())
  }
}

function* guestIssueCardNewCardProcess({ payload }: actionTypes.GuestIssueCardNewCard) {
  const url = `${process.env.REACT_APP_ISSUE_CARD_URL}/api/KeyCard/newKeyCard`
  const DATA_BODY = { ...payload }
  try {
    const response: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number }, data?: any, status?: number } = yield APIPostRequest(url, DATA_BODY, {
      'Content-Type': 'application/json'
    })

    if (response?.errorCode === 0) {
      const issueCardInfo: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number } } = response
      yield put(actionCreators.guestIssueCardSetNextCard({ cardSerial: issueCardInfo.cardData.cardSerial, cardIndex: issueCardInfo.cardData.cardIndex }))
      const processStatus = { status: true, action: "guestIssueNewCard", message: issueCardInfo.message }
      yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
      yield put(actionCreators.guestIssueCardProcessReset())

    } else {
      console.log("Error : ", response)
      //const {message} = response.data
      let temp: string = ""
      if (response.status === 400) {
        temp = "Validate errors"
      } else if (response.status === 500) {
        const { message } = response.data
        temp = message
      }

      const processStatus = { status: false, action: "guestIssueNewCard", message: temp }
      yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
      yield put(actionCreators.guestIssueCardProcessReset())
    }

  } catch (error) {
    console.log("Error on guest issue new card info : ", error)
    const processStatus = { status: false, action: "guestIssueNewCard", message: error }
    yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
    yield put(actionCreators.guestIssueCardProcessReset())
  }
}

function* guestIssueCardCopyCardProcess({ payload }: actionTypes.GuestIssueCardCopyCard) {
  const url = `${process.env.REACT_APP_ISSUE_CARD_URL}/api/KeyCard/copyKeyCard`

  const DATA_BODY = { ...payload }
  try {
    const response: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number }, data?: any, status?: number } = yield APIPostRequest(url, DATA_BODY, {
      'Content-Type': 'application/json'
    })

    if (response?.errorCode === 0) {
      const issueCardInfo: { message: string, errorCode: number, cardData: { cardSerial: string, cardIndex: number } } = response
      yield put(actionCreators.guestIssueCardSetNextCard({ cardSerial: issueCardInfo.cardData.cardSerial, cardIndex: issueCardInfo.cardData.cardIndex }))
      const processStatus = { status: true, action: "guestIssueCopyCard", message: issueCardInfo.message }
      yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
      yield put(actionCreators.guestIssueCardProcessReset())

    } else {
      console.log("Error : ", response)
      //const {message} = response.data
      let temp: string = ""
      if (response.status === 400) {
        temp = "Validate errors"
      } else if (response.status === 500) {
        const { message } = response.data
        temp = message
      }

      const processStatus = { status: false, action: "guestIssueCopyCard", message: temp }
      yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
      yield put(actionCreators.guestIssueCardProcessReset())
    }

  } catch (error) {
    console.log("Error on guest issue copy card info : ", error)
    const processStatus = { status: false, action: "guestIssueCopyCard", message: error }
    yield put(actionCreators.guestIssueCardProcessSuccess(processStatus))
    yield put(actionCreators.guestIssueCardProcessReset())
  }
}

function* gusetIssueCardRequest() {
  yield takeEvery(actionTypes.GUEST_ISSUE_CARD_REQUEST, guestIssueCardRequestProcess)
}
function* guestIssueCardNewCard() {
  yield takeEvery(actionTypes.GUEST_ISSUE_CARD_NEW_CARD, guestIssueCardNewCardProcess)
}
function* guestIssueCardCopyCard() {
  yield takeEvery(actionTypes.GUEST_ISSUE_CARD_COPY_CARD, guestIssueCardCopyCardProcess)
}
export default function* rootSaga() {
  yield all([fork(gusetIssueCardRequest),
  fork(guestIssueCardNewCard),
  fork(guestIssueCardCopyCard)
  ])
}


