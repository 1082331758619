import * as actions from "appRedux/actions/calendar/calendarActionType"
import { calendarType } from "./occupancyCalendarType"

const calendarDefaultState:calendarType={
    calendar_start: undefined,
    calendar_end: undefined,
    summary: {
        occ_percent: 0,
        occ_rooms: 0,
        revenue: 0,
        adr: 0,
        rev_par: 0
    },
        calendar: []
}

export interface calendarState {
    loader: boolean,
    dataSource: calendarType,
    process:any
}

const initialState: calendarState = {
    loader: false,
    dataSource: calendarDefaultState,
    process:{ status:false,action:"",message:""}
}

export default (state: calendarState = initialState,action: actions.CalendarAction): calendarState =>{
    switch (action.type) {
    case actions.CALENDAR_REQUEST:{
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.CALENDAR_PROCESS_SUCCESS:{
        return{
            ...state,
            loader:false,
            process:action.payload
        }
    }
    case actions.CALENDAR_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.CALENDAR_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}