import {
    NATIONALITY_PREVIEW,
    NATIONALITY_NEW,
    NATIONALITY_UPDATE,
    NATIONALITY_REMOVE,
    NATIONALITY_SEARCH,
    NATIONALITY_PROCESS_SUCCESS,
    NATIONALITY_DATASOURCE_SUCCESS,
    NATIONALITY_PROCESS_RESET
  } from "constants/ActionTypes"

  export const nationalityPreview = (value) => {
    return {
      type: NATIONALITY_PREVIEW,
      payload:value
    }
  }

  export const nationalityCreate =(value) =>{
    return {
      type:NATIONALITY_NEW,
      payload:value
    }
  }

  export const nationalityUpdate = (value) =>{
    return {
      type:NATIONALITY_UPDATE,
      payload: value
    }
  }

  export const nationalityRemove = (value)=>{
    return {
      type:NATIONALITY_REMOVE,
      payload:value
    }
  }

  export const nationalitySearch = (keyword) =>{
    return {
      type: NATIONALITY_SEARCH,
      payload:keyword
    }
  }

  export const nationalityProcessSuccess = (value) =>{
    return{
      type:NATIONALITY_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const nationalityDataSourceUpdate = (value) => {
    return {
      type: NATIONALITY_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const nationalityProcessReset = () =>{
    return{
      type:NATIONALITY_PROCESS_RESET
    }
  }

