import {
    ROOM_AVAIL_PREVIEW,
    ROOM_AVAIL_PROCESS_SUCCESS,
    ROOM_AVAIL_DATASOURCE_SUCCESS,
    ROOM_AVAIL_PROCESS_RESET,
    ROOM_AVAIL_DETAIL_PREVIEW,
    ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS
} from "constants/ActionTypes"

const INIT_STATE = {
    loader: false,
    dateRange:[],
    dataSource:[],
    detailDataSource:[],
    process:{}
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ROOM_AVAIL_PREVIEW: {
            return {
                ...state,
                loader: true,
                detailDataSource:[],
                process:{status:false,action:"",message:""}
            }
        }
        case ROOM_AVAIL_DETAIL_PREVIEW:{
            return{
                ...state,
                loader:true,
                process:{status:false,action:"",message:""}
            }
        }
        case ROOM_AVAIL_PROCESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                process:action.payload
            }
        }
        case ROOM_AVAIL_DATASOURCE_SUCCESS:{
            return {
                ...state,
                loader: false,
                dataSource: action.payload.dataSource,
                dateRange:action.payload.dateRange
            }
        }
        case ROOM_AVAIL_DETAIL_DATASOURCE_SUCCESS:{
            return {
                ...state,
                loader: false,
                detailDataSource: action.payload
            }
        }
        case ROOM_AVAIL_PROCESS_RESET:{
            return{
                ...state,
                process:{}
            }
        }
        default:
            return state
    }
}