import {all, fork, put,takeLatest, call} from "redux-saga/effects"
import { getItems } from "util/localStorage"
import {
    SYSTEM_CONFIG_PREVIEW,
    SYSTEM_CONFIG_UPDATE
} from "constants/ActionTypes"

import { systemConfigProcessSuccess, systemConfigDataSourceUpdate } from "appRedux/actions/setup/systemSetup/systemConfig"
import { userSignOut } from "appRedux/actions/Auth"

import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import moment from "moment"

function setDynamicValue(payload){
        const { action } = payload
        switch (action) {
            case "system":
                const { allow_join_room: ALLOW_JOIN_ROOM, auto_print_flag: AUTOPRINTFLAG,
                    language: LANGUAGE,online_post: ONLINE_POST } = payload
                return {"SYSTEM": {
                        ALLOW_JOIN_ROOM,
                        AUTOPRINTFLAG,
                        LANGUAGE,
                        ONLINE_POST
                    }
                }

            case "endday":
                const { end_day_check_deposit: ENDDAY_CHECK_DEPOSIT,
                        end_day_skip_backup: ENDDAY_SKIP_BACKUP,
                        end_day_skip_print: ENDDAY_SKIP_PRINT } = payload
                return {"ENDDAY":{
                        ENDDAY_CHECK_DEPOSIT, 
                        ENDDAY_SKIP_BACKUP, 
                        ENDDAY_SKIP_PRINT 
                    }
                }

            case "tax":
                const { force_issue_tax: FORCE_ISSUE_TAX,
                        issue_tax: ISSUE_TAX,
                        vat_issue_on_payment: VAT_ISSUE_ON_PAYMENT,
                        vat_item_description: VAT_ITEM_DESCRIPTION,
                        vat_rate: VAT_RATE } = payload

                return {"TAX": {
                        FORCE_ISSUE_TAX, ISSUE_TAX, 
                        VAT_ISSUE_ON_PAYMENT, 
                        VAT_ITEM_DESCRIPTION, 
                        VAT_RATE 
                    }
                }
            
            case "backup":
                const { backup_day: BACKUPDAY, backup_db: DBBACKUP_DATABASE,
                    backup_daykeep: DBBACKUP_DAYKEEP, backup_server: DBBACKUP_SERVER } = payload
                return {"BACKUP":{
                        BACKUPDAY , 
                        DBBACKUP_DATABASE ,
                        DBBACKUP_DAYKEEP,
                        DBBACKUP_SERVER
                    }
                }
            case "cashier":
                const { close_shift_by_user: CLOSE_SHIFT_BY_USER,
                        credit_card_mask: CREDIT_CARD_MASK  } = payload
                return {"CASHIER":{ CLOSE_SHIFT_BY_USER , CREDIT_CARD_MASK }}

            case "reservation":
                const { allotment_enabled: ALLOTMENT_ENABLED,
                        allow_overbook: ALLOW_OVERBOOK,
                        auto_confirm_booking: AUTO_CONFIRM_BOOKING } = payload

                return {
                    RESERVATION: {
                        ALLOTMENT_ENABLED,
                        ALLOW_OVERBOOK,
                        AUTO_CONFIRM_BOOKING
                    }
                }

            case "roomrate":
                const { extra_adult: EXTRA_ADULT,
                        extra_child: EXTRA_CHILD,
                        extra_infant: EXTRA_INFANT,
                        include_adult: INCLUDE_ADULT,
                        monthly_rate_enabled: MONTHLY_RATE_ENABLED,
                        monthly_rate_mode: MONTHLY_RATE_MODE,
                        service_rate: SERVICE_RATE,
                        tax_on_service: TAX_ON_SERVICE,
                        tax_rate: TAX_RATE,
                        weekly_rate_enabled: WEEKLY_RATE_ENABLED,
                        weekly_rate_mode: WEEKLY_RATE_MODE } = payload
                
                return {"ROOM_RATE": {
                        EXTRA_ADULT,
                        EXTRA_CHILD,
                        EXTRA_INFANT,
                        INCLUDE_ADULT,
                        MONTHLY_RATE_ENABLED,
                        MONTHLY_RATE_MODE,
                        SERVICE_RATE,
                        TAX_ON_SERVICE,
                        TAX_RATE,
                        WEEKLY_RATE_ENABLED,
                        WEEKLY_RATE_MODE
                    }
                }
            case "folio":
                const { folio_del_trans: FOLIO_DEL_TRANS,
                    folio_editable: FOLIO_EDITABLE,
                    folio_edit_arr_dep: FOLIO_EDIT_ARR_DEP,
                    folio_edit_balance: FOLIO_EDIT_BALANCE, 
                    folio_edit_header: FOLIO_EDIT_HEADER, 
                    folio_edit_roomno: FOLIO_EDIT_ROOMNO,
                    folio_format:FOLIO_FORMAT,
                    folio_gen_trans: FOLIO_GEN_TRANS, 
                    folio_grp_trans:FOLIO_GRP_TRANS, 
                    folio_ins_trans:FOLIO_INS_TRANS, 
                    folio_mrg_trans:FOLIO_MRG_TRANS, 
                    folio_option:FOLIO_OPTION, 
                    folio_print_empty:FOLIO_PRINT_EMPTY } = payload

                return {"FOLIO": {
                        FOLIO_DEL_TRANS,
                        FOLIO_EDITABLE,
                        FOLIO_EDIT_ARR_DEP,
                        FOLIO_EDIT_BALANCE,
                        FOLIO_EDIT_HEADER,
                        FOLIO_EDIT_ROOMNO,
                        FOLIO_FORMAT,
                        FOLIO_GEN_TRANS,
                        FOLIO_GRP_TRANS,
                        FOLIO_INS_TRANS,
                        FOLIO_MRG_TRANS,
                        FOLIO_OPTION,
                        FOLIO_PRINT_EMPTY
                    }
                }
            case "guest_profile":
                const {name_auto_match: NAME_AUTO_MATCH, name_force_upper: NAME_FORCE_UPPER, name_format: NAME_FORMAT} = payload
                return {"GUEST_PROFILE":{NAME_AUTO_MATCH,NAME_FORCE_UPPER,NAME_FORMAT}}

            case "calendar":
                const { first_month_fiscal: FIRST_MONTH_FISCAL, first_month_tourism: FIRST_MONTH_TOURISM,weekend_days} = payload
                const initialValue = weekend_days.reduce((obj, item) => {
                            return {...obj,[item]:true}
                        }, {})
                    const WEEEKEND_DAYS = {}
                        WEEEKEND_DAYS.mon = initialValue.mon||false
                        WEEEKEND_DAYS.tue = initialValue.tue||false
                        WEEEKEND_DAYS.wed = initialValue.wed||false
                        WEEEKEND_DAYS.thu = initialValue.thu||false
                        WEEEKEND_DAYS.fri = initialValue.fri||false
                        WEEEKEND_DAYS.sat = initialValue.sat||false
                        WEEEKEND_DAYS.sun = initialValue.sun||false
                return {"CALENDAR":{FIRST_MONTH_FISCAL,FIRST_MONTH_TOURISM,WEEEKEND_DAYS}}

            case "default":
                const {title:TITLE, origin:ORIGIN, adult_qty:ADULT_QTY,child_qty:CHILD_QTY,
                    title_male:TITLE_MALE,nationality:NATIONALITY,
                    accom_format:ACCOM_FORMAT,
                    checkin_time:CHECKIN_TIME,credit_limit:CREDIT_LIMIT,
                    title_female:TITLE_FEMALE,
                    checkout_time:CHECKOUT_TIME,confirm_letter:CONFIRMLETTER,
                    folio_pattern:FOLIO_PATTERN,auto_print_check:AUTOPRINTCHECK,payment_method:PAYMENT_METHOD,
                    vip: VIP,reservation_type:RESERVATION_TYPE,approve_rate: APPROVERATE} = payload

                return {"DEFAULT":{VIP,TITLE,ORIGIN,ADULT_QTY,CHILD_QTY,TITLE_MALE,APPROVERATE,
                        NATIONALITY,ACCOM_FORMAT,CONFIRMLETTER,
                        CHECKIN_TIME:moment(CHECKIN_TIME).format("HH:mm"),
                        CHECKOUT_TIME:moment(CHECKOUT_TIME).format("HH:mm"),
                        CREDIT_LIMIT,TITLE_FEMALE,
                        FOLIO_PATTERN,AUTOPRINTCHECK,PAYMENT_METHOD,RESERVATION_TYPE}}

            case "room_status":
                const { ooi_color:OOI_COLOR, vac_color:VAC_COLOR, pickup_enabled:PICKUP_ENABLED,
                    clean_is_sellable: CLEAN_IS_SELLABLE, inspected_is_sellable: INSPECTED_IS_SELLABLE,
                    inspected_enabled:INSPECTED_ENABLED, occ_color:OCC_COLOR,
                    ooo_color:OOO_COLOR, oos_color:OOS_COLOR, oos_enabled:OOS_ENABLED,
                    return_status: RETURN_STATUS, COLORS } = payload

                return {"ROOM_STATUS": {
                    CLEAN_IS_SELLABLE,
                    INSPECTED_ENABLED,
                    INSPECTED_IS_SELLABLE,
                    COLORS: {
                        ...COLORS,
                        OCC: OCC_COLOR,
                        OOO: OOO_COLOR,
                        OOI: OOI_COLOR,
                        OOS: OOS_COLOR,
                        VAC: VAC_COLOR,
                    },
                    OCC_COLOR,
                    OOO_COLOR,
                    OOI_COLOR,
                    OOS_COLOR,
                    OOS_ENABLED,
                    PICKUP_ENABLED,
                    RETURN_STATUS,
                    VAC_COLOR,
                    }
                }
            default:
                break;
        }
}

function* systemConfigPreviewProcess() {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/system/config/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response = yield APIGetRequest(url, API_PMS_HEADERS)
        console.log(response)
        if (response.status === 200) {
            const { data:{system_params}} = response
            /* @system params : 
                { SYSTEM, DEFAULT, TAX, ROOM_RATE, CALENDAR, BACKUP, CASHIER,DEPOSIT,
                ENDDAY, FOLIO, GUEST_PROFILE, RESERVATION, ROOM_STATUS }  */
                yield put(systemConfigDataSourceUpdate(system_params))
        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "systemConfigPreview", message }
            yield put(systemConfigProcessSuccess(processStatus))
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on saga get system config : ", error)
    }
}

function* systemConfigUpdateProcess({payload}){
    console.log("Payload ",payload)
    const dynamicValue = yield setDynamicValue(payload)
    const reform = { system_params:{ ...dynamicValue }}

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/system/config/update`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    try {
        const API_DATABODY  = reform
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        console.log(response)
        if (response.status === 200) {
            const processStatus = { status: true, action: "update", message: "Update Success" }  
            yield put(systemConfigProcessSuccess(processStatus))
        } else {
            console.log("Error : ", response)
            /* Show error message and signout */
            const { message, data } = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(systemConfigProcessSuccess(processStatus))
            if (response.status === 403) {
                /* if data is not null force signout */
                if (data)
                    yield put(userSignOut())
            }
        }
    } catch (error) {
        console.log("Error on saga update system config : ", error)
    }
}

/* Watcher Saga */
export function* systemConfigPreview() {
    yield takeLatest(SYSTEM_CONFIG_PREVIEW, systemConfigPreviewProcess)
}
export function* systemConfigUpdate(){
    yield takeLatest(SYSTEM_CONFIG_UPDATE,systemConfigUpdateProcess)
}

export default function* rootSaga() {
    yield all([fork(systemConfigPreview),
               fork(systemConfigUpdate)])
}
