import * as actions from "appRedux/actions/rsv/IssueCardActionType"
import {issueCardState} from "./IssueCardType"

const initialState: issueCardState = {
    loader: false,
    dataSource: [],
    setNextCard:undefined,
    process:{ status:false,action:"",message:""}
}

export default (state: issueCardState = initialState,action: actions.IssueCardAction): issueCardState =>{
    switch (action.type) {
    case actions.ISSUE_CARD_REQUEST:{
        return {
            ...state,
            loader:true,
            setNextCard:undefined,
            process: {status:false,action:"",message:""}
        }
    }
	case actions.ISSUE_CARD_DATASOURCE_SUCCESS:{
		return {
			...state,
			loader:false,
			dataSource:action.payload
		}
	}
    case actions.ISSUE_CARD_PROCESS_SUCCESS:{
        return{
            ...state,
            loader:false,
            process:action.payload
        }
    }
    case actions.ISSUE_CARD_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ISSUE_CARD_RESET:{
        return{
            loader: false,
            dataSource:[],
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ISSUE_CARD_NEW_CARD:{
        return{
            ...state,
            loader: true
        }
    }
    case actions.ISSUE_CARD_COPY_CARD:{
        return{
            ...state,
            loader: true
        }
    }
    case actions.ISSUE_CARD_SET_NEXT_CARD:{
        return{
            ...state,
            setNextCard:{
                cardSN: action.payload.cardSerial,
                cardIdx: action.payload.cardIndex
            }
        }
    }
    case actions.ISSUE_CARD_SET_NEXT_CARD_RESET:{
        return{
            ...state,
            setNextCard:undefined
        }
    }
    default:
        return state
    }
}