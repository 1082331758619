import { all, fork, put,takeEvery,takeLatest, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/registration/GuestInhouseActionType"
import * as actionCreators from "appRedux/actions/registration/GuestInhouse"
import { detailType, verifyType } from "appRedux/reducers/registration/GuestInhouseType"

import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import moment from 'moment'
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { AppState } from "appRedux/reducers/ReducerState"
import { select } from "redux-saga/effects"

function* guestInhouseRequestProcess(value: actionTypes.GuestInhouseRequest) {

  const {payload, sorter = undefined} = value

    let keywords = payload
    const guestInhouseInfo = {} as any    
      if(keywords.searchText)
        guestInhouseInfo.search_text = keywords.searchText
      if(keywords.arrival)
        guestInhouseInfo.arrival = moment(keywords.arrival).format('YYYY-MM-DD')
      if(keywords.departure)
        guestInhouseInfo.departure = moment(keywords.departure).format('YYYY-MM-DD')
      if(keywords.stayDate)
        guestInhouseInfo.stay_date = moment(keywords.stayDate).format('YYYY-MM-DD')
      if(keywords.roomNumber)
        guestInhouseInfo.room_number = keywords.roomNumber
      if(keywords.roomTypeId)
        guestInhouseInfo.roomtype_id = keywords.roomTypeId
      if(keywords.channelName)
        guestInhouseInfo.channel_name = keywords.channelName
      if(keywords.vipId)
        guestInhouseInfo.vip_id = keywords.vipId
      if(keywords.memberCode)
        guestInhouseInfo.member_code = keywords.memberCode
      if(keywords.company)
        guestInhouseInfo.company = keywords.company
      if(keywords.nationId)
        guestInhouseInfo.nation_id = keywords.nationId
      if(keywords.ratePlanId)
        guestInhouseInfo.rateplan_id = keywords.ratePlanId
      if(keywords.carplateNo)
        guestInhouseInfo.carplate_no = keywords.carplateNo
      if(keywords.registerId)
        guestInhouseInfo.register_id = keywords.registerId
      if(keywords.bookingId)
        guestInhouseInfo.booking_id = keywords.bookingId

      const roomStatus = []
        if(keywords.statusINH) roomStatus.push("I")
        if(keywords.statusCO) roomStatus.push("O")

      guestInhouseInfo.statuses = isEmpty(roomStatus)?("I,O"):roomStatus.toString()
      guestInhouseInfo.page = keywords.current
      guestInhouseInfo.limit = keywords.limit

    /* Create query string from array object */
    let queryString = Object.keys(guestInhouseInfo).map(key =>`${key}=${guestInhouseInfo[key]}`).join('&')

    /* Set sorter */
    if(sorter !== undefined){
      const sortFieldData = sorter.fieldArray.toString()
      const sortOrderData = sorter.orderArray.toString() 
        queryString += `&sortField=${sortFieldData}&sortOrder=${sortOrderData}`
      }

    try {
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/registration/guestInHouse/search/?${queryString}`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
      const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200){
        /* if no error put everything to reducer */
        const guestInhouseData = response.data
        const {paging} = guestInhouseData
        /* if no error put everything to reducer */
        yield put(actionCreators.guestInhouseDataSourceSuccess(guestInhouseData))
        yield put(actionCreators.guestInhouseSetFilter({...payload,
                                                    current:paging?.current,
                                                    limit:paging?.limit,
                                                    pageSize:paging?.limit,
                                                    total:paging?.total_rows
                                                  })) 

      }else{
        console.log("Error : ",response)
        /* Show error message and signout */
        const {message,data} = response.data
        const processStatus = { status: false, action: "GuestInhousePreview", message}
        yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
        yield put(actionCreators.guestInhouseProcessReset())
        if(response.status === 403){
        //if data is not null force signout 
          if(data)
              yield put(userSignOut())
        }
      }
    } catch (error) {
        console.log("Error on guestInhouse preview : ",error)
        const processStatus = { status: false, action: "GuestInhousePreview", message:error}
        yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
        yield put(actionCreators.guestInhouseProcessReset())
      
    }
}
function* guestInhouseCheckOutProcess({payload}: actionTypes.GuestInhouseCheckOut){
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/checkOut/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = {register_id:payload}
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code,result_msg} = response.data
      const processStatus = { status: true, action: "checkOut", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
    }else { 
      const {message} = response.data
      const processStatus = { status: false, action: "checkOut", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
        const {data} = response.data
        
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "checkOut", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}
function* guestInhouseCancelCheckInProcess({payload}: actionTypes.GuestInhouseCancelCheckIn){
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/cancelCheckIn/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = {register_id:payload}
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code,result_msg} = response.data
      const processStatus = { status: true, action: "cancelCheckIn", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
    }else { 
      const {message} = response.data
      const processStatus = { status: false, action: "cancelCheckIn", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
        const {data} = response.data
        
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "cancelCheckIn", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}
function* guestInhouseCancelCheckOutProcess({payload}: actionTypes.GuestInhouseCancelCheckOut){
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/cancelCheckOut/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = {register_id:payload,new_department:null}
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code,result_msg} = response.data
      const processStatus = { status: true, action: "cancelCheckOut", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
    }else { 
      const {message} = response.data
      const processStatus = { status: false, action: "cancelCheckOut", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
        const {data} = response.data
        
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "cancelCheckOut", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}
function* guestInhouseDetailRequestProcess({payload}: actionTypes.GuestInhouseDetailRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/guestInHouse/${payload}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const guestInhouseDetail:detailType = response.data

                yield put(actionCreators.guestInhouseDetailDataSourceSuccess(guestInhouseDetail))
                yield put(actionCreators.guestInhouseBookingSummarySuccess(guestInhouseDetail.summary)) 
                /* Keep original roomtype from checkin detail */
                const { room_id ,room_number,roomtype_code,roomtype_id,roomtype_name } = guestInhouseDetail
                yield put(actionCreators.guestInhouseCheckinRoomOriginal({room_id,room_number,roomtype_code,roomtype_id,roomtype_name}))
        }else{
                console.log("Error : ",response)
                const {message,data} = response.data
                const processStatus = { status: false, action: "guestinhouse_detail", message}
                yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
                yield put(actionCreators.guestInhouseProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get guest inhouse detail : ",error)
        const processStatus = { status: false, action: "guestinhouse_detail", message:error}
        yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
        yield put(actionCreators.guestInhouseProcessReset())
    }
}
function* guestInhouseVerifyRateProcess({ payload }: actionTypes.GuestInhouseVerifyRate) {
  // Extracting payload data
  console.log('payload', payload)
  const { actionValidate, keep_original_rate, ...others } = payload;
  const { arrival, departure, register_id, arrival_time, departure_time, rateplan_id, room_id, room_number, adult, child, infant, check_rate, check_avail ,upgrade_type,upgrade_reason} = payload;

  // Selecting previous guest in-house data for comparison
  const previousData: verifyType = yield select(
    (state: AppState) => state.guestInHouse.detailDataSource as unknown as verifyType
  );
  // Constructing new verify data
  const verifyInfo: verifyType = {
      register_id: register_id || null,
      rateplan_id,
      arrival_date: arrival,
      departure_date: departure,
      room_id: room_id || undefined,
      room_number: room_id ? undefined : room_number,  // Ensures only one is set
      adult,
      child,
      infant,
      checkin_data: { ...others },
      check_rate,
      check_avail,
      upgrade_type,
      upgrade_reason,
      keep_original_rate,
  };

  // Detect changed fields
  const changedData: Record<string, { old: any; new: any }> = {};
if (previousData) {
  Object.entries(verifyInfo).forEach(([key, newValue]) => {
    const mappedKey = key === "arrival_date" ? "arrival" : key === "departure_date" ? "departure" : key;
    const oldValue = previousData[mappedKey as keyof verifyType];

    if (oldValue !== newValue) {
      changedData[key] = { old: oldValue, new: newValue };
    }
  });
}
  try {
      // Fetch access token
      const { access_token } = yield call(getItems, ["access_token"]);
      const AccessToken = access_token;

      // API request
      const url = `${API_URL_V1}pms/inventory/verifyRateAndAvail/`;
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`;
      const API_DATABODY = {
        register_id: verifyInfo.register_id,
        rateplan_id: verifyInfo.rateplan_id,
        arrival_date: verifyInfo.arrival_date,
        departure_date: verifyInfo.departure_date,
        room_id: verifyInfo.room_id,
        adult: verifyInfo.adult,
        child: verifyInfo.child,
        infant: verifyInfo.infant,
        check_rate: verifyInfo.check_rate,
        check_avail: verifyInfo.check_avail,
        upgrade_type: verifyInfo.upgrade_type,
        upgrade_reason: verifyInfo.upgrade_reason,
        room_number: verifyInfo.room_number,
        keep_original_rate: verifyInfo.keep_original_rate,
        checkin_data: {
          data_old: {
            adult: previousData?.adult || "",
            arrival_date: previousData?.arrival_date || "",
            child: previousData?.child || "",
            departure_date: previousData?.departure || "",
            infant: previousData?.infant || "",
            rateplan_id: previousData?.rateplan_id || "",
            register_id: previousData?.register_id || "",
            room_id: previousData?.room_id || "",
          },
          ...previousData, // เก็บค่าทั้งหมดจาก previousData
          keep_original_rate: verifyInfo.keep_original_rate,
        },

      };
      const currentPath = window.location.pathname.split("/").slice(2, 4).join("/");

      const API_DATABODY_CURRENT = verifyInfo 
      const requestData = currentPath === "guestinhouse/checkin" ? API_DATABODY_CURRENT : API_DATABODY;
      const response: ApiResponse = yield APIPostRequest(url, requestData, API_PMS_HEADERS);

      if (response.status === 200) {
          const { result_code, result_msg, result_data } = response.data;

          if (result_data) {
            const updatedData = {
              ...others,
              ...result_data,
              arrival_time: moment(arrival_time, "HH:mm").format("HH:mm"),
              departure_time: moment(departure_time, "HH:mm").format("HH:mm"),
          }
          
              // Updating Redux store with new data
              yield put(actionCreators.guestInhouseDetailDataSourceSuccess(updatedData));
              // Update booking summary
              yield put(actionCreators.guestInhouseBookingSummarySuccess(result_data.summary));
              yield put(actionCreators.guestInhouseProcessReset());
          }
          const processStatus = { status: true, action: actionValidate, message: result_msg, result_code };

          console.log("📌 Dispatching guestInhouseProcessSuccess with:", processStatus);
          // Dispatch success status
          yield put(actionCreators.guestInhouseProcessSuccess({ status: true, action: actionValidate, message: result_msg, result_code }));
          yield put(actionCreators.guestInhouseProcessReset());
      } else {
          // Handle API errors
          const { message } = response.data;
          yield put(actionCreators.guestInhouseProcessSuccess({ status: false, action: actionValidate, message }));
          yield put(actionCreators.guestInhouseProcessReset());

          if (response.status === 403 && response.data?.data) {
              yield put(userSignOut());
          }
      }
  } catch (error) {
      console.error("❌ Error in verifyRate API call:", error);
      yield put(actionCreators.guestInhouseProcessSuccess({ status: false, action: "verifyRate", message: error }));
      yield put(actionCreators.guestInhouseProcessReset());
  }
}
function* guestInhouseNewProcess({payload}:actionTypes.GuestInhouseNew){

  const guestInhouseInfo = {
    guest_id:payload?.guest_id,
    room_id:payload?.room_id,
    checkin_data: {...payload,
                    arrival_time:moment(payload.arrival_time).format("HH:mm"),
                    departure_time:moment(payload.departure_time).format("HH:mm")
    }
  }

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/walkIn/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = guestInhouseInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){

      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: "guestInhouseWalkIn", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))      
      yield put(actionCreators.guestInhouseProcessReset())

    }else { 
      console.log("Error : ",response)
      const {message,data} = response.data
      const processStatus = { status: false, action: "guestInhouseWalkIn", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
          if(data)
            yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error on save new walkin : ",error)
    const processStatus = { status: false, action: "guestInhouseWalkIn", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}
function* guestInhouseUpdateProcess({payload}: actionTypes.GuestInhouseUpdate){

  const guestInhouseInfo = {
    register_id:payload?.register_id,
    checkin_data: {...payload,
                    arrival_time:moment(payload.arrival_time).format("HH:mm"),
                    departure_time:moment(payload.departure_time).format("HH:mm")
    }
  }

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/guestInHouse/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = guestInhouseInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: "updateGuestInhouse", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))      
      yield put(actionCreators.guestInhouseProcessReset())

    }else { 
      console.log("Error : ",response)
      const {message,data} = response.data
      const processStatus = { status: false, action: "updateGuestInhouse", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
          if(data)
            yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error on create booking : ",error)
    const processStatus = { status: false, action: "updateGuestInhouse", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}
function* guestInhouseBookingSummaryProcess({payload}: actionTypes.GuestInhouseBookingSummaryRequest){
  
  const arrival_time = moment(payload.arrival_time).format('HH:mm')
  const departure_time = moment(payload.departure_time).format('HH:mm')
  
  const reformData = {...payload,arrival_time,departure_time}

  const summaryInfo = { checkin_data: {...reformData}}
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/checkInSummary/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = summaryInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      yield put(actionCreators.guestInhouseBookingSummarySuccess(response.data))   
    }else { 
      console.log("Error : ",response)
    } 
  } catch (error) {
    console.log("Error call checkin summary api : ",error)
  }
}
function* guestInhouseCheckinVerifyProcess({payload}: actionTypes.GuestInhouseCheckinVerify){
    const {booking_item,room_id,guest_id} = payload
    let checkinDetail = {} as any
    if(room_id) checkinDetail.room_id = room_id
    if(guest_id) checkinDetail.guest_id = guest_id
        
    let queryString = Object.keys(checkinDetail).map(key =>`${key}=${checkinDetail[key]}`).join('&')

  const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/checkInDetail/${booking_item}/?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const guestInhouseDetail:detailType = response.data
                yield put(actionCreators.guestInhouseDetailDataSourceSuccess(guestInhouseDetail))
                yield put(actionCreators.guestInhouseBookingSummarySuccess(guestInhouseDetail.summary)) 
                /* Keep original roomtype from checkin detail */
                const { room_id ,room_number,roomtype_code,roomtype_id,roomtype_name } = guestInhouseDetail
                yield put(actionCreators.guestInhouseCheckinRoomOriginal({room_id,room_number,roomtype_code,roomtype_id,roomtype_name}))
        }else{
                console.log("Error : ",response)
                const {message,data} = response.data
                const processStatus = { status: false, action: "guestInhouseCheckinVerify", message}
                yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
                yield put(actionCreators.guestInhouseProcessReset())
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get guest inhouse checkin verify : ",error)
        const processStatus = { status: false, action: "guestInhouseCheckinVerify", message:error}
        yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
        yield put(actionCreators.guestInhouseProcessReset())
    }

}
function* guestInhouseCheckinProcess({payload}: actionTypes.GuestInhouseCheckin){

  const guestInhouseInfo = {
    booking_item: payload?.booking_item,
    guest_id:payload?.guest_id,
    room_id:payload?.room_id,
    upgrade_type: payload?.upgrade_type||null,
    upgrade_reason: payload?.upgrade_reason||null,
    checkin_data: {...payload,
                    arrival_time:moment(payload.arrival_time).format("HH:mm"),
                    departure_time:moment(payload.departure_time).format("HH:mm")
    }
  }

  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/checkIn/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = guestInhouseInfo
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){

      const {result_code, result_msg } = response.data
      const processStatus = { status: true, action: "guestInhouseCheckIn", message:result_msg,result_code}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))      
      yield put(actionCreators.guestInhouseProcessReset())

    }else { 
      console.log("Error : ",response)
      const {message,data} = response.data
      const processStatus = { status: false, action: "guestInhouseCheckIn", message}
      yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
      yield put(actionCreators.guestInhouseProcessReset())
      if(response.status === 403){
          if(data)
            yield put(userSignOut())
      }
    } 
  } catch (error) {
    console.log("Error on save new checkin : ",error)
    const processStatus = { status: false, action: "guestInhouseCheckIn", message:error}
    yield put(actionCreators.guestInhouseProcessSuccess(processStatus))
    yield put(actionCreators.guestInhouseProcessReset())
  }
}

function* guesetInhouseRequest() {
  yield takeEvery(actionTypes.GUEST_INHOUSE_REQUEST, guestInhouseRequestProcess)
}
function* guestInhouseCheckOut(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_CHECK_OUT,guestInhouseCheckOutProcess)
}

function* guestInhouseCancelCheckIn(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_CANCEL_CHECK_IN,guestInhouseCancelCheckInProcess)
}
function* guestInhouseCancelCheckOut(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_CANCEL_CHECK_OUT,guestInhouseCancelCheckOutProcess)
}

function* guestInhouseDetailRequest(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_DETAIL_REQUEST,guestInhouseDetailRequestProcess)
}

function* guestInhouseVerifyRate(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_VERIFY_RATE,guestInhouseVerifyRateProcess)
}
function* guestInhouseNew(){
  yield takeLatest(actionTypes.GUEST_INHOUSE_NEW,guestInhouseNewProcess)
}
function* guestInhouseUpdate(){
  yield takeEvery(actionTypes.GUEST_INHOUSE_UPDATE,guestInhouseUpdateProcess)
}
function* guestInhouseBookingSummary(){
  yield takeEvery(actionTypes.GUEST_INHOUSE_BOOKING_SUMMARY_REQUEST,guestInhouseBookingSummaryProcess)
}
function* guestInhouseCheckinVerify(){
  yield takeEvery(actionTypes.GUEST_INHOUSE_CHECKIN_VERIFY,guestInhouseCheckinVerifyProcess)
}
function* guestInhouseCheckin(){
  yield takeEvery(actionTypes.GUSET_INHOUSE_CHECKIN,guestInhouseCheckinProcess)
}

export default function* rootSaga() {
    yield all([fork(guesetInhouseRequest),
              fork(guestInhouseDetailRequest),
              fork(guestInhouseCheckOut),
              fork(guestInhouseCancelCheckOut),
              fork(guestInhouseCancelCheckIn),
              fork(guestInhouseVerifyRate),
              fork(guestInhouseNew),
              fork(guestInhouseUpdate),
              fork(guestInhouseBookingSummary),
              fork(guestInhouseCheckinVerify),
              fork(guestInhouseCheckin)
  ])
}


