export const BED_TYPE_PREVIEW = "BED_TYPE_PREVIEW"
export const BED_TYPE_NEW = "BED_TYPE_NEW"
export const BED_TYPE_UPDATE = "BED_TYPE_UPDATE"
export const BED_TYPE_REMOVE = "BED_TYPE_REMOVE"
export const BED_TYPE_SEARCH = "BED_TYPE_SEARCH"
export const BED_TYPE_PROCESS_SUCCESS = "BED_TYPE_PROCESS_SUCCESS"
export const BED_TYPE_DATASOURCE_SUCCESS = "BED_TYPE_DATASOURCE_SUCCESS"
export const BED_TYPE_PROCESS_RESET = "BED_TYPE_PROCESS_RESET"
export const BED_TYPE_SWITCH = "BED_TYPE_SWITCH"

export interface BedTypeRequest {
  type: typeof BED_TYPE_PREVIEW,
  payload?:boolean
}
export interface BedTypeNew{
  type: typeof BED_TYPE_NEW,
  payload:any
}
export interface BedTypeUpdate{
  type: typeof BED_TYPE_UPDATE,
  payload:any
}
export interface BedTypeRemove{
  type: typeof BED_TYPE_REMOVE,
  payload:any
}
export interface BedTypeSearch{
  type: typeof BED_TYPE_SEARCH,
  payload:any
}
export interface BedTypeProcessSuccess {
  type: typeof BED_TYPE_PROCESS_SUCCESS,
  payload:{}
}
export interface BedTypeDataSourceSuccess{
  type: typeof BED_TYPE_DATASOURCE_SUCCESS,
  payload:any
}
export interface BedTypeProcessReset {
  type: typeof BED_TYPE_PROCESS_RESET
}
export interface BedTypeSwitch{
  type: typeof BED_TYPE_SWITCH,
  payload:any
}


export type BedTypeAction = 
| BedTypeRequest
| BedTypeNew
| BedTypeUpdate
| BedTypeRemove
| BedTypeSearch
| BedTypeProcessSuccess
| BedTypeDataSourceSuccess
| BedTypeSwitch
| BedTypeProcessReset


