import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  FACILITY_PREVIEW,
  FACILITY_NEW,
  FACILITY_UPDATE,
  FACILITY_REMOVE,
  FACILITY_SEARCH
} from "constants/ActionTypes"

import {facilityDataSourceUpdate,facilityProcessSuccess,facilityProcessReset} from "appRedux/actions/setup/roomInformation/Facility"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* facilityPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/roomFacility/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const facilityDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.facility_id = obj.id
                container.facility_name = obj.name
          return container
          })
            
            /* if no error put everything to reducer */
             yield put(facilityDataSourceUpdate(facilityDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreFacilityList",facilityDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(facilityProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on facility preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(facilityProcessSuccess(processStatus))
    }
    
}

function* facilitySearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFacilityList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.facility_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(facilityDataSourceUpdate(mergeData))
  }else 
      yield put(facilityDataSourceUpdate(ReformalData))
}

function* facilityCreateProcess({payload}){
 
  const {facility_name} = payload
  
  let facilityInfo = {}
      facilityInfo.name = facility_name
   
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomFacility/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = facilityInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success."}
              yield put(facilityProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(facilityProcessSuccess(processStatus))
              yield put(facilityProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(facilityProcessSuccess(processStatus))
            yield put(facilityProcessReset())
      }

}

function* facilityUpdateProcess({payload}){

  const {facility_id,facility_name} = payload
  
  let facilityInfo = {}
      facilityInfo.id = facility_id
      facilityInfo.name = facility_name
   
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/roomFacility/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = facilityInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "update", message:"Update success."}
              yield put(facilityProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message}
              yield put(facilityProcessSuccess(processStatus))
              yield put(facilityProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error}
            yield put(facilityProcessSuccess(processStatus))
            yield put(facilityProcessReset())
      }
}

function* facilityRemoveProcess({payload}){
    const facilityRemove = payload
    
    console.log("Delete id ",facilityRemove)

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/roomFacility/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : facilityRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(facilityProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(facilityProcessSuccess(processStatus))
          yield put(facilityProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(facilityProcessSuccess(processStatus))
      yield put(facilityProcessReset())
    }
}

/* Watcher Saga */
export function* facilityPreview() {
  yield takeLatest(FACILITY_PREVIEW, facilityPreviewProcess)
}
export function* facilitySearch(){
  yield takeLatest(FACILITY_SEARCH, facilitySearchProcess)
}
export function* facilityCreate(){
  yield takeLatest(FACILITY_NEW,facilityCreateProcess)
}
export function* facilityUpdate(){
  yield takeLatest(FACILITY_UPDATE, facilityUpdateProcess)
}
export function* facilityRemove(){
  yield takeLatest(FACILITY_REMOVE,facilityRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(facilityPreview),
             fork(facilityCreate),
             fork(facilitySearch),
             fork(facilityUpdate),
             fork(facilityRemove)])


}
