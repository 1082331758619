import * as actions from "appRedux/actions/cashier/chargeScheduleActionType"

export interface chargeState {
    loader: boolean,
    keyword:string,
    dataSource: [],
    process:any
}

const initialState: chargeState = {
    loader: false,
    keyword:'',
    dataSource: [],
    process:{ status:false,action:"",message:""}
}

export default (state: chargeState = initialState,action: actions.CashierAction): chargeState =>{
    switch (action.type) {
    case actions.CHARGE_SCHEDULE_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.CHARGE_SCHEDULE_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.CHARGE_SCHEDULE_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.CHARGE_SCHEDULE_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}