import { all, call, fork, put, takeEvery, select } from "redux-saga/effects"
import { osName, browserName, browserVersion } from "react-device-detect"
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  RESET_PASSWORD,
  VERIFY_USER,
  VERIFY_OTP,
  CHANGE_WORK_SHIFT,
  REQUEST_RESET_USER,
  // VERIFY_TOKEN
} from "constants/ActionTypes"
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  resetPasswordSuccess,
  userVerifySuccess,
  verifyOTPSuccess,
  changeWorkShiftSuccess,
  authProcessSuccess,
  requestResetUserSuccess,
} from "../actions/Auth"
import { reservationSetFilter } from "appRedux/actions/rsv/Reservation"
import { guestInhouseSetFilter } from "appRedux/actions/registration/GuestInhouse"
import { expectedArrivalSetFilter } from "appRedux/actions/expected/ExpectedArrival"
import { expectedDepartureSetFilter } from "appRedux/actions/expected/ExpectedDeparture"
import { systemConfigDataSourceUpdate } from "../actions/setup/systemSetup/systemConfig"
import { cashierSetFilter } from "appRedux/actions/cashier/guest"
import { cashierBookingSetFilter } from "appRedux/actions/cashier/booking"
import { cashierCashSaleSetFilter } from "appRedux/actions/cashier/cashSale"
import { APIPostRequest } from "util/connection"
import { API_URL_AUTH, API_HEADERS } from "constants/ApiSetting"
import {
  setItems,
  getItems,
  removeItems,
  removeItemsBySession,
} from "util/custom"
import { IndexedDBOpen, IndexedDBDeleteDatabase } from "util/indexedDBLibrary"
import { DataStore } from "constants/DataStore"
//import { notificationPreview} from "appRedux/actions/Notifications"
import { stateSet } from "constants/StateDefault"

import { REQUEST_RESET_USER_URL } from "constants/ConstantsVariable"

export const getAuthUser = (state) => state.auth;

function* signInWithUserAndPassword({ payload }) {
  try {
    yield IndexedDBDeleteDatabase("ListBoxDB")

    const URL = `${API_URL_AUTH}auth/login/`
    const { username, password } = payload
    /* Split username case get prop_code */
    const splitUsername = username.split("@") //your_username@your_prop_code

    delete API_HEADERS.headers.Authorization
    const deviceInfo = `${osName} - ${browserName} V.${browserVersion}`
    const API_DATABODY = {
      prop_code: splitUsername[1],
      username: splitUsername[0],
      password: password,
      device: deviceInfo,
      renew_session: false,
    };

    const resSignIn = yield call(
      APIPostRequest,
      URL,
      API_DATABODY,
      API_HEADERS
    );
    const { status, data } = resSignIn

    if (status === 200) {
      const {
        system_config: { system_params },
      } = data;
      setItems({ access_token: data.access_token })

      /*  run multiple Effects in parallel and wait for all of them to complete */
      yield all([
        put(userSignInSuccess(data)),
        put(systemConfigDataSourceUpdate(system_params)),
        put(reservationSetFilter([])),
        put(guestInhouseSetFilter([])),
        put(expectedArrivalSetFilter([])),
        put(expectedDepartureSetFilter([])),
        put(cashierSetFilter([])),
        put(cashierBookingSetFilter([])),
        put(cashierCashSaleSetFilter([])),
      ])
      //yield put(notificationPreview())

      yield IndexedDBOpen("ListBoxDB", DataStore)
    } else {
      const { message } = data;
      removeItems(["access_token", stateSet])
      yield put(showAuthMessage(message))
    }
  } catch (error) {
    removeItems(["access_token", stateSet])
    yield put(showAuthMessage(error))
  }
}

// function* verifyTokenProcess({payload}) {
//   console.log('== GET VERIFY ==')
// try {
//   const  { access_token }  = yield call(getItems, ['access_token'])
//   const url =  `${API_URL_AUTH}auth/verify/?access_token=${access_token}&grant_type=information_user`
//   // API_PMS_HEADERS.headers.Authorization = `Bearer ${access_token}`
//   delete API_HEADERS.headers.Authorization
//   const response = yield APIGetRequest(url,API_HEADERS)
//   if(response.status === 200){
//       const dataResponse = response.data
//       console.log('VERIFY ', dataResponse)
//       yield put(userSignInSuccess(dataResponse))
//       yield put(notificationPreview())
//   }else{
//     const {message, data} = response.data
//     yield put(showAuthMessage(message))
//     if(response.status === 403){
//       /* if data is not null force signout */
//       if(data)
//         yield put(userSignOut())
//     }
//   }
// } catch (error) {
//   console.log("Error : ",error)
//   yield put(showAuthMessage(error))
// }
// }

function* signOut() {
  const { access_token } = yield call(getItems, ["access_token"]);
  const AuthUser = yield select(getAuthUser);
  const log_id = AuthUser.authUser ? AuthUser.authUser.log_id : 0;

  if (log_id === 0) {
    removeItems(["access_token", stateSet]);
    localStorage.clear();
    removeItemsBySession(["report_active_menu_category"]);
    sessionStorage.clear();
    yield put(userSignOutSuccess());
  } else {
    try {
      const URL = `${API_URL_AUTH}auth/logout/`;
      API_HEADERS.headers.Authorization = `${process.env.REACT_APP_ACCESS_TOKEN_TYPE} ${access_token}`;
      const API_DATABODY = { log_id: log_id };

      yield call(APIPostRequest, URL, API_DATABODY, API_HEADERS);
      //Remove all Item Storage
      removeItems(["access_token", stateSet]);
      localStorage.clear();
      removeItemsBySession(["report_active_menu_category"]);
      sessionStorage.clear();
      yield put(userSignOutSuccess());
    } catch (error) {
      console.log("signOut:error", error);
      yield put(showAuthMessage(error));
    }
  }
}

function* verifyUserProcess({ payload }) {
  try {
    const URL = `${API_URL_AUTH}auth/otp/generate/`;
    const { otp_option, user, action } = payload;
    const splitUsername = user.split("@");
    const grantType =
      action === "activate" ? "activate_user" : "forgot_password";
    let receivedBy = "";
    let sentTo = "";
    if (otp_option === "email") {
      receivedBy = "email";
      sentTo = payload.email;
    } else {
      receivedBy = "phone";
      sentTo = payload.mobile;
    }

    const API_DATABODY = {
      grant_type: grantType,
      info: {
        prop_code: splitUsername[1],
        user_name: splitUsername[0],
        received_by: receivedBy,
        to: sentTo,
      },
    };
    delete API_HEADERS.headers.Authorization;

    const response = yield call(APIPostRequest, URL, API_DATABODY, API_HEADERS);
    if (response.status === 200) {
      console.log("Generate OTP success.");

      yield put(userVerifySuccess(response.data));
    } else {
      console.log("Error. ", response.data.message);
      yield put(showAuthMessage(response.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* verifyOTPProcess({ payload }) {
  try {
    const URL = `${API_URL_AUTH}auth/otp/verify/`;
    const { otp_number, secret_key } = payload;

    const API_DATABODY = {
      secret_key: secret_key,
      otp_number: otp_number,
    };
    delete API_HEADERS.headers.Authorization;

    const response = yield call(APIPostRequest, URL, API_DATABODY, API_HEADERS);
    if (response.status === 200) {
      console.log("Verity OTP success.");

      yield put(verifyOTPSuccess(response.data));
    } else {
      console.log("Error. ", response.data.message);
      yield put(showAuthMessage(response.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* resetPasswordProcess({ payload }) {
  try {
    const URL = `${API_URL_AUTH}auth/user/resetPassword/`;
    const { password, uid, action, gt } = payload;
    const grantType = action === "OTP" ? gt : "activate_user";

    const API_DATABODY = {
      grant_type: grantType,
      code_reset: uid,
      new_password: password,
    };

    delete API_HEADERS.headers.Authorization;

    const response = yield call(APIPostRequest, URL, API_DATABODY, API_HEADERS);
    if (response.status === 200) {
      //console.log("Reset success.")
      yield put(resetPasswordSuccess());
    } else {
      //console.log("Error. ",response.data.message)
      yield put(showAuthMessage(response.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* changeWorkShiftProcess({ payload }) {
  try {
    const { workShift, user_id, prop_id } = payload;

    const { access_token } = yield call(getItems, ["access_token"]);
    const AccessToken = access_token;

    const API_DATABODY = { user_id, prop_id, new_shift: workShift };
    API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`;
    const URL = `${API_URL_AUTH}auth/user/changeWorkShift/`;
    const response = yield APIPostRequest(URL, API_DATABODY, API_HEADERS);
    if (response.status === 200) {
      yield put(changeWorkShiftSuccess(workShift));
    } else {
      const message =
        response.status === 404 ? "404 Not found" : response.message;
      const processStatus = {
        status: false,
        action: "authChangeWorkShift",
        message: message || "",
      };
      yield put(authProcessSuccess(processStatus));
    }
  } catch (error) {
    const processStatus = {
      status: false,
      action: "authChangeWorkShift",
      message: error,
    };
    yield put(authProcessSuccess(processStatus));
  }
}

function* requestResetUserProcess({ payload }) {
  try {
    console.log("4. Saga: requestResetUserProcess started");
    const URL = `${API_URL_AUTH}${REQUEST_RESET_USER_URL}`;
    const { action_type, user_name, email } = payload;
    console.log("5. Payload received:", payload);

    const API_DATABODY = {
      action_type: action_type,
      user_name: user_name,
      email: email,
    };

    delete API_HEADERS.headers.Authorization;

    const response = yield call(APIPostRequest, URL, API_DATABODY, API_HEADERS);
    console.log("6. API Response:", response);

    yield put(requestResetUserSuccess(response));

  } catch (error) {
    console.log("9. Exception:", error);
    yield put(showAuthMessage(error));
  }
}

/* Watcher Saga */
export function* signInUserAndPass() {
  yield takeEvery(SIGNIN_USER, signInWithUserAndPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPasswordProcess);
}

export function* verifyUser() {
  yield takeEvery(VERIFY_USER, verifyUserProcess);
}

export function* verifyOTP() {
  yield takeEvery(VERIFY_OTP, verifyOTPProcess);
}

export function* changeWorkShift() {
  yield takeEvery(CHANGE_WORK_SHIFT, changeWorkShiftProcess);
}

export function* requestResetUser() {
  yield takeEvery(REQUEST_RESET_USER, requestResetUserProcess);
}

// export function* verifyTokenData(){
//   yield takeLatest(VERIFY_TOKEN, verifyTokenProcess)
// }

export default function* rootSaga() {
  yield all([
    fork(signOutUser),
    fork(signInUserAndPass),
    fork(resetPassword),
    fork(verifyUser),
    fork(verifyOTP),
    fork(changeWorkShift),
    fork(requestResetUser),
    //  fork(verifyTokenData),
  ]);
}
