import * as actions from "./currentLoacationActionType"

export const currentLocationAdd = (payload?: any): actions.CurrentLocationAdd => {
    return {
        type: actions.CURRENT_LOCATION_ADD,
        payload
        }
    }
export const currentLocationRemove = (payload?: any): actions.CurrentLocationRemove => {
    return {
        type: actions.CURRENT_LOCATION_REMOVE,
        payload
        }
    }
export const CurrentLocationDataSouceSuccess = (payload?:any): actions.CurrentLocationDataSouceSuccess =>{
    return{
        type: actions.CURRENT_LOCATION_DATASOURCE_SUCCESS,
        payload
    }
}
export const currentLocationReset = (): actions.CurrentLocationReset => {
    return {
        type: actions.CURRENT_LOCATION_RESET
        }
}