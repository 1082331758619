
export const AUTOPOST_ROOM_REQUEST = "AUTOPOST_ROOM_REQUEST"
export const AUTOPOST_ROOM_RUN = "AUTOPOST_ROOM_RUN"
export const AUTOPOST_ROOM_REALTIME_UPDATE = "AUTOPOST_ROOM_REALTIME_UPDATE"
export const AUTOPOST_ROOM_DATASOURCE_SUCCESS = "AUTOPOST_ROOM_DATASOURCE_SUCCESS"
export const AUTOPOST_ROOM_PROCESS_SUCCESS = "AUTOPOST_ROOM_PROCESS_SUCCESS"
export const AUTOPOST_ROOM_PROCESS_RESET = "AUTOPOST_ROOM_PROCESS_RESET"


export interface AutoPostRoomRequest {
    type: typeof AUTOPOST_ROOM_REQUEST
}

export interface AutoPostRoomRun {
    type: typeof AUTOPOST_ROOM_RUN,
    payload:any
}

export interface AutoPostRoomRealTimeUpdate {
    type: typeof AUTOPOST_ROOM_REALTIME_UPDATE,
    payload?: any
}
export interface AutoPostRoomProcessSuccess {
    type: typeof AUTOPOST_ROOM_PROCESS_SUCCESS,
    payload: any
}
export interface AutoPostRoomDataSourceSuccess {
    type: typeof AUTOPOST_ROOM_DATASOURCE_SUCCESS,
    payload: any
}
export interface AutoPostRoomProcessReset {
    type: typeof AUTOPOST_ROOM_PROCESS_RESET
}


export type AutoPostRoomAction =
    | AutoPostRoomRequest
    | AutoPostRoomRun
    | AutoPostRoomRealTimeUpdate
    | AutoPostRoomProcessSuccess
    | AutoPostRoomDataSourceSuccess
    | AutoPostRoomProcessReset
