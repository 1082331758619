export const DataStore = [
  { key: 0, name: "objFilterReservation" },
  { key: 1, name: "objStoreCountryList" },
  { key: 2, name: "objStoreRoomTypeList" },
  { key: 3, name: "objStoreChannelTypeList" },
  { key: 4, name: "objStoreOriginList" },
  { key: 5, name: "objStoreSalesList" },
  { key: 6, name: "objStoreMarketList" },
  { key: 7, name: "objStoreNationList" },
  { key: 8, name: "objStoreFolioList" },
  { key: 9, name: "objStoreRoomGroupList" },
  { key: 10, name: "objStoreInclusionList" },
  { key: 11, name: "objStoreRatePlanList" },
  { key: 12, name: "objStoreVipList" },
  { key: 13, name: "objStoreFacilityList" },
  { key: 14, name: "objStoreBuildingList" },
  { key: 15, name: "objStoreLocationList" },
  { key: 16, name: "objStoreExposureList" },
  { key: 17, name: "objStoreRoomInfoList" },
  { key: 18, name: "objStoreBedTypeList" },
  { key: 19, name: "objStoreTitleNameList" },
  { key: 20, name: "objStoreOccupationList" },
  { key: 21, name: "objStoreOutOfOrderList" },
  { key: 22, name: "objStoreCityList" },
  { key: 23, name: "objStoreSocialList" },
  { key: 24, name: "objStoreReservationTypeList" },
  { key: 25, name: "objStoreLanguageList" },
  { key: 26, name: "objStoreChannelsList" },
  { key: 27, name: "objStoreMediaList" },
  { key: 28, name: "objStoreAlertPageList" },
  { key: 29, name: "objStoreFollowUpCategoryList" },
  { key: 30, name: "objStoreFollowUpStatusList" },
  { key: 31, name: "objStoreUserList" },
  { key: 32, name: "objStoreDepartmentList" },
  { key: 33, name: "objStoreDepartmentGroupList" },
  { key: 34, name: "objStoreMediaGroupList" },
  { key: 35, name: "objStoreChannelGroupList" },
  { key: 36, name: "objStoreItemizerList" },
  { key: 37, name: "objStoreMealTypeList" },
  { key: 38, name: "objStoreRateGroupList" }
]

// export const DataReservationStore = [
//   { key:1, name: "objFilterReservation"}
// ]