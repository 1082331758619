import * as actions from "./userActionType"

export const userPreview = (payload?: any): actions.UserRequest => {
  return {
    type: actions.USER_PREVIEW,
    payload
  }
}

export const userCreate = (payload:any):actions.UserNew =>{
    return {
      type:actions.USER_NEW,
      payload
    }
}
export const userUpdate = (payload:any):actions.UserUpdate =>{
    return {
      type:actions.USER_UPDATE,
      payload
    }
}
export const userRemove = (payload:any):actions.UserRemove =>{
    return {
      type:actions.USER_REMOVE,
      payload
    }
}
export const userSearch = (payload:any):actions.UserSearch =>{
    return {
      type:actions.USER_SEARCH,
      payload
    }
}
export const userDataSourceUpdate = (payload:any):actions.UserDataSourceSuccess =>{
    return {
      type: actions.USER_DATASOURCE_SUCCESS,
      payload
    }
  }
  
export const userProcessSuccess = (payload:any):actions.UserProcessSuccess =>{
    return{
      type: actions.USER_PROCESS_SUCCESS,
      payload
    }
  }


export const getPropertyList = (payload:any):actions.GetPropertyList =>{
    return{
      type: actions.USER_PROPERTY_LIST,
      payload
    }
  }

  export const getUserGroupList = (payload:any):actions.GetUserGroupList=>{
    return{
      type: actions.USER_GROUP_LIST,
      payload
    }
  }

  export const userPermissionPreview = (payload:any):actions.UserPermissionPreview =>{
    return{
      type: actions.USER_PERMISSION_PREVIEW,
      payload
    }
  }

  export const userPermissionSetDataSource = (payload:any):actions.UserPermissionSetDataSource =>{
    return{
      type:actions.USER_PERMISSION_DATASOURCE,
      payload
    }
  }

  export const userPermissionSearch = (payload:any):actions.UserPermissionSearch =>{
    return{
      type: actions.USER_PERMISSION_SEARCH,
      payload
    }
  }

  export const userPermissionUpdate = (payload:any):actions.UserPermissionUpdate =>{
    return{
      type:actions.USER_PERMISSION_UPDATE,
      payload
    }
  }

  export const userPermissionUpdateSuccess = (payload:any):actions.UserPermissionUpdateSuccess =>{
    return{
      type:actions.USER_PERMISSION_UPDATE_SUCCESS,
      payload
    }
  }

  export const userSwitchEnabled = (payload:any):actions.UserSwitchEnabled=>{
    return{
      type:actions.USER_SWITCH_ENABLED,
      payload
    }
  }

  export const userProcessReset = () =>{
    return{
      type:actions.USER_PROCESS_RESET
    }
  }
