import { all, fork, takeEvery,takeLatest,select,put }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/currentLocation/currentLoacationActionType"
import * as actionCreators from "appRedux/actions/currentLocation"
import { AppState } from "ReducerState"

function* currentLocationAddProcess({ payload }: actionTypes.CurrentLocationAdd) {

    const {markLocations} = yield select((state:AppState) => state.currentLocation)
    const locationsInfo = [...markLocations,payload]
    yield put(actionCreators.CurrentLocationDataSouceSuccess([...new Set(locationsInfo)]))
}
function* currentLocationRemoveProcess({payload}: actionTypes.CurrentLocationRemove){
    const {markLocations} = yield select((state:AppState) => state.currentLocation)
    const locationsInfo = markLocations.filter((obj:string)=>obj!==payload)
    yield put(actionCreators.CurrentLocationDataSouceSuccess(locationsInfo))
}


function* currentLocationAdd() {
    yield takeEvery(actionTypes.CURRENT_LOCATION_ADD, currentLocationAddProcess)
}
function* currentLocationRemove(){
    yield takeLatest(actionTypes.CURRENT_LOCATION_REMOVE,currentLocationRemoveProcess)
}




export default function* rootSaga() {
    yield all([fork(currentLocationAdd),
        fork(currentLocationRemove)])
}


