import { all, fork, put, takeEvery, takeLatest, call } from "redux-saga/effects"
import {
  VIP_PREVIEW,
  VIP_NEW,
  VIP_UPDATE,
  VIP_REMOVE
} from "constants/ActionTypes"

import { vipDataSourceUpdate, vipProcessSuccess, vipProcessReset } from "appRedux/actions/setup/miscellaneous/Vip"
import { userSignOut } from "appRedux/actions/Auth"

import { APIGetRequest, APIPostRequest } from "util/connection"
import { API_URL_V1, API_PMS_HEADERS } from "constants/ApiSetting"
import { IndexedDBAdd } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"
import { orderBy } from "lodash"

function* vipPreviewProcess() {
  const { access_token } = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_V1}pms/masterfile/vipLevel/`
  API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response = yield APIGetRequest(url, API_PMS_HEADERS)

    if (response.status === 200) {
      /* if no error put everything to reducer */
      yield put(vipDataSourceUpdate(response.data))
      const DataResource = orderBy(response.data, [o => o.name.toLowerCase()], ['asc']).map((obj, key) => {
        const container = {}
        container.key = key
        container.vip_id = obj.id
        container.vip_code = obj.code
        container.vip_name = obj.name
        container.enabled = obj.enabled
        return container
      })
      yield IndexedDBAdd("ListBoxDB", "objStoreVipList", DataResource)
    } else {
      yield put(vipDataSourceUpdate([]))
      console.log("Error : ", response)
      /* Show error message and signout */
      const { message, data } = response.data
      const processStatus = { status: false, action: "preview", message }
      yield put(vipProcessSuccess(processStatus))
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error on vip preview : ", error)
    const processStatus = { status: false, action: "preview", message: error }
    yield put(vipProcessSuccess(processStatus))
  }
}

function* vipCreateProcess({ payload }) {
  let dataInfo = {
    code: payload.code,
    name: payload.name,
    enabled: payload.enabled
  }


  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/vipLevel/create/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = dataInfo
    const response = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const processStatus = { status: true, action: "create", message: "Create success." }
      yield put(vipProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "create", message }
      yield put(vipProcessSuccess(processStatus))
      yield put(vipProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "create", message: error }
    yield put(vipProcessSuccess(processStatus))
    yield put(vipProcessReset())
  }
}


function* vipUpdateProcess({ payload }) {
  let dataInfo = {
    id: payload.id,
    code: payload.code,
    name: payload.name,
    enabled: payload.enabled
  }

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/vipLevel/update/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = dataInfo
    const response = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)

    if (response.status === 200) {
      const processStatus = { status: true, action: "update", message: "Update success." }
      yield put(vipProcessSuccess(processStatus))
      yield put(vipProcessReset())
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "update", message }
      yield put(vipProcessSuccess(processStatus))
      yield put(vipProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }

  } catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "update", message: error }
    yield put(vipProcessSuccess(processStatus))
    yield put(vipProcessReset())
  }
}

function* vipRemoveProcess({ payload }) {
  const vipId = payload

  try {
    const { access_token } = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/vipLevel/delete/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY = { id: vipId }
    const response = yield APIPostRequest(url, API_DATABODY, API_PMS_HEADERS)
    if (response.status === 200) {
      const processStatus = { status: true, action: "delete", message: "Delete success." }
      yield put(vipProcessSuccess(processStatus))
    } else {
      const { message, data } = response.data
      const processStatus = { status: false, action: "delete", message }
      yield put(vipProcessSuccess(processStatus))
      yield put(vipProcessReset())
      if (response.status === 403) {
        /* if data is not null force signout */
        if (data)
          yield put(userSignOut())
      }
    }
  }
  catch (error) {
    console.log("Error : ", error)
    const processStatus = { status: false, action: "delete", message: error }
    yield put(vipProcessSuccess(processStatus))
    yield put(vipProcessReset())
  }
}

/* Watcher Saga */
export function* vipPreviewData() {
  yield takeLatest(VIP_PREVIEW, vipPreviewProcess)
}

export function* vipCreate() {
  yield takeEvery(VIP_NEW, vipCreateProcess)
}

export function* vipUpdate() {
  yield takeEvery(VIP_UPDATE, vipUpdateProcess)
}

export function* vipRemove() {
  yield takeEvery(VIP_REMOVE, vipRemoveProcess)
}

export default function* rootSaga() {
  yield all([
    fork(vipPreviewData),
    fork(vipCreate),
    fork(vipUpdate),
    fork(vipRemove)
  ])
}