import { openDB,deleteDB } from "idb"

const keyValue = "dataStore"

async function IndexedDBOpen(dbName, storeName, version = 1) {
  // This works on all devices/browsers, and uses IndexedDBShim as a final fallback
  await openDB(dbName, version, {
    upgrade(db) {
      storeName.map(value => {
        if (!db.objectStoreNames.contains(value.name)) {
          db.createObjectStore(value.name, { autoIncrement: true })
        }
        return true
      })
    }
  }).then(()=> {
    storeName.forEach(obj => IndexedDBRemove("ListBoxDB",obj.name))
  })
}

async function IndexedDBAdd(dbName, storeName, dataStore, version = 1) {
  const db = await openDB(dbName, version)

  const tx = db.transaction(storeName, "readwrite")
  const store = await tx.objectStore(storeName)
        store.put(dataStore,keyValue)

  tx.oncomplete = () => {
    // console.log("**Add IndexDB success Store**")
  }
  tx.onerror = e => {
    // console.log("Error IndexDBADD", e.target.errorCode)
  }
  await tx.done
}

async function IndexedDBRemove(dbName, storeName, Removekey=keyValue, version = 1) {
  const db = await openDB(dbName, version)

  const tx = await db.transaction(storeName, "readwrite")
  const store = await tx.objectStore(storeName)

  await store.delete(Removekey)
  await tx.done
}

async function IndexedDBDeleteDatabase(dbName) {
    await deleteDB(dbName)
}


const IndexDBGetDataAll = async(dbName,storeName,version=1)=>{
  const db = await openDB(dbName, version)

  const store = await db.transaction(storeName,"readonly").objectStore(storeName)
  let resArray = []
  await store.getAll(keyValue).then((res) => {
    resArray =  res
  })
  .catch(error => {
    // console.log('error get all db',error)
  })
  return resArray
}

export {IndexedDBOpen,IndexedDBAdd,IndexedDBRemove,IndexDBGetDataAll,IndexedDBDeleteDatabase}