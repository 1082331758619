import {
  ROOMINFO_PREVIEW,
  ROOMINFO_NEW,
  ROOMINFO_UPDATE,
  ROOMINFO_REMOVE,
  ROOMINFO_PROCESS_SUCCESS,
  ROOMINFO_DATASOURCE_SUCCESS,
  ROOMINFO_SEARCH,
  ROOMINFO_DETAIL,
  ROOMINFO_DETAIL_SUCCESS,
  ROOMINFO_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  roomInfoDetailDataSource:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ROOMINFO_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case ROOMINFO_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case ROOMINFO_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""}
        }
    }
    case ROOMINFO_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case ROOMINFO_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case ROOMINFO_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case ROOMINFO_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case ROOMINFO_DETAIL:{
      return{
        ...state,
        loader:false,
        roomInfoDetailDataSource:{}
      }
    }
    case ROOMINFO_DETAIL_SUCCESS:{
      return{
        ...state,
        loader:false,
        roomInfoDetailDataSource:action.payload
      }
    }
    case ROOMINFO_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}