import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/chargeSchedule/ChargeInhouseActionType"
import * as actionCreators from "appRedux/actions/chargeSchedule/ChargeInhouse"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { chargeScheduleType, updateRateInhouseType } from "appRedux/reducers/chargeSchedule/chargeType"


function* chargeInhouseRequestProcess({payload}: actionTypes.ChargeInhouseRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/guestInHouse/${payload}/roomChargeSchedule/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        
        if(response.status === 200)
        {      
                const chargeRateInfo:chargeScheduleType[] = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.chargeInhouseDataSourceSuccess(chargeRateInfo))
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "chargeInhouse", message}
                yield put(actionCreators.chargeInhouseProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                    if(data)
                        yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get chargeInhouse : ",error)
        const processStatus = { status: false, action: "chargeInhouse", message:error}
        yield put(actionCreators.chargeInhouseProcessSuccess(processStatus))
    }
}

function* chargeInhouseUpdateProcess({payload}:actionTypes.ChargeInhouseUpdate){
    const {registerID,trans,valueUpdate} = payload
    const items = trans.map((obj:any)=>{
        const dates = obj.selectedRows.map((o:any)=>o.date)
        return {id:obj.id,dates}
    })
    const updateData = {} as updateRateInhouseType
        updateData.register_id = registerID
        updateData.items = items
        updateData.room_rate = valueUpdate.room_rate ? parseFloat(valueUpdate.room_rate) : null
        updateData.extra_adult = valueUpdate.extra_adult ? parseInt(valueUpdate.extra_adult) : null
        updateData.extra_child = valueUpdate.extra_child ?parseInt(valueUpdate.extra_child) : null
        updateData.extra_infant = valueUpdate.extra_infant ? parseInt(valueUpdate.extra_infant) : null
        updateData.mealtype_id = valueUpdate.mealtype_id ? parseInt(valueUpdate.mealtype_id) : null

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/registration/guestInHouse/updateRoomChargeSchedule/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = updateData
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const {result_code} = response.data
            const result_msg = result_code==="0"?"Save ":response.data.result_msg
            const processStatus = { status: true, action: "updateRateSchedule", message:result_msg,result_code}
            yield put(actionCreators.chargeInhouseProcessSuccess(processStatus))
        }else { 
            const {message} = response.data
            const processStatus = { status: false, action: "updateRateSchedule", message}
            yield put(actionCreators.chargeInhouseProcessSuccess(processStatus))
            if(response.status === 403){
            const {data} = response.data
            
            /* if data is not null force signout */
            if(data)
                yield put(userSignOut())
            }
        }
        } catch (error) {
        const processStatus = { status: false, action: "updateRateSchedule", message:error}
        yield put(actionCreators.chargeInhouseProcessSuccess(processStatus))
        }
}


function* chargeInhouseDetailProcess({payload}: actionTypes.ChargeInhouseDetailRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/guestInHouse/${payload}`

    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        console.log("res ",response)
        if(response.status === 200)
        {      
                const {guests, extra_adult,extra_child,extra_infant} = response.data
                const {full_name} = guests[0]
                /* if no error put everything to reducer */
                yield put(actionCreators.chargeInhouseDetailDataSourceSuccess({full_name,extra_adult,extra_child,extra_infant})) 
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "guestInhouseDetail", message}
                console.log("process ",processStatus)
                if(response.status === 403){
                //if data is not null force signout 
                if(data)
                    yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get reservation detail folio : ",error)
        //const processStatus = { status: false, action: "guestInhouseDetail", message:error}
    }
}


function* chargeInhouseRequest(){
    yield takeEvery(actionTypes.CHARGE_INHOUSE_REQUEST,chargeInhouseRequestProcess)
}

function* chargeInhouseUpdate(){
    yield takeEvery(actionTypes.CHARGE_INHOUSE_UPDATE,chargeInhouseUpdateProcess)
}

function* chargeInhouseDetail(){
    yield takeEvery(actionTypes.CHARGE_INHOUSE_DETAIL_REQUEST,chargeInhouseDetailProcess)
}

export default function* rootSaga() {
    yield all([fork(chargeInhouseRequest),
            fork(chargeInhouseUpdate),
            fork(chargeInhouseDetail)
    ])
}


