import * as actions from "appRedux/actions/setup/department/itemizerActionType"

export interface itemizerType{
  code: string,
  name: string,
  rate?: number|null,
  amount?: number|null,
  calc_on_baseprice: boolean,
  enabled: boolean,
  is_service: boolean,
  is_tax: boolean
}

export interface itemizerState {
    loader: boolean,
    dataSource: Array<itemizerType>, 
    dataSerialize: any,
    keyword?:string,  
    process:any
}


const initialState: itemizerState = {
    loader: false,
    dataSource: [],
    dataSerialize:undefined,
    process:{ status:false,action:"",message:""}
}

export default (state: itemizerState = initialState,action: actions.ItemizerAction): itemizerState =>{
    switch (action.type) {
    case actions.ITEMIZER_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.ITEMIZER_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.ITEMIZER_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.ITEMIZER_NEW:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ITEMIZER_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ITEMIZER_REMOVE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ITEMIZER_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}