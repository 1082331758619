import * as actions from "./RateGroupActionType"

export const rateGroupPreview = (payload?: any): actions.RateGroupRequest => {
  return {
    type: actions.RATEGROUP_PREVIEW,
    payload
  }
}

export const rateGroupCreate = (payload: any): actions.RateGroupNew => {
  return {
    type: actions.RATEGROUP_NEW,
    payload
  }
}

export const rateGroupUpdate = (payload: any): actions.RateGroupUpdate => {
  return {
    type: actions.RATEGROUP_UPDATE,
    payload
  }
}
export const rateGroupRemove = (payload: number): actions.RateGroupRemove => {
  return {
    type: actions.RATEGROUP_REMOVE,
    payload
  }
}
export const rateGroupProcessSuccess = (payload: any): actions.RateGroupProcessSuccess => {
  return {
    type: actions.RATEGROUP_PROCESS_SUCCESS,
    payload
  }
}


export const rateGroupDataSourceUpdate = (value: any): actions.RateGroupDataSouceSuccess => {
  return {
    type: actions.RATEGROUP_DATASOURCE_SUCCESS,
    payload: value
  }
}
export const rateGroupProcessReset = () => {
  return {
    type: actions.RATEGROUP_PROCESS_RESET
  }
}
export const rateGroupSwitchEnable = (value: any): actions.RateGroupSwitchEnabled => {
  return {
    type: actions.RATEGROUP_SWITCH_ENABLED,
    payload: value
  }
}
