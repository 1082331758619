import {
    RESERVATION_TYPE_PREVIEW,
    RESERVATION_TYPE_NEW,
    RESERVATION_TYPE_UPDATE,
    RESERVATION_TYPE_REMOVE,
    RESERVATION_TYPE_SEARCH,
    RESERVATION_TYPE_PROCESS_SUCCESS,
    RESERVATION_TYPE_DATASOURCE_SUCCESS,
    RESERVATION_TYPE_PROCESS_RESET
  } from "constants/ActionTypes"

  export const reservationTypePreview = (value) => {
    return {
      type: RESERVATION_TYPE_PREVIEW,
      payload:value
    }
  }

  export const reservationTypeCreate =(value) =>{
    return {
      type:RESERVATION_TYPE_NEW,
      payload:value
    }
  }

  export const reservationTypeUpdate = (value) =>{
    return {
      type:RESERVATION_TYPE_UPDATE,
      payload: value
    }
  }

  export const reservationTypeRemove = (value)=>{
    return {
      type:RESERVATION_TYPE_REMOVE,
      payload:value
    }
  }

  export const reservationTypeSearch = (keyword) =>{
    return {
      type: RESERVATION_TYPE_SEARCH,
      payload:keyword
    }
  }

  export const reservationTypeProcessSuccess = (value) =>{
    return{
      type:RESERVATION_TYPE_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const reservationTypeDataSourceUpdate = (value) => {
    return {
      type: RESERVATION_TYPE_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const reservationTypeProcessReset = () =>{
    return{
      type:RESERVATION_TYPE_PROCESS_RESET
    }
  }

