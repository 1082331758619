
export const RESERVATION_REQUEST = "RESERVATION_REQUEST"
export const RESERVATION_NEW = "RESERVATION_NEW"
export const RESERVATION_UPDATE = "RESERVATION_UPDATE"
export const RESERVATION_REMOVE = "RESERVATION_REMOVE"
export const RESERVATION_SEARCH = "RESERVATION_SEARCH"
export const RESERVATION_DATASOURCE_SUCCESS = "RESERVATION_DATASOURCE_SUCCESS"
export const RESERVATION_PROCESS_SUCCESS = "RESERVATION_PROCESS_SUCCESS"
export const RESERVATION_PROCESS_RESET = "RESERVATION_PROCESS_RESET"
export const RESERVATION_SET_FILTER = "RESERVATION_SET_FILTER"

export const RESERVATION_DETAIL_REQUEST = "RESERVATION_DETAIL_REQUEST"
export const RESERVATION_DETAIL_DATASOURCE_SUCCESS = "RESERVATION_DETAIL_DATASOURCE_SUCCESS"

export const RESERVATION_RATEPLAN_REQUEST = "RESERVATION_RATEPLAN_REQUEST"
export const RESERVATION_RATEPLAN_DATASOUCE_SUCCESS = "RESERVATION_RATEPLAN_DATASOUCE_SUCCESS"

export const RESERVATION_CHANGE_BOOKING_STATUS = "RESERVATION_CHANGE_BOOKING_STATUS"
export const RESERVATION_BOOKING_SUMMARY_REQUEST = "RESERVATION_BOOKING_SUMMARY_REQUEST"
export const RESERVATION_BOOKING_SUMMARY_SUCCESS = "RESERVATION_BOOKING_SUMMARY_SUCCESS"

export const RESERVATION_RESET = "RESERVATION_RESET"

export interface ReservationRequest {
  type: typeof RESERVATION_REQUEST,
  payload?:any,
  sorter?:any
}

export interface ReservationNew {
  type: typeof RESERVATION_NEW,
  payload?:any
}

export interface ReservationUpdate {
  type: typeof RESERVATION_UPDATE,
  payload?:any
}
export interface ReservationRemove {
  type: typeof RESERVATION_REMOVE,
  payload?:any
}

export interface ReservationSearch {
  type: typeof RESERVATION_SEARCH,
  payload?:any
}
export interface ReservationProcessSuccess {
  type: typeof RESERVATION_PROCESS_SUCCESS,
  payload:{}
}
export interface ReservationDataSourceSuccess{
  type: typeof RESERVATION_DATASOURCE_SUCCESS,
  payload:any
}
export interface ReservationProcessReset {
  type: typeof RESERVATION_PROCESS_RESET
}
export interface ReservationSetFilter{
  type: typeof RESERVATION_SET_FILTER,
  payload:any
}
export interface ReservationDetailRequest{
  type: typeof RESERVATION_DETAIL_REQUEST,
  payload:number
}
export interface ReservationDetailDataSourceSuccess{
  type: typeof RESERVATION_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface ReservationRatePlanRequest{
  type: typeof RESERVATION_RATEPLAN_REQUEST,
  payload:any
}
export interface ReservationRatePlanDataSourceSuccess{
  type: typeof RESERVATION_RATEPLAN_DATASOUCE_SUCCESS,
  payload:any
}

export interface ReservationChangeBookingStatus{
  type: typeof RESERVATION_CHANGE_BOOKING_STATUS,
  payload:any
}

export interface ReservationBookingSummaryRequest{
  type: typeof RESERVATION_BOOKING_SUMMARY_REQUEST,
  payload:any
}
export interface ReservationBookingSummarySuccess{
  type: typeof RESERVATION_BOOKING_SUMMARY_SUCCESS,
  payload:any
}

export interface ReservationReset{
  type: typeof RESERVATION_RESET
}




export type ReservationAction = 
| ReservationRequest
| ReservationNew
| ReservationUpdate
| ReservationRemove
| ReservationSearch
| ReservationProcessSuccess
| ReservationDataSourceSuccess
| ReservationProcessReset
| ReservationSetFilter
| ReservationDetailRequest
| ReservationDetailDataSourceSuccess
| ReservationRatePlanRequest
| ReservationRatePlanDataSourceSuccess
| ReservationChangeBookingStatus
| ReservationBookingSummaryRequest
| ReservationBookingSummarySuccess
| ReservationReset