import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  RESERVATION_TYPE_PREVIEW,
  RESERVATION_TYPE_NEW,
  RESERVATION_TYPE_UPDATE,
  RESERVATION_TYPE_REMOVE,
  RESERVATION_TYPE_SEARCH
} from "constants/ActionTypes"

import {reservationTypeDataSourceUpdate,reservationTypeProcessSuccess,reservationTypeProcessReset} from "appRedux/actions/setup/miscellaneous/ReservationType"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* reservationTypePreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/reservationType/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {     
            const reservationTypeDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.reservation_id = obj.id
                    container.reservation_name = obj.name
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
             yield put(reservationTypeDataSourceUpdate(reservationTypeDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreReservationTypeList",reservationTypeDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(reservationTypeProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on room group preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(reservationTypeProcessSuccess(processStatus))
    }
    
}

function* reservationTypeSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreReservationTypeList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return o.reservation_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered])]

      yield put(reservationTypeDataSourceUpdate(mergeData))
  }else 
      yield put(reservationTypeDataSourceUpdate(ReformalData))
}

function* reservationTypeCreateProcess({payload}){
  const reservation_name = payload.reservation_name
  const reservationTypeActive = payload.active
  let reservationTypeInfo = { name:reservation_name,
                              enabled:reservationTypeActive}

      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/reservationType/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = reservationTypeInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(reservationTypeProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(reservationTypeProcessSuccess(processStatus))
              yield put(reservationTypeProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(reservationTypeProcessSuccess(processStatus))
            yield put(reservationTypeProcessReset())
      }
}

function* reservationTypeUpdateProcess({payload}){
  const reservation_name = payload.reservation_name
  const reservation_id = payload.reservation_id
  const reservationTypeActive = payload.active

  let reservationTypeInfo = {}
      reservationTypeInfo.id = reservation_id
      reservationTypeInfo.name = reservation_name
      reservationTypeInfo.enabled = reservationTypeActive
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/reservationType/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = reservationTypeInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success"}
            yield put(reservationTypeProcessSuccess(processStatus))
            yield put(reservationTypeProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(reservationTypeProcessSuccess(processStatus))
          yield put(reservationTypeProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(reservationTypeProcessSuccess(processStatus))
        yield put(reservationTypeProcessReset())
  }
}

function* reservationTypeRemoveProcess({payload}){
    const reservationTypeRemove = payload
  
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/reservationType/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : reservationTypeRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(reservationTypeProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(reservationTypeProcessSuccess(processStatus))
          yield put(reservationTypeProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(reservationTypeProcessSuccess(processStatus))
      yield put(reservationTypeProcessReset())
    }
}

/* Watcher Saga */
export function* reservationTypePreview() {
  yield takeLatest(RESERVATION_TYPE_PREVIEW, reservationTypePreviewProcess)
}
export function* reservationTypeSearch(){
  yield takeLatest(RESERVATION_TYPE_SEARCH, reservationTypeSearchProcess)
}
export function* reservationTypeCreate(){
  yield takeEvery(RESERVATION_TYPE_NEW,reservationTypeCreateProcess)
}
export function* reservationTypeUpdate(){
  yield takeEvery(RESERVATION_TYPE_UPDATE, reservationTypeUpdateProcess)
}
export function* reservationTypeRemove(){
  yield takeEvery(RESERVATION_TYPE_REMOVE,reservationTypeRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(reservationTypePreview),
             fork(reservationTypeCreate),
             fork(reservationTypeUpdate),
             fork(reservationTypeRemove)])


}
