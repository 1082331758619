// eslint-disable-next-line
import React from 'react'
import {useDispatch, useSelector} from "react-redux"
import {Avatar, Popover} from "antd"
import {userSignOut} from "appRedux/actions/Auth"
import imageDefault from "assets/images/placeholder.jpg"
import {isEmpty} from "lodash"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faSignOutAlt, faKey, faCheckCircle,faCalendar,faMinus } from '@fortawesome/free-solid-svg-icons'
import moment from "moment"
import { AppState } from 'ReducerState'
import { useHistory } from 'react-router-dom'

const UserInfo = ()=>{
  //  const {auth:{authUser},systemConfig , currentLocation:{markLocations}} = useSelector((state:AppState) => state)

    const state = useSelector((state: AppState) => state);
  //  const authUser = state.auth.authUser;
    const AuthUser = state.auth
    const authUser =  (AuthUser as any).authUser ?? {};
    const systemConfig = state.systemConfig;
    const markLocations = state.currentLocation.markLocations;
    
    const profile_pic = authUser?.profile_pic ?? '';
    const user_name = authUser?.user_name
    const current_shift = authUser?.current_shift
    const login_time = authUser?.login_time

    const { dataSource:{SYSTEM} } = systemConfig
    const SYSTEM_DATE = SYSTEM?.SYSTEM_DATE||""
    const ONLINE_SHIFT = SYSTEM?.ONLINE_SHIFT||""
    const CURRENT_SHIFT = current_shift||""
    const imageProfile = isEmpty(profile_pic)?imageDefault:profile_pic
    const history = useHistory()

    const dispatch = useDispatch()

    const onSignOut = ()=>{
        if(markLocations.includes(history.location.pathname)) return
        dispatch(userSignOut())
    }

    const userProfile = ()=>{
      if(markLocations.includes(history.location.pathname)) return
      history.push("/main/userprofile")
    }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
          <FontAwesomeIcon icon={faUser} className="gx-mr-2" />
        Welcome : {user_name}
      </li>
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
          <FontAwesomeIcon icon={faMinus} className="gx-mr-2" />
        Current Shift : {CURRENT_SHIFT.toUpperCase()}
      </li>
      {SYSTEM?.ONLINE_POST && 
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
          <FontAwesomeIcon icon={faMinus} className="gx-mr-2" />
        Online Shift : {ONLINE_SHIFT.toUpperCase()!=="OFF"?ONLINE_SHIFT.toUpperCase():"Offline"}
      </li>
      }
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
          <FontAwesomeIcon icon={faCalendar} className="gx-mr-2" />
        System Date : {moment(SYSTEM_DATE).format("DD-MM-YYYY")}
      </li>
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
        <FontAwesomeIcon icon={faCheckCircle} className="gx-mr-2" />
        Login: {moment(login_time).format("DD/MM/YYYY HH:MM")}
      </li>
      {/*
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }}>
        <FontAwesomeIcon icon={faClock} className="gx-mr-2" />
        Elapse Time: 00:10:14
      </li>
      */}
      <li style={{ width: "230px", borderBottom: "1px solid #eee" }} onClick={()=> userProfile()}>
        <FontAwesomeIcon icon={faKey} className="gx-mr-2" />
        Setup Profile
      </li>
      <li onClick={() => onSignOut()}>
        <FontAwesomeIcon icon={faSignOutAlt} className="gx-mr-2" />
        Logout
      </li>
    </ul>
  )

    return(
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
        <span className="gx-pointer gx-flex-row gx-align-items-center">
        <Avatar src={imageProfile} className="gx-avatar gx-pointer gx-size-30" alt=""/>
          <i className="icon icon-chevron-down gx-pl-2" />
        </span>
      </Popover>
    )
}
export default UserInfo