import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  CHANNEL_PREVIEW,
  CHANNEL_NEW,
  CHANNEL_UPDATE,
  CHANNEL_REMOVE,
  CHANNEL_SEARCH,
  CHANNEL_DETAIL,
  CHANNEL_LOADLIST
} from "constants/ActionTypes"

import {channelDataSourceUpdate,
        channelProcessSuccess,
        channelDetailSuccess,
        channelLoadListSuccess,
        channelProcessReset} from "appRedux/actions/setup/channel/ChannelList"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined, filter, orderBy ,isEmpty } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* channelPreviewProcess({payload}){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/channel/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)
      if(response.status === 200)
      { 
            const channelDataSource = orderBy(response.data,[o=>o.channel_name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.channel_id = obj.id
                    container.channel_name = obj.channel_name
                    container.company_name = obj.company_name
                    container.channel_type_id = obj.channel_type_id
                    container.channel_type = obj.channel_type||""
                    container.origin_id = obj.origin_id
                    container.origin = obj.origin||"" //Check object is undefined set value is ""
                    container.nationality_id = obj.nationality_id
                    container.nationality = obj.nationality||"" //Check object is undefined set value is ""
                    container.country_id = obj.country_id
                    container.country = obj.country||"" //Check object is undefined set value is ""
                    container.folio_pattern_id = obj.folio_pattern_id
                    container.folio_pattern = obj.folio_pattern||""
                    container.sales_staff_id = obj.sales_staff_id
                    container.sales_staff = obj.sales_staff||""
                    container.channel_group_id = obj.channel_group_id
                    container.channel_group = obj.channel_group||""
                    container.payment_condition = obj.payment_condition
                    container.enabled = obj.enabled
                return container
            })
            /* if no error put everything to reducer */
            yield put(channelDataSourceUpdate(channelDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreChannelsList",channelDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(channelProcessSuccess(processStatus))
            yield put(channelProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
      }
      
    } catch (error) {
        console.log("Error on saga channel list : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(channelProcessSuccess(processStatus))
        yield put(channelProcessReset())
    }
    
}

function* channelSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreChannelsList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_channel = filter(ReformalData, function(o) {
        return (o.channel_id.toString().indexOf(keyword) > -1 ||
                o.channel_name.toLowerCase().indexOf(keyword) > -1 || 
                o.channel_type.toLowerCase().indexOf(keyword) > -1) ||
                o.origin.toLowerCase().indexOf(keyword) > -1 ||
                o.nationality.toLowerCase().indexOf(keyword) > -1 ||
                o.country.toLowerCase().indexOf(keyword) > -1 ||
                o.folio_pattern.toLowerCase().indexOf(keyword) > -1

      })
      const mergeData = [...new Set([...filtered_channel])]
      yield put(channelDataSourceUpdate(mergeData))
  }else 
      yield put(channelDataSourceUpdate(ReformalData))
}

function* channelCreateProcess({payload}){

  let channelInfo = {}
      channelInfo.name = payload.channel_name
      channelInfo.contact_name = payload.contact_name||null
      channelInfo.contact_addr1 = payload.address_1||null
      channelInfo.contact_addr2 = payload.address_2||null
      channelInfo.contact_country_id = payload.country_id||null
      channelInfo.contact_telephone = payload.contact_telephone||null
      channelInfo.contact_mobile = payload.contact_mobile||null
      channelInfo.contact_fax = payload.contact_fax||null
      channelInfo.contact_email = payload.contact_email||null
      channelInfo.company_name = payload.company_name||null
      channelInfo.branch_no = payload.branch_no||null
      channelInfo.tax_id = payload.tax_id||null
      channelInfo.payment_term = payload.payment_term||null
      channelInfo.credit_term = parseInt(payload.credit_term)||null
      channelInfo.credit_limit = parseFloat(payload.credit_limit)||null
      channelInfo.type_id = payload.channel_type_id||null
      channelInfo.origin_id = payload.origin_id||null
      channelInfo.folpat_id = payload.folio_pattern_id||null
      channelInfo.nation_id = payload.nationality_id||null
      channelInfo.sales_id = payload.sales_staff_id||null
      channelInfo.payment_condition = payload.payment_condition
      channelInfo.remark = payload.remark||null
      channelInfo.otacode = payload.ota_code||null
      channelInfo.enabled = payload.active
      channelInfo.channel_market = []
      if(!isEmpty(payload.market_id)){
        payload.market_id.map((val)=>{
            const objData = {market_id:val}
            channelInfo.channel_market.push(objData)
            return true
        })
      }

      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/channel/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = channelInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(channelProcessSuccess(processStatus))
                yield put(channelProcessReset())
            }else{   
                const {message,data} = response.data
                const processStatus = { status: false, action: "create", message }
                yield put(channelProcessSuccess(processStatus))
                yield put(channelProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(channelProcessSuccess(processStatus))
            yield put(channelProcessReset())
      }
}

function* channelUpdateProcess({payload}){

  let channelInfo = {}
      channelInfo.id = payload.channel_id
      channelInfo.name = payload.channel_name
      channelInfo.contact_name = payload.contact_name||null
      channelInfo.contact_addr1 = payload.address_1||null
      channelInfo.contact_addr2 = payload.address_2
      channelInfo.contact_country_id = payload.country_id||null
      channelInfo.contact_telephone = payload.contact_telephone||null
      channelInfo.contact_mobile = payload.contact_mobile||null
      channelInfo.contact_fax = payload.contact_fax||null
      channelInfo.contact_email = payload.contact_email||null
      channelInfo.company_name = payload.company_name||null
      channelInfo.branch_no = payload.branch_no||null
      channelInfo.tax_id = payload.tax_id||null
      channelInfo.payment_term = payload.payment_term||null
      channelInfo.credit_term = parseInt(payload.credit_term)||null
      channelInfo.credit_limit = parseFloat(payload.credit_limit)||null
      channelInfo.type_id = payload.channel_type_id||null
      channelInfo.origin_id = payload.origin_id||null //Set null if update value is non selected
      channelInfo.folpat_id = payload.folio_pattern_id
      channelInfo.nation_id = payload.nationality_id||null
      channelInfo.sales_id = payload.sales_staff_id||null
      channelInfo.payment_condition = payload.payment_condition||null
      channelInfo.remark = payload.remark||null
      channelInfo.otacode = payload.ota_code||null
      channelInfo.enabled = payload.active
      channelInfo.channel_market = []
      if(!isEmpty(payload.market_id)){
        payload.market_id.map((val)=>{
            const objData = {market_id:val}
            channelInfo.channel_market.push(objData)
            return true
        })
      }

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channel/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = channelInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(channelProcessSuccess(processStatus))
            yield put(channelProcessReset())
        }else{     
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(channelProcessSuccess(processStatus))
            yield put(channelProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(channelProcessSuccess(processStatus))
        yield put(channelProcessReset())
  }
}

function* channelRemoveProcess({payload}){
    const channelRemove = payload
    

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channel/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : channelRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(channelProcessSuccess(processStatus))
          yield put(channelProcessReset())
        }else{      
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(channelProcessSuccess(processStatus))
          yield put(channelProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(channelProcessSuccess(processStatus))
      yield put(channelProcessReset())
    }
}

function* channelDetailProcess({payload}){
  const channel_id = payload

  try{
      const  { access_token }  = yield call(getItems, ['access_token'])
      const AccessToken = access_token
      const url = `${API_URL_V1}pms/masterfile/channel/detail/${channel_id}/`
      API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

      const response = yield APIGetRequest(url,API_PMS_HEADERS)
      if(response.status === 200){
        const obj = response.data
        let detailData = {}
            detailData.channel_name = obj.channel_name 
            detailData.channel_id = channel_id
            detailData.company_name = obj.company_name
            detailData.contact_name = obj.contact_name
            detailData.contact_addr1 = obj.contact_addr1
            detailData.contact_addr2 = obj.contact_addr2
            detailData.branch_no = obj.branch_no||undefined
            detailData.coallot_channel = obj.coallot_channel
            detailData.channel_type_id = obj.channel_type_id
            detailData.contact_email = obj.contact_email
            detailData.contact_fax = obj.contact_fax
            detailData.contact_telephone = obj.contact_telephone
            detailData.contact_mobile = obj.contact_mobile
            detailData.country_id = obj.country_id
            detailData.channel_group_id = obj.channel_group_id
            detailData.folio_pattern_id = obj.folio_pattern_id
            detailData.nationality_id = obj.nationality_id
            detailData.origin_id = obj.origin_id
            detailData.otacode = obj.otacode
            detailData.payment_condition = obj.payment_condition
            detailData.payment_term = obj.payment_term
            detailData.remark = obj.remark
            detailData.credit_limit = obj.credit_limit||undefined
            detailData.credit_term = obj.credit_term||undefined
            detailData.sales_staff_id = obj.sales_staff_id
            detailData.tax_id = obj.tax_id
            detailData.channel_market = []
            if(!isEmpty(obj.channel_market)){
                obj.channel_market.map((val)=>{
                  detailData.channel_market.push(val.id)
                  return true
              })
            }
            detailData.enabled=obj.enabled 
        yield put(channelDetailSuccess(detailData))
      }else{ 
        const {message,data} = response.data
        const processStatus = { status: false, action: "load-detail", message }
        yield put(channelProcessSuccess(processStatus))
        yield put(channelProcessReset())
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
      }
  }
  catch(error){
    console.log("Error : ",error)
    const processStatus = { status: false, action: "load-detail", message:error }
    yield put(channelProcessSuccess(processStatus))
    yield put(channelProcessReset())
  }
}

function * channelLoadListProcess({payload}){
    const action = payload
    try{

      /* Coallot Channel */
      const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreChannelsList')
      const SessionChannelDataSource = [...storeValue[0]]
      const coallotFilter = filter(SessionChannelDataSource, function(o) {
        const formatData = {}
              formatData.channel_id = o.channel_id
              formatData.channel_name = o.channel_name
        return formatData
      })

      let coallotDataSource = {}
      if(action === "new")
        coallotDataSource = coallotFilter
      else{
        coallotDataSource = filter(coallotFilter,function(o){
          return o.channel_id !== action
        })
      }

      const temp = {}
            temp.coallotList = coallotDataSource

      yield put(channelLoadListSuccess(temp))
    }catch(error){
        console.log("Coallot error ",error)
    }
}

/* Watcher Saga */
export function* channelPreview() {
  yield takeLatest(CHANNEL_PREVIEW, channelPreviewProcess)
}
export function* channelSearch(){
  yield takeLatest(CHANNEL_SEARCH, channelSearchProcess)
}
export function* channelCreate(){
  yield takeEvery(CHANNEL_NEW,channelCreateProcess)
}
export function* channelUpdate(){
  yield takeEvery(CHANNEL_UPDATE, channelUpdateProcess)
}
export function* channelRemove(){
  yield takeEvery(CHANNEL_REMOVE,channelRemoveProcess)
}
export function* channelDetail(){
  yield takeEvery(CHANNEL_DETAIL,channelDetailProcess)
}
export function* channelLoadList(){
  yield takeEvery(CHANNEL_LOADLIST,channelLoadListProcess)
}

export default function* rootSaga() {
  yield all([fork(channelPreview),
             fork(channelSearch),
             fork(channelCreate),
             fork(channelUpdate),
             fork(channelRemove),
             fork(channelDetail),
             fork(channelLoadList)])


}
