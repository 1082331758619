import {
    SettingsAction,
    SettingsState, 
    SWITCH_LANGUAGE 
} from "constants/ActionTypes"
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
  SET_PATH_NAME
} from "../../constants/ThemeSetting"

const initialSettings: SettingsState = {
  navStyle: NAV_STYLE_FIXED,
  layoutType: LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: THEME_COLOR,
  pathname: '/',
  
  isDirectionRTL: false,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
}

export default (state: SettingsState = initialSettings, action: SettingsAction): SettingsState => {
    // console.log('setting-reducer', action)
    switch (action.type) {
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType
      }
    case THEME_COLOR:
      console.log("yes",action.themeColor)
      return {
        ...state,
        themeColor: action.themeColor
      }

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      }
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType
      }
    case SET_PATH_NAME:
      return {
        ...state,
        pathname: action.payload
      }
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,

      }
    default:
      return state
  }
}