import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/cashier/chargeScheduleActionType"
import * as actionCreators from "appRedux/actions/cashier/chargeSchedule"
import { userSignOut} from "appRedux/actions/Auth"
import { APIGetRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* chargeScheduleRequestProcess({ payload }: actionTypes.ChargeScheduleRequest) {

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/cashier/folio/${payload}/chargeSchedule/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const postingJournalInfo = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.chargeScheduleDataSourceSuccess(postingJournalInfo))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(actionCreators.chargeScheduleProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on posting journal : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.chargeScheduleProcessSuccess(processStatus))
    }
}

function* PostingRequest() {
  yield takeEvery(actionTypes.CHARGE_SCHEDULE_REQUEST, chargeScheduleRequestProcess)
}
export default function* rootSaga() {
  yield all([fork(PostingRequest)])
}


