import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  MESSAGE_TO_GUEST_PREVIEW,
  MESSAGE_TO_GUEST_NEW,
  MESSAGE_TO_GUEST_UPDATE,
  MESSAGE_TO_GUEST_REMOVE
} from "constants/ActionTypes"

import {messageToGuestDataSourceUpdate,messageToGuestProcessSuccess} from "appRedux/actions/MessageToGuest"
import {userSignOut,showAuthMessage} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest} from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { orderBy } from "lodash"
import moment from 'moment'
import { getItems } from "util/localStorage"

function* messageToGuestPreviewProcess({payload}){
    const { id,reservationType } = payload
    const url = `${API_URL_V1}pms/utility/guestMessages/${reservationType}/${id}/`
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      { 
            const messageDataSource = orderBy(response.data,[o => o.id],['desc']).map((obj,key) =>{
              const container = {}
                    container.key = `message-${key}`
                    container.messageID = obj.id
                    container.message = obj.message
                    container.messageTime = moment(obj.message_time).format("DD/MM/YYYY HH:mm")
                    container.messageFrom = obj.message_from
                    container.entryBy = obj.entry_by
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(messageToGuestDataSourceUpdate(messageDataSource))

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            yield put(showAuthMessage(message))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on message to guest preview : ",error)
        yield put(showAuthMessage(error))
    }
    
}

function* messageToGuestCreateProcess({payload}){
  
  const {id,reservationType, message,message_time, entry_by,message_from } = payload

  const messageInfo = {}
        messageInfo[reservationType] = id
        messageInfo.message = message
        messageInfo.message_time = message_time
        messageInfo.message_from = message_from
        messageInfo.entry_by =  entry_by

      try {
            const url = `${API_URL_V1}pms/utility/guestMessages/create/`
            const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = messageInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message: "Create Success" }  
              yield put(messageToGuestProcessSuccess(processStatus))
                
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }  
              yield put(messageToGuestProcessSuccess(processStatus))
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }  
            yield put(messageToGuestProcessSuccess(processStatus))
      }
}

function* messageToGuestUpdateProcess({payload}){

  const { message_id,id,reservationType, message,message_time, entry_by,message_from } = payload
  const messageInfo = {}
        messageInfo.id = message_id
        messageInfo[reservationType] = id
        messageInfo.message = message
        messageInfo.message_time = message_time
        messageInfo.message_from = message_from
        messageInfo.entry_by =  entry_by
  try {
        const url = `${API_URL_V1}pms/utility/guestMessages/update/`
        const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = messageInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message: "Update Success" }  
            yield put(messageToGuestProcessSuccess(processStatus))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(messageToGuestProcessSuccess(processStatus))
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(messageToGuestProcessSuccess(processStatus))
  }
}

function* messageToGuestRemoveProcess({payload}){
    const messageRemoveID = payload

    try{
        const url = `${API_URL_V1}pms/utility/guestMessages/delete/`
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : messageRemoveID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "remove", message: "Remove Success" } 
          yield put(messageToGuestProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "remove", message } 
          yield put(messageToGuestProcessSuccess(processStatus))
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "remove", message:error } 
      yield put(messageToGuestProcessSuccess(processStatus))
    }
}

/* Watcher Saga */
export function* messageToGuestPreview() {
  yield takeLatest(MESSAGE_TO_GUEST_PREVIEW, messageToGuestPreviewProcess)
}

export function* messageToGuestCreate(){
  yield takeEvery(MESSAGE_TO_GUEST_NEW,messageToGuestCreateProcess)
}
export function* messageToGuestUpdate(){
  yield takeEvery(MESSAGE_TO_GUEST_UPDATE, messageToGuestUpdateProcess)
}
export function* messageToGuestRemove(){
  yield takeEvery(MESSAGE_TO_GUEST_REMOVE,messageToGuestRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(messageToGuestPreview),
             fork(messageToGuestCreate),
             fork(messageToGuestUpdate),
             fork(messageToGuestRemove)])
}
