// // eslint-disable-next-line
// import React, { useState, useRef, useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Button, message } from "antd";
// import { Link } from "react-router-dom";
// import { Formik, FormikProps } from "formik";
// import { Form, Input } from "formik-antd";
// import * as yup from "yup";

// import { footerText } from "util/config";
// import { AppState } from "ReducerState";
// //import IntlMessages from "util/IntlMessages"

// import {
//   hideMessage,
//   userVerify,
//   resetProcessStatus,
//   requestResetUser,
//   requestResetUserSuccess,
//   invalidRequestResetUser,
// } from "appRedux/actions/Auth";
// import styled from "styled-components";
// import Column from "antd/lib/table/Column";
// import { SEND_EMAIL_COMPLETE } from "constants/ConstantsVariable";

// interface activateType {
//   user?: string;
// }

// export const DivBlockWarning = styled.div`
//   font-size: 12px;
//   font-style: italic;
//   color: red;
//   font-weight: lighter;
// `;

// export const LabelStyle = styled.label`
//   font-size: 20px;
//   color: #262626;
//   font-weight: 400;
//   margin-left: 0 !important;
 
// `;
// export const LabelWarning = styled.label`
//   font-size: 12px !important;
//   color: #262626;
//   font-weight: 400;
//   margin-left: 0 !important;
//   color:red;
//   font-style: italic;
// `;

// const RequestResetUser = ({ match, history }: any) => {
//   const [isEmailSent, setIsEmailSent] = useState(false);
//   const [showLabel, setShowLabel] = useState(false);
//   const [warning, setWarning] = useState("");
//   const action = match.params.action;
//   const headerTitle = action === "activate" ? "[ User account activate ]" : "[ Forgot password ]";

//   const [initialData] = useState<activateType>({
//     user: "",
//   });
//   // const { auth } = useSelector((state: AppState) => state);

//   const { auth } = useSelector((state: AppState) => {
//   //  console.log("13. Redux state:", state.auth);
//     return state;
//   });

//   const { showMessage, loader, alertMessage, processStatus, process } = auth;

//   const mounted = useRef<any>(undefined);
//   const dispatch = useDispatch();

//   useEffect(() => {
//    // console.log("ข้อมูล response processStatus data จาก saga:", processStatus);
//    // console.log("ข้อมูล response process data จาก saga:", process);

//     if ((process as any)?.action === "requestResetUser") {
//       setShowLabel(true);
//       // แสดงทั้ง res_code และ res_msg

//       const resMsg = (process as any)?.message;
//     //  console.log("message data จาก saga:", resMsg);
//       //  setResMessage(resMsg);
//     //  console.log("((process as any)?.message:", ((process as any)?.message));
//       if
//         ((process as any)?.message === 'OK') {
//         setShowLabel(false);
//         setIsEmailSent(true);
//       } else {
//         setShowLabel(true);
//         setWarning(resMsg);
//         setIsEmailSent(false);
//       }
//     } else {
//       setShowLabel(true);
//       setWarning(alertMessage as string);
//       setIsEmailSent(false);

//       setTimeout(() => {
//         dispatch(hideMessage());
//       }, 3000);
//     }
    
//     // } else if (showMessage && alertMessage) {
//     //   setShowLabel(true);
//     //   setWarning(alertMessage as string);

//     //   setTimeout(() => {
//     //     dispatch(hideMessage());
//     //   }, 3000);
//     // }
//   }, [processStatus, showMessage, alertMessage, dispatch, history, process]);

//   const handleSubmit = (values: any) => {
//   //  console.log("1. handleSubmit triggered");

//     let userInfo = {
//       action_type: "activate-user",
//       user_name: values.user,
//       email: '',
//     };

//     if (action != 'activate') {
//       userInfo = {
//         action_type: "reset-password",
//         user_name: '',
//         email: values.user,
//       };
//     }

//     // console.log(`action_type : ${values.action_type}`);
//    // console.log("2. userInfo:", userInfo);

//     dispatch(requestResetUser(userInfo));

//    // console.log("2.1 (process as any).status:", (process as any).status);

//     // setIsEmailSent(true);

//     //console.log("3. After dispatch");

//     if ((process as any).status == true) {
//       setIsEmailSent(true);

//       dispatch(requestResetUserSuccess(process));

//     } else { 
//       setIsEmailSent(false); 
    
//       dispatch(invalidRequestResetUser(process));
//     }
//   };

//   let schema = yup.object({
//     user: yup
//       .string()
//       .required("Please Input user name or email")
//       .matches(RegExp("^([a-zA-Z0-9_.-])+@([a-zA-Z0-9-]+)")),
//   });

//   return (
//     <div className="gx-login-container">
//       <div className="gx-login-content">
//         <div className="gx-login-header">
//           <Link to="/">
//             <img
//               src={require("assets/images/logo-white.png")}
//               alt="fromas"
//               title="fromas"
//             />
//           </Link>
//         </div>
//         <Formik
//           enableReinitialize
//           initialValues={initialData}
//           validationSchema={schema}
//           onSubmit={handleSubmit}
//         >
//           {({ errors, touched, isValid, dirty }: any) => (
//             <Form className="gx-login-form gx-form-row0" layout="vertical">
//               {isEmailSent ? (
//                 <div style={{ textAlign: 'center', margin: '20px 0' }}>
//                   {SEND_EMAIL_COMPLETE}
//                 </div>
//               ) : (
//                 <div>
//                   <div style={{
//                     marginBottom: 20,
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "flex-start",
//                   }}>
//                     <div style={{
//                       alignItems: "center",
//                       width: "100%",
//                       borderWidth: "0px",
//                       borderStyle: "solid",
//                       borderColor: "#000000",
//                       display: "flex",
//                       justifyContent: "center",
//                     }}>
//                       <LabelStyle>{headerTitle}</LabelStyle>
//                     </div>
//                     <Input
//                       name="user"
//                       type="text"
//                       placeholder="Please input your User name or Email."
//                       style={{ marginTop: 10, width: "100%" }}
//                       onKeyDown={(e) => {
//                         setShowLabel(false);
//                       }}
//                     />
//                     {showLabel && <LabelWarning>{warning}</LabelWarning>}
//                   </div>
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     loading={loader}
//                   >
//                     Submit
//                   </Button>
//                   <Button type="default" onClick={() => history.goBack()}>
//                     Back
//                   </Button>
//                 </div>
//               )}

//             </Form>
//           )}
//         </Formik>
//         <span className="gx-text-light gx-fs-sm" style={{ whiteSpace: 'nowrap' }}>{footerText.copyright}</span>
//       </div>
//     </div>
//   );
// };
// export default RequestResetUser;

// eslint-disable-next-line


// eslint-disable-next-line
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, message } from "antd";
import { Link } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { Form, Input } from "formik-antd";
import * as yup from "yup";
import styled, { createGlobalStyle } from "styled-components";
import Column from "antd/lib/table/Column";

import { footerText } from "util/config";
import { AppState } from "ReducerState";
import { SEND_EMAIL_COMPLETE } from "constants/ConstantsVariable";
import {
 hideMessage,
 userVerify,
 resetProcessStatus,
 requestResetUser,
 requestResetUserSuccess,
 invalidRequestResetUser,
} from "appRedux/actions/Auth";

// Styled Components
const LoginContainer = styled.div`
 min-height: 100vh;

 padding: 2rem;
 display: flex;
 justify-content: center;
 align-items: center;
`;

const LoginContent = styled.div`
 background: white;
 padding: 2.5rem;
 border-radius: 8px;
 box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
 width: 100%;
 max-width: 420px;
`;

const LoginHeader = styled.div`
 text-align: center;
 margin-bottom: 2rem;
 
 img {
   max-width: 180px;
   height: auto;
 }
`;

const LabelStyle = styled.label`
 font-size: 24px;
 color: #262626;
 font-weight: 500;
 margin-bottom: 1.5rem;
 text-align: center;
 width: 100%;
 display: block;
`;

const LabelWarning = styled.label`
 font-size: 13px;
 color: #ff4d4f;
 font-style: italic;
 margin-top: 0.5rem;
 display: block;
`;

const ButtonContainer = styled.div`
 display: flex;
 gap: 1rem;
 margin-top: 1.5rem;
 justify-content: center;
`;

const StyledInput = styled(Input)`
 height: 40px;
 font-size: 14px;
 border-radius: 4px;
 
 &:focus {
   box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
 }
 
 &::placeholder {
   color: #bfbfbf;
 }
`;

const SubmitButton = styled(Button)`
 height: 40px;
 font-size: 14px;
 font-weight: 500;
 width: 120px;
 
 &.ant-btn-primary {
   background: #1890ff;
   
   &:hover {
     background: #40a9ff;
   }
 }
`;

const BackButton = styled(Button)`
 height: 40px;
 font-size: 14px;
 font-weight: 500;
 width: 120px;
`;

const FooterText = styled.div`
 text-align: center;
 margin: 2rem auto 0;
 font-size: 12px;
 color: #8c8c8c;
 padding: 0px;
 width: fit-content;
 white-space: nowrap;  
`;

const GlobalStyle = createGlobalStyle`
 .success-message {
   text-align: center;
   padding: 2rem;
   font-size: 16px;
   color: #1890ff;         
   background: #e6f7ff;      
   border: 1px solid #91d5ff; 
   border-radius: 4px;
   margin-bottom: 1.5rem;
 }
`;

interface ActivateType {
 user?: string;
}

const RequestResetUser = ({ match, history }: any) => {
 const [isEmailSent, setIsEmailSent] = useState(false);
 const [showLabel, setShowLabel] = useState(false);
 const [warning, setWarning] = useState("");
 const action = match.params.action;
 const headerTitle = action === "activate" ? "[ User account activate ]" : "[ Forgot password ]";
 const [initialData] = useState<ActivateType>({ user: "" });
 
 const { auth } = useSelector((state: AppState) => state);
 const { showMessage, loader, alertMessage, processStatus, process } = auth;
 const mounted = useRef<any>(undefined);
 const dispatch = useDispatch();

 useEffect(() => {
   if ((process as any)?.action === "requestResetUser") {
     setShowLabel(true);
     const resMsg = (process as any)?.message;
     
     if ((process as any)?.message === 'OK') {
       setShowLabel(false);
       setIsEmailSent(true);
     } else {
       setShowLabel(true);
       setWarning(resMsg);
       setIsEmailSent(false);
     }
   } else {
     setShowLabel(true);
     setWarning(alertMessage as string);
     setIsEmailSent(false);

     setTimeout(() => {
       dispatch(hideMessage());
     }, 3000);
   }
 }, [processStatus, showMessage, alertMessage, dispatch, history, process]);

 const handleSubmit = (values: any) => {
   let userInfo = {
     action_type: "activate-user",
     user_name: values.user,
     email: '',
   };

   if (action !== 'activate') {
     userInfo = {
       action_type: "reset-password",
       user_name: '',
       email: values.user,
     };
   }

   dispatch(requestResetUser(userInfo));

   if ((process as any).status === true) {
     setIsEmailSent(true);
     dispatch(requestResetUserSuccess(process));
   } else {
     setIsEmailSent(false);
     dispatch(invalidRequestResetUser(process));
   }
 };

 const schema = yup.object({
   user: yup
     .string()
     .required("Please Input user name or email")
     .matches(RegExp("^([a-zA-Z0-9_.-])+@([a-zA-Z0-9-]+)")),
 });

 return (
   <LoginContainer>
     <GlobalStyle />
     <LoginContent>
       <LoginHeader>
         <Link to="/">
           <img
             src={require("assets/images/logo-white.png")}
             alt="fromas"
             title="fromas"
           />
         </Link>
       </LoginHeader>
       
       <Formik
         enableReinitialize
         initialValues={initialData}
         validationSchema={schema}
         onSubmit={handleSubmit}
       >
         {({ errors, touched, isValid, dirty }: any) => (
           <Form layout="vertical">
             {isEmailSent ? (
               <div className="success-message">
                 {SEND_EMAIL_COMPLETE}
               </div>
             ) : (
               <div>
                 <LabelStyle>{headerTitle}</LabelStyle>
                 
                 <StyledInput
                   name="user"
                   type="text"
                   placeholder="Please input your User name or Email."
                   onKeyDown={() => setShowLabel(false)}
                 />
                 
                 {showLabel && <LabelWarning>{warning}</LabelWarning>}
                 
                 <ButtonContainer>
                   <SubmitButton
                     type="primary"
                     htmlType="submit"
                     loading={loader}
                   >
                     Submit
                   </SubmitButton>
                   
                   <BackButton 
                     onClick={() => history.goBack()}
                   >
                     Back
                   </BackButton>
                 </ButtonContainer>
               </div>
             )}
           </Form>
         )}
       </Formik>
       
       <FooterText>
         {footerText.copyright}
       </FooterText>
     </LoginContent>
   </LoginContainer>
 );
};

export default RequestResetUser;
