import * as actions from "./GuestIssueCardActionType"

export const guestIssueCardPreview = (payload?: any): actions.GuestIssueCardRequest => {
  return {
    type: actions.GUEST_ISSUE_CARD_REQUEST,
    payload
  }
}
export const guestIssueCardDatasouceSuccess = (payload:any):actions.GuestIssueCardDataSourceSuccess=>{
  return{
    type: actions.GUEST_ISSUE_CARD_DATASOURCE_SUCCESS,
    payload
  }
}

export const guestIssueCardProcessSuccess = (payload:any):actions.GuestIssueCardProcessSuccess =>{
  return{
    type: actions.GUEST_ISSUE_CARD_PROCESS_SUCCESS,
    payload
  }
}

export const guestIssueCardProcessReset = ():actions.GuestIssueCardProcessReset =>{
  return{
    type: actions.GUEST_ISSUE_CARD_PROCESS_RESET
  } 
}

export const guestIssueCardReset = ():actions.GuestIssueCardReset =>{
  return{
    type: actions.GUEST_ISSUE_CARD_RESET
  } 
}

export const guestIssueCardNewCard = (payload:any):actions.GuestIssueCardNewCard =>{
  return{
    type: actions.GUEST_ISSUE_CARD_NEW_CARD,
    payload
  }
}
export const guestIssueCardCopyCard = (payload:any):actions.GuestIssueCardCopyCard =>{
  return{
    type: actions.GUEST_ISSUE_CARD_COPY_CARD,
    payload
  }
}

export const guestIssueCardSetNextCard  = (payload:any):actions.GuestIssueCardSetNextCard =>{
  return{
    type: actions.GUEST_ISSUE_CARD_SET_NEXT_CARD,
    payload
  }
}

export const guestIssueCardSetNextCardReset = ():actions.GuestIssueCardSetNextCardReset =>{
  return{
    type: actions.GUEST_ISSUE_CARD_SET_NEXT_CARD_RESET
  }
}