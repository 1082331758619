import * as actions from "appRedux/actions/cashier/transferActionType"

interface transferType {
    transactions: {
        folio1: any[];
        folio2: any[];
        folio3: any[];
        folio4: any[];
    };
    folio1: any[];
    folio2: any[];
    folio3: any[];
    folio4: any[];
}

export interface transferState {
    loader: boolean,
    keyword: string,
    dataSourceFirst: any, /* Folio detail left */
    dataSourceSecond: any, /* Folio detail right */
    dataSourceAdvance: [], /* Guest folio from search */
    paging: any,
    dataTarget: { source?: number, target?: number },
    dataTransfer: transferType, /* Data Transfer from first to second or second to first */
    transferFirstToSecond: any, /* Store data transfer first to second before send to api after save button */
    transferSecondToFirst: any, /* Store data transfer second to first before send to api after save button */
    transferButtonFirst: boolean, /* toggle button if transfer from second to first reset after save */
    transferButtonSecond: boolean, /* toggle button if transfer from first to second reset after save */
    folioFirstSelected: number, /* Keep folio-seq first selected */
    folioSecondSelected: number, /* Keep folio-seq second selected */
    process: any
}

const initialState: transferState = {
    loader: false,
    keyword: '',
    dataSourceFirst: undefined,
    dataSourceSecond: undefined,
    dataSourceAdvance: [],
    paging: { current: 1, limit: 15, total_row: undefined },
    dataTarget: { source: undefined, target: undefined },
    dataTransfer: {
        transactions: {
            folio1: [],
            folio2: [],
            folio3: [],
            folio4: []
        },
        folio1: [],
        folio2: [],
        folio3: [],
        folio4: []
    },
    transferFirstToSecond: undefined,
    transferSecondToFirst: undefined,
    transferButtonFirst: false,
    transferButtonSecond: false,
    folioFirstSelected: 1,
    folioSecondSelected: 0,
    process: { status: false, action: "", message: "" }
}

export default (state: transferState = initialState, action: actions.TransferAction): transferState => {
    switch (action.type) {
        case actions.TRANSFER_FOLIO_FIRST_REQUEST:
            return {
                ...state,
                loader: true,
                dataTarget: { source: undefined, target: undefined },
                dataTransfer: {
                    transactions: {
                        folio1: [],
                        folio2: [],
                        folio3: [],
                        folio4: []
                    },
                    folio1: [],
                    folio2: [],
                    folio3: [],
                    folio4: []
                },
                dataSourceSecond: undefined,
                transferButtonFirst: true,
                transferButtonSecond: false,
                process: { status: false, action: "", message: "" }
            }
        case actions.TRANSFER_FOLIO_PROCESS_SUCCESS: {
            return {
                ...state,
                transferButtonFirst: true,
                transferButtonSecond: true,
                process: action.payload
            }
        }
        case actions.TRANSFER_FOLIO_FIRST_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataSourceFirst: action.payload
            }
        }
        case actions.TRANSFER_FOLIO_SECOND_REQUEST: {
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.TRANSFER_FOLIO_SECOND_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                folioSecondSelected: 1,
                transferButtonSecond: true,
                dataSourceSecond: action.payload,
                dataSourceAdvance: []
            }
        }
        case actions.TRANSFER_FOLIO_ADVANCE_REQUEST: {
            return {
                ...state,
                loader: true,
                dataSourceAdvance: [],
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.TRANSFER_FOLIO_ADVANCE_DATASOURCE_SUCCESS: {
            const { results, paging } = action.payload
            return {
                ...state,
                loader: false,
                dataSourceAdvance: results,
                paging
            }
        }
        case actions.TRANSFER_SET_TARGET_FIRST_FOLIO: {
            return {
                ...state,
                folioFirstSelected: action.payload
            }
        }
        case actions.TRANSFER_SET_TARGET_SECOND_FOLIO: {
            return {
                ...state,
                folioSecondSelected: action.payload
            }
        }
        case actions.TRANSFER_DATA_FIRST_TO_SECOND: {
            const { target, transfer } = action.payload;
            const transactionID = transfer.map((obj: any) => obj.id);

            let updatedTransactions = { ...state.dataTransfer.transactions };

            switch (state.folioSecondSelected) { // ใช้ folio ที่เลือกอยู่
                case 1:
                    updatedTransactions.folio1 = [...updatedTransactions.folio1, ...transactionID];
                    break;
                case 2:
                    updatedTransactions.folio2 = [...updatedTransactions.folio2, ...transactionID];
                    break;
                case 3:
                    updatedTransactions.folio3 = [...updatedTransactions.folio3, ...transactionID];
                    break;
                case 4:
                    updatedTransactions.folio4 = [...updatedTransactions.folio4, ...transactionID];
                    break;
            }

            return {
                ...state,
                dataTransfer: { 
                    transactions: updatedTransactions,
                    folio1: updatedTransactions.folio1,
                    folio2: updatedTransactions.folio2,
                    folio3: updatedTransactions.folio3,
                    folio4: updatedTransactions.folio4
                },
                transferFirstToSecond: action.payload,
                transferButtonSecond: false,
                dataTarget: {
                    source: state.dataSourceFirst?.folio_id || null,
                    target: state.dataSourceSecond?.folio_id || null
                }
            };
        }
        case actions.TRANSFER_DATA_SECOND_TO_FIRST: {
            const { target, transfer } = action.payload;
            const transactionID = transfer.map((obj: any) => obj.id);

            let updatedTransactions = { ...state.dataTransfer.transactions };

            switch (state.folioFirstSelected) { // ใช้ folio ที่เลือกอยู่
                case 1:
                    updatedTransactions.folio1 = [...updatedTransactions.folio1, ...transactionID];
                    break;
                case 2:
                    updatedTransactions.folio2 = [...updatedTransactions.folio2, ...transactionID];
                    break;
                case 3:
                    updatedTransactions.folio3 = [...updatedTransactions.folio3, ...transactionID];
                    break;
                case 4:
                    updatedTransactions.folio4 = [...updatedTransactions.folio4, ...transactionID];
                    break;
            }

            return {
                ...state,
                dataTransfer: { 
                    transactions: updatedTransactions,
                    folio1: updatedTransactions.folio1,
                    folio2: updatedTransactions.folio2,
                    folio3: updatedTransactions.folio3,
                    folio4: updatedTransactions.folio4
                },
                transferSecondToFirst: action.payload,
                transferButtonFirst: false,
                dataTarget: {
                    source: state.dataSourceSecond?.folio_id || null,
                    target: state.dataSourceFirst?.folio_id || null
                }
            };
        }
        case actions.TRANSFER_FOLIO_PROCESS_RESET: {
            return {
                ...state,
                transferFirstToSecond: undefined,
                transferSecondToFirst: undefined,
                dataTransfer: {
                    transactions: {
                        folio1: [],
                        folio2: [],
                        folio3: [],
                        folio4: []
                    },
                    folio1: [],
                    folio2: [],
                    folio3: [],
                    folio4: []
                },
                paging: { current: 1, limit: 15, totoal_row: 15 },
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state;
    }
}