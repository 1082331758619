import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  CHANGE_PERSONAL_INFO,
  CHANGE_CONTACT_INFO,
  CHANGE_PASSWORD,
  USER_PROFILE_PREVIEW,
  CHANGE_IMAGE_PROFILE
} from "constants/ActionTypes"

import {changeProcessSuccess,userProfileProcessSuccess} from "appRedux/actions/UserProfile"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_AUTH,API_HEADERS } from "constants/ApiSetting"
import { isEmpty } from "lodash"
import { getItems } from "util/localStorage"

function* changePersonalInfoProcess({payload}){
    const { full_name,job_position,user_id } = payload
    const userProfile = {}
          userProfile.user_id = user_id
          userProfile.full_name = full_name
          userProfile.job_position = job_position
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/updateInfo/`
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = userProfile
    try {
      const response = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
                const processStatus = { status: true, action: "updatePersonal", message: "Update Success" }  
                yield put(changeProcessSuccess(processStatus))
        }else { 
              const {message,data} = response.data
              const processStatus = { status: false, action: "updatePersonal", message }
              yield put(changeProcessSuccess(processStatus))
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
        } 
      
    } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "updatePersonal", message:error }  
        yield put(changeProcessSuccess(processStatus))
    }
    
}

function* changeContactInfoProcess({payload}){
    const { mobile,telephone,user_id } = payload
    const userProfile = {}
          userProfile.user_id = user_id
          userProfile.mobile = mobile
          userProfile.telephone = telephone
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/updateInfo/`
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = userProfile
    try {
      const response = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
                const processStatus = { status: true, action: "updateContact", message: "Update Success" }  
                yield put(changeProcessSuccess(processStatus))
        }else { 
              const {message,data} = response.data
              const processStatus = { status: false, action: "updateContact", message }
              yield put(changeProcessSuccess(processStatus))
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
        } 
      
    } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "updateContact", message:error }  
        yield put(changeProcessSuccess(processStatus))
    }
}

function* changePasswordProcess({payload}){

  const { old_pass,new_pass,user_id } = payload
    const userProfile = {}
          userProfile.user_id = user_id
          userProfile.new_password = new_pass
          userProfile.old_password = old_pass
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/updateInfo/`
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = userProfile
    try {
      const response = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
                const processStatus = { status: true, action: "updatePassword", message: "Update Success" }  
                yield put(changeProcessSuccess(processStatus))
        }else { 
              const {message,data} = response.data
              const processStatus = { status: false, action: "updatePassword", message }
              yield put(changeProcessSuccess(processStatus))
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
        } 
      
    } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "updatePassword", message:error }  
        yield put(changeProcessSuccess(processStatus))
    }
}

function* userProfilePreviewProcess({payload}){
  const {user_id} = payload
  const  { access_token }  = yield call(getItems, ['access_token'])
  const AccessToken = access_token
  const url = `${API_URL_AUTH}auth/user/${user_id}/`
  API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

  try {
    const response = yield APIGetRequest(url, API_HEADERS)
    if (response.status === 200) {
        const {data:user} = response 
        const container = {}
              container.key = user.user_id
              container.user_id = user.user_id
              container.user_name = user.user_name
              container.full_name = user.full_name||""
              container.group_id = user.group_id
              container.group_name = user.group_name
              container.group_type = user.group_type
              container.mobile = user.mobile||""
              container.telephone = user.telephone||""
              container.profile_pic = user.profile_pic
              container.properties = []
              container.email = user.email||""
              container.job_position = user.job_position||""
              container.active = (user.active)?true:false
              if(!isEmpty(user.group_name))
                container.group_tags = [user.group_name] 
              else 
              container.group_tags = []

              if(!isEmpty(user.job_position))
                container.job_tags = [user.job_position] 
              else 
                container.job_tags = []

              if(!isEmpty(user.properties)){
                const propertieID = user.properties.map((value)=>{
                        const valueID = (value.id)                         
                        return valueID
                      })
                      container.properties =[...propertieID]
              }

      yield put(userProfileProcessSuccess(container))
    } else {
      console.log("Error : ",response)
      /* Show error message and signout */
      const {message,data} = response.data
      const processStatus = { status: false, action: "profilePreview", message }  
      yield put(changeProcessSuccess(processStatus))
        if(response.status === 403){
              /* if data is not null force signout */
          if(data)
            yield put(userSignOut())
        }
    }
  } catch (error) {
    console.log("Error on saga get userprofile : ", error)
  }
}

function* changeImageProfileProcess({payload}){
    const {user_id,imageUrl} = payload
    const dataSplit = imageUrl.split(",")
    const image_type = dataSplit[0]
    const image = dataSplit[1]
    
    const userProfile = {}
          userProfile.user_id = user_id
          userProfile.profile_pic = {image,type:image_type}

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user/updateInfo/`
          API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = userProfile
    try {
      const response = yield APIPostRequest(url,API_DATABODY,API_HEADERS)

        if(response.status === 200){
                const processStatus = { status: true, action: "updateImageProfile", message: "Change image Success" }  
                yield put(changeProcessSuccess(processStatus))
        }else { 
              const {message,data} = response.data
              const processStatus = { status: false, action: "updateImageProfile", message }
              yield put(changeProcessSuccess(processStatus))
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
        } 
      
    } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "updateImageProfile", message:error }  
        yield put(changeProcessSuccess(processStatus))
    }
}

/* Watcher Saga */
export function* changePersonalInfo() {
  yield takeLatest(CHANGE_PERSONAL_INFO, changePersonalInfoProcess)
}

export function* changeContactInfo(){
  yield takeLatest(CHANGE_CONTACT_INFO,changeContactInfoProcess)
}
export function* changePassword(){
  yield takeLatest(CHANGE_PASSWORD, changePasswordProcess)
}
export function* userProfilePreview(){
  yield takeLatest(USER_PROFILE_PREVIEW,userProfilePreviewProcess)
}

export function* changeImageProfile(){
  yield takeLatest(CHANGE_IMAGE_PROFILE,changeImageProfileProcess)
}

export default function* rootSaga() {
  yield all([fork(changePersonalInfo),
             fork(changeContactInfo),
             fork(changePassword),
             fork(changeImageProfile),
             fork(userProfilePreview)])
}
