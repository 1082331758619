import {
  EXPOSURE_PREVIEW,
  EXPOSURE_NEW,
  EXPOSURE_UPDATE,
  EXPOSURE_REMOVE,
  EXPOSURE_PROCESS_SUCCESS,
  EXPOSURE_DATASOURCE_SUCCESS,
  EXPOSURE_SEARCH,
  EXPOSURE_PROCESS_RESET
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPOSURE_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case EXPOSURE_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case EXPOSURE_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case EXPOSURE_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case EXPOSURE_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case EXPOSURE_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case EXPOSURE_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case EXPOSURE_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}