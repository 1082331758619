import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  FOLLOW_UP_PREVIEW,
  FOLLOW_UP_NEW,
  FOLLOW_UP_UPDATE,
  FOLLOW_UP_REMOVE,
  FOLLOW_UP_COMMENT_NEW,
  FOLLOW_UP_COMMENT_UPDATE,
  FOLLOW_UP_COMMENT_REMOVE,
  FOLLOW_UP_UPDATE_SEEN
} from "constants/ActionTypes"

import {followUpDataSourceUpdate,followUpProcessSuccess,followUpResetState} from "appRedux/actions/FollowUp"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { orderBy,isEmpty } from "lodash"
import moment from 'moment'
import { getItems } from "util/localStorage"

function* followUpPreviewProcess({payload}){
    const { id,reservationType } = payload
    const url = `${API_URL_V1}pms/utility/followUp/${reservationType}/${id}/`
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {  
            const messageDataSource = orderBy(response.data,[o => o.id],['desc']).map((obj,key) =>{
              const container = {}
                    container.key = `followup-${key}`
                    container.followID = obj.id
                    container.followTopic = obj.title
                    container.followMessage =  obj.message
                    container.followCategoryID = obj.followup_category_id
                    container.followCategoryName = obj.followup_category_name
                    container.followStatusID = obj.followup_status_id
                    container.followStatusName = obj.follow_status_name
                    if(obj.assign_users)
                      container.assignUserID = obj.assign_users
                    else
                      container.assignUserID = []
                    container.alertLocationID = obj.alert_pages_id
                    container.alertDate = obj.alert_date
                    container.alertDateMessage = moment(obj.alert_date).format("DD/MM/YYYY")
                    container.seen = obj.seen
                    container.userSeen = obj.users_seen
                    container.entryBy = obj.create_user
                    container.userProfileImages = obj.profile_user
                    container.followTime = moment(obj.create_time).format("DD/MM/YYYY HH:mm")
                    container.followModifyTime = (obj.modify_time)?moment(obj.modify_time).format("DD/MM/YY HH:mm"):undefined
                    if(isEmpty(obj.followup_comments)) 
                      container.followDetail = []
                    else{
                      const newCommentsModifier = obj.followup_comments.map((obj)=>{
                      const {id,message,create_user,create_time,modify_time,profile_user} = obj
                      const newObjectModifier = { id,message,create_user,
                                                  create_time: moment(create_time).format("DD/MM/YY HH:mm"),
                                                  modify_time: (modify_time)?moment(modify_time).format("DD/MM/YY HH:mm"):undefined,
                                                  userProfileImages:profile_user }
                          return newObjectModifier
                      })
                      container.followDetail = newCommentsModifier
                    }
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(followUpDataSourceUpdate(messageDataSource))

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message } 
            yield put(followUpProcessSuccess(processStatus))
            yield put(followUpResetState())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on message to followup preview : ",error)
        const processStatus = { status: false, action: "preview", message:error } 
        yield put(followUpProcessSuccess(processStatus))
        yield put(followUpResetState())

    }
    
}

function* followUpCreateProcess({payload}){
  
  const {ref_id,reservationType, follow_message,category, status,assign_user,alert_location,follow_topic,alert_date,notikey } = payload

  const followUpInfo = {}
        followUpInfo.ref_type = reservationType
        followUpInfo.ref_id = ref_id
        followUpInfo.title = follow_topic
        followUpInfo.message = follow_message
        followUpInfo.followup_category_id = category
        followUpInfo.followup_status_id = status
        followUpInfo.assign_users = assign_user
        followUpInfo.alert_pages_id = alert_location
        followUpInfo.alert_date = alert_date

      try {
            const url = `${API_URL_V1}pms/utility/followUp/create/`
            const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = followUpInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message: "Create Success",notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
                
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message,notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error,notikey }  
            yield put(followUpProcessSuccess(processStatus))
            yield put(followUpResetState())
      }
}

function* followUpUpdateProcess({payload}){

  const { follow_id,ref_id,reservationType, follow_message,follow_topic, category,status,assign_user,alert_location,alert_date,notikey } = payload
  const followUpInfo = {}
        followUpInfo.id = follow_id;
        followUpInfo.ref_type = reservationType
        followUpInfo.ref_id = ref_id
        followUpInfo.title = follow_topic
        followUpInfo.message = follow_message
        followUpInfo.followup_category_id = category
        followUpInfo.followup_status_id = status
        followUpInfo.assign_users = assign_user
        followUpInfo.alert_pages_id = alert_location
        followUpInfo.alert_date = alert_date
  try {
        const url = `${API_URL_V1}pms/utility/followUp/update/`
        const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = followUpInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message: "Update Success",notikey }  
            yield put(followUpProcessSuccess(processStatus))
            yield put(followUpResetState())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message,notikey }
          yield put(followUpProcessSuccess(processStatus))
          yield put(followUpResetState())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error,notikey }
        yield put(followUpProcessSuccess(processStatus))
        yield put(followUpResetState())
  }
}

function* followUpRemoveProcess({payload}){
    const {followUpRemoveID,notikey} = payload
    
    try{
        const url = `${API_URL_V1}pms/utility/followUp/delete/`
        const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : followUpRemoveID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "remove", message: "Remove Success",notikey } 
          yield put(followUpProcessSuccess(processStatus))
          yield put(followUpResetState())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "remove", message,notikey } 
          yield put(followUpProcessSuccess(processStatus))
          yield put(followUpResetState())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "remove", message:error,notikey } 
      yield put(followUpProcessSuccess(processStatus))
      yield put(followUpResetState())
    }
}

function* followUpCommentCreateProcess({payload}){

  const {followID, message,notikey} = payload

  const commentInfo = {}
        commentInfo.followup_id = followID
        commentInfo.message = message

      try {
            const url = `${API_URL_V1}pms/utility/followUpComments/create/`
            const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = commentInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "comment_create", message: "Success",notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
                
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "comment_create", message,notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "comment_create", message:error }  
            yield put(followUpProcessSuccess(processStatus))
            yield put(followUpResetState())
      }
}

function* followUpCommentUpdateProcess({payload}){

  const {commentID, message,notikey} = payload

  const commentInfo = {}
        commentInfo.id = commentID
        commentInfo.message = message

      try {
            const url = `${API_URL_V1}pms/utility/followUpComments/update/`
            const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = commentInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "comment_update", message: "Success",notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
                
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "comment_update", message,notikey }  
              yield put(followUpProcessSuccess(processStatus))
              yield put(followUpResetState())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "comment_update", message:error }  
            yield put(followUpProcessSuccess(processStatus))
            yield put(followUpResetState())
      }
}

function* followUpCommentRemoveProcess({payload}){

  const {commentID,notikey} = payload
  
    try{
        const url = `${API_URL_V1}pms/utility/followUpComments/delete/`
        const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : commentID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "comment_remove", message: "Remove Success",notikey } 
          yield put(followUpProcessSuccess(processStatus))
          yield put(followUpResetState())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "comment_remove", message,notikey } 
          yield put(followUpProcessSuccess(processStatus))
          yield put(followUpResetState())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "comment_remove", message:error,notikey } 
      yield put(followUpProcessSuccess(processStatus))
      yield put(followUpResetState())
    }
}

function* followUpUpdateSeenProcess({payload}){
  const followID = payload
  
    try{
        const url = `${API_URL_V1}pms/utility/followUp/seen/`
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {followup_id : followID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200)
          console.log("Update seen success")
        else{
          const {message} = response
          console.log("Update seen false ",message)     
        }
    }
    catch(error){
      console.log("Error update seen : ",error)
    }
}
/* Watcher Saga */
export function* followUpPreview() {
  yield takeLatest(FOLLOW_UP_PREVIEW, followUpPreviewProcess)
}

export function* followUpCreate(){
  yield takeLatest(FOLLOW_UP_NEW,followUpCreateProcess)
}
export function* followUpUpdate(){
  yield takeLatest(FOLLOW_UP_UPDATE, followUpUpdateProcess)
}
export function* followUpRemove(){
  yield takeLatest(FOLLOW_UP_REMOVE,followUpRemoveProcess)
}
export function* followUpCommentCreate(){
  yield takeLatest(FOLLOW_UP_COMMENT_NEW,followUpCommentCreateProcess)
}
export function* followUpCommentUpdate(){
  yield takeLatest(FOLLOW_UP_COMMENT_UPDATE,followUpCommentUpdateProcess)
}
export function* followUpCommentRemove(){
  yield takeLatest(FOLLOW_UP_COMMENT_REMOVE,followUpCommentRemoveProcess)
}
export function* followUpUpdateSeen(){
  yield takeLatest(FOLLOW_UP_UPDATE_SEEN,followUpUpdateSeenProcess)
}

export default function* rootSaga() {
  yield all([fork(followUpPreview),
             fork(followUpCreate),
             fork(followUpUpdate),
             fork(followUpRemove),
             fork(followUpCommentCreate),
             fork(followUpCommentUpdate),
             fork(followUpCommentRemove),
             fork(followUpUpdateSeen)])
}
