import * as actions from "./calendarActionType"

export const calendarPreview = (payload?: any): actions.CalendarRequest => {
  return {
    type: actions.CALENDAR_REQUEST,
    payload
  }
}

export const calendarProcessSuccess = (payload:any):actions.CalendarProcessSuccess =>{
  return{
    type: actions.CALENDAR_PROCESS_SUCCESS,
    payload
  }
}

export const calendarDataSourceSuccess = (payload:any):actions.CalendarDataSourceSuccess => {
  return {
    type: actions.CALENDAR_DATASOURCE_SUCCESS,
    payload
  }
}

export const calendarProcessReset = () =>{
  return{
    type:actions.CALENDAR_PROCESS_RESET
  } 
}
