import {
    MEDIA_GROUP_PREVIEW,
    MEDIA_GROUP_NEW,
    MEDIA_GROUP_UPDATE,
    MEDIA_GROUP_REMOVE,
    MEDIA_GROUP_SEARCH,
    MEDIA_GROUP_PROCESS_SUCCESS,
    MEDIA_GROUP_DATASOURCE_SUCCESS,
    MEDIA_GROUP_PROCESS_RESET
  } from "constants/ActionTypes"

  export const mediaGroupPreview = () => {
    return {
      type: MEDIA_GROUP_PREVIEW
    }
  }

  export const mediaGroupCreate =(value) =>{
    return {
      type:MEDIA_GROUP_NEW,
      payload:value
    }
  }

  export const mediaGroupUpdate = (value) =>{
    return {
      type:MEDIA_GROUP_UPDATE,
      payload: value
    }
  }

  export const mediaGroupRemove = (value)=>{
    return {
      type:MEDIA_GROUP_REMOVE,
      payload:value
    }
  }

  export const mediaGroupSearch = (keyword) =>{
    return {
      type: MEDIA_GROUP_SEARCH,
      payload:keyword
    }
  }

  export const mediaGroupProcessSuccess = (value) =>{
    return{
      type:MEDIA_GROUP_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const mediaGroupDataSourceUpdate = (value) => {
    return {
      type: MEDIA_GROUP_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const mediaGroupProcessReset = ()=>{
    return{
      type:MEDIA_GROUP_PROCESS_RESET
    }
  }

