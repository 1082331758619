
export const CHARGE_RESERVATION_REQUEST = "CHARGE_RESERVATION_REQUEST"
export const CHARGE_RESERVATION_UPDATE = "CHARGE_RESERVATION_UPDATE"
export const CHARGE_RESERVATION_PROCESS_SUCCESS = "CHARGE_RESERVATION_PROCESS_SUCCESS"
export const CHARGE_RESERVATION_DATASOURCE_SUCCESS = "CHARGE_RESERVATION_DATASOURCE_SUCCESS"
export const CHARGE_RESERVATION_PROCESS_RESET = "CHARGE_RESERVATION_PROCESS_RESET"
export const CHARGE_RESERVATION_SET_FILTER = "CHARGE_RESERVATION_SET_FILTER"

export const CHARGE_RESERVATION_DETAIL_REQUEST = "CHARGE_RESERVATION_DETAIL_REQUEST"
export const CHARGE_RESERVATION_DETAIL_DATASOURCE_SUCCESS = "CHARGE_RESERVATION_DETAIL_DATASOURCE_SUCCESS"

export interface ChargeReservationRequest {
  type: typeof CHARGE_RESERVATION_REQUEST,
  payload?:any
}
export interface ChargeReservationUpdate {
  type: typeof CHARGE_RESERVATION_UPDATE,
  payload?:any
}

export interface ChargeReservationProcessSuccess {
  type: typeof CHARGE_RESERVATION_PROCESS_SUCCESS,
  payload?:any
}
export interface ChargeReservationDataSourceSuccess{
  type: typeof CHARGE_RESERVATION_DATASOURCE_SUCCESS,
  payload:any
}
export interface ChargeReservationDetailRequest {
  type: typeof CHARGE_RESERVATION_DETAIL_REQUEST,
  payload?:any
}
export interface ChargeReservationDetailDataSourceSuccess{
  type: typeof CHARGE_RESERVATION_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface ChargeReservationProcessReset {
  type: typeof CHARGE_RESERVATION_PROCESS_RESET
}
export interface ChargeReservationSetFilter{
  type: typeof CHARGE_RESERVATION_SET_FILTER,
  payload:any
}


export type ChargeAction = 
| ChargeReservationRequest
| ChargeReservationDetailRequest
| ChargeReservationUpdate
| ChargeReservationProcessSuccess
| ChargeReservationDataSourceSuccess
| ChargeReservationDetailDataSourceSuccess
| ChargeReservationProcessReset
| ChargeReservationSetFilter

