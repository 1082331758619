export const REPORT_OPTIONS_GET_REQUEST = 'REPORT_OPTIONS_GET_REQUEST'
export const REPORT_OPTIONS_GET_SUCCESS = 'REPORT_OPTIONS_GET_SUCCESS'
export const REPORT_OPTIONS_GET_FAIL = 'REPORT_OPTIONS_GET_FAIL'

export declare type ReportOptionsKeyType = | 'Text' | 'Number' | 'DropdownList' | 'CheckBox' | 'RadioGroup' | 'Day' | 'Date' | 'MonthYear' | 'Month' | 'Year' | 'DateRange'

declare type ReportOptionsKeyLayout = | 'vertical' | 'horizontal'

export declare type DefaultValueType = | 'TODAY' | 'TOMORROW' | 'YESTERDAY' | 'NEXT_7_DAYS' | 'NEXT_30_DAYS' | 'LAST_7_DAYS' | 'LAST_30_DAYS' | 'NEXT_MONTH' | 'LAST_MONTH' | 'NEXT_WEEK' | 'LAST_WEEK' | 'START_OF_MONTH' | 'END_OF_MONTH' | 'START_OF_YEAR' | 'END_OF_YEAR' | 'START_OF_DAY' | 'END_OF_DAY'

declare type DropdownMode = | 'single' | 'multiple'

interface DropdownsItems {
    label: string
    value: number | string
}

/**
 * State key in parameters
 */
export interface OptionsKeyState {
    type: ReportOptionsKeyType
    name: string
    label: string
    groupArea: string
    groupLayout: ReportOptionsKeyLayout
    defaultValue: DefaultValueType | number | boolean | string | null
    mode: DropdownMode
    items: DropdownsItems[]
    startDateName?: string
    endDateName?: string
    defaultStartDate?: string
    defaultEndDate?: string
    passValueLabel?: boolean
}

/**
 * State of report's options
 */
export interface ReportOptionsState {
    parameters: OptionsKeyState[]
}


/**
 * State of new structure realdy for render dynamic form
 */
 export interface ReportFormComponentsState {
    groupArea: string | null
    existNextGroupArea: boolean
    groupLayout: ReportOptionsKeyLayout | null
    components: OptionsKeyState[]
}

// /**
//  * Pass parameter for API call options of report
//  */
// export interface ReportOptionsParams {
//     report_id: number
// }