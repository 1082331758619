import React from "react"
import { useHistory } from 'react-router-dom'
import {Avatar,Popconfirm,Tooltip} from "antd"
import styled from 'styled-components'

const HeaderTopic = styled.p`
  cursor:pointer;
  margin-top:0;
`

const NotificationItem = ({notification,handleFollowUp,onHandleClose,handleDelete}) => {
  const {notification_id,profile_pic, notification_title,notification_message, notify_date,ref_id,ref_type} = notification
  const history = useHistory()
  return (

    <li className="gx-media">
      <Avatar className="gx-size-40 gx-mr-3" alt={profile_pic} src={profile_pic}/>
      <div className="gx-media-body gx-align-self-center">
          <HeaderTopic className="gx-fs-sm" onClick={() => 
          { 
            if(ref_type==="followup"){
              handleFollowUp(ref_id) 
              onHandleClose()
            }
            else if(ref_type==="booking"){
              history.push(`/main/reservations/individual/form/${ref_id}`)
              onHandleClose()
            }
          }}>{<Tooltip title={notification_message} trigger="click|hover">{notification_title}</Tooltip>}</HeaderTopic>
        <span className="gx-meta-date"><small>{notify_date}</small></span>
        <span style={{float:"right"}}>
          <small>
            <Popconfirm
              title="Are you confirm to delete ?"
              onConfirm={() => handleDelete(notification_id)}
            ><i className="icon icon-trash" title="Remove" style={{cursor:"pointer"}}></i>
            </Popconfirm>
          </small></span>
      </div>
    </li>
  )
}

export default NotificationItem
