import {
    MEDIA_PREVIEW,
    MEDIA_NEW,
    MEDIA_UPDATE,
    MEDIA_REMOVE,
    MEDIA_SEARCH,
    MEDIA_PROCESS_SUCCESS,
    MEDIA_DATASOURCE_SUCCESS,
    MEDIA_PROCESS_RESET
  } from "constants/ActionTypes"

  export const mediaPreview = () => {
    return {
      type: MEDIA_PREVIEW
    }
  }

  export const mediaCreate =(value) =>{
    return {
      type:MEDIA_NEW,
      payload:value
    }
  }

  export const mediaUpdate = (value) =>{
    return {
      type:MEDIA_UPDATE,
      payload: value
    }
  }

  export const mediaRemove = (value)=>{
    return {
      type:MEDIA_REMOVE,
      payload:value
    }
  }

  export const mediaSearch = (keyword) =>{
    return {
      type: MEDIA_SEARCH,
      payload:keyword
    }
  }

  export const mediaProcessSuccess = (value) =>{
    return{
      type:MEDIA_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const mediaDataSourceUpdate = (value) => {
    return {
      type: MEDIA_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const mediaProcessReset = () =>{
    return{
      type:MEDIA_PROCESS_RESET
    }
  }

