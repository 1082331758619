
export const CHARGE_SCHEDULE_REQUEST = "CHARGE_SCHEDULE_REQUEST"
export const CHARGE_SCHEDULE_PROCESS_SUCCESS = "CHARGE_SCHEDULE_PROCESS_SUCCESS"
export const CHARGE_SCHEDULE_DATASOURCE_SUCCESS = "CHARGE_SCHEDULE_DATASOURCE_SUCCESS"
export const CHARGE_SCHEDULE_PROCESS_RESET = "CHARGE_SCHEDULE_PROCESS_RESET"

export interface ChargeScheduleRequest {
  type: typeof CHARGE_SCHEDULE_REQUEST,
  payload?:any
}
export interface ChargeScheduleProcessSuccess {
  type: typeof CHARGE_SCHEDULE_PROCESS_SUCCESS,
  payload:{}
}
export interface ChargeScheduleDataSourceSuccess{
  type: typeof CHARGE_SCHEDULE_DATASOURCE_SUCCESS,
  payload:any
}
export interface ChargeScheduleProcessReset {
  type: typeof CHARGE_SCHEDULE_PROCESS_RESET
}

export type CashierAction = 
| ChargeScheduleRequest
| ChargeScheduleProcessSuccess
| ChargeScheduleDataSourceSuccess
| ChargeScheduleProcessReset
