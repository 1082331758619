import moment from 'moment'
import {
    INVENTORY_RATECALENDAR,
    INVENTORY_RATECALENDAR_SUCCESS,
    INVENTORY_RATECALENDAR_FAIL,
    INVENTORY_RATECALENDAR_UPDATE,
    INVENTORY_RATECALENDAR_UPDATE_FAIL,
    INVENTORY_RATECALENDAR_UPDATE_SUCCESS,
    INVENTORY_BULKUPDATE_REQUEST,
    INVENTORY_BULKUPDATE_SUCCESS,
    INVENTORY_BULKUPDATE_FAIL,
    INVENTORY_BULKUPDATE_RESET
} from 'constants/ActionTypes'

export const bulkUpdateReset = () => {
    return {
        type: INVENTORY_BULKUPDATE_RESET
    }
}
export const bulkUpdateSuccess = (value) => {
    return {
        type: INVENTORY_BULKUPDATE_SUCCESS,
        payload: value
    }
}
export const bulkUpdateFail = (value) => {
    return {
        type: INVENTORY_BULKUPDATE_FAIL,
        payload: value
    }
}
export const bulkUpdateRequest = (value) => {
    return {
        type: INVENTORY_BULKUPDATE_REQUEST,
        payload: value
    }
}

export const updateInventoryCalendarSuccess = (data) => {
    return {
        type: INVENTORY_RATECALENDAR_UPDATE_SUCCESS,
        payload: data
    }
}

export const updateInventoryCalendar = (start_date, value, processUpdateMSG) => {
    if(value.length<=0) {
        // getInventoryRateCalendar(start_date)
        return {
            type: INVENTORY_RATECALENDAR,
            start_date: start_date,
            is_edit_data: false
          }
    }
    return {
        type: INVENTORY_RATECALENDAR_UPDATE,
        start_date,
        payload: value,
        processUpdateMSG: processUpdateMSG
    }
}

export const updateInventoryCalendarFail = (messages) => {
    return {
        type: INVENTORY_RATECALENDAR_UPDATE_FAIL,
        processUpdateMSG: messages
    }
}

const chooseStartdate = (period, todaySystemDate, value) => {
    switch (period) {
        case 'today':
            return moment(value).format('YYYY-MM-DD')
        case 'nextMonth':
            return moment(value).add(1, 'months').format('YYYY-MM-DD')
        case 'forward':
            return moment(value).add(20, 'days').format('YYYY-MM-DD')
        case 'backward':
            const startDays = moment(value).subtract(20, 'days').format('YYYY-MM-DD')
            if(moment(startDays).diff(todaySystemDate, 'days')<=0) return moment(todaySystemDate).format('YYYY-MM-DD')
            return startDays
        case 'previousMonth':
            const startMonths = moment(value).subtract(1, 'months').format('YYYY-MM-DD')
            if(moment(startMonths).diff(todaySystemDate, 'months')<=0) return moment(todaySystemDate).format('YYYY-MM-DD')
            return startMonths
        case 'calendar':
            return moment(value).format('YYYY-MM-DD')
        default:
            return moment(value).format('YYYY-MM-DD')
    }
}
export const chooseInventoryRateCalendar = (period, todaySystemDate, value, is_edit_data = false) => {
    return {
      type: INVENTORY_RATECALENDAR,
      start_date: chooseStartdate(period, todaySystemDate, value),
      is_edit_data: is_edit_data
    }
}
export const getInventoryRateCalendar = (start_date, is_edit_data = false) => {
    return {
      type: INVENTORY_RATECALENDAR,
      start_date: start_date,
      is_edit_data: is_edit_data
    }
}

export const getInventoryRateCalendarSuccess = (data) => {
    return {
        type: INVENTORY_RATECALENDAR_SUCCESS,
        payload: data
    }
}

export const getInventoryRateCalendarFail = (messages) => {
    return {
        type: INVENTORY_RATECALENDAR_FAIL,
        messages: messages
    }
}