import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {NOTIFICATION_PREVIEW,NOTIFICATION_REMOVE} from "constants/ActionTypes"

import {notificationDataSourceUpdate,notificationProcessSuccess} from "appRedux/actions/Notifications"
import {userSignOut} from "appRedux/actions/Auth"
import moment from "moment"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { isNull } from 'lodash'
import { API_URL_AUTH,API_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"

function* GetNotifyFromAPI () {
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_AUTH}auth/user-notification/`
    API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    const response = yield APIGetRequest(url, API_HEADERS)
    if (response.status === 200) {
      return response.data
    } else {
      console.log("Error : ",response)
      /* Show error message and signout */
      const {message,data} = response.data
      const processStatus = { status: false, action: "notificationPreview", message:"" }  
      yield put(notificationProcessSuccess(processStatus))
        if(response.status === 403){
          /* if data is not null force signout */
          if(data)
          yield put(userSignOut())
        }
    }
  } catch (error) {
    console.log("Error on saga get notification(GetNotifyFromAPI) : ", error)
  }
}
  
function* notificationPreviewProcess({ notify_datas }){
  try {
    let data_notify = []
    if(isNull(notify_datas)) {
      //get data from API
      data_notify = yield GetNotifyFromAPI()
    } else {
      //get data from socket
      data_notify = notify_datas.last_data
    }

    // Process and update to action
    const notificationDataSource = data_notify.map((o,_k) =>{
          const container = {}
              container.key = o.id
              container.notification_id = o.id
              container.notification_title = o.title
              container.notification_message = o.notify_message
              container.ref_id = o.ref_id
              container.ref_type = o.ref_type
              container.create_user = o.create_user
              container.notify_date = moment(o.notify_date).format("DD/MM/YYYY HH:mm")
              container.notify_user = o.notify_user
              container.profile_pic = o.profile_user
        return container
      })
    yield put(notificationDataSourceUpdate(notificationDataSource))
  } catch (error) {
    console.log("Error on saga get notification : ", error)
  }
}

function* notificationRemoveProcess({payload}){
  const notificationID = payload

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_AUTH}auth/user-notification/delete/`
        API_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : notificationID}
        const response = yield APIPostRequest(url,API_DATABODY,API_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "notification_remove", message: "Remove Success" } 
          yield put(notificationProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "notification_remove", message } 
          yield put(notificationProcessSuccess(processStatus))
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "notification_remove", message:error } 
      yield put(notificationProcessSuccess(processStatus))
    }
}

/* Watcher Saga */
export function* notificationPreview(){
  yield takeLatest(NOTIFICATION_PREVIEW,notificationPreviewProcess)
}
export function* notificationRemove(){
  yield takeLatest(NOTIFICATION_REMOVE,notificationRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(notificationPreview),fork(notificationRemove)])
}
