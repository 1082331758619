import {
  CHANGE_PERSONAL_INFO,
  CHANGE_CONTACT_INFO,
  CHANGE_PASSWORD,
  CHANGE_PROCESS_SUCCESS,
  USER_PROFILE_PREVIEW,
  USER_PROFILE_PROCESS_SUCCESS
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSource:{},
  process:{}
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE_PREVIEW:{
      return{
        ...state,
        loader:false,
        process:{status:false,action:"",message:""}
      }
    }
    case USER_PROFILE_PROCESS_SUCCESS:{
      return{
        ...state,
        dataSource:action.payload
      }
    }
    case CHANGE_PERSONAL_INFO: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case CHANGE_CONTACT_INFO: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case CHANGE_PASSWORD:{
        return{
          ...state,
          loader:false,
          process:{status:false,action:"",message:""}
        }
    }
    case CHANGE_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:{...action.payload}
      }
    }
    default:
      return state
  }
}