import {
  CHANNEL_PREVIEW,
  CHANNEL_NEW,
  CHANNEL_UPDATE,
  CHANNEL_REMOVE,
  CHANNEL_PROCESS_SUCCESS,
  CHANNEL_DATASOURCE_SUCCESS,
  CHANNEL_SEARCH,
  CHANNEL_DETAIL_SUCCESS,
  CHANNEL_LOAD_LIST_SUCCESS,
  CHANNEL_PROCESS_RESET
  
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  coallotList:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANNEL_PREVIEW: {
      return {
        ...state,
        loader: false,
        dataSerialize:{},
        process:{status:false,action:"",message:""}
      }
    }
    case CHANNEL_NEW: {
      return {
          ...state,
          loader: true,
          process:{status:false,action:"",message:""}
      }
    }
    case CHANNEL_UPDATE:{
        return{
          ...state,
          loader:true,
          process:{status:false,action:"",message:""},
          dataSerialize:action.payload
        }
    }
    case CHANNEL_REMOVE: {
      return {
          ...state,
          loader: false,
          process:{status:false,action:"",message:""}
      }
    }
    case CHANNEL_SEARCH:{
        return {
            ...state,
            loader:false,
            keyword:action.payload
        }
    }
    case CHANNEL_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case CHANNEL_DATASOURCE_SUCCESS:{
        return {
            ...state,
            loader: false,
            dataSource: action.payload
        }
    }
    case CHANNEL_DETAIL_SUCCESS:{
      return{
        ...state,
        dataSerialize:action.payload
      }
    }
    case CHANNEL_LOAD_LIST_SUCCESS:{
      return{
        ...state,
        coallotList:action.payload.coallotList
      }
    }
    case CHANNEL_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}