import {
    FOLLOW_UP_PREVIEW,
    FOLLOW_UP_NEW,
    FOLLOW_UP_UPDATE,
    FOLLOW_UP_REMOVE,
    FOLLOW_UP_PROCESS_SUCCESS,
    FOLLOW_UP_DATASOURCE_SUCCESS,
    FOLLOW_UP_COMMENT_NEW,
    FOLLOW_UP_COMMENT_UPDATE,
    FOLLOW_UP_COMMENT_REMOVE,
    FOLLOW_UP_UPDATE_SEEN,
    RESET_STATE
  } from "constants/ActionTypes"

  export const followUpPreview = (value) => {
    return {
      type: FOLLOW_UP_PREVIEW,
      payload:value
    }
  }

  export const followUpCreate =(value) =>{
    return {
      type:FOLLOW_UP_NEW,
      payload:value
    }
  }

  export const followUpUpdate = (value) =>{
    return {
      type:FOLLOW_UP_UPDATE,
      payload: value
    }
  }

  export const followUpRemove = (value)=>{
    return {
      type:FOLLOW_UP_REMOVE,
      payload:value
    }
  }

  export const followUpProcessSuccess = (value) =>{
    return{
      type:FOLLOW_UP_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const followUpDataSourceUpdate = (value) => {
    return {
      type: FOLLOW_UP_DATASOURCE_SUCCESS,
      payload: value
    }
  }

  export const followUpCommentNew = (value) =>{
    return {
      type:FOLLOW_UP_COMMENT_NEW,
      payload:value
    }
  }

  export const followUpCommentUpdate = (value) =>{
    return{
      type:FOLLOW_UP_COMMENT_UPDATE,
      payload:value
    }
  }

  export const followUpCommentRemove = (value)=>{
    return{
      type:FOLLOW_UP_COMMENT_REMOVE,
      payload:value
    }
  }

  export const followUpUpdateSeen = (value)=>{
    return{
      type:FOLLOW_UP_UPDATE_SEEN,
      payload:value
    }
  }

  export const followUpResetState = () =>{
    return{
      type: RESET_STATE
    }
  }

