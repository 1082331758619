import {
    CITY_PREVIEW,
    CITY_NEW,
    CITY_UPDATE,
    CITY_REMOVE,
    CITY_SEARCH,
    CITY_PROCESS_SUCCESS,
    CITY_DATASOURCE_SUCCESS,
    CITY_PROCESS_RESET
  } from "constants/ActionTypes"

  export const cityPreview = (value) => {
    return {
      type: CITY_PREVIEW,
      payload:value
    }
  }

  export const cityCreate =(value) =>{
    return {
      type:CITY_NEW,
      payload:value
    }
  }

  export const cityUpdate = (value) =>{
    return {
      type:CITY_UPDATE,
      payload: value
    }
  }

  export const cityRemove = (value)=>{
    return {
      type:CITY_REMOVE,
      payload:value
    }
  }

  export const citySearch = (keyword) =>{
    return {
      type: CITY_SEARCH,
      payload:keyword
    }
  }

  export const cityProcessSuccess = (value) =>{
    return{
      type:CITY_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const cityDataSourceUpdate = (value) => {
    return {
      type: CITY_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const cityProcessReset = () =>{
    return{
      type:CITY_PROCESS_RESET
    }
  }

