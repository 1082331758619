import {
    CHANNEL_GROUP_PREVIEW,
    CHANNEL_GROUP_NEW,
    CHANNEL_GROUP_UPDATE,
    CHANNEL_GROUP_REMOVE,
    CHANNEL_GROUP_SEARCH,
    CHANNEL_GROUP_PROCESS_SUCCESS,
    CHANNEL_GROUP_DATASOURCE_SUCCESS,
    CHANNEL_GROUP_PROCESS_RESET
  } from "constants/ActionTypes"

  export const channelGroupPreview = (value) => {
    return {
      type: CHANNEL_GROUP_PREVIEW,
      payload:value
    }
  }

  export const channelGroupCreate =(value) =>{
    return {
      type:CHANNEL_GROUP_NEW,
      payload:value
    }
  }

  export const channelGroupUpdate = (value) =>{
    return {
      type:CHANNEL_GROUP_UPDATE,
      payload: value
    }
  }

  export const channelGroupRemove = (value)=>{
    return {
      type:CHANNEL_GROUP_REMOVE,
      payload:value
    }
  }

  export const channelGroupSearch = (keyword) =>{
    return {
      type: CHANNEL_GROUP_SEARCH,
      payload:keyword
    }
  }

  export const channelGroupProcessSuccess = (value) =>{
    return{
      type:CHANNEL_GROUP_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const channelGroupDataSourceUpdate = (value) => {
    return {
      type: CHANNEL_GROUP_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const channelGroupProcessReset=()=>{
    return{
      type:CHANNEL_GROUP_PROCESS_RESET
    }
  }

