import React from 'react'
import { useSelector } from "react-redux"
import { Row,Col,Card,Popconfirm,Divider,Avatar,Tooltip} from 'antd'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash,faEdit,faClock,faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import styled from 'styled-components'

const CardStyle = styled(Card)`
    border-radius:10px;
    margin:10px;
    padding:10px;
    :hover{
        background:aliceblue;
    }
    & .messageBox{
    cursor:pointer;
    }
`;

const FontAwesomeIconStyle = styled(FontAwesomeIcon)`
    margin-right:10px;
    cursor:pointer;
`

const FollowUpComment = (props) => {
    console.log("props ", props)
    const {followUpComment:{id,message,userProfileImages,create_time,create_user},
        handleCommentUpdate,
        handleCommentDelete,
        hideUpdate = false,
        hideDelete = false } = props

    const { auth:{authUser} } = useSelector(props=>props)

    const IconMessage = (props) =>{
        const {toolTipPlacement,toolTipTitle,toolTipTigger,fontIcon,fontSize,message} = props
        
        return (
            <span style={{ marginRight: 20 }}>
                <Tooltip placement={toolTipPlacement} title={toolTipTitle} trigger={toolTipTigger}>
                    <FontAwesomeIconStyle icon={fontIcon} size={fontSize} />{message}
                </Tooltip>
            </span>
            )
    }
    
    return (
    <CardStyle>
        <div className="messageBox">
            <Row>
                <Col span={2}>
                    <Avatar
                        shape="square"
                        size={32}
                        src={userProfileImages}
                    />
                </Col>
                <Col span={20}>
                    <div style={{ textIndent: 25,whiteSpace:"pre-line", minHeight:70 }}>{message}</div>
                </Col>
            </Row>
        </div>
        <Divider />
        <div className="messageFooter">
            <IconMessage
                toolTipPlacement="top"
                toolTipTitle="Message Time"
                toolTipTigger="hover|click"
                fontIcon={faClock}
                fontSize="sm"
                message={create_time}
            />
            <IconMessage
                toolTipPlacement="top"
                toolTipTitle="Entry by"
                toolTipTigger="hover|click"
                fontIcon={faPencilAlt}
                fontSize="sm"
                message={create_user}
            />
                {authUser.user_name === create_user &&
            <span style={{ float: "right" }}>
                        {hideUpdate ? "" :
                <FontAwesomeIconStyle
                    icon={faEdit}
                    size="lg"
                    style={{ marginRight: 10 }}
                    onClick={() => handleCommentUpdate(id)}
                />
                        }
                        {hideDelete ? "" :
                <Popconfirm
                    title="Are you confirm to delete?"
                    onConfirm={() => handleCommentDelete(id)}
                                cancelButtonProps={{ style: { paddingTop: 0 } }}
                >
                    <FontAwesomeIconStyle icon={faTrash} size="lg" style={{ marginRight: 10 }} />
                </Popconfirm>
                        }
                    </span>
                }
        </div>
    </CardStyle>
    )
}

export default FollowUpComment  