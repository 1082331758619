import * as actions from "./roomStatusActionType"

export const roomStatusPreview = (payload?: any): actions.RoomStatusRequest => {
  return {
    type: actions.ROOM_STATUS_REQUEST,
    payload
  }
}

export const roomStatusProcessSuccess = (payload:any):actions.RoomStatusProcessSuccess =>{
  return{
    type: actions.ROOM_STATUS_PROCESS_SUCCESS,
    payload
  }
}

export const roomStatusDataSourceSuccess = (payload:any):actions.RoomStatusDataSourceSuccess => {
  return {
    type: actions.ROOM_STATUS_DATASOURCE_SUCCESS,
    payload
  }
}

export const roomStatusRawDataSource = (payload:any):actions.RoomStatusRawDataSource => {
  return {
    type: actions.ROOM_STATUS_RAW_DATASOURCE,
    payload
  }
}

export const roomStatusFilterRawData = (payload:any):actions.RoomStatusFilterRawData => {
  return {
    type: actions.ROOM_STATUS_FILTER_RAWDATA,
    payload
  }
}


export const roomStatusSetFilter = (payload:any):actions.RoomStatusSetFilter => {
  return {
    type: actions.ROOM_STATUS_SET_FILTER,
    payload
  }
}
export const roomStatusCheckIn = (value:number):actions.RoomStatusCheckIn=>{
  return{
    type:actions.ROOM_STATUS_CHECK_IN,
    payload:value
  }
}

export const roomStatusCancelCheckIn = (value:any):actions.RoomStatusCancelCheckIn =>{
  return{
    type:actions.ROOM_STATUS_CANCEL_CHECK_IN,
    payload:value
  }
}
export const roomStatusCheckOut = (value:number):actions.RoomStatusCheckOut=>{
  return{
    type:actions.ROOM_STATUS_CHECK_OUT,
    payload:value
  }
}

export const roomStatusCancelCheckOut = (value:any):actions.RoomStatusCancelCheckOut =>{
  return{
    type:actions.ROOM_STATUS_CANCEL_CHECK_OUT,
    payload:value
  }
}
export const roomStatusChangeGroupView = (value:any):actions.RoomStatusChangeGroupView =>{
  return{
    type:actions.ROOM_STATUS_CHANGE_GROUP_VIEW,
    payload:value
  }
}
export const RoomStatusExtraOptional = (value:any):actions.RoomStatusExtraOptional =>{
  return{
    type:actions.ROOM_STATUS_EXTRA_OPTIONAL,
    payload:value
  }
}
export const RoomStatusSelectionMode = (value:any):actions.RoomStatusSelectionMode =>{
  return{
    type:actions.ROOM_STATUS_SELECTION_MODE,
    payload:value
  }
}
export const RoomStatusTargetSelected = (value:any):actions.RoomStatusTargetSelected=>{
  return{
    type:actions.ROOM_STATUS_TARGET_SELECTED,
    payload:value
  }
}

export const roomStatusUpdate = (value:any):actions.RoomStatusUpdate =>{
  return{
    type: actions.ROOM_STATUS_UPDATE,
    payload:value
  }
}
export const roomStatusMaidUpdate = (value:any):actions.RoomStatusMaidUpdate =>{
  return{
    type: actions.ROOM_STATUS_MAID_UPDATE,
    payload:value
  }
}
export const roomStatusBedUpdate = (value:any):actions.RoomStatusBedUpdate =>{
  return{
    type: actions.ROOM_STATUS_BED_UPDATE,
    payload:value
  }
}
export const roomStatusOutOfOrderNew = (value:any):actions.RoomStatusOutOfOrderNew =>{
  return{
    type: actions.ROOM_STATUS_OUT_OF_ORDER_NEW,
    payload:value
  }
}
export const roomStatusOutOfOrderUpdate = (value:any):actions.RoomStatusOutOfOrderUpdate =>{
  return{
    type: actions.ROOM_STATUS_OUT_OF_ORDER_UPDATE,
    payload:value
  }
}
export const roomStatusOutOfOrderRemove = (value:any):actions.RoomStatusOutOfOrderRemove =>{
  return{
    type: actions.ROOM_STATUS_OUT_OF_ORDER_REMOVE,
    payload:value
  }
}
export const roomStatusRemarkUpdate = (value:any):actions.RoomStatusRemarkUpdate=>{
  return{
    type: actions.ROOM_STATUS_REMARK_UPDATE,
    payload:value
  }
}
export const roomStatusDataCheckinListRequest = (value:any):actions.RoomStatusDataCheckinListRequest=>{
  return{
    type: actions.ROOM_STATUS_DATALIST_CHECKIN_REQUEST,
    payload:value
  }
}
export const roomStatusDataCheckinListDataSource = (value:any):actions.RoomStatusDataCheckinListDataSource=>{
  return{
    type: actions.ROOM_STATUS_DATALIST_CHECKIN_DATASOURCE,
    payload:value
  }
}

export const roomStatusProcessReset = () =>{
  return{
    type:actions.ROOM_STATUS_PROCESS_RESET
  } 
}
