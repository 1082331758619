// eslint-disable-next-line
import React, { useEffect, useRef, useState} from "react"
import { useDispatch, useSelector} from "react-redux"
import { Button, message } from "antd"
import { Link } from 'react-router-dom'
import { Formik } from "formik"
import { Form,Input} from "formik-antd"
import * as yup from 'yup'
import {footerText} from "util/config"
//import IntlMessages from "util/IntlMessages"

import {hideMessage,verifyOTP,resetProcessStatus} from "appRedux/actions/Auth"
import {isEmpty} from 'lodash'
import { AppState } from 'ReducerState'
import styled from "styled-components"

export const DivBlockWarning = styled.div`
    font-size: 12px;
    font-style: italic;
    color: red;
    font-weight: lighter;
`

const OTPVerify =({history}:any)=>{

  const [initialData] = useState<any>({otp_number:undefined,secret_key:undefined})
  const { auth } = useSelector((state:AppState)=> state) 
  const { showMessage,loader, alertMessage,processStatus,otpGenerate,activateUID } = auth
  
  const dispatch = useDispatch()
  const mounted = useRef<any>(undefined)

  useEffect(()=>{
    if(isEmpty(otpGenerate)) history.push("/signin")
  },[otpGenerate,history])

  useEffect(()=>{
		if (!mounted.current) {
      // do componentDidMount logic
      mounted.current = true
    } else {
      // do componentDidUpdate logic
      if(processStatus){
        const {activate_uid,grant_type} = activateUID
        const urlRedirect = `/activate/?uid=${activate_uid}&gt=${grant_type}&ac=OTP`

        setTimeout(() =>{ history.push(urlRedirect)},1000)
      }else if(showMessage){
       // message.error(alertMessage.toString())
        message.error((alertMessage as any).toString())
        setTimeout(() => { dispatch(hideMessage())}, 100)
      }
      dispatch(resetProcessStatus())
    }
  },[processStatus,dispatch,showMessage,alertMessage,activateUID,history]) 

  const handleSubmit = (value:any) => {
    const { secret_key} = otpGenerate
    const dataInfo = {otp_number:value.otp_verify,secret_key}
    dispatch(verifyOTP(dataInfo))
    
  }
  let schema = yup.object({
    otp_verify:yup.string().required("Please Input OTP Number").matches(RegExp("^[0-9]*$"),"Please input only number")
  })
  return(
    <div className="gx-login-container">
        <div className="gx-login-content">
          <div className="gx-login-header">
          <Link to="/"><img src={require("assets/images/logo-white.png")} alt="fromas" title="fromas"/></Link>
          </div>
          <div className="gx-mb-4">
            <h2>OTP Verify</h2>
            {/* <p>Ref no : {otpGenerate?.referent_no}</p> */}
            <p>Ref no : {(otpGenerate as any)?.referent_no}</p>
            <small>Please wait we will sending OTP to you.</small>
            <small>This code will expire in 5 minute</small>
          </div>
          <Formik
                enableReinitialize
                initialValues={initialData}
                validationSchema={schema}
                onSubmit={handleSubmit}
            >  
            {({errors,touched})=>
            <Form className="gx-login-form gx-form-row0">
            <div style={{marginBottom:20}}>
                <Input name='otp_verify' type="text" placeholder="Activate OTP Number" />
                {errors?.otp_verify && touched.otp_verify && <DivBlockWarning>{errors.otp_verify}</DivBlockWarning>}
              </div>
              <Button type="primary" htmlType="submit" loading={loader} >Submit</Button>
          </Form>
          }
          </Formik>
          <span className="gx-text-light gx-fs-sm">{footerText.copyright}</span>
        </div>
        
      </div>
  )
}
export default OTPVerify


