// eslint-disable-next-line
import React,{ useEffect} from 'react'
import { message,Tag,Avatar,Menu,Dropdown } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import {AppState} from "appRedux/reducers/ReducerState"
import { changeWorkShift,resetProcessStatus } from 'appRedux/actions/Auth'
import styled from "styled-components"
import permissionVerify from 'util/permissionVerify'

const TagCustom = styled(Tag)`
    display:flex;
    align-items:center;
    margin-bottom:0;
    padding:4px;
    cursor: pointer;
    & span{
        margin-right:5px;
        font-size:14px; 
    }
`

export const CurrentTag: React.FC = () => {

    const {auth:{authUser,process}} = useSelector((state:any) => state)
    const systemConfig = useSelector((state:AppState)=>state.systemConfig)
    const { dataSource:dataSourceConfig } = systemConfig

    const dispatch = useDispatch()

    const WORKING_SHIFT = dataSourceConfig?.SYSTEM?.SHIFTS||[]
    const CURRENT_SHIFT = authUser?.current_shift||""
    const user_id = authUser?.user_id
    const prop_id =  authUser?.prop_id

    const menu = () => {
        return (
                <>
                {permissionVerify("30001") &&
                    <Menu onClick={onHandleChange} style={{float:"right"}}>
                        {WORKING_SHIFT.map((obj:any)=><Menu.Item key={obj}>Shift {obj}</Menu.Item>)}
                    </Menu>
                }
                </>
            )
        }

        const onHandleChange = (value:any) =>{
            dispatch(changeWorkShift({workShift:value?.key,user_id,prop_id}))
        }
        
    useEffect(()=>{    
        const action_validate = ["authChangeWorkShift"]
        const error_code = ["1","2","4","ERROR"]
        if(action_validate.includes(process?.action)){
            if((process.status && !error_code.includes(process.result_code)) )
                message.success(process.message.toString())
            else 
                message.error(process.message.toString())
            
            setTimeout(() => {dispatch(resetProcessStatus())},100)
        }
    },[process,dispatch])

        return (
            <Dropdown 
                overlay={menu()}   
                placement="bottomCenter"
                trigger={["click"]}
                >
            <TagCustom>
                <span> Current Shift</span>
                <Avatar size={16} style={{backgroundColor:"#60bd60"}}>
                    {CURRENT_SHIFT.toUpperCase()}
                </Avatar>
            </TagCustom>
            </Dropdown>
        )
}
export default CurrentTag


