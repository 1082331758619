import * as actions from "./folioEditActionType"

export const folioEditPreview = (payload: number | string): actions.FolioEditRequest => {
  return {
    type: actions.FOLIO_EDIT_REQUEST,
    payload
  }
}

export const folioEditUpdate = (payload?: any): actions.FolioEditUpdate => {
  return {
    type: actions.FOLIO_EDIT_UPDATE,
    payload
  }
}

export const folioEditProcessSuccess = (payload: any): actions.FolioEditProcessSuccess => {
  return {
    type: actions.FOLIO_EDIT_PROCESS_SUCCESS,
    payload
  }
}

export const folioEditDataSourceSuccess = (payload: any): actions.FolioEditDataSourceSuccess => {
  return {
    type: actions.FOLIO_EDIT_DATASOURCE_SUCCESS,
    payload
  }
}
export const folioEditProcessReset = () => {
  return {
    type: actions.FOLIO_EDIT_PROCESS_RESET
  }
}
