import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  SOCIAL_PREVIEW,
  SOCIAL_NEW,
  SOCIAL_UPDATE,
  SOCIAL_REMOVE,
  SOCIAL_SEARCH
} from "constants/ActionTypes"

import {socialDataSourceUpdate,socialProcessSuccess,socialProcessReset} from "appRedux/actions/setup/miscellaneous/Social"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy,isEmpty } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* socialPreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/socialMedia/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const socialDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key+1
                    container.social_id = obj.id
                    container.social_name = obj.name
                    container.social_url = obj.url||""
                    container.social_icon = obj.icon||""
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(socialDataSourceUpdate(socialDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreSocialList",socialDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(socialProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on socialMedia preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(socialProcessSuccess(processStatus))
    }
    
}

function* socialSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreSocialList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.social_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(socialDataSourceUpdate(mergeData))
  }else 
      yield put(socialDataSourceUpdate(ReformalData))
}

function* socialCreateProcess({payload}){
  
  const { social_name,social_url, enabled,social_icon } = payload
  const socialInfo = {}
        socialInfo.name = social_name
        socialInfo.url = social_url
         if (!isEmpty(social_icon)) {
          const dataSplit = social_icon.split(",")
          socialInfo.icon_type = dataSplit[0]
          socialInfo.icon = dataSplit[1]
          
        }
        socialInfo.enabled = enabled
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token  
            const url = `${API_URL_V1}pms/masterfile/socialMedia/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = socialInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(socialProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(socialProcessSuccess(processStatus))
              yield put(socialProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(socialProcessSuccess(processStatus))
            yield put(socialProcessReset())
      }
}

function* socialUpdateProcess({payload}){

  const { social_id,social_name,social_url, enabled,social_icon } = payload
  const socialInfo = {}
        socialInfo.id = social_id
        socialInfo.name = social_name
        socialInfo.url = social_url
        if (!isEmpty(social_icon)) {
          const dataSplit = social_icon.split(",")
          socialInfo.icon_type = dataSplit[0]
          socialInfo.icon = dataSplit[1]
          
        }
        socialInfo.enabled = enabled

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/socialMedia/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = socialInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(socialProcessSuccess(processStatus))
            yield put(socialProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(socialProcessSuccess(processStatus))
          yield put(socialProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(socialProcessSuccess(processStatus))
        yield put(socialProcessReset())
  }
}

function* socialRemoveProcess({payload}){
    const occupationRemoveID = payload
    

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/socialMedia/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : occupationRemoveID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(socialProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(socialProcessSuccess(processStatus))
          yield put(socialProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(socialProcessSuccess(processStatus))
      yield put(socialProcessReset())
    }
}

/* Watcher Saga */
export function* socialPreview() {
  yield takeLatest(SOCIAL_PREVIEW, socialPreviewProcess)
}
export function* socialSearch(){
  yield takeLatest(SOCIAL_SEARCH, socialSearchProcess)
}
export function* socialCreate(){
  yield takeEvery(SOCIAL_NEW,socialCreateProcess)
}
export function* socialUpdate(){
  yield takeEvery(SOCIAL_UPDATE, socialUpdateProcess)
}
export function* socialRemove(){
  yield takeEvery(SOCIAL_REMOVE,socialRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(socialPreview),
             fork(socialCreate),
             fork(socialUpdate),
             fork(socialRemove)])
}
