import * as actions from "./IssueCardActionType"

export const issueCardPreview = (payload?: any): actions.IssueCardRequest => {
  return {
    type: actions.ISSUE_CARD_REQUEST,
    payload
  }
}
export const issueCardDatasouceSuccess = (payload:any):actions.IssueCardDataSourceSuccess=>{
  return{
    type: actions.ISSUE_CARD_DATASOURCE_SUCCESS,
    payload
  }
}

export const issueCardProcessSuccess = (payload:any):actions.IssueCardProcessSuccess =>{
  return{
    type: actions.ISSUE_CARD_PROCESS_SUCCESS,
    payload
  }
}

export const issueCardProcessReset = ():actions.IssueCardProcessReset =>{
  return{
    type: actions.ISSUE_CARD_PROCESS_RESET
  } 
}

export const issueCardReset = ():actions.IssueCardReset =>{
  return{
    type: actions.ISSUE_CARD_RESET
  } 
}

export const issueCardNewCard = (payload:any):actions.IssueCardNewCard =>{
  return{
    type: actions.ISSUE_CARD_NEW_CARD,
    payload
  }
}
export const issueCardCopyCard = (payload:any):actions.IssueCardCopyCard =>{
  return{
    type: actions.ISSUE_CARD_COPY_CARD,
    payload
  }
}

export const issueCardSetNextCard  = (payload:any):actions.IssueCardSetNextCard =>{
  return{
    type: actions.ISSUE_CARD_SET_NEXT_CARD,
    payload
  }
}

export const issueCardSetNextCardReset = ():actions.IssueCardSetNextCardReset =>{
  return{
    type: actions.ISSUE_CARD_SET_NEXT_CARD_RESET
  }
}