export const USER_LOGS_REQUEST = "USER_LOGS_REQUEST"

export const USER_LOGS_PROCESS_SUCCESS = "USER_LOGS_PROCESS_SUCCESS"
export const USER_LOGS_DATASOURCE_SUCCESS = "USER_LOGS_DATASOURCE_SUCCESS"
export const USER_LOGS_DETAIL_DATASOURCE_SUCCESS = "USER_LOGS_DETAIL_DATASOURCE_SUCCESS"
export const USER_LOGS_SET_FILTER = "USER_LOGS_SET_FILTER"
export const USER_LOGS_PROCESS_RESET = "USER_LOGS_PROCESS_RESET"


export interface UserLogsRequest {
  type: typeof USER_LOGS_REQUEST,
  payload:any
}

export interface UserLogsProcessSuccess {
  type: typeof USER_LOGS_PROCESS_SUCCESS,
  payload:{}
}
export interface UserLogsDataSourceSuccess{
  type: typeof USER_LOGS_DATASOURCE_SUCCESS,
  payload:any
}
export interface UserLogsDetailDataSourceSuccess{
  type: typeof USER_LOGS_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface UserLogsSetFilter{
  type: typeof USER_LOGS_SET_FILTER,
  payload:any
}
export interface UserLogsProcessReset {
  type: typeof USER_LOGS_PROCESS_RESET
}

export type UserLogsAction = 
| UserLogsRequest
| UserLogsProcessSuccess
| UserLogsDataSourceSuccess
| UserLogsDetailDataSourceSuccess
| UserLogsSetFilter
| UserLogsProcessReset
