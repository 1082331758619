import { all, fork, put,takeEvery, call ,select}  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/houseKeeping/roomStatusActionType"
import * as actionCreators from "appRedux/actions/houseKeeping/roomStatus"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { AppState } from "ReducerState"

function* roomStatusRequestProcess({ payload }: actionTypes.RoomStatusRequest) {

    let keywords = payload
    const roomStatusInfo = {} as any
    if(keywords.searchText) 
      roomStatusInfo.search_text = keywords.searchText
    if(keywords.startDate)
      roomStatusInfo.arrival = moment(keywords.startDate).format('YYYY-MM-DD')
    if(keywords.endDate)
      roomStatusInfo.departure = moment(keywords.endDate).format('YYYY-MM-DD')
    if(keywords.bedType)
      roomStatusInfo.bedtype_id= keywords.bedType
    if(keywords.roomType)
      roomStatusInfo.roomtype_id= keywords.roomType
    if(keywords.location)
      roomStatusInfo.location_id= keywords.location
    if(keywords.building)
      roomStatusInfo.building_id= keywords.building
    if(keywords.exposure)
      roomStatusInfo.exposure_id= keywords.exposure
    if(keywords.facilities)
      roomStatusInfo.faciliites= keywords.facilities
    if(keywords.connectCheck)
      roomStatusInfo.connecting= keywords.connectCheck
    if(keywords.connect)
      roomStatusInfo.connecting_room= keywords.connect

      roomStatusInfo.room_activities = true

    /* Create query string from array object */
    let queryString = Object.keys(roomStatusInfo).map(key =>`${key}=${roomStatusInfo[key]}`).join('&')

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/utility/room/search/?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
            const {results} = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusRawDataSource(results))
                yield put(actionCreators.roomStatusSetFilter({...payload})) 
                const processStatus = { status: true, action: "preview", message:""}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusFilterRawDataProcess({payload}: actionTypes.RoomStatusFilterRawData){
  //yield console.log("filterd ",payload)
  const {statusVAC,statusOCC,statusOOO,statusOOS,statusOOI,
    maidDirty,maidIn,maidClean,maidInspected,maidPickup} = payload
  const {rawDataSource} = yield select((state:AppState) => state.roomStatus)
  //yield console.log("Raw data ",rawDataSource)
  
  let roomFilterCheck = [] as any
  let maidFilterCheck = [] as any
  if(statusVAC) roomFilterCheck.push("VAC")
  if(statusOCC) roomFilterCheck.push("OCC")
  if(statusOOO) roomFilterCheck.push("OOO")
  if(statusOOS) roomFilterCheck.push("OOS")
  if(statusOOI) roomFilterCheck.push("OOI")

  if(maidDirty) maidFilterCheck.push("DI")
  if(maidIn) maidFilterCheck.push("MI")
  if(maidClean) maidFilterCheck.push("CL")
  if(maidInspected) maidFilterCheck.push("IS")
  if(maidPickup) maidFilterCheck.push("PU")

  const dataReform = rawDataSource.filter((obj:any)=> {
      if(roomFilterCheck.length > 0 && maidFilterCheck.length >0)
        return (roomFilterCheck.includes(obj.room_status) && maidFilterCheck.includes(obj.hk_status))
      else if(roomFilterCheck.length > 0) return roomFilterCheck.includes(obj.room_status)
      else if(maidFilterCheck.length > 0) return maidFilterCheck.includes(obj.hk_status)
      else return obj
    })

  //yield console.log("Final ",dataReform)  
  yield put(actionCreators.roomStatusDataSourceSuccess(dataReform))
  
}
function* roomStatusUpdateProcess({payload}: actionTypes.RoomStatusUpdate){
  //yield console.log("update saga ",payload)
  const {newStatus,maidNewStatus,returnStatus,reasonID,remark,datePeriod} = payload
  
  const {roomTargetSelected} = yield select((state:AppState) => state.roomStatus)
  const roomStatusUpdateInfo = {} as any
  roomStatusUpdateInfo.room_ids = roomTargetSelected.map((obj:any)=>obj.id)
  roomStatusUpdateInfo.return_status = returnStatus||null
  if(newStatus) 
    roomStatusUpdateInfo.new_status = newStatus
  if(maidNewStatus) 
    roomStatusUpdateInfo.new_hkstatus= maidNewStatus
  if(reasonID)
    roomStatusUpdateInfo.reason_id = reasonID
  if(datePeriod){
    roomStatusUpdateInfo.start_date = moment(datePeriod[0]).format("YYYY-MM-DD")
    roomStatusUpdateInfo.end_date = moment(datePeriod[1]).format("YYYY-MM-DD")
  }
  if(remark)
    roomStatusUpdateInfo.remark= remark

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/housekeeping/roomStatus/updateStatus/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIPostRequest(url,roomStatusUpdateInfo,API_PMS_HEADERS)
        //console.log("resposne ",response)
        if(response.status === 200)
        {      
                const {result_code,result_msg,result_data} = response.data
                const processStatus = { status: true, action: "roomStatusUpdate", message:result_msg,result_code,result_data}
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

                yield put(actionCreators.roomStatusProcessReset())
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "roomStatusUpdate", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status roomStatusUpdate : ",error)
        const processStatus = { status: false, action: "roomStatusUpdate", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusBedUpdateProcess({payload}: actionTypes.RoomStatusBedUpdate){

  const {roomTargetSelected} = yield select((state:AppState) => state.roomStatus)
  const roomBedTypeUpdateInfo = {} as any
        roomBedTypeUpdateInfo.room_ids = roomTargetSelected.map((obj:any)=>obj.id)
        roomBedTypeUpdateInfo.bedtype_id = payload.bedTypeID

  const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/housekeeping/roomStatus/updateBedType/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIPostRequest(url,roomBedTypeUpdateInfo,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const {result_code,result_msg,result_data} = response.data
                const processStatus = { status: true, action: "roomStatusBedTypeUpdate", message:result_msg,result_code,result_data}
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "roomStatusBedTypeUpdate", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status roomStatusBedTypeUpdate : ",error)
        const processStatus = { status: false, action: "roomStatusBedTypeUpdate", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusOutOfOrderNewProcess({payload}: actionTypes.RoomStatusOutOfOrderNew){
  const {room_id,oooType,reasonID,remark,returnStatus,datePeriod} = payload

    const oooInfo = {} as any
          oooInfo.room_id = room_id
          oooInfo.ooo_type= oooType
          oooInfo.reason_id = reasonID
          oooInfo.start_date = moment(datePeriod[0]).format("YYYY-MM-DD")
          oooInfo.end_date = moment(datePeriod[1]).format("YYYY-MM-DD")
          oooInfo.return_status = returnStatus||null
    if(remark)
      oooInfo.remark= remark

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/housekeeping/roomStatus/createOutOfOrder/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIPostRequest(url,oooInfo,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const {result_code,result_msg} = response.data
                const processStatus = { status: true, action: "roomOOONew", message:result_msg,result_code}
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "roomOOONew", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status update OOO : ",error)
        const processStatus = { status: false, action: "roomOOONew", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusOutOfOrderUpdateProcess({payload}: actionTypes.RoomStatusOutOfOrderUpdate){
  const {oooID,oooType,reasonID,remark,returnStatus,datePeriod} = payload

    const oooInfo = {} as any
          oooInfo.ooo_id = oooID
          oooInfo.ooo_type= oooType
          oooInfo.reason_id = reasonID
          oooInfo.start_date = moment(datePeriod[0]).format("YYYY-MM-DD")
          oooInfo.end_date = moment(datePeriod[1]).format("YYYY-MM-DD")
          oooInfo.return_status = returnStatus||null
    if(remark)
      oooInfo.remark= remark

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/housekeeping/roomStatus/updateOutOfOrder/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIPostRequest(url,oooInfo,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const {result_code,result_msg} = response.data
                const processStatus = { status: true, action: "roomOOOUpdate", message:result_msg,result_code,reason_id:reasonID}
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "roomOOOUpdate", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status update OOO : ",error)
        const processStatus = { status: false, action: "roomOOOUpdate", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusOutOfOrderRemoveProcess({payload}: actionTypes.RoomStatusOutOfOrderRemove){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/housekeeping/roomStatus/deleteOutOfOrder/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    const API_DATABODY = {ooo_id:payload}

    try {
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const {result_code,result_msg} = response.data
                const processStatus = { status: true, action: "roomOOORemove", message:result_msg,result_code}
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "roomOOORemove", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status update OOO : ",error)
        const processStatus = { status: false, action: "roomOOORemove", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}
function* roomStatusCancelCheckInProcess({payload}: actionTypes.RoomStatusCancelCheckIn){
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/cancelCheckIn/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = {register_id:payload}
    const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code,result_msg} = response.data
      const processStatus = { status: true, action: "roomstatusCancelCheckIn", message:result_msg,result_code}
      yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }else { 
      const {message} = response.data
      const processStatus = { status: false, action: "roomstatusCancelCheckIn", message}
      yield put(actionCreators.roomStatusProcessSuccess(processStatus))
      if(response.status === 403){
        const {data} = response.data
        
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "roomstatusCancelCheckIn", message:error}
    yield put(actionCreators.roomStatusProcessSuccess(processStatus))
  }
}
function* roomStatusCheckOutProcess({payload}: actionTypes.RoomStatusCheckOut){
  try {
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/checkOut/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    const API_DATABODY  = {register_id:payload}
    const response:ApiResponse  = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

    if(response.status === 200){
      const {result_code,result_msg} = response.data
      const processStatus = { status: true, action: "roomStatusCheckOut", message:result_msg,result_code}
      yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }else { 
      const {message} = response.data
      const processStatus = { status: false, action: "roomStatusCheckOut", message}
      yield put(actionCreators.roomStatusProcessSuccess(processStatus))
      if(response.status === 403){
        const {data} = response.data
        
        /* if data is not null force signout */
        if(data)
          yield put(userSignOut())
      }
    }
  } catch (error) {
    const processStatus = { status: false, action: "roomStatusCheckOut", message:error}
    yield put(actionCreators.roomStatusProcessSuccess(processStatus))
  }
}
function* roomStatusRemarkUpdateProcess({payload}: actionTypes.RoomStatusRemarkUpdate){
  const remarkInfo = {} as any
        remarkInfo.id = payload?.id
        remarkInfo.remark = payload?.remark

        try {
          const url = `${API_URL_V1}pms/masterfile/roomInfo/update`
          const  { access_token }  = yield call(getItems, ['access_token'])
          const AccessToken = access_token
          API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
          const API_DATABODY  = remarkInfo
          const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
      
          if(response.status === 200){

            const {message} = response
            const processStatus = { status: true, action: "roomStatusRemarkupdate", message,rerult_code:"0"}
            yield put(actionCreators.roomStatusProcessSuccess(processStatus))
          }else { 
            console.log("check error remark update ",response)
            const {message} = response.data
            const processStatus = { status: false, action: "roomStatusRemarkupdate", message}
            yield put(actionCreators.roomStatusProcessSuccess(processStatus))
            if(response.status === 403){
              const {data} = response.data
              
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
          } 
        } catch (error) {
          const processStatus = { status: false, action: "roomStatusRemarkupdate", message:error}
          yield put(actionCreators.roomStatusProcessSuccess(processStatus))
        }
}
function* roomStatusDataCheckinListRequestProcess({payload}: actionTypes.RoomStatusDataCheckinListRequest){
  //console.log("Load list booking id ",payload)
  const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/roomList/${payload}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
            const results = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.roomStatusDataCheckinListDataSource({...results,status:true}))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "loadGuestListCheckin", message}
                yield put(actionCreators.roomStatusProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on room status get RoomListData : ",error)
        const processStatus = { status: false, action: "loadGuestListCheckin", message:error}
        yield put(actionCreators.roomStatusProcessSuccess(processStatus))
    }
}

function* roomStatusRequest() {
  yield takeEvery(actionTypes.ROOM_STATUS_REQUEST, roomStatusRequestProcess)
}
function* roomStatusFilterRawData() {
  yield takeEvery(actionTypes.ROOM_STATUS_FILTER_RAWDATA, roomStatusFilterRawDataProcess)
}
function* roomStatusUpdate(){
  yield takeEvery(actionTypes.ROOM_STATUS_UPDATE,roomStatusUpdateProcess)
}
function* roomStatusBedUpdate(){
  yield takeEvery(actionTypes.ROOM_STATUS_BED_UPDATE,roomStatusBedUpdateProcess)
}
function* roomStatusOutOfOrderNew(){
  yield takeEvery(actionTypes.ROOM_STATUS_OUT_OF_ORDER_NEW,roomStatusOutOfOrderNewProcess)
}
function* roomStatusOutOfOrderUpdate(){
  yield takeEvery(actionTypes.ROOM_STATUS_OUT_OF_ORDER_UPDATE,roomStatusOutOfOrderUpdateProcess)
}
function* roomStatusOutOfOrderRemove(){
  yield takeEvery(actionTypes.ROOM_STATUS_OUT_OF_ORDER_REMOVE,roomStatusOutOfOrderRemoveProcess)
}
function* roomStatusCancelCheckIn(){
  yield takeEvery(actionTypes.ROOM_STATUS_CANCEL_CHECK_IN,roomStatusCancelCheckInProcess)
}
function* roomStatusCheckOut(){
  yield takeEvery(actionTypes.ROOM_STATUS_CHECK_OUT,roomStatusCheckOutProcess)
}
function* roomStatusRemarkUpdate(){
  yield takeEvery(actionTypes.ROOM_STATUS_REMARK_UPDATE,roomStatusRemarkUpdateProcess)
}
function* roomStatusDataCheckinListRequest(){
  yield takeEvery(actionTypes.ROOM_STATUS_DATALIST_CHECKIN_REQUEST,roomStatusDataCheckinListRequestProcess)
}

export default function* rootSaga() {
  yield all([fork(roomStatusRequest),
            fork(roomStatusFilterRawData),
            fork(roomStatusUpdate),
            fork(roomStatusBedUpdate),
            fork(roomStatusOutOfOrderNew),
            fork(roomStatusOutOfOrderUpdate),
            fork(roomStatusOutOfOrderRemove),
            fork(roomStatusCancelCheckIn),
            fork(roomStatusCheckOut),
            fork(roomStatusRemarkUpdate),
            fork(roomStatusDataCheckinListRequest)
  ])
}


