export const ROOMPLAN_RESOURCE_REQUEST = "ROOMPLAN_RESOURCE_REQUEST"
export const ROOMPLAN_RESOURCE_DATASOURCE_SUCCESS = "ROOMPLAN_RESOURCE_DATASOURCE_SUCCESS"
export const ROOMPLAN_RESOURCE_PROCESS_SUCCESS = "ROOMPLAN_RESOURCE_PROCESS_SUCCESS"

export const ROOMPLAN_RESOURCE_RAW_DATASOURCE = "ROOMPLAN_RESOURCE_RAW_DATASOURCE"
export const ROOMPLAN_RESOURCE_FILTER_RAWDATA = "ROOMPLAN_RESOURCE_FILTER_RAWDATA"
export const ROOMPLAN_RESOURCE_SET_FILTER = "ROOMPLAN_RESOURCE_SET_FILTER"

export const ROOMPLAN_RESOURCE_PROCESS_RESET = "ROOMPLAN_RESOURCE_PROCESS_RESET"
export const ROOMPLAN_RESOURCE_RESET = "ROOMPLAN_RESOURCE_RESET"


export interface RoomPlanResourceRequest {
    type: typeof ROOMPLAN_RESOURCE_REQUEST,
    payload?: any
}
export interface RoomPlanResourceRawDataSourceSuccess {
    type: typeof ROOMPLAN_RESOURCE_RAW_DATASOURCE,
    payload: any
}
export interface RoomPlanResourceFilterRawData {
    type: typeof ROOMPLAN_RESOURCE_FILTER_RAWDATA,
    payload: any
}
export interface RoomPlanResourceSetFilter {
    type: typeof ROOMPLAN_RESOURCE_SET_FILTER,
    payload: any
}
export interface RoomPlanResourceDataSourceSuccess {
    type: typeof ROOMPLAN_RESOURCE_DATASOURCE_SUCCESS,
    payload: any
}
export interface RoomPlanResourceProcessSuccess {
    type: typeof ROOMPLAN_RESOURCE_PROCESS_SUCCESS,
    payload: {}
}
export interface RoomPlanResourceProcessReset {
    type: typeof ROOMPLAN_RESOURCE_PROCESS_RESET
}
export interface RoomPlanResourceReset {
    type: typeof ROOMPLAN_RESOURCE_RESET
}

export type RoomPlanResourceAction =
    | RoomPlanResourceRequest
    | RoomPlanResourceRawDataSourceSuccess
    | RoomPlanResourceFilterRawData
    | RoomPlanResourceSetFilter
    | RoomPlanResourceDataSourceSuccess
    | RoomPlanResourceProcessSuccess
    | RoomPlanResourceProcessReset
    | RoomPlanResourceReset