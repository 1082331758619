import React from 'react'
import { Modal,Row,Col,message} from 'antd'
import FollowUpMessage from "components/FollowUpMessage"
import FollowUpMessageForm from "components/FollowUpMessage/form"
import FollowUpComment from "components/FollowUpMessage/comment"
import FollowUpCommentForm from "components/FollowUpMessage/comment/form"
import { useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { followUpPreview,followUpCreate,followUpUpdate,followUpRemove,
         followUpCommentNew,followUpCommentUpdate,followUpCommentRemove,followUpUpdateSeen } from "appRedux/actions/FollowUp"

import {orderBy,isEmpty} from "lodash"
import * as yup from "yup"

export default function FollowUp({reservationID , isOpen, onChangeModal,notikey}) {

    const [visible, setVisible] = useState()
    const [visibleCommentColumn,setVisibleCommentColumn] = useState(false)
    const [visibleCommentCard,setVisibleCommentCard] = useState(true)

    const [initialTopicData,setInitialData] = useState({followID:undefined,
                                                        followTopic: undefined ,
                                                        followMessage:undefined,
                                                        category: undefined,
                                                        statusID: undefined,
                                                        assignUserID: [],
                                                        alertLocationID: [],
                                                        alertDate: undefined})
    const [initialCommentData,setInitialCommentData] = useState({follow_comment:"",comment_id:undefined})

    const [topicSelected,setTopicSelected] = useState({followDetail:[]})
    const [showCommentCloseButton,setShowCommentCloseButton] = useState(false)

    const [modalWidth,setModalWidth] = useState(1300)
    
    const { auth:{authUser},followUp } = useSelector(props=>props)
    const { dataSource: dataSourceCurrent, process,loader } = followUp
    const disPatch = useDispatch()
    useEffect(()=>{
      if(!isEmpty(process.action) && process.notikey===notikey){
            if(process.status){
                  message.success(process.message)
                  
                  if(process.action==="remove")
                    setVisibleCommentColumn(false)

                  if(process.action==="comment_update"){
                    setShowCommentCloseButton(false)
                    setVisibleCommentCard(true)
                    setInitialCommentData({follow_comment:"",comment_id:undefined})
                  }

                  setVisible(false)     
                  setModalWidth(1300)
                  setTimeout(() => { disPatch(followUpPreview({reservationType:"followup",id:reservationID}))},100)  
            }
            else 
                message.error(process.message)     
      }

    },[process,disPatch,reservationID,notikey])

    useEffect(() => {
      if(!isEmpty(dataSourceCurrent)){
      const followID = reservationID
      const followFilterd = dataSourceCurrent.find((obj)=>obj.followID===followID)
        if(!isEmpty(followFilterd)){ 
          setTopicSelected(followFilterd)
          setVisibleCommentColumn(true)
        }
      }
    }, [dataSourceCurrent,reservationID])

    useEffect(() => {
      if(reservationID){
        disPatch(followUpPreview({reservationType:"followup",id:reservationID}))
        setVisible(false)
        setVisibleCommentColumn(false)
        setModalWidth(1300)
      }
    },[isOpen,disPatch,reservationID])

    const handleOk = () => {
      setVisible(false)
      onChangeModal(false)
    }
  
    const handleCancel = () => {
      setVisible(false)
      onChangeModal(false)
    }

    /* Start Topic */
    const handleSelect = (data) =>{

      setVisibleCommentCard(true)
      setInitialCommentData({follow_comment:""})
      const {followID} = data
      const followFilterd = dataSourceCurrent.find((obj)=>obj.followID===followID)
      const {seen} = followFilterd
      if(!seen){
        /* Call api update seen */
        disPatch(followUpUpdateSeen(followID))
      }

      setTopicSelected(followFilterd)
      setVisibleCommentColumn(true)
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 100)
    }

    const handleUpdate = (data)=>{

      const {followID,followTopic,followMessage,followCategoryID,followStatusID,assignUserID,alertLocationID,alertDate} = data
      const alertOnlyLocationID = alertLocationID.map(o=>o.id)
      setInitialData({
        followID,
        followTopic,
        followMessage,
        category: followCategoryID,
        statusID: followStatusID||undefined,
        assignUserID: assignUserID,
        alertLocationID: alertOnlyLocationID,
        alertDate: alertDate||undefined
      })

      setVisible(true)
      setModalWidth(800)
    }

    const handleDelete = (data) =>{
      const removeFollowupTopic = { followUpRemoveID:data, notikey }
      disPatch(followUpRemove(removeFollowupTopic))
    }

    const handleSubmit = (data,{setSubmitting}) =>{

      const { follow_topic,follow_message,category,status,assign_user,alert_location,alert_date} = data
      const followTopic = {}
            followTopic.ref_id = reservationID
            followTopic.reservationType = "booking" 
            followTopic.follow_message = follow_message
            followTopic.category = category
            followTopic.status = status
            followTopic.assign_user = assign_user
            followTopic.alert_location = alert_location
            followTopic.follow_topic = follow_topic
            followTopic.alert_date = alert_date
            followTopic.notikey = notikey
          if(initialTopicData.followID){
              /* Update data */
            followTopic.follow_id = initialTopicData.followID
            disPatch(followUpUpdate(followTopic))
            setSubmitting(false)
          }else{
              disPatch(followUpCreate(followTopic))
              setSubmitting(false)
          }
    }

    const handleCloseForm = () =>{
      setVisible(false)
      setModalWidth(1300)
    }
    /* End Topic */

    /* Start Comment */
    const handleCommentSubmit = (data,{setSubmitting, resetForm }) =>{
      const {follow_comment} = data
      const {followID} = topicSelected
      const {comment_id} = initialCommentData
      if(comment_id){
         /* Update Comment */
        console.log("Not undefind")
        if(!isEmpty(follow_comment))
        {
          const commentInfo = { commentID:comment_id, message: follow_comment,notikey }
          setInitialCommentData({follow_comment,comment_id})
          disPatch(followUpCommentUpdate(commentInfo))
          setSubmitting(false)
          resetForm()
        }
      }
      else {
        /* New Comment */
        console.log("is undefind")
        if(!isEmpty(follow_comment))
        {
          const commentInfo = { followID, message: follow_comment,notikey }
          disPatch(followUpCommentNew(commentInfo))
          setSubmitting(false)
          resetForm()
        }
      }
      
    }

    const handleCommentUpdate = (data) =>{
      const {followDetail} = topicSelected
      const commentSelected = followDetail.find((obj)=>obj.id===data)
      const {id:comment_id,message} = commentSelected
      
      setShowCommentCloseButton(true)
      setVisibleCommentCard(false)
      setInitialCommentData({follow_comment:message,comment_id})
      
    }

    const handleCloseCommentForm = () =>{
        setShowCommentCloseButton(false)
        setVisibleCommentCard(true)
        setInitialCommentData({follow_comment:"",comment_id:undefined})
    }

    const handleCommentDelete = (data)=>{
      const removeComment = {commentID:data,notikey}
      disPatch(followUpCommentRemove(removeComment))
    }
    /* End Comment */

    const initialValue = {
      follow_topic: initialTopicData.followTopic,
      follow_message:initialTopicData.followMessage,
      category: initialTopicData.category,
      status: initialTopicData.statusID,
      assign_user: initialTopicData.assignUserID,
      alert_location: initialTopicData.alertLocationID,
      alert_date: initialTopicData.alertDate
    }
    let schema = yup.object({
      follow_topic: yup.string().required("Please Enter Topic"),
      follow_message: yup.string(),
      category: yup.number().required("Please Select Category"),
      status:yup.number(),
      assign_user:yup.array(),
      alert_location:yup.array(),
      alert_date: yup.date()
    })

    let validationSchemaComment = yup.object({
      follow_comment:yup.string()
    })
    const initialValueCommment = { follow_comment: initialCommentData.follow_comment }

    return (
      <>
        <Modal
          title={`Followup`}
          style={{ top: 20 }}
          visible={isOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={modalWidth}
          footer={null}
        >
          <Row gutter={12}>
            {visible ? (
              <Col span={24}>
                <FollowUpMessageForm
                  initialValue={initialValue}
                  handleSubmit={handleSubmit}
                  handleCloseForm={handleCloseForm}
                  validationSchema={schema}
                  loader={loader}
                />
              </Col>
            ) : (
              <>
                <Col span={12} style={{ borderRight: "1px solid #ccc", minHeight: "30em" }} >
                  {orderBy(dataSourceCurrent,[(o) => o.followID],["desc"]).map((obj) => (
                    <FollowUpMessage
                      {...obj}
                      hideUpdate={true}
                      hideDelete={true}
                      handleSelect={handleSelect}
                      handleUpdate={handleUpdate}
                      handleDelete={handleDelete}
                    />
                  ))}
                </Col>
                <Col span={12}>
                  {visibleCommentColumn ? (
                    <>
                      {visibleCommentCard
                        ? orderBy(topicSelected.followDetail, [o => o.create_time],["asc"]).map((obj) => (
                            <FollowUpComment
                              key={`comments-${obj.id}`}
                              followUpComment={obj}
                              handleCommentUpdate={handleCommentUpdate}
                              handleCommentDelete={handleCommentDelete}
                            />
                          ))
                        : "" }
                      <FollowUpCommentForm
                        validationSchemaComment={validationSchemaComment}
                        initialValueCommment={initialValueCommment}
                        handleCommentSubmit={handleCommentSubmit}
                        handleCloseForm={handleCloseCommentForm}
                        showCommentCloseButton={showCommentCloseButton}
                        userProfileImages={authUser.profile_pic}
                        loader={loader}
                      />
                    </>
                  ) : "" }
                </Col>
              </>
            )}
          </Row>
        </Modal>
      </>
    );
}