import {
    SALES_PERSON_PREVIEW,
    SALES_PERSON_NEW,
    SALES_PERSON_UPDATE,
    SALES_PERSON_REMOVE,
    SALES_PERSON_SEARCH,
    SALES_PERSON_PROCESS_SUCCESS,
    SALES_PERSON_DATASOURCE_SUCCESS,
    SALES_PROCESS_RESET
  } from "constants/ActionTypes"

  export const salesPersonPreview = (value) => {
    return {
      type: SALES_PERSON_PREVIEW,
      payload:value
    }
  }

  export const salesPersonCreate =(value) =>{
    return {
      type:SALES_PERSON_NEW,
      payload:value
    }
  }

  export const salesPersonUpdate = (value) =>{
    return {
      type:SALES_PERSON_UPDATE,
      payload: value
    }
  }

  export const salesPersonRemove = (value)=>{
    return {
      type:SALES_PERSON_REMOVE,
      payload:value
    }
  }

  export const salesPersonSearch = (keyword) =>{
    return {
      type: SALES_PERSON_SEARCH,
      payload:keyword
    }
  }

  export const salesPersonProcessSuccess = (value) =>{
    return{
      type:SALES_PERSON_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const salesPersonDataSourceUpdate = (value) => {
    return {
      type: SALES_PERSON_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const salesProcessReset = ()=>{
    return{
      type:SALES_PROCESS_RESET
    }
  }

