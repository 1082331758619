import * as actions from "appRedux/actions/cashier/folioHistoryActionType"

export interface folioHistoryState {
    loader: boolean,
    keyword:string,
    dataSource: [], 
    dataSourceSearch:[], 
    process:any
}

const initialState: folioHistoryState = {
    loader: false,
    keyword:'',
    dataSource: [],
    dataSourceSearch:[],
    process:{ status:false,action:"",message:""}
}

export default (state: folioHistoryState = initialState,action: actions.FolioHistoryAction): folioHistoryState =>{
    switch (action.type) {
    case actions.FOLIO_HISTORY_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.FOLIO_HISTORY_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.FOLIO_HISTORY_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    
    case actions.FOLIO_HISTORY_SEARCH:{
        return{
            ...state,
            loader:true,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.FOLIO_HISTORY_TRANS_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.FOLIO_HISTORY_PROCESS_RESET:{
        return{
            ...state,
            process:{ status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}