import * as actions from "./IssueLogActionType"

export const issueLogPreview = (payload: {
  type: "booking" | "registation",
  id: number
}): actions.IssueLogRequest => {
  return {
    type: actions.ISSUE_LOG_REQUEST,
    payload
  }
}
export const issueLogDatasouceSuccess = (payload: {
  type: "booking" | "registation",
  value: Array<any>
}): actions.IssueLogDataSourceSuccess => {
  return {
    type: actions.ISSUE_LOG_DATASOURCE_SUCCESS,
    payload
  }
}

export const issueLogProcessSuccess = (payload: any): actions.IssueLogProcessSuccess => {
  return {
    type: actions.ISSUE_LOG_PROCESS_SUCCESS,
    payload
  }
}

export const issueLogProcessReset = (): actions.IssueLogProcessReset => {
  return {
    type: actions.ISSUE_LOG_PROCESS_RESET
  }
}

export const issueLogReset = (payload?:{type: "booking" | "registation"}): actions.IssueLogReset => {
  return {
    type: actions.ISSUE_LOG_RESET,
    payload
  }
}