import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  CHANNEL_GROUP_PREVIEW,
  CHANNEL_GROUP_NEW,
  CHANNEL_GROUP_UPDATE,
  CHANNEL_GROUP_REMOVE,
  CHANNEL_GROUP_SEARCH
} from "constants/ActionTypes"

import {channelGroupDataSourceUpdate,channelGroupProcessSuccess,channelGroupProcessReset} from "appRedux/actions/setup/channel/ChannelGroup"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"


function* channelGroupPreviewProcess({payload}){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/channelGroup/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const channelDataSource = orderBy(response.data,[o=>o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.channel_group_id = obj.id
                    container.channel_group_name = obj.name
                return container
            })
            /* if no error put everything to reducer */
            yield put(channelGroupDataSourceUpdate(channelDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreChannelGroupList",channelDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(channelGroupProcessSuccess(processStatus))
            yield put(channelGroupProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }   
      }
      
    } catch (error) {
        console.log("Error on saga channel Group : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(channelGroupProcessSuccess(processStatus))
        yield put(channelGroupProcessReset())
    }
    
}

function* channelGroupSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll("ListBoxDB","objStoreChannelGroupList")
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()
  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_channel_group_name = filter(ReformalData, function(o) {
        return o.channel_group_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_channel_group_name])]

      yield put(channelGroupDataSourceUpdate(mergeData))
  }else 
      yield put(channelGroupDataSourceUpdate(ReformalData))
}

function* channelGroupCreateProcess({payload}){
  const channel_group_name = payload.channel_group_name
  let channelGroupInfo = {name:channel_group_name}
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/channelGroup/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = channelGroupInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success." }
              yield put(channelGroupProcessSuccess(processStatus))
              yield put(channelGroupProcessReset())
            }else{
                yield put(channelGroupProcessSuccess(response.data.message))        
                const {message,data} = response.data
                const processStatus = { status: false, action: "create", message }
                yield put(channelGroupProcessSuccess(processStatus))
                yield put(channelGroupProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(channelGroupProcessSuccess(processStatus))
            yield put(channelGroupProcessReset())
      }
}

function* channelGroupUpdateProcess({payload}){
  const channel_group_name = payload.channel_group_name
  const channel_group_id = payload.channel_group_id

  let channelInfo = {}
      channelInfo.id = channel_group_id
      channelInfo.name = channel_group_name
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channelGroup/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = channelInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(channelGroupProcessSuccess(processStatus))
            yield put(channelGroupProcessReset())
        }else{     
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(channelGroupProcessSuccess(processStatus))
            yield put(channelGroupProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(channelGroupProcessSuccess(processStatus))
        yield put(channelGroupProcessReset())
  }
}

function* channelGroupRemoveProcess({payload}){
    const channelGroupRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/channelGroup/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : channelGroupRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(channelGroupProcessSuccess(processStatus))
          yield put(channelGroupProcessReset())
        }else{       
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(channelGroupProcessSuccess(processStatus))
          yield put(channelGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(channelGroupProcessSuccess(processStatus))
      yield put(channelGroupProcessReset())
    }
}

/* Watcher Saga */
export function* channelGroupPreview() {
  yield takeLatest(CHANNEL_GROUP_PREVIEW, channelGroupPreviewProcess)
}
export function* channelGroupSearch(){
  yield takeLatest(CHANNEL_GROUP_SEARCH, channelGroupSearchProcess)
}
export function* channelGroupCreate(){
  yield takeEvery(CHANNEL_GROUP_NEW,channelGroupCreateProcess)
}
export function* channelGroupUpdate(){
  yield takeEvery(CHANNEL_GROUP_UPDATE, channelGroupUpdateProcess)
}
export function* channelGroupRemove(){
  yield takeEvery(CHANNEL_GROUP_REMOVE,channelGroupRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(channelGroupPreview),
             fork(channelGroupSearch),
             fork(channelGroupCreate),
             fork(channelGroupUpdate),
             fork(channelGroupRemove)])


}
