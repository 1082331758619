import {all, fork, put, takeEvery,takeLatest, call } from "redux-saga/effects"

import {
    END_DAY_PREVIEW,
    END_DAY_RUN,
    END_DAY_REALTIME_UPDATE
} from "constants/ActionTypes"

import {endDayDataSourceUpdate,
        endDayProcessSuccess,
        endDayProcessReset} from "appRedux/actions/nightAudit/endDay"
import {userSignOut} from "appRedux/actions/Auth"

import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"

function* endDayPreviewProcess(){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/nightAudit/endday/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const {status,start_time,finish_time,tasks} = response.data
                const endDayInfo = {status,start_time,finish_time,tasks}
                /* if no error put everything to reducer */
                yield put(endDayDataSourceUpdate(endDayInfo))

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(endDayProcessSuccess(processStatus))
                yield put(endDayProcessReset())
                if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                    yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on endday preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(endDayProcessSuccess(processStatus))
        yield put(endDayProcessReset())
    }
    
}

function* endDayRunProcess(){
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/nightAudit/endday/execute/`

        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

        const response = yield APIPostRequest(url,{},API_PMS_HEADERS,)

        if(response.status === 200){
          /* Code something true */
          const {status,start_time,finish_time,tasks} = response.data
                const endDayInfo = {status,start_time,finish_time,tasks}
          yield put(endDayDataSourceUpdate(endDayInfo))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "execute", message}
          yield put(endDayProcessSuccess(processStatus))
          yield put(endDayProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "execute", message:error}
        yield put(endDayProcessSuccess(processStatus))
        yield put(endDayProcessReset())
  }
}

function* endDayRealTimeUpdateProcess({payload}){
    yield console.log("Real time update",payload)
}

/* Watcher Saga */
export function* endDayPreview() {
  yield takeLatest(END_DAY_PREVIEW, endDayPreviewProcess)
}
export function* endDayRun(){
  yield takeEvery(END_DAY_RUN, endDayRunProcess)
}
export function* endDayRealTimeUpdate(){
  yield takeEvery(END_DAY_REALTIME_UPDATE,endDayRealTimeUpdateProcess)
}

export default function* rootSaga() {
  yield all([fork(endDayPreview),
             fork(endDayRun),
             fork(endDayRealTimeUpdate)])


}
