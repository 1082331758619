// Error: 'React' was used before it was defined  @typescript-eslint/no-use-before-define
// So use: // eslint-disable-next-line
// This issule just begin (14/09/2020)
// https://github.com/typescript-eslint/typescript-eslint/issues/2540

// Import package from NPM
// eslint-disable-next-line
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Drawer, Layout } from "antd"

// Import custom by Template
import SidebarContent from "./SidebarContent"
import { toggleCollapsedSideNav, updateWindowWidth } from "appRedux/actions"
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting"

// Import custom by Project
import { AppState } from 'ReducerState'

const { Sider } = Layout

const Sidebar = () => {
  const dispatch = useDispatch()

  const { themeType, navStyle } = useSelector((state: AppState) => state.settings)

  const { navCollapsed, width } = useSelector((state: AppState) => state.common)

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed))
  }

  useEffect(() => {
    window.addEventListener('resize', () => {
      dispatch(updateWindowWidth(window.innerWidth))
    })
  }, [dispatch])


  let drawerStyle = "gx-collapsed-sidebar"

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = ""
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar"
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar"
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar"
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
    || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
    drawerStyle = "gx-collapsed-sidebar"
  }
  return (
    <Sider
      className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
      trigger={null}
      collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
      theme={themeType === THEME_TYPE_LITE ? "light" : "dark"}
      collapsible>
      {
        navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
          <Drawer
            className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
            placement="left"
            closable={false}
            onClose={onToggleCollapsedNav}
            open={navCollapsed}>
            <SidebarContent/>
          </Drawer> :
          <SidebarContent/>
      }
    </Sider>)
}

export default Sidebar