import React,{useEffect,useState} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import NotificationItem from "./NotificationItem"
import CustomScrollbars from "util/CustomScrollbars"
import Auxiliary from "util/Auxiliary"
import {message} from "antd"
import {isEmpty} from "lodash"
import { notificationPreview ,notificationRemove } from "appRedux/actions/Notifications"
import FollowUp from "./modal/FollowUp"

const MessageNotification = (props) => {
  const {onHandleClose} = props
  const { notifications } = useSelector(props=>props)
  const {dataSource:notificationMessage,process} = notifications
  const [refID,setRefID] = useState(undefined)
  const [isOpenFollow, setIsOpenFollow] = useState(false)

  const disPatch = useDispatch()
  useEffect(()=>{
      if(!isEmpty(process.action)){
        if(process.status){
            message.success(process.message)
            setTimeout(() => {  disPatch(notificationPreview())},300)
        }
        else {
            message.error(process.message)    
        }
      }
  },[process,disPatch])

  useEffect(()=>{ 
      disPatch(notificationPreview())
  },[disPatch,notificationMessage.length])


  const notificationView = (value)=>{
    setIsOpenFollow(true)
    setRefID(value)
  }

  const handleModalFollow = (value) => {
    setIsOpenFollow(value)
  }

  const handleDelete = (value)=>{
    disPatch(notificationRemove(value))
  }

  return (
    <>
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" onClick={onHandleClose}/>
      </div>
      <CustomScrollbars className="gx-popover-scroll">
        <ul className="gx-sub-popover">
          {notificationMessage.map((obj, index) => 
            <NotificationItem key={index} 
                              notification={obj} 
                              handleFollowUp={notificationView} 
                              onHandleClose={onHandleClose}
                              handleDelete={handleDelete}
                              />)}
        </ul>
      </CustomScrollbars>
    </Auxiliary>
    <FollowUp notikey={"noti-topbar"} isOpen={isOpenFollow} onChangeModal={handleModalFollow} reservationID={refID} />
    </>
  )
}

export default MessageNotification

