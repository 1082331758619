import {
    TITLE_NAME_PREVIEW,
    TITLE_NAME_NEW,
    TITLE_NAME_UPDATE,
    TITLE_NAME_REMOVE,
    TITLE_NAME_SEARCH,
    TITLE_NAME_PROCESS_SUCCESS,
    TITLE_NAME_DATASOURCE_SUCCESS,
    TITLE_NAME_PROCESS_RESET
  } from "constants/ActionTypes"

  export const titleNamePreview = () => {
    return {
      type: TITLE_NAME_PREVIEW
    }
  }

  export const titleNameCreate =(value) =>{
    return {
      type:TITLE_NAME_NEW,
      payload:value
    }
  }

  export const titleNameUpdate = (value) =>{
    return {
      type:TITLE_NAME_UPDATE,
      payload: value
    }
  }

  export const titleNameRemove = (value)=>{
    return {
      type:TITLE_NAME_REMOVE,
      payload:value
    }
  }

  export const titleNameSearch = (keyword) =>{
    return {
      type: TITLE_NAME_SEARCH,
      payload:keyword
    }
  }

  export const titleNameProcessSuccess = (value) =>{
    return{
      type:TITLE_NAME_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const titleNameDataSourceUpdate = (value) => {
    return {
      type: TITLE_NAME_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const titleNameProcessReset = () =>{
    return{
      type:TITLE_NAME_PROCESS_RESET
    }
  }

