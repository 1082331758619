import { historyRequestType } from "./typeInterface"

export const FOLIO_HISTORY_REQUEST = "FOLIO_HISTORY_REQUEST"
export const FOLIO_HISTORY_SEARCH = "FOLIO_HISTORY_SEARCH"
export const FOLIO_HISTORY_DATASOURCE_SUCCESS = "FOLIO_HISTORY_DATASOURCE_SUCCESS"
export const FOLIO_HISTORY_PROCESS_SUCCESS = "FOLIO_HISTORY_PROCESS_SUCCESS"
export const FOLIO_HISTORY_PROCESS_RESET =  "FOLIO_HISTORY_PROCESS_RESET"
export const FOLIO_HISTORY_REOPEN = "FOLIO_HISTORY_REOPEN"
export const FOLIO_HISTORY_TRANS_UPDATE = "FOLIO_HISTORY_TRANS_UPDATE"

export interface FolioHistoryRequest {
  type: typeof FOLIO_HISTORY_REQUEST,
  payload:historyRequestType
}
export interface FolioHistorySearch {
  type: typeof FOLIO_HISTORY_SEARCH,
  payload:any
}
export interface FolioHistoryDataSourceSuccess{
  type: typeof FOLIO_HISTORY_DATASOURCE_SUCCESS,
  payload:any
}

export interface FolioHisrotyProcessSuccess {
  type: typeof FOLIO_HISTORY_PROCESS_SUCCESS,
  payload:{}
}
export interface FolioHistoryProcessReset {
  type: typeof FOLIO_HISTORY_PROCESS_RESET
}
export interface FolioHistoryReopen {
  type: typeof FOLIO_HISTORY_REOPEN,
  payload:number
}
export interface FolioHistoryUpdate{
  type: typeof FOLIO_HISTORY_TRANS_UPDATE,
  payload:any
}

export type FolioHistoryAction = 
| FolioHistoryRequest
| FolioHistorySearch
| FolioHistoryDataSourceSuccess
| FolioHisrotyProcessSuccess
| FolioHistoryProcessReset
| FolioHistoryReopen
| FolioHistoryUpdate
