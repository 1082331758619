import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  FOLIO_PATTERN_PREVIEW,
  FOLIO_PATTERN_NEW,
  FOLIO_PATTERN_UPDATE,
  FOLIO_PATTERN_REMOVE,
  FOLIO_PATTERN_SEARCH
} from "constants/ActionTypes"

import {folioPatternDataSourceUpdate,folioPatternProcessSuccess,folioPatternProcessReset} from "appRedux/actions/setup/folio/FolioPattern"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* folioPatternPreviewProcess(){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/folioPattern/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const folioPatternDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.folio_id = obj.id
                    container.folio_name = obj.name
                    container.split_room_charge = obj.split_room_charge
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(folioPatternDataSourceUpdate(folioPatternDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreFolioList",folioPatternDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(folioPatternProcessSuccess(processStatus))
            yield put(folioPatternProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on foliopattern preview : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(folioPatternProcessSuccess(processStatus))
    }
    
}

function* folioPatternSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreFolioList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_folio_name = filter(ReformalData, function(o) {
        return o.folio_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_folio_name])]

      yield put(folioPatternDataSourceUpdate(mergeData))
  }else 
      yield put(folioPatternDataSourceUpdate(ReformalData))
}

function* folioPatternCreateProcess({payload}){
  const {folio_name,split_room_charge,active:folioActive} = payload

  let folioPatternInfo = {name:folio_name,
                          split_room_charge:split_room_charge,
                          enabled:folioActive}

      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/folioPattern/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = folioPatternInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success." }
              yield put(folioPatternProcessSuccess(processStatus))
              yield put(folioPatternProcessReset())
            }else{
                const {message,data} = response.data
                const processStatus = { status: false, action: "create", message }
                yield put(folioPatternProcessSuccess(processStatus))
                yield put(folioPatternProcessReset())
                if(response.status === 403){
                  /* if data is not null force signout */
                  if(data)
                    yield put(userSignOut())
                }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(folioPatternProcessSuccess(processStatus))
            yield put(folioPatternProcessReset())
      }
}

function* folioPatternUpdateProcess({payload}){
  const {folio_name,folio_id,split_room_charge,active:folioActive} = payload

  let folioPatternInfo = {}
      folioPatternInfo.id = folio_id
      folioPatternInfo.name = folio_name
      folioPatternInfo.split_room_charge = split_room_charge
      folioPatternInfo.enabled = folioActive

  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/folioPattern/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = folioPatternInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(folioPatternProcessSuccess(processStatus))
            yield put(folioPatternProcessReset())
        }else{
            const {message,data} = response.data
            const processStatus = { status: false, action: "update", message }
            yield put(folioPatternProcessSuccess(processStatus))
            yield put(folioPatternProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
        }        

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(folioPatternProcessSuccess(processStatus))
        yield put(folioPatternProcessReset())
  }
}

function* folioPatternRemoveProcess({payload}){
    const folioPatternRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/folioPattern/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : folioPatternRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(folioPatternProcessSuccess(processStatus))
          yield put(folioPatternProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(folioPatternProcessSuccess(processStatus))
          yield put(folioPatternProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }     
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(folioPatternProcessSuccess(processStatus))
      yield put(folioPatternProcessReset())
    }
}

/* Watcher Saga */
export function* folioPatternPreview() {
  yield takeLatest(FOLIO_PATTERN_PREVIEW, folioPatternPreviewProcess)
}
export function* folioPatternSearch(){
  yield takeLatest(FOLIO_PATTERN_SEARCH, folioPatternSearchProcess)
}
export function* folioPatternCreate(){
  yield takeEvery(FOLIO_PATTERN_NEW,folioPatternCreateProcess)
}
export function* folioPatternUpdate(){
  yield takeEvery(FOLIO_PATTERN_UPDATE, folioPatternUpdateProcess)
}
export function* folioPatternRemove(){
  yield takeEvery(FOLIO_PATTERN_REMOVE,folioPatternRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(folioPatternPreview),
             fork(folioPatternSearch),
             fork(folioPatternCreate),
             fork(folioPatternUpdate),
             fork(folioPatternRemove)])


}
