import * as actions from "./bookingActionType"

export const cashierBookingPreview = (payload?: any,sorter?:any): actions.CashierBookingFolioRequest => {
  return {
    type: actions.CASHIER_BOOKING_FOLIO_REQUEST,
    payload,
    sorter
  }
}

export const cashierBookingProcessSuccess = (payload:any):actions.CashierBookingFolioProcessSuccess =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_PROCESS_SUCCESS,
    payload
  }
}

export const cashierBookingDataSourceSuccess = (payload:any):actions.CashierBookingFolioDataSourceSuccess =>{
  return {
    type: actions.CASHIER_BOOKING_FOLIO_DATASOURCE_SUCCESS,
    payload
  }
}
export const cashierBookingDetailPreview = (payload:number):actions.CashierBookingFolioDetailRequest=>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_DETAIL_REQUEST,
    payload
  }
}
export const cashierBookingDetailDataSourceSuccess = (payload:any):actions.CashierBookingFolioDetailDataSourceSuccess=>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}
export const cashierBookingSetFilter = (payload:any): actions.CashierBookingSetFilter =>{
  return {
    type: actions.CASHIER_BOOKING_FOLIO_SET_FILTER,
    payload
  }
}

export const cashierBookingCheckOut = (value:any): actions.CashierBookingCheckOut =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_CHECK_OUT,
    payload:value
  }
}

export const cashierBookingCancelCheckOut = (value:any): actions.CashierBookingCancelCheckOut =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_CANCEL_CHECK_OUT,
    payload:value
  }
}

export const cashierBookingCheckOutListPreview = (value:any): actions.CashierBookingCheckOutList =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_CHECKOUT_LIST,
    payload:value
  }
}

export const cashierBookingCheckOutListSuccess = (value:any): actions.CashierBookingCheckOutListSuccess =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_CHECKOUT_LIST_SUCCESS,
    payload:value
  }
}

export const cashierBookingFolioPost = (value:any):actions.CashierBookingFolioPost =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_POST,
    payload:value
  }
}

export const cashierBookingFolioVoid = (value:any):actions.CashierBookingFolioVoid =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_VOID,
    payload:value
  }
}

export const cashierBookingFolioUpdate = (value:any):actions.CashierBookingFolioUpdate =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_UPDATE,
    payload:value
  }
}

export const cashierBookingFolioPayment = (value:any):actions.CashierBookingFolioPayment =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_PAYMENT,
    payload:value
  }
}
export const cashierBookingFolioSplit = (value:any):actions.CashierBookingFolioSplit =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_SPLIT,
    payload:value
  }
}
export const cashierBookingFolioMove = (value:any):actions.CashierBookingFolioMove =>{
  return{
    type: actions.CASHIER_BOOKING_FOLIO_MOVE,
    payload:value
  }
}

export const cashierBookingCloseFolioSeq = (value:any):actions.CashierBookingCloseFolioSeq => {
  return {
    type: actions.CASHIER_BOOKING_CLOSE_FOLIO_SEQ,
    payload:value
  }
}

export const cashierBookingToggleLock = (value:any):actions.CashierBookingToggleLock => {
  return {
    type: actions.CASHIER_BOOKING_FOLIO_TOGGLE_LOCK,
    payload:value
  }
}

export const cashierBookingToggleSuccess = (value:any):actions.CashierBookingToggleSuccess => {
  return {
    type: actions.CASHIER_BOOKING_FOLIO_TOGGLE_SUCCESS,
    payload:value
  }
}

export const cashierBookingInfoUpdate = (value:any):actions.CashierBookingInfoUpdate => {
  return {
    type: actions.CASHIER_BOOKING_INFO_UPDATE,
    payload:value
  }
}

export const cashierBookingInfoUpdateSuccess = (value:any):actions.CashierBookingInfoUpdateSuccess => {
  return {
    type: actions.CASHIER_BOOKING_INFO_UPDATE_SUCCESS,
    payload:value
  }
}

export const cashierBookingChangeOnlineShift = (value:any):actions.CashierBookingChangeOnlineShift => {
  return {
    type: actions.CASHIER_BOOKING_CHANGE_ONLINE_SHIFT,
    payload:value
  }
}

export const cashierBookingProcessReset=() =>{
  return{
    type:actions.CASHIER_BOOKING_FOLIO_PROCESS_RESET
  } 
}
