import axios from 'axios'

export const APIGetRequest = async (URL:string, setHeaders:any) =>{
    
    return await axios.get(URL, setHeaders)
    .then(response => response.data)
    .catch(error =>{ 
        if(error.response) {
        // Request made and server responded
            console.log(error.response.data)
            console.log(error.response.status)
            console.log(error.response.headers)
            const {status,data} = error.response
            return {status,data:{message:data.message,data:data.data}}
        } else if (error.request) {
        // The request was made but no response was received
            return {status:500,data:{message:error,data:undefined}}
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message)
        }})
}

export const APIPostRequest = async (URL:string,API_DATABODY:any, setHeaders:any) =>{
    return await axios.post(URL,API_DATABODY, setHeaders)
    .then(response => response.data)
    .catch(error => { if (error.response) {
      // Request made and server responded
        console.log(error.response.data)
        console.log(error.response.status)
        console.log(error.response.headers)
        const {status,data} = error.response
        return {status,data:{message:data.message,data:data.data}}
    } else if (error.request) {
      // The request was made but no response was received
        return {status:500,data:{message:error,data:undefined}}
    } else {
      // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
    }})
}