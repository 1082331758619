export const RATEPLAN_PREVIEW = "RATEPLAN_PREVIEW"
export const RATEPLAN_NEW = "RATEPLAN_NEW"
export const RATEPLAN_UPDATE = "RATEPLAN_UPDATE"
export const RATEPLAN_REMOVE = "RATEPLAN_REMOVE"
export const RATEPLAN_PROCESS_SUCCESS = "RATEPLAN_PROCESS_SUCCESS"
export const RATEPLAN_SEARCH = "RATEPLAN_SEARCH"
export const RATEPLAN_DATASOURCE_SUCCESS = "RATEPLAN_DATASOURCE_SUCCESS"
export const RATEPLAN_DETAIL = "RATEPLAN_DETAIL"
export const RATEPLAN_DETAIL_SUCCESS = "RATEPLAN_DETAIL_SUCCESS"
export const RATEPLAN_CHANNEL_MAPPING = "RATEPLAN_CHANNEL_MAPPING"

export const RATEPLAN_ROOM_NEW = "RATEPLAN_ROOM_NEW"
export const RATEPLAN_ROOM_UPDATE = "RATEPLAN_ROOM_UPDATE"
export const RATEPLAN_ROOM_REMOVE = "RATEPLAN_ROOM_REMOVE"

export const RATEPLAN_ROOM_PROCESS_SUCCESS = "RATEPLAN_ROOM_PROCESS_SUCCESS"
export const RATEPLAN_GET_ROOM_DETAIL = "RATEPLAN_GET_ROOM_DETAIL"
export const RATEPLAN_GET_ROOM_DETAIL_SUCCESS = "RATEPLAN_GET_ROOM_DETAIL_SUCCESS"
export const RATEPLAN_PROCESS_RESET = "RATEPLAN_PROCESS_RESET"

export const RATEPLAN_SWITCH_ENABLED = "RATEPLAN_SWITCH_ENABLED"


export interface RatePlanRequest {
  type: typeof RATEPLAN_PREVIEW,
  payload?:any
}
export interface RatePlanNew {
  type: typeof RATEPLAN_NEW,
  payload?:any
}
export interface RatePlanUpdate {
  type: typeof RATEPLAN_UPDATE,
  payload?: any
}
export interface RatePlanRemove{
  type: typeof RATEPLAN_REMOVE,
  payload:any
}
export interface RatePlanDataSouceSuccess{
  type: typeof RATEPLAN_DATASOURCE_SUCCESS,
  payload:any
}
export interface RatePlanProcessSuccess {
  type: typeof RATEPLAN_PROCESS_SUCCESS,
  payload:any
}
export interface RatePlanSearch{
  type: typeof RATEPLAN_SEARCH,
  payload?:any
}

export interface RatePlanDetail{
  type: typeof RATEPLAN_DETAIL,
  payload:any
}
export interface RatePlanDetailSuccess{
  type: typeof RATEPLAN_DETAIL_SUCCESS,
  payload:any
}
export interface RatePlanGetRoomDetail{
  type: typeof RATEPLAN_GET_ROOM_DETAIL,
  payload:any
}
export interface RateplanGetRoomDetailSuccess{
  type: typeof RATEPLAN_GET_ROOM_DETAIL_SUCCESS,
  payload:any
}
export interface RatePlanChannelMapping{
  type: typeof RATEPLAN_CHANNEL_MAPPING,
  payload:any
}

export interface RatePlanSwitchEnabled{
  type: typeof RATEPLAN_SWITCH_ENABLED,
  payload:any
}


/* Remove rateplan room after success new rateplan */
export interface RatePlanRoomNew{
  type: typeof RATEPLAN_ROOM_NEW,
  payload:any
}
export interface RatePlanRoomUpdate{
  type: typeof RATEPLAN_ROOM_UPDATE,
  payload:any
}
export interface RatePlanRoomRemove{
  type: typeof RATEPLAN_ROOM_REMOVE,
  payload:any
}
export interface RatePlanRoomProcessSuccess {
  type: typeof RATEPLAN_ROOM_PROCESS_SUCCESS,
  payload:any
}
export interface RatePlanProcessReset{
  type: typeof RATEPLAN_PROCESS_RESET
}

export type RatePlanAction = 
| RatePlanRequest
| RatePlanSearch
| RatePlanNew
| RatePlanUpdate
| RatePlanRemove
| RatePlanDataSouceSuccess
| RatePlanProcessSuccess
| RatePlanDetail
| RatePlanDetailSuccess
| RatePlanGetRoomDetail
| RateplanGetRoomDetailSuccess
| RatePlanChannelMapping
| RatePlanSwitchEnabled
| RatePlanRoomNew
| RatePlanRoomUpdate
| RatePlanRoomRemove
| RatePlanRoomProcessSuccess
| RatePlanProcessReset