import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/chargeSchedule/ChargeReservationActionType"
import * as actionCreators from "appRedux/actions/chargeSchedule/ChargeReservation"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { chargeScheduleType, updateRateReservationType } from "appRedux/reducers/chargeSchedule/chargeType"

function* chargeReservationRequestProcess({payload}: actionTypes.ChargeReservationRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/${payload}/roomChargeSchedule/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const chargeRateInfo:chargeScheduleType[] = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.chargeReservationDataSourceSuccess(chargeRateInfo))
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "chargeReservation", message}
                yield put(actionCreators.chargeReservationProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                    if(data)
                        yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get chargeReservation : ",error)
        const processStatus = { status: false, action: "chargeReservation", message:error}
        yield put(actionCreators.chargeReservationProcessSuccess(processStatus))
    }
}

function* chargeReservationUpdateProcess({payload}:actionTypes.ChargeReservationUpdate){
    const {bookingID,trans,valueUpdate} = payload
    const items = trans.map((obj:any)=>{
        const dates = obj.selectedRows.map((o:any)=>o.date)
        return {id:obj.id,dates}
    })
    const updateData = {} as updateRateReservationType
        updateData.booking_id = bookingID
        updateData.items = items
        updateData.room_rate = valueUpdate.room_rate ? parseFloat(valueUpdate.room_rate) : null
        updateData.extra_adult = valueUpdate.extra_adult ? parseInt(valueUpdate.extra_adult) : null
        updateData.extra_child = valueUpdate.extra_child ?parseInt(valueUpdate.extra_child) : null
        updateData.extra_infant = valueUpdate.extra_infant ? parseInt(valueUpdate.extra_infant) : null
        updateData.mealtype_id = valueUpdate.mealtype_id ? parseInt(valueUpdate.mealtype_id) : null

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/reservation/booking/updateRoomChargeSchedule/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = updateData
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const {result_code} = response.data
            const result_msg = result_code==="0"?"Save success":response.data.result_msg
            const processStatus = { status: true, action: "updateRateSchedule", message:result_msg,result_code}
            yield put(actionCreators.chargeReservationProcessSuccess(processStatus))
        }else { 
            const {message} = response.data
            const processStatus = { status: false, action: "updateRateSchedule", message}
            yield put(actionCreators.chargeReservationProcessSuccess(processStatus))
            if(response.status === 403){
            const {data} = response.data
            
            /* if data is not null force signout */
            if(data)
                yield put(userSignOut())
            }
        }
        } catch (error) {
        const processStatus = { status: false, action: "updateRateSchedule", message:error}
        yield put(actionCreators.chargeReservationProcessSuccess(processStatus))
        }
}


function* chargeReservationDetailProcess({payload}: actionTypes.ChargeReservationDetailRequest){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/reservation/booking/${payload}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)

        if(response.status === 200)
        {      
                const {booking_name,details} = response.data
                if(details.length===1){
                    const {extra_adult,extra_child,extra_infant} = details[0]
                    yield put(actionCreators.chargeReservationDetailDataSourceSuccess({booking_name,extra_adult,extra_child,extra_infant}))
                }else
                    yield put(actionCreators.chargeReservationDetailDataSourceSuccess({booking_name}))
        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "reservationDetail", message}
                console.log("process ",processStatus)
                if(response.status === 403){
                //if data is not null force signout 
                if(data)
                    yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on get reservation detail folio : ",error)
        //const processStatus = { status: false, action: "reservationDetail", message:error}
    }
}


function* chargeReservationRequest(){
    yield takeEvery(actionTypes.CHARGE_RESERVATION_REQUEST,chargeReservationRequestProcess)
}

function* chargeReservationUpdate(){
    yield takeEvery(actionTypes.CHARGE_RESERVATION_UPDATE,chargeReservationUpdateProcess)
}

function* chargeReservationDetail(){
    yield takeEvery(actionTypes.CHARGE_RESERVATION_DETAIL_REQUEST,chargeReservationDetailProcess)
}

export default function* rootSaga() {
    yield all([fork(chargeReservationRequest),
            fork(chargeReservationUpdate),
            fork(chargeReservationDetail)
    ])
}


