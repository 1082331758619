import React, {Component} from "react"
import {Layout} from "antd"

import Sidebar from "../Sidebar/index.tsx"
import HorizontalDefault from "../Topbar/HorizontalDefault/index"
import HorizontalDark from "../Topbar/HorizontalDark/index"
import InsideHeader from "../Topbar/InsideHeader/index"
import AboveHeader from "../Topbar/AboveHeader/index"
import BelowHeader from "../Topbar/BelowHeader/index"

import Topbar from "../Topbar/index"
import {footerText} from "util/config"
import App from "routes/index"
import {connect} from "react-redux"
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting"
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index"
import { userSignOut} from "appRedux/actions/Auth"
const {Content, Footer} = Layout

export class MainApp extends Component {
  componentDidUpdate(prevProps) {
    const { authUser } = this.props
    if(authUser !== prevProps.authUser) {
      if (authUser===null) {
        //history.push('/')
        this.props.userSignOut()
      }
    } 
  }

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap"
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap"
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap"
      default :
        return ""
    }
  }
  getNavStyles = (navStyle, props ) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault  {...props}/>
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark  {...props}/>
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader  {...props}/>
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader  {...props}/>
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader  {...props}/>
      case NAV_STYLE_FIXED :
        return <Topbar {...props}/>
      case NAV_STYLE_DRAWER :
        return <Topbar  {...props}/>
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar  {...props}/>
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification  {...props}/>
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification  {...props}/>
      default :
        return null
    }
  }

  getSidebar = (navStyle, width, props) => {
    if (width < TAB_SIZE) {
      return <Sidebar {...props}/>
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Sidebar {...props}/>
      case NAV_STYLE_DRAWER :
        return <Sidebar {...props}/>
      case NAV_STYLE_MINI_SIDEBAR :
        return <Sidebar {...props}/>
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <Sidebar {...props}/>
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar {...props}/>
      default :
        return null
    }
  }
  
  render() {
    const hideFooter = true
    const {match, width, navStyle} = this.props
    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width , this.props)}
        <Layout>
          {this.getNavStyles(navStyle, this.props)}
          <Content className={`gx-layout-content ${ this.getContainerClass(navStyle)} `}>
            <App match={match}/>
            {hideFooter?"":
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText.copyright}
              </div>
            </Footer>
            }
          </Content>
        </Layout>
      </Layout>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {width, navStyle} = settings
  const {authUser} = auth
  return {width, navStyle, authUser}
}
export default connect(mapStateToProps,{userSignOut})(MainApp)

