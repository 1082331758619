import {
    FACILITY_PREVIEW,
    FACILITY_NEW,
    FACILITY_UPDATE,
    FACILITY_REMOVE,
    FACILITY_SEARCH,
    FACILITY_PROCESS_SUCCESS,
    FACILITY_DATASOURCE_SUCCESS,
    FACILITY_PROCESS_RESET
  } from "constants/ActionTypes"

  export const facilityPreview = (value) => {
    return {
      type: FACILITY_PREVIEW,
      payload:value
    }
  }

  export const facilityCreate =(value) =>{
    return {
      type:FACILITY_NEW,
      payload:value
    }
  }

  export const facilityUpdate = (value) =>{
    return {
      type:FACILITY_UPDATE,
      payload: value
    }
  }

  export const facilityRemove = (value)=>{
    return {
      type:FACILITY_REMOVE,
      payload:value
    }
  }

  export const roomTypeSearch = (keyword) =>{
    return {
      type: FACILITY_SEARCH,
      payload:keyword
    }
  }

  export const facilityProcessSuccess = (value) =>{
    return{
      type:FACILITY_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const facilityDataSourceUpdate = (value) => {
    return {
      type: FACILITY_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const facilityProcessReset = () =>{
    return{
      type:FACILITY_PROCESS_RESET
    }
  }

