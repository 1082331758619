import * as actions from "appRedux/actions/houseKeeping/roomStatusActionType"

interface checkinType{
    booking_items:[],
    booking_qty:number,
    checkin_available:number,
    inhouse_qty:number,
    guests:[],
    status:boolean
}
export const checkinState: checkinType = {
	booking_items:[],
    booking_qty:0,
    checkin_available:0,
    inhouse_qty:0,
    guests:[],
    status:false
}

export interface roomStatusState {
    loader: boolean,
    rawDataSource:[], /* Store raw data from api */
    dataSource: [], /* Store data after filter use on preview */
    dataCheckinList:checkinType, /* Store data from api room list used on checkin */
    filterRoomStatus: any,
    groupView:string,   /* Option group view room/bed,location,building...etc */
    extraOption?:string, /* Option view Expected arrival/departure */
    selectionMode:string, /* Selection can switch single/multiple */
    roomTargetSelected:any, /* Array room number for change room/maid status */
    process:any
}

const initialState: roomStatusState = {
    loader: false,
    rawDataSource:[],
    dataSource: [],
    dataCheckinList:checkinState,
    filterRoomStatus: {},
    groupView:"room",
    extraOption: undefined,
    selectionMode:"single",
    roomTargetSelected:[],
    process:{ status:false,action:"",message:""}
}

export default (state: roomStatusState = initialState,action: actions.RoomStatusAction): roomStatusState =>{
    switch (action.type) {
    case actions.ROOM_STATUS_REQUEST:
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    case actions.ROOM_STATUS_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.ROOM_STATUS_RAW_DATASOURCE:{
        return{
            ...state,
            rawDataSource:action.payload
        }
    }
    case actions.ROOM_STATUS_DATASOURCE_SUCCESS:{
        return{
            ...state,
            loader:false,
            dataSource: action.payload
        }
    }
    case actions.ROOM_STATUS_SET_FILTER:{
        return{
            ...state,
            filterRoomStatus:action.payload
        }
    }
    case actions.ROOM_STATUS_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_MAID_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_BED_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_CHECK_IN:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_CANCEL_CHECK_IN:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_CHECK_OUT:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_CANCEL_CHECK_OUT:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_CHANGE_GROUP_VIEW:{
        return{
            ...state,
            groupView:action.payload
        }
    }
    case actions.ROOM_STATUS_EXTRA_OPTIONAL:{
        return{
            ...state,
            extraOption:action.payload
        }
    }
    case actions.ROOM_STATUS_SELECTION_MODE:{
        return{
            ...state,
            selectionMode:action.payload
        }
    }
    case actions.ROOM_STATUS_TARGET_SELECTED:{
        return{
            ...state,
            roomTargetSelected:action.payload
        }
    }
    case actions.ROOM_STATUS_OUT_OF_ORDER_NEW:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_OUT_OF_ORDER_UPDATE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_OUT_OF_ORDER_REMOVE:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_DATALIST_CHECKIN_REQUEST:{
        return{
            ...state,
            dataCheckinList:{...checkinState},
            process:{state:false,action:"",message:""}
        }
    }
    case actions.ROOM_STATUS_DATALIST_CHECKIN_DATASOURCE:{
        return{
            ...state,
            dataCheckinList:action.payload
        }
    }
    case actions.ROOM_STATUS_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state;
    }
}