import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  DEPARTMENT_GROUP_PREVIEW,
  DEPARTMENT_GROUP_NEW,
  DEPARTMENT_GROUP_UPDATE,
  DEPARTMENT_GROUP_REMOVE,
  DEPARTMENT_GROUP_SEARCH
} from "constants/ActionTypes"

import {departmentGroupDataSourceUpdate,departmentGroupProcessSuccess,departmentGroupProcessReset} from "appRedux/actions/setup/department/DepartmentGroup"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* departmentGroupPreviewProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/departmentGroup/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const departmentGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.department_group_id = obj.id
                    container.department_group_name = obj.name
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(departmentGroupDataSourceUpdate(departmentGroupDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreDepartmentGroupList",departmentGroupDataSource)
            
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(departmentGroupProcessSuccess(processStatus))
            yield put(departmentGroupProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on department group preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(departmentGroupProcessSuccess(processStatus))
    }
    
}

function* departmentGroupSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreDepartmentGroupList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_department_group_name = filter(ReformalData, function(o) {
        return o.department_group_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_department_group_name])]

      yield put(departmentGroupDataSourceUpdate(mergeData))
  }else 
      yield put(departmentGroupDataSourceUpdate(ReformalData))
}

function* departmentGroupCreateProcess({payload}){
  const department_group_name = payload.department_group_name
  let departmentGroupInfo = {name:department_group_name}
  
      
      try {

            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/departmentGroup/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = departmentGroupInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(departmentGroupProcessSuccess(processStatus))
                yield put(departmentGroupProcessReset())
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(departmentGroupProcessSuccess(processStatus))
              yield put(departmentGroupProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(departmentGroupProcessSuccess(processStatus))
            yield put(departmentGroupProcessReset())
      }
}

function* departmentGroupUpdateProcess({payload}){
  const department_group_name = payload.department_group_name
  const department_group_id = payload.department_group_id

  let departmentGroupInfo = {}
      departmentGroupInfo.id = department_group_id
      departmentGroupInfo.name = department_group_name
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/departmentGroup/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = departmentGroupInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(departmentGroupProcessSuccess(processStatus))
            yield put(departmentGroupProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(departmentGroupProcessSuccess(processStatus))
          yield put(departmentGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(departmentGroupProcessSuccess(processStatus))
        yield put(departmentGroupProcessReset())
  }
}

function* departmentGroupRemoveProcess({payload}){
    const departmentGroupRemove = payload
    

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/departmentGroup/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : departmentGroupRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(departmentGroupProcessSuccess(processStatus))
          yield put(departmentGroupProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(departmentGroupProcessSuccess(processStatus))
          yield put(departmentGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(departmentGroupProcessSuccess(processStatus))
      yield put(departmentGroupProcessReset())
    }
}

/* Watcher Saga */
export function* departmentGroupPreview() {
  yield takeLatest(DEPARTMENT_GROUP_PREVIEW, departmentGroupPreviewProcess)
}
export function* departmentGroupSearch(){
  yield takeLatest(DEPARTMENT_GROUP_SEARCH, departmentGroupSearchProcess)
}
export function* departmentGroupCreate(){
  yield takeEvery(DEPARTMENT_GROUP_NEW,departmentGroupCreateProcess)
}
export function* departmentGroupUpdate(){
  yield takeEvery(DEPARTMENT_GROUP_UPDATE, departmentGroupUpdateProcess)
}
export function* departmentGroupRemove(){
  yield takeEvery(DEPARTMENT_GROUP_REMOVE,departmentGroupRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(departmentGroupPreview),
             fork(departmentGroupSearch),
             fork(departmentGroupCreate),
             fork(departmentGroupUpdate),
             fork(departmentGroupRemove)])


}
