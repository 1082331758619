import {all, fork, put,takeLatest, call} from "redux-saga/effects"

import {
  OUT_OF_ORDER_PREVIEW,
  OUT_OF_ORDER_NEW,
  OUT_OF_ORDER_UPDATE,
  OUT_OF_ORDER_REMOVE,
  OUT_OF_ORDER_SEARCH
} from "constants/ActionTypes"

import {outOfOrderDataSourceUpdate,outOfOrderProcessSuccess,outOfOrderProcessReset} from "appRedux/actions/setup/roomInformation/OutOfOrder"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* outOfOrderPreviewProcess(){

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/outoforderReason/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
          const outOfOrderDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj, key) => {
          const container = {}
                container.key = key+1
                container.out_of_order_id = obj.id
                container.out_of_order_name = obj.name
          return container
          })
            
            /* if no error put everything to reducer */
             yield put(outOfOrderDataSourceUpdate(outOfOrderDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreOutOfOrderList",outOfOrderDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(outOfOrderProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on out of order preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(outOfOrderProcessSuccess(processStatus))
    }
    
}

function* outOfOrderSearchProcess({payload}){

  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreOutOfOrderList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.out_of_order_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(outOfOrderDataSourceUpdate(mergeData))
  }else 
      yield put(outOfOrderDataSourceUpdate(ReformalData))
}

function* locationCreateProcess({payload}){
  const {out_of_order_name} = payload
  
  let outOfOrderInfo = {}
      outOfOrderInfo.name = out_of_order_name
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/outoforderReason/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = outOfOrderInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(outOfOrderProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(outOfOrderProcessSuccess(processStatus))
              yield put(outOfOrderProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(outOfOrderProcessSuccess(processStatus))
            yield put(outOfOrderProcessReset())
      }

}

function* outOfOrderUpdateProcess({payload}){
  const {out_of_order_id,out_of_order_name} = payload
  
  let outOfOrderInfo = {}
      outOfOrderInfo.id = out_of_order_id
      outOfOrderInfo.name = out_of_order_name
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/outoforderReason/update/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = outOfOrderInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "update", message:"Update success." }
                yield put(outOfOrderProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "update", message }
              yield put(outOfOrderProcessSuccess(processStatus))
              yield put(outOfOrderProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "update", message:error}
            yield put(outOfOrderProcessSuccess(processStatus))
            yield put(outOfOrderProcessReset())
      }
}

function* outOfOrderRemoveProcess({payload}){
    const outOfOrderRemove = payload

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/outoforderReason/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : outOfOrderRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(outOfOrderProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(outOfOrderProcessSuccess(processStatus))
          yield put(outOfOrderProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(outOfOrderProcessSuccess(processStatus))
      yield put(outOfOrderProcessReset())
    }
}

/* Watcher Saga */
export function* outOfOrderPreview() {
  yield takeLatest(OUT_OF_ORDER_PREVIEW, outOfOrderPreviewProcess)
}
export function* outOfOrderSearch(){
  yield takeLatest(OUT_OF_ORDER_SEARCH, outOfOrderSearchProcess)
}
export function* outOfOrderCreate(){
  yield takeLatest(OUT_OF_ORDER_NEW,locationCreateProcess)
}
export function* outOfOrderUpdate(){
  yield takeLatest(OUT_OF_ORDER_UPDATE, outOfOrderUpdateProcess)
}
export function* outOfOrderRemove(){
  yield takeLatest(OUT_OF_ORDER_REMOVE,outOfOrderRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(outOfOrderPreview),
             fork(outOfOrderCreate),
             fork(outOfOrderSearch),
             fork(outOfOrderUpdate),
             fork(outOfOrderRemove)])


}
