import {
    CHANNEL_PREVIEW,
    CHANNEL_NEW,
    CHANNEL_UPDATE,
    CHANNEL_REMOVE,
    CHANNEL_PROCESS_SUCCESS,
    CHANNEL_SEARCH,
    CHANNEL_DATASOURCE_SUCCESS,
    CHANNEL_DETAIL,
    CHANNEL_DETAIL_SUCCESS,
    CHANNEL_LOADLIST,
    CHANNEL_LOAD_LIST_SUCCESS,
    CHANNEL_PROCESS_RESET
  } from "constants/ActionTypes"

  export const channelPreview = (value) => {
    return {
      type: CHANNEL_PREVIEW,
      payload:value
    }
  }

  export const channelCreate =(value) =>{
    return {
      type:CHANNEL_NEW,
      payload:value
    }
  }

  export const channelUpdate = (value) =>{
      return {
          type:CHANNEL_UPDATE,
          payload: value
      }
  }

  export const channelRemove = (value)=>{
      return {
          type:CHANNEL_REMOVE,
          payload:value
      }
  }

  export const channelProcessSuccess = (value)=>{
      return {
          type:CHANNEL_PROCESS_SUCCESS,
          payload:value
      }
  }

  export const channelSearch = (keyword) =>{
      return {
          type: CHANNEL_SEARCH,
          payload:keyword
      }
  }

  export const channelDataSourceUpdate = (value) => {
    return {
      type: CHANNEL_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const getChannelDatail = (value) => {
    return{
      type:CHANNEL_DETAIL,
      payload: value
    }
  }

  export const channelDetailSuccess = (value) => {
    return{
      type:CHANNEL_DETAIL_SUCCESS,
      payload: value
    }
  }
  export const channelLoadList = (value)=>{
    return {
      type:CHANNEL_LOADLIST,
      payload: value
    }
  }
  export const channelLoadListSuccess = (value) =>{
    return {
      type: CHANNEL_LOAD_LIST_SUCCESS,
      payload:value
    }
  }
  export const channelProcessReset = () =>{
    return{
      type:CHANNEL_PROCESS_RESET
    }
  }