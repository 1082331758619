
export const CASHIER_CASHSALE_FOLIO_REQUEST = "CASHIER_CASHSALE_FOLIO_REQUEST"
export const CASHIER_CASHSALE_FOLIO_REALTIME = "CASHIER_CASHSALE_FOLIO_REALTIME"
export const CASHIER_CASHSALE_FOLIO_PROCESS_SUCCESS = "CASHIER_CASHSALE_FOLIO_PROCESS_SUCCESS"
export const CASHIER_CASHSALE_FOLIO_DATASOURCE_SUCCESS = "CASHIER_CASHSALE_FOLIO_DATASOURCE_SUCCESS"
export const CASHIER_CASHSALE_FOLIO_PROCESS_RESET = "CASHIER_CASHSALE_FOLIO_PROCESS_RESET"
export const CASHIER_CASHSALE_FOLIO_SET_FILTER = "CASHIER_CASHSALE_FOLIO_SET_FILTER"

export const CASHIER_CASHSALE_FOLIO_TOGGLE_LOCK = "CASHIER_CASHSALE_FOLIO_TOGGLE_LOCK"
export const CASHIER_CASHSALE_FOLIO_TOGGLE_SUCCESS = "CASHIER_CASHSALE_FOLIO_TOGGLE_SUCCESS"
export const CASHIER_CASHSALE_CHANGE_ONLINE_SHIFT = "CASHIER_CASHSALE_CHANGE_ONLINE_SHIFT"

export const CASHIER_CASHSALE_FOLIO_DETAIL_REQUEST = "CASHIER_CASHSALE_FOLIO_DETAIL_REQUEST"
export const CASHIER_CASHSALE_FOLIO_DETAIL_DATASOURCE_SUCCESS = "CASHIER_CASHSALE_FOLIO_DETAIL_DATASOURCE_SUCCESS"
export const CASHIER_CASHSALE_INFO_UPDATE = "CASHIER_CASHSALE_INFO_UPDATE"
export const CASHIER_CASHSALE_INFO_UPDATE_SUCCESS = "CASHIER_CASHSALE_INFO_UPDATE_SUCCESS"

export const CASHIER_CASHSALE_FOLIO_POST = "CASHIER_CASHSALE_FOLIO_POST"
export const CASHIER_CASHSALE_FOLIO_VOID = "CASHIER_CASHSALE_FOLIO_VOID"
export const CASHIER_CASHSALE_FOLIO_UPDATE = "CASHIER_CASHSALE_FOLIO_UPDATE"
export const CASHIER_CASHSALE_FOLIO_PAYMENT = "CASHIER_CASHSALE_FOLIO_PAYMENT"
export const CASHIER_CASHSALE_FOLIO_SPLIT = "CASHIER_CASHSALE_FOLIO_SPLIT"
export const CASHIER_CASHSALE_FOLIO_MOVE = "CASHIER_CASHSALE_FOLIO_MOVE"

export const CASHIER_CASHSALE_CLOSE_FOLIO_SEQ = "CASHIER_CASHSALE_CLOSE_FOLIO_SEQ"
export const CASHIER_CASHSALE_OPEN_FOLIO = "CASHIER_CASHSALE_OPEN_FOLIO"
export const CASHIER_CASHSALE_CLOSE_FOLIO = "CASHIER_CASHSALE_CLOSE_FOLIO"
export const CASHIER_CASHSALE_REOPEN_FOLIO = "CASHIER_CASHSALE_REOPEN_FOLIO"

export interface CashierCashSaleFolioRequest {
  type: typeof CASHIER_CASHSALE_FOLIO_REQUEST,
  payload?:any,
  sorter?:any
}
export interface CashierCashSaleFolioRealTime {
  type: typeof CASHIER_CASHSALE_FOLIO_REALTIME,
  payload?:any
}
export interface CashierCashSaleFolioProcessSuccess {
  type: typeof CASHIER_CASHSALE_FOLIO_PROCESS_SUCCESS,
  payload:{}
}
export interface CashierCashSaleFolioDataSourceSuccess{
  type: typeof CASHIER_CASHSALE_FOLIO_DATASOURCE_SUCCESS,
  payload:any
}
export interface CashierCashSaleFolioProcessReset {
  type: typeof CASHIER_CASHSALE_FOLIO_PROCESS_RESET
}
export interface CashierCashSaleSetFilter{
  type: typeof CASHIER_CASHSALE_FOLIO_SET_FILTER,
  payload:any
}
export interface CashierCashSaleFolioDetailRequest {
  type: typeof CASHIER_CASHSALE_FOLIO_DETAIL_REQUEST,
  payload:number
}
export interface CashierCashSaleFolioDetailDataSourceSuccess{
  type: typeof CASHIER_CASHSALE_FOLIO_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}

export interface CashierCashSaleFolioPost{
  type: typeof CASHIER_CASHSALE_FOLIO_POST,
  payload:any
}

export interface CashierCashSaleFolioVoid{
  type: typeof CASHIER_CASHSALE_FOLIO_VOID,
  payload:any
}

export interface CashierCashSaleFolioUpdate{
  type: typeof CASHIER_CASHSALE_FOLIO_UPDATE,
  payload:any
}

export interface CashierCashSaleFolioPayment{
  type: typeof CASHIER_CASHSALE_FOLIO_PAYMENT,
  payload:any
}

export interface CashierCashSaleFolioSplit{
  type: typeof CASHIER_CASHSALE_FOLIO_SPLIT,
  payload:any
}
export interface CashierCashSaleFolioMove{
  type: typeof CASHIER_CASHSALE_FOLIO_MOVE,
  payload:any
}

export interface CashierCashSaleCloseFolioSeq{
  type: typeof CASHIER_CASHSALE_CLOSE_FOLIO_SEQ,
  payload:any
}

export interface CashierCashSaleToggleLock{
  type: typeof CASHIER_CASHSALE_FOLIO_TOGGLE_LOCK,
  payload:any
}

export interface CashierCashSaleToggleSuccess{
  type: typeof CASHIER_CASHSALE_FOLIO_TOGGLE_SUCCESS,
  payload:any
}

export interface CashierCashSaleInfoUpdate{
  type: typeof CASHIER_CASHSALE_INFO_UPDATE,
  payload:any
}

export interface CashierCashSaleInfoUpdateSuccess{
  type: typeof CASHIER_CASHSALE_INFO_UPDATE_SUCCESS,
  payload:any
}

export interface CashierCashSaleChangeOnlineShift{
  type: typeof CASHIER_CASHSALE_CHANGE_ONLINE_SHIFT,
  payload:any
}

export interface CashierCashSaleOpenFolio{
  type: typeof CASHIER_CASHSALE_OPEN_FOLIO,
  payload:any
}
export interface CashierCashSaleCloseFolio{
  type: typeof CASHIER_CASHSALE_CLOSE_FOLIO,
  payload:number
}
export interface CashierCashSaleReopenFolio{
  type: typeof CASHIER_CASHSALE_REOPEN_FOLIO,
  payload:any
}

export type CashierAction = 
| CashierCashSaleFolioRequest
| CashierCashSaleFolioRealTime
| CashierCashSaleFolioProcessSuccess
| CashierCashSaleFolioDataSourceSuccess
| CashierCashSaleFolioProcessReset
| CashierCashSaleSetFilter
| CashierCashSaleFolioDetailRequest
| CashierCashSaleFolioDetailDataSourceSuccess
| CashierCashSaleFolioPost
| CashierCashSaleFolioVoid
| CashierCashSaleFolioUpdate
| CashierCashSaleFolioPayment
| CashierCashSaleFolioSplit
| CashierCashSaleFolioMove
| CashierCashSaleCloseFolioSeq
| CashierCashSaleToggleLock
| CashierCashSaleToggleSuccess
| CashierCashSaleInfoUpdate
| CashierCashSaleInfoUpdateSuccess
| CashierCashSaleChangeOnlineShift
| CashierCashSaleOpenFolio
| CashierCashSaleCloseFolio
| CashierCashSaleReopenFolio