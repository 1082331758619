import { all, fork, put,takeLatest, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/setup/department/itemizerActionType"
import * as actionCreators from "appRedux/actions/setup/department/Itemizer"
import { userSignOut} from "appRedux/actions/Auth"
import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"
import { orderBy } from "lodash"
import { IndexedDBAdd } from "util/indexedDBLibrary"

function* itemizerPreviewProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/itemizer/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
      const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      { 
            const dataSource:Array<any> = response.data||[]
            const itemizerDataSource = orderBy(dataSource,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {} as any
                    container.key = key
                    container.itemizer_id = obj.id
                    container.itemizer_code = obj.code
                    container.itemizer_name = obj.name
                    container.itemizer_rate = obj.rate 
                    container.itemizer_amount = obj.amount 
                    container.itemizer_calc_on_baseprice = obj.calc_on_baseprice
                    container.itemizer_is_service = obj.is_service
                    container.itemizer_is_tax = obj.is_tax
                    container.itemizer_enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(actionCreators.itemizerDataSourceSuccess(itemizerDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreItemizerList",itemizerDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(actionCreators.itemizerProcessSuccess(processStatus))
            yield put(actionCreators.itemizerProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on itemizer preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.itemizerProcessSuccess(processStatus))
        yield put(actionCreators.itemizerProcessReset())
    }
    
}

function* itemizerCreateProcess({payload}:any){

  const itemizer_name = payload.itemizer_name
  const itemizer_code = payload.itemizer_code
  const itemizer_rate = parseFloat(payload.itemizer_rate)
  const itemizer_amount = payload.itemizer_amount
  const itemizer_calc_on_baseprice = payload.itemizer_calc_on_baseprice
  const itemizer_is_tax = payload.itemizer_is_tax
  const itemizer_is_service = payload.itemizer_is_service
  const itemizer_enabled = payload.itemizer_enabled

  let itemizerInfo = {name:itemizer_name,
                        code:itemizer_code,
                        rate:itemizer_rate,
                        amount:itemizer_amount,
                        calc_on_baseprice:itemizer_calc_on_baseprice,
                        is_service:itemizer_is_service,
                        is_tax:itemizer_is_tax,
                        enabled:itemizer_enabled}
      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/itemizer/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = itemizerInfo
            const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(actionCreators.itemizerProcessSuccess(processStatus))
                yield put(actionCreators.itemizerProcessReset())
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(actionCreators.itemizerProcessSuccess(processStatus))
              yield put(actionCreators.itemizerProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(actionCreators.itemizerProcessSuccess(processStatus))
            yield put(actionCreators.itemizerProcessReset())
      }
}

function* itemizerUpdateProcess({payload}:any){
  const itemizer_id = payload.itemizer_id
  const itemizer_name = payload.itemizer_name
  const itemizer_code = payload.itemizer_code
  const itemizer_rate = parseFloat(payload.itemizer_rate)
  const itemizer_amount = parseFloat(payload.itemizer_amount)
  const itemizer_calc_on_baseprice = payload.itemizer_calc_on_baseprice
  const itemizer_is_tax = payload.itemizer_is_tax
  const itemizer_is_service = payload.itemizer_is_service
  const itemizer_enabled = payload.itemizer_enabled

  let itemizerInfo = {id:itemizer_id,
                      name:itemizer_name,
                      code:itemizer_code,
                      rate:itemizer_rate,
                      amount:itemizer_amount,
                      calc_on_baseprice:itemizer_calc_on_baseprice,
                      is_service:itemizer_is_service,
                      is_tax:itemizer_is_tax,
                      enabled:itemizer_enabled}
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/itemizer/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = itemizerInfo
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(actionCreators.itemizerProcessSuccess(processStatus))
            yield put(actionCreators.itemizerProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(actionCreators.itemizerProcessSuccess(processStatus))
          yield put(actionCreators.itemizerProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(actionCreators.itemizerProcessSuccess(processStatus))
        yield put(actionCreators.itemizerProcessReset())
  }
}

function* itemizerRemoveProcess({payload}:any){
    const itemizerRemove = payload

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/itemizer/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : itemizerRemove}
        const response:ApiResponse = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(actionCreators.itemizerProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(actionCreators.itemizerProcessSuccess(processStatus))
          yield put(actionCreators.itemizerProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(actionCreators.itemizerProcessSuccess(processStatus))
      yield put(actionCreators.itemizerProcessReset())
    }
}

/* Watcher Saga */
export function* itemizerPreview() {
  yield takeLatest(actionTypes.ITEMIZER_REQUEST, itemizerPreviewProcess)
}

export function* itemizerCreate(){
  yield takeLatest(actionTypes.ITEMIZER_NEW,itemizerCreateProcess)
}
export function* itemizerUpdate(){
  yield takeLatest(actionTypes.ITEMIZER_UPDATE, itemizerUpdateProcess)
}
export function* itemizerRemove(){
  yield takeLatest(actionTypes.ITEMIZER_REMOVE,itemizerRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(itemizerPreview),
            fork(itemizerCreate),
            fork(itemizerUpdate),
            fork(itemizerRemove)
  ])


}
