
export const BLOCKROOM_REQUEST = "BLOCKROOM_REQUEST"
export const BLOCKROOM_BY_ROOM_NUMBER = "BLOCKROOM_BY_ROOM_NUMBER"
export const BLOCKROOM_BY_ROOM_ID = "BLOCKROOM_BY_ROOM_ID"
export const BLOCKROOM_UNBLOCK = "BLOCKROOM_UNBLOCK"
export const BLOCKROOM_UNBLOCK_ALL = "BLOCKROOM_UNBLOCK_ALL"
export const BLOCKROOM_AUTO_BLOCK = "BLOCKROOM_AUTO_BLOCK"
export const BLOCKROOM_SET_SUPERBLOCK = "BLOCKROOM_SET_SUPERBLOCK"
export const BLOCKROOM_CHANGE_ROOM = "BLOCKROOM_CHANGE_ROOM"
export const BLOCKROOM_DATASOURCE_SUCCESS = "BLOCKROOM_DATASOURCE_SUCCESS"
export const BLOCKROOM_PROCESS_SUCCESS = "BLOCKROOM_PROCESS_SUCCESS"
export const BLOCKROOM_PROCESS_RESET = "BLOCKROOM_PROCESS_RESET"


export interface BlockRoomRequest {
  type: typeof BLOCKROOM_REQUEST,
  payload?:any
}

export interface BlockRoomByRoomNumber {
  type: typeof BLOCKROOM_BY_ROOM_NUMBER,
  payload?:any
}

export interface BlockRoomByRoomID {
  type: typeof BLOCKROOM_BY_ROOM_ID,
  payload?:any
}
export interface BlockRoomUnblock {
  type: typeof BLOCKROOM_UNBLOCK,
  payload?:any
}
export interface BlockRoomUnblockAll {
  type: typeof BLOCKROOM_UNBLOCK_ALL,
  payload?:number
}
export interface BlockRoomAutoBlock {
  type: typeof BLOCKROOM_AUTO_BLOCK,
  payload?:any
}

export interface BlockRoomSetSuperBlock {
  type: typeof BLOCKROOM_SET_SUPERBLOCK,
  payload?:any
}
export interface BlockRoomProcessSuccess {
  type: typeof BLOCKROOM_PROCESS_SUCCESS,
  payload:{}
}
export interface BlockRoomChangeRoom{
  type: typeof BLOCKROOM_CHANGE_ROOM,
  payload:any
}
export interface BlockRoomDataSourceSuccess{
  type: typeof BLOCKROOM_DATASOURCE_SUCCESS,
  payload:any
}
export interface BlockRoomProcessReset{
  type: typeof BLOCKROOM_PROCESS_RESET
}


export type BlockRoomAction = 
| BlockRoomRequest
| BlockRoomByRoomNumber
| BlockRoomByRoomID
| BlockRoomUnblock
| BlockRoomUnblockAll
| BlockRoomAutoBlock
| BlockRoomSetSuperBlock
| BlockRoomProcessSuccess
| BlockRoomChangeRoom
| BlockRoomDataSourceSuccess
| BlockRoomProcessReset
