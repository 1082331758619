import {
  VIP_PREVIEW,
  VIP_DATASOURCE_SUCCESS,
  VIP_NEW,
  VIP_UPDATE,
  VIP_REMOVE,
  VIP_PROCESS_SUCCESS,
  VIP_PROCESS_RESET
} from "constants/ActionTypes"

export const vipPreview = () => {
  return {
    type : VIP_PREVIEW
  }
}

export const vipDataSourceUpdate = (value) => {
  return {
    type: VIP_DATASOURCE_SUCCESS,
    payload: value
  }
}

export const vipCreate = (value) =>{
  return {
    type:VIP_NEW,
    payload:value
  }
}
export const vipUpdate = (value) => {
  return {
    type: VIP_UPDATE,
    payload: value
  }
}

export const vipRemove = (value) => {
  return {
    type: VIP_REMOVE,
    payload: value
  }
}

export const vipProcessSuccess = (value) => {
  return{
    type:VIP_PROCESS_SUCCESS,
    payload:value
  }
}
export const vipProcessReset = () =>{
  return{
    type:VIP_PROCESS_RESET
  }
}






