import * as actions from "appRedux/actions/issueLog/IssueLogActionType"

export type IssueLogType = {
    id: number
    room_id: number
    card_qty: number
    log_time: string
    log_type: string
    log_user: null | string
    booking_id: number
    card_index: null | number
    valid_from: string
    card_serial: null | string
    register_id: null | number
    valid_until: string
}
export type IssueLogState = {
    loader: boolean,
    bookingIssueLog: Array<IssueLogType>,
    registrationIssueLog: Array<IssueLogType>,
    process: any
}


const initialState: IssueLogState = {
    loader: false,
    bookingIssueLog: [],
    registrationIssueLog: [],
    process: { status: false, action: "", message: "" }
}

export default (state: IssueLogState = initialState, action: actions.IssueLogAction): IssueLogState => {
    switch (action.type) {
        case actions.ISSUE_LOG_REQUEST: {
            if (action.payload.type === 'booking') {
                return {
                    ...state,
                    loader: true,
                    bookingIssueLog: [],
                    process: { status: false, action: "", message: "" }
                }
            } else
                return {
                    ...state,
                    loader: true,
                    registrationIssueLog: [],
                    process: { status: false, action: "", message: "" }
                }
        }
        case actions.ISSUE_LOG_DATASOURCE_SUCCESS: {
            if (action.payload.type === 'booking') {
                return {
                    ...state,
                    loader: false,
                    bookingIssueLog: action.payload.value
                }
            } else
                return {
                    ...state,
                    loader: false,
                    registrationIssueLog: action.payload.value
                }
        }
        case actions.ISSUE_LOG_PROCESS_SUCCESS: {
            return {
                ...state,
                loader: false,
                process: action.payload
            }
        }
        case actions.ISSUE_LOG_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.ISSUE_LOG_RESET: {
            if (!action.payload)
                return {
                    loader: false,
                    bookingIssueLog: [],
                    registrationIssueLog: [],
                    process: { status: false, action: "", message: "" }
                }
            else if (action.payload.type === 'booking')
                return {
                    ...state,
                    loader: false,
                    bookingIssueLog: [],
                    process: { status: false, action: "", message: "" }
                }
            else if (action.payload.type === 'registation')
                return {
                    ...state,
                    loader: false,
                    registrationIssueLog: [],
                    process: { status: false, action: "", message: "" }
                }
            else
                return state
            
        }
        default:
            return state
    }
}