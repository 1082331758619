import { put, call } from "redux-saga/effects"

import { 
    AppAction,
    CallReturnType,
    REPORT_MENU_GET_SUCCESS,
    REPORT_MENU_GET_FAIL
} from 'constants/ActionTypes'
import {
    ReportOptionsState,
    REPORT_OPTIONS_GET_SUCCESS,
    REPORT_OPTIONS_GET_FAIL
} from 'appRedux/actions/report/report-actionTypes'
import { ReportAllState, ReportState, ReportsState } from 'interfaces/report'
import { commonAction, userSignOut,  } from 'appRedux/actions'

import { reportApi } from './report-api'

export function* reportOptionsWorker(action: AppAction<string, number>) {
    try {
        const response: CallReturnType<typeof reportApi.reportOptions> = yield call(reportApi.reportOptions, action.payload)
        const { status, message, data } = response

        if(status === 200) {
            // Action success then return this function --> Reducer
            yield put(commonAction<string, ReportOptionsState>(REPORT_OPTIONS_GET_SUCCESS, data))
        } else {
            // Action fail then return this function --> Reducer
            yield put(commonAction<string, string>(REPORT_OPTIONS_GET_FAIL, message))

            if(status === 403){
                /* if status 403(Forbidden) that force signout */
                yield put(userSignOut())
            }
        }
    } catch (error) {
        yield put(commonAction<string, string>(REPORT_MENU_GET_SUCCESS, `${error}`))
    }
}

const jointReports = (values: ReportAllState[]): ReportsState[] => {
    let init_report: ReportsState[] = []
    for(let value of values) {
        init_report = init_report.concat(value.reports)
    }
    return init_report
}
/**
 * Separate value
 * 1. Separate follow by category (for filter by category)
 * 2. Add all report (for filtter by report name)
 * @param {*} value 
 */
const improveReportState = (values: ReportAllState[]): ReportState => {
    return {
        category_report: values, //jointCategory(values),
        all_report: jointReports(values)
    }
}

export function* reportMenuWorker(action: AppAction<string, null>) {
    try {
        const response: CallReturnType<typeof reportApi.reportMenu> = yield call(reportApi.reportMenu)

        const { status, message, data } = response
        // console.log('reportMenuWorker', data)

        if(status === 200) {
            const complete_data = improveReportState(data)
            // console.log('complete_data-->', complete_data)
            // Action success then return this function --> Reducer
            yield put(commonAction<string, ReportState>(REPORT_MENU_GET_SUCCESS, complete_data))
        } else {
            // Action fail then return this function --> Reducer
            yield put(commonAction<string, string>(REPORT_MENU_GET_FAIL, message))
            
            if(status === 403){
                /* if status 403(Forbidden) that force signout */
                yield put(userSignOut())
            }            
        }
    } catch (error) {
        yield put(commonAction<string, string>(REPORT_MENU_GET_FAIL, `${error}`))
    }
}