export const EXPECTED_ARRIVAL_REQUEST = "EXPECTED_ARRIVAL_REQUEST"
export const EXPECTED_ARRIVAL_DATASOURCE_SUCCESS = "EXPECTED_ARRIVAL_DATASOURCE_SUCCESS"
export const EXPECTED_ARRIVAL_PROCESS_SUCCESS = "EXPECTED_ARRIVAL_PROCESS_SUCCESS"
export const EXPECTED_ARRIVAL_PROCESS_RESET = "EXPECTED_ARRIVAL_PROCESS_RESET"
export const EXPECTED_ARRIVAL_SET_FILTER = "EXPECTED_ARRIVAL_SET_FILTER"

export interface ExpectedArrivalRequest {
  type: typeof EXPECTED_ARRIVAL_REQUEST,
  payload?:any
}

export interface ExpectedArrivalProcessSuccess {
  type: typeof EXPECTED_ARRIVAL_PROCESS_SUCCESS,
  payload:{}
}
export interface ExpectedArrivalDataSourceSuccess{
  type: typeof EXPECTED_ARRIVAL_DATASOURCE_SUCCESS,
  payload:any
}
export interface ExpectedArrivalProcessReset {
  type: typeof EXPECTED_ARRIVAL_PROCESS_RESET
}
export interface ExpectedArrivalSetFilter{
  type: typeof EXPECTED_ARRIVAL_SET_FILTER,
  payload:any
}

export type ExpectedArrivalAction = 
| ExpectedArrivalRequest
| ExpectedArrivalProcessSuccess
| ExpectedArrivalDataSourceSuccess
| ExpectedArrivalProcessReset
| ExpectedArrivalSetFilter