import {
    ROOMINFO_PREVIEW,
    ROOMINFO_NEW,
    ROOMINFO_UPDATE,
    ROOMINFO_REMOVE,
    ROOMINFO_SEARCH,
    ROOMINFO_PROCESS_SUCCESS,
    ROOMINFO_DATASOURCE_SUCCESS,
    ROOMINFO_DETAIL,
    ROOMINFO_DETAIL_SUCCESS,
    ROOMINFO_PROCESS_RESET
  } from "constants/ActionTypes"

  export const roomInfoPreview = (value) => {
    return {
      type: ROOMINFO_PREVIEW,
      payload:value
    }
  }

  export const roomInfoCreate =(value) =>{
    return {
      type:ROOMINFO_NEW,
      payload:value
    }
  }

  export const roomInfoUpdate = (value) =>{
    return {
      type:ROOMINFO_UPDATE,
      payload: value
    }
  }

  export const roomInfoRemove = (value)=>{
    return {
      type:ROOMINFO_REMOVE,
      payload:value
    }
  }

  export const roomInfoSearch = (keyword) =>{
    return {
      type: ROOMINFO_SEARCH,
      payload:keyword
    }
  }

  export const roomInfoProcessSuccess = (value) =>{
    return{
      type:ROOMINFO_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const roomInfoDataSourceUpdate = (value) => {
    return {
      type: ROOMINFO_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const roomInfoDetail = (value) =>{
    return{
      type:ROOMINFO_DETAIL,
      payload: value
    }
  }
  export const roomInfoDetailSuccess = (value) =>{
    return{
      type:ROOMINFO_DETAIL_SUCCESS,
      payload: value
    }
  }
  export const roomInfoProcessReset = () =>{
    return{
      type:ROOMINFO_PROCESS_RESET
    }
  }

