import {
    OUT_OF_ORDER_PREVIEW,
    OUT_OF_ORDER_NEW,
    OUT_OF_ORDER_UPDATE,
    OUT_OF_ORDER_REMOVE,
    OUT_OF_ORDER_SEARCH,
    OUT_OF_ORDER_PROCESS_SUCCESS,
    OUT_OF_ORDER_DATASOURCE_SUCCESS,
    OUT_OF_ORDER_PROCESS_RESET
  } from "constants/ActionTypes"

  export const outOfOrderPreview = (value) => {
    return {
      type: OUT_OF_ORDER_PREVIEW,
      payload:value
    }
  }

  export const outOfOrderCreate =(value) =>{
    return {
      type:OUT_OF_ORDER_NEW,
      payload:value
    }
  }

  export const outOfOrderUpdate = (value) =>{
    return {
      type:OUT_OF_ORDER_UPDATE,
      payload: value
    }
  }

  export const outOfOrderRemove = (value)=>{
    return {
      type:OUT_OF_ORDER_REMOVE,
      payload:value
    }
  }

  export const outOfOrderSearch = (keyword) =>{
    return {
      type: OUT_OF_ORDER_SEARCH,
      payload:keyword
    }
  }

  export const outOfOrderProcessSuccess = (value) =>{
    return{
      type:OUT_OF_ORDER_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const outOfOrderDataSourceUpdate = (value) => {
    return {
      type: OUT_OF_ORDER_DATASOURCE_SUCCESS,
      payload: value
    }
  }

  export const outOfOrderProcessReset = () =>{
    return{
      type:OUT_OF_ORDER_PROCESS_RESET
    }
  }
