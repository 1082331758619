import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  MARKET_PREVIEW,
  MARKET_NEW,
  MARKET_UPDATE,
  MARKET_REMOVE,
  MARKET_SEARCH
} from "constants/ActionTypes"

import {marketDataSourceUpdate,marketProcessSuccess,marketProcessReset} from "appRedux/actions/setup/market/MarketZone"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* marketPreviewProcess({payload}){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/market/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const marketDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.market_id = obj.id
                    container.market_name = obj.name
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
             yield put(marketDataSourceUpdate(marketDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreMarketList",marketDataSource)
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(marketProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on saga market : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(marketProcessSuccess(processStatus))
    }
    
}

function* marketSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMarketList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_market_name = filter(ReformalData, function(o) {
        return o.market_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_market_name])]

      yield put(marketDataSourceUpdate(mergeData))
  }else 
      yield put(marketDataSourceUpdate(ReformalData))
}

function* marketCreateProcess({payload}){
  const {market_name,active:marketActive} = payload

  let marketInfo = {name:market_name,
                    enabled:marketActive}
      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/market/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = marketInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(marketProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(marketProcessSuccess(processStatus))
              yield put(marketProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(marketProcessSuccess(processStatus))
            yield put(marketProcessReset())
      }
}

function* marketUpdateProcess({payload}){
  const { market_name,market_id,active:marketActive} = payload
 
  let marketInfo = {}
      marketInfo.id = market_id
      marketInfo.name = market_name
      marketInfo.enabled = marketActive
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/market/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = marketInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(marketProcessSuccess(processStatus))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(marketProcessSuccess(processStatus))
          yield put(marketProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(marketProcessSuccess(processStatus))
        yield put(marketProcessReset())
  }
}

function* marketRemoveProcess({payload}){
    const marketRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/market/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : marketRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(marketProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(marketProcessSuccess(processStatus))
          yield put(marketProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(marketProcessSuccess(processStatus))
      yield put(marketProcessReset())
    }
}

/* Watcher Saga */
export function* marketPreview() {
  yield takeLatest(MARKET_PREVIEW, marketPreviewProcess)
}
export function* marketSearch(){
  yield takeLatest(MARKET_SEARCH, marketSearchProcess)
}
export function* marketCreate(){
  yield takeEvery(MARKET_NEW,marketCreateProcess)
}
export function* marketUpdate(){
  yield takeEvery(MARKET_UPDATE, marketUpdateProcess)
}
export function* marketRemove(){
  yield takeEvery(MARKET_REMOVE,marketRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(marketPreview),
             fork(marketSearch),
             fork(marketCreate),
             fork(marketUpdate),
             fork(marketRemove)])


}
