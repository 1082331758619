import * as actions from "./ChargeInhouseActionType"

export const chargeInhousePreview = (payload?: any): actions.ChargeInhouseRequest => {
  return {
    type: actions.CHARGE_INHOUSE_REQUEST,
    payload
  }
}
export const chargeInhouseDetailRequest = (payload?: any): actions.ChargeInhouseDetailRequest => {
  return {
    type: actions.CHARGE_INHOUSE_DETAIL_REQUEST,
    payload
  }
}

export const chargeInhouseUpdate= (payload?: any): actions.ChargeInhouseUpdate => {
  return {
    type: actions.CHARGE_INHOUSE_UPDATE,
    payload
  }
}

export const chargeInhouseProcessSuccess = (payload:any):actions.ChargeInhouseProcessSuccess =>{
  return{
    type: actions.CHARGE_INHOUSE_PROCESS_SUCCESS,
    payload
  }
}

export const chargeInhouseDataSourceSuccess = (payload:any):actions.ChargeInhouseDataSourceSuccess =>{
  return {
    type: actions.CHARGE_INHOUSE_DATASOURCE_SUCCESS,
    payload
  }
}

export const chargeInhouseDetailDataSourceSuccess = (payload:any):actions.ChargeInhouseDetailDataSourceSuccess =>{
  return {
    type: actions.CHARGE_INHOUSE_DETAIL_DATASOURCE_SUCCESS,
    payload
  }
}


export const chargeInhouseSetFilter = (payload:any): actions.ChargeInhouseSetFilter =>{
  return {
    type: actions.CHARGE_INHOUSE_SET_FILTER,
    payload
  }
}

export const chargeInhouseProcessReset=() =>{
  return{
    type:actions.CHARGE_INHOUSE_PROCESS_RESET
  } 
}
