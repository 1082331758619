import * as actions from "./autoPostRoomType"

export const autoPostRoomPreview = (): actions.AutoPostRoomRequest => {
    return {
        type: actions.AUTOPOST_ROOM_REQUEST
    }
}

export const autoPostRoomRun = (payload:any): actions.AutoPostRoomRun => {
    return {
        type: actions.AUTOPOST_ROOM_RUN,
        payload
    }
}

export const autoPostRoomRealTimeUpdate = (payload: any): actions.AutoPostRoomRealTimeUpdate => {
    return {
        type: actions.AUTOPOST_ROOM_REALTIME_UPDATE,
        payload
    }
}

export const autoPostRoomProcessSuccess = (payload: any): actions.AutoPostRoomProcessSuccess => {
    return {
        type: actions.AUTOPOST_ROOM_PROCESS_SUCCESS,
        payload
    }
}

export const autoPostRoomDataSourceUpdate = (payload: any): actions.AutoPostRoomDataSourceSuccess => {
    return {
        type: actions.AUTOPOST_ROOM_DATASOURCE_SUCCESS,
        payload
    }
}
export const autoPostRoomProcessReset = (): actions.AutoPostRoomProcessReset => {
    return {
        type: actions.AUTOPOST_ROOM_PROCESS_RESET
    }
}

