import { all, fork, put,takeEvery, call }  from "redux-saga/effects"
import * as actionTypes from "appRedux/actions/expected/ExpectedDepartureActionType"
import * as actionCreators from "appRedux/actions/expected/ExpectedDeparture"
import { userSignOut} from "appRedux/actions/Auth"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import moment from 'moment'
import { APIGetRequest } from "util/connection"
import { getItems } from "util/localStorage"
import { ApiResponse } from "constants/ActionTypes"

function* expectedDepartureRequestProcess({ payload }: actionTypes.ExpectedDepartureRequest) {
    yield console.log("Search ",payload)
    let keywords = payload
    const guestInhouseInfo = {} as any
    if(keywords.searchText)
      guestInhouseInfo.search_text = keywords.searchText
    if(keywords.arrival)
      guestInhouseInfo.arrival = moment(keywords.arrival).format('YYYY-MM-DD')
    if(keywords.departure)
      guestInhouseInfo.departure = moment(keywords.departure).format('YYYY-MM-DD')
    if(keywords.stayDate)
      guestInhouseInfo.stay_date = moment(keywords.stayDate).format('YYYY-MM-DD')
    if(keywords.roomNumber)
      guestInhouseInfo.room_number= keywords.roomNumber
    if(keywords.roomTypeId)
      guestInhouseInfo.roomtype_id= keywords.roomTypeId
    if(keywords.channelName)
      guestInhouseInfo.channel_name= keywords.channelName
    if(keywords.vipId)
      guestInhouseInfo.vip_id= keywords.vipId
    if(keywords.memberCode)
      guestInhouseInfo.member_code= keywords.memberCode
    if(keywords.company)
      guestInhouseInfo.company= keywords.company
    if(keywords.nationId)
      guestInhouseInfo.nation_id= keywords.nationId
    if(keywords.ratePlanId)
      guestInhouseInfo.rateplan_id= keywords.ratePlanId
    if(keywords.carplateNo)
      guestInhouseInfo.carplate_no = keywords.carplateNo
    if(keywords.registerId)
      guestInhouseInfo.register_id = keywords.registerId
    if(keywords.bookingId)
      guestInhouseInfo.booking_id = keywords.bookingId

    /* Create query string from array object */
    let queryString = Object.keys(guestInhouseInfo).map(key =>`${key}=${guestInhouseInfo[key]}`).join('&')

    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/registration/expectedDeparture/?${queryString}`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`

    try {
        const response:ApiResponse = yield APIGetRequest(url,API_PMS_HEADERS)
        if(response.status === 200)
        {      
                const expectDepartureInfo = response.data
                /* if no error put everything to reducer */
                yield put(actionCreators.expectedDepartureDataSourceSuccess(expectDepartureInfo))
                yield put(actionCreators.expectedDepartureSetFilter({...payload})) 

        }else{
                console.log("Error : ",response)
                /* Show error message and signout */
                const {message,data} = response.data
                const processStatus = { status: false, action: "preview", message}
                yield put(actionCreators.expectedDepartureProcessSuccess(processStatus))
                if(response.status === 403){
                //if data is not null force signout 
                  if(data)
                      yield put(userSignOut())
                }
                
        }
    } catch (error) {
        console.log("Error on expected departure preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(actionCreators.expectedDepartureProcessSuccess(processStatus))
    }
}

function* expectedDepartureRequest() {
  yield takeEvery(actionTypes.EXPECTED_DEPARTURE_REQUEST, expectedDepartureRequestProcess)
}


export default function* rootSaga() {
  yield all([fork(expectedDepartureRequest)
  ])
}


