import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  ORIGIN_PREVIEW,
  ORIGIN_NEW,
  ORIGIN_UPDATE,
  ORIGIN_REMOVE,
  ORIGIN_SEARCH
} from "constants/ActionTypes"

import {originDataSourceUpdate,originProcessSuccess,originProcessReset} from "appRedux/actions/setup/market/Origin"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* originPreviewProcess({payload}){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/origin/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const originDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.origin_id = obj.id
                    container.origin_name = obj.name
                    container.origin_code = obj.code
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(originDataSourceUpdate(originDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreOriginList",originDataSource)
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(originProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on saga channel type : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(originProcessSuccess(processStatus))
    }
    
}

function* originSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll("ListBoxDB","objStoreOriginList")
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by keyword */
      const filtered_origin = filter(ReformalData, function(o) {
        return o.origin_name.toLowerCase().indexOf(keyword) > -1 || o.orgin_code.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_origin])]

      yield put(originDataSourceUpdate(mergeData))
  }else 
      yield put(originDataSourceUpdate(ReformalData))
}

function* originCreateProcess({payload}){
  const { origin_code,origin_name,active:originActive} = payload

  let originInfo = {code:origin_code,
                    name:origin_name,
                    enabled:originActive}

      
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/origin/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = originInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
              const processStatus = { status: true, action: "create", message:"Create success." }
              yield put(originProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(originProcessSuccess(processStatus))
              yield put(originProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(originProcessSuccess(processStatus))
            yield put(originProcessReset())
      }
}

function* originUpdateProcess({payload}){
  const { origin_code,origin_name,origin_id,active:originActive } = payload

  let originInfo = {}
      originInfo.id = origin_id
      originInfo.code = origin_code
      originInfo.name = origin_name
      originInfo.enabled = originActive
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/origin/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = originInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
          const processStatus = { status: true, action: "update", message:"Update success." }
          yield put(originProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(originProcessSuccess(processStatus))
          yield put(originProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }        

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(originProcessSuccess(processStatus))
        yield put(originProcessReset())
  }
}

function* originRemoveProcess({payload}){
    const originRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/origin/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : originRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(originProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message }
          yield put(originProcessSuccess(processStatus))
          yield put(originProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }     
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(originProcessSuccess(processStatus))
      yield put(originProcessReset())
    }
}

/* Watcher Saga */
export function* originPreview() {
  yield takeLatest(ORIGIN_PREVIEW, originPreviewProcess)
}
export function* originSearch(){
  yield takeLatest(ORIGIN_SEARCH, originSearchProcess)
}
export function* originCreate(){
  yield takeEvery(ORIGIN_NEW,originCreateProcess)
}
export function* originUpdate(){
  yield takeEvery(ORIGIN_UPDATE, originUpdateProcess)
}
export function* originRemove(){
  yield takeEvery(ORIGIN_REMOVE,originRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(originPreview),
             fork(originSearch),
             fork(originCreate),
             fork(originUpdate),
             fork(originRemove)])


}
