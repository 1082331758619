import {
  NATIONALITY_PREVIEW,
  NATIONALITY_NEW,
  NATIONALITY_UPDATE,
  NATIONALITY_REMOVE,
  NATIONALITY_PROCESS_SUCCESS,
  NATIONALITY_DATASOURCE_SUCCESS,
  NATIONALITY_SEARCH,
  NATIONALITY_PROCESS_RESET
  
} from "constants/ActionTypes"

const INIT_STATE = {
  loader: false,
  dataSerialize:{},
  dataSource:[],
  process:{},
  keyword:''
}

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case NATIONALITY_PREVIEW: {
      return {
        ...state,
        loader: false,
        process:{status:false,action:"",message:""}
      }
    }
    case NATIONALITY_NEW: {
      return {
        ...state,
        loader: true,
        process:{status:false,action:"",message:""}
      }
    }
    case NATIONALITY_UPDATE:{
        return{
        ...state,
        loader:true,
        process:{status:false,action:"",message:""},
        dataSerialize:action.payload
      }
    }
    case NATIONALITY_REMOVE: {
      return {
        ...state,
        loader: false
      }
    }
    case NATIONALITY_SEARCH:{
      return {
        ...state,
        loader:false,
        keyword:action.payload
      }
    }
    case NATIONALITY_PROCESS_SUCCESS:{
      return{
        ...state,
        loader:false,
        process:action.payload
      }
    }
    case NATIONALITY_DATASOURCE_SUCCESS:{
      return {
        ...state,
        loader: false,
        dataSource: action.payload
      }
    }
    case NATIONALITY_PROCESS_RESET:{
      return{
        ...state,
        process:{}
      }
    }
    default:
      return state
  }
}