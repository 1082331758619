import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestConfigParam, AxiosRequestConfigData, AxiosResponse } from "axios"

import { API_URL } from "constants/ApiSetting"

export class Api {
    private api: AxiosInstance

    public constructor (config: AxiosRequestConfig) {
        this.api = axios.create(config)

        this.api.interceptors.request.use((param: AxiosRequestConfig) => {
            const config = {
                ...param,
            baseURL: `${API_URL}`,
            }
            // console.log('config', config)

            return {
                ...config
            }
        })
    }

    /**
     * ### RequestConfig for POST Method
     * #### How to use this function
     * const requestConfig = this.requestConfig`<Interface45454545APIParams>`({
     *      ...AxiosRequestConfig...
     *  })
     * 
     * #### AxiosRequestConfig Headers
     * Example: `Key: Authorization, Value: 'PPP'`
     * 
     * How to use: in AxiosRequestConfig `headers: { Authorization: 'PPP' }`
     * 
     * #### Method POST 
     * 
     * How to use: in AxiosRequestConfig `data: { 
     *                      params1_in_Interface45454545APIParams: 'some_value1',  
     *                      params2_in_Interface45454545APIParams: 'some_value2'
     *                  }`
     * 
     * @template P interface parameters that sent to API
     * @param config AxiosRequestConfigData 
     */
    public postConfig<D>({ headers, data }: AxiosRequestConfigData<D>): AxiosRequestConfigData<D> {
        return {
            headers,
            data
        }
    }

    /**
     * ### RequestConfig for GET Method
     * #### How to use this function
     * const requestConfig = this.requestConfig`<Interface2222APIParams>`({
     *      ...AxiosRequestConfig...
     *  })
     * 
     * #### AxiosRequestConfig Headers
     * Example: `Key: Authorization, Value: 'PPP'`
     * 
     * How to use: in AxiosRequestConfig `headers: { Authorization: 'PPP' }`
     * 
     * #### Method GET 
     * 
     * How to use: in AxiosRequestConfig `params: { 
     *                      params1_in_Interface2222APIParams: 'some_value1',  
     *                      params2_in_Interface2222APIParams: 'some_value2'
     *                  }`
     * 
     * @template P interface parameters that sent to API
     * @param config AxiosRequestConfigParam
     */
    public getConfig<P>({ headers, params }: AxiosRequestConfigParam<P>): AxiosRequestConfigParam<P> {
        return {
            headers,
            params
        }
    }

    public getUri (config?: AxiosRequestConfig): string {
        return this.api.getUri(config)
    }

    public request<T, R = AxiosResponse<T>> (config: AxiosRequestConfig): Promise<R> {
        return this.api.request(config)
    }

    /**
     * Request API by Axios
     * Method: GET
     * @param url Path of url like so /v1/pms/cashier/folio/search/
     * @param config 
     */
    public async get<T, R = AxiosResponse<T>> (url: string, config: AxiosRequestConfig): Promise<R> {
        // console.log('Axios:Api.get', url, config)
        return await this.api.get(url, config)
            .then(response => response)
            .catch(error => error.response)

        // try {
        //     const response = await this.api.get(url, config)
        //     return response.data
        // } catch (error) {
        //     return error.response
        // }

        // return this.api.get(url, config)

        // return this.api.get(url, config)
        // .then(response => response)
        // .catch(error => error.response)

    }

    public async post<T, R = AxiosResponse<T>> (url: string, config: AxiosRequestConfig): Promise<R> {
        return await this.api.post(url, config.data, config)
            .then(response => response)
            .catch(error => error.response)
        // return this.api.post(url, data, config)
    }
    // public post<T, D, R = AxiosResponse<T>> (url: string, data: D, config?: AxiosRequestConfig): Promise<R> {
    //     return this.api.post(url, data, config)
    // }

    public delete<T, R = AxiosResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, config)
    }

    public head<T, R = AxiosResponse<T>> (url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.head(url, config)
    }

    public put<T, D, R = AxiosResponse<T>> (url: string, data: D, config?: AxiosRequestConfig): Promise<R> {
        return this.api.put(url, data, config)
    }

    public patch<T, R = AxiosResponse<T>> (url: string, data?: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.patch(url, data, config)
    }

}