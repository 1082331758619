import {
    BUILDING_PREVIEW,
    BUILDING_NEW,
    BUILDING_UPDATE,
    BUILDING_REMOVE,
    BUILDING_SEARCH,
    BUILDING_PROCESS_SUCCESS,
    BUILDING_DATASOURCE_SUCCESS,
    BUILDING_PROCESS_RESET
  } from "constants/ActionTypes"

  export const buildingPreview = (value) => {
    return {
      type: BUILDING_PREVIEW,
      payload:value
    }
  }

  export const buildingCreate =(value) =>{
    return {
      type:BUILDING_NEW,
      payload:value
    }
  }

  export const buildingUpdate = (value) =>{
    return {
      type:BUILDING_UPDATE,
      payload: value
    }
  }

  export const buildingRemove = (value)=>{
    return {
      type:BUILDING_REMOVE,
      payload:value
    }
  }

  export const buildingSearch = (keyword) =>{
    return {
      type: BUILDING_SEARCH,
      payload:keyword
    }
  }

  export const buildingProcessSuccess = (value) =>{
    return{
      type:BUILDING_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const buildingDataSourceUpdate = (value) => {
    return {
      type: BUILDING_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const buildingProcessReset = () =>{
    return{
      type:BUILDING_PROCESS_RESET
    }
  }

