export const BATCH_REPORT_REQUEST = "BATCH_REPORT_REQUEST"
export const BATCH_REPORT_DETAIL_REQUEST = "BATCH_REPORT_DETAIL_REQUEST"
export const BATCH_REPORT_PROCESS_SUCCESS = "BATCH_REPORT_PROCESS_SUCCESS"
export const BATCH_REPORT_DATASOURCE_SUCCESS = "BATCH_REPORT_DATASOURCE_SUCCESS"
export const BATCH_REPORT_DETAIL_DATASOURCE_SUCCESS = "BATCH_REPORT_DETAIL_DATASOURCE_SUCCESS"
export const BATCH_REPORT_DETAIL_VIEWMODE_DATASOURCE_SUCCESS = "BATCH_REPORT_DETAIL_VIEWMODE_DATASOURCE_SUCCESS"
export const BATCH_REPORT_PROCESS_RESET = "BATCH_REPORT_PROCESS_RESET"

export const BATCH_REPORT_NEW = "BATCH_REPORT_NEW"
export const BATCH_REPORT_UPDATE = "BATCH_REPORT_UPDATE"
export const BATCH_REPORT_REMOVE = "BATCH_REPORT_REMOVE"
export const BATCH_REPORT_DUPLICATE = "BATCH_REPORT_DUPLICATE"
export const BATCH_REPORT_RENAME = "BATCH_REPORT_RENAME"
export const BATCH_REPORT_OPTION_REQUEST = "BATCH_REPORT_OPTION_REQUEST"
export const BATCH_REPORT_SAVE_OPTION = "BATCH_REPORT_SAVE_OPTION"
export const BATCH_REPORT_OPTION_REQUEST_SUCCESS = "BATCH_REPORT_OPTION_REQUEST_SUCCESS"
export const BATCH_REPORT_OPTION_RESET = "BATCH_REPORT_OPTION_RESET"

export const BATCH_REPORT_RUN = "BATCH_REPORT_RUN"
export const BATCH_REPORT_SWITCH = "BATCH_REPORT_SWITCH"


export interface BatchReportRequest {
  type: typeof BATCH_REPORT_REQUEST,
  payload?:boolean
}
export interface BatchReportDetailRequest {
  type: typeof BATCH_REPORT_DETAIL_REQUEST,
  payload?:any
}

export interface BatchReportProcessSuccess {
  type: typeof BATCH_REPORT_PROCESS_SUCCESS,
  payload:{}
}
export interface BatchReportDataSourceSuccess{
  type: typeof BATCH_REPORT_DATASOURCE_SUCCESS,
  payload:any
}
export interface BatchReportDetailDataSourceSuccess{
  type: typeof BATCH_REPORT_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}
export interface BatchReportDetailViewModeDataSourceSuccess{
  type: typeof BATCH_REPORT_DETAIL_VIEWMODE_DATASOURCE_SUCCESS,
  payload:any
}

export interface BatchReportProcessReset {
  type: typeof BATCH_REPORT_PROCESS_RESET
}

export interface BatchReportNew{
  type: typeof BATCH_REPORT_NEW,
  payload:any
}
export interface BatchReportUpdate{
  type: typeof BATCH_REPORT_UPDATE,
  payload:any
}
export interface BatchReportRemove{
  type: typeof BATCH_REPORT_REMOVE,
  payload:any
}
export interface BatchReportDuplicate{
  type: typeof BATCH_REPORT_DUPLICATE,
  payload:any
}
export interface BatchReportRename{
  type: typeof BATCH_REPORT_RENAME,
  payload:any
}
export interface BatchReportOptionRequest{
  type: typeof BATCH_REPORT_OPTION_REQUEST,
  payload:any
}
export interface BatchReportOptionRequestSuccess{
  type: typeof BATCH_REPORT_OPTION_REQUEST_SUCCESS,
  payload:any
}
export interface BatchReportSaveOption{
  type: typeof BATCH_REPORT_SAVE_OPTION,
  payload:any
}
export interface BatchReportRun{
  type: typeof BATCH_REPORT_RUN,
  payload:{
    batchID:number[],
    userName?:string
  }
}
export interface BatchReportOptionReset{
  type: typeof BATCH_REPORT_OPTION_RESET
}
export interface BatchReportSwitch{
  type: typeof BATCH_REPORT_SWITCH,
  payload:any
}

export type BatchReportAction = 
| BatchReportRequest
| BatchReportDetailRequest
| BatchReportProcessSuccess
| BatchReportDataSourceSuccess
| BatchReportDetailDataSourceSuccess
| BatchReportDetailViewModeDataSourceSuccess
| BatchReportProcessReset
| BatchReportNew
| BatchReportUpdate
| BatchReportRemove
| BatchReportDuplicate
| BatchReportRename
| BatchReportOptionRequest
| BatchReportSaveOption
| BatchReportOptionRequestSuccess
| BatchReportRun
| BatchReportOptionReset
| BatchReportSwitch
