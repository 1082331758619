import * as actions from "appRedux/actions/roomPlan/roomplanEventActionType"
import { DateApi } from 'types'

export interface EventParams {
    start_date: DateApi
    end_date: DateApi
}

export interface roomplanEventType {
    loader: boolean,
    dataSource: Array<any>,
    process: any
}

const initialState: roomplanEventType = {
    loader: false,
    dataSource: [],
    process: { status: false, action: "", message: "" }
}

export default (state: roomplanEventType = initialState, action: actions.RoomPlanEventAction): roomplanEventType => {
    switch (action.type) {
        case actions.ROOMPLAN_EVENT_REQUEST: {
            return {
                ...state,
                loader: true,
                process: { status: false, action: "", message: "" }
            }
        }
        case actions.ROOMPLAN_EVENT_DATASOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                dataSource: action.payload
            }
        }
        case actions.ROOMPLAN_EVENT_PROCESS_SUCCESS: {
            return {
                ...state,
                process: action.payload
            }
        }
        case actions.ROOMPLAN_EVENT_PROCESS_RESET: {
            return {
                ...state,
                process: { status: false, action: "", message: "" }
            }
        }
        default:
            return state
    }
}