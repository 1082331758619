import {
    ORIGIN_PREVIEW,
    ORIGIN_NEW,
    ORIGIN_UPDATE,
    ORIGIN_REMOVE,
    ORIGIN_SEARCH,
    ORIGIN_PROCESS_SUCCESS,
    ORIGIN_DATASOURCE_SUCCESS,
    ORIGIN_PROCESS_RESET
  } from "constants/ActionTypes"

  export const originPreview = (value) => {
    return {
      type: ORIGIN_PREVIEW,
      payload:value
    }
  }

  export const originCreate =(value) =>{
    return {
      type:ORIGIN_NEW,
      payload:value
    }
  }

  export const originUpdate = (value) =>{
    return {
      type:ORIGIN_UPDATE,
      payload: value
    }
  }

  export const originRemove = (value)=>{
    return {
      type:ORIGIN_REMOVE,
      payload:value
    }
  }

  export const originSearch = (keyword) =>{
    return {
      type: ORIGIN_SEARCH,
      payload:keyword
    }
  }

  export const originProcessSuccess = (value) =>{
    return{
      type:ORIGIN_PROCESS_SUCCESS,
      payload:value
    }
  }

  export const originDataSourceUpdate = (value) => {
    return {
      type: ORIGIN_DATASOURCE_SUCCESS,
      payload: value
    }
  }
  export const originProcessReset = () =>{
    return{
      type:ORIGIN_PROCESS_RESET
    }
  }

