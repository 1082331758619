import React, { Component } from "react"
import { connect } from "react-redux"
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom"
import IdleTimer from 'react-idle-timer'
import { ConfigProvider } from "antd"
import { IntlProvider } from "react-intl"
import AppLocale from "lngProvider"
import { getItems } from 'util/custom'
import { setInitUrl, userSignOut } from "appRedux/actions/Auth"
// import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting"
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from 'appRedux/actions'

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK
} from "../../constants/ThemeSetting"

import MainApp from "./MainApp"
import SignIn from '../SignIn'
import ActivateUser from '../Activate/ActivateUser'
import OtpVerify from '../Activate/OtpVerify'
import { isEmpty } from "lodash"
import RequestResetUser from "containers/Activate/RequestResetUser"


const RestrictedRoute = ({ component: Component, access_token, location, ...rest }) =>
  <Route
    {...rest}
    render={props =>
      access_token !== null
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: { from: location }
          }}
        />}
  />

class App extends Component {

  constructor(props) {
    super(props)
    this.idleTimer = null
    this.state = {
      timeout: 1000 * 60 * 15,
      remaining: null,
      isIdle: false,
      lastActive: null,
      elapsed: null,
      user: null,
      token: null
    }

    // Bind event handlers and methods
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.reset = this._reset.bind(this)
    this.pause = this._pause.bind(this)
    this.resume = this._resume.bind(this)
    this.changeTimeout = this._changeTimeout.bind(this)
  }

  //==============================================
  //========== START: IdleTimer ==================
  //==============================================
  // set onIdle
  _onActive() {
    // console.log("--_onActive--")
    // console.log('this.state.timeout' , this.state.timeout)
    this.setState({ isIdle: false })
  }

  async _onIdle() {
    // console.log("--_onIdle--")
    // console.log('this.state.timeout' , this.state.timeout)
    const { access_token } = getItems(['access_token'])
    if (access_token != null) {
      this.signOut()
    }
    this.setState({ isIdle: true })
  }

  _changeTimeout() {
    // console.log("--_changeTimeout--")
    this.setState({
      timeout: this.refs.timeoutInput.state.value()
    })
  }

  _reset() {
    // console.log("--_reset--")
    this.idleTimer.reset()
  }

  _pause() {
    // console.log("--_pause--")
    this.idleTimer.pause()
  }

  _resume() {
    // console.log("--_resume--")
    this.idleTimer.resume()
  }

  signOut = async () => {
    await this.props.userSignOut()
    await this.props.history.push('/')
  }
  //===============END : IdleTimer ================

  componentDidUpdate(prevProps) {

    //[ set :: token ]
    if (this.props.authUser !== prevProps.authUser) {
      this.setState({
        token: isEmpty(this.props.authUser) ? null : this.props.authUser.access_token
      })
      this.setState({
        timeout: isEmpty(this.props.authUser) ? null : parseInt(this.props.authUser.system_config.session_timeout) * 1000 //change to milisecon
      })
    }
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('full-layout')
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout')
      document.body.classList.remove('framed-layout')
      document.body.classList.add('boxed-layout')
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout')
      document.body.classList.remove('full-layout')
      document.body.classList.add('framed-layout')
    }
  }

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll')
      document.body.classList.add('horizontal-layout')
    } else {
      document.body.classList.remove('full-scroll')
      document.body.classList.remove('horizontal-layout')
    }
  }

  hideScrollbar = (history) => {
    let clsHideScrollbar = document.getElementsByClassName('hide-scrollbar')
    let spliPathname = history.location.pathname.split('?')
    if (spliPathname[0] !== '/main/report-viewer/' && clsHideScrollbar.length > 0) {
      // console.log('hideScrollbar', spliPathname[0], clsHideScrollbar.length)
      window.scrollTo(0, 0)
      document.getElementsByClassName('gx-main-content-wrapper')[0].classList.remove('hide-scrollbar')
    }
  }

  componentDidMount() {
    const { access_token } = getItems(['access_token'])
    if (access_token !== null && this.props.history.location.pathname === '/') {
      this.props.history.push('/main/dashboard')
    }
    if (access_token !== null) {
      this.setState({
        timeout: isEmpty(this.props.authUser) ? null : parseInt(this.props.authUser.system_config.session_timeout) * 1000 //change to milisecon
      })
    }

    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }


    const params = new URLSearchParams(this.props.location.search)

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'))
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'))
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'))
    }
  }

  render() {
    const { access_token } = getItems(['access_token'])
    const { match, themeType, layoutType, navStyle, locale } = this.props
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add('dark-theme')
    }

    this.setLayoutType(layoutType)
    this.setNavStyle(navStyle)
    this.hideScrollbar(this.props.history)

    const currentAppLocale = AppLocale[locale.locale]
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          timeout={this.state.timeout}
          startOnLoad
        />
        <ConfigProvider locale={currentAppLocale.antd}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ?
              <Switch>
                <Route exact path='/signin' component={SignIn} />
                <Route exact path='/activate/' component={ActivateUser} />
                <Route exact path='/OTP/verify/' component={OtpVerify} />
                <Route exact path='/request/:action' component={RequestResetUser} />
                <RestrictedRoute
                  path={`/main`}
                  access_token={access_token}
                  component={MainApp}
                />
                <Route exact path={`${match.url}`} component={SignIn} />
              </Switch>
              :
              <Switch>
                <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
                  <Route exact path='/signin' component={SignIn} />
                  <Route exact path='/activate/' component={ActivateUser} />
                  <Route exact path='/OTP/verify/' component={OtpVerify} />
                  <Route exact path='/request/:action' component={RequestResetUser} />
                  <RestrictedRoute
                    path={`/main`}
                    access_token={access_token}
                    component={MainApp}
                  />
                  <Route exact path={`${match.url}`} component={SignIn} />
                </BrowserRouter>
              </Switch>
            }
          </IntlProvider>
        </ConfigProvider>
      </>
    )
  }
}

const mapStateToProps = ({ settings, auth }) => {
  const { locale, navStyle, themeType, layoutType } = settings
  const { authUser, initURL } = auth
  return { locale, navStyle, themeType, layoutType, authUser, initURL }
}
export default connect(mapStateToProps, { setInitUrl, userSignOut, setThemeType, onNavStyleChange, onLayoutTypeChange })(App)
