import * as actions from "./folioHistoryActionType"
import { historyRequestType } from "./typeInterface"

export const folioHistoryPreview = (payload: historyRequestType): actions.FolioHistoryRequest => {
  return {
    type: actions.FOLIO_HISTORY_REQUEST,
    payload
  }
}

export const folioHistorySearch = (payload?: any): actions.FolioHistorySearch => {
  return {
    type: actions.FOLIO_HISTORY_SEARCH,
    payload
  }
}

export const folioHistoryDataSourceSuccess = (payload:any):actions.FolioHistoryDataSourceSuccess => {
  return {
    type: actions.FOLIO_HISTORY_DATASOURCE_SUCCESS,
    payload
  }
}

export const folioHistoryProcessSuccess = (payload:any):actions.FolioHisrotyProcessSuccess =>{
  return{
    type: actions.FOLIO_HISTORY_PROCESS_SUCCESS,
    payload
  }
}

export const folioHistoryReopen = (payload:number):actions.FolioHistoryReopen => {
  return {
    type: actions.FOLIO_HISTORY_REOPEN,
    payload
  }
}
export const folioHistoryUpdate = (value:any):actions.FolioHistoryUpdate =>{
  return{
    type: actions.FOLIO_HISTORY_TRANS_UPDATE,
    payload:value
  }
}

export const folioHistoryProcessReset = () =>{
  return{
    type:actions.FOLIO_HISTORY_PROCESS_RESET
  } 
}
