export const API_URL = process.env.REACT_APP_API_URL
export const API_URL_AUTH = `${API_URL}/v1/`
export const API_URL_V1 = `${API_URL}/v1/`
export const BASE_URL_LOCAL = 'https://localhost:7190';
export let API_HEADERS = {
	headers: {
		'Content-Type': 'application/json',
		'x-api-key': process.env.REACT_APP_API_KEY,
		Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
	}
}
export let API_PMS_HEADERS = {
	headers:{
		'Content-Type':'application/json',
		'x-api-key': process.env.REACT_APP_API_PMS_KEY,
		Authorization: `Bearer ${localStorage.getItem('access_token') || ''}`,
	}
}
export let LOCAL_HEADERS = {
	headers: {
	  Accept: '*/*',
	},
  };
//grant type of API
export const GRANT_TYPE_INFO_USER = 'information_user'