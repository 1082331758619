import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  LANGUAGE_PREVIEW,
  LANGUAGE_NEW,
  LANGUAGE_UPDATE,
  LANGUAGE_REMOVE,
  LANGUAGE_SEARCH
} from "constants/ActionTypes"

import {languageDataSourceUpdate,languageProcessSuccess,languageProcessReset} from "appRedux/actions/setup/market/Language"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* languagePreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/language/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const languageDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key
                    container.lang_id = obj.id
                    container.lang_name = obj.name
                    container.lang_code = obj.code
                    container.enabled = obj.enabled
                return container
            })
            
            /* if no error put everything to reducer */
            yield put(languageDataSourceUpdate(languageDataSource))
            yield IndexedDBAdd("ListBoxDB","objStoreLanguageList",languageDataSource)
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message }
            yield put(languageProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on saga language : ",error)
        const processStatus = { status: false, action: "preview", message:error }
        yield put(languageProcessSuccess(processStatus))
    }
    
}

function* languageSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreLanguageList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return o.lang_name.toLowerCase().indexOf(keyword) > -1 || o.lang_code.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered])]

      yield put(languageDataSourceUpdate(mergeData))
  }else 
      yield put(languageDataSourceUpdate(ReformalData))
}

function* languageCreateProcess({payload}){
  const {lang_code,lang_name,active:langActive} = payload

  let langInfo = {code:lang_code,
                  name:lang_name,
                  enabled:langActive}
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/language/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = langInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success." }
                yield put(languageProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message }
              yield put(languageProcessSuccess(processStatus))
              yield put(languageProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              }
            }      

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error }
            yield put(languageProcessSuccess(processStatus))
            yield put(languageProcessReset())
      }
}

function* languageUpdateProcess({payload}){
  const {lang_name,lang_id,active:langActive} = payload
 
  let langInfo = {}
      langInfo.id = lang_id
      langInfo.name = lang_name
      langInfo.enabled = langActive
  
  
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/language/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = langInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success." }
            yield put(languageProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message }
          yield put(languageProcessSuccess(processStatus))
          yield put(languageProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }        

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error }
        yield put(languageProcessSuccess(processStatus))
        yield put(languageProcessReset())
  }
}

function* languageRemoveProcess({payload}){
    const languageRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/language/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : languageRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success." }
          yield put(languageProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(languageProcessSuccess(processStatus))
          yield put(languageProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }     
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error }
      yield put(languageProcessSuccess(processStatus))
      yield put(languageProcessReset())
    }
}

/* Watcher Saga */
export function* languagePreview() {
  yield takeLatest(LANGUAGE_PREVIEW, languagePreviewProcess)
}
export function* languageSearch(){
  yield takeLatest(LANGUAGE_SEARCH, languageSearchProcess)
}
export function* languageCreate(){
  yield takeEvery(LANGUAGE_NEW,languageCreateProcess)
}
export function* languageUpdate(){
  yield takeEvery(LANGUAGE_UPDATE, languageUpdateProcess)
}
export function* languageRemove(){
  yield takeEvery(LANGUAGE_REMOVE,languageRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(languagePreview),
             fork(languageSearch),
             fork(languageUpdate)])


}
