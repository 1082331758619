export const USER_PREVIEW = "USER_PREVIEW"
export const USER_NEW = "USER_NEW"
export const USER_UPDATE = "USER_UPDATE"
export const USER_REMOVE = "USER_REMOVE"
export const USER_PROCESS_SUCCESS = "USER_PROCESS_SUCCESS"
export const USER_DATASOURCE_SUCCESS = "USER_DATASOURCE_SUCCESS"
export const USER_SEARCH = "USER_SEARCH"
export const USER_PROPERTY_LIST = "USER_PROPERTY_LIST"
export const USER_GROUP_LIST = "USER_GROUP_LIST"
export const USER_PROCESS_RESET = "USER_PROCESS_RESET"

export const USER_PERMISSION_PREVIEW = "USER_PERMISSION_PREVIEW"
export const USER_PERMISSION_SEARCH = "USER_PERMISSION_SEARCH"
export const USER_PERMISSION_DATASOURCE = "USER_PERMISSION_DATASOURCE"
export const USER_PERMISSION_UPDATE = "USER_PERMISSION_UPDATE" //Action update user permission
export const USER_PERMISSION_UPDATE_SUCCESS = "USER_PERMISSION_UPDATE_SUCCESS"
export const USER_SWITCH_ENABLED = "USER_SWITCH_ENABLED"

export interface UserRequest {
  type: typeof USER_PREVIEW,
  payload?:any
}
export interface UserDataSourceSuccess{
  type: typeof USER_DATASOURCE_SUCCESS,
  payload:any
}

export interface UserProcessReset {
  type: typeof USER_PROCESS_RESET
}

export interface UserNew{
  type: typeof USER_NEW,
  payload:any
}

export interface UserUpdate{
  type: typeof USER_UPDATE,
  payload:any
}
export interface UserRemove{
  type: typeof USER_REMOVE,
  payload:any
}

export interface UserSearch{
  type: typeof USER_SEARCH,
  payload?:string
}

export interface UserProcessSuccess {
  type: typeof USER_PROCESS_SUCCESS,
  payload:{}
}
export interface GetPropertyList{
  type: typeof USER_PROPERTY_LIST,
  payload:any
}
export interface GetUserGroupList{
  type: typeof USER_GROUP_LIST,
  payload:any
}

export interface UserPermissionPreview{
  type: typeof USER_PERMISSION_PREVIEW,
  payload:any
}
export interface UserPermissionSetDataSource{
  type: typeof USER_PERMISSION_DATASOURCE,
  payload:any
}
export interface UserPermissionSearch{
  type: typeof USER_PERMISSION_SEARCH,
  payload:any
}
export interface UserPermissionUpdate{
  type: typeof USER_PERMISSION_UPDATE,
  payload:any
}

export interface UserPermissionUpdateSuccess{
  type: typeof USER_PERMISSION_UPDATE_SUCCESS,
  payload:any
}
export interface UserSwitchEnabled{
  type: typeof USER_SWITCH_ENABLED,
  payload:any
}

export type UserAction = 
| UserRequest
| UserNew
| UserUpdate
| UserRemove
| UserSearch
| UserProcessSuccess
| UserDataSourceSuccess
| UserProcessReset
| GetPropertyList
| GetUserGroupList
| UserPermissionPreview
| UserPermissionSearch
| UserPermissionSetDataSource
| UserPermissionUpdate
| UserPermissionUpdateSuccess
| UserSwitchEnabled
