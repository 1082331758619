import * as actions from "appRedux/actions/rsv/BlockRoomActionType"
import {blockRoomType} from "./BlockRoomType"

export interface blockRoomState {
    loader: boolean,
    dataSource: Array<blockRoomType>,
    process:any
}

const initialState: blockRoomState = {
    loader: false,
    dataSource: [],
    process:{ status:false,action:"",message:""}
}

export default (state: blockRoomState = initialState,action: actions.BlockRoomAction): blockRoomState =>{
    switch (action.type) {
    case actions.BLOCKROOM_REQUEST:{
        return {
            ...state,
            loader:true,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BLOCKROOM_BY_ROOM_NUMBER:{
        return {
            ...state,
            loader:false,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BLOCKROOM_BY_ROOM_ID:{
        return {
            ...state,
            loader:false,
            process: {status:false,action:"",message:""}
        }
    }
	case actions.BLOCKROOM_CHANGE_ROOM:{
		return{
			...state,
			process:{status:false,action:"",message:""}
		}
	}

	case actions.BLOCKROOM_SET_SUPERBLOCK:{
		return{
			...state,
			process:{status:false,action:"",message:""}
		}
	}
    case actions.BLOCKROOM_UNBLOCK:{
        return {
            ...state,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BLOCKROOM_UNBLOCK_ALL:{
        return {
            ...state,
            process: {status:false,action:"",message:""}
        }
    }
    case actions.BLOCKROOM_AUTO_BLOCK:{
        return {
            ...state,
            process: {status:false,action:"",message:""}
        }
    }
	case actions.BLOCKROOM_DATASOURCE_SUCCESS:{
		return {
			...state,
			loader:false,
			dataSource:action.payload
		}
	}
    case actions.BLOCKROOM_PROCESS_SUCCESS:{
        return{
            ...state,
            process:action.payload
        }
    }
    case actions.BLOCKROOM_PROCESS_RESET:{
        return{
            ...state,
            process:{status:false,action:"",message:""}
        }
    }
    default:
        return state
    }
}