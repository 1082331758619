
export const CASHIER_GUEST_FOLIO_REQUEST = "CASHIER_GUEST_FOLIO_REQUEST"
export const CASHIER_GUEST_FOLIO_REALTIME = "CASHIER_GUEST_FOLIO_REALTIME"
export const CASHIER_GUEST_FOLIO_PROCESS_SUCCESS = "CASHIER_GUEST_FOLIO_PROCESS_SUCCESS"
export const CASHIER_GUEST_FOLIO_DATASOURCE_SUCCESS = "CASHIER_GUEST_FOLIO_DATASOURCE_SUCCESS"
export const CASHIER_GUEST_FOLIO_PROCESS_RESET = "CASHIER_GUEST_FOLIO_PROCESS_RESET"
export const CASHIER_GUEST_FOLIO_SET_FILTER = "CASHIER_GUEST_FOLIO_SET_FILTER"
export const CASHIER_GUEST_FOLIO_CHECK_OUT = "CASHIER_GUEST_FOLIO_CHECK_OUT"
export const CASHIER_GUEST_FOLIO_CANCEL_CHECK_OUT = "CASHIER_GUEST_FOLIO_CANCEL_CHECK_OUT"
export const CASHIER_GUEST_FOLIO_TOGGLE_LOCK = "CASHIER_GUEST_FOLIO_TOGGLE_LOCK"
export const CASHIER_GUEST_FOLIO_TOGGLE_SUCCESS = "CASHIER_GUEST_FOLIO_TOGGLE_SUCCESS"
export const CASHIER_GUEST_CHANGE_ONLINE_SHIFT = "CASHIER_GUEST_CHANGE_ONLINE_SHIFT"

export const CASHIER_GUEST_FOLIO_DETAIL_REQUEST = "CASHIER_GUEST_FOLIO_DETAIL_REQUEST"
export const CASHIER_GUEST_FOLIO_DETAIL_DATASOURCE_SUCCESS = "CASHIER_GUEST_FOLIO_DETAIL_DATASOURCE_SUCCESS"
export const CASHIER_GUEST_INFO_UPDATE = "CASHIER_GUEST_INFO_UPDATE"
export const CASHIER_GUEST_INFO_UPDATE_SUCCESS = "CASHIER_GUEST_INFO_UPDATE_SUCCESS"

export const CASHIER_GUEST_FOLIO_POST = "CASHIER_GUEST_FOLIO_POST"
export const CASHIER_GUEST_FOLIO_VOID = "CASHIER_GUEST_FOLIO_VOID"
export const CASHIER_GUEST_FOLIO_UPDATE = "CASHIER_GUEST_FOLIO_UPDATE"
export const CASHIER_GUEST_FOLIO_PAYMENT = "CASHIER_GUEST_FOLIO_PAYMENT"
export const CASHIER_GUEST_FOLIO_SPLIT = "CASHIER_GUEST_FOLIO_SPLIT"
export const CASHIER_GUEST_FOLIO_MOVE = "CASHIER_GUEST_FOLIO_MOVE"

export const CASHIER_GUEST_CLOSE_FOLIO_SEQ = "CASHIER_GUEST_CLOSE_FOLIO_SEQ"

export interface CashierGuestFolioRequest {
  type: typeof CASHIER_GUEST_FOLIO_REQUEST,
  payload?:any,
  sorter?:any
}
export interface CashierGuestFolioRealTime {
  type: typeof CASHIER_GUEST_FOLIO_REALTIME,
  payload?:any
}
export interface CashierGuestFolioProcessSuccess {
  type: typeof CASHIER_GUEST_FOLIO_PROCESS_SUCCESS,
  payload:{}
}
export interface CashierGuestFolioDataSourceSuccess{
  type: typeof CASHIER_GUEST_FOLIO_DATASOURCE_SUCCESS,
  payload:any
}
export interface CashierGuestFolioProcessReset {
  type: typeof CASHIER_GUEST_FOLIO_PROCESS_RESET
}
export interface CashierSetFilter{
  type: typeof CASHIER_GUEST_FOLIO_SET_FILTER,
  payload:any
}
export interface CashierGuestCheckOut{
  type: typeof CASHIER_GUEST_FOLIO_CHECK_OUT,
  payload:number
}
export interface CashierGuestCancelCheckOut{
  type: typeof CASHIER_GUEST_FOLIO_CANCEL_CHECK_OUT,
  payload:any
}

export interface CashierGuestFolioDetailRequest {
  type: typeof CASHIER_GUEST_FOLIO_DETAIL_REQUEST,
  payload:number
}
export interface CashierGuestFolioDetailDataSourceSuccess{
  type: typeof CASHIER_GUEST_FOLIO_DETAIL_DATASOURCE_SUCCESS,
  payload:any
}

export interface CashierGuestFolioPost{
  type: typeof CASHIER_GUEST_FOLIO_POST,
  payload:any
}

export interface CashierGuestFolioVoid{
  type: typeof CASHIER_GUEST_FOLIO_VOID,
  payload:any
}

export interface CashierGuestFolioUpdate{
  type: typeof CASHIER_GUEST_FOLIO_UPDATE,
  payload:any
}

export interface CashierGuestFolioPayment{
  type: typeof CASHIER_GUEST_FOLIO_PAYMENT,
  payload:any
}

export interface CashierGuestFolioSplit{
  type: typeof CASHIER_GUEST_FOLIO_SPLIT,
  payload:any
}
export interface CashierGuestFolioMove{
  type: typeof CASHIER_GUEST_FOLIO_MOVE,
  payload:any
}

export interface CashierGuestCloseFolioSeq{
  type: typeof CASHIER_GUEST_CLOSE_FOLIO_SEQ,
  payload:any
}

export interface CashierGuestToggleLock{
  type: typeof CASHIER_GUEST_FOLIO_TOGGLE_LOCK,
  payload:any
}

export interface CashierGuestToggleSuccess{
  type: typeof CASHIER_GUEST_FOLIO_TOGGLE_SUCCESS,
  payload:any
}

export interface CashierGuestInfoUpdate{
  type: typeof CASHIER_GUEST_INFO_UPDATE,
  payload:any
}

export interface CashierGuestInfoUpdateSuccess{
  type: typeof CASHIER_GUEST_INFO_UPDATE_SUCCESS,
  payload:any
}

export interface CashierGuestChangeOnlineShift{
  type: typeof CASHIER_GUEST_CHANGE_ONLINE_SHIFT,
  payload:any
}

export type CashierAction = 
| CashierGuestFolioRequest
| CashierGuestFolioRealTime
| CashierGuestFolioProcessSuccess
| CashierGuestFolioDataSourceSuccess
| CashierGuestFolioProcessReset
| CashierSetFilter
| CashierGuestCheckOut
| CashierGuestCancelCheckOut
| CashierGuestFolioDetailRequest
| CashierGuestFolioDetailDataSourceSuccess
| CashierGuestFolioPost
| CashierGuestFolioVoid
| CashierGuestFolioUpdate
| CashierGuestFolioPayment
| CashierGuestFolioSplit
| CashierGuestFolioMove
| CashierGuestCloseFolioSeq
| CashierGuestToggleLock
| CashierGuestToggleSuccess
| CashierGuestInfoUpdate
| CashierGuestInfoUpdateSuccess
| CashierGuestChangeOnlineShift
