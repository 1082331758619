import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  TITLE_NAME_PREVIEW,
  TITLE_NAME_NEW,
  TITLE_NAME_UPDATE,
  TITLE_NAME_REMOVE,
  TITLE_NAME_SEARCH
} from "constants/ActionTypes"

import {titleNameDataSourceUpdate,titleNameProcessSuccess,titleNameProcessReset} from "appRedux/actions/setup/miscellaneous/TitleName"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd,IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* titleNamePreviewProcess(){
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/title/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const titleNameDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
              const container = {}
                    container.key = key+1
                    container.title_id = obj.id
                    container.title_name = obj.name
                    container.gender = obj.sex||""
                    container.enabled = obj.enabled
                   
                return container
            })
            
            /* if no error put everything to reducer */
             yield put(titleNameDataSourceUpdate(titleNameDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreTitleNameList",titleNameDataSource)

      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(titleNameProcessSuccess(processStatus))
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on Inclusion preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(titleNameProcessSuccess(processStatus))
    }
    
}

function* titleNameSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreTitleNameList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered = filter(ReformalData, function(o) {
        return (o.title_name.toLowerCase().indexOf(keyword) > -1)
      })

      const mergeData = [...new Set([...filtered])]

      yield put(titleNameDataSourceUpdate(mergeData))
  }else 
      yield put(titleNameDataSourceUpdate(ReformalData))
}

function* titleNameCreateProcess({payload}){
  const {title_name,gender} = payload

  const titleNameInfo = {}
        titleNameInfo.name = title_name
        titleNameInfo.sex = gender||null

     
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/title/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = titleNameInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(titleNameProcessSuccess(processStatus))
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(titleNameProcessSuccess(processStatus))
              yield put(titleNameProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(titleNameProcessSuccess(processStatus))
            yield put(titleNameProcessReset())
      }
}

function* titleNameUpdateProcess({payload}){

  const {title_id,title_name,gender} = payload
 
  let titleNameInfo = {}
      titleNameInfo.id = title_id
      titleNameInfo.name = title_name
      titleNameInfo.sex = gender||null
  
 
  try {
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/title/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = titleNameInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(titleNameProcessSuccess(processStatus))
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(titleNameProcessSuccess(processStatus))
          yield put(titleNameProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(titleNameProcessSuccess(processStatus))
        yield put(titleNameProcessReset())
  }
}

function* titleNameRemoveProcess({payload}){
    const titleRemoveID = payload
   

    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/title/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : titleRemoveID}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(titleNameProcessSuccess(processStatus))
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(titleNameProcessSuccess(processStatus))
          yield put(titleNameProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(titleNameProcessSuccess(processStatus))
      yield put(titleNameProcessReset())
    }
}

/* Watcher Saga */
export function* titleNamePreview() {
  yield takeLatest(TITLE_NAME_PREVIEW, titleNamePreviewProcess)
}
export function* titleNameSearch(){
  yield takeLatest(TITLE_NAME_SEARCH, titleNameSearchProcess)
}
export function* titleNameCreate(){
  yield takeEvery(TITLE_NAME_NEW,titleNameCreateProcess)
}
export function* titleNameUpdate(){
  yield takeEvery(TITLE_NAME_UPDATE, titleNameUpdateProcess)
}
export function* titleNameRemove(){
  yield takeEvery(TITLE_NAME_REMOVE,titleNameRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(titleNamePreview),
             fork(titleNameCreate),
             fork(titleNameUpdate),
             fork(titleNameRemove)])


}
