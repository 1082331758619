import {all, fork, put, takeEvery,takeLatest, call} from "redux-saga/effects"

import {
  MEDIA_GROUP_PREVIEW,
  MEDIA_GROUP_NEW,
  MEDIA_GROUP_UPDATE,
  MEDIA_GROUP_REMOVE,
  MEDIA_GROUP_SEARCH
} from "constants/ActionTypes"

import {mediaGroupDataSourceUpdate,mediaGroupProcessSuccess,mediaGroupProcessReset} from "appRedux/actions/setup/department/MediaGroup"
import {userSignOut} from "appRedux/actions/Auth"

import { APIGetRequest,APIPostRequest } from "util/connection"
import { API_URL_V1,API_PMS_HEADERS } from "constants/ApiSetting"
import { isUndefined,filter,orderBy } from "lodash"
import { IndexedDBAdd, IndexDBGetDataAll } from "util/indexedDBLibrary"
import { getItems } from "util/localStorage"

function* mediaGroupPreviewProcess(){
    
    const  { access_token }  = yield call(getItems, ['access_token'])
    const AccessToken = access_token
    const url = `${API_URL_V1}pms/masterfile/mediaGroup/`
    API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
    
    try {
      const response = yield APIGetRequest(url,API_PMS_HEADERS)

      if(response.status === 200)
      {
            const mediaGroupDataSource = orderBy(response.data,[o => o.name.toLowerCase()],['asc']).map((obj,key) =>{
            const container = {}
                  container.key = key
                  container.media_group_id = obj.id
                  container.media_group_name = obj.name
                  container.media_group_category = obj.category
                  container.media_group_category_name = obj.category_name
              return container
            })
            
            /* if no error put everything to reducer */
             yield put(mediaGroupDataSourceUpdate(mediaGroupDataSource))
             yield IndexedDBAdd("ListBoxDB","objStoreMediaGroupList",mediaGroupDataSource)
            
      }else{
            console.log("Error : ",response)
            /* Show error message and signout */
            const {message,data} = response.data
            const processStatus = { status: false, action: "preview", message}
            yield put(mediaGroupProcessSuccess(processStatus))
            yield put(mediaGroupProcessReset())
            if(response.status === 403){
              /* if data is not null force signout */
              if(data)
                yield put(userSignOut())
            }
            
      }
      
    } catch (error) {
        console.log("Error on department group preview : ",error)
        const processStatus = { status: false, action: "preview", message:error}
        yield put(mediaGroupProcessSuccess(processStatus))
        yield put(mediaGroupProcessReset())
    }
    
}

function* mediaGroupSearchProcess({payload}){
  const storeValue = yield IndexDBGetDataAll('ListBoxDB', 'objStoreMediaGroupList')
  const ReformalData = [...storeValue[0]]
  const keyword = payload.toLowerCase()

  if(!isUndefined(keyword)){
      /* Search by name */
      const filtered_media_group_name = filter(ReformalData, function(o) {
        return o.media_group_name.toLowerCase().indexOf(keyword) > -1
      })

      const mergeData = [...new Set([...filtered_media_group_name])]

      yield put(mediaGroupDataSourceUpdate(mergeData))
  }else 
      yield put(mediaGroupDataSourceUpdate(ReformalData))
}

function* mediaGroupCreateProcess({payload}){
  const {media_group_name ,media_group_category} = payload
  let mediaGroupInfo = {name:media_group_name ,category:media_group_category}
      
      try {
            const  { access_token }  = yield call(getItems, ['access_token'])
            const AccessToken = access_token
            const url = `${API_URL_V1}pms/masterfile/mediaGroup/create/`
            API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
            const API_DATABODY  = mediaGroupInfo
            const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

            if(response.status === 200){
                const processStatus = { status: true, action: "create", message:"Create success."}
                yield put(mediaGroupProcessSuccess(processStatus))
                yield put(mediaGroupProcessReset())
            }else{
              const {message,data} = response.data
              const processStatus = { status: false, action: "create", message}
              yield put(mediaGroupProcessSuccess(processStatus))
              yield put(mediaGroupProcessReset())
              if(response.status === 403){
                /* if data is not null force signout */
                if(data)
                  yield put(userSignOut())
              } 
            }  

      } catch (error) {
            console.log("Error : ",error)
            const processStatus = { status: false, action: "create", message:error}
            yield put(mediaGroupProcessSuccess(processStatus))
            yield put(mediaGroupProcessReset())
      }
}

function* mediaGroupUpdateProcess({payload}){
  const {media_group_name , media_group_category ,media_group_id} = payload
 
  let mediaGroupInfo = {}
      mediaGroupInfo.id = media_group_id
      mediaGroupInfo.name = media_group_name
      mediaGroupInfo.category = media_group_category
  
  try {

        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/mediaGroup/update/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY  = mediaGroupInfo
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)

        if(response.status === 200){
            const processStatus = { status: true, action: "update", message:"Update success."}
            yield put(mediaGroupProcessSuccess(processStatus))
            yield put(mediaGroupProcessReset())
        }else { 
          const {message,data} = response.data
          const processStatus = { status: false, action: "update", message}
          yield put(mediaGroupProcessSuccess(processStatus))
          yield put(mediaGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        } 

  } catch (error) {
        console.log("Error : ",error)
        const processStatus = { status: false, action: "update", message:error}
        yield put(mediaGroupProcessSuccess(processStatus))
        yield put(mediaGroupProcessReset())
  }
}

function* mediaGroupRemoveProcess({payload}){
    const mediaGroupRemove = payload
    
    try{
        const  { access_token }  = yield call(getItems, ['access_token'])
        const AccessToken = access_token
        const url = `${API_URL_V1}pms/masterfile/mediaGroup/delete/`
        API_PMS_HEADERS.headers.Authorization = `Bearer ${AccessToken}`
        const API_DATABODY = {id : mediaGroupRemove}
        const response = yield APIPostRequest(url,API_DATABODY,API_PMS_HEADERS)
        if(response.status === 200){
          const processStatus = { status: true, action: "delete", message:"Delete success."}
          yield put(mediaGroupProcessSuccess(processStatus))
          yield put(mediaGroupProcessReset())
        }else{
          const {message,data} = response.data
          const processStatus = { status: false, action: "delete", message}
          yield put(mediaGroupProcessSuccess(processStatus))
          yield put(mediaGroupProcessReset())
          if(response.status === 403){
            /* if data is not null force signout */
            if(data)
              yield put(userSignOut())
          }
        }  
    }
    catch(error){
      console.log("Error : ",error)
      const processStatus = { status: false, action: "delete", message:error}
      yield put(mediaGroupProcessSuccess(processStatus))
      yield put(mediaGroupProcessReset())
    }
}

/* Watcher Saga */
export function* mediaGroupPreview() {
  yield takeLatest(MEDIA_GROUP_PREVIEW, mediaGroupPreviewProcess)
}
export function* mediaGroupSearch(){
  yield takeLatest(MEDIA_GROUP_SEARCH, mediaGroupSearchProcess)
}
export function* mediaGroupCreate(){
  yield takeEvery(MEDIA_GROUP_NEW,mediaGroupCreateProcess)
}
export function* mediaGroupUpdate(){
  yield takeEvery(MEDIA_GROUP_UPDATE, mediaGroupUpdateProcess)
}
export function* mediaGroupRemove(){
  yield takeEvery(MEDIA_GROUP_REMOVE,mediaGroupRemoveProcess)
}

export default function* rootSaga() {
  yield all([fork(mediaGroupPreview),
             fork(mediaGroupSearch),
             fork(mediaGroupCreate),
             fork(mediaGroupUpdate),
             fork(mediaGroupRemove)])


}
